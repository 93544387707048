import React, { useState } from 'react';

import { FaFrown, FaLaughBeam, FaSadCry, FaSmile } from 'react-icons/fa';
import { BsEmojiNeutralFill } from 'react-icons/bs';
import { Button, Container } from './styles';
import api from '../../services/api';

export const Research = ({ research, notif }) => {
  const [rated, setRated] = useState(notif.rated);

  const sendResearch = async (value) => {
    await api.post('/restaurants/research-answer', {
      research_id: research.id,
      value,
      notification_id: notif.id,
    });

    setRated(true);
    notif.rated = true;
  };

  return rated ? (
    <>
      <p style={{ marginTop: 5 }}>Obrigado por avaliar!</p>
    </>
  ) : (
    <>
      <p style={{ marginTop: 5 }}>{research.question}</p>
      <Container>
        <Button
          onTouchStart={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
          onTouchEnd={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={() => sendResearch(1)}
        >
          <FaSadCry color="#F10D27" />
        </Button>
        <Button
          onTouchStart={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
          onTouchEnd={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={() => sendResearch(2)}
        >
          <FaFrown color="#FF7686" />
        </Button>
        <Button
          onTouchStart={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
          onTouchEnd={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={() => sendResearch(3)}
        >
          <BsEmojiNeutralFill color="#FFCD0C" />
        </Button>
        <Button
          onTouchStart={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
          onTouchEnd={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={() => sendResearch(4)}
        >
          <FaSmile color="#6ED889" />
        </Button>
        <Button
          onTouchStart={(e) => (e.currentTarget.style.transform = 'scale(1.2)')}
          onTouchEnd={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={() => sendResearch(5)}
        >
          <FaLaughBeam color="#24D953" />
        </Button>
      </Container>
    </>
  );
};
