import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 28px;

  button {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    float: left;
  }
`;

export const Content = styled.div`
  width: 100%;

  margin-top: 20px;
`;
