import { Form } from '@unform/web';
import React, { useCallback, useEffect, useState } from 'react';
import { FaClock, FaUserCircle } from 'react-icons/fa';
import { Badge } from 'reactstrap';
import { differenceInMinutes } from 'date-fns';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import OrderItem from './OrderItem';

import {
  BuyerInfo,
  ConfirmButton,
  Container,
  Header,
  Orders,
  OrdersInfo,
} from './styles';
import PhoneString from '~/services/formatPhoneString';

function OrderCard({ item, baskets, setBaskets }) {
  const [cardExpanded, setCardExpanded] = useState(false);

  function toggleCardExpand() {
    setCardExpanded(!cardExpanded);
  }

  const buttonStatus = item?.status === 'pending' ? 'Começar' : 'Pronto';
  function getBadgeStatus(status) {
    if (status === 'pending') {
      return 'bg-secondary';
    }

    if (status === 'doing') {
      return 'bg-info';
    }

    if (status === 'finished') {
      return 'bg-success';
    }

    if (status === 'canceled') {
      return 'bg-danger';
    }

    return false;
  }

  function getBadgeText(status) {
    if (status === 'pending') {
      return 'NOVO';
    }

    if (status === 'doing') {
      return 'EM PREPARO';
    }

    if (status === 'finished') {
      return 'PRONTO';
    }

    if (status === 'canceled') {
      return 'CANCELADO';
    }

    return false;
  }

  const handleChangeStatus = useCallback(
    async (order_status, id) => {
      await api.put(`/restaurants/kds/basket/status/${item?.id}`, {
        status: order_status === 'pending' ? 'doing' : 'finished',
      });

      const newBaskets = [...baskets];

      const basketFind = newBaskets?.findIndex((item) => item?.id === id);

      if (basketFind !== -1) {
        newBaskets[basketFind].status =
          order_status === 'pending' ? 'doing' : 'finished';
      }

      setBaskets(newBaskets);
    },
    [item, baskets, setBaskets]
  );

  const [isLate, setIsLate] = useState([]);
  const [averageTime, setAverageTime] = useState();

  useEffect(() => {
    const average_time = item?.kds_orders;

    average_time.sort((a, b) => {
      if (a.average_time > b.average_time) {
        return 1;
      }

      if (a.average_time === b.average_time) {
        return 0;
      }

      if (a.average_time < b.average_time) {
        return -1;
      }
    });

    if (average_time) {
      setAverageTime(average_time[0]);
    }
  }, [item]);

  const verifyIfIsLate = useCallback(() => {
    if (
      differenceInMinutes(new Date(), new Date(item?.createdAt)) >
      averageTime?.average_time
    ) {
      setIsLate([item?.id]);
    }
  }, [averageTime, item]);

  useEffect(() => {
    verifyIfIsLate();
  }, [verifyIfIsLate]);

  useEffect(() => {
    const verifyIfProductIsLate = setInterval(verifyIfIsLate, 60000);
    return () => {
      clearInterval(verifyIfProductIsLate);
    };
  }, [isLate, item, averageTime, verifyIfIsLate]);

  function getTableData(table) {
    if (table.table_type === 'table') {
      return `Mesa ${table.table_number}`;
    }

    if (table.table_type === 'delivery') {
      return `Delivery`;
    }

    if (table.table_type === 'balcony') {
      return `Balcão`;
    }

    return false;
  }

  return (
    <Container>
      <Form>
        <Header>
          <span style={{ fontWeight: 'bold' }}>
            {getTableData(item?.table)}
          </span>

          <Badge pill className={getBadgeStatus(item?.status)}>
            {getBadgeText(item?.status)}
          </Badge>
          <span>
            #
            {item?.table.table_type === 'delivery'
              ? item?.attendance_password
              : item?.basket_key}
          </span>
        </Header>
        <BuyerInfo
          isProductLate={
            isLate.includes(item?.id) &&
            (item?.status === 'doing' || item?.status === 'pending')
          }
        >
          <span>
            <FaUserCircle />{' '}
            <span>
              {item?.buyer
                ? item?.buyer?.name
                  ? item?.buyer?.name
                  : PhoneString(item?.buyer?.phone)
                : item?.waiter?.name}
            </span>
          </span>
          <span>
            <FaClock /> <span>{formatTime(item?.createdAt)}</span>
          </span>
        </BuyerInfo>
        <Orders>
          {item &&
            item?.kds_orders &&
            item?.kds_orders.length > 0 &&
            item?.kds_orders
              .sort((a) => {
                if (a.canceled_at) {
                  return 1;
                }

                if (!a.canceled_at) {
                  return -1;
                }
              })
              .map((product, index) => (
                <OrderItem
                  product={product}
                  handleChangeStatus={handleChangeStatus}
                  basket={item}
                  isLate={
                    isLate.includes(item?.id) &&
                    (item?.status === 'doing' || item?.status === 'pending')
                  }
                  index={index}
                  key={product.id}
                />
              ))}
        </Orders>

        {/* {item.products.length > 3 && (
          <ExpandButton type="button" onClick={toggleCardExpand}>
            <FaAngleDown color="#3D3D3D" /> Mostrar mais
          </ExpandButton>
        )} */}
        {/* <ExpandButton type="button">
          <FaAngleDown color="#3D3D3D" /> Mostrar mais
        </ExpandButton> */}
        {(item?.status === 'pending' || item?.status === 'doing') && (
          <ConfirmButton
            type="button"
            status={item?.status}
            onClick={() => handleChangeStatus(item?.status, item?.id)}
          >
            {buttonStatus}{' '}
          </ConfirmButton>
        )}
      </Form>
    </Container>
  );
}

export default OrderCard;
