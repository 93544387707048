import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { Row, Col, Container } from 'reactstrap';
import * as Yup from 'yup';
import { Wrapper, Header, Card, StyledForm } from './styles';

import api from '~/services/api';
import Input from '~/components/Form/Input';
import getValidationErrorrs from '~/services/getValidationErrors';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { Button, Checkbox } from 'ui-kit-takeat';
import { FaCheck } from 'react-icons/fa';

function EditMethods({ paymentMethod, toggle, getCustomPaymentMethods }) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const [banks, setBanks] = useState();
  const [checkboxValues, setCheckboxValues] = useState({
    is_exclusive: paymentMethod.is_exclusive,
    delivery_accepts: paymentMethod.delivery_accepts,
    available: paymentMethod.available,
    include_weekends: !paymentMethod.include_weekends,
  });
  const initialData = {
    name: paymentMethod.name,
    interval: paymentMethod.interval,
    service_tax: paymentMethod.service_tax,
  };

  const selectBank = {
    value: paymentMethod.bank_account_id,
    label: paymentMethod.bank_account_name,
  };

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        interval: Yup.string().required('Prazo obrigatório'),
        service_tax: Yup.string().required('A taxa é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await api.put(
        `restaurants/payment-method-info/${paymentMethod.keyword}`,
        {
          name: data.name,
          available: checkboxValues.available,
          delivery_accepts: checkboxValues.delivery_accepts,
          interval: data.interval || null,
          service_tax: data.service_tax || null,
          include_weekends: !checkboxValues.include_weekends,
          bank_account_id: data.bank_account_id || null,
          is_exclusive: checkboxValues.is_exclusive,
        }
      );

      toast.success('Forma de pagamento atualizada com sucesso!');
      getCustomPaymentMethods();
      toggle();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrorrs(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao editar forma de pagamento! ${error.message}`);
      }
    }
  }

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksOptions = response.data.map((bank) => {
        return {
          label: bank.name,
          value: bank.id,
        };
      });

      setBanks(banksOptions);
    } catch (error) {
      toast.error('Erro ao baixar contas');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  return (
    <Wrapper>
      <Header>
        <h3 align="center">Editar forma de pagamento</h3>
      </Header>
      <Container>
        <Card>
          <StyledForm
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={initialData}
          >
            <Row>
              <Col lg="7">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Forma de pagamento"
                  disabled={paymentMethod.is_ifood_master}
                />
              </Col>
              <Col lg="5">
                <Input
                  label="Taxa de transação %"
                  name="service_tax"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="Taxa %"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="7">
                <Select
                  name="bank_account_id"
                  label="Conta de recebimento"
                  options={banks}
                  placeholder="Selecione a conta"
                  defaultValue={selectBank}
                />
              </Col>
              <Col lg="5">
                <Input
                  label="Prazo de recebimento"
                  name="interval"
                  type="number"
                  step="1"
                  placeholder="Dias"
                  min="0"
                />
              </Col>
            </Row>
            <Checkbox
              label="Contar só dias úteis para recebimento"
              checked={checkboxValues.include_weekends}
              clickFunction={(e) =>
                setCheckboxValues({ ...checkboxValues, include_weekends: e })
              }
            />
            {!user.only_delivery && (
              <Checkbox
                label="Disponível no PDV"
                checked={checkboxValues.available}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, available: e })
                }
              />
            )}

            {user.delivery_info?.is_delivery_allowed && (
              <Checkbox
                label="Disponível no Delivery"
                checked={checkboxValues.delivery_accepts}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, delivery_accepts: e })
                }
              />
            )}

            <div style={{ position: 'relative' }}>
              <Checkbox
                label="Uso exclusivo"
                checked={checkboxValues.is_exclusive}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, is_exclusive: e })
                }
              />
              <span
                style={{
                  color: '#6c6c6c',
                  fontSize: '12px',
                  position: 'absolute',
                  top: 20,
                  left: 29,
                }}
              >
                Esse método não será exibido para o cliente, apenas no PDV
              </span>
            </div>
            <Button
              marginTop={15}
              title="Salvar"
              icon={<FaCheck />}
              onClick={() => {}}
              type="default"
              float="right"
            />
          </StyledForm>
        </Card>
      </Container>
    </Wrapper>
  );
}

export default EditMethods;
