import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';

import JSZip from 'jszip';
import FileSaver from 'file-saver';
import {
  Container,
  Header,
  StyledLink,
  TablesTable,
  ProductImage,
  Delete,
  Actions,
  StatusTag,
  NavLinkSpan,
  NavDiv,
} from './styles';

import QrCodePrint from './QrCodePrint';

// import Pagination from '~/components/Pagination';
import { useAuth } from '~/context/AuthContext';
import RegisterButton from '~/components/Buttons/RegisterButton';

import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

function Tables() {
  const [tables, setTables] = useState([]);
  const [tablesFiltered, setTablesFiltered] = useState([]);

  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState('1');

  const [disabledCreate, setDisabledCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canCreateBalcony, setCanCreateBalcony] = useState(false);

  // const [totalItems, setTotalItems] = useState(0);

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');
    setTables(response.data);
    setTablesFiltered(response.data.filter(table => ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(table.table_type)));

    const canCreateBalcony = response.data.filter(table => table.table_type === 'balcony');
    if(canCreateBalcony.length === 0){
      setCanCreateBalcony(true);
    }

    if(user.table_limit_type === 'only-balcony'){
      setDisabledCreate(true);
    }else if(user.table_limit_type === 'limited-tables'){
      const tables = response.data.filter(table => table.table_type === 'table').length;
      if(tables >= parseFloat(user.table_limit)){
        setDisabledCreate(true);
      }
    }
    // setTotalItems(response.data.count);
  }, [user]);

  useEffect(() => {
    try {
      getTables();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getTables]);

  function changeFilter(tab){
    switch(tab){
      case '1':
        setTablesFiltered(tables.filter(table => ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(table.table_type)));
        break;
      case '2':
        setTablesFiltered(tables.filter(table => ['command'].includes(table.table_type)));
        break;
      case '3':
        setTablesFiltered(tables.filter(table => ['lounge'].includes(table.table_type)));
        break;
      default:
        setTablesFiltered(tables.filter(table => ['table', 'balcony', 'delivery', 'prepaid', 'menu'].includes(table.table_type)));
    }
  }

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta mesa?')) {
        await api.delete(`/restaurants/tables/${id}`);

        getTables();

        toast.success('Mesa deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar mesa');
    }
  }

  const downloadAllCodes = async () => {
    const qrLinks = tables
      .filter((t) => {
        const name = searchTableName(t);
        const notNumbers = ['Balcão', 'Delivery', 'Cardápio'];
        return !notNumbers.includes(name);
      })
      .map((t) => {
        return { name: searchTableName(t), url: t.table_code?.url };
      });

    const qrUrls = qrLinks.map((qr) => qr.url);

    const downloadMany = (urls) => {
      return Promise.all(
        urls.map((url) => {
          // Adding param to avoid problems with cors
          return fetch(`${url}?q=${Date.now()}`).then((resp) => resp.blob());
        })
      );
    };

    const exportZip = (blobs) => {
      const zip = new JSZip();
      blobs.forEach((blob, i) => {
        zip.file(`${qrLinks[i].name}.jpeg`, blob);
      });

      zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const fileName = `${user.name}-qrcodes.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    };

    const blobs = await downloadMany(qrUrls);
    exportZip(blobs);
  };

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_type === 'menu') {
      return 'Cardápio';
    }

    if (table.table_type === 'prepaid') {
      return `${table.table_number} - Pagamento antecipado`;
    }

    if (table.table_type === 'lounge') {
      return `${table.table_number} - Lounge / R$${table.price_limit && parseFloat(table.price_limit).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }

    return table.table_number;
  }
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterTables'}`
      );

      const { can_read } = response.data.RegisterTables;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  async function handleSubmitBalcony() {
    setLoading(true);
    try {
      await api.post('restaurants/tables/multiple', {
        start: -1,
        end: -1,
        table_type: 'balcony',
      });

      getTables();
      setCanCreateBalcony(false);
      toast.success('Balcão criado com sucesso!');
    } catch (err) {
      console.log('Create balcony error: ', JSON.stringify(err, null, 2));
      toast.error('Falha ao criar balcão');
    }
    setLoading(false);
  }

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Mesas</h2>
          <p>Gerencie as mesas de seu estabelecimento</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'column',
          }}
        >

          <div style={{flexDirection: 'row', display: 'flex'}}>
            {!user.only_delivery && (
              disabledCreate ? 
                <RegisterButton disabled={disabledCreate}>Adicionar</RegisterButton>
              :
              <StyledLink to="/tables/new">
                <RegisterButton>Adicionar</RegisterButton>
              </StyledLink>
            )}

            {
              canCreateBalcony &&
              <RegisterButton 
                text="Adicionar Balcão" 
                style={{marginLeft: 15}} 
                onClick={() => handleSubmitBalcony()}
                disabled={loading}
              >
                Adicionar Balcão
              </RegisterButton>
            }
          </div>


          <RegisterButton
            style={{
              width: 'fit-content',
              marginTop: 20,
            }}
            onClick={() => downloadAllCodes()}
            text="Baixar QR Codes"
            download
          />
        </div>
      </Header>

      {/* <Container>
        <TablesPlace>
          {sessionsOpen.map((session) => (
            <Table session={session} />
          ))}
          {tablesAvailable.map((table) => (
            <TablesClosed table={table?.table_number} />
          ))}
        </TablesPlace>
      </Container> */}

      <NavDiv>
        <Nav tabs borderless="true">
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => { setActiveTab('1'); changeFilter('1')}}
              style={{ cursor: 'pointer', color: '#333' }}
            >
              <NavLinkSpan>Mesas</NavLinkSpan>{' '}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => {
                setActiveTab('2');
                changeFilter('2');
              }}
              style={{ cursor: 'pointer', color: '#333' }}
            >
              <NavLinkSpan>Comandas</NavLinkSpan>{' '}
            </NavLink>
          </NavItem>

          {/* <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => {
                setActiveTab('3');
                changeFilter('3');
              }}
              style={{ cursor: 'pointer', color: '#333' }}
            >
              <NavLinkSpan>Lounges</NavLinkSpan>{' '}
            </NavLink>
          </NavItem> */}
        </Nav>
      </NavDiv>

      {/* <TabContent activeTab={activeTab}>
        <TabPane tabId="1"> */}
          <TablesTable borderless>
            <thead>
              <tr>
                <th>Número</th>
                <th>Status</th>
                <th>Código</th>
                <th>QRCode</th>
                <th>Imprimir</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {tablesFiltered.map((table) => (
                <tr key={table.id}>
                  <td>{searchTableName(table)}</td>
                  <td>
                    {table.status === 'ongoing' ? (
                      <StatusTag color="#ffc107">
                        <span>Em Uso</span>
                      </StatusTag>
                    ) : (
                      <StatusTag color="green">
                        <span>Disponível</span>
                      </StatusTag>
                    )}
                  </td>
                  <td>{table.table_code ? table.table_code.key : 'Sem código'}</td>
                  <td>
                    {table.table_code ? (
                      <ProductImage
                        src={table.table_code.url}
                        alt="Foto do QRCode"
                      />
                    ) : (
                      <p>Sem QRCode</p>
                    )}
                  </td>
                  <td>
                    <QrCodePrint table={table} />
                  </td>
                  <td>
                    {table.table_type !== 'menu' &&
                      table.table_type !== 'delivery' && (
                        <Actions>
                          <StyledLink
                            to={{
                              pathname: 'tables/edit',
                              state: {
                                table,
                              },
                            }}
                          >
                            <MdEdit size={20} />
                          </StyledLink>
                          <Delete onClick={() => handleDelete(table.id)}>
                            <MdDelete size={20} />
                          </Delete>
                        </Actions>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </TablesTable>
        {/* </TabPane>
      </TabContent> */}
    </Container>
  );
}

export default Tables;
