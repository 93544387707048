/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import Switch from 'react-switch'
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import { ButtonsArea, InnerArea, AreaSwitcher, Container } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';

function EditComplementCategoryInsideProduct({
  toggle,
  complementCategory,
  setComplementsCategories,
  complementCategories,
  complementCategoriesProduct,
  setComplementCategoriesInsideProduct,
}) {
  const [complements, setComplements] = useState([]);
  const formRef = useRef(null);
  const initialData = {
    name: complementCategory?.name,
    question: complementCategory?.question,
    minimum: complementCategory?.minimum || 1,
    limit: complementCategory?.limit,
    available: complementCategory?.available,
    optional: complementCategory?.optional,
    single_choice: complementCategory?.single_choice,
    additional: complementCategory?.additional,
    more_expensive_only: complementCategory?.more_expensive_only,
    use_average: complementCategory?.use_average,
  };
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const initialComplements = complementCategory?.complements?.map(
    (complement) => ({
      value: complement.id,
      label: complement.name,
    })
  );

  const getComplements = useCallback(async () => {
    const response = await api.get('/restaurants/product/complements');

    const data = response.data.map((complement) => ({
      value: complement.id,
      label: complement.name,
    }));

    setComplements(data);
  }, []);

  useEffect(() => {
    try {
      getComplements();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplements, complementCategory.id]);

  const [availableCategory, setAvailableCategory] = useState(
    complementCategory?.available
  );

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Máximo obrigatório'),
        minimum: Yup.string().required('Mínimo obrigatório'),
        question: Yup.string().required('Pergunta obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(
        `restaurants/product/complement-category/${complementCategory.id}`,
        {
          name: data.name,
          question: data.question,
          limit: data.limit,
          minimum: Number(data.minimum),
          single_choice: false,
          optional: data.optional,
          available: availableCategory,
          additional: data.additional,
          complements: data.complements,
          more_expensive_only: data.more_expensive_only,
          use_average: data.use_average,
        }
      );

      const newCategories = [...complementCategories];

      const categoryFiltered = newCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryFiltered !== -1) {
        newCategories[categoryFiltered] = response.data;

        setComplementsCategories(newCategories);
      }

      const newComplementsCategories = [...complementCategoriesProduct];

      const categoryComplementsFiltered = newComplementsCategories.findIndex(
        (cat) => cat.id === complementCategory.id
      );

      if (categoryComplementsFiltered !== -1) {
        newComplementsCategories[categoryComplementsFiltered] = response.data;
        setComplementCategoriesInsideProduct(newComplementsCategories);
      }

      toast.success('Categoria atualizada com sucesso!');

      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toggle();
    } catch (error) {
      console.log(error.message);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao salvar categoria');
      }
    }
  }

  const [moreExpensiveChecked, setMoreExpensiveChecked] = useState(
    complementCategory?.more_expensive_only
  );
  const [useAverageChecked, setUseAverageChecked] = useState(
    complementCategory?.use_average
  );

  return (
    <Container>
      <InnerArea browserZoomLevel={browserZoomLevel}>
        <Row>
          <Col md="12">
            <p style={{ fontSize: 18 }}>Editar Grupo de complementos</p>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
          <Row>
            <Col sm="12" lg="6">
              <Input
                label="Nome"
                name="name"
                type="text"
                placeholder="Nome da categoria"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Mínimo"
                name="minimum"
                type="number"
                placeholder="1"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
            <Col sm="12" lg="3">
              <Input
                label="Máximo"
                name="limit"
                type="number"
                placeholder="1"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Input
                label="Pergunta"
                name="question"
                type="text"
                placeholder="Escolha uma opção de carne, Qual o ponto da carne..."
                disabled={complementCategory.is_multistore_child}
              />
            </Col>
          </Row>
          <Row>
            <Select
              name="complements"
              isMulti
              options={complements}
              label="Associar complementos à categoria"
              defaultValue={initialComplements}
              disabled={complementCategory.is_multistore_child}
            />
          </Row>
          <Row>
            <Col md="3">
              {/* <CheckboxInput
                defaultChecked
                label="Disponível no presencial"
                name="available"
              /> */}

              <AreaSwitcher title="Disponível">
                <Switch
                  onChange={handleChangeAvailableCategory}
                  draggable
                  checked={availableCategory}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={27}
                  width={44}
                  handleDiameter={16}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                />
                <span>Disponível</span>
              </AreaSwitcher>
            </Col>
            {/* 
            <Col md="3">
            
              <AreaSwitcher title="Disponível no presencial">
                <Switch
                  onChange={handleChangeAvailableDeliveryCategory}
                  draggable
                  checked={availableDeliveryCategory}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={27}
                  width={44}
                  handleDiameter={16}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                />
                <span>Disponível no delivery</span>
              </AreaSwitcher>
            </Col> */}
          </Row>
          <Row className="mt-2">
            <Col md="3">
              <CheckboxInput
                defaultChecked={complementCategory?.additional}
                label="Adicional"
                name="additional"
                disabled={complementCategory.is_multistore_child}
              />
            </Col>

            <Col md="3">
              <CheckboxInput defaultChecked={complementCategory?.optional} label="Opcional" name="optional" disabled={complementCategory.is_multistore_child} />
            </Col>
            {/* 
            <Col md="3">
              <CheckboxInput
                defaultChecked={complementCategory?.single_choice}
                label="Escolha única"
                name="single_choice"
                disabled={complementCategory.is_multistore_child}
              />
            </Col> */}
          </Row>
          <Row>
            <Col sm="12" md="3">
              <CheckboxInput
                label="Cobrar apenas o mais caro"
                name="more_expensive_only"
                onChange={() => setMoreExpensiveChecked(!moreExpensiveChecked)}
                disabled={useAverageChecked || complementCategory.is_multistore_child}
              />
            </Col>

            <Col sm="12" md="3">
              <CheckboxInput
                label="Cobrar a média do preço"
                name="use_average"
                onChange={() => setUseAverageChecked(!useAverageChecked)}
                disabled={moreExpensiveChecked || complementCategory.is_multistore_child}
              />
            </Col>

          </Row>

          <ButtonsArea>
            <PersonalButton
              color="#FF2C3A"
              message="Cancelar"
              style={{
                marginLeft: 0,
              }}
              onClick={() => {
                toggle();
              }}
            />

            <PersonalButton
              color="#0CCAB4"
              message="Salvar"
              type="submit"
              style={{
                marginRight: 10,
              }}
            />
          </ButtonsArea>
        </Form>
      </InnerArea>
    </Container>
  );
}

export default EditComplementCategoryInsideProduct;
