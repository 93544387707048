/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { MdArrowDropDown, MdDelete, MdEdit } from 'react-icons/md';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { RiArrowLeftRightLine } from 'react-icons/ri';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';

import ReactSelect from 'react-select';

import { FaQuestionCircle } from 'react-icons/fa';
import {
  Container,
  HistoryTable,
  Delete,
  InputsTable,
  DisplayNoneImprimir,
  InputAreaStyled,
  DataPickerArea,
  DatePickerStyled,
  SelectDateButton,
} from './styles';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import formatCompleteDate from '~/services/formatCompleteDate';
import api from '~/services/api';
import TextareaContainer from '~/components/Form/Textarea';
import PrintRecipe from '~/components/Prints/PrintRecipe';

function IntermediaryRow({
  intermediary,
  setIntermediary,
  handleDelete,
  inputs,
  intermediaries,
  setIntermediaryFiltered,
  getInputs,
  filterStringInter,
  filterString,
  isInterInventory,
  interInventory,
  setInterInventory,
}) {
  const componentRef = useRef(null);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [monthYearDate, setMonthYearDate] = useState(new Date());
  const [historyIntermediary, setHistoryIntermediary] = useState(null);
  const [modalEye, setModalEye] = useState(false);
  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
  }, [modalEye]);

  const [modalUnit, setModalUnit] = useState(false);
  const toggleUnit = () => setModalUnit(!modalUnit);

  const [inputQuant, setInputQuant] = useState(
    parseFloat(intermediary.quantidade)
  );
  const [inputZero, setInputZero] = useState(0);
  const [inputInicial, setInputInicial] = useState(
    parseFloat(intermediary.quantidade)
  );
  const [valueToSend, setValueToSend] = useState(
    parseFloat(intermediary.quantidade)
  );

  const [newUnit, setNewUnit] = useState('');
  const [valueNewUnit, setValueNewUnit] = useState(1);

  const inputs_select = inputs.filter(inp => !inp.is_master).map((input) => ({
    value: input.id,
    label: input.name,
    unidade: input.unidade,
  }));
  const inter_select = intermediaries.map((inter) => ({
    value: inter.id,
    label: inter.name,
    unidade: inter.unidade,
  }));
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);

  const [selecteInterInput, setSelectedInterInput] = useState(null);
  const [
    selectedInterIntermediaryInput,
    setSelectedInterIntermediaryInput,
  ] = useState(null);
  const [modalInterInput, setModalInterInput] = useState(false);
  const [
    modalInterIntermediaryInput,
    setModalInterIntermediaryInput,
  ] = useState(false);

  const justificatives = [
    { value: 0, label: 'Perda por validade/vencimento' },
    { value: 1, label: 'Desperdício de produção' },
    { value: 2, label: 'Item Danificado' },
    { value: 3, label: 'Devolução de mercadoria' },
    { value: -1, label: 'Outros' },
  ];

  const [modalYield, setModelYield] = useState(false);
  const toggleYield = () => {
    setModelYield(!modalYield);
  };

  const [selectedJustificative, setSelectedJustificative] = useState(null);

  const toggleModalInterInput = () => {
    setModalInterInput(!modalInterInput);
  };

  const toggleModalInterIntermediaryInput = () => {
    setModalInterIntermediaryInput(!modalInterIntermediaryInput);
  };

  async function insertInputInter(
    selectInput,
    defaultInterValue,
    filterStringInter,
    filterString
  ) {
    const exists = intermediary.intermediary_inputs.filter(
      (int) => int.input.id === selectInput.value
    );

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      try {
        const data = {
          intermediary_id: intermediary.id,
          input_id: selectInput.value,
          quantidade: defaultInterValue.replace(',', '.'),
        };

        const response = await api.put(
          '/restaurants/add-input-to-intermediary',
          data
        );

        setIntermediary(response.data);
        if (filterStringInter === '') {
          setIntermediaryFiltered(response.data);
        } else {
          const filter = response.data.filter((input) =>
            input.name.toLowerCase().startsWith(filterStringInter.toLowerCase())
          );

          setIntermediaryFiltered(filter);
        }
        getInputs(filterString);
        toast.success('Insumo adicionado ao Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao adicionar Insumo a Produto Intermediario.');
      }
    }
  }

  async function deleteInputInter(id) {
    const ok = window.confirm(
      'Tem certeza que deseja excluir a relação entre o produto intermediário e o insumo?'
    );

    if (ok) {
      try {
        const data = {
          intermediary_id: intermediary.id,
          input_id: Number(id),
        };

        const response = await api.post(
          '/restaurants/delete-input-intermediary',
          data
        );

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        getInputs();

        toast.success('Insumo removido do Produto Intermediario.');
      } catch (err) {
        toast.error('Erro ao remover Insumo do Produto Intermediario.');
      }
    }
  }

  async function insertInterInter(
    selectInter,
    defaultInterValue,
    filterStringInter
  ) {
    const exists = intermediary.intermediary_intermediaries.filter(
      (int) => int.intermediary.id === selectInter.value
    );

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      try {
        const data = {
          intermediary_id: intermediary.id,
          new_intermediary_id: selectInter.value,
          quantidade: defaultInterValue.replace(',', '.'),
        };

        const response = await api.put(
          '/restaurants/add-intermediary-to-intermediary',
          data
        );

        setIntermediary(response.data);

        if (filterStringInter === '') {
          setIntermediaryFiltered(response.data);
        } else {
          const filter = response.data.filter((input) =>
            input.name.toLowerCase().startsWith(filterStringInter.toLowerCase())
          );

          setIntermediaryFiltered(filter);
        }

        toast.success(
          'Produto Intermediario adicionado ao Produto Intermediario.'
        );
      } catch (err) {
        toast.error('Erro ao adicionar Produto Intermediario a PI.');
      }
    }
  }

  async function deleteInterInter(id) {
    const ok = window.confirm(
      'Tem certeza que deseja excluir a relação entre os produtos intermediários?'
    );

    if (ok) {
      try {
        const data = {
          intermediary_id: intermediary.id,
          old_intermediary_id: Number(id),
        };

        const response = await api.post(
          '/restaurants/delete-intermediary-intermediary',
          data
        );

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        getInputs();

        toast.success(
          'Produto Intermediario removido do Produto Intermediario.'
        );
      } catch (err) {
        toast.error(
          'Erro ao remover Produto Intermediario do Produto Intermediario.'
        );
      }
    }
  }

  const handleChangeYield = useCallback(
    async (data) => {
      try {
        const handleData = {
          yield_update: parseFloat(data.yield_update || intermediary.yield),
        };
        console.log(data);
        if (!data.yield_update || parseFloat(data.yield_update) <= 0) {
          toast.error('Rendimento deve ser maior que zero.');
        } else {
          const response = await api.put(
            `/restaurants/intermediary/yield/${intermediary.id}`,
            handleData
          );

          setIntermediary(response.data);
          setIntermediaryFiltered(response.data);

          toast.success('Rendimento atualizado com sucesso!');
          setModelYield(false);
        }
      } catch (error) {
        toast.error('Erro ao atualizar rendimento.');
      }
    },
    [intermediary.id]
  );

  // const unidade_options = [
  //   {
  //     value: 'un',
  //     label: 'Unidade',
  //   },
  //   {
  //     value: 'kg',
  //     label: 'Kg',
  //   },
  //   {
  //     value: 'l',
  //     label: 'Litro',
  //   },
  // ];

  let unidade_default;
  if (intermediary.unidade === 'kg') {
    unidade_default = { value: 'kg', label: 'Kg' };
  } else {
    unidade_default = { value: 'un', label: 'Unidade' };
  }

  const handleUpdateInput = useCallback(
    async (data) => {
      try {
        let justificative = data.inputed_justificative || null;

        if (selectedJustificative?.value !== -1) {
          justificative = selectedJustificative?.label;
        }

        const handleData = {
          id: intermediary.id,
          quantidade: valueToSend,
          name: data.name,
          recipe: data.recipe,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          justificative,
        };

        console.log('@@@', data, handleData, justificative);

        if (!valueToSend && valueToSend !== 0) {
          toast.error('Coloque o valor gasto do insumo.');
        } else {
          const response = await api.put(
            '/restaurants/intermediary',
            handleData
          );

          setIntermediary(response.data);
          // document.getElementById('update_button').click();
          getInputs();

          setInputInicial(valueToSend);
          setInputZero(0);
          setInputQuant(valueToSend);
          setSelectedJustificative(null);

          setModal(false);

          toast.success('Produto Intermediario atualizado com sucesso!');
        }
      } catch (error) {
        toast.error('Erro ao atualizar insumo.');
      }
    },
    [valueToSend, intermediary.id, setIntermediary, selectedJustificative]
  );

  const handleChangeUnit = useCallback(async () => {
    try {
      const handleData = {
        intermediary_id: intermediary.id,
        value: valueNewUnit,
        unit_change: newUnit,
      };

      if (!valueNewUnit || parseFloat(valueNewUnit) === 0) {
        toast.error('Valor de conversão deve ser válido e diferente de zero.');
      } else if (newUnit === '') {
        toast.error('Informe a nova unidade.');
      } else {
        await api.put('/restaurants/change-unit-intermediary', handleData);

        // document.getElementById('update_button').click();
        getInputs();
        setValueNewUnit(1);
        setNewUnit('');
        setModalUnit(false);

        toast.success('Unidades convertidas com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao atualizar unidades.');
    }
  }, [newUnit, valueNewUnit, intermediary.id]);

  const handleUpdateIntermediaryInput = useCallback(
    async (data) => {
      try {
        const handleData = {
          quantidade: data.quantidade,
          intermediary_input_id: selecteInterInput.id,
        };

        const response = await api.put(
          '/restaurants/update-intermediary-input',
          handleData
        );

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);

        setModalInterInput(false);

        toast.success('Ficha técnica atualizada!');
      } catch (error) {
        toast.error('Erro ao atualizar ficha técnica.');
      }
    },
    [selecteInterInput, setIntermediary, setIntermediaryFiltered]
  );

  const handleUpdateInterIntermediaryInput = useCallback(
    async (data) => {
      try {
        const handleData = {
          quantidade: data.quantidade,
          intermediary_intermediary_id: selectedInterIntermediaryInput.id,
        };

        const response = await api.put(
          '/restaurants/update-intermediary-intermediary',
          handleData
        );

        setIntermediary(response.data);
        setIntermediaryFiltered(response.data);
        setModalInterIntermediaryInput(false);

        toast.success('Ficha técnica atualizada!');
      } catch (error) {
        toast.error('Erro ao atualizar ficha técnica.');
      }
    },
    [selectedInterIntermediaryInput]
  );

  useEffect(() => {
    if (modalEye) {
      api
        .get('/restaurants/history-intermediaries', {
          params: {
            intermediary_id: intermediary?.id || 0,
            start_date: monthYearDate,
          },
        })
        .then((response) => setHistoryIntermediary(response.data))
        .catch((e) => toast.error('Não foi possível consultar o histórico.'));
    }
  }, [modalEye, monthYearDate]);

  return (
    <>
      <tr>
        <td>{intermediary.name}</td>

        {isInterInventory ? (
          <td>
            <input
              defaultValue={parseFloat(intermediary.quantidade)}
              value={interInventory[intermediary.id]}
              onChange={(e) =>
                setInterInventory({
                  ...interInventory,
                  [intermediary.id]: e.target.value,
                })
              }
              style={{ width: '75px', textAlign: 'center' }}
            />
          </td>
        ) : parseFloat(intermediary.quantidade) > 0 ? (
          <td>
            <strong style={{ color: 'green' }}>
              {parseFloat(intermediary.quantidade).toFixed(4).replace('.', ',')}{' '}
              {intermediary.unidade}
            </strong>
            <button
              type="button"
              style={{
                border: 'none',
                borderRadius: '50%',
                width: 20,
                height: 20,
                background: 'orange',
                marginLeft: 5,
              }}
              onClick={() => toggleUnit()}
            >
              <RiArrowLeftRightLine size={10} color="white" />
            </button>
          </td>
        ) : (
          <td>
            <strong style={{ color: 'red' }}>
              {parseFloat(intermediary.quantidade).toFixed(4).replace('.', ',')}{' '}
              {intermediary.unidade}
            </strong>
            <button
              type="button"
              style={{
                border: 'none',
                borderRadius: '50%',
                width: 20,
                height: 20,
                background: 'orange',
                marginLeft: 5,
              }}
              onClick={() => toggleUnit()}
            >
              <RiArrowLeftRightLine size={10} color="white" />
            </button>
          </td>
        )}

        <td>
          R$
          {parseFloat(intermediary.unitary_price).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          })}
        </td>

        <td>
          {parseFloat(intermediary.quantidade) >= 0
            ? `R$
          ${parseFloat(intermediary.total_value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`
            : 'N/A'}
        </td>

        <td>
          {intermediary.intermediary_inputs.length +
            intermediary.intermediary_intermediaries.length}{' '}
          insumos
        </td>

        <td>
          <div>
            <ReactToPrint
              trigger={() => (
                <Delete>
                  <div>
                    <AiOutlinePrinter size={20} style={{ marginRight: 5 }} />
                  </div>
                </Delete>
              )}
              content={() => componentRef.current}
            />
            <Delete onClick={toggleEye}>
              <AiOutlineEye size={20} color="blue" />
            </Delete>
            <Delete onClick={toggle}>
              <MdEdit size={20} color="#17a2b8" />
            </Delete>
            {!intermediary?.is_multistore_child && (
              <Delete
                onClick={() => handleDelete(intermediary.id, filterStringInter)}
              >
                <MdDelete size={20} />
              </Delete>
            )}
          </div>

          <DisplayNoneImprimir>
            <PrintRecipe intermediary={intermediary} ref={componentRef} />
          </DisplayNoneImprimir>

          <Modal isOpen={modal} toggle={toggle} size="xl">
            <Form onSubmit={handleUpdateInput} initialData={intermediary}>
              <ModalHeader>Editar Produto Intermediário</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col>
                      <Input
                        label="Nome do Insumo"
                        name="name"
                        type="text"
                        disabled={intermediary?.is_multistore_child || false}
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="6">
                      <Input
                        label="Estoque Mínimo"
                        name="minimum_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                    <Col md="6">
                      <Input
                        label="Estoque Ideal"
                        name="ideal_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md="2">
                      <InputAreaStyled>
                        <b>Qtd. Disponível</b>
                        {parseFloat(intermediary.quantidade).toFixed(4)}
                      </InputAreaStyled>
                    </Col>
                    <Col md="3">
                      <InputAreaStyled>
                        <b>Custo Unitário Atual </b>
                        <p>{intermediary.unitary_price}</p>
                      </InputAreaStyled>
                    </Col>
                    <Col md="3">
                      <InputAreaStyled>
                        <b>Valor em Estoque</b>
                        {intermediary.total_value}
                      </InputAreaStyled>
                    </Col>
                    <Col md="4">
                      <InputAreaStyled>
                        <b>Rendimento da Ficha Técnica</b>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {intermediary.yield}{' '}
                          <MdEdit
                            onClick={() => toggleYield()}
                            style={{
                              marginLeft: 10,
                              marginTop: 5,
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </InputAreaStyled>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <Row>
                        <Col md="1">
                          {' '}
                          <button
                            type="button"
                            style={{
                              background: '#FF3939',
                              width: 32,
                              height: 32,
                              position: 'absolute',
                              bottom: 0,
                              left: 10,

                              borderRadius: '50%',
                              border: 'none',
                              marginBottom: 15,
                            }}
                            onClick={() => {
                              setInputQuant(parseFloat(inputQuant) - 1);
                              setInputZero(parseFloat(inputZero) - 1);
                              setValueToSend(
                                inputInicial + parseFloat(inputZero) - 1
                              );
                            }}
                          >
                            -
                          </button>
                        </Col>
                        <Col md="9" style={{ marginLeft: 5 }}>
                          {' '}
                          <Input
                            label="Quantidade"
                            name="quantidade"
                            type="number"
                            step="0.001"
                            value={inputZero}
                            onChange={(e) => {
                              setInputZero(e.target.value);
                              setValueToSend(
                                inputInicial + parseFloat(e.target.value)
                              );
                            }}
                          />
                        </Col>
                        <Col md="1">
                          <button
                            type="button"
                            style={{
                              width: 32,
                              height: 32,
                              position: 'absolute',
                              bottom: 0,
                              left: -8,
                              background: '#3BD2C1',
                              borderRadius: '50%',
                              border: 'none',
                              marginBottom: 15,
                            }}
                            onClick={() => {
                              setInputQuant(parseFloat(inputQuant) + 1);
                              setInputZero(parseFloat(inputZero) + 1);
                              setValueToSend(
                                inputInicial + parseFloat(inputZero) + 1
                              );
                            }}
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col md="4" style={{ marginTop: 10 }}>
                      <Select
                        label="Unidade"
                        name="unidade"
                        options={unidade_options}
                        defaultValue={unidade_default}
                      />
                    </Col> */}

                    <Col md="3" style={{ marginTop: 15, marginLeft: 20 }}>
                      <p style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong>Nova Quantidade:</strong>{' '}
                        <span>
                          {Number(inputZero)
                            ? (inputInicial + parseFloat(inputZero)).toFixed(4)
                            : inputInicial.toFixed(4)}
                        </span>
                      </p>
                    </Col>

                    <Col md="2">
                      <Input
                        label="Estoque Mínimo"
                        name="minimum_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                    <Col md="2">
                      <Input
                        label="Estoque Ideal"
                        name="ideal_stock"
                        type="number"
                        step="0.001"
                      />
                    </Col>
                  </Row>
                  {inputZero && inputZero < 0 ? (
                    <Row>
                      <Col md="6">
                        <ReactSelect
                          placeholder="Selecione a justificativa"
                          options={justificatives}
                          onChange={(e) => {
                            setSelectedJustificative(e);
                          }}
                          value={selectedJustificative}
                          name="justificative"
                        />
                      </Col>

                      {selectedJustificative &&
                        selectedJustificative.value < 0 ? (
                        <Col md="6">
                          <Input
                            style={{
                              marginTop: '-11px',
                              borderRadius: '4px',
                              height: '41px',
                            }}
                            placeholder="Escreva a justificativa"
                            name="inputed_justificative"
                            type="text"
                            id="input"
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col md="12"> </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Col md="12" style={{ marginTop: 10 }}>
                      <p>Ficha Técnica</p>
                    </Col>
                  </Row>
                  {!intermediary?.is_multistore_child && (
                    <Row>
                      <Col md="6">
                        <Select
                          label="Insumos"
                          name="inputs_select"
                          options={inputs_select}
                          placeholder="Selecione"
                          onChange={(e) => setSelectInput(e)}
                          icon={
                            <FaQuestionCircle
                              title="Vincule os insumos usados na receita para produzir seu produto intermediário"
                              color="orange"
                              style={{ marginLeft: 5 }}
                            />
                          }
                        />
                      </Col>
                      <Col md="4">
                        <Input
                          label="Quantidade"
                          name="quantidade"
                          type="text"
                          defaultValue={0}
                          onChange={(e) => {
                            setDefaultInterValue(e.target.value);
                            document.getElementById('add').disabled = false;
                          }}
                          icon={
                            <FaQuestionCircle
                              title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                              color="orange"
                              style={{ marginLeft: 5 }}
                            />
                          }
                        />
                      </Col>
                      <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                        <button
                          type="button"
                          id="add"
                          style={{
                            background: '#3BD2C1',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border: 'none',
                            fontSize: 16,
                          }}
                          onClick={() =>
                            insertInputInter(
                              selectInput,
                              defaultInterValue,
                              filterStringInter,
                              filterString
                            )
                          }
                        >
                          +
                        </button>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md="12">
                      <InputsTable borderless>
                        <thead>
                          <tr>
                            <th>Insumo</th>
                            <th>Gasto Únit.</th>
                            <th>Custo Únit.</th>
                            <th>Gasto total</th>
                            <th>Custo total</th>
                            <th>Disponível</th>
                            <th>Redução de</th>
                            <th> </th>
                          </tr>
                        </thead>

                        <tbody>
                          {intermediary.intermediary_inputs.length > 0 &&
                            intermediary.intermediary_inputs.map((inter) => (
                              <tr key={inter.id}>
                                <td>{inter.input.name}</td>
                                <td style={{ textAlign: 'center' }}>
                                  {parseFloat(inter.quantidade).toFixed(4)}{' '}
                                  {inter.input.unidade}
                                </td>
                                <td>
                                  {' '}
                                  R$
                                  {parseFloat(
                                    inter.input.unitary_price
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {(
                                    parseFloat(inter.quantidade) *
                                    parseFloat(intermediary.yield)
                                  )
                                    .toFixed(3)
                                    .replace('.', ',')}{' '}
                                  {inter.input.unidade}
                                </td>
                                <td>
                                  R$
                                  {parseFloat(
                                    inter.quantidade *
                                    intermediary.yield *
                                    inter.input.unitary_price
                                  ).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    color: 'green',
                                  }}
                                >
                                  {parseFloat(inter.input.quantidade)
                                    .toFixed(4)
                                    .replace('.', ',')}{' '}
                                  {inter.input.unidade}
                                </td>

                                <td style={{ color: 'red' }}>
                                  {inputZero > 0
                                    ? parseFloat(inputZero *
                                      inter.quantidade).toFixed(4)
                                    : 0}{' '}
                                  {inter.input.unidade}
                                </td>

                                {!intermediary?.is_multistore_child && (
                                  <td>
                                    <div>
                                      <Button
                                        style={{
                                          border: 'none',
                                          padding: 5,
                                          margin: 2,
                                          background: 'transparent',
                                          color: '#17a2b8',
                                        }}
                                        onClick={() => {
                                          setSelectedInterInput(inter);
                                          toggleModalInterInput();
                                        }}
                                      >
                                        <MdEdit size={20} />
                                      </Button>
                                      <Button
                                        style={{
                                          border: 'none',
                                          padding: 5,
                                          margin: 2,
                                          background: 'transparent',
                                          color: 'red',
                                        }}
                                        onClick={() =>
                                          deleteInputInter(inter.input.id)
                                        }
                                      >
                                        <MdDelete size={20} />
                                      </Button>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </InputsTable>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div style={{ margin: 5 }} />
                    </Col>
                  </Row>
                  {!intermediary?.is_multistore_child && (
                    <Row>
                      <Col md="6">
                        <Select
                          label="Produto Intermediário"
                          name="inter_select"
                          options={inter_select}
                          placeholder="Selecione"
                          onChange={(e) => setSelectInter(e)}
                          icon={
                            <FaQuestionCircle
                              title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                              color="orange"
                              style={{ marginLeft: 5 }}
                            />
                          }
                        />
                      </Col>
                      <Col md="4">
                        <Input
                          label="Quantidade"
                          name="quantidade"
                          type="text"
                          defaultValue={0}
                          onChange={(e) => {
                            setDefaultInterInterValue(
                              e.target.value.replace(',', '.')
                            );
                            document.getElementById('add1').disabled = false;
                          }}
                          icon={
                            <FaQuestionCircle
                              title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                              color="orange"
                              style={{ marginLeft: 5 }}
                            />
                          }
                        />
                      </Col>
                      <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                        <button
                          type="button"
                          id="add1"
                          style={{
                            background: '#3BD2C1',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border: 'none',
                            fontSize: 16,
                          }}
                          onClick={() =>
                            insertInterInter(
                              selectInter,
                              defaultInterInterValue,
                              filterStringInter
                            )
                          }
                        >
                          +
                        </button>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md="12">
                      <InputsTable borderless>
                        <thead>
                          <tr>
                            <th>Prod. Interm.</th>
                            <th>Gasto Únit.</th>
                            <th>Custo Únit.</th>
                            <th>Gasto total</th>
                            <th>Custo total</th>
                            <th>Disponível</th>
                            <th>Redução de</th>
                            <th> </th>
                          </tr>
                        </thead>

                        <tbody>
                          {intermediary.intermediary_intermediaries.length &&
                            intermediary.intermediary_intermediaries.map(
                              (inter) => (
                                <tr key={inter.id}>
                                  <td>{inter.intermediary.name}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {parseFloat(
                                      (inter.quantidade * intermediary.yield) /
                                      intermediary.yield
                                    ).toFixed(3)}{' '}
                                    {inter.intermediary.unidade}
                                  </td>
                                  <td>
                                    {' '}
                                    R$
                                    {parseFloat(
                                      inter.intermediary.unitary_price
                                    ).toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {(
                                      parseFloat(inter.quantidade) *
                                      parseFloat(intermediary.yield)
                                    )
                                      .toFixed(2)
                                      .replace('.', ',')}{' '}
                                    {inter.intermediary.unidade}
                                  </td>
                                  <td>
                                    R$
                                    {parseFloat(
                                      inter.quantidade *
                                      intermediary.yield *
                                      inter.intermediary.unitary_price
                                    ).toLocaleString('pt-BR', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      color: 'green',
                                    }}
                                  >
                                    {parseFloat(inter.intermediary.quantidade)
                                      .toFixed(4)
                                      .replace('.', ',')}{' '}
                                    {inter.intermediary.unidade}
                                  </td>
                                  <td style={{ color: 'red' }}>
                                    {inputZero > 0
                                      ? parseFloat(inputZero *
                                        inter.quantidade).toFixed(4)
                                      : 0}{' '}
                                    {inter.intermediary.unidade}
                                  </td>
                                  {!intermediary?.is_multistore_child && (
                                    <td>
                                      <div>
                                        <Button
                                          style={{
                                            border: 'none',
                                            padding: 5,
                                            margin: 2,
                                            background: 'transparent',
                                            color: '#17a2b8',
                                          }}
                                          onClick={() => {
                                            setSelectedInterIntermediaryInput(
                                              inter
                                            );
                                            toggleModalInterIntermediaryInput();
                                          }}
                                        >
                                          <MdEdit size={20} />
                                        </Button>
                                        <Button
                                          style={{
                                            border: 'none',
                                            padding: 5,
                                            margin: 2,
                                            background: 'transparent',
                                            color: 'red',
                                          }}
                                          onClick={() =>
                                            deleteInterInter(
                                              inter.intermediary.id
                                            )
                                          }
                                        >
                                          <MdDelete size={20} />
                                        </Button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                        </tbody>
                      </InputsTable>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 10 }}>
                    <Col style={{ marginTop: 10 }}>
                      <p>Receita</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <TextareaContainer
                        name="recipe"
                        placeholder="Salve a receita do produto intermediário"
                        type="text"
                        maxLength="500"
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="primary" type="submit">
                  Salvar
                </Button>{' '}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalEye} toggle={toggleEye} size='lg'>
            <ModalHeader>Histórico do Insumo</ModalHeader>
            <ModalBody>
              <Container>
                <DataPickerArea>
                  <DatePickerStyled
                    selected={monthYearDate}
                    onChange={(date) => {
                      setMonthYearDate(date);
                    }}
                    locale="pt"
                    showMonthYearPicker
                    customInput={
                      <SelectDateButton
                        className="btn-round mr-auto"
                        color="info"
                      >
                        Mês/Ano <MdArrowDropDown color="white" size={20} />
                      </SelectDateButton>
                    }
                  />
                </DataPickerArea>
                <p>
                  Exibindo: {months[monthYearDate.getMonth()]}/
                  {monthYearDate.getFullYear()}
                </p>
                <HistoryTable>
                  <thead>
                    <tr>
                      <th>Operação</th>
                      <th>Produto / Complemento</th>
                      <th>Valor</th>
                      <th>Saldo</th>
                      <th>Data / Hora</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyIntermediary?.map((history) =>
                      history.type === '+' ? (
                        <tr style={{ background: '#D7EED2', color: '#1E3422' }}>
                          <td>
                            {history.type}{' '}
                            {parseFloat(history.quantidade).toFixed(4).replace('.', ',')}{' '}
                            {intermediary.unidade}
                          </td>
                          <td>
                            {history.product && `[P] ${history.product.name}`}
                            {history.complement &&
                              `[C] ${history.complement.name}`}
                            {history.operation === 'canceled_item' &&
                              ' - Cancelado'}
                            {!history.complement &&
                              !history.product &&
                              (history.operation !== 'nfe'
                                ? 'Editado Manualmente'
                                : 'Entrada de Nota Fiscal')}
                          </td>
                          <td>{history.total ? `R$${history.total}` : '-'}</td>
                          <td>
                            {parseFloat(history?.balance)
                              ? parseFloat(history.balance).toFixed(3)
                              : ''}
                          </td>
                          <td>{formatCompleteDate(history.createdAt)}</td>
                        </tr>
                      ) : (
                        <tr style={{ background: '#FBD1C5', color: '#590D12' }}>
                          <td>
                            {history.type}{' '}
                            {parseFloat(history.quantidade).toFixed(4).replace('.', ',')}{' '}
                            {intermediary.unidade}
                          </td>
                          <td>
                            {history.product && `[P] ${history.product.name}`}
                            {history.complement &&
                              `[C] ${history.complement.name}`}
                            {history.operation === 'canceled_item' &&
                              ` - Cancelado`}
                            {!history.complement &&
                              !history.product &&
                              'Editado Manualmente'}
                          </td>
                          <td>{history.total ? `R$${history.total}` : '-'}</td>
                          <td>
                            {parseFloat(history?.balance)
                              ? parseFloat(history.balance).toFixed(3)
                              : ''}
                          </td>
                          <td>{formatCompleteDate(history.createdAt)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HistoryTable>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleEye}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalUnit} toggle={toggleUnit}>
            <ModalHeader>Converter Unidade</ModalHeader>
            <ModalBody>
              <Container>
                <Form>
                  <Row>
                    <Col md="4" style={{ margin: 'auto' }}>
                      1 {intermediary.unidade} equivale a:
                    </Col>
                    <Col md="4">
                      <Input
                        type="number"
                        step="0.1"
                        label="Valor"
                        name="value_change"
                        id="value_change"
                        value={valueNewUnit}
                        placeholder="Valor de conversão"
                        onChange={(e) => setValueNewUnit(e.target.value)}
                      />
                    </Col>
                    <Col md="4">
                      <Input
                        type="text"
                        name="unit_change"
                        placeholder="ex: KG"
                        label="Unidade"
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Atualizado: </strong> {intermediary.quantidade}{' '}
                      {intermediary.unidade} {'=>'}{' '}
                      {(parseFloat(intermediary.quantidade) * valueNewUnit)
                        .toFixed(3)
                        .replace('.', ',')}{' '}
                      {newUnit || 'nova unidade'}
                    </Col>
                  </Row>
                </Form>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => handleChangeUnit()}>
                Converter
              </Button>
              <Button color="danger" onClick={toggleUnit}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalYield} toggle={toggleYield}>
            <Form onSubmit={handleChangeYield}>
              <ModalHeader>Alterar rendimento</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md="12">
                      <Input
                        type="text"
                        label="Rendimento"
                        name="yield_update"
                        defaultValue={intermediary.yield}
                        placeholder="Rendimento"
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggleYield}>
                  Fechar
                </Button>
                <Button color="success" type="submit">
                  Salvar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalInterInput} toggle={toggleModalInterInput}>
            <Form
              onSubmit={handleUpdateIntermediaryInput}
              initialData={selecteInterInput}
            >
              <ModalHeader>
                Editar Ficha Técnica do Produto Intermediário
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md="6">
                      <Input
                        label="Insumo:"
                        name="input.name"
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        label="Gasto Total:"
                        name="quantidade"
                        type="text"
                        defaultValue={(
                          parseFloat(selecteInterInput?.quantidade) *
                          parseFloat(intermediary.yield)
                        ).toFixed(2)}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggleModalInterInput}>
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={toggleModalInterInput}
                >
                  Salvar
                </Button>{' '}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={modalInterIntermediaryInput}
            toggle={toggleModalInterIntermediaryInput}
          >
            <Form
              onSubmit={handleUpdateInterIntermediaryInput}
              initialData={selectedInterIntermediaryInput}
            >
              <ModalHeader>
                Editar Ficha Técnica do Produto Intermediário
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md="6">
                      <Input
                        label="Produto intermediário:"
                        name="intermediary.name"
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        label="Gasto Total:"
                        name="quantidade"
                        type="text"
                        defaultValue={(
                          parseFloat(
                            selectedInterIntermediaryInput?.quantidade
                          ) * parseFloat(intermediary.yield)
                        ).toFixed(2)}
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={toggleModalInterIntermediaryInput}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={toggleModalInterIntermediaryInput}
                >
                  Salvar
                </Button>{' '}
              </ModalFooter>
            </Form>
          </Modal>
        </td>
      </tr>
    </>
  );
}

export default IntermediaryRow;
