import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import formatCompleteDate from '~/services/formatCompleteDate';
import { Container, Content, NavLinkSpan, SessionsTable } from './styles';
import TableOperation from './TableOperation';
import TableAvailable from './TableAvailable';
import TableDelivery from './TableDelivery';
import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function BillsOperation(
  {
    getSessions,
    minimalSessions,
    getMinimalSessions,
    setMinimalSessions,
    inicialDate,
    finalDate,
    getTablesAvailable,
    tablesAvailable,
    updateOpeninBoxOperation,
    orderByTableDate,
    handleSearchInputTables,
    setTablesFilteredSearch,
    setAllTablesAvailable,
    setCount,
    hasFilteredSessions,
    handleUpdate,
    sessionsInCache,
    activeTab,
  },
  ref
) {
  const { websocketManager, user } = useAuth();

  const sessionsOpen = minimalSessions.filter(
    (session) => session.status === 'open' && !session.is_delivery
  );

  const sessionsClosing = minimalSessions.filter(
    (session) => session.status === 'closing'
  );

  const [renderState, setRenderState] = useState();
  const [inputValue, setInputValue] = useState('');

  function setInput() {
    setInputValue('');
  }

  useImperativeHandle(ref, () => {
    return {
      setInput,
    };
  });

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(2, (data) => {
        if (data.type === 'table-closing') {
          const session = data.item;

          setMinimalSessions((state) => {
            const updatedSessions = [...state];
            const idx = updatedSessions.findIndex((s) => s.id === session.id);

            if (idx !== -1) {
              updatedSessions.splice(idx, 1, session);
            } else {
              updatedSessions.push(session);
            }

            return updatedSessions;
          });
        } else if (data.type === 'new-session-payment') {
          getMinimalSessions();
        } else if (data.type === 'new-transfer') {
          getTablesAvailable();
        } else if (data.type === 'table-finished') {
          getTablesAvailable();
        }
      });
    }
  }, [
    setMinimalSessions,
    websocketManager,
    getMinimalSessions,
    getTablesAvailable,
  ]);

  return (
    <Container
      style={{ display: 'flex', flexDirection: 'column' }}
      isFirst={activeTab === 0}
    >
      <input
        placeholder="Busque pelo número da mesa"
        onChange={(e) => {
          handleSearchInputTables(e.target.value);
          setInputValue(e.target.value);
        }}
        value={inputValue}
      />

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <Content>
            {sessionsClosing &&
              sessionsClosing.map((session) => (
                <TableOperation
                  session={session}
                  sessionId={session.id}
                  key={session.id}
                  getMinimalSessions={getMinimalSessions}
                  setRenderState={setRenderState}
                  setMinimalSessions={setMinimalSessions}
                  inicialDate={inicialDate}
                  finalDate={finalDate}
                  type="closing"
                  getTablesAvailable={getTablesAvailable}
                  minimalSessions={minimalSessions}
                  orderByTableDate={orderByTableDate}
                  updateOpeninBoxOperation={updateOpeninBoxOperation}
                  setInputValue={setInputValue}
                  setTablesFilteredSearch={setTablesFilteredSearch}
                  setAllTablesAvailable={setAllTablesAvailable}
                  handleSearchInputTables={handleSearchInputTables}
                  hasFilteredSessions={hasFilteredSessions}
                />
              ))}
            {sessionsOpen &&
              sessionsOpen.map((session) => (
                <TableOperation
                  session={session}
                  sessionId={session.id}
                  key={session.id}
                  getSessions={getSessions}
                  getMinimalSessions={getMinimalSessions}
                  setRenderState={setRenderState}
                  setMinimalSessions={setMinimalSessions}
                  inicialDate={inicialDate}
                  finalDate={finalDate}
                  type="open"
                  getTablesAvailable={getTablesAvailable}
                  updateOpeninBoxOperation={updateOpeninBoxOperation}
                  orderByTableDate={orderByTableDate}
                  minimalSessions={minimalSessions}
                  setTablesFilteredSearch={setTablesFilteredSearch}
                  setAllTablesAvailable={setAllTablesAvailable}
                  setInputValue={setInputValue}
                  handleSearchInputTables={handleSearchInputTables}
                  hasFilteredSessions={hasFilteredSessions}
                />
              ))}

            {user?.delivery_info?.is_delivery_allowed &&
              tablesAvailable &&
              tablesAvailable
                .filter((table) => table.table_type === 'delivery')
                .map((session) => (
                  <TableDelivery
                    session={session}
                    sessionId={null}
                    key={session.id}
                    getMinimalSessions={getMinimalSessions}
                    setMinimalSessions={setMinimalSessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    type="available"
                    getTablesAvailable={getTablesAvailable}
                    handleSearchInputTables={handleSearchInputTables}
                    setCount={setCount}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setAllTablesAvailable={setAllTablesAvailable}
                  />
                ))}

            {tablesAvailable &&
              tablesAvailable
                .filter((table) => table.table_type === 'balcony')
                .map((session) => (
                  <TableAvailable
                    session={session}
                    sessionId={null}
                    key={session.id}
                    getMinimalSessions={getMinimalSessions}
                    setMinimalSessions={setMinimalSessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    type={session.table_type}
                    getTablesAvailable={getTablesAvailable}
                    handleSearchInputTables={handleSearchInputTables}
                    setCount={setCount}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setAllTablesAvailable={setAllTablesAvailable}
                    handleUpdate={handleUpdate}
                  />
                ))}

            {tablesAvailable &&
              tablesAvailable
                .filter((table) => table.table_type === 'prepaid')
                .map((session) => (
                  <TableAvailable
                    session={session}
                    sessionId={null}
                    key={session.id}
                    getMinimalSessions={getMinimalSessions}
                    setMinimalSessions={setMinimalSessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    type={session.table_type}
                    getTablesAvailable={getTablesAvailable}
                    handleSearchInputTables={handleSearchInputTables}
                    setCount={setCount}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setAllTablesAvailable={setAllTablesAvailable}
                    handleUpdate={handleUpdate}
                  />
                ))}

            {tablesAvailable &&
              tablesAvailable
                .filter(
                  (table) =>
                    table.table_type === 'table' ||
                    table.table_type === 'command' ||
                    table.table_type === 'lounge'
                )
                .map((session) => (
                  <TableAvailable
                    session={session}
                    sessionId={null}
                    key={session.id}
                    getMinimalSessions={getMinimalSessions}
                    setMinimalSessions={setMinimalSessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    type={session.table_type}
                    getTablesAvailable={getTablesAvailable}
                    handleSearchInputTables={handleSearchInputTables}
                    setCount={setCount}
                    setInputValue={setInputValue}
                    setTablesFilteredSearch={setTablesFilteredSearch}
                    setAllTablesAvailable={setAllTablesAvailable}
                    handleUpdate={handleUpdate}
                  />
                ))}
          </Content>
        </TabPane>

        <TabPane tabId={1}>
          <p style={{ marginLeft: 15, marginTop: 15 }}>
            Acompanhe aqui suas comandas caso sua conexão com a internet seja
            interrompida
          </p>
          <p style={{ marginLeft: 15, marginTop: 5 }}>
            <strong>Última atualização:</strong>{' '}
            {sessionsInCache?.updated_at
              ? formatCompleteDate(sessionsInCache.updated_at)
              : '-'}{' '}
          </p>

          <SessionsTable borderless>
            <thead>
              <tr>
                <th>Mesa/Comanda</th>
                <th>Status</th>
                <th>Total</th>
                <th>Pago</th>
                <th>Horário de abertura</th>
              </tr>
            </thead>
            <tbody>
              {sessionsInCache?.sessions?.map((session) => (
                <>
                  <tr>
                    <td>
                      <strong>{session.table_number}</strong>
                    </td>
                    <td>
                      <strong>
                        {session.status === 'open' ? 'Aberta' : 'Em Fechamento'}
                      </strong>
                    </td>
                    <td>
                      <strong>R${session.total_service_price}</strong>
                    </td>
                    <td>
                      <strong>R${session.paid?.toFixed(2)}</strong>
                    </td>
                    <td>
                      <strong>{formatCompleteDate(session.start_time)}</strong>
                    </td>
                  </tr>
                  {session.bills.map((bill) => (
                    <tr>
                      <td />
                      <td>{bill.phone}</td>
                      <td>R${bill.total}</td>
                      <td />
                      <td />
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </SessionsTable>
        </TabPane>
      </TabContent>
    </Container>
  );
}

export default forwardRef(BillsOperation);
