import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  addHours,
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subHours,
} from 'date-fns';

import { MdArrowDropDown, MdKeyboardArrowRight } from 'react-icons/md';
import { CardBody, Card, Spinner, Collapse, Row, Col, Input } from 'reactstrap';
import { Form } from '@unform/web';
import { FaDownload } from 'react-icons/fa';
import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import {
  CCTableHeader,
  CollapsableTd,
  Container,
  CostCenterTable,
  EarningRow,
  Header,
  SubCategoryRow,
  ErrorContainer,
  SelectDateButton,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  DatePickerStyled,
  DataPickerArea,
  ButtonsSelectDate,
} from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import { generateEarningsSheetXLSX } from '~/services/generateEarningsSheet';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Invoicing() {
  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const [inicialFormat, setInicialFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [finalFormat, setFinalFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [monthYearDate, setMonthYearDate] = useState(null);
  const [rangeOn, setRangeOn] = useState(true);
  const [payments, setPayments] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [collapse, setCollapse] = useState([]);

  const [loadingDre, setLoadingDre] = useState(false);

  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [hourPhrase, setHourPhrase] = useState(phrase);
  const formRef = useRef(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const isDate = typeof date.getMonth === 'function';
    const inicial = startOfMonth(isDate ? date : new Date());
    const final = endOfMonth(isDate ? date : new Date());

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  const [ifoodPayments, setIfoodPayments] = useState([]);
  const [deliveryPayments, setDeliveryPayments] = useState([]);
  const [localPayments, setLocalPayments] = useState([]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
      },
    });
    setPayments(response.data.totalPaymentsReport);
    setExportData(response.data);

    const localPay = response.data.localPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    const deliveryPay = response.data.deliveryPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    const ifoodPay = response.data.ifoodPaymentsReport.map((item) =>
      item.payments.reduce((accum, curr) => {
        return accum + Number(curr.payment_value);
      }, 0)
    );

    setIfoodPayments(ifoodPay);
    setDeliveryPayments(deliveryPay);
    setLocalPayments(localPay);
  }, [finalDate, inicialDate]);

  const formatValue = (value) => {
    if (value === null) {
      value = 0;
    }
    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateEarningsSheetXLSX(exportData);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Faturamento(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsInvoicing'}`
      );

      const { can_read } = response.data.ReportsInvoicing;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  function searchTable(table, number) {
    switch (table) {
      case 'delivery':
        return 'Delivery';
        break;
      case 'balcony':
        return 'Balcão';
        break;
      case 'table':
        return `Mesa ${number}`;
        break;
      default:
        break;
    }
  }

  // useEffect(() => {
  //   console.log('inicial > ', inicialDate);
  //   console.log('final > ', finalDate);
  // }, [inicialDate, finalDate]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <h2>Relatório de Faturamento</h2>
        <p>Filtro referente a data que foi informado o pagamento.</p>
      </Header>
      <ButtonsSelectDate>
        <p>{hourPhrase}</p>
        <div style={{ display: 'flex' }}>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setToday}
            color="info"
          >
            Hoje
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setWeek}
            color="info"
          >
            Essa semana
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setMonth}
            color="info"
          >
            Esse mês
          </SelectDateButton>
          <DataPickerArea>
            <DatePickerStyled
              selected={monthYearDate}
              onChange={(date) => {
                setMonthYearDate(date);
                setMonth(date);
                setRangeOn(true);
              }}
              locale="pt"
              showMonthYearPicker
              customInput={
                <SelectDateButton className="btn-round mr-auto" color="info">
                  Mês/Ano <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              }
            />
          </DataPickerArea>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={toggle}
            color="info"
          >
            Selecionar Período
            <MdArrowDropDown color="white" size={20} />
          </SelectDateButton>
        </div>
      </ButtonsSelectDate>

      <Row>
        <Col md="12">
          <div style={{ float: 'right', marginRight: '15px' }}>
            <Collapse isOpen={isOpen}>
              <Form onSubmit={handleChangeDate} ref={formRef}>
                <DateSelectorDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Inicial"
                      type="datetime-local"
                      name="initialDate"
                      onChange={(e) => setInitialPre(e.target.value)}
                      defaultValue={defaultInicial}
                      // className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{fontSize: '1rem'}}
                    />
                  </DateDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Final"
                      type="datetime-local"
                      name="finalDate"
                      onChange={(e) => setFinalPre(e.target.value)}
                      defaultValue={defaultFinal}
                      // className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      step="1"
                      style={{fontSize: '1rem'}}
                    />
                  </DateDiv>
                  <div>
                    <SubmitDate onClick="submit">Filtrar</SubmitDate>
                  </div>
                </DateSelectorDiv>
              </Form>
            </Collapse>
          </div>
        </Col>
      </Row>
      <Card>
        <CardBody style={{ textAlign: loadingDre && 'center', paddingTop: 50 }}>
          <button
            style={{
              fontSize: '12px',
              height: '30px',
              padding: '0 12px',
              border: 'none',
              backgroundColor: '#04bfbf',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
            }}
            onClick={() => handleDownloadSheet()}
          >
            <FaDownload style={{ marginRight: '3px' }} /> Baixar planilha
          </button>
          {loadingDre ? (
            <Spinner />
          ) : (
            <CostCenterTable>
              <CCTableHeader>
                <tr>
                  <th
                    style={{
                      padding: '12px 7px',
                      color: '#525f7f',
                      textAlign: 'left',
                    }}
                  >
                    Método
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Total
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Presencial
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Delivery
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Ifood
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Taxa
                  </th>
                  <th style={{ padding: '12px 7px', color: '#525f7f' }}>
                    Líquido
                  </th>
                </tr>
              </CCTableHeader>
              <tbody>
                {payments.length > 0 && (
                  <tr
                    style={{
                      fontWeight: 'bold',
                      borderBottom: '1px solid lightgrey',
                    }}
                  >
                    <td style={{ padding: '12px 7px' }}>TOTAL</td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, pays) =>
                            acc +
                            pays.payments.reduce(
                              (acc2, p) => acc2 + parseFloat(p.payment_value),
                              0
                            ),
                          0
                        )
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        localPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        deliveryPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        ifoodPayments.reduce((acc, p) => acc + p, 0)
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, p) => acc + parseFloat(p.tax_payments_price),
                          0
                        )
                      )}
                    </td>
                    <td style={{ padding: '12px 7px' }}>
                      R$
                      {formatValue(
                        payments.reduce(
                          (acc, p) => acc + parseFloat(p.liquid_payments_price),
                          0
                        )
                      )}
                    </td>
                  </tr>
                )}
                {payments.map((p, ind) => (
                  <>
                    <EarningRow
                      onClick={() => {
                        handleCollapse(p.id);
                      }}
                    >
                      <td>
                        <MdKeyboardArrowRight
                          style={{
                            transition: 'all 0.2s',
                            transform: collapse.includes(p.id)
                              ? 'rotate(90deg)'
                              : 'rotate(0)',
                          }}
                        />{' '}
                        {p.name}
                      </td>
                      <td>R$ {formatValue(p.total_payments_price)}</td>
                      <td>R${formatValue(localPayments[ind] || 0)}</td>
                      <td> R$ {formatValue(deliveryPayments[ind] || 0)} </td>
                      <td> R$ {formatValue(ifoodPayments[ind] || 0)} </td>
                      <td>R$ {p.tax_payments_price}</td>
                      <td>R$ {p.liquid_payments_price}</td>
                    </EarningRow>

                    <>
                      {p.payments.map((item) => {
                        const check = collapse.includes(p.id);
                        if (item.is_food) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {item.payment_value}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }

                        if (
                          item.table_type === 'table' ||
                          item.table_type === 'balcony'
                        ) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {item.payment_value}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }

                        if (item.table_type === 'delivery' && !item.is_food) {
                          return (
                            <SubCategoryRow>
                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  {item.is_food
                                    ? 'Ifood'
                                    : searchTable(
                                        item.table_type,
                                        item.table_number
                                      )}{' '}
                                  - {formatCompleteDate(item.created_at)}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}>
                                  R$ {item.payment_value}
                                </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>

                              <CollapsableTd check={check}>
                                <Collapse isOpen={check}> - </Collapse>
                              </CollapsableTd>
                            </SubCategoryRow>
                          );
                        }
                      })}
                    </>
                  </>
                ))}
              </tbody>
            </CostCenterTable>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default Invoicing;
