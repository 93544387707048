import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';

import { FaQuestion, FaDownload } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';

import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { Input } from '@material-ui/core';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfMonth,
  endOfMonth,
  addHours,
  subDays,
  differenceInDays,
} from 'date-fns';

import { Row, Col, Collapse } from 'reactstrap';

import {
  Card,
  Container,
  Header,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  TableReport,
  ButtonsSelectDate,
  DataPickerArea,
  DatePickerStyled,
  DownloadSheetButton,
} from './styles';

import CreateClient from './CreateClient';

import Select from '~/components/Form/SelectInput';
import ClientsRow from '~/components/Reports/ClientsRow';
import PersonalButton from '~/components/Buttons/PersonalButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import { generateSheet } from '~/services/SheetGenerate/generateClientsSheet';

import { useAuth } from '~/context/AuthContext';

ReactModal.setAppElement('#root');

function ReportsClient() {
  const [rangeOn, setRangeOn] = useState(true);
  const [report, setReport] = useState([]);
  const [reportFiltered, setReportFiltered] = useState([]);
  const formRef = useRef(null);

  const { user } = useAuth();
  const { has_service_tax } = user;

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subDays(subHours(new Date(), 3), 7)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(
    `De ${inicialFormat} até ${finalFormat}`
  );

  const [monthYearDate, setMonthYearDate] = useState(null);

  const [showClientCreateModal, setShowClientCreateModal] = useState(false);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setYesterday() {
    setRangeOn(true);
    const inicial = startOfDay(subDays(new Date(), 1));
    const final = endOfDay(subDays(new Date(), 1));

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
      const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
      const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
      const phrase = `De ${inicialFormat} até ${finalFormat}`;
      setHourPhrase(phrase);
    }
  }

  const [permission, setPermission] = useState(false);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsClients'}`
      );

      const { can_read } = response.data.ReportsClients;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const getReport = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/clients/report', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setReport(response.data);
      setReportFiltered(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    if (permission) {
      getReport();
    }
  }, [getReport, permission]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [inputValue, setInputValue] = useState('');
  const options_filter = [
    { value: 'by_last_visit', label: 'Última Visita mais Recente' },
    {
      value: 'by_total',
      label: 'Mais gastou até hoje',
    },
  ];

  async function handleSearchInput(target) {
    const pos = target.indexOf('_');

    const phone = pos !== -1 
      ? target.substr(0, pos)
      : target;

    const reportFiltered = report.filter((report) => {
      const report_phone = report?.phone || '';

      return report_phone.startsWith(phone);
    });

    setReportFiltered(reportFiltered);
  }

  async function handleFilter(filter) {
    if (filter === 'by_last_visit') {
      const newFiltered = reportFiltered.sort((a, b) => {
        return new Date(b.last_visit) - new Date(a.last_visit);
      });

      setReportFiltered(newFiltered);
      setReport(newFiltered);
    } else {
      const newFiltered = reportFiltered.sort((a, b) => {
        return b.total - a.total;
      });

      setReport(newFiltered);
      setReportFiltered(newFiltered);
    }
  }

  const handleDownloadSheet = () => {
    try {
      const sheet = generateSheet(inicialDate, finalDate, reportFiltered);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Clientes(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <ReactModal
        onRequestClose={() => setShowClientCreateModal(false)}
        isOpen={showClientCreateModal}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <CreateClient onExit={() => setShowClientCreateModal(false)} />
      </ReactModal>

      <Header>
        <div>
          <h2 style={{ marginBottom: '15px' }}>
            Relatório de Clientes
          </h2>
          <p>Acompanhe seus clientes!</p>
        </div>

        <PersonalButton
          color="#FF2C3A"
          message="+ Cadastrar cliente"
          style={{ margin: '0px' }}
          onClick={() => setShowClientCreateModal(true)}
        />
      </Header>
      <ButtonsSelectDate>
        <p>{hourPhrase}</p>
        <div>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setToday}
            color="info"
          >
            Hoje
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setYesterday}
            color="info"
          >
            Ontem
          </SelectDateButton>
          <DataPickerArea>
            <DatePickerStyled
              selected={monthYearDate}
              onChange={(date) => {
                setMonthYearDate(date);
                setMonth(date);
                setRangeOn(true);
              }}
              locale="pt"
              showMonthYearPicker
              customInput={
                <SelectDateButton className="btn-round mr-auto" color="info">
                  Mês/Ano
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              }
            />
          </DataPickerArea>

          <SelectDateButton
            className="btn-round mr-auto"
            onClick={toggle}
            color="info"
          >
            Selecionar Período
            <MdArrowDropDown color="white" size={20} />
          </SelectDateButton>
          <FaQuestion
            color="#ff2c3a"
            title="Selecione o intervalo entre as datas com máximo de 31 dias"
            size={12}
            style={{ marginTop: -45, marginLeft: -10, cursor: 'help' }}
          />
        </div>
      </ButtonsSelectDate>

      <div>
        <Collapse isOpen={isOpen}>
          <Form
            onSubmit={handleChangeDate}
            ref={formRef}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <DateSelectorDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Inicial"
                  type="datetime-local"
                  name="initialDate"
                  onChange={(e) => setInitialPre(e.target.value)}
                  defaultValue={defaultInicial}
                  className="data"
                />
              </DateDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Final"
                  type="datetime-local"
                  name="finalDate"
                  onChange={(e) => setFinalPre(e.target.value)}
                  defaultValue={defaultFinal}
                  className="data"
                  step="1"
                />
              </DateDiv>
              <div>
                <SubmitDate type="submit">Filtrar</SubmitDate>
              </div>
            </DateSelectorDiv>
          </Form>
        </Collapse>
        <p
          style={{
            marginTop: -25,
            color: 'red',
            display: rangeOn ? 'none' : 'block',
          }}
        >
          Favor selecionar o intervalo máximo de 31 dias entre as datas.
        </p>
      </div>

      <Card>
        <Row style={{ marginBottom: '5px' }}>
          <Col md="4">
            <InputMask
              mask="(99) 99999-9999"
              onChange={(e) => {
                handleSearchInput(e.target.value);
                setInputValue(e.target.value);
              }}
              value={inputValue}
            >
              <input
                placeholder="Busque pelo telefone"
                style={{height: '40px', borderRadius: '4px'}}
              />
            </InputMask>
          </Col>
          <Col md="4">
            <Form>
              <Select
                name="filter_options"
                placeholder="Filtrar por:"
                options={options_filter}
                defaultValue={{
                  value: 'by_last_visit',
                  label: 'Última Visita mais Recente',
                }}
                onChange={(e) => handleFilter(e.value)}
                containerMargin="0px"
              />
            </Form>
          </Col>
          <Col md="4" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <DownloadSheetButton onClick={handleDownloadSheet}>
              <FaDownload style={{ marginRight: '3px' }} />
              Baixar planilha
            </DownloadSheetButton>
          </Col>
        </Row>
        <Row>
          <TableReport borderless>
            <thead>
              <tr>
                <th>Número</th>
                <th>Nome</th>
                {/* <th>E-mail</th> */}
                <th>Gastou até hoje</th>
                {has_service_tax && <th>Gastou até hoje (com taxas)</th>}
                <th>Visitas</th>
                <th>Última Avaliação</th>
                <th>Última Visita</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {reportFiltered.map((report, id) => (
                <ClientsRow
                  key={`cr:${id}`}
                  report={report}
                  has_service_tax={has_service_tax}
                />
              ))}
            </tbody>
          </TableReport>
        </Row>
      </Card>
    </Container>
  );
}

export default ReportsClient;
