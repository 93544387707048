import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';

import { ButtonContainer } from './styles';

export default function SentEmailButton({ ...rest }) {
  return (
    <ButtonContainer type="button" {...rest}>
      <HiOutlineMail color="#fff" size={16} />
      <span>ENVIAR XML POR E-MAIL</span>
    </ButtonContainer>
  );
}
