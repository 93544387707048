import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

// import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';

import Products from '../pages/Products';
import CreateProduct from '../pages/Products/Create';
import EditProduct from '../pages/Products/Edit';

import ProductCategories from '../pages/Products/Categories';
import StoreProductCategories from '../pages/Products/Categories/Store';
import EditProductCategories from '../pages/Products/Categories/Edit';

import ProductMenu from '../pages/Products/Menu';
import ProductComplements from '../pages/Products/Complements';
import CreateProductComplements from '../pages/Products/Complements/Create';
import EditComplements from '../pages/Products/Complements/Edit';
import OrderProducts from '../pages/Products/OrderProducts';
import OrderProductsCategoryItem from '../pages/Products/OrderProducts/CategoryItem';
import OrderProductCategory from '../pages/Products/OrderProductCategory';

import ComplementsCategories from '../pages/Products/ComplementsCategories';
import CreateComplementsCategories from '../pages/Products/ComplementsCategories/Create';
import EditComplementsCategories from '../pages/Products/ComplementsCategories/Edit';

import Tables from '../pages/Tables';

import Homepage from '../pages/Homepage';
import Orders from '../pages/Orders';
import Operation from '../pages/Operation';
import Kds from '~/pages/Kds';

import Transfers from '../pages/Transfers';

import CreateTables from '../pages/Tables/Create';
import EditTables from '../pages/Tables/Edit';
import ViewTables from '../pages/Tables/View';
import ViewBills from '../pages/Bills/View';
import CloseSolicitation from '../pages/Bills/CloseSolicitation';

import PaymentForm from '../pages/PaymentForm';
import StorePayment from '../pages/PaymentForm/StorePayment';
import Box from '../pages/Box';

import ReportsProducts from '../pages/Reports/ReportsProducts';
import PrintReportsProducts from '../pages/Reports/PrintReportsProducts';
import ReportsPrincipal from '../pages/Reports/ReportsPrincipal';
import ReportsRate from '../pages/Reports/ReportsRate';
import ReportsOrderCanceled from '../pages/Reports/ReportsOrderCanceled';
import ReportsDiscount from '../pages/Reports/ReportsDiscount';
import ReportsNew from '../pages/Reports/ReportsNew';
import ReportOpeningBox from '~/pages/Reports/ReportOpeningBox';

import NFCeHistory from '~pages/Fiscal/NFCeHistory';
import Nfe from '~/pages/Fiscal/NFeManifest';
import NfeEntry from '~pages/Fiscal/NFeEntry';
import TransferInputs from '~/pages/Fiscal/TransferInputs';
import PrintNFePage from '../pages/PrintNFePage';

import Waiter from '../pages/Waiter';
import CreateWaiter from '../pages/Waiter/Create';
import EditWaiter from '../pages/Waiter/Edit';

import Users from '~/pages/Users';

import TableSessions from '../pages/TableSessions';

import Settings from '../pages/Settings';
import EditPrinter from '../pages/Settings/EditPrinter';
import Inputs from '../pages/Inputs';

import Banks from '../pages/Financial/Banks';
import AuditCashier from '../pages/Financial/AuditCashier';
import Invoices from '~/pages/Financial/Invoices';
import OpeningBox from '~/pages/OpeningBox';
import UserProfile from '~/pages/Users/UserProfile';
import Suppliers from '~/pages/Financial/Suppliers';
import PaymentsMethods from '~/pages/Financial/PaymentMethods';
import CostCenters from '~/pages/Financial/CostCenters';
import Transactions from '~/pages/Financial/Transactions';
import ReportsClient from '~/pages/Reports/ReportsClient';

import DeliveryConfig from '../pages/Delivery/Config';
import DeliveryAddresses from '../pages/Delivery/Addresses';
import Scheduling from '~/pages/Delivery/Scheduling';

import Tickets from '../pages/Delivery/Coupons';
import EditTicket from '../pages/Delivery/Coupons/Edit';
import NewTicket from '../pages/Delivery/Coupons/Store';
import DeliveryOrdersPage from '~/pages/Delivery/DeliveryOrdersPage';
import OpeningHours from '~/pages/Delivery/OpeningHours';
import Motoboy from '~/pages/Motoboys';
import CreateMotoboy from '~/pages/Motoboys/Create';
import EditMotoboy from '~/pages/Motoboys/Edit';
import ReportsExpenses from '~/pages/Reports/ReportsExpenses';

import Tasks from '~/pages/Tasks';
import Dre from '~/pages/Financial/DRE';
import Invoicing from '~/pages/Reports/ReportsInvoicing';
import NFeDevolucao from '~/pages/Fiscal/NFeDevolucao';
import { ServiceTax } from '~/pages/Reports/ServiceTaxReports';
import FiscalGroups from '~/pages/Fiscal/FiscalGroups';
import IfoodRelation from '~/pages/IfoodRelation';
import Kitchen from '~/pages/Kds/Kitchen';

import StoneDevices from '~/pages/StoneDevices';
import NFeAvulsa from '~/pages/Fiscal/NFeAvulsa';
import Dfc from '~/pages/Financial/DFC';
import Help from '~/pages/Help';
import ViewDeliveryBills from '~/pages/DeliveryBills/View';
import FiscalRelation from '~/pages/Fiscal/FiscalRelation';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/products/menu" component={ProductMenu} isPrivate />

      <Route path="/products/inputs" component={Inputs} isPrivate />

      <Route
        path="/products/categories/new"
        component={StoreProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories/edit"
        component={EditProductCategories}
        isPrivate
      />
      <Route
        path="/products/categories"
        component={ProductCategories}
        isPrivate
      />
      <Route
        path="/products/new/:category_id"
        component={CreateProduct}
        isPrivate
      />
      <Route path="/products/edit" component={EditProduct} isPrivate />

      <Route path="/products/ifood" component={IfoodRelation} isPrivate />
      <Route path="/products/fiscal" component={FiscalRelation} isPrivate />

      <Route
        path="/products/complements/new"
        component={CreateProductComplements}
        isPrivate
      />
      <Route
        path="/products/complements/edit"
        component={EditComplements}
        isPrivate
      />
      <Route
        path="/products/complements"
        component={ProductComplements}
        isPrivate
      />

      <Route
        path="/products/complement-categories/new"
        component={CreateComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories/edit"
        component={EditComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/complement-categories"
        component={ComplementsCategories}
        isPrivate
      />
      <Route
        path="/products/order-products"
        exact
        component={OrderProducts}
        isPrivate
      />
      <Route
        path="/products/order-product-categories"
        component={OrderProductCategory}
        isPrivate
      />
      <Route
        path="/products/order-products/category"
        component={OrderProductsCategoryItem}
        isPrivate
      />

      <Route path="/nfe-manifest" component={Nfe} isPrivate />
      <Route path="/nfe-entry" component={NfeEntry} isPrivate />
      <Route path="/nfe-avulsa" component={NFeAvulsa} isPrivate />
      <Route path="/nfe-devolucao" component={NFeDevolucao} isPrivate />
      <Route path="/nfce" component={NFCeHistory} isPrivate />
      <Route path="/print-nfce/:ref" component={PrintNFePage} isPrivate />
      <Route path="/transfer-inputs" component={TransferInputs} isPrivate />

      <Route path="/financial/banks" component={Banks} isPrivate />
      <Route path="/financial/audit-box" component={AuditCashier} isPrivate />
      <Route path="/financial/suppliers" component={Suppliers} isPrivate />
      <Route
        path="/financial/payments-methods"
        component={PaymentsMethods}
        isPrivate
      />
      <Route path="/financial/cost-center" component={CostCenters} isPrivate />
      <Route
        path="/financial/transactions"
        component={Transactions}
        isPrivate
      />
      <Route path="/financial/dre" component={Dre} isPrivate />
      <Route path="/financial/dfc" component={Dfc} isPrivate />
      <Route path="/financial/invoices" component={Invoices} isPrivate />

      <Route path="/tables/new" component={CreateTables} isPrivate />
      <Route path="/tables/edit" component={EditTables} isPrivate />
      <Route path="/tables/view" component={ViewTables} isPrivate />
      <Route path="/tables" component={Tables} isPrivate />
      <Route
        path="/bills/close-solicitation"
        component={CloseSolicitation}
        isPrivate
      />
      <Route path="/bills" component={ViewBills} isPrivate />
      <Route path="/delivery/bills" component={ViewDeliveryBills} isPrivate />

      <Route path="/orders" component={Orders} isPrivate />
      <Route path="/operation" component={Operation} isPrivate />
      <Route path="/kds" component={Kds} isPrivate />
      <Route path="/kitchen" component={Kitchen} isPrivate />

      <Route path="/transfers" component={Transfers} isPrivate />

      <Route path="/box/payment/new" component={StorePayment} isPrivate />

      <Route path="/opening-box" component={OpeningBox} isPrivate />
      <Route path="/box/payment" component={PaymentForm} isPrivate />
      <Route path="/box" component={Box} isPrivate />

      <Route path="/reports-old" component={ReportsPrincipal} isPrivate />

      <Route path="/reports/products" component={ReportsProducts} isPrivate />
      <Route path="/reports/clients" component={ReportsClient} isPrivate />
      {/* <Route path="/reports/new" component={ReportsNew} isPrivate /> */}

      <Route
        path="/reports/print"
        exact
        component={PrintReportsProducts}
        isPrivate
      />

      <Route
        path="/reports/opening-box"
        component={ReportOpeningBox}
        isPrivate
        exact
      />

      <Route path="/reports/rate" exact component={ReportsRate} isPrivate />
      <Route
        path="/reports/expenses"
        exact
        component={ReportsExpenses}
        isPrivate
      />
      <Route
        path="/reports/orders-canceled"
        exact
        component={ReportsOrderCanceled}
        isPrivate
      />
      <Route path="/reports/invoicing" exact component={Invoicing} isPrivate />
      <Route
        path="/reports/discount"
        exact
        component={ReportsDiscount}
        isPrivate
      />

      <Route path="/reports/service-tax" component={ServiceTax} isPrivate />
      <Route path="/reports" component={ReportsNew} isPrivate />

      <Route path="/products" exact component={Products} isPrivate />

      <Route path="/tasks" exact component={Tasks} isPrivate />

      <Route path="/help" component={Help} isPrivate />

      <Route path="/waiters/new" exact component={CreateWaiter} isPrivate />
      <Route path="/waiters/edit" exact component={EditWaiter} isPrivate />
      <Route path="/waiters" exact component={Waiter} isPrivate />
      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/profile" exact component={UserProfile} isPrivate />

      <Route path="/stone-devices" exact component={StoneDevices} isPrivate />

      <Route path="/table-sessions" component={TableSessions} isPrivate />
      <Route path="/settings/printer" component={EditPrinter} isPrivate />
      <Route path="/settings" component={Settings} isPrivate />

      <Route path="/fiscal-groups" component={FiscalGroups} isPrivate />

      <Route
        path="/delivery/orders"
        exact
        component={DeliveryOrdersPage}
        isPrivate
      />
      <Route
        path="/delivery/config"
        exact
        component={DeliveryConfig}
        isPrivate
      />
      <Route path="/delivery/motoboys" exact component={Motoboy} isPrivate />
      <Route
        path="/delivery/opening-hours"
        exact
        component={OpeningHours}
        isPrivate
      />
      <Route
        path="/delivery/motoboys/edit"
        exact
        component={EditMotoboy}
        isPrivate
      />

      <Route
        path="/delivery/addresses"
        exact
        component={DeliveryAddresses}
        isPrivate
      />

      <Route path="/delivery/coupons/new" component={NewTicket} isPrivate />
      <Route path="/delivery/coupons/edit" component={EditTicket} isPrivate />
      <Route path="/delivery/coupons" component={Tickets} isPrivate />
      <Route
        path="/delivery/scheduling"
        exact
        component={Scheduling}
        isPrivate
      />
      {/* <Route path="/homepage" component={Homepage} isPrivate /> */}
    </Switch>
  );
}
