import React from 'react';

import { Container } from './styles';

import InputUserInfo from '../InputUserInfo';
import InputUserInfoByDistance from '../InputUserInfoByDistance';

import { useAuth } from '~/context/AuthContext';

function UserInfo({
  toggleModalOperation,
  setDeliveryTax,
  discount,
  discountObs,
  adminPassword,
  setAdminPassword
}) {
  const { user } = useAuth();

  const userDeliveryByDistance =
    user?.delivery_info?.is_delivery_by_distance ||
    user?.delivery_info?.delimit_by_area;

  return (
    <Container>
      <h4 style={{ fontWeight: 'bold' }}>Informações do cliente</h4>

      {userDeliveryByDistance ? (
        <InputUserInfoByDistance
          toggleModalOperation={toggleModalOperation}
          setDeliveryTax={setDeliveryTax}
          discount={discount}
          discountObs={discountObs}
          adminPassword={adminPassword}
          setAdminPassword={setAdminPassword}
          user={user}
        />
      ) : (
        <InputUserInfo
          toggleModalOperation={toggleModalOperation}
          discount={discount}
          discountObs={discountObs}
          adminPassword={adminPassword}
          setAdminPassword={setAdminPassword}
          user={user}
        />
      )}
    </Container>
  );
}

export default UserInfo;
