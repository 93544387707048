import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import UserInfo from '~/components/Delivery/UserInfo';
import Cart from '~/components/OrderingSystem/Cart';

import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
} from './styles';
import { useCart } from '~/context/OrderingSystem/Cart';
import Menu from '~/pages/OrderingSystem/Menu';
import { useAuth } from '~/context/AuthContext';

function TableDeliveryButton({
  session,
  getMinimalSessions,
  type,
  getTablesAvailable,
  sessionId,
  handleSearchInputTables,
  setInputValue,
  setTablesFilteredSearch,
  setAllTablesAvailable,
  disable,
}) {
  const { closeCart, cart } = useCart();
  const [discountObs, setDiscountObs] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [deliveryTax, setDeliveryTax] = useState(null);
  const [adminPassword, setAdminPassword] = useState(null);
  const { user } = useAuth();

  const [modalAdvice, setModalAdvice] = useState(false);

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
  }

  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  function toggleModalOperation() {
    if (cart.length > 0) {
      setModalAdvice(true);
    } else {
      setIsModalOperationOpened(!isModalOperationOpened);
      closeCart();
      setDeliveryTax(null);
    }
  }

  function confirmCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    toggleModalAdvice();
    closeCart();
    setDeliveryTax(null);
  }

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }
  const tableId = type !== 'available' ? session?.table?.id : session?.id;
  function toggleModalOperationWithoutCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    setDiscount(null);
  }

  return (
    <Content onClick={setIsModalOperationOpened} disable={disable}>
      Fazer Pedido
      <Modal isOpen={modalAdvice} toggle={toggleModalAdvice}>
        <ModalBody>
          <p>
            Você possui itens no carrinho. Tem certeza que deseja sair sem fazer
            o pedido?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModalAdvice} style={{ background: '#7fd1b9' }}>
            Irei fazer o pedido
          </Button>
          <Button onClick={confirmCloseCart} style={{ background: '#dc3545' }}>
            Sim, sair e excluir o carrinho.
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="takeat"
      >
        <ModalBody>
          <OperationArea>
            <OrderSystemArea>
              <Menu
                tableId={tableId}
                toggleModalOperation={toggleModalOperation}
                getTablesAvailable={getTablesAvailable}
                getSessions={getMinimalSessions}
                session={sessionId}
                handleSearchInputTables={handleSearchInputTables}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                setAllTablesAvailable={setAllTablesAvailable}
                isTableDelivery
              />
            </OrderSystemArea>
            <BillTableArea>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Delivery </h2>{' '}
                <span
                  style={{ fontWeight: 'bold', fontSize: 20, paddingRight: 10 }}
                >
                  Carrinho
                </span>
              </div>
              <Cart
                isTableDelivery
                setDiscount={setDiscount}
                setDiscountObs={setDiscountObs}
                discount={discount}
                discountObs={discountObs}
                deliveryTax={deliveryTax}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                user={user}
                isBalcony={false}
              />
              <UserInfo
                toggleModalOperation={toggleModalOperationWithoutCloseCart}
                discount={discount}
                discountObs={discountObs}
                setDeliveryTax={setDeliveryTax}
                adminPassword={adminPassword}
                setAdminPassword={setAdminPassword}
                user={user}
              />
              {/* <ButtonsArea>
                <button>Fazer PEdido</button>
              </ButtonsArea> */}
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
    </Content>
  );
}

export default TableDeliveryButton;
