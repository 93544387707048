/* eslint-disable react/prop-types */
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaCircle, FaPlus, FaUtensils } from 'react-icons/fa';

import { Col, Row } from 'reactstrap';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import OutlineButton from '~/components/Buttons/OutlineButton';
import PersonalButton from '~/components/Buttons/PersonalButton';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import logo1 from '~/assets/img/3partes.png';
import logo2 from '~/assets/img/4partes.png';
import logo3 from '~/assets/img/5partes.png';

import {
  Card,
  ScreenTwo,
  ScreenOne,
  ButtonsArea,
  StyledTable,
  InputStyled,
  CheckboxInputStyled,
} from './styles';
import RadioInput from '~/components/Form/RadioInput';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function EditKitchen({ toggleModalEditKitchen, kdsState }) {
  const initialData = {
    name: kdsState?.name,
    average_time: kdsState?.average_time,
    group_items: kdsState?.group_items,
  };

  const [arrayCategories, setArrayCategories] = useState([]);

  const getCategories = useCallback(async () => {
    const response = await api.get(
      `/restaurants/kds/product-categories/${kdsState?.id}`
    );

    const categories = response.data;

    setArrayCategories(categories);
  }, [kdsState]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const [categoryExpanded, setCategoryExpanded] = useState([]);

  function addToExpanded(id) {
    const categoryFind = categoryExpanded.find((item) => item === id);

    if (categoryFind) {
      setCategoryExpanded([]);
    } else {
      setCategoryExpanded([id]);
    }
  }

  const [allChecked, setAllChecked] = useState(false);

  function handleCheckAll(status) {
    if (allChecked === false) {
      setArrayCategories((cat) => {
        const newArray = [...cat];

        newArray.map((item) => {
          item.checked = true;
        });

        newArray.map((item) => {
          item.products.map((product) => {
            product.checked = true;
          });
        });
        const allCategoriesChecked = newArray.every((item) => item.checked);
        allCategoriesChecked && !allChecked
          ? setAllChecked(true)
          : setAllChecked(false);
        return [...newArray];
      });
    } else {
      setArrayCategories((cat) => {
        const newArray = [...cat];

        newArray.map((item) => {
          item.checked = false;
        });

        newArray.map((item) => {
          item.products.map((product) => {
            product.checked = false;
          });
        });

        const allCategoriesChecked = newArray.every((item) => item.checked);
        allCategoriesChecked && allChecked
          ? setAllChecked(true)
          : setAllChecked(false);
        return [...newArray];
      });
    }
  }

  function handleCheckCategory(id, status) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === id
      );

      if (status) {
        newArray[categoyFind].checked = false;

        newArray[categoyFind].products.map((product) => {
          product.checked = false;
        });

        const allCategoriesChecked = newArray.every((item) => item.checked);
        allCategoriesChecked ? setAllChecked(true) : setAllChecked(false);
      } else {
        newArray[categoyFind].checked = true;

        newArray[categoyFind].products.map((product) => {
          product.checked = true;
        });
        const allCategoriesChecked = newArray.every((item) => item.checked);
        allCategoriesChecked ? setAllChecked(true) : setAllChecked(false);
      }

      return [...newArray];
    });
  }

  function handleCheckProduct(category_id, product_id, status) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === category_id
      );

      if (status) {
        const productFind = newArray[categoyFind].products.findIndex(
          (prod) => prod.product_id === product_id
        );

        newArray[categoyFind].products[productFind].checked = false;
      } else {
        const productFind = newArray[categoyFind].products.findIndex(
          (prod) => prod.product_id === product_id
        );

        newArray[categoyFind].products[productFind].checked = true;
      }

      if (
        newArray[categoyFind].products.filter((prod) => prod.checked).length ===
        0
      ) {
        newArray[categoyFind].checked = false;
      } else {
        newArray[categoyFind].checked = true;
      }

      return [...newArray];
    });
  }

  const [viewMode, setViewMode] = useState(kdsState?.view_mode);

  const formRef = useRef(null);

  function handleChangeCategoryPreparationTime(id, value) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === id
      );

      newArray[categoyFind].average_time = value;

      return [...newArray];
    });
  }

  function handleChangeProductPreparationTime(category_id, product_id, value) {
    setArrayCategories((cat) => {
      const newArray = [...cat];
      const categoyFind = newArray.findIndex(
        (cat) => cat.product_category_id === category_id
      );

      const productFind = newArray[categoyFind].products.findIndex(
        (prod) => prod.product_id === product_id
      );

      newArray[categoyFind].products[productFind].average_time = value;

      return [...newArray];
    });
  }

  function handleGetAllProductsChecked(id) {
    const categoryFind = arrayCategories.find(
      (category) => category.product_category_id === id
    );

    const allCheckedItems = categoryFind.products.every((item) => item.checked);

    return allCheckedItems;
  }

  const [activeOne, setActiveOne] = useState(true);

  const toogleActiveOne = useCallback(() => {
    setActiveOne(!activeOne);
  }, [activeOne]);

  const handleSubmit = useCallback(
    async (data) => {
      if (activeOne) {
        try {
          if (formRef.current) {
            formRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            average_time: Yup.string().required('Tempo de preparo obrigatório'),
            group_items: Yup.string().required('Agrupar itens Obrigatório'),
            name: Yup.string().required('Nome Obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (viewMode) {
            toogleActiveOne();
          } else {
            toast.error('Selecione o modo de visualização');
          }
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (formRef.current) {
              formRef.current.setErrors(errors);
            }
          }
        }
      } else {
        try {
          const response = await api.put(`restaurants/kds/${kdsState.id}`, {
            average_time: data.average_time,
            group_items: data.group_items,
            name: data.name,
            view_mode: viewMode,
            product_categories: arrayCategories.filter(
              (item) => item.checked && item.products.length > 0
            ),
          });

          const { average_time, group_items, name, view_mode } = response.data;

          // const kdsFind = kds?.findIndex((item) => item.id === kdsId);

          // if (kdsFind !== -1) {
          //   kds[kdsFind].name = name;
          //   kds[kdsFind].average_time = average_time;
          //   kds[kdsFind].group_items = group_items;
          //   kds[kdsFind].view_mode = view_mode;
          // }

          kdsState.name = name;
          kdsState.average_time = average_time;
          kdsState.group_items = group_items;
          kdsState.view_mode = view_mode;

          toast.success('Tela atualizada com sucesso');
          toggleModalEditKitchen();
        } catch (error) {
          toast.error('Erro ao atualizar tela');
          console.log(error);
        }
      }
    },
    [
      activeOne,
      arrayCategories,
      viewMode,
      toogleActiveOne,
      toggleModalEditKitchen,
      kdsState,
    ]
  );
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  return (
    <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
      <ScreenOne active={activeOne} browserZoomLevel={browserZoomLevel}>
        <Row className="mb-3">
          <p style={{ fontSize: 18 }}>Editar KDS</p>
          <span>
            As edições feitas nessa tela só serão aplicadas a partir do próximo
            novo pedido.
          </span>
        </Row>
        <Row>
          <Input label="Nome do KDS" name="name" maxlength="30" />
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',

              alignItems: 'center',
            }}
          >
            <CheckboxInput
              name="group_items"
              id="teste"
              defaultValue={kdsState.group_items}
            />
            <span>
              Agrupar itens do pedido (se desmarcado, será exibido um item por
              vez)
            </span>
          </div>
        </Row>
        <Row>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>
            Modo de visualização
          </p>
          <p style={{ fontSize: 14 }}>
            Selecione a quantidade de colunas a serem exibidas na tela
          </p>
        </Row>
        <Row className="mt-3">
          <Col md="4" className="d-flex flex-column">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {' '}
              <RadioInput
                name="view_mode"
                value="3"
                onChange={(e) => setViewMode(3)}
                checked={viewMode === 3}
              />
              <span>3</span>
            </div>
            <img
              src={logo1}
              alt="imagem mostrando como a tela vai ficar dividida"
              className="mt-3"
            />
          </Col>
          <Col md="4" className="d-flex flex-column">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {' '}
              <RadioInput
                name="view_mode"
                value="4"
                onChange={(e) => setViewMode(4)}
                checked={viewMode === 4}
              />
              <span>4 (recomendado)</span>
            </div>
            <img
              src={logo2}
              alt="imagem mostrando como a tela vai ficar dividida"
              className="mt-3"
            />
          </Col>
          <Col md="4" className="d-flex flex-column">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {' '}
              <RadioInput
                name="view_mode"
                value="5"
                onChange={(e) => setViewMode(5)}
                checked={viewMode === 5}
              />
              <span>5</span>
            </div>
            <img
              src={logo3}
              alt="imagem mostrando como a tela vai ficar dividida"
              className="mt-3"
            />
          </Col>
        </Row>
        <Row>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>
            Tempo médio (Em minutos)
          </p>
          <p style={{ fontSize: 14 }}>
            Tempo médio de preparação dos pratos. Caso algum prato tenha tempo
            específico, basta personalizar na próxima etapa.
          </p>
        </Row>
        <Row>
          <Input name="average_time" />
        </Row>
      </ScreenOne>

      <ScreenTwo active={!activeOne} browserZoomLevel={browserZoomLevel}>
        <Row>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>
            Tempo Personalizado
          </p>
          <p style={{ fontSize: 14 }}>
            Personalize o tempo de preparo para itens específicos. Essa etapa é
            opcional. Caso não seja definido será considerado a tempo médio
            definido na etapa anterior.
          </p>
        </Row>
        <StyledTable>
          <thead>
            <tr>
              <th>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#6c6c6c',
                  }}
                >
                  {' '}
                  <CheckboxInputStyled allChecksChecked={!allChecked}>
                    <input
                      name="all_selected"
                      onClick={handleCheckAll}
                      type="checkbox"
                    />
                  </CheckboxInputStyled>
                  Todos os itens
                </span>
              </th>
              <th>Tempo de preparo</th>
            </tr>
          </thead>
          <tbody>
            {arrayCategories.length > 0 &&
              arrayCategories.map((category) => (
                <React.Fragment key={category?.product_category_id}>
                  <tr>
                    <td>
                      {' '}
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#6c6c6c',
                          fontWeight: 'bold',
                        }}
                      >
                        <FaAngleDown
                          size={16}
                          onClick={() => {
                            addToExpanded(category?.product_category_id);
                          }}
                          cursor="pointer"
                          style={{
                            transition: 'all 0.2s',
                            transform: categoryExpanded.includes(
                              category?.product_category_id
                            )
                              ? 'rotate(-90deg)'
                              : 'rotate(0)',
                            marginLeft: 22,
                            marginRight: 10,
                          }}
                        />
                        <CheckboxInputStyled
                          allChecksChecked={
                            !handleGetAllProductsChecked(
                              category?.product_category_id
                            )
                          }
                        >
                          <input
                            defaultChecked={category?.checked}
                            name={category?.name}
                            key={category?.checked}
                            value={category?.checked}
                            type="checkbox"
                            onClick={() => {
                              handleCheckCategory(
                                category?.product_category_id,
                                category?.checked
                              );
                            }}
                          />
                        </CheckboxInputStyled>

                        {category?.name}
                      </span>
                    </td>
                    <td>
                      {' '}
                      <InputStyled
                        type="number"
                        defaultValue={category?.average_time}
                        onChange={(e) =>
                          handleChangeCategoryPreparationTime(
                            category?.product_category_id,
                            e.target.value
                          )
                        }
                      />{' '}
                      min
                    </td>
                  </tr>

                  {category?.products &&
                    category?.products.map((product) => (
                      <tr
                        style={{
                          display:
                            !categoryExpanded.includes(
                              category.product_category_id
                            ) && 'none',
                        }}
                      >
                        <td style={{ border: 'none' }}>
                          {' '}
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#6c6c6c',
                              paddingLeft: 55,
                            }}
                          >
                            <CheckboxInput
                              defaultChecked={product.checked}
                              name={product.name}
                              key={product.checked}
                              value={product.checked}
                              onClick={() =>
                                handleCheckProduct(
                                  category.product_category_id,
                                  product.product_id,
                                  product.checked
                                )
                              }
                            />{' '}
                            {product.name}
                          </span>
                        </td>
                        <td style={{ border: 'none' }}>
                          <InputStyled
                            defaultValue={product.average_time}
                            onChange={(e) =>
                              handleChangeProductPreparationTime(
                                category.product_category_id,
                                product.product_id,
                                e.target.value
                              )
                            }
                            type="number"
                          />{' '}
                          min
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
        </StyledTable>
      </ScreenTwo>

      <ButtonsArea>
        <PersonalButton
          color="#FF2C3A"
          message="Cancelar"
          style={{
            marginLeft: 0,
          }}
          onClick={() => {
            toggleModalEditKitchen();
          }}
          type="button"
        />

        <div style={{ display: 'flex' }}>
          <FaCircle color={activeOne ? '#FF2C3A' : '#D9D9D9'} />
          <FaCircle
            color={!activeOne ? '#FF2C3A' : '#D9D9D9'}
            style={{ marginLeft: 8 }}
          />
        </div>

        {!activeOne ? (
          <div style={{ display: 'flex' }}>
            <OutlineButton
              text="Voltar"
              style={{
                marginLeft: 0,
              }}
              onClick={toogleActiveOne}
            />
            <PersonalButton color="#0CCAB4" message="Salvar" type="submit" />
          </div>
        ) : (
          <PersonalButton color="#0CCAB4" message="Próximo" type="submit" />
        )}
      </ButtonsArea>
    </Form>
  );
}

export default EditKitchen;
