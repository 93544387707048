import React, { useState, useRef, useCallback, useEffect } from 'react';
import { AiOutlinePrinter, AiOutlineEye, AiFillPrinter } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import ReactToPrint from 'react-to-print';
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';
import { TiDelete } from 'react-icons/ti';
import { toast } from 'react-hot-toast';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import EyeModalOrders from '~/components/EyeModalOrders';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import api from '~/services/api';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  CancelButton,
  OrdersInfo,
  Badge,
  InfoDiv,
} from '../styles';
import { DisplayNoneImprimir } from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import formatCompleteDate from '~/services/formatCompleteDate';

import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import { FaEye, FaPrint } from 'react-icons/fa';
import apiClube from '~/services/apiClube';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function OrderSchedulingNew({
  item,
  handlePrintItem,
  handleCancelItem,
  setItemIfoodId,
}) {
  const { user } = useAuth();
  const { moveOrderToCanceled } = useOrder();
  const componentRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [btnDisable, serBtnDisable] = useState(false);
  const [orders, setOrders] = useState([]);
  const [printLoading, setPrintLoading] = useState(false);

  const hasKey = user.has_order_cancel_password;
  async function handleNotifyWhatsapp() {
    if (
      user.delivery_info.notify_whatsapp &&
      item?.table?.table_type === 'delivery'
    ) {
      const userPhone = item?.buyer.phone;
      const userName = item?.buyer?.name.split(' ')[0];

      const userAdress =
        `${item?.buyer.delivery_address?.street || ''} ${
          item?.buyer.delivery_address?.number || ''
        } - ${item?.buyer.delivery_address?.city || ''} - ${
          item?.buyer.delivery_address?.state || ''
        } - ${item?.buyer.delivery_address?.zip_code || ''}` || '';

      const userPaymentMethod = item?.intended_payment_method?.name || '';

      if (user.use_wpp) {
        const productsWpp = orders.map((prod) => {
          return `\n*${prod.amount}x - ${
            prod.product.name
          } - R$ ${prod.total_price?.toFixed(2)}*\n${
            prod.complement_categories.length > 0
              ? prod.complement_categories
                  .map((complement_cat) => {
                    return `    - ${complement_cat.complement_category.name}\n${
                      complement_cat.order_complements.length > 0 &&
                      complement_cat.order_complements
                        .map((comp) => {
                          return `    ${comp.amount}x - ${comp.complement.name} - R$${comp.complement.price}\n`;
                        })
                        .join('')
                    }`;
                  })
                  .join('')
              : ''
          }`;
        });

        const textWpp = `Olá${userName && ', ' + userName}! Aqui é do *${
          user.fantasy_name
        }*.\nO seu pedido já foi aceito e está em preparo!\n\n*Itens:*${productsWpp.join(
          ''
        )}\nTotal com Taxa (se houver): *R$${(
          Number(item?.basket.total_service_price) +
          Number(item?.delivery_tax_price) -
          Number(item?.coupon_discount ? item?.coupon_discount : 0)
        )?.toFixed(2)}*\n\n*Endereço:* ${userAdress}\n\n*Pagamento:* ${
          userPaymentMethod || 'Não informado'
        }`;

        const textSent = await api.post('/restaurants/uzapi/delivery-msg', {
          text: textWpp,
          phone: userPhone,
          token: user.name,
        });

        if (textSent.data.status === 400) {
          toast.error(
            'Whatsapp está desconectado, essa mensagem deverá ser enviada manualmente',
            { duration: 5000 }
          );
          const products = orders
            .map((product) => {
              return `%0a*${product.amount}x - ${
                product.product.name
              } - R$ ${product.total_price?.toFixed(2)}*%0a${
                product.complement_categories.length > 0
                  ? product.complement_categories
                      .map((complement_cat) => {
                        return `    - ${
                          complement_cat.complement_category.name
                        }%0a${
                          complement_cat.order_complements.length > 0
                            ? complement_cat.order_complements
                                .map((comp) => {
                                  return `    ${comp.amount}x - ${comp.complement.name} - R$${comp.complement.price}%0a`;
                                })
                                .join()
                            : ''
                        }`;
                      })
                      .join()
                  : ''
              }`;
            })
            .join('');

          const text = `Olá! Aqui é do *${
            user.fantasy_name
          }*.%0aO seu pedido já foi aceito e está em preparo!%0aItens:%0a${products}Total com Taxa (se houver): R$${(
            Number(item?.basket.total_service_price) +
            Number(item?.delivery_tax_price) -
            Number(item?.coupon_discount ? item?.coupon_discount : 0)
          )?.toFixed(2)}%0a%0a*Endereço:* ${userAdress}%0a*Pagamento:* ${
            userPaymentMethod || 'Não informado'
          }`.replace(',', '');

          window.open(
            `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`
          );
        } else {
          toast.success(
            'Whatsapp informando PEDIDO ACEITO enviado para cliente'
          );
        }
      } else {
        const products = orders
          .map((product) => {
            return `%0a*${product.amount}x - ${
              product.product.name
            } - R$ ${product.total_price?.toFixed(2)}*%0a${
              product.complement_categories.length > 0
                ? product.complement_categories
                    .map((complement_cat) => {
                      return `    - ${
                        complement_cat.complement_category.name
                      }%0a${
                        complement_cat.order_complements.length > 0
                          ? complement_cat.order_complements
                              .map((comp) => {
                                return `    ${comp.amount}x - ${comp.complement.name} - R$${comp.complement.price}%0a`;
                              })
                              .join()
                          : ''
                      }`;
                    })
                    .join()
                : ''
            }`;
          })
          .join('');

        const text = `Olá! Aqui é do *${
          user.fantasy_name
        }*.%0aO seu pedido já foi aceito e está em preparo!%0aItens:%0a${products}Total com Taxa (se houver): R$${(
          Number(item?.basket.total_service_price) +
          Number(item?.delivery_tax_price) -
          Number(item?.coupon_discount ? item?.coupon_discount : 0)
        )?.toFixed(2)}%0a%0a*Endereço:* ${userAdress}%0a*Pagamento:* ${
          userPaymentMethod || 'Não informado'
        }`.replace(',', '');

        window.open(
          `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`
        );
      }
    }
  }

  const getOrders = useCallback(async () => {
    try {
      setPrintLoading(true);
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
      setPrintLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => {
    setModalDelete(!modalDelete);
    serBtnDisable(!btnDisable);
  };

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  async function closeClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });

      handlePrintItem(item.basket.id, item.basket.ifood_id);
    } catch (error) {
      toast.error('Erro ao aceitar pedido.');
    }
  }

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [getOrders, modalDelete, modal]);

  return (
    <Pedido>
      <ContentBody>
        {item?.table.table_type !== 'delivery' && (
          <div>
            <OrderStatusTag text="Novo - Pendente" color="#2EC9B7" reversed />
          </div>
        )}

        <InfoDiv>
          {item?.table.table_type === 'delivery' ? (
            <>
              <OrderNumber>#{item?.attendance_password}</OrderNumber>
              <Time>{formatCompleteDate(item?.basket.start_time)}</Time>
            </>
          ) : (
            <>
              <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
              <Time>{formatCompleteDate(item?.basket.start_time)}</Time>
            </>
          )}
          <img
            src={item.basket.ifood_id === null ? logoTakeat : logoIfood}
            style={{ width: item.basket.ifood_id === null ? 20 : 40 }}
          />
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            {item?.table.table_type === 'command' &&
            item?.table.table_type !== 'delivery' ? (
              `Comanda  ${item.table.table_number} / Mesa ${
                item?.basket?.command_table_number || 'não informada'
              } `
            ) : (
              <>
                {item?.basket?.ifood_table
                  ? item?.basket?.ifood_table
                  : searchTableName(item?.table)}
              </>
            )}
          </TableNumber>

          <PhoneNumber>
            {/* {item?.buyer?.name?.length < 20
                ? item?.buyer?.name
                : `${item?.buyer?.name.slice(0, 17)}...`} */}
            {item?.buyer?.name}
          </PhoneNumber>
          <PhoneNumber>
            {`Agendado para ${formatCompleteDate(item.scheduled_to)}`}
          </PhoneNumber>
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            {user.is_printed_on_web ? (
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getOrders();
                }}
                trigger={() => (
                  <div>
                    {!printLoading ? (
                      <div>
                        <FaPrint
                          size={16}
                          style={{ marginRight: 3, marginBottom: 10 }}
                          color="#3BD2C1"
                          cursor="pointer"
                          title="Aceitar Pedido"
                        />
                      </div>
                    ) : (
                      <Spinner
                        size="sm"
                        style={{ marginRight: 3, marginBottom: 10 }}
                      />
                    )}
                  </div>
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  handlePrintItem(
                    item.basket.id,
                    item.basket.ifood_id,
                    item?.table?.table_type === 'delivery'
                  );
                  if (!item.basket.ifood_id) {
                    handleNotifyWhatsapp(item, orders, user, 'accepted');
                  }
                }}
              />
            ) : (
              <>
                {item?.table?.table_type === 'delivery' ? (
                  <ReactToPrint
                    onBeforeGetContent={async () => {
                      await getOrders();
                    }}
                    trigger={() => (
                      <div>
                        {!printLoading ? (
                          <div>
                            <FaPrint
                              size={16}
                              style={{ marginRight: 3, marginBottom: 10 }}
                              color="#3BD2C1"
                              cursor="pointer"
                              title="Aceitar Pedido"
                            />
                          </div>
                        ) : (
                          <Spinner size="sm" />
                        )}
                      </div>
                    )}
                    content={() => componentRef.current}
                    onAfterPrint={() => {
                      handlePrintItem(
                        item.basket.id,
                        item.basket.ifood_id,
                        item?.table?.table_type === 'delivery'
                      );
                      if (!item.basket.ifood_id) {
                        handleNotifyWhatsapp(item, orders, user, 'accepted');
                      }
                    }}
                  />
                ) : (
                  <div>
                    <FaPrint
                      size={16}
                      onClick={closeClick}
                      color="#3BD2C1"
                      cursor="pointer"
                      title="Aceitar Pedido"
                    />
                  </div>
                )}
              </>
            )}

            <div>
              <FaEye
                size={18}
                onClick={toggle}
                color="#FFA50B"
                cursor="pointer"
                style={{ marginRight: 3, marginBottom: 10 }}
                title="Detalhes do pedido"
              />
            </div>

            {item?.table?.table_type === 'delivery' ? (
              <TiDelete
                color="#FE1933"
                size={20}
                onClick={() => {
                  setItemIfoodId(item.basket.ifood_id);
                  toggleDeleteDelivery();
                }}
                disabled={btnDisable}
                cursor="pointer"
                style={{ marginRight: 3, marginBottom: 10 }}
                title="Deletar Pedido"
              />
            ) : (
              <TiDelete
                color="#FE1933"
                size={20}
                onClick={toggleDelete}
                disabled={btnDisable}
                cursor="pointer"
                style={{ marginRight: 3, marginBottom: 10 }}
              />
            )}
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>

      <DisplayNoneImprimir>
        {/* console.log("aqqqq")} {console.log(item) */}
        <PrintNewOrderComponent
          ref={componentRef}
          restaurant={user}
          item={item}
          handlePrintItem={handlePrintItem}
          orders={orders}
        />
      </DisplayNoneImprimir>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'pending',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="pending"
              orders={orders}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'pending',
                ifood_id: item.basket.ifood_id,
              })
            }
          >
            Cancelar esse(s) item(s)
          </Button>
        </ModalFooter>
      </Modal>
    </Pedido>
  );
}
