/* eslint-disable no-console */
/* eslint-disable no-shadow */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Form } from '@unform/web';

import classnames from 'classnames';
import { FaDownload } from 'react-icons/fa';

import * as Yup from 'yup';
import { registerLocale } from 'react-datepicker';
import ReactSelect from 'react-select';
import FileSaver from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';

import { MdArrowDropDown, MdDateRange } from 'react-icons/md';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Collapse,
  CardBody,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
  endOfMonth,
  endOfWeek,
} from 'date-fns';

import { toast } from 'react-hot-toast';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { generateTransactionsSheet } from '~/services/SheetGenerate/generateTransactionsSheet';
import Select from '~/components/Form/SelectInput';

import Input from '~/components/Form/Input';
// import Select from '~/components/Form/SelectInput';

import SaveButton from '~/components/Buttons/DefaultButton';
import RegisterButton from '~/components/Buttons/RegisterButton';

import {
  Container,
  NumberFormatStyled,
  Card,
  Header,
  SelectDateButton,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  DataPickerArea,
  DatePickerStyled,
  SelectDataArea,
  FilterArea,
  DeleteFilterButton,
  ErrorContainer,
  StyledLink,
  DatePickerStyledUnify,
} from './styles';

import Transaction from './Transaction';
import CheckboxInput from '~/components/Form/Checkbox';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import formatCompleteDate from '~/services/formatDate';
import formatValue from '~/utils/formatValue';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { FullCalendar, Tabs } from 'ui-kit-takeat';
import { counter } from '@fortawesome/fontawesome-svg-core';

registerLocale('pt', pt);

function Transactions() {
  const formRef = useRef(null);
  const transactionFormRef = useRef(null);
  const [isModalErrorOpened, setIsModalErrorOpened] = useState(false);
  const [, setTransactions] = useState();
  const [banks, setBanks] = useState();
  const [suppliers, setSuppliers] = useState();
  const [paymentMethods, setPaymentMethods] = useState();
  const [categories, setCategories] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalCreateTransactionOpen, setIsModalCreateTransactionOpen] =
    useState(false);
  const [dateSelected, setDateSelected] = useState({
    start: new Date(),
    end: null,
  });
  const [bankUnify, setBankUnify] = useState(null);
  const [methodUnify, setMethodUnify] = useState(null);

  const [isEarningTotal, setIsEarningTotal] = useState();
  const [isNotEarningTotal, setIsNotEarningTotal] = useState();
  const [isEarningTotalPaid, setIsEarningTotalPaid] = useState();
  const [isNotEarningTotalPaid, setIsNotEarningTotalPaid] = useState();
  const [transactionsToUnify, setTransactionsToUnify] = useState([]);

  const [isEarningOption, setIsEarningOption] = useState(false);

  const [paidCheck, setPaidCheck] = useState(false);
  const [repitCheck, setRepitCheck] = useState(false);
  const [dueDate, setDuedate] = useState('');
  const [competenceDate, setCompetenceDate] = useState('');
  const [paidAtDate, setpaidAtDate] = useState('');
  const [paidParams, setPaidParams] = useState({
    value: '',
    label: 'Situação',
  });
  const [providerParams, setProviderParams] = useState({
    value: '',
    label: 'Fornecedor',
  });
  const [bankParams, setBankParams] = useState({
    value: '',
    label: 'Conta',
  });
  const [categoryParams, setCategoryParams] = useState({
    value: '',
    label: 'Centro de custo',
    type: '',
  });
  const [paymentParams, setPaymenParams] = useState({
    value: '',
    label: 'Método',
  });

  const [isEarning, setIsEarning] = useState({
    value: '',
    label: 'Despesas / Receitas',
  });

  const [, setCategorySelected] = useState({
    value: '',
    label: 'Centro de custo',
    type: '',
  });

  const [valueState, setValueState] = useState();
  const [isEarningTransactions, setIsEarningTransactions] = useState([]);
  const [isNotEarningTransactions, setIsNotEarningTransactions] = useState([]);

  const [activeTab, setActiveTab] = useState('1');

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);

    setpaidAtDate(new Date());
  }

  function handleSetRepitCheck() {
    setRepitCheck(!repitCheck);
  }

  function handleSetCheks() {
    setPaidCheck(false);
    setRepitCheck(false);
  }

  const toggleModalError = useCallback(() => {
    setIsModalErrorOpened(!isModalErrorOpened);
  }, [isModalErrorOpened]);

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  // Date Select
  const defaultInicial = useMemo(() => {
    return format(
      startOfMonth(subHours(new Date(), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(
    startOfMonth(subHours(new Date(), 3)),
    "yyyy-MM-dd'T'HH:mm:ss"
  );
  const [finalDate, setFinalDate] = useState(
    format(endOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
  );
  const [, setHourPhrase] = useState(phrase);

  const freqOptions = [
    { value: 'day', label: 'Diáriamente' },
    { value: 'week', label: 'Semanalmente' },
    { value: 'month', label: 'Mensalmente' },
    { value: 'year', label: 'Anualmente' },
  ];

  const toggle = () => setIsOpen(!isOpen);

  function setToday() {
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfWeek(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfWeek(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(
      format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }

  // Get Datas
  const getTransactions = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
          is_earning: isEarning.value,
        },
      });

      setTransactions(response.data.cash_flows);
      setIsNotEarningTotal(response.data.is_not_earning_total);
      setIsEarningTotal(response.data.is_earning_total);
      setIsNotEarningTotalPaid(response.data.is_not_earning_total_paid);
      setIsEarningTotalPaid(response.data.is_earning_total_paid);

      const isEarningTransactionsData = response.data.cash_flows.filter(
        (item) => item.is_earning
      );
      setIsEarningTransactions(isEarningTransactionsData);

      const isNotEarningTransactionsData = response.data.cash_flows.filter(
        (item) => !item.is_earning
      );
      setIsNotEarningTransactions(isNotEarningTransactionsData);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, [
    finalDate,
    inicialDate,
    paidParams,
    providerParams,
    paymentParams,
    categoryParams,
    bankParams,
    isEarning,
  ]);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/payment-methods');

      const methodsSelect = response?.data?.map((method) => {
        return {
          value: method.id,
          label: method.name,
        };
      });

      setPaymentMethods(methodsSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  const getCategoriesDatails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
          type: category.type,
        };
      });

      setCategories(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar centro de custos');
    }
  }, []);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    getCategoriesDatails();
  }, [getCategoriesDatails]);

  const toggleModalCreateTransaction = useCallback(() => {
    setIsModalCreateTransactionOpen(!isModalCreateTransactionOpen);
    setCategorySelected({
      value: '',
      label: 'Centro de custo',
      type: '',
    });
    setValueState('');
  }, [isModalCreateTransactionOpen]);

  const [isModalErrorCategoryOpened, setIsModalErrorCategoryOpened] =
    useState(false);

  const toggleModalErrorCategory = useCallback(() => {
    setIsModalErrorCategoryOpened(!isModalErrorCategoryOpened);
  }, [isModalErrorCategoryOpened]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        // if (categorySelected.type === 'insumos') {
        //   toggleModalErrorCategory();
        // } else

        if (!dueDate || !valueState) {
          toggleModalError();
        } else {
          const readyValue = (valueState.floatValue / 100).toFixed(2);

          const is_earning = data.is_earning || false;

          if (transactionFormRef?.current) {
            transactionFormRef.current.setErrors({});
          }
          const schema = Yup.object().shape({
            cash_flow_category_subcategory_id: is_earning
              ? Yup.string().nullable()
              : Yup.string().required('Centro de custo obrigatório'),
            bank_account_id: Yup.string().required('Conta obrigatória'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await api.post('/restaurants/cash-flows', {
            value: readyValue,
            description: data.description,
            information: data.information,
            due_date: dueDate,
            competence_date: competenceDate,
            paid: data.paid,
            paid_at: paidAtDate,
            provider_id: data.provider_id || null,
            bank_account_id: data.bank_account_id,
            cash_flow_payment_method_id:
              data.cash_flow_payment_method_id || null,
            cash_flow_category_subcategory_id: is_earning
              ? null
              : data.cash_flow_category_subcategory_id,
            freq_type: data.freq_type || '',
            freq_value: data.freq_value || '',
            is_earning,
          });

          toast.success('Movimentação cadastrada com sucesso!');

          getTransactions();
          toggleModalCreateTransaction();
          setCategorySelected({
            value: '',
            label: 'Centro de custo',
            type: '',
          });
          setValueState('');
          setIsEarningOption(false);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (transactionFormRef?.current) {
            transactionFormRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar Movimentação!`);
          console.log(error);
        }
      }
    },
    [
      getTransactions,
      toggleModalCreateTransaction,
      competenceDate,
      dueDate,
      paidAtDate,
      valueState,
      toggleModalError,
    ]
  );

  const handleDeleteTransaction = useCallback(
    async (id) => {
      try {
        if (window.confirm('Deseja excluir essa movimentação?')) {
          await api.delete(`/restaurants/cash-flows/${id}`);

          toast.success('Movimentação deletada com sucesso!');
          getTransactions();
        }
      } catch (error) {
        toast.error('Erro ao deletar a movimentação');
      }
    },
    [getTransactions]
  );

  function deleteFilters() {
    setBankParams('');
    setPaymenParams('');
    setProviderParams('');
    setPaidParams('');
    setCategoryParams('');
    setIsEarning('');
  }

  function handleChangePaidSituation() {
    if (paidParams) {
      if (paidParams.value === true) {
        setPaidParams({ value: false, label: 'Não Pago' });
      }

      if (paidParams.value === false) {
        setPaidParams({ value: true, label: 'Pago' });
      }

      if (paidParams.value === '') {
        setPaidParams({ value: true, label: 'Pago' });
      }
    } else {
      setPaidParams({ value: true, label: 'Pago' });
    }
    getTransactions();
  }

  const handleOrderDueDate = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
        },
      });

      const sorted = response.data.sort(function (a, b) {
        if (a.due_date > b.due_date) {
          return 1;
        }

        if (a.due_date < b.due_date) {
          return -1;
        }

        return 0;
      });
      setTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  const handleOrderCategoryName = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
        },
      });

      const sorted = response.data.sort(function (a, b) {
        if (a.text > b.text) {
          return 1;
        }

        if (a.text < b.text) {
          return -1;
        }

        return 0;
      });
      setTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  const handleOrderProviders = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
        },
      });

      const sorted = response.data.sort(function (a, b) {
        if (a.provider?.name > b.provider?.name) {
          return 1;
        }

        if (a.provider?.name < b.provider?.name) {
          return -1;
        }

        return 0;
      });
      setTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
      console.log(error);
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  const handleOrderDescription = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          paid: paidParams.value,
          provider_id: providerParams.value,
          bank_account_id: bankParams.value,
          payment_method_id: paymentParams.value,
          category_subcategory_id: categoryParams.value,
        },
      });

      const sorted = response.data.sort(function (a, b) {
        if (a.description > b.description) {
          return 1;
        }

        if (a.description < b.description) {
          return -1;
        }

        return 0;
      });
      setTransactions(sorted);
    } catch (error) {
      toast.error('Erro ao baixar movimentações');
    }
  }, [
    bankParams,
    finalDate,
    inicialDate,
    categoryParams,
    paidParams,
    providerParams,
    paymentParams,
  ]);

  function handleValueChange(e) {
    setValueState(e);
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceCashFlows'}`
      );

      const { can_read } = response.data.FinanceCashFlows;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const isEarningOptions = [
    {
      label: 'Despesas',
      value: false,
    },
    {
      label: 'Receitas',
      value: true,
    },
  ];

  function handleAddTransactionToUnify(transaction) {
    const transactionFind = transactionsToUnify.findIndex(
      (item) => item.id === transaction.id
    );

    if (transactionFind !== -1) {
      setTransactionsToUnify((state) => {
        const transactionFiltered = state.filter(
          (item) => item.id !== transaction.id
        );
        console.log(transactionFiltered);
        return transactionFiltered;
      });
    } else {
      setTransactionsToUnify((state) => {
        console.log([...state, transaction]);
        return [...state, transaction];
      });
    }
  }

  const [isModalUnifyTransactionOpened, setModalUnifyTransactionsOpened] =
    useState(false);

  function toggleModalUnifyTransactions() {
    setModalUnifyTransactionsOpened(!isModalUnifyTransactionOpened);
  }

  const submitUnify = useCallback(
    async (data) => {
      try {
        const transactions = transactionsToUnify.map((trans) => trans.id);
        const handleData = {
          due_date: dueDate,
          bank_account_id: bankUnify,
          cash_flow_payment_method_id: methodUnify,
          description: data.description,
          transactions,
        };
        await api.post('/restaurants/cash-flows/unify', handleData);
        toast.success('Lançamentos unificados com sucesso!');
        setTransactionsToUnify([]);
        setBankUnify(null);
        setDuedate(null);
        setMethodUnify(null);
        toggleModalUnifyTransactions();
        getTransactions();
      } catch (error) {
        console.log(error);
        toast.error('Erro ao unificar lançamentos. Tente novamente');
      }
    },
    [transactionsToUnify, dueDate, methodUnify, bankUnify]
  );

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateTransactionsSheet(
        isNotEarningTransactions,
        isEarningTransactions
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Lancamentos(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  useEffect(() => {
    setInicialDate(
      format(
        startOfDay(subHours(new Date(dateSelected.start), 3)),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(
        endOfDay(subHours(new Date(dateSelected.end), 3)),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
  }, [dateSelected]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Modal
        isOpen={isModalUnifyTransactionOpened}
        toggle={toggleModalUnifyTransactions}
        size="xl"
      >
        <Form onSubmit={submitUnify}>
          <ModalHeader>Unificar contas a receber</ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>Recebimento</th>
                  <th>Método de pagamento</th>
                  <th>Competência</th>
                  <th>Conta bancária</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {transactionsToUnify &&
                  transactionsToUnify.length > 0 &&
                  transactionsToUnify.map((item) => (
                    <tr>
                      <td>{formatCompleteDate(item?.due_date || 0)}</td>
                      <td>{item?.cash_flow_payment_method?.name || '-'}</td>
                      <td>{item?.description || '-'}</td>
                      <td>{item?.bank_account?.name || '-'}</td>
                      <td>{formatValue(item?.value) || '-'}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <DatePickerStyledUnify
                      selected={dueDate}
                      onChange={(date) => setDuedate(date)}
                      locale="pt"
                      name="due_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="__/__/____"
                    />
                  </td>
                  <td>
                    {' '}
                    <ReactSelect
                      placeholder="Método"
                      options={paymentMethods}
                      onChange={(e) => {
                        setMethodUnify(e.value);
                      }}
                      name="cash_flow_payment_method_id"
                    />
                  </td>
                  <td>
                    <Input placeHolder="Descrição" name="description" />
                  </td>
                  <td style={{ marginBottom: 15 }}>
                    <ReactSelect
                      placeholder="Conta"
                      options={banks}
                      onChange={(e) => {
                        setBankUnify(e.value);
                      }}
                      name="bank_account_id"
                    />
                  </td>
                  <td>
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      {' '}
                      Total R${' '}
                      {transactionsToUnify
                        .reduce((accum, curr) => accum + Number(curr.value), 0)
                        .toFixed(2)
                        .replace('.', ',')}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-warning"
              onClick={toggleModalUnifyTransactions}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-success">
              Unificar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalErrorOpened} toggle={toggleModalError}>
        <ModalBody>Os campos Valor e Vencimento são obrigatórios</ModalBody>
        <ModalFooter>
          <SaveButton onClick={toggleModalError} message="Ok, entendi" />
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isModalErrorCategoryOpened}
        toggle={toggleModalErrorCategory}
      >
        <ModalBody>
          <strong> Não é possível concluir a ação.</strong>
          <p>Contas a Pagar de centros de custos relacionados a:</p>
          <p>
            <strong> • Insumos</strong>
          </p>
          <p>
            <strong>• Revendas</strong>
          </p>
          <p>
            {' '}
            devem ser lançados através da página de cadastro ou edição de
            insumos, pois o pagamento deve estar vinculado a uma entrada de
            mercadoria Botão: Ir para a página de insumos Na página, basta
            editar um insumo existente ou cadastrar um novo. O lançamento
            entrará no financeiro automaticamente
          </p>
        </ModalBody>
        <ModalFooter>
          <StyledLink onClick={toggleModalErrorCategory} to="/products/inputs">
            Ir para insumos
          </StyledLink>
          <SaveButton onClick={toggleModalErrorCategory} message="Voltar" />
        </ModalFooter>
      </Modal>
      <Header>
        <div style={{ display: 'flex' }}>
          <h2>Lançamentos</h2>
          {/* <p>Gerencie aqui os lançamentos do seu estabelecimento.</p>
          <p>{hourPhrase}</p> */}
          <div>
            <SelectDataArea>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setMonth}
                color="info"
              >
                Esse mês
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
            </SelectDataArea>
            <Collapse isOpen={isOpen}>
              <Form onSubmit={handleChangeDate} ref={formRef}>
                <DateSelectorDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Inicial"
                      type="datetime-local"
                      name="initialDate"
                      onChange={(e) => setInitialPre(e.target.value)}
                      defaultValue={defaultInicial}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </DateDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Final"
                      type="datetime-local"
                      name="finalDate"
                      onChange={(e) => setFinalPre(e.target.value)}
                      defaultValue={defaultFinal}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </DateDiv>
                  <div>
                    <SubmitDate onClick="submit">Filtrar</SubmitDate>
                  </div>
                </DateSelectorDiv>
              </Form>
            </Collapse>
          </div>
        </div>

        {/* <FullCalendar
          value={dateSelected}
          onDateSelected={(e) => setDateSelected(e)}
        /> */}
        <RegisterButton onClick={toggleModalCreateTransaction} />
      </Header>

      <FilterArea>
        <Row style={{ marginBottom: 15 }}>
          {/* <Col lg="2">
            <ReactSelect
              placeholder="Despesas/ Receitas"
              options={isEarningOptions}
              onChange={(e) => {
                setIsEarning(e);
              }}
              value={isEarning}
              name="is_earning"
            />
          </Col> */}
          <Col lg="2">
            <ReactSelect
              placeholder="Conta"
              options={banks}
              onChange={(e) => {
                setBankParams(e);
              }}
              value={bankParams}
            />
          </Col>
          <Col lg="2">
            <ReactSelect
              placeholder="Forma de pagamento"
              options={paymentMethods}
              onChange={(e) => {
                setPaymenParams(e);
              }}
              value={paymentParams}
            />
          </Col>
          <Col lg="3">
            <ReactSelect
              placeholder="Centro de custo"
              options={categories}
              onChange={(e) => {
                setCategoryParams(e);
              }}
              value={categoryParams}
            />
          </Col>

          <Col lg="2">
            <ReactSelect
              placeholder="Fornecedor"
              options={suppliers}
              onChange={(e) => {
                setProviderParams(e);
              }}
              value={providerParams}
            />
          </Col>

          <Col lg="2">
            <ReactSelect
              placeholder="Situação"
              options={[
                { label: 'Pago', value: true },
                { label: 'Não Pago', value: false },
              ]}
              onChange={(e) => {
                setPaidParams(e);
              }}
              value={paidParams}
            />
          </Col>
          <Col lg="1">
            <DeleteFilterButton onClick={deleteFilters}>
              Limpar
            </DeleteFilterButton>
          </Col>
        </Row>
      </FilterArea>

      <Row
        style={{
          margin: 'auto',
          marginTop: 20,
          marginBottom: 10,
        }}
        className="d-flex justify-content-center"
      >
        <Col sm="12" md="5">
          <Card className="card-stats">
            <CardBody style={{ height: 90, paddingTop: 0 }}>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    {/* <i className="tim-icons icon-money-coins" /> */}
                    <i className="tim-icons">
                      <FiArrowDown />
                    </i>
                    {/* <SiAirtable style={{color: 'white', margin: 'auto'}} size={20}/> */}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Despesas</p>
                    <CardTitle tag="h3" style={{ color: 'red' }}>
                      R$
                      {(isNotEarningTotal || 0).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </CardTitle>
                    <p style={{ fontSize: 12 }}>
                      Pago:{' '}
                      <strong>
                        R$
                        {(isNotEarningTotalPaid || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </strong>
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="5">
          <Card className="card-stats">
            <CardBody style={{ height: 90, paddingTop: 0 }}>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-success">
                    <i className="tim-icons">
                      <FiArrowUp />
                    </i>
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <p className="card-category">Receitas</p>
                    <CardTitle tag="h3" style={{ color: 'green' }}>
                      R$
                      {(isEarningTotal || 0).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </CardTitle>
                    <p style={{ fontSize: 12 }}>
                      Pago:{' '}
                      <strong>
                        R$
                        {(isEarningTotalPaid || 0).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </strong>
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem style={{ marginLeft: -11 }}>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => {
              toggleTab('1');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Contas a Pagar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => {
              toggleTab('2');
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Contas a Receber
          </NavLink>
        </NavItem>
        <button
          style={{
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            border: 'none',
            backgroundColor: '#04bfbf',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 15,
          }}
          onClick={() => handleDownloadSheet()}
        >
          <FaDownload style={{ marginRight: '3px' }} />
          Baixar planilha
        </button>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div style={{ height: 500, overflowY: 'auto', marginTop: -26 }}>
            <Card borderless>
              <thead>
                <tr>
                  {/* <th>
                    <button
                      type="button"
                      className="btn btn-warning"
                      disabled={transactionsToUnify.length < 2}
                      style={{ color: '#fff' }}
                      onClick={toggleModalUnifyTransactions}
                    >
                      Unificar
                    </button>
                  </th> */}
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderDueDate}
                      title="Ordenar por vencimento"
                    >
                      <FiArrowDown />
                      Vencimento
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderDescription}
                      title="Ordenar por descrição"
                    >
                      <FiArrowDown />
                      Descrição
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderProviders}
                      title="Ordenar por fornecedor"
                    >
                      <FiArrowDown />
                      Fornecedor
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderCategoryName}
                      title="Ordenar por centro de custo"
                    >
                      <FiArrowDown />
                      Centro de custo
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      className="true"
                      onClick={handleChangePaidSituation}
                      title="Alterar situação"
                    >
                      Pago?
                    </button>
                  </th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>

              {isNotEarningTransactions &&
                isNotEarningTransactions.length > 0 &&
                isNotEarningTransactions.map((transaction) => (
                  <tbody key={transaction.id}>
                    <Transaction
                      transaction={transaction}
                      handleDeleteTransaction={handleDeleteTransaction}
                      getTransactions={getTransactions}
                      banks={banks}
                      paymentMethods={paymentMethods}
                      suppliers={suppliers}
                      freqOptions={freqOptions}
                      categories={categories}
                      handleAddTransactionToUnify={handleAddTransactionToUnify}
                      isEarningTransaction={false}
                    />
                  </tbody>
                ))}
            </Card>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div style={{ height: 500, overflowY: 'auto', marginTop: -26 }}>
            <Card borderless>
              <thead>
                <tr>
                  <th>
                    <button
                      type="button"
                      className="btn btn-warning"
                      disabled={transactionsToUnify.length < 2}
                      style={{ color: '#fff' }}
                      onClick={toggleModalUnifyTransactions}
                    >
                      Unificar
                    </button>
                  </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      onClick={handleOrderDueDate}
                      title="Ordenar por vencimento"
                    >
                      <FiArrowDown />
                      Recebimento
                    </button>
                  </th>
                  <th>Método</th>
                  <th>Competência</th>
                  <th>Conta Bancária</th>
                  <th>Recebido</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>

              {isEarningTransactions &&
                isEarningTransactions.length > 0 &&
                isEarningTransactions.map((transaction) => (
                  <tbody key={transaction.id}>
                    <Transaction
                      transaction={transaction}
                      handleDeleteTransaction={handleDeleteTransaction}
                      getTransactions={getTransactions}
                      banks={banks}
                      paymentMethods={paymentMethods}
                      suppliers={suppliers}
                      freqOptions={freqOptions}
                      categories={categories}
                      handleAddTransactionToUnify={handleAddTransactionToUnify}
                      isEarningTransaction
                    />
                  </tbody>
                ))}
            </Card>
          </div>
        </TabPane>
      </TabContent>

      <Modal
        isOpen={isModalCreateTransactionOpen}
        toggle={toggleModalCreateTransaction}
        size="lg"
        onClosed={handleSetCheks}
      >
        <ModalHeader> Novo Lançamento</ModalHeader>
        <Form onSubmit={handleSubmit} ref={transactionFormRef}>
          <ModalBody>
            <Row>
              <Col>
                <DataPickerArea>
                  <span>Valor (R$)</span>
                  <NumberFormatStyled
                    prefix="R$"
                    format={currencyFormatter}
                    name="value"
                    onValueChange={(e) => {
                      handleValueChange(e);
                    }}
                  />
                </DataPickerArea>
              </Col>
              <Col>
                <DataPickerArea>
                  <span>
                    Vencimento <MdDateRange />
                  </span>

                  <DatePickerStyled
                    selected={dueDate}
                    onChange={(date) => setDuedate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="__/__/____"
                  />
                </DataPickerArea>
              </Col>
              <Col>
                <DataPickerArea>
                  <>
                    <span>
                      Competência <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={competenceDate}
                      onChange={(date) => setCompetenceDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="__/__/____"
                    />
                  </>
                </DataPickerArea>
              </Col>
              <Col>
                <Select
                  label="Tipo de Lançamento"
                  name="is_earning"
                  options={[
                    { value: false, label: 'Despesa' },
                    { value: true, label: 'Receita' },
                  ]}
                  defaultValue={{ value: false, label: 'Despesa' }}
                  onChange={(e) => setIsEarningOption(e.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Select
                  label="Conta"
                  name="bank_account_id"
                  placeholder="Selecione a conta"
                  options={banks}
                />
              </Col>
              <Col>
                <Select
                  label="Forma de pagamento"
                  name="cash_flow_payment_method_id"
                  placeholder="Selecione a forma de pagamento"
                  options={paymentMethods}
                />
              </Col>
            </Row>

            <Input label="Descrição" name="description" />

            <Row>
              <Col>
                <Select
                  label="Centro de custo"
                  name="cash_flow_category_subcategory_id"
                  placeholder="Centro de custo"
                  options={categories}
                  onChange={(e) => {
                    setCategorySelected(e);
                  }}
                  isDisabled={isEarningOption}
                />
              </Col>
              <Col>
                <Select
                  label="Fornecedor"
                  name="provider_id"
                  placeholder="Informe o fornecedor"
                  options={suppliers}
                />
              </Col>
            </Row>

            <Input label="Linha digitável" name="information" />

            <CheckboxInput
              name="paid"
              label="Pago"
              onChange={handleSetPaidCheck}
            />

            {paidCheck && (
              <Row>
                <Col lg="3">
                  <DataPickerArea>
                    <>
                      <span>
                        Pago em <MdDateRange />
                      </span>

                      <DatePickerStyled
                        selected={paidAtDate}
                        onChange={(date) => setpaidAtDate(date)}
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="__/__/____"
                      />
                    </>
                  </DataPickerArea>
                </Col>
              </Row>
            )}

            <CheckboxInput
              name="repit"
              label="Recorrente"
              onChange={handleSetRepitCheck}
            />

            {repitCheck && (
              <Row>
                <Col>
                  <Input
                    label="Qtd repetições"
                    name="freq_value"
                    type="number"
                  />
                </Col>
                <Col>
                  <Select
                    label="Frequência"
                    name="freq_type"
                    options={freqOptions}
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default Transactions;
