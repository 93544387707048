import styled, { css } from 'styled-components';
import { Row } from 'reactstrap';
import { lighten } from 'polished';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;
export const FiscalMessage = styled.div`
  background: #d3d3d3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  height: 119px;
  padding: 10px;
  p {
    font-weight: bold;
  }
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;

export const SelectStyled = styled(ReactSelect)`
  width: 90%;
  text-align: left;
`;

export const InputStyled = styled.input`
  width: 70%;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding-right: 25px;

  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;

export const SubmitStyledButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: none;

  margin-left: 8px;

  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const ButtonStyle = styled.button`
  height: 25px;
  border-radius: 5px;
  padding: 5px;
  // padding-left: 10px;
  // padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #dc3545;
  // margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 100%;
  padding: auto;
  font-size: 15px;
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const InputsTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 15px auto 15px 0;
  border-collapse: initial;
  background: ${(props) => props.theme.colors.white};

  border-radius: 8px;
  padding: 20px 30px;
  position: relative;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const IfoodTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 10px auto 10px 0;
  border-collapse: initial;
  border-spacing: 0 10px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  padding: 0 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Card = styled.div``;

export const CheckboxInfo = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const StockBanner = styled.div`
  width: 100%;
  background: #ecfffd;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 20px;

  margin-top: 10px;

  p {
    text-align: left;
    font-size: 20px;
    color: #414141;
    font-weight: bold;
  }

  span {
    font-size: 18px;
    color: #414141;
  }

  img {
    margin-right: 20px;
  }
`;

export const SwitcherArea = styled.div`
  p {
    font-weight: bold;
  }
  height: 116px;
  background: #f5f5f5;

  display: flex;
  align-items: center;
  padding: 10px;

  margin-top: 10px;
`;

export const ButtonAdd = styled.button`
  color: #ff2c3a;
  text-decoration: underline;
  border: none;
  font-weight: bold;
  background-color: transparent;
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
`;

export const ColorTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  content: ' ';
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

export const CheckBoxContainer = styled(Row)`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const NumberFormatStyledValue = styled.input`
  height: 44px;
  width: 70%;
 
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: calc(100% - 54px);
  max-width: 120px;
  min-width: 82px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const CmvButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;

  span {
    margin-left: 6px;
  }

  input {
    margin-right: 5px;
  }
`;

// export const SubmitStyledButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 44px;
//   width: 44px;
//   padding: 10px;
//   border-radius: 8px;
//   border: 1px solid transparent;
//   margin-left: 8px;
//   background-color: #0ccab4;
//   svg {
//     color: #fff;
//   }
//   &:disabled {
//     pointer-events: none;
//     cursor: not-allowed;
//     background-color: transparent;
//     border: 1px solid ${(props) => props.theme.colors.lightGray};
//     svg {
//       filter: opacity(0.3);
//       color: #333;
//     }
//   }
// `;
