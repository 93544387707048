import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;

  div {
    svg {
      margin-left: 4px;
      color: #ff1814;
    }
  }

  label {
    font-weight: bold;
  }

  input {
    height: 44px;
    padding: 10px;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #33333320;
    transition: all 1s;

    &:focus {
      border-color: red;
    }

    &:disabled {
      background: #e3e3e3;
    }
  }
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    font-weight: bold;
  }

  textarea {
    height: 120px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    resize: none;

    &:focus {
      border: 1px solid ${(props) => props.theme.colors.gray};
      border-radius: 8px;
    }
  }
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const SelectContainer = styled.div`
  label {
    font-weight: bold;
    padding-top: 10px;
  }
`;
export const CheckboxContainer = styled.div`
  // height: 100%;
  display: flex;
  align-items: center;
  input {
    margin: auto 0;
  }
  label {
    margin-left: 15px;
    font-weight: bold;
    padding-top: 8px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin: auto 0;
  }
  label {
    margin-left: 15px;
    font-weight: bold;
    padding-top: 8px;
  }
`;

export const InputPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80%;

  label {
    font-weight: bold;
  }

  input {
    width: 100px;
    padding: 5px;
    font-size: 15px;
    border-radius: 8px;
    border-color: green;
    border-effect: none;
    border: 2px solid green;

    &:focus {
      border: 2px solid green;
      border-radius: 8px;
    }
  }
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.secondary};
  }
`;
