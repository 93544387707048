import React from 'react';

import { Spinner } from 'reactstrap';
import { Content, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco, IconTakeat } from './styles';
import OrderNew from '~/components/Orders/Items/OrderNew';
import iconTakeat from '~/assets/img/icon-site.svg';

export default function CardOrdersNew({
  baskets,
  handlePrintItem,
  handleCancelItem,
  loading,
  setItemIfoodId,
}) {
  const count_orders = baskets.filter(
    (order) => order.table.table_type === 'delivery' && !order.scheduled_to
  ).length;

  return (
    <Bloco>
      <Header>
        <IconTakeat>
          <img src={iconTakeat} alt="Logo" />
        </IconTakeat>
        NOVOS PEDIDOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          baskets.length >= 1 &&
          baskets
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .map((item) => (
              <OrderNew
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handlePrintItem={handlePrintItem}
                item={item}
                setItemIfoodId={setItemIfoodId}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
