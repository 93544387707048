import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import { BiTransfer } from 'react-icons/bi';
import { format, addHours } from 'date-fns';
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { FaPrint } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import formatPhone from '~/services/formatPhoneString';
import {
  Body,
  Phone,
  Price,
  Items,
  Item,
  NamePrice,
  ItemName,
  PriceItem,
  ReopenButton,
} from './styles';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import SelectTable from '~/components/Form/SelectTable';
import EyeOrderBillItem from '../EyeOrderBillItem';
import EyeOrderBillCashback from '../EyeOrderBillCashback';
import Input from '~/components/Form/Input';
import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';

export default function EyeOrderBillOperation({
  bill,
  session,
  setSessions,
  inicialDate,
  finalDate,
  toggleEyeOpen,
  length,
  getSessions,
  getTablesAvailable,
  getTableSessions,
  handleSearchInputTables,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  setIsDivideBillsAreaOpen,
}) {
  const { user } = useAuth();

  const componentRef = useRef(null);
  const [tables, setTables] = useState([]);
  const [, setTableChanged] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [modalTransfer, setModalTransfer] = useState(false);
  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const [transferDataState, setTransferDataState] = useState();

  const userName = bill?.buyer
    ? formatPhone(bill?.buyer.phone)
    : bill?.waiter?.name;

  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  const getTables = useCallback(async (id) => {
    const response = await api.get('/restaurants/tables');
    const tablesData = response.data;

    const tablesFiltered = tablesData.filter(
      (table) => table.table_type === 'table' || table.table_type === 'command'
    );
    const tablesOptions = [];

    tablesFiltered.forEach((table) => {
      let status;
      if (table.status === 'available') {
        status = 'Disponível';
      } else {
        status = 'Em Uso';
      }

      tablesOptions.push({
        value: table.id,
        label: `${table.table_number.toString()} - ${status}`,
        status: table.status,
      });
    });

    setTables(tablesOptions);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleVerifyTransferPassword(data) {
    setIsSubmiting(true);
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });

    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }
  async function handleTransferTableBill(data) {
    try {
      setIsSubmiting(true);
      if (bill.buyer) {
        await api.put('restaurants/transfer-table-client', {
          key: session.key,
          table_id: data.new_table_id,
          buyer_id: bill?.buyer.id,
        });
      } else {
        await api.put('restaurants/transfer-table-client', {
          key: session?.key,
          table_id: data.new_table_id,
          waiter_id: bill?.waiter.id,
        });
      }

      await delay(1000);
      const response = await api.get('/restaurants/table-sessions', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const tablesFiltered = response.data.filter(
        (item) => item.table.table_type !== 'delivery'
      );

      setIsSubmiting(false);
      setSessions(tablesFiltered);
      setTableChanged(data.new_table_id);
      toggleTransfer();
      setInputValue('');
      setAllTablesAvailable('');
      setTablesFilteredSearch('');
      toggleEyeOpen();

      getTablesAvailable();
      toast.success('Cliente transferido com sucesso!');
    } catch (error) {
      setIsSubmiting(false);
      toast.error('Erro ao transferir cliente.');
      console.log('error', error);
    }
  }

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  return (
    <React.Fragment key={bill.id}>
      <Body>
        <Phone>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ lineHeight: '16px' }}>
              <strong>{userName}</strong>
              {bill?.buyer?.name && (
                <p style={{ marginTop: 10 }}>{bill?.buyer?.name}</p>
              )}
            </div>
            <ReactToPrint
              trigger={() => (
                <FaPrint
                  size={21}
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                />
              )}
              content={() => componentRef.current}
            />
          </div>

          {length > 1 && (
            <Price>
              <ReopenButton onClick={toggleTransfer}>
                <BiTransfer size={17} color="#ffc210" />
                {/* {bill?.buyer
                  ? 'Trocar Cliente de Mesa'
                  : 'Trocar Pedidos do Garçom de Mesa'} */}
              </ReopenButton>
            </Price>
          )}
        </Phone>
      </Body>

      <Items>
        {bill?.order_baskets?.map((basket) => (
          <React.Fragment key={basket.id}>
            {basket.orders.map((order) => (
              <EyeOrderBillItem
                order={order}
                bill={bill}
                basket={basket}
                basketLength={basket.orders.length}
                billLength={bill?.order_baskets.length}
                length={length}
                getTables={getTables}
                changeValue={changeValue}
                inicialDate={inicialDate}
                finalDate={finalDate}
                setSessions={setSessions}
                session={session}
                toggleEyeOpen={toggleEyeOpen}
                userName={userName}
                tables={tables}
                getSessions={getSessions}
                getTablesAvailable={getTablesAvailable}
                handleSearchInputTables={handleSearchInputTables}
                getTableSessions={getTableSessions}
                setAllTablesAvailable={setAllTablesAvailable}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                setIsDivideBillsAreaOpen={setIsDivideBillsAreaOpen}
              />
            ))}
          </React.Fragment>
        ))}

        {bill?.rescues?.map((rescue) => (
          <EyeOrderBillCashback rescue={rescue} />
        ))}
        {user.has_service_tax && (
          <Item>
            <NamePrice>
              <ItemName>Serviços ({user.service_tax}%)</ItemName>
              <PriceItem>
                R$
                {(bill?.total_service_price - bill?.total_price).toFixed(2)}
              </PriceItem>
            </NamePrice>
          </Item>
        )}

        <Item style={{ borderBottom: '1px solid black', paddingBottom: 10 }}>
          <NamePrice>
            <ItemName>
              {' '}
              <strong>Total</strong>
            </ItemName>
            <PriceItem>
              <strong>
                R$
                {bill?.rescues?.length > 0
                  ? (
                      bill?.total_service_price -
                      parseFloat(bill?.rescues[0].cashback)
                    ).toFixed(2)
                  : parseFloat(bill?.total_service_price).toFixed(2)}
              </strong>
            </PriceItem>
          </NamePrice>
        </Item>
      </Items>
      <div style={{ display: 'none' }}>
        <PrintBillsIndividual
          item={{
            bill,
            table: session?.table,
          }}
          restaurant={user}
          ref={componentRef}
        />
      </div>

      <Modal
        isOpen={isModalTransferPasswordOpen}
        toggle={toggleModalTransferPassword}
      >
        <Form onSubmit={handleVerifyTransferPassword}>
          <ModalBody>
            <Input
              name="order_transfer_password"
              label="Senha de Transferência"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              style={{ background: 'green' }}
              disabled={isSubmiting}
            >
              Transferir
            </Button>

            <Button
              onClick={toggleModalTransferPassword}
              style={{ background: '#dc3545' }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
        <Form onSubmit={handleTransferTable}>
          <ModalBody>
            <h3>Transferência de Mesa</h3>
            <h5>{userName}</h5>
            {tables.length !== 0 ? (
              <Row>
                <Col md="5">
                  <SelectTable
                    name="new_table_id"
                    label="Nova mesa:"
                    options={tables.filter(
                      (a) => a.value !== session?.table?.id
                    )}
                  />
                </Col>
                <Col md="6">
                  <h6>
                    Mesa atual: <strong>{session?.table?.table_number}</strong>
                  </h6>
                </Col>
              </Row>
            ) : (
              <p style={{ color: 'red' }}>
                Não há nenhuma mesa disponível no momento.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            {tables.length !== 0 && (
              <Button
                type="submit"
                style={{ background: 'green' }}
                disabled={isSubmiting}
              >
                Transferir
              </Button>
            )}
            <Button onClick={toggleTransfer} style={{ background: '#dc3545' }}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
