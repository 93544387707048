import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import { MdDone, MdArrowDropDown } from 'react-icons/md';
import { Spinner, Row, Col, Collapse } from 'reactstrap';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours,
} from 'date-fns';
import { Form } from '@unform/web';
import { Input } from '@material-ui/core';
import { toast } from 'react-hot-toast';
import {
  Header,
  Bloco,
  SelectDateButton,
  SubmitDate,
  DateDiv,
  DateSelectorDiv,
  Container,
} from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderFinishedDelivery from '../../Items/OrderFinishedDelivery';
import api from '~/services/api';
import { useOrder } from '~/context/orderContext';
import { useAuth } from '~/context/AuthContext';

export default function CardOrdersFinishedDelivery({ handleCancelItem }) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const { finishedBaskets } = useOrder();
  const [loadingFinished, setLoadingFinished] = useState(false);

  const defaultInicial = useMemo(() => {
    return startOfDay(subHours(new Date(), 27)).toISOString();
  }, []);
  const defaultFinal = useMemo(() => {
    return endOfDay(new Date()).toISOString();
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  function setToday() {
    setInicialDate(startOfDay(subHours(new Date(), 27)).toISOString());
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setInicialDate(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }).toISOString()
    );
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth() {
    setInicialDate(startOfMonth(subHours(new Date(), 3)).toISOString());
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfMonth(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(new Date(initialPre).toISOString());
    setFinalDate(new Date(finalPre).toISOString());
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
  }
  const [sessions, setSessions] = useState([]);

  const getSessions = useCallback(async () => {
    try {
      setLoadingFinished(true);
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          filter_all: true,
          is_delivery: true,
          status: 'completed',
        },
      });

      const sessionsMinimalFinished = response.data.filter(
        (session) => session.status === 'completed' && session.is_delivery
      );

      setSessions(sessionsMinimalFinished);
      setLoadingFinished(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
      setLoadingFinished(false);
    }
  }, [finalDate, inicialDate]);

  useEffect(() => {
    getSessions();
  }, [getSessions, finishedBaskets]);

  return (
    <Container>
      <Row>
        <Col md="4">
          <p>{hourPhrase}</p>
        </Col>
        <Col md="8">
          <div style={{ float: 'right' }}>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={setToday}
              color="info"
            >
              Hoje / Ontem
            </SelectDateButton>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={setWeek}
              color="info"
            >
              Essa semana
            </SelectDateButton>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={setMonth}
              color="info"
            >
              Esse mês
            </SelectDateButton>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={toggle}
              color="info"
            >
              Selecionar Período
              <MdArrowDropDown color="white" size={20} />
            </SelectDateButton>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <div style={{ float: 'right', marginRight: '15px' }}>
            <Collapse isOpen={isOpen}>
              <Form onSubmit={handleChangeDate} ref={formRef}>
                <DateSelectorDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Inicial"
                      type="datetime-local"
                      name="initialDate"
                      onChange={(e) => setInitialPre(e.target.value)}
                      defaultValue={defaultInicial}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </DateDiv>
                  <DateDiv>
                    <Input
                      id="datetime-local"
                      label="Data Final"
                      type="datetime-local"
                      name="finalDate"
                      onChange={(e) => setFinalPre(e.target.value)}
                      defaultValue={defaultFinal}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </DateDiv>
                  <div>
                    <SubmitDate onClick="submit">Filtrar</SubmitDate>
                  </div>
                </DateSelectorDiv>
              </Form>
            </Collapse>
          </div>
        </Col>
      </Row>
      <Bloco>
        <Header>
          <Icon>
            <MdDone color="#fff" size={25} />
          </Icon>
          FINALIZADOS
          <ItemsCount>{sessions?.length}</ItemsCount>
        </Header>
        <Content>
          {loadingFinished ? (
            <LoadingDiv>
              <Spinner color="success" />
            </LoadingDiv>
          ) : (
            sessions.length > 0 &&
            sessions.map((session) => (
              <OrderFinishedDelivery
                handleCancelItem={handleCancelItem}
                session={session}
                user={user}
                getSessions={getSessions}
              />
            ))
          )}
        </Content>
      </Bloco>
    </Container>
  );
}