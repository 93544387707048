import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 10px;
  display: ${(props) => !props.show && 'none'};
  position: relative;
`;

export const YoutubeContainer = styled.div`
  ${(props) => (props.hidden ? 'none' : 'flex')};
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 15px;
  z-index: 500;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 16px 16px 11px 16px;

  iframe {
    border-radius: 15px;
    width: 360px;
    aspect-ratio: 16/9;
  }

  h1 {
    font-size: 18px;
    font-weight: 700;
    font-family: Poppins;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  color: #fe1933;
  justify-content: center;
  align-items: center;
  border: 2px solid #fe1933;
  padding: 3px 13px 3px 3px;
  border-radius: 30px;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 600;
  transition: all 0.2s;

  &:hover {
    background-color: #fe1933;
    color: #ffffff;
  }
`;

export const Icon = styled.div`
  border: 2px solid #fe1933;
  border-radius: 20px;
  padding: 0 5px;
  margin-right: 10px;
`;

export const ModalYoutube = styled(Modal)`
  .modal-content {
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 15px;
  }

  iframe {
    border-radius: 15px;
    width: 720px;
    height: 405px;
  }

  h1 {
    font-size: 18px;
    font-weight: 700;
    font-family: Poppins;
    margin-bottom: 20px;
  }
`;
