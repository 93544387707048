import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Table } from 'reactstrap';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 25px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableArea = styled.div`
  margin-top: 35px;

  h5 {
    margin-left: 40px;
  }
`;

export const Header = styled.div`
  margin: 20px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  width: 95%;
  min-width: 400px;
  margin: 0 auto;
  margin-top: 15px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 20px;
`;

export const CategoriesTable = styled(Table)`
  width: 100%;
  min-width: 400px;

  svg {
    cursor: pointer;
  }

  thead {
    text-align: center;
    tr {
      th:first-child {
        text-align: left;
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      text-align: center;
    }
    td:first-child {
      text-align: left;
    }
    td:last-child {
      text-align: right;
    }

    td:nth-child(1) {
      width: 20%;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;
