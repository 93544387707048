import { Form } from '@unform/web';
import { endOfDay, format, startOfDay, subHours } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { CarouselItem, Modal, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import Input from '~/components/Form/Input';
import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import ButtonDefault from '~/components/Buttons/DefaultButton';
import { CarouselControlStyled, CarouselIndicatorsStyled, CarouselStyled, Container, ReconnectButton, WppModal } from './styles';
import OrdersPage from './OrdersPage';
import Select from '~/components/Form/SelectInput';
import wppLostConnection from '../../../assets/img/wpp_lost_connection.png';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';


import slide1 from '~/assets/img/BANNER01.png'
import slide2 from '~/assets/img/BANNER02.png'
import slide3 from '~/assets/img/BANNER03.png'
import slide4 from '~/assets/img/BANNER04.png'
import slide5 from '~/assets/img/BANNER05.png'
import slide6 from '~/assets/img/BANNER06.png'
import slide7 from '~/assets/img/BANNER07.png'
import slide8 from '~/assets/img/BANNER08.png'
import slide9 from '~/assets/img/BANNER09.png'
import slide10 from '~/assets/img/BANNER10.png'
// import PersonalOutlineButton from '~/components/Buttons/PersonalOutlineButton';
// import PersonalButton from '~/components/Buttons/PersonalButton';
function DeliveryOrdersPage() {
  const [sessionId, setSessionId] = useState();
  const cancelForm = useRef(null);
  const { created, setCreated } = useOrder();
  const [itemIfoodId, setItemIfoodId] = useState(null);
  const [ifood_motives, setIfoodMotives] = useState([]);
  const {
    user,
    modalOperationMessageWhatsappOnline,
    updateUiModalContext,
    setUpdateUiModalContext

  } = useAuth();
  const [wppModal, setWppModal] = useState(true);
  const wppConnected = JSON.parse(
    localStorage.getItem('@gddashboard:wppConnected')
  );

  const toggleWppModal = () => {
    setWppModal(!wppModal);
  };
  const [isModalWhatsappOnlineOpened, setIsModalWhatsappOnlineOpened] = useState(modalOperationMessageWhatsappOnline.toString() === "true")


  function toggleWhatsappOnlineModal() {
    setIsModalWhatsappOnlineOpened(!isModalWhatsappOnlineOpened)
  }
  const hasKey = user ? user.has_order_cancel_password : false;

  const [modalCancelOrderIsOpened, setModalCancelOrderIsOpened] =
    useState(false);

  async function getIfoodCancellationReasons(session_id) {
    try {
      const response = await api.get(
        `restaurants/ifood/cancellationReasons/${session_id}`
      );

      if (response.data && response.data.length > 0) {
        setIfoodMotives(response.data);
      } else {
        setIfoodMotives([{ value: 200, label: 'Cancelado no ifood' }]);
      }
    } catch (err) {
      toast.error(
        'Não foi possível carregar motivos para cancelar o pedido! tente novamente'
      );
    }
  }

  function handleOpenCancelOrderModal(
    order_basket_id,
    from,
    session_id,
    ifood_id
  ) {
    getIfoodCancellationReasons(session_id);
    setModalCancelOrderIsOpened(true);
    setSessionId(session_id);
    setItemIfoodId(ifood_id);
  }

  const toggleModalCancelOrder = useCallback(() => {
    setModalCancelOrderIsOpened(!modalCancelOrderIsOpened);
    setItemIfoodId(null);
  }, [modalCancelOrderIsOpened]);

  const {
    setLoading,
    getAllOrders,
    moveOrderToAccepted,
    moveOrderToFinished,
    moveOrderToFinishedDelivery,
    moveOrderToReady,
  } = useOrder();

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryCurrentSessions'}`
      );

      const { can_read } = response.data.DeliveryCurrentSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function handlePrintItem(id, ifood_id, is_delivery) {
    try {
      const response = await api.put(`restaurants/orders/${id}`, {
        order_status: 'accepted',
        ifood_id,
        is_delivery,
      });

      moveOrderToAccepted({
        order_basket_id: id,
        accepted_at: response.data?.accepted_at,
      });

      toast.success('Pedido aceito!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemFinished(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      moveOrderToFinished({ order_basket_id: id });
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemFinishedDelivery(id) {
    try {
      await api.put(`restaurants/orders/${id}`, {
        order_status: 'finished',
      });
      moveOrderToFinishedDelivery({ order_basket_id: id });
      toast.success('Pedido entregue!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleItemReady(id, ifood_id, with_withdrawal) {
    try {
      const response = await api.put(`restaurants/orders/${id}`, {
        order_status: 'ready',
        ifood_id,
        with_withdrawal,
      });

      moveOrderToReady({
        order_basket_id: id,
        ready_at: response.data.ready_at,
      });
      toast.success('Pedido pronto pra entrega!');
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  async function handleCancelItemModal(data) {
    try {
      if (cancelForm?.current) {
        cancelForm.current.setErrors({});
      }

      const requiredSchema = Yup.string().required('Campo obrigatório');

      const schema = Yup.object().shape({
        order_cancel_password: Yup.string().concat(
          hasKey ? requiredSchema : null
        ),
        cancel_reason: Yup.string().concat(hasKey ? requiredSchema : null),
        ifood_motive: Yup.string().concat(
          itemIfoodId !== null && itemIfoodId ? requiredSchema : null
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await api.put(`/restaurants/delivery/orders/cancel/${sessionId}`, {
        order_cancel_password: data.order_cancel_password,
        cancel_reason: data.cancel_reason,
        ifood_motive: data.ifood_motive,
      });
      toggleModalCancelOrder();
      getAllOrders();

      toast.success('Pedido cancelado!');
    } catch (error) {
      console.log('error', error);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (cancelForm?.current) {
          cancelForm.current.setErrors(errors);
        }
      } else if (
        error.response?.data.errorType === 'incorrect_order_cancel_password'
      ) {
        toast.error('senha incorreta');
      } else {
        toast.error('Erro ao cancelar pedido!');
      }
    }
  }

  async function handleCancelItem({
    order_basket_id,
    from,
    session_id,
    ifood_id,
  }) {
    try {
      if (itemIfoodId !== null || hasKey) {
        handleOpenCancelOrderModal(order_basket_id, from, session_id, ifood_id);
      } else if (
        window.confirm('Tem certeza que deseja cancelar este pedido?')
      ) {
        await api.put(`/restaurants/delivery/orders/cancel/${session_id}`);
        toast.success('Pedido cancelado!');
        getAllOrders();
      }
    } catch (err) {
      toast.error('Não foi possível atualizar o pedido! tente novamente');
    }
  }

  // Comandas

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subHours(new Date(), 27)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  //modal de serviço de whatsapp

  // function handleSendMessageToTakeat() {
  //   window.open(
  //     'https://api.whatsapp.com/send?phone=5527996350965&utm_source=banner-upsell&utm_medium=dashboard-takeat&utm_campaign=campanha-upselll'
  //   );
  // }

  // const [isModalWhatsappOpened, setIsModalWhatsappOpened] = useState(
  //   modalOperationMessageWhatsapp.toString() === 'true'
  // );

  // function toggleWhatsappModal() {
  //   setIsModalWhatsappOpened(!isModalWhatsappOpened);
  // }



  //modal carousel
  const [updateUiModalOpened, setUpdateUiModalOpened] = useState(updateUiModalContext.toString() === 'true')

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  function toggleUpdateUiModal() {
    setUpdateUiModalOpened(!updateUiModalOpened)
  }

  const items = [
    {
      src: slide1,
    },
    {
      src: slide2,
    },
    {
      src: slide3,
    },
    {
      src: slide4,
    },
    {
      src: slide5,
    },
    {
      src: slide6,
    },
    {
      src: slide7,
    },
    {
      src: slide8,
    },
    {
      src: slide9,
    },
    {
      src: slide10,
    },

  ];

  // Items array length
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Carousel Item Data
  const carouselItemData = items.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        active={index === activeIndex}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      {user?.only_delivery && (
        <Modal
          isOpen={updateUiModalOpened}
          toggle={toggleUpdateUiModal}
          onClosed={() => setActiveIndex(0)}

        >
          <CarouselStyled
            activeIndex={activeIndex}
            next={nextButton}
            previous={previousButton}
            interval={20000}
          >

            {carouselItemData}

            <CarouselControlStyled
              direction="prev"
              directionText="Previous"
              onClickHandler={previousButton}
            />
            <CarouselControlStyled
              direction="next"
              directionText="Next"
              onClickHandler={nextButton}
            />
          </CarouselStyled>
          <CarouselIndicatorsStyled
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <button
                className="btn"
                type="button"
                onClick={() => {
                  toggleUpdateUiModal();
                  setUpdateUiModalContext(false)
                }}
                style={{ background: '#ff403d' }}
              >
                Não desejo ver novamente
              </button>
              <button
                className="btn"
                type="button"
                onClick={() => {
                  toggleUpdateUiModal();

                }}
                style={{ background: '#2ec9b7' }}
              >
                Fechar
              </button>

            </div>


          </ModalFooter>
        </Modal>
      )}
      {/* {!user?.use_wpp && user?.only_delivery && (
        <Modal
          isOpen={isModalWhatsappOpened}
          toggle={toggleWhatsappModal}
          size="md"
        >
          <ModalBody>
            <img
              src={WhatsappBanner}
              alt="banner de venda de serviço de whatsapp"
              style={{ width: '100%' }}
            />
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <PersonalOutlineButton
                borderColor="#2ec9b7"
                color="#fff"
                type="button"
                fontColor="#2ec9b7"
                onClick={() => {
                  setModalOperationMessageWhatsapp(false);
                  setIsModalWhatsappOpened(false);
                }}
                message="Não desejo ver novamente"
              />

              <PersonalButton
                className="btn"
                type="button"
                onClick={() => {
                  handleSendMessageToTakeat();
                }}
                color="#2ec9b7"
                message="Entrar em contato"
              />
            </div>
          </ModalFooter>
        </Modal>
      )} */}
      <OrdersPage
        handleItemFinished={handleItemFinished}
        handleItemFinishedDelivery={handleItemFinishedDelivery}
        handleCancelItem={handleCancelItem}
        handleItemReady={handleItemReady}
        handlePrintItem={handlePrintItem}
        setItemIfoodId={setItemIfoodId}
      />
      <WppModal
        isOpen={wppModal && user.use_wpp && wppConnected === false && wppConnected !== null}
        toggle={toggleWppModal}
      >

        <img src={wppLostConnection} />
        <h1>CONEXÃO PERDIDA COM WHATSAPP</h1>
        <p>
          Atenção, não detectamos conexão com o seu WhatsApp. Clique no botão
          abaixo e reestabeleça conexão o quanto antes.
        </p>
        <ReconnectButton
          onClick={() => {
            toggleWppModal();
            document.getElementById('wpp_button').click();
          }}
        >
          Reestabelecer conexão

        </ReconnectButton>
      </WppModal>

      <Modal isOpen={modalCancelOrderIsOpened} toggle={toggleModalCancelOrder}>
        <Form onSubmit={handleCancelItemModal} ref={cancelForm}>
          <ModalBody>
            {hasKey && (
              <Input
                name="order_cancel_password"
                label="Senha de cancelamento"
                type="password"
              />
            )}
            {hasKey && <Input name="cancel_reason" label="Justificativa" />}
            {itemIfoodId !== null && itemIfoodId && (
              <Select
                name="ifood_motive"
                options={ifood_motives}
                label="Motivo"
              />
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonDefault message="Confirmar" type="submit" />
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={created.length > 0}>
        <ModalBody>
          <h3>Novos itens foram criados</h3>
          <h4>
            Recebemos do iFood produtos e/ou complementos que não estão
            cadastrados em nosso sistema. Por isso, criamos categorias
            exclusivas de produtos iFood e adicionamos esses novos itens nelas.
            Você pode realizar quaisquer alterações que deseja em sua página de
            produtos.
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <div>
              <p>Produtos</p>
              <ul style={{ padding: 0 }}>
                {created
                  .filter((p) => p.ifood_product_id)
                  .map((p) => {
                    return <li key={p.id}>{p.name}</li>;
                  })}
              </ul>
            </div>
            <div>
              <p>Complementos</p>
              <ul style={{ padding: 0 }}>
                {created
                  .filter((p) => p.ifood_complement_id)
                  .map((p) => {
                    return <li key={p.id}>{p.name}</li>;
                  })}
              </ul>
            </div>
          </div>
          <ButtonDefault
            message="OK"
            style={{ margin: '10px auto' }}
            onClick={() => setCreated([])}
          />
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default DeliveryOrdersPage;
