import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '~/services/getValidationErrors';
import { Container, Header, WaitersTable } from './styles';

import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/SaveButton';
import api from '~/services/api';

import ChangePasswordButton from '~/components/Buttons/ChangePasswordButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import Select from '~/components/Form/SelectInput';
import { BsDeviceSsd } from 'react-icons/bs';

function UserProfile() {
  const [isModalChangePassOpen, setIsModalChangePassOpen] = useState(false);
  const [userState, setUserState] = useState();

  const { user, getProfileData } = useAuth();

  const formRef = useRef(null);
  const formChangePassRef = useRef(null);

  const initialValue = {
    name: userState?.name,
    email: userState?.email,
    access: userState?.access,
    stone_device_id: userState?.stone_device_id
  };

  function toggleModalChangePass() {
    setIsModalChangePassOpen(!isModalChangePassOpen);
  }

  const [users, setUsers] = useState([]);
  const [devices, setDevices] = useState([]);

  const getDevices = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/stone/devices`);

      const devices = response.data
        .filter(dev => !dev.waiter)
        .map(dev => {
        return {
          label: dev.serial_number,
          value: dev.id,
          id: dev.id
        }
      });

      devices.push({
        label: 'Nenhum',
        value: null
      });
      
      setDevices(devices);
    } catch (error) {
      toast.error('Falha ao encontrar POS Stone.');
    }
  }, []);

  const getUsers = useCallback(async () => {
    const response = await api.get('/restaurants/users');
    setUsers(response.data);

    const userFound = response.data.filter((us) => us.email === user.email);

    setUserState(userFound[0]);
    // getDevices();
  }, [user]);

  async function handleSubmit(data) {
    try {
      await api.put(`restaurants/users/${userState.id}`, {
        name: data.name,
        email: data.email,
        stone_device_id: data.stone_device_id
      });

      toast.success('Perfil editado com sucesso!');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Falha ao editar Perfil');
    }
  }

  async function handleChangeUser(data) {
    try {
      if (formChangePassRef?.current) {
        formChangePassRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        password: Yup.string().min(6, 'Mínimo de 6 dígitos'),
        oldPassword: Yup.string().min(6, 'Mínimo de 6 dígitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      await api.put(`restaurants/users/${userState.id}`, {
        password: data.password,
        oldPassword: data.oldPassword,
      });

      toast.success('Perfil editado com sucesso!');
      toggleModalChangePass(!isModalChangePassOpen);
      getProfileData();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formChangePassRef?.current) {
          formChangePassRef.current.setErrors(errors);
        }
      } else if (error.response.data.errorType === 'incorrect_data') {
        toast.error('A senha atual está incorreta.');
      } else {
        toast.error('Falha ao editar Perfil');
      }
    }
  }
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterProfile'}`
      );

      const { can_read } = response.data.RegisterProfile;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getUsers();
    getDevices();
  }, [getUsers, getDevices]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Perfil</h2>
          <p>Gerencie aqui seu perfil de usuário.</p>
        </div>
      </Header>

      <WaitersTable>
        <Form onSubmit={handleSubmit} initialData={initialValue} ref={formRef}>
          <Row>
            <Col lg="6">
              <Input name="name" label="Nome" />
            </Col>
            <Col lg="6">
              <Input name="email" label="E-mail" />
            </Col>
          </Row>
          <Row>
          {
            user.has_stone_pdv && userState?.access === 'administrador' &&
              <Col md="12">
                <Select
                  label="POS Stone (S/N):" 
                  name="stone_device_id" 
                  options={devices}
                  defaultValue={devices.find(dev => dev.id === initialValue.stone_device_id) || {value: null, label: 'Nenhum'}}
                />
                {console.log(devices, initialValue,devices.find(dev => dev.id === initialValue.stone_device_id)) }
              </Col>
            }            
           </Row>

          <ChangePasswordButton onClick={toggleModalChangePass} />
          <SaveButton style={{ float: 'right' }} type="submit" />
        </Form>
      </WaitersTable>

      <Modal isOpen={isModalChangePassOpen} toggle={toggleModalChangePass}>
        <ModalHeader>Alterar senha</ModalHeader>
        <Form onSubmit={handleChangeUser} ref={formChangePassRef}>
          <ModalBody>
            <Input name="oldPassword" label="Senha Atual" type="password" />
            <Input name="password" label="Nova Senha" type="password" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" />
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default UserProfile;
