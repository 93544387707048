import styled from 'styled-components';
import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: auto;
  width: 90%;
  background-color: #fff;
  border-radius: 7px;
  padding: 16px;
  margin-top: 10px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
`;

export const ButtonCollapse = styled.div`
  margin-left: 50px;
  border: none;
  background: none;
  margin-bottom: 16px;
  font-weight: bold;

  cursor: pointer;
`;

export const GroupTable = styled(Table)`
  width: 100%;
  margin: 5px auto 3px auto;
  border-collapse: initial;
  border-spacing: 0 0px;

  padding: 0 30px;

  thead {
    border-bottom: 5px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #33333310;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const ButttonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 50px;
  margin-bottom: 20px;
`;

export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 18px;

  span {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const ReorderContainer = styled.div`
  width: 350px;
  margin-bottom: 16px;

  font-size: 25px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const CategoryHeader = styled.div`
  margin: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-left: 14px;
  padding-bottom: 24px;

  border-bottom: 1px solid #33333310;

  div {
    display: flex;
    align-items: flex-end;

    svg {
      cursor: pointer;
    }

    p {
      margin-left: 16px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;

export const ReorderCategoryButton = styled.button`
  border: none;
  background-color: #ff403d;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-weight: bold;

  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border-radius: 4px;

  :hover {
    filter: brightness(0.8);
  }
`;

export const CategoryName = styled.div`
  display: flex;
  flex-direction: column;
`;
