import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: fit-content;
  gap: 20px;
  margin: 8px auto;
  width: 98%;
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  padding: 0px;
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    color: black;
  }
`;
