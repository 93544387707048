import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 80%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const InputPhone = styled(InputMask)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const Label = styled.span`
  margin-bottom: 5px;
`;

export const DivButton = styled.div`
  width: 100%;
  height: 30px;
  style: block;
  position: relative;
`;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;
