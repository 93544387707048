import React from 'react';

import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Footer,
  ItemsArea,
  PartialBillArea,
  StyledTable,
} from './styles';

import formatValue from '~/utils/formatValue';

const PrintBillsDivide = React.forwardRef(({ item, billsDivideItems }, ref) => {
  const { user } = useAuth();

  const subtotal =
    billsDivideItems && billsDivideItems?.length > 0
      ? billsDivideItems?.reduce((accum, curr) => {
        return accum + Number(curr.price);
      }, 0)
      : 'R$ 0,00';

  return (
    <Container ref={ref}>
      {item ? (
        <React.Fragment key={item?.id}>
          <Table>
            <strong>
              {item?.table?.is_balcony
                ? 'Balcão'
                : ` MESA  ${item?.table?.table_number}`}
            </strong>
          </Table>
          <Bill>
            <strong>Comanda Parcial #{item?.id}</strong>
          </Bill>
          {item?.is_prepaid && (
            <Bill>
              <strong>Pagamento antecipado</strong>
            </Bill>
          )}

          <Restaurant>
            <strong>{user.fantasy_name}</strong>
          </Restaurant>

          <ItemsArea>
            <h4>Itens</h4>

            <PartialBillArea>
              <StyledTable>
                <tr>
                  <th>Qtde</th>
                  <th>Item</th>
                  <th>Valor</th>
                  <td />
                </tr>
                <tbody>
                  {billsDivideItems &&
                    billsDivideItems?.length > 0 &&
                    billsDivideItems?.map((item) => (
                      <tr>
                        <td>{Number(item?.amount).toFixed(2)}x</td>
                        <td>{item?.name}</td>
                        <td>{formatValue(item?.price)}</td>
                        <td />
                      </tr>
                    ))}
                </tbody>
              </StyledTable>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {user.has_service_tax && (
                  <span>
                    Serviços ({user.service_tax}% - Opcional):
                    {` ${formatValue(subtotal * (user.service_tax / 100))}`}
                  </span>
                )}

                {user.has_service_tax ? (
                  <span style={{ fontSize: 20 }}>
                    TOTAL:
                    {` ${formatValue(
                      subtotal + subtotal * (user.service_tax / 100)
                    )}`}
                  </span>
                ) : (
                  <span style={{ fontSize: 20 }}>
                    TOTAL:{` ${formatValue(subtotal)}`}
                  </span>
                )}

                {user.has_service_tax && (
                  <span style={{ fontSize: 20 }}>
                    SUB TOTAL:{` ${formatValue(subtotal)}`}
                  </span>
                )}
              </div>
            </PartialBillArea>
          </ItemsArea>

          <Footer>
            <strong>Desenvolvido por Takeat</strong>
          </Footer>
        </React.Fragment>
      ) : (
        <div />
      )}
    </Container>
  );
});

export default PrintBillsDivide;
