/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import ReactToPrint from 'react-to-print';

import { toast } from 'react-hot-toast';

import JSZip from 'jszip';
import FileSaver from 'file-saver';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  differenceInDays,
} from 'date-fns';

import {
  Row,
  Col,
  Collapse,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { Input } from '@material-ui/core';
import { Form } from '@unform/web';

import { HiOutlineMail } from 'react-icons/hi';
import { AiFillFileZip } from 'react-icons/ai';
import { FiDollarSign } from 'react-icons/fi';
import { MdArrowDropDown } from 'react-icons/md';

import {
  Wrapper,
  Header,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  Card,
  Content,
  SelectDateButton,
  DisplayNoneImprimir,
  ButtonAloneArea,
  DiscountButton,
  ButtonsArea,
  DataPickerArea,
  DatePickerStyled,
  Progress2,
} from './styles';

import Nfces from '~/components/Nfces';
import Select from '~/components/Form/SelectInput';
import PrintPaymentReportFiscal from '~/components/Prints/PrintPaymentReportFiscal';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import RegisterButton from '~/components/Buttons/RegisterButton';
import NfeAvulsa from '~/components/NfesEntry/NfeAvulsa';
import NfeInutilizacao from '~/components/NfesEntry/NfeInutilizacao';
import InputSimple from '~/components/Form/InputSimple';

import api from '~/services/api';

export default function NFCeHistory() {
  const formRef = useRef(null);
  const componentRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(
      startOfDay(subHours(new Date(), 27)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [rangeOn, setRangeOn] = useState(true);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 27)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    setRangeOn(true);

    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;

    setHourPhrase(phrase);
    setMonthYearDate(date);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    }
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [nfces, setNfces] = useState([]);
  const [payments, setPayments] = useState([]);
  const [nfcesFiltered, setNfcesFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const getNfces = useCallback(async () => {
    try {
      let response = await api.get('restaurants/nfces', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      setNfces(response.data.nfces);
      setNfcesFiltered(response.data.nfces);
      setPayments(response.data?.payments || []);
      setSearch('');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate, setSearch]);

  useEffect(() => {
    getNfces();
    setLoading(false);
  }, [getNfces]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [typeSelected, setTypeSelected] = useState('both');
  const options_nf = [
    { value: 'both', label: 'NFCe e NFe' },
    { value: 'nfce', label: 'NFCe' },
    { value: 'nfe', label: 'NFe' },
  ];

  const default_nf = [{ value: 'both', label: 'NFCe e NFe' }];

  useEffect(() => {
    if (typeSelected !== 'both') {
      const newNfces = nfces.filter((nfce) => nfce.type === typeSelected);
      setNfcesFiltered(newNfces);
    } else {
      setNfcesFiltered(nfces);
    }
  }, [typeSelected, nfces]);

  const searchTimer = useRef(0);

  function handleSearchChange(event) {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    const value = event.target.value;

    searchTimer.current = setTimeout(() => {
      setSearch(value ? value.trim() : '');

      searchTimer.current = 0;
    }, 200);
  }

  useEffect(() => {
    if (search !== '') {
      // numero, ref, total_price
      const newNfces = nfces.filter((nfce) => {
        return (
          nfce.ref.includes(search) ||
          nfce.numero.toString() === search ||
          parseFloat(nfce.total_price) === parseFloat(search.replace(',', '.'))
        );
      });

      setNfcesFiltered(newNfces);
    } else {
      setNfcesFiltered(nfces);
    }
  }, [search, nfces]);

  const [modal, setModal] = useState(false);
  async function toggleEmail() {
    setModal(!modal);
  }

  const sendXMLEmail = useCallback(
    async (data) => {
      try {
        await api.post('restaurants/nfces-sent-email', {
          email: data.email,
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        });
        toast.success('E-mail enviado com sucesso!');
        document.getElementById('close_modal').click();
      } catch (err) {
        toast.error('Não foi possível enviar o e-mail;');
      }
    },
    [inicialDate, finalDate]
  );

  function partition(array, n = 5) {
    const array2 = [...array];
    const pieces = [];

    while (array2.length > 0) {
      pieces.push(array2.splice(0, n));
    }

    return pieces;
  }

  const terminateDownload = useRef(false);
  const [zipDownloadProgress, setZipDownloadProgress] = useState(0);

  const downloadZip = useCallback(async () => {
    if (nfces.length === 0) {
      terminateDownload.current = true;
      return;
    }

    const autorizados = nfces.filter((nfce) => nfce.status === 'autorizado');
    const cancelados = nfces.filter((nfce) => nfce.status === 'canceled');

    const xmls = autorizados.map((n) => n.nfce_xml);
    const xmlsCanceladasAutorizacao = cancelados.map((n) => n.nfce_xml);
    let xmlsCanceladasCancelamento = cancelados.map((n) => n.nfce_xml_cancelamento);

    xmlsCanceladasCancelamento = xmlsCanceladasCancelamento.filter(xm => xm !== null);
    
    // Downloading by batches of 10
    const partitions = partition(xmls, 15);
    const partitionsCanceladasAutorizacao = partition(xmlsCanceladasAutorizacao, 15);
    const partitionsCanceladasCancelamento = partition(xmlsCanceladasCancelamento, 15);

    console.log('@@ xmls count', nfces.length, xmls.length, partitions.length);

    let files = [];
    let filesCanceladasAutorizacao = [];
    let filesCanceladasCancelamento = [];

    //Autorizadas
    // TODO: Create treatment for failed
    for (const p of partitions) {
      if (terminateDownload.current) {
        console.log('@@ force finish');
        return;
      }

      const { data } = await api.post('/restaurants/nfce/xmls/download', {
        xmls: p,
      });

      setZipDownloadProgress((v) => v + data.length);

      files = files.concat(data.map((item) => item.data));
    }

    const zip = new JSZip();
    files.forEach((blob, i) => {
      const [name] = xmls[i].split('/').slice(-1);

      zip.folder('Autorizadas').file(name, blob, { base64: false });
    });

    //Canceladas - XML de Autorização
    for (const p of partitionsCanceladasAutorizacao) {
      if (terminateDownload.current) {
        console.log('@@ force finish');
        return;
      }

      const { data } = await api.post('/restaurants/nfce/xmls/download', {
        xmls: p,
      });

      setZipDownloadProgress((v) => v + data.length);

      filesCanceladasAutorizacao = filesCanceladasAutorizacao.concat(data.map((item) => item.data));
    }

    filesCanceladasAutorizacao.forEach((blob, i) => {
      const [name] = xmlsCanceladasAutorizacao[i].split('/').slice(-1);

      zip.folder('Canceladas - XML de Autorização').file(name, blob, { base64: false });
    });

    //Canceladas - XML de Cancelamento
    for (const p of partitionsCanceladasCancelamento) {
      if (terminateDownload.current) {
        console.log('@@ force finish');
        return;
      }

      const { data } = await api.post('/restaurants/nfce/xmls/download', {
        xmls: p,
      });

      setZipDownloadProgress((v) => v + data.length);

      filesCanceladasCancelamento = filesCanceladasCancelamento.concat(data.map((item) => item.data));
    }

    filesCanceladasCancelamento.forEach((blob, i) => {
      const [name] = xmlsCanceladasCancelamento[i].split('/').slice(-1);

      zip.folder('Canceladas - XML de Cancelamento').file(name, blob, { base64: false });
    });


    const fileName = `Notas Fiscais.zip`;
    const zipFile = await zip.generateAsync({ type: 'blob' });
    
    FileSaver.saveAs(zipFile, fileName);

    terminateDownload.current = true;
    setZipModal(false);
  }, [nfces, setZipDownloadProgress]);

  const [zipModal, setZipModal] = useState(false);

  async function toggleZip() {
    if (!zipModal) {
      terminateDownload.current = false;
      setZipDownloadProgress(0);
      downloadZip();
    } else {
      terminateDownload.current = true;
    }

    setZipModal(!zipModal);
  }

  const [permission, setPermission] = useState();

  const [modalAvulsa, setModalAvulsa] = useState(false);
  const toggleModalAvulsa = () => setModalAvulsa(!modalAvulsa);

  const [modalInutilizacao, setModalInutilizacao] = useState(false);
  const toggleModalInutilizacao = () => setModalInutilizacao(!modalInutilizacao);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalEmittedNF'}`
      );

      const { can_read } = response.data.FiscalEmittedNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <Row style={{ width: '100%' }}>
          <Col md="8">
            <h2>Histórico de Notas Fiscais</h2>
            <p>
              Aqui você acompanha o histórico de NFCe emitidas pelo seu
              restaurante.
            </p>
            <p>
              <strong>
                Obs.: só é possível cancelar a NFCe por no máximo 30 minutos
                após a criação.
              </strong>
            </p>
          </Col>
          <Col md="4">
            <RegisterButton
              id="inutilizacao_button" 
              style={{ float: 'right', marginLeft: 10 }}
              text="Inutilização"
              onClick={toggleModalInutilizacao}
            />
            <RegisterButton
              id="register_button"
              style={{ float: 'right' }}
              text="Nota"
              onClick={toggleModalAvulsa}
            />
          </Col>
        </Row>
      </Header>
      <Card>
        <Content>
          <Row>
            <Col md="4">
              <p>{hourPhrase}</p>
            </Col>
            <Col md="8">
              <div style={{ float: 'right' }}>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setToday}
                  color="info"
                >
                  Hoje / Ontem
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setWeek}
                  color="info"
                >
                  Essa semana
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={() => setMonth(new Date())}
                  color="info"
                >
                  Esse mês
                </SelectDateButton>

                <DataPickerArea>
                  <DatePickerStyled
                    selected={monthYearDate}
                    onChange={(date) => setMonth(date)}
                    locale="pt"
                    showMonthYearPicker
                    customInput={
                      <SelectDateButton
                        className="btn-round mr-auto"
                        color="info"
                      >
                        Mês/Ano <MdArrowDropDown color="white" size={20} />
                      </SelectDateButton>
                    }
                  />
                </DataPickerArea>

                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={toggle}
                  color="info"
                >
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </Col>
            <p
              style={{
                textAlign: 'right',
                color: 'red',
                display: rangeOn ? 'none' : 'block',
              }}
            >
              Favor selecionar o intervalo máximo de 31 dias entre as datas.
            </p>
          </Row>

          <Row style={{ height: 50 }}>
            <Col md="2">
              <Form>
                <Select
                  name="type_nf"
                  options={options_nf}
                  defaultValue={default_nf}
                  label="Tipo:"
                  onChange={(e) => setTypeSelected(e.value)}
                />
              </Form>
            </Col>

            <Col md="2">
              <Form>
                <InputSimple
                  name="search"
                  label="Pesquise:"
                  type="text"
                  placeholder="ref, número, valor"
                  style={{height: "38px"}}
                  onChange={handleSearchChange}
                />
              </Form>
            </Col>
            <Col md="8">
              <ButtonsArea>
                <ButtonAloneArea style={{ float: 'right' }}>
                  <DiscountButton
                    onClick={toggleZip}
                    style={{ background: '#113c51' }}
                  >
                    <AiFillFileZip size={14} />
                  </DiscountButton>

                  Baixar XML's
                </ButtonAloneArea>

                <ButtonAloneArea style={{ float: 'right' }}>
                  <DiscountButton
                    onClick={toggleEmail}
                    style={{ background: '#113c51' }}
                  >
                    <HiOutlineMail size={14} />
                  </DiscountButton>
                  XML por E-mail
                </ButtonAloneArea>

                <ReactToPrint
                  trigger={() => (
                    <ButtonAloneArea style={{ float: 'right' }}>
                      <DiscountButton style={{ background: '#81CC66' }}>
                        <div>
                          <FiDollarSign size={14} />
                        </div>
                      </DiscountButton>
                      Pagamentos
                    </ButtonAloneArea>
                  )}
                  content={() => componentRef.current}
                />
              </ButtonsArea>
            </Col>
          </Row>

          <DisplayNoneImprimir>
            <PrintPaymentReportFiscal
              items={payments}
              ref={componentRef}
              phrase={hourPhrase}
            />
          </DisplayNoneImprimir>

          <Row />

          <Nfces
            nfces={nfcesFiltered}
            loading={loading}
            getNfces={getNfces}
          />
        </Content>
      </Card>

      <Modal isOpen={modal} toggle={toggleEmail}>
        <Form onSubmit={sendXMLEmail}>
          <ModalHeader>
            <h4>Enviar arquivos XML por E-mail</h4>
          </ModalHeader>
          <ModalBody>
            <InputSimple id="email" label="E-mail" type="email" name="email" />
            <p>Será enviado os arquivos XML do período selecionado.</p>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" style={{ background: 'green' }}>
              Enviar E-mail
            </Button>
            <Button
              onClick={toggleEmail}
              id="close_modal"
              style={{ background: '#dc3545' }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={zipModal} toggle={toggleZip}>
        <Form onSubmit={() => {}}>
          <ModalHeader>
            <h4>Baixando XMLs</h4>
            <p>O zip será salvo automaticamente assim que o download dos XMLs concluir.</p>
            <p>Será enviado os XML das notas <strong>Autorizadas</strong>, <strong>Canceladas - XML de Autorização</strong> e <strong>Canceladas - XML de Cancelamento</strong></p>
          </ModalHeader>
          <ModalBody>
            <Progress2
              value={zipDownloadProgress}
              max={nfces?.length || 0}
              color="primary"
              animated
            />

            <div style={{ textAlign: 'right' }}>
              {zipDownloadProgress} de {(nfces?.length || 0) + (nfces?.filter(nfce => nfce.status === 'canceled')?.length || 0)}
            </div>

          </ModalBody>
          <ModalFooter style={{ justifyContent: 'end' }}>
            <Button
              id="close_modal"
              style={{ background: '#dc3545' }}
              onClick={toggleZip}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <NfeAvulsa 
        modal={modalAvulsa}
        toggle={toggleModalAvulsa}
        getNfces={getNfces}
      />

      <NfeInutilizacao 
        modal={modalInutilizacao}
        toggle={toggleModalInutilizacao}
        getNfces={getNfces}
      />
    </Wrapper>
  );
}
