import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  margin: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const MotoboysDetailsTable = styled.table`
  width: 96%;
  margin: -20px auto 30px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2)); */
  border-radius: 8px;
  padding:0px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
      text-align: left;
      width: 20%;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const MotoboysTable = styled.table`
  width: 96%;
  margin: -32px auto 30px auto;
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2)); */
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
   
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      width: 20%;
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const MotoboyRow = styled.tr`
  
`;

export const DetailHeader = styled.th`
  font-size: 22px;
  text-align: center !important;
`;

export const SelectDateButton = styled.button`
  height: 40px;
  width: 160px;
  border-radius: 20px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: ${(props) =>
    props.selected === props.id
      ? '1px solid #0c4c56'
      : '1px solid transparent'};
  background: #17a2b8;
  margin-left: 10px;
  transition: all 0.1s;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
export const ButtonsSelectDate = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  text-align: center;

  p {
    padding-left: 10px;
  }
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;
margin-top: 15px;
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
export const CategoryHeader = styled.div`
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  width: 80px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.td`

  transition: all 0.2s;
  :hover {
    transform: translate(-5px, 0);
  }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  max-width: 150px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;
