import { Form } from '@unform/web';

import React, { useEffect, useRef, useState } from 'react';

import { Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { useCart } from '~/context/OrderingSystem/Cart';
import * as Yup from 'yup'
import MenuItemList from './MenuItemList';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  ItemListHeader,
} from './styles';
import BuyersScrollNav from '~/components/BuyersScrollNav';
import InputMask from 'react-input-mask';
import getValidationErrors from '~/services/getValidationErrors';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useBills } from '~/context/BillsContext';
import api from '~/services/api';
import { toast } from 'react-hot-toast';

const Cart = ({
  isTableDelivery,
  deliveryTax,
  discount,
  setDiscount,
  setDiscountObs,
  adminPassword,
  setAdminPassword,
  isBalcony,
  session,
  isAvailable,
  isCommand
}) => {
  const { cart } = useCart();
  const { restaurantDiscountObs, user, setBuyerCreatedByWaiter, setBuyersCreatedByWaiter, buyersCreatedByWaiter, buyerSelectedByWaiter } = useAuth();
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountText, setDiscountText] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(false);

  const [totalWithDiscount, setTotalWithDiscount] = useState(null);



  const totalProductPrice = isTableDelivery
    ? cart.reduce((TotalAccumulator, order) => {
      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(
                  complement.delivery_price
                    ? complement.delivery_price
                    : complement.price
                ) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(
                    complement.delivery_price
                      ? complement.delivery_price
                      : complement.price
                  ) * (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }

          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              category.complements
                .map((item) => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0
                ) / amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator +
                (complement.delivery_price || complement.price) *
                complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        const sum = order.weight_price
          ? parseFloat(totalCategories) + parseFloat(order.weight_price)
          : (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount;
        return TotalAccumulator + sum;
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0)
    : cart?.reduce((TotalAccumulator, order) => {
      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(complement.price) *
                  (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }
          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0
            );

            const totalPriceAverage =
              category.complements
                .map((item) => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0
                ) / amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator + complement.price * complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        const sum = order.weight_price
          ? parseFloat(totalCategories) + parseFloat(order.weight_price)
          : (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount;
        return TotalAccumulator + sum;
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0);

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '').replace(',', '.');

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setTotalWithDiscount(totalProductPrice + (deliveryTax || 0));
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        (
          (parseFloat(targetValue.replace(',', '.')) /
            (totalProductPrice + (deliveryTax || 0))) *
          100 || 0
        ).toFixed(2)
      );
      setTotalWithDiscount(
        totalProductPrice +
        (deliveryTax || 0) -
        parseFloat(targetValue.replace(',', '.'))
      );
    } else {
      setDiscount(
        (
          (parseFloat(targetValue.replace(',', '.')) *
            (totalProductPrice + (deliveryTax || 0))) /
          100
        ).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setTotalWithDiscount(
        totalProductPrice +
        (deliveryTax || 0) -
        (parseFloat(targetValue.replace(',', '.')) *
          (totalProductPrice + deliveryTax)) /
        100
      );
    }
  };

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([
      ...(obs_options || []),
      { value: 'other', label: 'Outro' },
    ]);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    if (applyDiscount && discount) {
      setTotalWithDiscount(totalProductPrice + (deliveryTax || 0) - discount);
      setDiscountPercent(
        (
          discount && (discount / (totalProductPrice + deliveryTax)) * 100
        ).toFixed(2)
      );
    }
  }, [cart, deliveryTax, totalProductPrice, applyDiscount, setDiscount]);

  function deleteDiscount() {
    if (totalWithDiscount) {
      setTotalWithDiscount(null);
    }
  }

  //create buyers by waiter/pdv
  const formRef = useRef(null)
  const [newBuyer, setNewBuyer] = useState(false)

  const [buyers, setBuyers] = useState([]);
  const [isModalCreateBuyerOpened, setIsModalCreateBuyerOpened] = useState(
    false,
  );




  useEffect(() => {

    async function fetchData() {

      if (isAvailable) {
        setBuyers([{ name: '+ Cliente', id: -33801 },
        { name: 'Mesa', id: -33800 }])
      } else {
        const response = await api.get(`restaurants/table-sessions-bills/${session.id}`)

        const buyersNew = response.data.bills.filter(
          (bill) => bill.status !== 'finished'
        ).filter(item => item.buyer).map(bill => {
          return {
            id: bill?.buyer?.id,
            name: bill?.buyer?.name || "",
            phone: bill?.buyer?.phone
          }
        })

        setBuyers([{ name: '+ Cliente', id: -33801 },
        { name: 'Mesa', id: -33800 }, ...buyersNew])

        setNewBuyer(false)
      }

    }

    if (!isBalcony && !isTableDelivery) {
      fetchData()

    }




  }, [session]);


  function toggleModalCreateBuyer() {
    setIsModalCreateBuyerOpened(!isModalCreateBuyerOpened);
  }

  function handleCreateBuyer() {
    toggleModalCreateBuyer();
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Usuário obrigatório')
          .min(3, 'Mínimo de 3 letras'),
        phone: Yup.string().matches(/\(\d\d\)\s\d{5}-\d{4}/, {
          message: 'Preencha todos os 9 números',
          excludeEmptyString: true,
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (isCommand) {
        if (buyers.length === 3) {
          toast.error("Mesa comanda só pode ser adicionado 1 usuário.")
        } else {
          const id_buyer_created = (Math.floor(Math.random() * -1000))
          setBuyerCreatedByWaiter({
            name: data.name,
            phone: data.phone,
          });

          const newBuyers = [...buyers];

          newBuyers.splice(2, 0, {
            name: data.name,
            phone: data.phone,
            id: id_buyer_created,
          });

          setBuyers(newBuyers);

          setNewBuyer(id_buyer_created);

          setBuyersCreatedByWaiter(newBuyers);

          toggleModalCreateBuyer();
        }
      } else {
        const id_buyer_created = (Math.floor(Math.random() * -1000))
        setBuyerCreatedByWaiter({
          name: data.name,
          phone: data.phone,
        });

        const newBuyers = [...buyers];

        newBuyers.splice(2, 0, {
          name: data.name,
          phone: data.phone,
          id: id_buyer_created,
        });

        setBuyers(newBuyers);



        setNewBuyer(id_buyer_created);

        setBuyersCreatedByWaiter(newBuyers);

        toggleModalCreateBuyer();
      }


    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
        console.log('@@', data.phone, errors);
      } else {
        alert('Falha ao criar usuário...');
      }
    }
  }



  return (
    <Container>

      <Modal
        isOpen={isModalCreateBuyerOpened}
        toggle={toggleModalCreateBuyer}


      >
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalBody>
            <Input name="name" label="Nome do cliente:" />
            <InputMask mask="(99) 99999-9999" maskChar="">
              <Input name="phone" label="Celular:" placeholder="(opcional)" />
            </InputMask>
          </ModalBody>
          <ModalFooter>
            <PersonalButton type="button" onClick={toggleModalCreateBuyer} message="Cancelar" color="#ff2c3a" />
            <PersonalButton type="submit" message="Salvar" color="#2ec9b7" />
          </ModalFooter>
        </Form>
      </Modal>

      <Content>
        {!isTableDelivery && !isBalcony && (
          <BuyersScrollNav buyers={buyers} createBuyer={handleCreateBuyer} newBuyer={newBuyer} />
        )}

        {isTableDelivery && (
          <Form>
            <CheckboxInput
              label="Aplicar desconto"
              name="apply"
              value={applyDiscount}
              onChange={() => {
                setApplyDiscount(!applyDiscount);
                deleteDiscount();
              }}
            />
          </Form>
        )}

        {applyDiscount && (
          <>
            <span style={{ color: 'grey' }}>Desconto</span>
            <div
              style={{
                display: 'flex',
                margin: '10px 0 0',
                position: 'relative',
              }}
            >
              <input
                name="flat_discount"
                type="text"
                placeholder="R$"
                style={{ width: '50%', padding: '3px 10px' }}
                value={discount && `R$${discount.replace('.', ',')}`}
                onChange={(e) => handleChangeDiscount(e.target)}
              />
              <input
                name="discount"
                placeholder="%"
                type="text"
                style={{ width: '50%', padding: '3px 10px', marginLeft: '5px' }}
                value={
                  discountPercent &&
                  (discountPercent > 100
                    ? 100
                    : discountPercent.replace('.', ','))
                }
                onChange={(e) => handleChangeDiscount(e.target)}
              />
              {discount && (
                <p style={{ position: 'absolute', right: '15px', top: '5px' }}>
                  %
                </p>
              )}
            </div>
            <Form>
              <Row>
                <Select
                  label={null}
                  name="discount_obs"
                  options={discountObsOptions}
                  onChange={(e) => {
                    if (e.value === 'other') {
                      setDiscountText(true);
                    } else {
                      setDiscountText(false);
                      setDiscountObs(e.label);
                    }
                  }}
                />
                {discountText && (
                  <Input
                    type="text"
                    placeholder="Escreva o motivo"
                    id="discount_obs"
                    name="discount_obs"
                    onChange={(e) => setDiscountObs(e.target.value)}
                  />
                )}
              </Row>
              {user.order_cancel_password !== '' &&
                <Input
                  placeholder="Senha administrativa"
                  id="cancel_password"
                  name="cancel_password"
                  type="password"
                  value={adminPassword}
                  autocomplete="off"
                  onChange={(e) => setAdminPassword(e.target.value)}
                />
              }
            </Form>
          </>
        )}
        <ItemListHeader>
          <div>
            <p>Item</p>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {cart.length > 0
              ? cart.map((item) => (
                <MenuItemList item={item} key={item.ordering_system_id} />
              ))
              : 'Sem itens no carrinho'}
          </MenuItem>
          {deliveryTax && <p> - Taxa de entrega: R$ {deliveryTax}</p>}
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>
            - Subtotal: R${' '}
            {totalWithDiscount
              ? totalWithDiscount.toFixed(2)
              : (totalProductPrice + (deliveryTax || 0)).toFixed(2)}
          </p>
        </MenuContent>
      </Content>
    </Container>
  );
};

export default Cart;
