import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { Container, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import api from '~/services/api';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  Badge,
  SeeButton,
  CancelButton,
  OrdersInfo,
  InfoDiv,
} from '../styles';
import { Print, DisplayNoneImprimir, ContainerOrders } from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';

import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import Select from '~/components/Form/SelectInput';
import { useMotoboys } from '~/context/MotoboysContext';
import { FaEye, FaPrint } from 'react-icons/fa';
import apiClube from '~/services/apiClube';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function OrderSchedulingAndamento({
  item,
  handleItemFinished,
  handlePrintItem,
  handleCancelItem,
  handleItemReady,
  session,
  handleUpdate,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const { motoboys } = useMotoboys();
  const { user } = useAuth();
  const initialData = {
    motoboy: {
      value: motoboys[0]?.id,
      label: motoboys[0]?.name,
    },
  };

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [modalMotoboy, setModalMotoboy] = useState(false);
  const toggleModalMotoboy = () => {
    setModalMotoboy(!modalMotoboy);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  async function handleNotifyWhatsapp() {
    if (
      user.delivery_info.notify_whatsapp &&
      item?.table?.table_type === 'delivery'
    ) {
      const userPhone = item?.buyer.phone;
      const userName = item?.buyer?.name.split(' ')[0];

      if (user.use_wpp) {
        const textWpp = item?.with_withdrawal
          ? `O seu pedido está pronto${
              userName && ', ' + userName
            }! 😋\nVocê pode vir retirar no local, estaremos aguardando por você!\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`
          : `O seu pedido está pronto${
              userName && ', ' + userName
            }! 😋\nO nosso motoboy está a caminho e em breve ele chegará ao seu endereço.\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`;

        const textSent = await api.post('/restaurants/uzapi/delivery-msg', {
          text: textWpp,
          phone: userPhone,
          token: user.name,
        });

        if (textSent.data.status === 400) {
          toast.error(
            'Whatsapp está desconectado, essa mensagem deverá ser enviada manualmente'
          );
          const text = item?.with_withdrawal
            ? `O seu pedido está pronto${
                userName && ', ' + userName
              }! 😋%0aVocê pode vir retirar no local, estaremos aguardando por você!%0a%0aAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`
            : `O seu pedido está pronto${
                userName && ', ' + userName
              }! 😋%0aO nosso motoboy está a caminho e em breve ele chegará ao seu endereço.%0a%0aAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`;

          window.open(
            `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`
          );
        } else {
          toast.success(
            'Whatsapp informando PEDIDO PRONTO enviado para cliente'
          );
        }
      } else {
        const text = item?.with_withdrawal
          ? `O seu pedido está pronto${
              userName && ', ' + userName
            }! 😋%0aVocê pode vir retirar no local, estaremos aguardando por você!%0a%0aAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`
          : `O seu pedido está pronto${
              userName && ', ' + userName
            }! 😋%0aO nosso motoboy está a caminho e em breve ele chegará ao seu endereço.%0a%0aAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`;

        window.open(
          `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`
        );
      }
    }
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const componentRef = useRef(null);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleAddMotoboy = async (data) => {
    try {
      const response = await api.post('/restaurants/motoboys/relate', {
        motoboy_id: data.motoboy,
        session_id: item.session_id,
      });

      item.motoboy = response.data;

      toast.success('Motoboy adicionado à entrega');
      toggleModalMotoboy();
      handleItemReady(
        item.basket.id,
        item.basket.ifood_id,
        item.with_withdrawal
      );
      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'ready');
      }
    } catch (err) {
      console.log('handleAddMotoboy Error: ', err);
      toast.error('Não foi possível adicionar motoboy à essa entrega');
    }
  };

  return (
    <Pedido>
      <ContentBody>
        {item?.table.table_type !== 'delivery' && (
          <div>
            <OrderStatusTag text="Em andamento" color="#01AFFF" />
          </div>
        )}

        <InfoDiv>
          {item?.table.table_type === 'delivery' ? (
            <>
              <OrderNumber>#{item?.attendance_password}</OrderNumber>
              <Time>{formatCompleteDate(item?.basket.start_time)}</Time>
              <img
                src={item.basket.ifood_id === null ? logoTakeat : logoIfood}
                style={{ width: item.basket.ifood_id === null ? 20 : 40 }}
              />
            </>
          ) : (
            <>
              <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
              <Time>{formatTime(item?.basket.start_time)}</Time>
            </>
          )}
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            {item?.table.table_type === 'command' &&
            item?.table.table_type !== 'delivery' ? (
              `Comanda  ${item?.table.table_number} / Mesa ${
                item?.basket?.command_table_number || 'não informada'
              } `
            ) : (
              <>
                {item?.basket?.ifood_table
                  ? item?.basket?.ifood_table
                  : searchTableName(item?.table)}
              </>
            )}
          </TableNumber>

          <PhoneNumber>
            {item?.buyer?.name &&
              (item?.buyer?.name?.length < 20
                ? item.buyer.name
                : `${item.buyer.name.slice(0, 17)}...`)}
          </PhoneNumber>
          <PhoneNumber>
            {`Agendado para ${formatCompleteDate(item.scheduled_to)}`}
          </PhoneNumber>
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            {item?.table?.table_type === 'delivery' ? (
              <div
                onClick={() => {
                  if (motoboys.length > 0) {
                    toggleModalMotoboy();
                  } else {
                    handleItemReady(
                      item.basket.id,
                      item.basket.ifood_id,
                      item.with_withdrawal
                    );
                    if (!item.basket.ifood_id) {
                      handleNotifyWhatsapp(item, orders, user, 'ready');
                    }
                  }
                }}
              >
                <div>
                  <MdDone
                    size={20}
                    style={{ marginRight: 3, marginBottom: 10 }}
                    cursor="pointer"
                    color="#3BD2C1"
                  />
                </div>
              </div>
            ) : (
              <MdDone
                size={20}
                color="#3BD2C1"
                onClick={() => {
                  handleItemFinished(item.basket.id);
                }}
                style={{ marginRight: 3, marginBottom: 10 }}
              />
            )}

            <FaEye
              size={18}
              style={{ marginRight: 3, marginBottom: 10 }}
              onClick={toggle}
              color="#FFA50B"
            />

            {item?.table?.table_type === 'delivery' ? (
              <div>
                <TiDelete
                  size={20}
                  color="#FE1933"
                  onClick={toggleDeleteDelivery}
                  disabled={btnDisable || item.basket.ifood_id !== null}
                  style={{
                    marginRight: 3,
                    marginBottom: 10,
                    cursor: item.basket.ifood_id !== null ? 'auto' : 'pointer',
                  }}
                />
              </div>
            ) : (
              <TiDelete
                color="#FE1933"
                size={20}
                onClick={toggleDelete}
                cursor="pointer"
                style={{ marginRight: 3, marginBottom: 10 }}
              />
            )}
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ContainerOrders browserZoomLevel={browserZoomLevel}>
            <EyeModalOrders item={item} />
          </ContainerOrders>
        </ModalBody>
        <ModalFooter>
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <>
              {item?.table.table_type === 'delivery' ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    await getOrders();
                  }}
                  trigger={() => (
                    <Print>
                      <FaPrint color="#fff" size={20} />
                      Imprimir
                    </Print>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                <Print onClick={printClick}>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'accepted',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalMotoboy} toggle={toggleModalMotoboy}>
        <Form onSubmit={handleAddMotoboy} initialData={initialData}>
          <ModalBody>
            <Container>
              <h3>Adicionar motoboy a essa entrega?</h3>
            </Container>
            <Select
              name="motoboy"
              label="Selecionar motoboy"
              options={motoboys.map((m) => {
                return { value: m.id, label: m.name };
              })}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                toggleModalMotoboy();
                handleItemReady(
                  item.basket.id,
                  item.basket.ifood_id,
                  item.with_withdrawal
                );
                if (!item.basket.ifood_id) {
                  handleNotifyWhatsapp(item, orders, user, 'ready');
                }
              }}
            >
              Ignorar
            </Button>
            <Button color="secondary" type="submit">
              Adicionar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="accepted"
              orders={orders}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'accepted',
              })
            }
          >
            Cancelar esse(s) item(s)
          </Button>
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
