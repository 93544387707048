import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FiArrowDown } from 'react-icons/fi';
import { TabContent, TabPane, Spinner, Row, Col } from 'reactstrap';
import formatCompleteDate from '~/services/formatCompleteDate';

import {
  CategoriesTable,
  Container,
  NavDiv,
  LoadingDiv,
  EmptyDiv,
  Content,
  OrdersContainer,
} from './styles';

import BillOperation from '~/components/BillOperation';

import OrderAndamento from '~/components/Orders/Items/OrderAndamento';
import OrderFinished from '~/components/Orders/Items/OrderFinished';
import OrderCanceled from '~/components/Orders/Items/OrderCanceled';
import WaiterTicket from '~/components/WaiterTickets';

import OrderNew from '~/components/Orders/Items/OrderNew';
import { useOrder } from '~/context/orderContext';
import CloseTable from '~/components/Tables/CloseOrder/Items/CloseTable';
import CloseIndividual from '~/components/Tables/CloseOrder/Items/CloseIndividual';
import OpeningBox from '~/pages/OpeningBox';
import { useAuth } from '~/context/AuthContext';
import { usePos } from '~/context/PosContext';

import ButtonDefault from '~/components/Buttons/DefaultButton';
import { Button, Hyperlink, Tabs } from 'ui-kit-takeat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function OrdersOperation(
  {
    sessions,
    getSessions,
    loading,
    minimalSessions,
    setSessions,
    inicialDate,
    finalDate,
    orderByTableDate,
    pendingBaskets,
    acceptedBaskets,
    canceledBaskets,
    finishedBaskets,
    handleItemFinished,
    handleCancelItem,
    handlePrintItem,
    tableBills,
    printQueueTable,
    printTableBill,
    printIndividualBill,
    individualBills,
    getTablesAvailable,
    getMinimalSessions,
    setAllTablesAvailable,
    setTablesFilteredSearch,
    setInputValueTables,
    waiterTickets,
  },
  ref
) {
  const { allPix, getPixPayments, pixLoading } = useOrder();
  const { user } = useAuth();
  const { cancelAllStoneTransactions } = usePos();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [finishedSortType, setFinishedSortType] = useState('date');
  const [completedSortType, setCompletedSortType] = useState('date');
  const [sessionsMinimalFinished, setSessionsMinimalFinished] = useState([]);
  const [sessionsMinimalCompleted, setSessionsMinimalCompleted] = useState([]);
  const [horizontalPadding, setHorizontalPadding] = useState(20);

  useEffect(() => {
    const finished = sortBy(
      minimalSessions.filter(
        (session) => session.status === 'finished' && !session.is_delivery
      ),
      finishedSortType
    );
    const completed = sortBy(
      minimalSessions.filter(
        (session) => session.status === 'completed' && !session.is_delivery
      ),
      completedSortType
    );

    setSessionsMinimalFinished(finished);
    setSessionsMinimalCompleted(completed);
  }, [minimalSessions, completedSortType, finishedSortType]);

  function sortBy(sessions, type_, info) {
    if (type_ === 'table') {
      sortSessionsByTableNumber(sessions);
    } else {
      sortSessionsByDate(sessions);
    }

    return sessions;
  }

  function sortSessionsByTableNumber(sessions) {
    sessions.sort((a, b) => {
      return a.table.table_number - b.table.table_number;
    });
  }

  function sortSessionsByDate(sessions) {
    sessions.sort((a, b) => {
      if (new Date(a.start_time).valueOf() < new Date(b.start_time).valueOf()) {
        return 1;
      }
      return -1;
    });
  }

  function selectFinishedSessionBy(type_) {
    setFinishedSortType(type_);

    if (type_ === 'table') {
      sortSessionsByTableNumber(sessionsMinimalFinished);
    } else {
      sortSessionsByDate(sessionsMinimalFinished);
    }
  }

  function selectCompletedSessionBy(type_) {
    setCompletedSortType(type_);

    if (type_ === 'table') {
      sortSessionsByTableNumber(sessionsMinimalCompleted);
    } else {
      sortSessionsByDate(sessionsMinimalCompleted);
    }
  }
  const sessionsCompletedLength = sessionsMinimalCompleted.length;
  const sessionsFinishedLength = sessionsMinimalFinished.length;

  const openingBoxRef = useRef(null);

  const updateOpeninBox = useCallback(() => {
    openingBoxRef.current.updateBox();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      updateOpeninBox,
    };
  });

  useEffect(() => {
    setHorizontalPadding(
      window.innerWidth < 1380
        ? window.innerWidth < 1270
          ? window.innerWidth < 1200
            ? 5
            : 10
          : 15
        : 20
    );
  }, []);

  return (
    <Container>
      <NavDiv>
        <Tabs
          tabs={['Pedidos', 'Fechadas', 'Caixa', 'Pgtos Online']}
          selected={activeTab}
          counter={[
            null,
            sessionsFinishedLength,
            // sessionsCompletedLength,
            null,
            null,
          ]}
          horizontalPadding={horizontalPadding}
          onChangeTab={(e) => setActiveTab(e)}
        />
        <Hyperlink
          fontSize={14}
          onClick={() => history.push('/bills?isCompleted=true')}
        >
          Comandas finalizadas
        </Hyperlink>
      </NavDiv>
      <OrdersContainer isFirst={activeTab === 0}>
        <TabContent activeTab={activeTab} style={{ height: '100%' }}>
          <TabPane tabId={0} style={{ height: '100%' }}>
            <Content>
              {tableBills &&
                tableBills.length > 0 &&
                tableBills.map((item) => (
                  <CloseTable
                    key={item.id}
                    item={item}
                    handlePrintItem={printTableBill}
                    printQueueTable={printQueueTable}
                    tableBills={tableBills}
                    // payments={payments}
                    getSessions={getSessions}
                  />
                ))}

              {individualBills &&
                individualBills.length > 0 &&
                individualBills.map((item) => (
                  <CloseIndividual
                    key={item.id}
                    item={item}
                    handlePrintItem={printIndividualBill}
                    individualBills={individualBills}
                  />
                ))}
              {pendingBaskets &&
                pendingBaskets?.length > 0 &&
                pendingBaskets
                  .filter((order) => order.table.table_type !== 'delivery')
                  .map((item) => (
                    <OrderNew
                      key={item.basket.id}
                      handleCancelItem={handleCancelItem}
                      handlePrintItem={handlePrintItem}
                      item={item}
                    />
                  ))}

              {waiterTickets &&
                waiterTickets.length > 0 &&
                waiterTickets.map((ticket) => <WaiterTicket item={ticket} />)}

              {acceptedBaskets &&
                acceptedBaskets?.length > 0 &&
                acceptedBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => (
                    <OrderAndamento
                      key={item.basket.id}
                      handleCancelItem={handleCancelItem}
                      handleItemFinished={handleItemFinished}
                      item={item}
                    />
                  ))}

              {finishedBaskets &&
                finishedBaskets?.length > 0 &&
                finishedBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => (
                    <OrderFinished
                      key={item?.basket && item.basket.id}
                      handleCancelItem={handleCancelItem}
                      item={item}
                    />
                  ))}

              {canceledBaskets &&
                canceledBaskets?.length > 0 &&
                canceledBaskets
                  .filter((order) => order?.table?.table_type !== 'delivery')
                  .map((item) => <OrderCanceled key={item?.id} item={item} />)}

              {tableBills.length +
                individualBills.length +
                pendingBaskets.filter(
                  (order) => order.table.table_type !== 'delivery'
                ).length +
                waiterTickets.length +
                acceptedBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length +
                finishedBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length +
                canceledBaskets.filter(
                  (order) => order?.table?.table_type !== 'delivery'
                ).length <=
                0 && (
                <EmptyDiv>
                  <span>Não há pedidos no momento.</span>
                </EmptyDiv>
              )}
            </Content>
          </TabPane>

          <TabPane tabId={1} style={{ height: '100%' }}>
            <Content>
              <CategoriesTable borderless>
                <thead>
                  <tr>
                    <th
                      onClick={() => selectCompletedSessionBy('table')}
                      title="Ordenar pela mesa"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Mesa
                    </th>
                    <th
                      onClick={() => selectCompletedSessionBy('date')}
                      title="Ordenar pela data"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Data/Hora
                    </th>
                    <th>Nº</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <LoadingDiv>
                          <Spinner color="success" />
                        </LoadingDiv>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {sessionsMinimalFinished.map((session) => (
                      <BillOperation
                        session={session}
                        key={session.id}
                        getSessions={getMinimalSessions}
                        setInputValueTables={setInputValueTables}
                        // payments={payments}
                        // setAllPayments={setAllPayments}
                        // paymentMethods={paymentMethods}
                        setSessions={setSessions}
                        sessions={sessions}
                        inicialDate={inicialDate}
                        finalDate={finalDate}
                        getTablesAvailable={getTablesAvailable}
                        orderByTableDate={orderByTableDate}
                        getMinimalSessions={getMinimalSessions}
                        setAllTablesAvailable={setAllTablesAvailable}
                        setTablesFilteredSearch={setTablesFilteredSearch}
                      />
                    ))}
                  </tbody>
                )}
              </CategoriesTable>
              {sessionsFinishedLength === 0 && (
                <EmptyDiv>
                  <span>Não há comandas fechadas no momento.</span>
                </EmptyDiv>
              )}
            </Content>
          </TabPane>

          <TabPane tabId={5}>
            <Content>
              <CategoriesTable borderless>
                <thead>
                  <tr>
                    <th
                      onClick={() => selectCompletedSessionBy('table')}
                      title="Ordenar pela mesa"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Mesa
                    </th>
                    <th
                      onClick={() => selectCompletedSessionBy('date')}
                      title="Ordenar pela data"
                      style={{ cursor: 'pointer' }}
                    >
                      <FiArrowDown size={14} /> Data/Hora
                    </th>
                    <th>Nº</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <LoadingDiv>
                          <Spinner color="success" />
                        </LoadingDiv>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {sessionsMinimalCompleted.map((session) => (
                      <BillOperation
                        session={session}
                        key={session.id}
                        getSessions={getMinimalSessions}
                        // payments={payments}
                        // setAllPayments={setAllPayments}
                        // paymentMethods={paymentMethods}
                        setSessions={setSessions}
                        sessions={sessions}
                        inicialDate={inicialDate}
                        finalDate={finalDate}
                        getTablesAvailable={getTablesAvailable}
                        orderByTableDate={orderByTableDate}
                        getMinimalSessions={getMinimalSessions}
                      />
                    ))}
                  </tbody>
                )}
              </CategoriesTable>
              {sessionsCompletedLength === 0 && (
                <EmptyDiv>
                  <span>Não há comandas finalizadas no momento.</span>
                </EmptyDiv>
              )}
            </Content>
          </TabPane>

          <TabPane tabId={2}>
            <Content>
              <OpeningBox ref={openingBoxRef} />
            </Content>
          </TabPane>

          {(user.has_pix || user.has_stone_pdv) && (
            <TabPane tabId={3}>
              <Content>
                <Row style={{ margin: 0 }}>
                  {user.has_stone_pdv && user.stone_device_id && (
                    <Col>
                      <ButtonDefault
                        message="Cancelar Pendentes Stone"
                        style={{
                          float: 'left',
                          background: '#00a868',
                          fontSize: 15,
                        }}
                        onClick={() => cancelAllStoneTransactions()}
                      />
                    </Col>
                  )}

                  {user.has_pix && (
                    <Button
                      title="Atualizar Pagamentos"
                      type="negative"
                      width="262px"
                      float="right"
                      height="40px"
                      isLoading={pixLoading}
                      disabled={pixLoading}
                      onClick={() => getPixPayments()}
                    />
                  )}
                </Row>

                <CategoriesTable borderless>
                  <thead>
                    <tr>
                      <th>Mesa/Comanda</th>
                      <th>Tipo</th>
                      <th>Telefone</th>
                      <th>Valor</th>
                      <th>Horário</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPix.map((pix) => (
                      <tr>
                        <td>{pix.table_session.table.table_number}</td>
                        <td>
                          {pix.individual_bill_id
                            ? 'Individual'
                            : pix.table_session.table.table_type === 'command'
                            ? 'Comanda'
                            : 'Mesa'}
                        </td>
                        <td>{pix.phone}</td>
                        <td>R${pix.total}</td>
                        <td>{formatCompleteDate(pix.payed_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </CategoriesTable>
              </Content>
            </TabPane>
          )}
        </TabContent>
      </OrdersContainer>
    </Container>
  );
}

export default React.forwardRef(OrdersOperation);
