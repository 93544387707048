import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  subYears,
  subDays,
  differenceInDays,
} from 'date-fns';
import { Form } from '@unform/web';
import {
  MdArrowDropDown,
  MdKeyboardArrowRight,
  MdWarning,
} from 'react-icons/md';

import {
  Button,
  ButtonGroup,
  CardHeader,
  CardTitle,
  Collapse,
  Spinner,
  Table,
} from 'reactstrap';

import ScrollContainer from 'react-indiana-drag-scroll';

import { Input } from '@material-ui/core';
import { AiOutlinePrinter } from 'react-icons/ai';
import randomColor from 'randomcolor';
import { Line } from 'react-chartjs-2';
import { FaCircle, FaDownload, FaQuestionCircle } from 'react-icons/fa';
import FileSaver from 'file-saver';
import {
  Container,
  Card,
  Header,
  ReportHeader,
  DateDiv,
  DateSelectorDiv,
  HeaderChart,
  ReportHeaderChartDiv,
  HeaderLegend,
  CategoryLegend,
  SubmitDate,
  Print,
  PrintDiv,
  StyledLink,
  SelectDateButton,
  CloseButton,
  ButtonsSelectDate,
  ErrorContainer,
  THProducts,
  TDProducts,
  TheadProducts,
  DataPickerArea,
  DatePickerStyled,
  ComplementRow,
  CompNameCell,
  RowSeparator,
} from './styles';

import ReportChartPie from '~/components/Reports/ReportChartPie';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import { generateProductsSheetXLSX } from '~/services/generateProductsSheet';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { FullCalendar } from 'ui-kit-takeat';

function ReportsProducts() {
  const { user } = useAuth();
  const { has_service_tax, fantasy_name, service_tax } = user;

  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const [inicialFormat, setInicialFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const [finalFormat, setFinalFormat] = useState(
    format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm')
  );
  const phrase = `De ${inicialFormat} até ${finalFormat}`;
  const [rangeOn, setRangeOn] = useState(true);
  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [paymentsCashier, setPaymentsCashier] = useState();
  const [productsChartActive, setProductsChartActive] = useState('total');
  const [complementsChartActive, setComplementsChartActive] = useState('total');

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    setHourPhrase(phrase);
  }

  function setYesterday() {
    setRangeOn(true);
    const inicial = startOfDay(subDays(new Date(), 1));
    const final = endOfDay(subDays(new Date(), 1));

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    setHourPhrase('Ontem');
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
    setInicialFormat(inicialFormat);
    setFinalFormat(finalFormat);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));

    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
      const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
      const phrase = `De ${inicialFormat} até ${finalFormat}`;
      setInicialFormat(inicialFormat);
      setFinalFormat(finalFormat);
      setHourPhrase(phrase);
    }
  }

  const [categories, setCategories] = useState([]);
  const [totais, setTotais] = useState([]);
  const [payments, setPayments] = useState([]);
  const [totalBox, setTotalBox] = useState(0);
  const [buyers, setBuyers] = useState(0);
  const [categoriesCanceled, setCategoriesCanceled] = useState([]);

  const colors = [
    '#22aa99',
    '#289976',
    '#329262',
    '#5574a6',
    '#1F8A70',
    '#00796B',
    '#287D7D',
    '#2a778d',
    '#009688',
    '#22aa90',
    '#289977',
    '#329261',
    '#5574a7',
    '#1F8A71',
    '#00796C',
    '#287D7E',
    '#2a778e',
    '#009684',
    '#226666',
    '#669999',
    '#407F7F',
    '#0D4D4D',
    '#003333',
    '#27556C',
    '#6E91A1',
    '#477286',
    '#113C51',
    '#032536',
    '#68b684',
    '#a6e1fa',
    '#75dddd',
    '#84c7d0',
    '#04151f',
    '#183a37',
    '#0c7489',
    '#119da4',
    '#13505b',
  ];

  const [, setResponseCat] = useState([]);
  const [complements, setComplements] = useState([]);

  const getCategories = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const response = await api.get('restaurants/reports/orders', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:59"
          )}`,
        },
      });

      setCategoriesCanceled(response.data.canceledCategoriesReport);
      setComplements(response.data.complementsReport);
      setTotais({
        total_restaurant_price: response.data.total_restaurant_service_price,
        total_restaurant_amount: response.data.total_restaurant_amount,
        total_restaurant_canceled_service_price:
          response.data.total_restaurant_canceled_service_price,
        total_restaurant_canceled_amount:
          response.data.total_restaurant_canceled_amount,
        total_restaurant_price_without_service:
          response.data.total_restaurant_price,
      });
      setResponseCat(response.data);
      setLoadingProducts(false);
    } catch (err) {
      toast.error('Erro ao carregar informações');
      setLoadingProducts(false);
    }
  }, [inicialDate, finalDate]);

  function random_color() {
    const cores = colors;
    const cor = cores[0];
    cores.splice(0, 1);
    return cor;
  }

  const CategorysToLegend = categories.map(function (categorie) {
    return {
      name: categorie.name,
      color: random_color(),
    };
  });

  const cores = [];
  CategorysToLegend.map((category) => cores.push(category.color));

  const dataChartPrice = categories.map(function (categorie) {
    const cor = CategorysToLegend.filter(
      (category) => category.name === categorie.name
    );
    return {
      id: categorie.name,
      label: categorie.name,
      value: categorie.total_sold_orders_price,
      color: cor[0].color,
    };
  });

  const dataChartAmount = categories.map(function (categorie) {
    const cor = CategorysToLegend.filter(
      (category) => category.name === categorie.name
    );

    return {
      id: categorie.name,
      label: categorie.name,
      value: categorie.total_sold_orders_amount,
      color: cor[0].color,
    };
  });

  const { total_restaurant_amount, total_restaurant_canceled_amount } = totais;
  const { total_restaurant_price, total_restaurant_canceled_service_price } =
    totais;
  const { total_restaurant_price_without_service } = totais;

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/reports/cashier', {
      params: {
        start_date: `${format(
          addHours(new Date(inicialDate), 3),
          "yyyy-MM-dd'T'HH:mm:ss"
        )}`,
        end_date: `${format(
          addHours(new Date(finalDate), 3),
          "yyyy-MM-dd'T'HH:mm:59"
        )}`,
      },
    });

    setPaymentsCashier(response.data);

    const parsedData = response.data.paymentsReport?.map((data) => ({
      id: data.id,
      name: data.name,
      total_payments_price: data.total_payments_price,
    }));
    setPayments(parsedData);

    const total = response.data.total_payment_methods_price;
    setTotalBox(total);
  }, [inicialDate, finalDate]);

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:59"
          )}`,
        },
      });
      setBuyers(response.data.length);
    } catch (err) {
      toast.error('Erro ao carregar informações!!');
    }
  }, [inicialDate, finalDate]);

  const [products, setProducts] = useState([]);
  const [productsLocal, setProductsLocal] = useState([]);
  const [productsDelivery, setProductsDelivery] = useState([]);
  const [productsIfood, setProductsIfood] = useState([]);
  const [complementsDetailed, setComplementsDetailed] = useState([]);
  const [dataToShow, setDataToShow] = useState(products);
  const [complementsLocal, setComplementsLocal] = useState([]);
  const [complementsDelivery, setComplementsDelivery] = useState([]);
  const [complementsIfood, setComplementsIfood] = useState([]);
  const [loadingComplements, setLoadingComplements] = useState(false);
  const [complementsToShow, setComplementsToShow] =
    useState(complementsDetailed);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const getProducts = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const response = await api.get('/restaurants/reports/products-detailed', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:59"
          )}`,
        },
      });

      setProducts(response.data.products);
      setProductsLocal(response.data.local);
      setProductsDelivery(response.data.delivery);
      setProductsIfood(response.data.ifood);
      setCategories(response.data.categories);
      switch (productsChartActive) {
        case 'total':
          setDataToShow(response.data.products);
          break;
        case 'local':
          setDataToShow(response.data.local);
          break;
        case 'delivery':
          setDataToShow(response.data.delivery);
          break;
        case 'ifood':
          setDataToShow(response.data.ifood);
          break;
      }
    } catch (err) {
      console.log('getProducts error > ', err);
    }
  }, [inicialDate, finalDate]);

  const [productsMonthly, setProductsMonthly] = useState([]);
  const [monthlyByCategory, setMonthlyByCategory] = useState([]);
  const [products60, setProducts60] = useState([]);
  const [products60ByCategory, set60ByCategory] = useState([]);
  const [graphToShow, setGraphToShow] = useState({
    total: productsMonthly,
    categories: monthlyByCategory,
  });
  const [graphLabel, setGraphLabel] = useState('monthly');

  const getProductsMonthly = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const response = await api.get('/restaurants/reports/products-monthly');

      const res60days = await api.get('/restaurants/reports/products-60-days');

      setProductsMonthly(response.data.total);
      setMonthlyByCategory(Object.entries(response.data.categories));
      setProducts60(res60days.data.total);
      set60ByCategory(Object.entries(res60days.data.categories));
      setGraphToShow({
        total: response.data.total,
        categories: Object.entries(response.data.categories),
      });
    } catch (err) {
      console.log('getProducts error > ', err);
    }
  }, []);

  const formatPrice = (price) => {
    return price.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getComplements = useCallback(async () => {
    setLoadingComplements(true);
    try {
      const response = await api.get(
        '/restaurants/reports/complements-detailed',
        {
          params: {
            start_date: `${format(
              addHours(new Date(inicialDate), 3),
              "yyyy-MM-dd'T'HH:mm:ss"
            )}`,
            end_date: `${format(
              addHours(new Date(finalDate), 3),
              "yyyy-MM-dd'T'HH:mm:59"
            )}`,
          },
        }
      );
      setComplementsDetailed(response.data.complements);
      setComplementsLocal(response.data.local);
      setComplementsDelivery(response.data.delivery);
      setComplementsIfood(response.data.ifood);
      switch (complementsChartActive) {
        case 'total':
          setComplementsToShow(response.data.complements);
          break;
        case 'local':
          setComplementsToShow(response.data.local);
          break;
        case 'delivery':
          setComplementsToShow(response.data.delivery);
          break;
        case 'ifood':
          setComplementsToShow(response.data.ifood);
          break;
      }
      setLoadingComplements(false);
    } catch (err) {
      setLoadingComplements(false);

      console.log('getComplements error > ', err);
    }
  }, [inicialDate, finalDate]);

  const handleChangeData = useCallback(async (data, label) => {
    setDataToShow(data);
    setProductsChartActive(label);
  }, []);

  const handleChangeComplementsData = useCallback(async (data, label) => {
    setComplementsToShow(data);
    setComplementsChartActive(label);
  }, []);

  const handleChangeGraph = useCallback(async (data, label) => {
    setGraphToShow(data);
    setGraphLabel(label);
  }, []);

  const [collapse, setCollapse] = useState([]);
  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const [collapseComps, setCollapseComps] = useState([]);
  const handleCollapseComps = (id) => {
    if (collapseComps.includes(id)) {
      const newCollapse = collapseComps.filter((c) => c !== id);
      setCollapseComps(newCollapse);
    } else {
      setCollapseComps((state) => [...state, id]);
    }
  };

  useEffect(() => {
    getBuyers();
    getProducts();
    getProductsMonthly();
  }, [getBuyers, getProducts]);

  useEffect(() => {
    getCategories();
    getPayments();
    getComplements();
  }, [getCategories, getPayments, getComplements]);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsProducts'}`
      );

      const { can_read } = response.data.ReportsProducts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  async function closeClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        print_type: 'orders-report',
        filter_start_date: inicialDate,
        filter_end_date: finalDate,
      });
      toast.success('Relatório impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir relatório.');
    }
  }

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateProductsSheetXLSX(
        categories.filter((c) => c.deleted_at === null),
        products,
        complementsDetailed
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Produtos(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <div style={{ width: 'calc(100vw - 223px)' }}>
        <Header>
          <div>
            <h2>Relatório de Produtos</h2>
            <p>Confira todas suas vendas detalhadamente!</p>
          </div>
        </Header>
        <Card>
          <CardHeader style={{ backgroundColor: '#ffffff' }}>
            <CardTitle tag="h3">
              Gráfico de vendas por categoria
              <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="info"
                  id="0"
                  size="sm"
                  tag="label"
                  className={graphLabel === 'monthly' && 'btn-simple'}
                  onClick={() =>
                    handleChangeGraph(
                      { total: productsMonthly, categories: monthlyByCategory },
                      'monthly'
                    )
                  }
                >
                  Mensal
                </Button>
                <Button
                  color="info"
                  id="1"
                  size="sm"
                  tag="label"
                  className={graphLabel === 'daily' && 'btn-simple'}
                  onClick={() =>
                    handleChangeGraph(
                      { total: products60, categories: products60ByCategory },
                      'daily'
                    )
                  }
                >
                  Diário
                </Button>
              </ButtonGroup>
            </CardTitle>
          </CardHeader>
          <Line
            height={100}
            data={{
              labels: graphToShow.total.map((p) =>
                graphLabel === 'monthly'
                  ? `${p.created_at.substring(5, 7)}/${p.created_at.substring(
                      2,
                      4
                    )}`
                  : `${p.created_at.substring(8, 10)}/${p.created_at.substring(
                      5,
                      7
                    )}`
              ),
              datasets: [
                {
                  data: graphToShow.total.map((p) => p.total_price),
                  label: 'Vendas',
                  borderColor: 'green',
                },
                ...graphToShow.categories.map((c) => {
                  return {
                    data: c[1].map((i) => i.total_price),
                    label: c[0],
                    borderColor: randomColor(),
                    hidden: true,
                  };
                }),
              ],
            }}
            options={{
              responsive: true,
              tension: 0.4,
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    pointStyle: 'line',
                    usePointStyle: true,
                  },
                },
              },
            }}
          />
        </Card>

        <ButtonsSelectDate style={{ width: '90%', margin: '0 auto' }}>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setYesterday}
                color="info"
              >
                Ontem
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>

              <DataPickerArea>
                <DatePickerStyled
                  selected={monthYearDate}
                  onChange={(date) => {
                    setMonthYearDate(date);
                    setMonth(date);
                    setRangeOn(true);
                  }}
                  locale="pt"
                  showMonthYearPicker
                  customInput={
                    <SelectDateButton
                      className="btn-round mr-auto"
                      color="info"
                    >
                      Mês/Ano <MdArrowDropDown color="white" size={20} />
                    </SelectDateButton>
                  }
                />
              </DataPickerArea>

              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
              <FaQuestionCircle
                color="orange"
                title="Selecione o intervalo entre as datas com máximo de 31 dias"
                size={20}
                style={{ marginTop: -45, marginLeft: -5 }}
              />

              <Collapse isOpen={isOpen} style={{ marginTop: 10 }}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>

            <p
              style={{
                color: 'red',
                marginTop: -10,
                display: rangeOn ? 'none' : 'block',
              }}
            >
              Favor selecionar o intervalo máximo de 31 dias entre as datas.
            </p>

            <p>{hourPhrase}</p>
          </div>

          <PrintDiv>
            {user.is_printed_on_web || user.print_bills_on_web ? (
              <CloseButton
                to={{
                  pathname: '/reports/print',
                  state: {
                    categories,
                    products,
                    inicialFormat,
                    complements,
                    finalFormat,
                    total_restaurant_amount,
                    total_restaurant_price,
                    payments,
                    totalBox,
                    buyers,
                    categoriesCanceled,
                    total_restaurant_canceled_service_price,
                    total_restaurant_canceled_amount,
                    has_service_tax,
                    service_tax,
                    fantasy_name,
                    total_restaurant_price_without_service,
                  },
                }}
              >
                <div style={{ fontSize: 15 }}>
                  <AiOutlinePrinter size={14} />
                  Imprimir
                </div>
              </CloseButton>
            ) : (
              <StyledLink>
                <Print onClick={closeClick}>
                  {' '}
                  <AiOutlinePrinter color="white" size={20} />
                </Print>
              </StyledLink>
            )}
          </PrintDiv>
        </ButtonsSelectDate>
        {/* 
        <div style={{ marginLeft: 60 }}>
          <FullCalendar
            onDateSelected={(e) => {
              setInicialDate(e.start);
              setFinalDate(e.end);
            }}
            value={{ start: new Date(), end: null }}
          />
        </div> */}

        <Card>
          <CardHeader style={{ backgroundColor: '#ffffff' }}>
            <CardTitle
              tag="h3"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                Produtos{' '}
                <MdWarning
                  color="orange"
                  title="Caso o estoque e/ou os insumos não sejam atualizados corretamente e frequentemente, os dados desta tabela podem ficar um pouco diferentes da realidade."
                />
                <button
                  style={{
                    fontSize: '12px',
                    height: '30px',
                    padding: '0 12px',
                    border: 'none',
                    backgroundColor: '#04bfbf',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleDownloadSheet()}
                >
                  <FaDownload style={{ marginRight: '3px' }} />
                  Baixar planilha
                </button>
              </div>
              <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="info"
                  id="0"
                  size="sm"
                  tag="label"
                  className={productsChartActive === 'total' && 'btn-simple'}
                  onClick={() => handleChangeData(products, 'total')}
                >
                  Total
                </Button>
                <Button
                  color="info"
                  id="1"
                  size="sm"
                  tag="label"
                  className={productsChartActive === 'local' && 'btn-simple'}
                  onClick={() => handleChangeData(productsLocal, 'local')}
                >
                  Presencial
                </Button>
                <Button
                  color="info"
                  id="2"
                  size="sm"
                  tag="label"
                  className={productsChartActive === 'delivery' && 'btn-simple'}
                  onClick={() => handleChangeData(productsDelivery, 'delivery')}
                >
                  Delivery
                </Button>
                {user.has_ifood && (
                  <Button
                    color="info"
                    id="3"
                    size="sm"
                    tag="label"
                    className={productsChartActive === 'ifood' && 'btn-simple'}
                    onClick={() => handleChangeData(productsIfood, 'ifood')}
                  >
                    iFood
                  </Button>
                )}
              </ButtonGroup>
            </CardTitle>
          </CardHeader>
          {loadingProducts ? (
            <Spinner style={{ margin: '0 auto' }} />
          ) : (
            <Table>
              <TheadProducts>
                <th>Produto</th>
                <THProducts>CMV</THProducts>
                <THProducts>
                  Preço atual
                  <br /> de venda
                </THProducts>
                <THProducts>Margem bruta</THProducts>
                <THProducts>Lucro bruto</THProducts>
                <THProducts>Qnt vendida</THProducts>
                <THProducts>CMV médio do período</THProducts>
                <THProducts>Lucro medido</THProducts>
                <THProducts>Vendas medidas</THProducts>
              </TheadProducts>
              <tbody>
                <tr>
                  <td colSpan={8} style={{ fontWeight: 'bold' }}>
                    Total do período
                  </td>
                  <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    R$
                    {parseFloat(
                      categories.reduce(
                        (acc, cur) =>
                          acc + cur.category_earning[productsChartActive],
                        0
                      )
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>

                {categories
                  .filter((c) => c.deleted_at === null)
                  .map((c, idx) => {
                    const check = collapse.includes(c.id);

                    return (
                      <>
                        <tr
                          key={idx}
                          style={{
                            cursor: 'pointer',
                            fontSize: '16px',
                            backgroundColor: '#ECFFFD',
                            fontWeight: 'bold',
                          }}
                          className="btn-outline-success"
                          onClick={() => handleCollapse(c.id)}
                        >
                          <td
                            colSpan={5}
                            style={{
                              border: 'none',
                            }}
                          >
                            <MdKeyboardArrowRight
                              style={{
                                transition: 'all 0.2s',
                                transform: check
                                  ? 'rotate(90deg)'
                                  : 'rotate(0)',
                              }}
                              size={20}
                            />
                            {c.name}
                          </td>
                          <td
                            style={{
                              border: 'none',
                              textAlign: 'center',
                            }}
                          >
                            {c.amount[productsChartActive]}
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: 'center',
                              border: 'none',
                            }}
                          />
                          <td
                            style={{
                              textAlign: 'center',
                              border: 'none',
                            }}
                          >
                            {`R$${formatPrice(
                              c.category_earning[productsChartActive]
                            )}`}
                          </td>
                        </tr>
                        {dataToShow.filter((p) => p.category.id === c.id)
                          .length > 0 ? (
                          dataToShow
                            .filter((p) => p.category.id === c.id)
                            .map((p, idx) => {
                              const compCheck =
                                collapseComps.includes(p.id) && check;
                              const hasComplements = p.complements.length > 0;
                              return (
                                <>
                                  <tr
                                    key={idx}
                                    onClick={() =>
                                      hasComplements &&
                                      handleCollapseComps(p.id)
                                    }
                                    style={{
                                      cursor: hasComplements && 'pointer',
                                    }}
                                  >
                                    <td
                                      style={{
                                        padding: check ? '12px 7px' : '0',
                                        transition: 'all 0.3s',
                                        transform: check
                                          ? 'scaleY(1)'
                                          : 'scaleY(0)',
                                        border:
                                          (!check || hasComplements) && 'none',
                                        borderTop: check
                                          ? '1px solid #e3e3e3'
                                          : 'none',
                                      }}
                                    >
                                      {check &&
                                        (hasComplements ? (
                                          <>
                                            <MdKeyboardArrowRight
                                              style={{
                                                color: '#0CCAB4',
                                                transition: 'all 0.2s',
                                                transform: compCheck
                                                  ? 'rotate(90deg)'
                                                  : 'rotate(0)',
                                                borderBottom: 'none',
                                              }}
                                              size={20}
                                            />
                                            {check ? p.name : ''}
                                          </>
                                        ) : (
                                          <div style={{ marginLeft: '5px' }}>
                                            {check ? p.name : ''}
                                          </div>
                                        ))}
                                    </td>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? p.cmv > 0
                                          ? `R$${p.cmv && formatPrice(p.cmv)}`
                                          : '-'
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? `R$${
                                            p.price &&
                                            formatPrice(parseFloat(p.price))
                                          }`
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check && p.price > 0
                                        ? `${(
                                            (1 - p.cmv / p.price) *
                                            100
                                          ).toFixed(2)}%`
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? `R$${formatPrice(p.price - p.cmv)}`
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check ? p.amount : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? `R$${
                                            p.period_cmv &&
                                            formatPrice(p.period_cmv)
                                          }`
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? `R$${
                                            p.profit && formatPrice(p.profit)
                                          }`
                                        : ''}
                                    </TDProducts>
                                    <TDProducts
                                      check={check}
                                      compCheck={compCheck}
                                      hasComplements={hasComplements}
                                    >
                                      {check
                                        ? `R$${
                                            p.earnings &&
                                            formatPrice(p.earnings)
                                          }`
                                        : ''}
                                    </TDProducts>
                                  </tr>
                                  {p.complements.length > 0 &&
                                    p.complements.map((c, i) => {
                                      const lastComp =
                                        i === p.complements.length - 1;

                                      return (
                                        <ComplementRow
                                          lastComp={lastComp}
                                          compCheck={compCheck}
                                        >
                                          <CompNameCell
                                            style={{ textAlign: 'left' }}
                                          >
                                            {compCheck && (
                                              <span
                                                style={{ color: '#3db2c1' }}
                                              >
                                                <FaCircle
                                                  size={8}
                                                  color="#3BD2C1"
                                                  style={{
                                                    marginRight: '5px',
                                                    marginLeft: '7px',
                                                  }}
                                                />
                                                {c.name}
                                              </span>
                                            )}
                                          </CompNameCell>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                ((c.cmv &&
                                                  `R$${formatPrice(c.cmv)}`) ||
                                                  '-')}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                `R$${
                                                  (c.price &&
                                                    formatPrice(c.price)) ||
                                                  0
                                                }`}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                ((c.price > 0 &&
                                                  `${(
                                                    (1 - c.cmv / c.price) *
                                                    100
                                                  ).toFixed(2)}%`) ||
                                                  '-')}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                `R$${formatPrice(
                                                  c.price - c.cmv
                                                )}`}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck && c.amount}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                c.period_cmv &&
                                                `R$${formatPrice(
                                                  c.period_cmv
                                                )}`}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                c.profit &&
                                                `R$${formatPrice(c.profit)}`}
                                            </span>
                                          </td>
                                          <td>
                                            <span style={{ color: '#3db2c1' }}>
                                              {compCheck &&
                                                c.earnings &&
                                                `R$${formatPrice(c.earnings)}`}
                                            </span>
                                          </td>
                                        </ComplementRow>
                                      );
                                    })}
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td
                              colSpan={9}
                              style={{
                                padding: check ? '12px 7px' : '0',
                                transition: 'all 0.3s',
                                transform: check ? 'scaleY(1)' : 'scaleY(0)',
                                border: !check && 'none',
                              }}
                            >
                              {check && 'Essa categoria não possui produtos'}
                            </td>
                          </tr>
                        )}
                        <RowSeparator>
                          <td colSpan={9} />
                        </RowSeparator>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Card>
        <Card>
          <CardHeader style={{ backgroundColor: '#ffffff' }}>
            <CardTitle tag="h3">
              Complementos{' '}
              <MdWarning
                color="orange"
                title="Caso o estoque e/ou os insumos não sejam atualizados corretamente e frequentemente, os dados desta tabela podem ficar um pouco diferentes da realidade."
              />
              <ButtonGroup
                className="btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <Button
                  color="info"
                  id="0"
                  size="sm"
                  tag="label"
                  className={complementsChartActive === 'total' && 'btn-simple'}
                  onClick={() =>
                    handleChangeComplementsData(complementsDetailed, 'total')
                  }
                >
                  Total
                </Button>
                <Button
                  color="info"
                  id="1"
                  size="sm"
                  tag="label"
                  className={complementsChartActive === 'local' && 'btn-simple'}
                  onClick={() =>
                    handleChangeComplementsData(complementsLocal, 'local')
                  }
                >
                  Presencial
                </Button>
                <Button
                  color="info"
                  id="2"
                  size="sm"
                  tag="label"
                  className={
                    complementsChartActive === 'delivery' && 'btn-simple'
                  }
                  onClick={() =>
                    handleChangeComplementsData(complementsDelivery, 'delivery')
                  }
                >
                  Delivery
                </Button>
                {user.has_ifood && (
                  <Button
                    color="info"
                    id="3"
                    size="sm"
                    tag="label"
                    className={
                      complementsChartActive === 'ifood' && 'btn-simple'
                    }
                    onClick={() =>
                      handleChangeComplementsData(complementsIfood, 'ifood')
                    }
                  >
                    iFood
                  </Button>
                )}
              </ButtonGroup>
            </CardTitle>
          </CardHeader>
          {loadingComplements ? (
            <Spinner style={{ margin: '0 auto' }} />
          ) : (
            <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
              <Table>
                <TheadProducts>
                  <th>Complemento</th>
                  <THProducts>CMV</THProducts>
                  <THProducts>
                    Preço atual
                    <br /> de venda
                  </THProducts>
                  <THProducts>Margem bruta</THProducts>
                  <THProducts>Lucro bruto</THProducts>
                  <THProducts>Qnt vendida</THProducts>
                  <THProducts>CMV médio do período</THProducts>
                  <THProducts>Lucro medido</THProducts>
                  <THProducts>Vendas medidas</THProducts>
                </TheadProducts>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 'bold' }} colSpan={8}>
                      Total do período
                    </td>
                    <TDProducts style={{ fontWeight: 'bold' }} check>
                      R$
                      {complementsToShow
                        .reduce(
                          (acc, c) => acc + parseFloat(c[8].substring(2)),
                          0
                        )
                        .toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                    </TDProducts>
                  </tr>
                  {complementsToShow.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        {c.map((value, i) => {
                          if (i === 0) {
                            return <td>{value}</td>;
                          }
                          return <TDProducts check>{value}</TDProducts>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Card>

        <ReportHeader>
          <HeaderLegend
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <ScrollContainer
              horizontal
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {CategorysToLegend.map((categorie, idx) => (
                <CategoryLegend color={categorie.color} key={idx}>
                  <div />
                  <span>{categorie.name}</span>
                </CategoryLegend>
              ))}
            </ScrollContainer>
          </HeaderLegend>
          <ReportHeaderChartDiv>
            <HeaderChart>
              <span>Arrecadação (R$)</span>
              <ReportChartPie radius={0} data={dataChartPrice} colors={cores} />
            </HeaderChart>
            <HeaderChart>
              <span>Quantidade</span>
              <ReportChartPie
                radius={0.5}
                data={dataChartAmount}
                colors={cores}
              />
            </HeaderChart>
          </ReportHeaderChartDiv>
        </ReportHeader>
      </div>
    </Container>
  );
}

export default ReportsProducts;
