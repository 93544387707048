import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

function appendCashFlows(lines, cashflows, line_markers, marker_type) {
  for (const f of cashflows) {
    line_markers.push([lines.length, marker_type]);
    lines.push([
      '  ' + (f.category || ''),
      ...f.values,
    ]);

    for (const s of f.subs) {
      lines.push([
        '    ' + (s.name || ''),
        ...s.values,
      ]);

      for (const ss of s.subsubs) {
        lines.push([
          '      ' + (ss.name || ''),
          ...ss.values,
        ]);
      }
    }
  }
}

function getDreData(year, expenses, payments) {
  const { financial, inputs, operational } = expenses;

  const totalPaid = new Array(12).fill(0);

  for (let i = 0; i < 12; i += 1) {
    for (const o of operational) {
      totalPaid[i] += o.values[i];
    }
  }

  const lines = [];
  const line_markers = [];

  line_markers.push([0, 'bold']);
  lines.push([
    year,
    'JANEIRO',
    'FEVEREIRO',
    'MARÇO',
    'ABRIL',
    'MAIO',
    'JUNHO',
    'JULHO',
    'AGOSTO',
    'SETEMBRO',
    'OUTUBRO',
    'NOVEMBRO',
    'DEZEMBRO',
  ]);

  line_markers.push([lines.length, 'bg-green']);
  lines.push([
    'Receitas com vendas',
    ...payments.methods_subtotal,
  ]);

  lines.push(
    ...payments.payment_methods.map((p) => [
      '  ' + (p.name || ''),
      ...p.data,
    ])
  );

  line_markers.push([lines.length, 'bg-green']);
  lines.push([
    'Receitas Não Operacionais',
    ...payments.methods_subtotal,
  ]);

  appendCashFlows(lines, financial, line_markers, 'bg-green');

  line_markers.push([lines.length, 'bg-orange']);
  lines.push([
    'Despesas com vendas',
    ...payments.tax_subtotal,
  ]);

  lines.push(
    ...payments.payment_methods
      .filter((p) => parseFloat(p.tax) > 0)
      .map((p) => [
        `  Taxa de ${p.name} (${p.tax}%)`,
        ...p.taxData,
      ]),
  );

  const liquid_profit = payments.methods_subtotal.map((m, i) => (
    m + 
    payments.earning_subtotal[i] -
    payments.tax_subtotal[i] -
    financial.reduce((acc, cur) => acc + cur.values[i], 0)
  ));

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'FATURAMENTO LÍQUIDO',
    ...liquid_profit,
  ]);
  
  appendCashFlows(lines, inputs, line_markers, 'bg-orange');

  const inputs_sums = [];

  for (let i = 0; i < 12; i += 1) {
    inputs_sums.push(
      inputs.reduce((total, input) => total + input.values[i], 0)
    );
  }

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'DESPESAS CMV(%)',
    ...payments.methods_subtotal.map((m, i) => {
      if (m === 0) {
        return '';
      }

      return 100 * inputs_sums[i] / (m + payments.earning_subtotal[i]);
    })
  ]);

  const contribuition_margin = liquid_profit.map((l, i) => {
    return l - inputs_sums[i];
  });

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'MARGEM DE CONTRIBUIÇÃO(R$)',
    ...contribuition_margin,
  ]);

  appendCashFlows(lines, operational, line_markers, 'bg-orange');

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'TOTAL DE CONTAS PAGAS',
    ...totalPaid,
  ]);

  const result_flat = contribuition_margin.map((cm, i) => {
    return cm + totalPaid[i];
  });

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'RESULTADO OPERACIONAL(R$)',
      ...result_flat,
  ]);

  line_markers.push([lines.length, 'bold']);
  lines.push([
    'RESULTADO OPERACIONAL(%)',
    ...payments.methods_subtotal.map((m, i) => {
      if (m === 0) {
        return '';
      }

      return 100 * result_flat[i] / (m + payments.earning_subtotal[i]);
    })
  ]);

  line_markers.push([lines.length, 'end']);

  // Removing nulls
  for (let line of lines) {
    for (let j = 0; j < line.length; j += 1) {
      if (line[j] === null || line[j] === undefined) {
        line[j] = '';
      }
    }
  }

  return { rows: lines, markers: line_markers };
}

export function generateSheet(year, expenses, payments) {
  const { rows, markers } = getDreData(year, expenses, payments);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 13;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });

  const first_col_width = rows.reduce(
    (max, r) => r[0].length > max ? r[0].length : max, 0
  );

  worksheet['!cols'][0] = { wch: first_col_width };

  const white = 'ffffff';
  const orange = 'fb6340';
  const light_orange = 'fed8cf';
  const green = '2dce89';
  const light_green = 'cbf3e2';
  const dark1 = '525f7f';
  const gray = 'dddddd';

  for (let idx = 0; idx < markers.length - 1; idx += 1) {
    const [row, marker_type] = markers[idx];

    if (marker_type === 'bold') {
      if (row === 0) {
        for (let j = 0; j < 13; j += 1) {
          const cell = worksheet[columns[j] + (row + 1)];

          cell.s = style()
            .Bg(white)
            .Borders('tb', gray)
            .Font(dark1, '', 'center')
            .Bold()
            .Get();
        }
      } else {
        const cell0 = worksheet[columns[0] + (row + 1)];
        cell0.s = style()
          .Bg(white)
          .Borders('tb', gray)
          .Font(dark1)
          .Bold()
          .Get();

        for (let j = 1; j < 13; j += 1) {
          const cell = worksheet[columns[j] + (row + 1)];

          cell.s = style()
            .Bg(white)
            .Borders('tb', gray)
            .Number()
            .Font(dark1)
            .Bold()
            .Get();
        }
      }
    } else if (marker_type === 'bg-green') {
      for (let j = 0; j < 13; j += 1) {
        const cell = worksheet[columns[j] + (row + 1)];

        if (j === 0) {
          cell.s = style().Font(white).Bg(green).Borders('tb', gray).Get();
        } else {
          cell.s = style().Number().Font(white).Bg(green).Borders('tb', gray).Get();
        }
      }

      const [end_row] = markers[idx + 1];

      let k = 0;
      for (let i = row + 1; i <= end_row; i += 1) {
        const bg = k % 2 ? light_green : white;

        for (let j = 0; j < 13; j += 1) {
          const cell = worksheet[columns[j] + (i + 1)];

          if (j === 0) {
            cell.s = style().Bg(bg).Borders('tb', gray).Get();
          } else {
            cell.s = style().Number().Bg(bg).Borders('tb', gray).Get();
          }
        }

        k += 1;
      }

    } else if (marker_type === 'bg-orange') {
      for (let j = 0; j < 13; j += 1) {
        const cell = worksheet[columns[j] + (row + 1)];

        if (j === 0) {
          cell.s = style().Font(white).Bg(orange).Borders('tb', gray).Get();
        } else {
          cell.s = style().Number().Font(white).Bg(orange).Borders('tb', gray).Get();
        }
      }

      const [end_row] = markers[idx + 1];

      let k = 0;
      for (let i = row + 1; i <= end_row; i += 1) {
        const bg = k % 2 ? light_orange : white;

        for (let j = 0; j < 13; j += 1) {
          const cell = worksheet[columns[j] + (i + 1)];

          if (j === 0) {
            cell.s = style().Bg(bg).Borders('tb', gray).Get();
          } else {
            cell.s = style().Number().Bg(bg).Borders('tb', gray).Get();
          }
        }

        k += 1;
      }
    }
  }

  const bStyle = {
    color: { rgb: 'ff000000' },
    style: 'thin',
  }; 

  for (let i = 0; i < rows.length; i += 1) {
    const cell1 = worksheet[`A${i + 1}`];
    cell1.s.border.left = bStyle;

    const cell2 = worksheet[`M${i + 1}`];
    cell2.s.border.right = bStyle;
  }

  for (let i = 0; i < 13; i += 1) {
    const cell1 = worksheet[columns[i] + '1'];
    cell1.s.border.top = bStyle;

    const cell2 = worksheet[columns[i] + rows.length];
    cell2.s.border.bottom = bStyle;
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório DRE');

  return XLSX.write(workbook, { type: 'buffer' });
}
