import React, { useCallback, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { toast } from 'react-hot-toast';

import {
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from 'reactstrap';

import ReactModal from 'react-modal';
import api from '~/services/api';
import EditComplementCategory from '../Edit';

function ComplementCategoryDropdown({
  category,
  getCategories,
  productsCategories,
  setProductsCategories,
  setCategories,
  categories,
  index,
  setComplementsCategoriesFiltered,
  fiscalGroups
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalEditCategoryData, setModalEditCategoryData] = useState();
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [count, setCount] = useState('');
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }
  function handleChangeEditCategory(cat) {
    setModalEditCategoryData(cat);
    toggleModalEditCategory();
  }

  async function handleDelete(id) {
    try {
      if (
        window.confirm(
          'Tem certeza que deseja deletar esta categoria de Complemento?'
        )
      ) {
        await api.delete(`/restaurants/product/complement-category/${id}`);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        getCategories();

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  const handleDuplicateComplementCategory = useCallback(
    async (category_id) => {
      try {
        const response = await api.put(
          `/restaurants/complement-category/duplicate/${category_id}`
        );

        const duplicate_item = response.data;

        const newCategories = [...categories];

        newCategories.splice([index + 1], 0, duplicate_item);
        setCategories(newCategories);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);
        toast.success('Categoria duplicada com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar categoria');
      }
    },
    [categories, index, setCategories]
  );
  return (
    <>
      <ReactModal
        onRequestClose={toggleModalEditCategory}
        isOpen={modalEditCategory}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <EditComplementCategory
          category={modalEditCategoryData}
          getCategories={getCategories}
          toggle={toggleModalEditCategory}
          setCount={setCount}
          productsCategories={productsCategories}
          setProductsCategories={setProductsCategories}
          setCategories={setCategories}
          categories={categories}
          setComplementsCategoriesFiltered={setComplementsCategoriesFiltered}
          fiscalGroups={fiscalGroups}
        />
      </ReactModal>

      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction="down">
        <DropdownToggle tag="span">
          {' '}
          <FaEllipsisV style={{ marginLeft: 20 }} title="Mostrar Opções" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              handleChangeEditCategory(category);
            }}
          >
            Editar Categoria
          </DropdownItem>
          {!category.is_multistore_child && !category.is_ifood && (
            <DropdownItem onClick={() => handleDelete(category.id)}>
              Excluir Item
            </DropdownItem>
          )}
          {!category.is_multistore_child && !category.is_ifood && (
            <DropdownItem
              onClick={() => handleDuplicateComplementCategory(category.id)}
            >
              Duplicar Item
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ComplementCategoryDropdown;
