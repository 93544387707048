import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/DefaultButton';
import RegisterButton from '~/components/Buttons/RegisterButton';

import { Container, Card, Header, ErrorContainer } from './styles';

import Supplier from './Supplier';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Suppliers() {
  const formRef = useRef(null);
  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] = useState(
    false
  );
  const [suppliers, setSuppliers] = useState();

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      setSuppliers(response.data);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  async function handleDeleteSupplier(id) {
    if (window.confirm('Você realmente deseja deletar o fornecedor?')) {
      try {
        await api.delete(`/restaurants/cash-flows/providers/${id}`);

        getSuppliers();
      } catch (error) {
        toast.error('Erro ao deletar fornecedor');
      }
    }
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceSuppliers'}`
      );

      const { can_read } = response.data.FinanceSuppliers;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Fornecedores</h2>
          <p>Crie aqui os fornecedores que serão usados para movimentações</p>
        </div>

        <RegisterButton onClick={toggleModalCreateSuplliers} />
      </Header>
      <Card borderless>
        <thead>
          <tr>
            <th> Nome </th>
            <th> CNPJ / CPF </th>
            <th>Telefone</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {suppliers &&
            suppliers.length > 0 &&
            suppliers.map((supplier) => (
              <Supplier
                supplier={supplier}
                getSuppliers={getSuppliers}
                handleDeleteSupplier={handleDeleteSupplier}
                key={supplier.id}
              />
            ))}
        </tbody>
      </Card>

      <Modal
        isOpen={isModalCreateSuppliersOpen}
        toggle={toggleModalCreateSuplliers}
      >
        <ModalHeader> Criar Fornecedor</ModalHeader>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalBody>
            <Input label="Nome do fornecedor" name="name" />
            <Input label="CNPJ / CPF" name="cnpj" />
            <InputMask mask="(99) 99999-9999">
              <Input
                label="Telefone"
                name="phone"
                maskChar=""
                placeholder="(xx) xxxxx-xxxx"
              />
            </InputMask>
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default Suppliers;
