import React, { useState } from 'react';
import { Form } from '@unform/web';
import { TableReport, EditButton } from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import { Row, Col, ModalBody, Modal, Button } from 'reactstrap';
import { differenceInDays } from 'date-fns';
import Input from '~/components/Form/Input';
import InputMask from 'react-input-mask';
import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { AiOutlineWhatsApp } from 'react-icons/ai';

function ClientsRow({ report, has_service_tax }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const toggleEdit = () => setIsOpenEdit(!isOpenEdit);

  function titleize(text) {
    var words = text.toLowerCase().split(' ');
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      if (w[0] !== undefined) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(' ');
  }

  async function redirectWhatsApp() {
    window.open(
      `https://api.whatsapp.com/send?phone=55${report.phone
        .replace('(', '')
        .replace(')', '')}&text=Olá!`,
      '_blank'
    );
  }

  async function editClient(data) {
    if (report.client) {
      try {
        const response = await api.put(
          `restaurants/clients/${report.client.id}`,
          data
        );
        report.client = response.data;
        toggleEdit();
        toast.success('Cliente atualizado com sucesso!');
      } catch (err) {
        toast.error('Não foi possível atualizar o cadastro! tente novamente');
      }
    } else {
      try {
        data.phone = report.phone;
        const response = await api.post(`restaurants/clients`, data);
        report.client = response.data;
        toggleEdit();
        toast.success('Cliente cadastrado com sucesso!');
      } catch (err) {
        toast.error('Não foi possível cadastrar o cliente! Tente novamente');
      }
    }
  }

  let initialData = report.client
    ? report.client
    : { phone: report.phone, name: report.name };
  return (
    <>
      <tr key={report.id}>
        <td style={{ color: '#3D3D3D', fontSize: '18px' }}>{report?.phone}</td>
        <td>{report?.client?.name || report.name}</td>
        <td style={{ color: 'green' }}>
          R$
          {parseFloat(report?.total).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          })}
        </td>
        {has_service_tax && (
          <td style={{ color: '#2DB43D' }}>
            R$
            {parseFloat(report?.total_service).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            })}
          </td>
        )}
        <td>
          {report?.visits} {report?.visits === 1 ? 'visita' : 'visitas'}
        </td>
        <td style={{ color: '#f97c22' }}>
          {report.last_rating ? report.last_rating : '-'} ★
        </td>
        <td>
          {report.bills.length > 0
            ? formatCompleteDate(report.bills[0].start_time)
            : '-'}
        </td>
        <td>
          <Button
            style={{ background: '#26d367', border: 'none' }}
            onClick={() => redirectWhatsApp()}
          >
            <AiOutlineWhatsApp />
          </Button>
          <Button
            style={{ background: '#00A6ED', border: 'none', marginLeft: 5 }}
            onClick={() => toggle()}
          >
            +
          </Button>
        </td>
      </tr>

      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalBody style={{ margin: 30 }}>
          <Row>
            <Col md="12">
              <h3>
                {titleize(report.client?.name || report.name || report.phone)}
              </h3>
              <EditButton
                onClick={() => toggleEdit()}
                style={{ float: 'right' }}
              >
                {report.client ? 'Editar Informações' : 'Finalizar Cadastro'}
              </EditButton>
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col md="4">
              <strong>Telefone: </strong>
              {report.phone}
            </Col>
            <Col md="4">
              <strong>Data de Nascimento: </strong>
              {report.client?.birthday}
            </Col>
            <Col md="4">
              <strong>CPF: </strong>
              {report.client?.cpf}
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col md="4">
              <strong>CEP: </strong>
              {report.client?.cep}
            </Col>
            <Col md="4">
              <strong>Complemento: </strong>
              {report.client?.complement}
            </Col>
            <Col md="4">
              <strong>Número: </strong>
              {report.client?.number}
            </Col>
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col md="12">
              <strong>Rua: </strong>
              {report.client?.street}
            </Col>
          </Row>

          <Row style={{ paddingTop: 20, fontSize: 16 }}>
            <Col style={{ color: 'green' }}>
              <strong>Gastou até hoje: </strong> R$
              {parseFloat(report.total).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              })}
            </Col>
            {has_service_tax && (
              <Col style={{ color: '#2DB43D' }}>
                <strong>Gastou até hoje (com taxa): </strong> R$
                {parseFloat(report.total_service).toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </Col>
            )}
          </Row>

          <Row style={{ paddingTop: 20 }}>
            <Col>
              <strong>Visitas: </strong> {report.visits}{' '}
              {report?.visits === 1 ? 'visita' : 'visitas'}
            </Col>
            <Col>
              <strong>Última Visita: </strong>{' '}
              {report.bills.length > 0
                ? formatCompleteDate(report.bills[0].start_time)
                : '-'}
            </Col>
          </Row>

          <TableReport>
            <thead>
              <tr>
                <th className="text-left">Abertura de Comanda</th>
                <th>Fechamento</th>
                <th>Valor gasto</th>
                {has_service_tax && <th>Valor gasto com taxa</th>}
                <th>Avaliação</th>
                <th>Dias</th>
              </tr>
            </thead>
            <tbody>
              {report.bills.map((bill, id) => (
                <tr key={`cr-bill:${id}`}>
                  <td className="text-left">
                    {bill?.start_time && formatCompleteDate(bill.start_time)}
                  </td>
                  <td>
                    {bill?.close_time
                      ? formatCompleteDate(bill.close_time)
                      : 'Comanda Aberta'}
                  </td>
                  <td style={{ color: 'green' }}>
                    R$
                    {parseFloat(bill.total_price).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  {has_service_tax && (
                    <td style={{ color: '#2DB43D' }}>
                      R$
                      {parseFloat(
                        bill.total_service_price
                      ).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                    </td>
                  )}
                  <td style={{ color: '#f97c22' }}>
                    {bill.session.ratings.length > 0
                      ? bill.session.ratings[0].stars
                      : '-'}{' '}
                    ★
                  </td>
                  <td>
                    {differenceInDays(new Date(), new Date(bill.start_time)) > 0
                      ? `há ${differenceInDays(
                          new Date(),
                          new Date(bill.start_time)
                        )} dia(s)`
                      : 'Hoje'}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableReport>
        </ModalBody>
      </Modal>

      <Modal isOpen={isOpenEdit} toggle={toggleEdit}>
        <ModalBody>
          <Form initialData={initialData} onSubmit={editClient}>
            <Row>
              <Col>
                <Input name="name" label="Nome: (Opcional)" type="text" />
              </Col>
              <Col>
                <Input name="email" label="E-mail: (Opcional)" type="email" />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputMask mask="999.999.999-99">
                  <Input name="cpf" label="CPF: (Opcional)" type="text" />
                </InputMask>
              </Col>
              <Col>
                <InputMask mask="99/99/9999">
                  <Input
                    name="birthday"
                    label="Data de Nascimento: (Opcional)"
                    type="text"
                  />
                </InputMask>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputMask mask="99.999-999">
                  <Input name="cep" label="CEP: (Opcional)" type="text" />
                </InputMask>
              </Col>
              <Col>
                <Input name="bairro" label="Bairro: (Opcional)" type="text" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name="street" label="Rua: (Opcional)" type="text" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name="number" label="Nº: (Opcional)" type="text" />
              </Col>
              <Col>
                <Input
                  name="complement"
                  label="Complemento: (Opcional)"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  type="submit"
                  style={{
                    background: '#17a2b8',
                    height: '30px',
                    paddingTop: '2px',
                    float: 'right',
                    fontSize: '14px',
                  }}
                >
                  {report.client
                    ? 'Atualizar Informações do Cliente'
                    : 'Finalizar Cadastro'}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ClientsRow;
