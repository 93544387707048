import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Form } from '@unform/web';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

import * as Yup from 'yup';

import { toast } from 'react-hot-toast';
import {
  AiOutlineEye,
  AiOutlinePrinter,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from 'react-icons/ai';
import { FaHandHoldingUsd, FaRedo, FaExclamationCircle } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';

import ReactToPrint from 'react-to-print';

import { TiTicket } from 'react-icons/ti';
import { useAuth } from '~/context/AuthContext';
import SelectTable from '~/components/Form/SelectTable';
import FooterNF from '~/components/FooterNF';
import {
  Actions,
  StatusTag,
  ModalPaymentBody,
  DisplayNoneImprimir,
  ActionsInside,
  DeleteAndEditActions,
  CloseButton,
  SeeButton,
  CashierButton,
  ReopenButton,
  Content,
  ButtonStyled,
} from './styles';
import PaymentForm from '~/components/PaymentForm';
import formatCompleteDate from '~/services/formatCompleteDate';
import EyeBillsTableOperation from '../Tables/CloseOrder/Eye/EyeBillsTableOperation';
import api from '~/services/api';
import OrdersBillOperation from '../Tables/Bills/OrdersBillOperation';
import EyeOrdersBillOperation from '../Tables/EyeOrdersBillOperation';

import PrintBillsOrderOperation from '../Prints/PrintBillsOrderOperation';
import Input from '../Form/Input';
import getValidationErrors from '~/services/getValidationErrors';
import { useClube } from '~/context/ClubeContext';
import { PaymentPercentTag } from 'ui-kit-takeat';

export default function BillOperation({
  session,
  getSessions,
  setSessions,
  sessions,
  inicialDate,
  finalDate,
  has_nfce,
  getTablesAvailable,
  orderByTableDate,
  getMinimalSessions,
  setAllTablesAvailable,
  setInputValueTables,
  setTablesFilteredSearch,
}) {
  const { user, userAccess } = useAuth();
  const { sessionsAwarded, handlePontuarSession } = useClube();
  const [sessionToUse, setSessionToUse] = useState(session);
  const [, setTableChanged] = useState(null);
  const [bills, setBills] = useState([]);
  const [count, setCount] = useState('');
  const [sessionPontuada, setSessionPontuada] = useState(false);
  const [buttonPontuarText, setButtonPontuarText] = useState(
    'Pontuar Clientes'
  );

  const [modalClube, setModalClube] = useState(false);

  function toggleClube() {
    setModalClube(!modalClube);
  }

  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);
  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionBills] = useState();
  const [productBills, setProductBills] = useState();
  const [commandTableNumber, setCommandTableNumber] = useState();
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { rescues, setRescues } = useClube();

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  const [tables, setTables] = useState([]);
  const componentRef = useRef(null);
  function handleUpdateSession() {
    orderByTableDate();
    getMinimalSessions();
    getTablesAvailable();
  }

  const status = useMemo(() => {
    if (session.status === 'open') {
      return {
        statusText: 'Aberta',
        color: '#17a2b8',
      };
    }
    if (session.status === 'closing') {
      return {
        statusText: 'Fechando',
        color: '#17a2b8',
      };
    }
    if (session.status === 'finished') {
      return {
        statusText: 'Fechada',
        color: 'green',
      };
    }
    if (session.status === 'finished2') {
      return {
        statusText: 'Fechada',

        color: '#dc3545',
      };
    }
    return {
      statusText: '',
      color: '#ddd',
    };
  }, [session.status]);

  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const [modal, setModal] = useState(false);
  const [payments, setAllPayments] = useState(session?.payments);
  const [modalEye, setModalEye] = useState(false);

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }

  const [modalReopen, setModalReopen] = useState(false);

  function toggleReopen() {
    setModalReopen(!modalReopen);
  }

  const [modalTransfer, setModalTransfer] = useState(false);
  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  const [loadingBills, setLoadingBills] = useState(false);

  const getBillsSession = useCallback(async () => {
    setLoadingBills(true);
    const response = await api.get(
      `restaurants/table-sessions-bills/${session.id}`
    );

    setBills(response.data.bills);
    setPrintBills(response.data.print_bills);
    setSessionBills(response.data.session);
    setProductBills(response.data.products);

    const baskets = [];

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setCommandTableNumber(sorted[0]);

    let sessionPontuada2 = true;
    response.data.bills.forEach((bill) => {
      if (!bill.rescued_clube) {
        sessionPontuada2 = false;
      }
    });
    setSessionPontuada(sessionPontuada2);
    if (sessionPontuada2) {
      setButtonPontuarText('Todos Clientes Já Pontuados!');
    }
    setLoadingBills(false);
  }, [session.id]);

  async function handlePontuarBill({ session_key, bill_id }) {
    document.getElementById(bill_id).disabled = true;
    try {
      await api.post('public/clube/send/bill', {
        session_key,
        bill_id,
      });
      toast.success('Cliente pontuado!');
      getSessions();
      toggleClube();
    } catch (error) {
      toast.error('Não foi possível pontuar o cliente. Tente novamente');
      document.getElementById(bill_id).disabled = false;
    }
  }

  const getTables = useCallback(async (id) => {
    const response = await api.get('/restaurants/tables');
    const tablesData = response.data;

    const tablesFiltered = tablesData.filter(
      (table) => table.table_type === 'table' || table.table_type === 'command'
    );

    const tablesOptions = [];

    tablesFiltered.forEach((table) => {
      let status;
      if (table.status === 'available') {
        status = 'Disponível';
      } else {
        status = 'Em Uso';
      }

      tablesOptions.push({
        value: table.id,
        label: `${table.table_number.toString()} - ${status}`,
      });
    });

    setTables(tablesOptions);
  }, []);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: session.id,
        close_after_print: true,
      });
      const newSessions = sessions?.filter((s) => s.id !== session.id);
      setSessions(newSessions);
      toast.success('Comanda impressa e fechada.');
    } catch (error) {
      toast.error('Erro ao imprimir e fechar comanda.');
    }
  }

  const payments_bill = session?.payments;

  const [paymentsBill, setPaymentsBill] = useState(payments_bill);

  async function handlePrintItem({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getSessions();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  async function handleSentMenew() {
    try {
      await api.post('restaurants/menew/send-bills', {
        session_key: session.key,
      });
      toast.success('Enviado para Menew!');
    } catch (error) {
      toast.error('Não foi possível enviar para Menew. Tente novamente');
    }
  }

  const getPayments = useCallback(async () => {
    let total = 0;

    paymentsBill.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });
    setPaid(total.toFixed(2).toString());

    const troco =
      total > sessionToUse?.total_service_price
        ? total - sessionToUse?.total_service_price
        : 0;

    const porcentPaid = sessionToUse?.is_delivery
      ? (total / sessionToUse?.total_delivery_price) * 100
      : ((total - troco) / sessionToUse?.total_service_price) * 100;
    setPorcent(parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, sessionToUse]);

  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
    getPayments();
  }, [setModalEye, modalEye, getPayments]);

  function toggle() {
    setModal(!modal);
    getPayments();
  }

  async function handleReopenTableBill() {
    try {
      await api.put('restaurants/reopen-table-bill', {
        key: session.key,
      });
      getSessions();
      setInputValueTables();
      setTablesFilteredSearch('');

      getTablesAvailable();
      setAllTablesAvailable('');

      toast.success('Comanda reaberta!');
    } catch (error) {
      if (session.table.status === 'ongoing') {
        toast.error('Essa mesa está aberta com outra sessão.');
      } else {
        toast.error('Erro ao reabrir mesa.');
        console.log(error);
      }
    }
  }

  async function handleTransferTableBill(data) {
    try {
      setIsSubmiting(true);
      await api.put('restaurants/transfer-table-bill', {
        key: session.key,
        table_id: data.new_table_id,
      });

      setTableChanged(data.new_table_id);
      toggleTransfer();
      getTablesAvailable();
      setIsSubmiting(false);
      toast.success('Mesa transferida com sucesso!');
    } catch (error) {
      toast.error('Erro ao transferir mesa.');
      setIsSubmiting(false);
    }
  }

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  useEffect(() => {
    try {
      if (modalEye) {
        getBillsSession();
      }
    } catch (error) {
      console.log(error);
    }
  }, [session, getBillsSession, modalEye]);

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_number === -10) {
      return 'Copa';
    }

    return table.table_number;
  }

  const openingBoxRef = useRef();

  const verifyOpeningBox = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggleEye();
    } catch (err) {
      if (err?.response?.data?.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggleEye, toggleModalHasOpenedBoxMessage]);

  const [
    isModalOnlineDepositsOpened,
    setIsModalOnlineDepositsOpened,
  ] = useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();
          toggleEye();

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      toggleEye,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );

  return (
    <>
      <Content>
        <Modal
          isOpen={isModalOnlineDepositsOpened}
          toggle={toggleOnlineDepositisModal}
        >
          <ModalBody>
            <strong>
              Os valores a seguir, que foram pagamentos online feitos enquanto
              seu caixa estava fechado, entrarão no caixa que você está abrindo:
            </strong>
            {onlineDeposits &&
              onlineDeposits.length > 0 &&
              onlineDeposits
                .filter((item) => item.total > 0)
                .map((deposit) => (
                  <p>
                    {deposit.description} - R$ {deposit.total.toFixed(2)}
                  </p>
                ))}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                toggleOnlineDepositisModal();
                setOnlineDeposits([]);
              }}
            >
              Ok, entendi
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
          <Form onSubmit={openingBox} ref={openingBoxRef}>
            <ModalBody>
              <FaExclamationCircle color="darkOrange" size={32} />
              <h3>Você precisa abrir seu caixa para inserir pagamentos.</h3>

              <Input
                name="opening_value"
                label="Valor inicial no caixa (Dinheiro)"
                type="number"
                step="0.01"
                min="0"
              />
            </ModalBody>
            <ModalFooter>
              <ButtonStyled
                operationType="withdrawal"
                type="button"
                onClick={toggleModalHasOpenedBoxMessage}
              >
                Cancelar
              </ButtonStyled>
              <ButtonStyled operationType="deposit" type="submit">
                Abrir caixa
              </ButtonStyled>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal
          isOpen={isModalErrorUserPdvOpened}
          toggle={toggleModalErrorUserPdv}
        >
          <ModalBody>
            Seu perfil de usuário não possui permissão de abrir caixa. Solicite
            ao seu administrador
          </ModalBody>
          <ModalFooter>
            <div style={{ width: '100%' }}>
              <ButtonStyled
                operationType="deposit"
                type="button"
                onClick={toggleModalErrorUserPdv}
                style={{ float: 'right' }}
              >
                Entendi, irei solicitar.
              </ButtonStyled>
            </div>
          </ModalFooter>
        </Modal>

        <td>{searchTableName(session.table)}</td>
        <td>{formatCompleteDate(session.start_time)}</td>
        <td>#{session.id}</td>
        <td>R${sessionToUse.total_service_price}</td>
        {has_nfce && session.status === 'completed' && (
          <th>
            {session.nfce_allowed ? (
              <AiOutlineCheckCircle size={23} color="green" />
            ) : (
              <AiOutlineCloseCircle size={23} color="red" />
            )}
          </th>
        )}
        <td>
          <PaymentPercentTag
            text={status.statusText}
            percent={
              sessionToUse.total_service_price === '0.00' ? '100' : porcent
            }
          />
        </td>
        <td>
          {' '}
          <Actions>
            {session?.status === 'finished' ||
            session?.status === 'completed' ? (
              <>
                <ActionsInside>
                  <DeleteAndEditActions>
                    {session?.status === 'completed' && user.has_menew && (
                      <CloseButton
                        onClick={() => handleSentMenew(session?.key)}
                        style={{ background: 'green' }}
                      >
                        <div style={{ fontSize: 10 }}>Enviar Menew</div>
                      </CloseButton>
                    )}
                    {user.is_printed_on_web || user.print_bills_on_web ? (
                      <ReactToPrint
                        onBeforeGetContent={async () => {
                          await getBillsSession();
                        }}
                        trigger={() => (
                          <CloseButton>
                            {!loadingBills ? (
                              <div style={{ fontSize: 10 }}>
                                <AiOutlinePrinter size={9} /> Imprimir{' '}
                              </div>
                            ) : (
                              <Spinner size="sm" />
                            )}
                          </CloseButton>
                        )}
                        content={() => componentRef.current}
                      />
                    ) : (
                      <>
                        {session?.is_delivery ? (
                          <ReactToPrint
                            onBeforeGetContent={async () => {
                              await getBillsSession();
                            }}
                            trigger={() => (
                              <CloseButton>
                                {!loadingBills ? (
                                  <div style={{ fontSize: 10 }}>
                                    <AiOutlinePrinter size={9} /> Imprimir{' '}
                                  </div>
                                ) : (
                                  <Spinner size="sm" />
                                )}
                              </CloseButton>
                            )}
                            content={() => componentRef.current}
                          />
                        ) : (
                          <CloseButton onClick={printClick}>
                            <div style={{ fontSize: 10 }}>
                              <AiOutlinePrinter size={9} />
                              Imprimir
                            </div>
                          </CloseButton>
                        )}
                      </>
                    )}
                    {session?.status === 'finished' && (
                      <>
                        {!user?.is_pdv ? (
                          <CashierButton onClick={verifyOpeningBox}>
                            <div style={{ fontSize: 10 }}>
                              <FaHandHoldingUsd size={13} />
                              Caixa
                            </div>
                          </CashierButton>
                        ) : (
                          <div />
                        )}

                        {session?.is_delivery ||
                        session?.is_prepaid ||
                        session.table.table_type === 'pos' ? (
                          <div />
                        ) : (
                          <ReopenButton onClick={toggleReopen}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: 10,
                              }}
                            >
                              <FaRedo size={8} style={{ marginRight: 1 }} />
                              Reabrir
                            </div>
                          </ReopenButton>
                        )}
                      </>
                    )}
                  </DeleteAndEditActions>
                </ActionsInside>
              </>
            ) : (
              <div>
                <ActionsInside>
                  <DeleteAndEditActions>
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        await getBillsSession();
                      }}
                      trigger={() => (
                        <CloseButton>
                          <div style={{ fontSize: 11 }}>
                            <AiOutlinePrinter size={10} />
                            Fechar
                          </div>
                        </CloseButton>
                      )}
                      content={() => componentRef.current}
                      onAfterPrint={() =>
                        handlePrintItem({
                          session_key: session?.key,
                        })
                      }
                    />
                    {!user?.is_pdv ? (
                      <CashierButton onClick={toggle}>
                        <div style={{ fontSize: 11 }}>
                          <FaHandHoldingUsd
                            size={13}
                            style={{ marginRight: 1 }}
                          />
                          Caixa
                        </div>
                      </CashierButton>
                    ) : (
                      <div />
                    )}

                    <SeeButton onClick={toggleEyeOpen}>
                      <div style={{ fontSize: 10 }}>
                        <AiOutlineEye size={9} style={{ marginRight: 0 }} />
                        Espelho
                      </div>
                    </SeeButton>

                    {!session?.is_delivery && (
                      <ReopenButton
                        onClick={toggleTransfer}
                        style={{ background: '#ffc107', color: 'black' }}
                      >
                        <div style={{ fontSize: 11 }}>
                          <BiTransfer size={13} style={{ marginRight: 1 }} />
                          Mesa
                        </div>
                      </ReopenButton>
                    )}
                  </DeleteAndEditActions>
                </ActionsInside>
              </div>
            )}
          </Actions>
        </td>

        <td>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalBody>
              <ModalPaymentBody>
                <PaymentForm
                  session={session}
                  getSessions={getMinimalSessions}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  setCount={setCount}
                  orderByTableDate={orderByTableDate}
                  setRescues={setRescues}
                />
                <OrdersBillOperation session={session} isOpen={modal} />
              </ModalPaymentBody>
            </ModalBody>
          </Modal>
          <Modal isOpen={modalEyeOpen} toggle={toggleEyeOpen}>
            <ModalBody>
              <Container>
                <EyeOrdersBillOperation
                  session={session}
                  inicialDate={inicialDate}
                  finalDate={finalDate}
                  setSessions={setSessions}
                  toggleEyeOpen={toggleEyeOpen}
                  orderByTableDate={orderByTableDate}
                />
              </Container>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getBillsSession();
                }}
                trigger={() => (
                  <CloseButton>
                    <div style={{ fontSize: 15 }}>
                      <AiOutlinePrinter size={14} style={{ marginRight: 5 }} />
                      Imprimir
                    </div>
                  </CloseButton>
                )}
                content={() => componentRef.current}
              />
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalReopen} toggle={toggleReopen}>
            <ModalBody>
              {session?.table?.status === 'available' ||
              session?.table?.table_type === 'balcony' ? (
                <p>Tem certeza que deseja reabrir essa comanda?</p>
              ) : (
                <p style={{ color: 'red' }}>
                  Essa mesa está com uma sessão ativa no momento.
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              {(session?.table?.status === 'available' ||
                session?.table?.table_type === 'balcony') && (
                <Button
                  onClick={handleReopenTableBill}
                  style={{ background: 'green' }}
                >
                  Sim, reabrir
                </Button>
              )}
              <Button onClick={toggleReopen} style={{ background: '#dc3545' }}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
            <Form onSubmit={handleTransferTableBill}>
              <ModalBody>
                <h3>Transferência de Mesa</h3>
                {tables.length !== 0 ? (
                  <Row>
                    <Col md="5">
                      <SelectTable
                        name="new_table_id"
                        label="Nova mesa:"
                        options={tables.filter(
                          (a) => a.value !== session?.table?.id
                        )}
                      />
                    </Col>
                    <Col md="6">
                      <h6>
                        Mesa atual:{' '}
                        <strong>{session?.table?.table_number}</strong>
                      </h6>
                    </Col>
                  </Row>
                ) : (
                  <p style={{ color: 'red' }}>
                    Não há nenhuma mesa disponível no momento.
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                {tables.length !== 0 && (
                  <Button
                    type="submit"
                    style={{ background: 'green' }}
                    disabled={isSubmiting}
                  >
                    Transferir
                  </Button>
                )}
                <Button
                  onClick={toggleTransfer}
                  style={{ background: '#dc3545' }}
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
          <DisplayNoneImprimir>
            <PrintBillsOrderOperation
              item={session}
              restaurant={user}
              ref={componentRef}
              count={count}
              paymentData={payments}
              printBills={printBills}
              sessionBills={sessionBills}
              productBills={productBills}
              commandTableNumber={commandTableNumber}
            />
          </DisplayNoneImprimir>
          <Modal isOpen={modalEye} toggle={toggleEye} size="lg">
            <ModalBody>
              <ModalPaymentBody>
                <PaymentForm
                  session={sessionToUse}
                  setSession={setSessionToUse}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  setCount={setCount}
                  getSessions={getMinimalSessions}
                  setRescues={setRescues}
                />
                <EyeBillsTableOperation
                  item={sessionToUse}
                  setItem={setSessionToUse}
                  payments_bill={payments_bill}
                  bills={bills}
                  count={count}
                />
              </ModalPaymentBody>
              <Row style={{ marginTop: 10 }}>
                <Col md="6" />
                {user.has_clube && (
                  <Col md="6">
                    {sessionPontuada ? (
                      <Button
                        disabled
                        style={{
                          float: 'right',
                          background: '#03db9e',
                          border: 'none',
                          color: 'black',
                        }}
                      >
                        <TiTicket size={22} color="black" />
                        {buttonPontuarText}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          float: 'right',
                          background: '#03db9e',
                          border: 'none',
                          color: 'black',
                        }}
                        onClick={toggleClube}
                      >
                        <TiTicket size={22} color="black" />
                        {buttonPontuarText}
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </ModalBody>
            {session?.status === 'finished' && (
              <ModalFooter>
                <FooterNF
                  session={session}
                  toggleEye={toggleEye}
                  getSessions={handleUpdateSession}
                  payments={payments}
                  has_nfce={user.has_nfce}
                  rescues={rescues}
                  setRescues={setRescues}
                />
              </ModalFooter>
            )}
          </Modal>
          <Modal isOpen={modalClube} toggle={toggleClube}>
            <ModalBody>
              <Row>
                <Col>
                  <h3>Pontuar no Clube</h3>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {porcent < 100 && (
                    <p>
                      Deseja pontuar apenas R${paid} do valor consumido aos
                      clientes?
                    </p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <Col md="6">
                  {!sessionPontuada && (
                    <Button
                      id={`s${session?.key}`}
                      style={{ background: '#02A174', border: 'none' }}
                      onClick={() => {
                        if (!sessionsAwarded.includes(session?.id)) {
                          handlePontuarSession({ session_key: session?.key });
                        } else {
                          toast.error('Sessão já pontuada!');
                        }
                      }}
                    >
                      Pontuar todos
                    </Button>
                  )}
                </Col>
              </Row>
              {bills &&
                bills.length > 0 &&
                bills.map(
                  (bill) =>
                    bill.buyer && (
                      <Row style={{ marginBottom: 14 }}>
                        <Col md="8">
                          <h5>{bill.buyer.phone || bill.buyer.name}</h5>
                        </Col>
                        <Col md="4">
                          {bill.rescued_clube || sessionPontuada ? (
                            <p style={{ textAlign: 'right', marginTop: 4 }}>
                              Pontuado!
                            </p>
                          ) : (
                            <Button
                              id={bill.id}
                              style={{
                                float: 'right',
                                background: '#02CA91',
                                border: 'none',
                              }}
                              onClick={() => {
                                if (!sessionsAwarded.includes(session?.id)) {
                                  handlePontuarBill({
                                    session_key: session?.key,
                                    bill_id: bill.id,
                                  });
                                } else {
                                  toast.error('Sessão já pontuada!');
                                }
                              }}
                            >
                              Pontuar
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )
                )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggleClube} className="bg-danger">
                Fechar
              </Button>
            </ModalFooter>
          </Modal>
        </td>
      </Content>
    </>
  );
}
