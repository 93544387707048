import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { TiDelete, TiTicket } from 'react-icons/ti';
import { toast } from 'react-hot-toast';

import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import api from '~/services/api';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  CancelButton,
  OrdersInfo,
  InfoDiv,
} from '../styles';
import {
  Print,
  DisplayNoneImprimir,
  ModalPaymentBody,
  ContainerOrders,
} from './styles';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';
import FooterNF from '~/components/FooterNF';
import PaymentFormDelivery from '~/components/Delivery/PaymentFormDelivery';

import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import Select from '~/components/Form/SelectInput';
import { useMotoboys } from '~/context/MotoboysContext';
import { FaEye, FaPrint } from 'react-icons/fa';

export default function OrderSchedulingReady({
  item,
  handleItemFinished,
  handleCancelItem,
  getAllOrders,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);

  const { motoboys } = useMotoboys();

  const [paymentsState, setPaymentsState] = useState([]);
  const [sessionPontuada, setSessionPontuada] = useState(false);
  const [modalClube, setModalClube] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [discount, setDiscount] = useState('');
  const [discountObs, setDiscountObs] = useState('');
  function toggleClube() {
    setModalClube(!modalClube);
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  const [buttonPontuarText, setButtonPontuarText] =
    useState('Pontuar Clientes');

  const [modalEye, setModalEye] = useState(false);
  function toggleEye() {
    setModalEye(!modalEye);
    getPayments();
  }

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getPayments = useCallback(async () => {
    const response = await api.get('restaurants/payments', {
      params: { table_session_id: item.session_id },
    });

    const parsedData = response.data.payments.map((data) => ({
      id: data.id,
      value: data.payment_value,
      payment_method_id: data.payment_method_id,
      removable: data.removable,
    }));

    setPaymentsState(parsedData);
  }, [item.session_id]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const { user } = useAuth();
  const componentRef = useRef(null);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  const handleUpdateMotoboy = async (id) => {
    try {
      await api.post('/restaurants/motoboys/relate', {
        session_id: item.session_id,
        motoboy_id: id,
      });

      item.motoboy = motoboys.find((m) => m.id === id);

      toast.success('Motoboy atualizado com sucesso!');
    } catch (err) {
      toast.error('Erro ao atualizar motoboy');
    }
  };

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleDiscountUpdate = async () => {
    if (item?.discount_total !== discount && discount > 0) {
      try {
        const response = await api.put(
          `/restaurants/delivery/orders/update/${item?.session_id}`,
          {
            discount: applyDiscount ? parseFloat(discount) : null,
            discount_obs: applyDiscount ? discountObs : null,
            user_id: user.user_id,
          }
        );

        console.log('res > ', response.data);
      } catch (err) {
        console.log('Error handleDiscountUpdate: ', err);
      }
    }
  };

  useEffect(() => {
    if (modalEye) {
      try {
        getPayments();
      } catch (error) {
        toast.error('Erro ao carregar informações');
      }
    }
  }, [getPayments, modalEye]);

  return (
    <Pedido>
      <ContentBody>
        <InfoDiv style={{ textAlign: 'left' }}>
          <OrderNumber>#{item?.attendance_password}</OrderNumber>
          <Time>{formatCompleteDate(item?.basket.start_time)}</Time>
          <img
            src={item.basket.ifood_id === null ? logoTakeat : logoIfood}
            style={{ width: item.basket.ifood_id === null ? 20 : 40 }}
          />
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            {item?.basket?.ifood_table
              ? item?.basket?.ifood_table
              : searchTableName(item?.table)}
          </TableNumber>

          <PhoneNumber>{item?.buyer?.name}</PhoneNumber>
          <PhoneNumber style={{ textAlign: 'left' }}>
            {`Agendado para ${formatCompleteDate(item.scheduled_to)}`}
          </PhoneNumber>
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            <MdDone
              size={20}
              color="#3BD2C1"
              style={{ marginRight: 3, marginBottom: 10 }}
              onClick={toggleEye}
              cursor="pointer"
            />

            <FaEye
              onClick={toggle}
              color="#FFA50B"
              size={18}
              style={{ marginRight: 3, marginBottom: 10 }}
              cursor="pointer"
            />

            <TiDelete
              color="#FE1933"
              size={20}
              onClick={toggleDeleteDelivery}
              style={{ marginRight: 3, marginBottom: 10 }}
              cursor="pointer"
            />
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ContainerOrders browserZoomLevel={browserZoomLevel}>
            <EyeModalOrders item={item} />
          </ContainerOrders>
        </ModalBody>
        <ModalFooter>
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <>
              {item?.table.table_type === 'delivery' ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    await getOrders();
                  }}
                  trigger={() => (
                    <Print>
                      <FaPrint color="#fff" size={20} />
                      Imprimir
                    </Print>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                <Print onClick={printClick}>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'accepted',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="accepted"
              orders={orders}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'accepted',
              })
            }
          >
            Cancelar esse(s) item(s)
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEye} toggle={toggleEye} size="lg">
        <ModalBody>
          <ModalPaymentBody>
            <PaymentFormDelivery
              item={item}
              payments_session={paymentsState}
              getPayments={getPayments}
              setPaymentsState={setPaymentsState}
              discount={discount}
              discountObs={discountObs}
              setDiscount={setDiscount}
              setDiscountObs={setDiscountObs}
              applyDiscount={applyDiscount}
              setApplyDiscount={setApplyDiscount}
            />
          </ModalPaymentBody>
          <Row style={{ marginTop: 10 }}>
            {motoboys?.length > 0 ? (
              <Col md="6">
                <Form>
                  <Select
                    name="motoboy"
                    label="Motoboy"
                    options={motoboys?.map((m) => {
                      return {
                        value: m.id,
                        label: m.name,
                      };
                    })}
                    defaultValue={
                      item?.motoboy
                        ? motoboys
                            ?.filter((moto) => moto.id === item.motoboy.id)
                            .map((m) => ({ value: m.id, label: m.name }))
                        : []
                    }
                    onChange={(e) => {
                      handleUpdateMotoboy(e.value);
                    }}
                  />
                </Form>
              </Col>
            ) : (
              <Col md="6" />
            )}
          </Row>
        </ModalBody>

        <ModalFooter>
          <FooterNF
            session={item}
            toggleEye={toggleEye}
            getSessions={getAllOrders}
            payments={paymentsState}
            has_nfce={user.has_nfce}
            item={item}
            handleItemFinished={handleItemFinished}
            motoboys={motoboys}
            handleDiscountUpdate={handleDiscountUpdate}
            discount={discount}
            discountObs={discountObs}
            applyDiscount={applyDiscount}
          />
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
