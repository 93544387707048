import React from 'react';
import OrderCard from '../OrderCard';

import { Container } from './styles';

function OrdersFinished({ baskets, kdsViewMode }) {
  return (
    <Container kdsViewMode={kdsViewMode}>
      {baskets?.length > 0 &&
        baskets
          ?.filter((item) => item.status === 'finished')
          .map((item) => <OrderCard item={item} key={item.id} />)}
    </Container>
  );
}

export default OrdersFinished;
