import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { MdDelete, MdCreate, MdExpandMore } from 'react-icons/md';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import api from '~/services/api';
import { Collapse } from 'reactstrap';
import NFeInfo from '~/services/NFeInfo';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import InputMask from 'react-input-mask';
import SaveButton from '~/components/Buttons/SaveButton';
import { Form } from '@unform/web';
import {
  Container,
  CategoryHeader,
  Delete,
  CategoryName,
  ButtonCollapse,
  GroupTable,
} from './styles';
import { TiGroupOutline } from 'react-icons/ti';
import CheckboxInput from '~/components/Form/Checkbox';

function FiscalGroupComponent({ group, user, setGroups }) {
  const [modalEdit, setModalEdit] = useState(false);

  const toggleModalEdit = useCallback(() => {
    setModalEdit(!modalEdit);
  }, [modalEdit]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => setIsOpen(!isOpen);

  const [cstValue, setCstValue] = useState(group.cst);

  const [updateProducts, setUpdateProducts] = useState(true);

  const icms_origem_options = NFeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = NFeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = NFeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  const [csosnSelected, setCsosnSelected] = useState(group.csosn || []);
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = NFeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = NFeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        title: data.title,
        updateProducts,
      };

      if (user.regime_tributario === '1') {
        parsedData.cfop = data.cfop;
        parsedData.csosn = data.csosn;
        // parsedData.cest = data?.cest || null;
        parsedData.icms_origem = data.icms_origem;

        if (csosnSelected === '00') {
          parsedData.aliquota = data.aliquota;
          parsedData.base_calculo = data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = data.pis;
        }
      } else {
        parsedData.cfop = data.cfop;
        parsedData.csosn = data.csosn;
        // parsedData.cest = data?.cest || null;
        parsedData.icms_origem = data.icms_origem;
        parsedData.icms_aliquota = data.icms_aliquota;
        parsedData.cst = data.cst; //cst
        parsedData.pis_aliquota = data.pis_aliquota;
        parsedData.cofins_aliquota = data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          data.icms_modalidade_base_calculo;
        parsedData.cbenef = data.cbenef;

        if (data.cofins !== undefined) {
          parsedData.cofins = data.cofins;
        }

        if (data.pis !== undefined) {
          parsedData.pis = data.pis;
        }

        if (cstValue === '20' || cstValue === '40') {
          parsedData.icms_reducao_base_calculo = data?.icms_reducao_base_calculo?.replace(',', '.') || null;
          parsedData.icms_valor_desonerado = data?.icms_valor_desonerado?.replace(',', '.') || null;
          parsedData.icms_motivo_desoneracao = data?.icms_motivo_desoneracao || null;
        }

        if (cstValue === '60') {
          parsedData.icms_base_calculo_retido_st = data?.icms_base_calculo_retido_st?.replace(',', '.') || null;
          parsedData.icms_aliquota_final = data?.icms_aliquota_final?.replace(',', '.') || null;
          parsedData.icms_valor_substituto = data?.icms_valor_substituto?.replace(',', '.') || null;
          parsedData.icms_valor_retido_st = data?.icms_valor_retido_st?.replace(',', '.') || null;
        }
      }

      const response = await api.put(
        `restaurants/fiscal-groups/${group.id}`,
        parsedData
      );
      setGroups(response.data);

      toggleModalEdit();
      toast.success('Grupo fiscal criado com sucesso!');
    } catch (error) {
      toast.error('Falha ao criar grupo');
      console.log(error);
    }
  }

  const deleteGroup = useCallback(async () => {
    if (window.confirm('Tem certeza que deseja excluir esse grupo?')) {
      try {
        const response = await api.delete(
          `restaurants/fiscal-groups/${group.id}`
        );
        setGroups(response.data);
        toast.success('Grupo fiscal deletado com sucesso!');
      } catch (error) {
        toast.error('Falha ao deletar grupo');
        console.log(error);
      }
    }
  }, [group.id]);

  const motivoDesoneracaoOptions = [
    { value: null, label: 'Nenhum' },
    { value: '1', label: '1 - Táxi' },
    { value: '3', label: '2 - Produtor Agropecuário' },
    { value: '4', label: '4 - Frotista/Locadora' },
    { value: '5', label: '5 - Diplomático/Consular' },
    { value: '6', label: '6 - Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 - CONTRAN e suas alterações)' },
    { value: '7', label: '7 - SUFRAMA' },
    { value: '8', label: '8 - Venda a Órgão Público' },
    { value: '9', label: '9 - Outros' },
    { value: '10', label: '10 - Deficiente Condutor (Convênio ICMS 38/12)' },
    { value: '11', label: '11 - Deficiente Não Condutor (Convênio ICMS 38/12)' },
  ];

  useEffect(() => {
    console.log(
     'aaaaa', group.cst
    )
    setCstValue(group.cst);
  }, [group])

  return (
    <Container>
      <CategoryHeader>
        <CategoryName>
          <span style={{ fontSize: '22px' }}>{group?.title}</span>
        </CategoryName>

        <div>
          <MdCreate
            size={20}
            color="#333333"
            alt="Editar Categoria"
            title="Editar Categoria"
            onClick={() => {
              toggleModalEdit();
            }}
          />

          {group.type === 'manual' && (
            <Delete>
              <MdDelete
                size={20}
                title="Deletar Categoria"
                onClick={deleteGroup}
              />
            </Delete>
          )}
        </div>
      </CategoryHeader>

      <ButtonCollapse onClick={toggleCollapse}>
        Exibir / Esconder Grupo{' '}
        <MdExpandMore size={20} title="Mostrar Produtos" />
      </ButtonCollapse>

      <Collapse isOpen={isOpen}>
        {user.regime_tributario === '3' ? (
          <div>
            <GroupTable responsive borderless>
              <thead>
                <tr>
                  <th>CFOP</th>
                  <th>CSOSN</th>
                  <th>ICMS ORIGEM</th>
                  <th>ICMS ALIQ.</th>
                  <th>PIS</th>
                  <th>PIS ALIQ.</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{group.cfop}</td>
                  <td>{group.csosn}</td>
                  <td>{group.icms_origem}</td>
                  <td>{group.icms_aliquota && `${group.icms_aliquota}%`}</td>
                  <td>{group.pis}</td>
                  <td>{group.pis_aliquota && `${group.pis_aliquota}%`}</td>
                </tr>
              </tbody>
            </GroupTable>
            <GroupTable responsive borderless>
              <thead>
                <tr>
                  <th>COFINS</th>
                  <th>COFINS ALIQ.</th>
                  <th>CST</th>
                  <th>ICMS MOD. BC</th>
                  <th>CBENEF</th>
                  {/* <th>CEST</th> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{group.cofins}</td>
                  <td>
                    {group.cofins_aliquota && `${group.cofins_aliquota}%`}
                  </td>
                  <td>{group.cst}</td>
                  <td>{group.icms_modalidade_base_calculo}</td>
                  <td>{group.cbenef}</td>
                  {/* <td>{group.cest}</td> */}
                </tr>
              </tbody>
            </GroupTable>
          </div>
        ) : (
          <div>
            <GroupTable responsive borderless>
              <thead>
                <tr>
                  <th>CFOP</th>
                  <th>CSOSN</th>
                  <th>ICMS ORIGEM</th>
                  <th>PIS</th>
                  <th>COFINS</th>
                  <th>ICMS ALIQ.</th>
                  <th>ICMS MOD. BC</th>
                  {/* <th>CEST</th> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{group.cfop}</td>
                  <td>{group.csosn}</td>
                  <td>{group.icms_origem}</td>
                  <td>{group.pis}</td>
                  <td>{group.cofins}</td>
                  <td>{group.aliquota && `${group.aliquota}%`}</td>
                  <td>{group.base_calculo}</td>
                  {/* <td>{group.cest}</td> */}
                </tr>
              </tbody>
            </GroupTable>
          </div>
        )}
      </Collapse>

      <Modal isOpen={modalEdit} toggle={toggleModalEdit} size="xl">
        <ModalBody>
          <Form onSubmit={handleSubmitFiscal} initialData={group}>
            {user.regime_tributario === '1' ? (
              <div>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="12" style={{ marginTop: 15 }}>
                    <Input
                      name="title"
                      label="Nome do grupo"
                      disabled={group.type !== 'manual'}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="12">
                    <Select
                      name="icms_origem"
                      options={icms_origem_options}
                      defaultValue={icms_origem_options.filter(
                        (x) => x.value === group.icms_origem
                      )}
                      label="ICMS de Origem"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="csosn"
                      options={csosn_options}
                      defaultValue={csosn_options.filter(
                        (x) => x.value === group.csosn
                      )}
                      label="CSOSN"
                      onChange={(e) => setCsosnSelected(e.value)}
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Select
                      name="cfop"
                      options={cfopOptions}
                      defaultValue={cfopOptions.filter(
                        (x) => x.value === group.cfop
                      )}
                      label="CFOP"
                    />
                  </Col>
                </Row>

                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="cofins"
                      options={NFeInfo.cofins_options}
                      defaultValue={NFeInfo.cofins_options.filter(
                        (x) => x.value === group.cofins
                      )}
                      label="Cofins"
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Select
                      name="pis"
                      options={NFeInfo.cofins_options}
                      label="Pis"
                      defaultValue={NFeInfo.cofins_options.filter(
                        (x) => x.value === group.pis
                      )}
                    />
                  </Col>
                </Row>

                {/* {(csosnSelected === '500' || group.cest) && (
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <InputMask mask="9999999">
                        <Input label="CEST" name="cest" type="text" />
                      </InputMask>
                    </Col>
                  </Row>
                )} */}

                {(csosnSelected === '00' ||
                  group.aliquota ||
                  group.base_calculo) && (
                  <div>
                    <Row style={{ paddingTop: 20 }}>
                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota (Entre 0 e 100)"
                          name="aliquota"
                          type="text"
                          maxlength="3"
                        />
                      </Col>

                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="base_calculo"
                          options={base_calculo_options}
                          defaultValue={base_calculo_options.filter(
                            (x) => x.value === group.base_calculo
                          )}
                          label="Base Cálculo"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="12" style={{ marginTop: 15 }}>
                    <Input name="title" label="Nome do grupo" />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="icms_origem"
                      options={icms_origem_options}
                      defaultValue={icms_origem_options.filter(
                        (x) => x.value === group.icms_origem
                      )}
                      label="ICMS de Origem"
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Input
                      label="Alíquota ICMS (%)"
                      name="icms_aliquota"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="cfop"
                      options={cfopOptions}
                      defaultValue={cfopOptions.filter(
                        (x) => x.value === group.cfop
                      )}
                      label="CFOP"
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Select
                      name="cst"
                      options={NFeInfo.icms_situacao_tributaria}
                      defaultValue={NFeInfo.icms_situacao_tributaria.filter(
                        (icms) => icms.value === group.cst
                      )}
                      label="CST"
                      onChange={(e) => {
                        setCstValue(
                          e.value
                        );
                      }}
                    />
                  </Col>
                </Row>

                {/* <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="12">
                    <Select
                      name="csosn"
                      options={csosn_options}
                      label="CSOSN"
                      defaultValue={csosn_options.filter(
                        (x) => x.value === group.csosn
                      )}
                      onChange={(e) => setCsosnSelected(e.value)}
                    />
                  </Col>
                </Row> */}

                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="pis"
                      options={NFeInfo.pis_situacao_tributaria}
                      defaultValue={NFeInfo.pis_situacao_tributaria.filter(
                        (icms) => icms.value === group.pis
                      )}
                      label="PIS"
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Input
                      label="Alíquota PIS (%)"
                      name="pis_aliquota"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="cofins"
                      options={NFeInfo.pis_situacao_tributaria}
                      defaultValue={NFeInfo.pis_situacao_tributaria.filter(
                        (icms) => icms.value === group.cofins
                      )}
                      label="COFINS"
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Input
                      label="Alíquota COFINS (%)"
                      name="cofins_aliquota"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row style={{ paddingTop: 10 }}>
                  <Col sm="12" lg="6">
                    <Select
                      name="icms_modalidade_base_calculo"
                      options={NFeInfo.icms_modalidade_base_calculo}
                      label="Modalidade Base Calculo"
                      defaultValue={NFeInfo.icms_modalidade_base_calculo.filter(
                        (icms) =>
                          icms.value === group.icms_modalidade_base_calculo
                      )}
                    />
                  </Col>

                  <Col sm="12" lg="6">
                    <Input
                      label="Código de Benefícios Fiscais (cBenef)"
                      name="cbenef"
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  {/* {(['10', '30', '60', '70', '90'].includes(cstValue?.value) || group.cest) && (
                    <Col sm="4" lg="4">
                      <InputMask
                        mask="9999999"
                        // onChange={(e) => {
                        //   setCestValue(e.target.value);
                        // }}
                        // value={cestValue}
                      >
                        <Input label="CEST" name="cest" type="text" />
                      </InputMask>
                    </Col>
                  )} */}

                  {(cstValue === '20') && (
                    <>
                      <Col sm="3" lg="3">
                        <Input
                          label="Valor Desonerado ICMS"
                          name="icms_valor_desonerado"
                          type="text"
                          placeholder="R$"
                        />
                      </Col>
                      <Col sm="4" lg="4">
                        <Input
                          label="Percentual de redução da base cálculo"
                          name="icms_reducao_base_calculo"
                          type="text"
                          placeholder="%"
                        />
                      </Col>
                      <Col sm="5" lg="5">
                        <Select
                          name="icms_motivo_desoneracao"
                          options={motivoDesoneracaoOptions}
                          label="Motivo da desoneração do ICMS"
                          defaultValue={group.icms_motivo_desoneracao
                            ? motivoDesoneracaoOptions.find(mot => mot.value === group.icms_motivo_desoneracao)
                            : null}
                        />
                      </Col>
                    </>
                  )}

                  {
                    cstValue === '60' &&
                    (
                      <>
                        <Col sm="3" lg="3">
                          <Input label="Base de Cálculo ICMS Retido na operação anterior" name="icms_base_calculo_retido_st" type="text" />
                        </Col>
                        <Col sm="3" lg="3">
                          <Input label="Alíquota suportada pelo Consumidor Final" name="icms_aliquota_final" type="text" />
                        </Col>
                        <Col sm="3" lg="3">
                          <Input label="Valor do ICMS próprio do Substituto" name="icms_valor_substituto" type="text" />
                        </Col>
                        <Col sm="3" lg="3">
                          <Input label="Valor do ICMS ST Retido na operação anterior" name="icms_valor_retido_st" type="text" />
                        </Col>
                      </>
                    )
                  }

                  {(cstValue === '40') && (
                    <>
                      <Col sm="4" lg="4">
                        <Input
                          label="Valor Desonerado ICMS"
                          name="icms_valor_desonerado"
                          type="text"
                          placeholder="R$"
                        />
                      </Col>
                      <Col sm="4" lg="4">
                        <Select
                          name="icms_motivo_desoneracao"
                          options={motivoDesoneracaoOptions}
                          label="Motivo da desoneração do ICMS"
                          defaultValue={group.icms_motivo_desoneracao
                            ? motivoDesoneracaoOptions.find(mot => mot.value === group.icms_motivo_desoneracao)
                            : null}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              {/* 
                {(csosnSelected === '500' || group.cest) && (
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <InputMask mask="9999999">
                        <Input label="CEST" name="cest" type="text" />
                      </InputMask>
                    </Col>
                  </Row>
                )} */}


              </div>
            )}
            <Row>
              <Col md="8">
                <CheckboxInput
                  name="update_products"
                  label="Atualizar todos os produtos e complementos associados a esse grupo fiscal"
                  onChange={() => {
                    setUpdateProducts(!updateProducts)
                  }}
                  value={updateProducts}
                  checked={updateProducts}
                  spanStyle={{marginLeft: 10, fontWeight: 400, marginTop: 7}}
                />
              </Col>
              <Col md="4">
                <SaveButton
                  type="submit"
                  style={{ marginTop: 20, float: 'right' }}
                >
                  Salvar
                </SaveButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default FiscalGroupComponent;
