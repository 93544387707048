import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FaBookOpen,
  FaFileInvoiceDollar,
  FaList,
  FaMotorcycle,
  FaUserEdit,

} from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';

import { FiSettings, FiHelpCircle } from 'react-icons/fi';
import { MdRestaurantMenu, MdInfo, MdLight } from 'react-icons/md';
import clubLogo from '../../assets/img/club-button.svg';

import {
  Container,
  NavbarItem,
  Profile,
  StyledCollapse,
  LinkMenu,
  CarouselIndicatorsStyled,
  CarouselStyled,
  TooltipStyled,
} from './styles';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';
import slide1 from '~/assets/img/BANNER01.png'
import slide2 from '~/assets/img/BANNER02.png'
import slide3 from '~/assets/img/BANNER03.png'
import slide4 from '~/assets/img/BANNER04.png'
import slide5 from '~/assets/img/BANNER05.png'
import slide6 from '~/assets/img/BANNER06.png'
import slide7 from '~/assets/img/BANNER07.png'
import slide8 from '~/assets/img/BANNER08.png'
import slide9 from '~/assets/img/BANNER09.png'
import slide10 from '~/assets/img/BANNER10.png'
import { CarouselControl, CarouselItem, Modal, ModalFooter, Tooltip } from 'reactstrap';
import { BsFillLightbulbFill } from 'react-icons/bs';

function SideBar({ collapsed }) {
  const { user } = useAuth();
  const [clubToken, setClubToken] = useState(
    localStorage.getItem('@clube:token') || null
  );
  const [clubUser, setClubUser] = useState(
    JSON.parse(localStorage.getItem('@clube:user') || null)
  );

  const loginClub = async () => {
    const response = await api.get('/restaurants/club');

    if (response.data.token) {
      setClubToken(response.data.token);
      localStorage.setItem('@clube:token', response.data.token);
    }

    if (response.data.user) {
      setClubUser(response.data.user);
    }

    if (response.data.token && response.data.user) {
      window.open(
        `https://dashboard.takeat.club/reports?token=${response.data.token}`,
        '_blank'
      );
    }
  };

  //modal carousel
  const [updateUiModalOpened, setUpdateUiModalOpened] = useState(false)

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  function toggleUpdateUiModal() {
    setUpdateUiModalOpened(!updateUiModalOpened)
  }

  const items = [
    {
      src: slide1,
    },
    {
      src: slide2,
    },
    {
      src: slide3,
    },
    {
      src: slide4,
    },
    {
      src: slide5,
    },
    {
      src: slide6,
    },
    {
      src: slide7,
    },
    {
      src: slide8,
    },
    {
      src: slide9,
    },
    {
      src: slide10,
    },

  ];

  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  // Carousel Item Data
  const carouselItemData = items.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        active={index === activeIndex}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Container>
      {/* cle */}
      <Modal
        isOpen={updateUiModalOpened}
        toggle={toggleUpdateUiModal}
        onClosed={() => setActiveIndex(0)}

      >
        <CarouselStyled
          activeIndex={activeIndex}
          next={nextButton}
          previous={previousButton}
          interval={20000}
        >

          {carouselItemData}

          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previousButton}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={nextButton}
          />
        </CarouselStyled>
        <CarouselIndicatorsStyled
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        <ModalFooter>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>

            <button
              className="btn"
              type="button"
              onClick={() => {
                toggleUpdateUiModal();

              }}
              style={{ background: '#2ec9b7' }}
            >
              Fechar
            </button>

          </div>


        </ModalFooter>
      </Modal>
      <NavbarItem collapsed={collapsed}>

        <Profile>
          <TooltipStyled
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
            target="TooltipExample"
            placement="right"
          >
            Veja as novas Mudanças de layout que virão!
          </TooltipStyled>
          <BsFillLightbulbFill id='TooltipExample' onClick={toggleUpdateUiModal} color='#ff2c3a' cursor="pointer" size={21} style={{ position: "absolute", height: 40, left: 20 }} />
          {user?.avatar ? (
            <img src={user.avatar.url} alt={user.fantasy_name} />
          ) : (
            <img
              src="https://api.adorable.io/avatars/128/abott@adorable.png"
              alt="Usuário sem foto"
            />
          )}

          <div>
            <span>{user.fantasy_name}</span>
            <Link to="/profile">Meu Restaurante</Link>
          </div>
        </Profile>

        <ul>
          {!user.only_delivery && (
            <li>
              <button type="button" id="collapse0">
                <MdRestaurantMenu />
                <div>
                  <span style={{ fontWeight: 500 }}>Operação</span>
                  <div />
                </div>
              </button>
              <StyledCollapse toggler="#collapse0">
                <Link to="/operation">
                  Acompanhar Pedidos e Solicitações de Fechamento
                </Link>
                <Link to="/bills">Acompanhar Comandas</Link>
                <Link to="/kds">KDS</Link>
                <Link to="/transfers">Histórico de Transferências</Link>
              </StyledCollapse>
            </li>
          )}

          {user?.delivery_info?.is_delivery_allowed && (
            <li>
              <button type="button" id="collapse1">
                <FaMotorcycle />
                <div>
                  <span style={{ fontWeight: 500 }}>Delivery</span>
                  <div />
                </div>
              </button>
              <StyledCollapse toggler="#collapse1">
                <Link to="/delivery/orders">Acompanhar Pedidos</Link>
                <Link to="/delivery/motoboys">Motoboys</Link>
                <Link to="/delivery/coupons">Cupons</Link>
                <Link to="/delivery/addresses">Endereços </Link>
                <Link to="/delivery/bills">Acompanhar Comandas </Link>
                <Link to="/delivery/opening-hours">
                  Horário de funcionamento{' '}
                </Link>
                <Link to="/delivery/scheduling">Agendamento </Link>
                <Link to="/delivery/config">Configurações </Link>
              </StyledCollapse>
            </li>
          )}

          <li>
            <button type="button" id="collapse3">
              <FaBookOpen />
              <div>
                <span style={{ fontWeight: 500 }}>Cardápio</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse3">
              <Link to="/products">Seus produtos</Link>

              <Link to="/products/inputs">Estoque e insumos</Link>

              {user.has_ifood && <Link to="/products/ifood">iFood</Link>}
              {user.has_nfce && <Link to="/products/fiscal">Fiscal</Link>}

            </StyledCollapse>
          </li>
          <li>
            <button type="button" id="collapse2">
              <FaUserEdit />
              <div>
                <span style={{ fontWeight: 500 }}>Cadastros</span>

                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse2">
              <Link to="/users/profile">Alterar meu perfil</Link>
              <Link to="/users">Gerenciar Usuários</Link>
              <Link to="/waiters">Gerenciar Garçons</Link>
              {/* <Link to="/bills/close-solicitation">Fechamento de Comanda</Link> */}
              {/* <Link to="/tables/view">Acompanhar Mesas</Link> */}
              <Link to="/tables">Gerenciar Mesas</Link>
              {(user.has_stone_pos || user.has_stone_pdv) && (
                <Link to="/stone-devices">Gerenciar POS</Link>
              )}
            </StyledCollapse>
          </li>

          {/* <li>
            <button type="button" id="collapse4">
              <FaUser />

              <div>
                <span>Usuários</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse4">
              <Link to="/users/profile">Alterar meu perfil</Link>
              <Link to="/users">Gerenciar Usuários</Link>
            </StyledCollapse>
          </li> */}
          {/* <li>
            <button type="button" id="collapse5">
              <FaUserMinus />

              <div>
                <span>Garçons</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse5">
              <Link to="/waiters">Gerenciar Garçons</Link>
            </StyledCollapse>
          </li> */}
          {/* <li>
            <button type="button" id="collapse6">
              <FaCashRegister />
              <div>
                <span>Caixa</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse6">
              <Link to="/opening-box">Abertura de Caixa</Link>
              <Link to="/box">Controle de Caixa</Link>
              <Link to="/box/payment">Formas de Pagamento</Link>
            </StyledCollapse>
          </li> */}
          <li>
            <button type="button" id="collapse7">
              <FaFileInvoiceDollar size={24} />
              <div>
                <span style={{ fontWeight: 500 }}>Financeiro</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse7">
              <Link to="/opening-box">Abertura de Caixa</Link>
              <Link to="/box">Controle de Caixa</Link>

              <Link to="/financial/audit-box">Conferência de Caixa</Link>

              <Link to="/box/payment">Métodos de Recebimento</Link>
              <Link to="/financial/cost-center">Centro de Custo</Link>
              <Link to="/financial/banks">Conciliação bancária</Link>
              <Link to="/financial/suppliers">Fornecedores</Link>
              {/* <Link to="/financial/payments-methods">Métodos de Pagamento</Link> */}
              <Link to="/financial/transactions">Lançamentos</Link>
              <Link to="/financial/dre">DRE</Link>
              <Link to="/financial/dfc">DFC</Link>
              <Link to="/financial/invoices">Boletos</Link>
            </StyledCollapse>
          </li>
          {user.has_nfce && (
            <li>
              <button type="button" id="collapse8">
                <FaList size={24} />
                <div>
                  <span style={{ fontWeight: 500 }}>Fiscal</span>
                  <div />
                </div>
              </button>
              <StyledCollapse toggler="#collapse8">
                <Link to="/nfe-manifest">Manifesto de Notas</Link>
                <Link to="/nfe-entry">Entrada de Notas</Link>
                <Link to="/nfe-devolucao">Notas Devolvidas</Link>
                <Link to="/nfce">Notas Emitidas</Link>
                <Link to="/fiscal-groups">Grupos Fiscais</Link>
                {user.is_distribution_center && (
                  <Link to="/transfer-inputs">Transferência de Estoque</Link>
                )}
              </StyledCollapse>
            </li>
          )}
          <li>
            <button type="button" id="collapse9">
              <GoGraph />
              <div>
                <span style={{ fontWeight: 500 }}>Relatórios</span>
                <div />
              </div>
            </button>
            <StyledCollapse toggler="#collapse9">
              <Link to="/reports">Página Principal</Link>
              <Link to="/reports/invoicing">Faturamento</Link>
              <Link to="/reports/products">Produtos</Link>
              <Link to="/reports/service-tax">Taxa de serviço</Link>
              <Link to="/reports/expenses">Custos</Link>
              <Link to="/reports/orders-canceled">Produtos Cancelados</Link>
              <Link to="/reports/discount">Descontos</Link>
              <Link to="/reports/rate">Avaliações</Link>
              <Link to="/reports/clients">Clientes</Link>
              <Link to="/tasks">Tarefas</Link>
            </StyledCollapse>
          </li>
          <li>
            <LinkMenu to="/settings">
              <FiSettings />
              <div>
                <span style={{ fontWeight: 500 }}>Configurações</span>
              </div>
            </LinkMenu>
          </li>
          <li>
            <LinkMenu to="/help">
              <FiHelpCircle />
              <div>
                <span style={{ fontWeight: 500 }}>Ajuda</span>
              </div>
            </LinkMenu>
          </li>
          {user.has_clube && (
            <li
              onClick={() => {
                if (clubToken && clubUser) {
                  window.open(
                    `https://dashboard.takeat.club/redirect/${clubToken}`,
                    'NewClubTab'
                  );
                } else {
                  loginClub();
                }
              }}
            >
              <LinkMenu>
                <img
                  src={clubLogo}
                  style={{ width: 28, color: '#ff403d', marginLeft: 11 }}
                  color="red"
                />
                <div>
                  <span style={{ fontWeight: 500 }}>Takeat Clube</span>
                </div>
              </LinkMenu>
            </li>
          )}
        </ul>
      </NavbarItem>
    </Container>
  );
}

export default SideBar;
