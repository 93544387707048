import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';
import { AiOutlineEye } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { addHours, isBefore, format } from 'date-fns';

import PrintNFCeHistory from '~/components/Prints/PrintNFCeHistory';
import Input from '~/components/Form/Input';

import {
  Actions,
  ActionsInside,
  DeleteAndEditActions,
  CloseButton,
  SeeButton,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';

import api from '~/services/api';

export default function NfeDevRow({ nfe, handleUpdate }) {
  const [modalHtml, setModalHtml] = useState(false);
  function toggleHtml() {
    if (modalHtml) {
      setHtml(null);
    }
    setModalHtml(!modalHtml);
  }

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  const [modalEmail, setModalEmail] = useState(false);
  function toggleEmail() {
    setModalEmail(!modalEmail);
  }

  const componentRef = useRef(React.createRef);
  const expireDate = addHours(new Date(nfe.createdAt), 24);
  const isAllowCancel = isBefore(new Date(), expireDate);

  function printClick() {
    window.open(
      nfe.url,
      'janela',
      'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
    );
  }
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const sentEmail = useCallback(async (data) => {
    setLoadingEmail(true);

    try {
      await api.post('/restaurants/nfe-received/single/send-email', {
        id: nfe.id,
        email: data.email,
      });
      toast.success('E-mail enviado com sucesso!');
    } catch (err) {
      toast.error('Erro ao enviar E-mail.');
    }

    setLoadingEmail(false);
    document.getElementById('close_email_modal').click();
  }, []);

  const cancelDevolucao = useCallback(async (data) => {
    if (
      window.confirm('Tem certeza que deseja cancelar essa nota de devolução?')
    ) {
      setLoadingCancel(true);

      try {
        await api.delete(`/restaurants/cancel-devolucao-nfe/${nfe.id}`);

        handleUpdate();
        toast.success('Nota cancelada com sucesso!');
      } catch (err) {
        toast.error('Erro ao cancelar nota.');
      }

      setLoadingCancel(false);
    }
  }, []);

  const [html, setHtml] = useState(null);
  const [nfeReq, setNfeReq] = useState(null);

  function formatAsDateTime(date) {
    if (date) {
      return format(new Date(date + 'T12:00-03:00'), 'dd/MM/yyyy');
    }

    return format(new Date(0), 'dd/MM/yyyy');
  }

  return (
    <>
      <tr>
        <td>{nfe?.nome_emitente}</td>
        <td>{formatCompleteDate(nfe?.data_emissao)}</td>
        <td>R${nfe?.valor_total}</td>
        <td>{nfe?.documento_emitente}</td>
        <td>
          {' '}
          <Actions>
            <ActionsInside>
              <DeleteAndEditActions>
                {nfe?.status !== 'canceled' && (
                  <>
                    {/* <SeeButton
                      onClick={handleSeeNfePdf}
                      style={{ background: '#C87800' }}
                    >
                      <div>
                        <FaFileInvoiceDollar
                          size={13}
                          style={{ marginRight: 3 }}
                        />
                        DANFe
                      </div>
                    </SeeButton> */}
                    {/* <SeeButton
                      onClick={printClick}
                      style={{ background: '#113C51' }}
                    >
                      <div>
                        <BiMailSend size={16} style={{ marginRight: 3 }} />
                        Enviar por E-mail
                      </div>
                    </SeeButton> */}
                  </>
                )}
                <SeeButton onClick={() => printClick()}>
                  <div>
                    <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                    Abrir nota
                  </div>
                </SeeButton>
                {nfe?.status !== 'canceled' && isAllowCancel && (
                  <CloseButton
                    onClick={cancelDevolucao}
                    disabled={loadingCancel}
                  >
                    <div>
                      <TiCancel size={17} style={{ marginRight: 3 }} />
                      {loadingCancel ? 'Aguarde..' : 'Cancelar'}
                    </div>
                  </CloseButton>
                )}
              </DeleteAndEditActions>
            </ActionsInside>
          </Actions>
        </td>
      </tr>

      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Chave:</strong> #{nfe?.chave_nfe}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Status:</strong> {nfe?.situacao}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Criada em:</strong>{' '}
              {formatCompleteDate(nfe?.data_emissao)}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Manifestação:</strong> {nfe?.manifestacao_destinatario}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Valor:</strong> R${nfe?.valor_total}
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <Form initialData={nfeReq}>
            {!nfeReq && (
              <Row>
                <Col>
                  <h5 style={{ color: 'red' }}>
                    <strong>
                      Informações da nota ainda não processada pela Sefaz. Tente
                      novamente mais tarde.
                    </strong>
                  </h5>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
              >
                <h5>Dados do Fornecedor:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_fantasia_emitente"
                  label="Fornecedor"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="6">
                <Input
                  name="nome_emitente"
                  label="Razão Social"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input name="cnpj_emitente" label="CNPJ" type="text" disabled />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados da Nota:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="valor_total"
                  label="Valor Total (R$)"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="2">
                <Input
                  name="data_emissao"
                  label="Data Emissão"
                  value={format(
                    new Date(nfeReq?.data_emissao || null),
                    'dd/MM/yyyy'
                  )}
                  type="text"
                  disabled
                />
              </Col>
              <Col md="8">
                <Input
                  name="chave_nfe"
                  label="Chave NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input name="serie" label="Série" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="numero" label="Número" type="text" disabled />
              </Col>
              <Col md="2">
                <Input name="modelo" label="Modelo" type="text" disabled />
              </Col>
              <Col md="3">
                <Input
                  name="codigo_unico"
                  label="Código Único"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input name="versao" label="Versão" type="text" disabled />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Duplicatas:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.duplicatas && nfeReq?.duplicatas.length > 0 ? (
            nfeReq?.duplicatas.map((dup) => (
              <Form initialData={dup}>
                <Row>
                  <Col md="4">
                    <Input name="numero" label="Número" type="text" disabled />
                  </Col>
                  <Col md="4">
                    <Input
                      name="data_vencimento"
                      label="Data Vencimento"
                      value={formatAsDateTime(dup.data_vencimento)}
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      name="valor"
                      label="Valor (R$)"
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
          )}

          <Form initialData={nfeReq}>
            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Totais da Nota :</h5>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_produtos"
                  label="Valor dos Produtos"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo"
                  label="Base de Cálculo do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_valor_total"
                  label="Valor do ICMS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="icms_base_calculo_st"
                  label="Base de Cálc. do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="icms_valor_total_st"
                  label="Valor Total do ICMS ST"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_pis"
                  label="Valor do PIS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_cofins"
                  label="Valor do COFINS"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_ipi"
                  label="Valor do IPI"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="valor_frete"
                  label="Valor Total do Frete"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_outras_despesas"
                  label="Valor de Outras Despesas"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_desconto"
                  label="Valor Total de Desconto"
                  type="text"
                  disabled
                />
              </Col>
              <Col md="3">
                <Input
                  name="valor_total"
                  label="Valor Total da NFe"
                  type="text"
                  disabled
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <h5>Produtos:</h5>
            </Col>
          </Row>
          {nfeReq && nfeReq?.itens && nfeReq?.itens.length > 0 ? (
            nfeReq?.itens.map((item) => (
              <Form
                initialData={item}
                style={{ paddingTop: 15, borderBottom: '1px solid #EBEBEB' }}
              >
                <Row>
                  <Col>
                    <h5>Produto #{item.numero_item}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input
                      name="codigo_produto"
                      label="Cód. Forn."
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="6">
                    <Input
                      name="descricao"
                      label="Produto"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="quantidade_comercial"
                      label="Qnt. Nota"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_comercial"
                      label="Unidade"
                      type="text"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Input
                      name="valor_unitario_comercial"
                      label="Valor de Custo"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_bruto"
                      label="Valor Total"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col md="2">
                    <Input name="codigo_ncm" label="NCM" type="text" disabled />
                  </Col>
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem duplicatas.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={() => toggle()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalHtml} toggle={toggleHtml}>
        <ModalBody style={{ padding: 20 }}>
          <PrintNFCeHistory html={html} ref={componentRef} />
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <SeeButton>
                <div>
                  <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                  Imprimir Html
                </div>
              </SeeButton>
            )}
            content={() => componentRef.current}
            onAfterPrint={() => toggleHtml()}
          />
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEmail} toggle={toggleEmail}>
        <Form onSubmit={sentEmail}>
          <ModalBody>
            <h6>Enviar Nota Fiscal por E-mail</h6>
            <Row>
              <Col sm="12" lg="12">
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  placeholder="exemplo@gmail.com"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" style={{ background: 'green' }}>
              {loadingEmail ? (
                <Spinner
                  color="light"
                  style={{ width: '1em', height: '1em' }}
                />
              ) : (
                'Enviar'
              )}
            </Button>
            <Button
              onClick={toggleEmail}
              id="close_email_modal"
              style={{ background: '#dc3545' }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
