import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import getValidationErrors from '../../services/getValidationErrors';
import { Wrapper, Header, Card, Content } from './styles';
import api from '~/services/api';
import AvatarInput from '~/components/Form/AvatarInput';
import Input from '~/components/Form/Input';
import TextareaContainer from '~/components/Form/Textarea';
import CheckboxInput from '~/components/Form/Checkbox';
import SaveButton from '~/components/Buttons/SaveButton';

import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';

import { FaQuestionCircle } from 'react-icons/fa';


function Profile() {
  const formRef = useRef(null);
  const { user, updateUser } = useAuth();
  const [oldAvatarId, setOldAvatarId] = useState(0);

  const initialData = {
    name: user.name,
    fantasy_name: user.fantasy_name,
    email: user.email,
    phone: user.phone,
    adress_id: user.adress_id,
    has_service_tax: user.has_service_tax,
    service_tax: user.service_tax,
    ask_waiter_key: user.ask_waiter_key,
    instagram: user.instagram,
    audit_cashier: user.audit_cashier,
    order_cancel_password: user.order_cancel_password,
    order_transfer_password: user.order_transfer_password,
    greeting_message: user.greeting_message,
    financial_email: user.financial_email,
    use_cashier_by_user: user.use_cashier_by_user,
    default_cashier_user_id: user.default_cashier_user_id,
    can_cashier_verify_sessions: user.can_cashier_verify_sessions,
    avatar: {
      id: user.avatar ? user.avatar.id : null,
      url: user.avatar ? user.avatar.url : null,
    },
    adress: user.adress
      ? {
        id: user.adress?.id,
        street: user.adress?.street,
        number: user.adress?.number,
        complement: user.adress?.complement,
        state: user.adress?.state,
        city: user.adress?.city,
        zip_code: user.adress?.zip_code,
        neighborhood: user.adress?.neighborhood,
        inscricao_estadual: user.adress?.inscricao_estadual,
      }
      : null,
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  async function handleSubmitProfile(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        fantasy_name: Yup.string().required('Nome de exibição obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const insta = data.instagram.replace('@', '');

      const updateProfile = {
        name: data.name,
        fantasy_name: data.fantasy_name,
        phone: data.phone,
        avatar_id: data.avatar,
        adress_id: user.adress?.id,
        has_service_tax: data.has_service_tax,
        service_tax: data.service_tax,
        instagram: insta,
        audit_cashier: data.audit_cashier,
        order_cancel_password: data.order_cancel_password,
        order_transfer_password: data.order_transfer_password,
        table_type: data.table_type,
        ask_waiter_key: data.ask_waiter_key,
        greeting_message: data.greeting_message,
        financial_email: data.financial_email,
        use_cashier_by_user: data.use_cashier_by_user,
        default_cashier_user_id: data.default_cashier_user_id,
        can_cashier_verify_sessions: data.can_cashier_verify_sessions,
      };

      if (
        data.adress.street.length > 0 &&
        data.adress.number !== null &&
        data.adress.state.length > 0 &&
        data.adress.city.length > 0 &&
        data.adress.zip_code.length > 0 &&
        data.adress.neighborhood.length > 0
      ) {
        updateProfile.adress = {
          id: user.adress?.id,
          street: data.adress?.street,
          neighborhood: data.adress?.neighborhood,
          number: Number(data.adress?.number || 0),
          complement: data.adress?.complement || '',
          state: data.adress?.state,
          city: data.adress?.city,
          zip_code: data.adress?.zip_code,
          inscricao_estadual: data.adress?.inscricao_estadual,
        };
      }

      updateUser(updateProfile);

      if (oldAvatarId !== null && oldAvatarId !== Number(data.avatar)) {
        await api.delete(`restaurants/files/${oldAvatarId}`);
      }

      // toast.success('Perfil do restaurante atualizado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        // console.tron.log(error);
        toast.error('Falha ao atualizar o perfil do restaurante.');
      }
    }
  }

  // submit de mudar senha
  async function handleSubmitChangePassword(data) {
    try {
      await api.put('restaurants/', {
        email: data.email,
        password: data.password,
        oldPassword: data.oldPassword,
        confirmPassword: data.confirmPassword,
      });
      toast.success('Senha atualizada com sucesso!');
    } catch (err) {
      toast.error('Falha ao alterar senha.');
    }
  }

  useEffect(() => {
    setOldAvatarId(user.avatar ? user.avatar.id : null);
  }, [user]);

  const [users, setUsers] = useState([]);

  const getUsers = useCallback(async () => {
    const response = await api.get('/restaurants/users');

    const usersFiltered = response.data.map(user => {
      return {
        label: user.name,
        value: user.id
      }
    })
    setUsers(usersFiltered);

  }, [user]);

  useEffect(() => {
    try {
      getUsers();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getUsers]);

  const [cashierByUser, setCashierByUser] = useState(user?.use_cashier_by_user)

  return (
    <Wrapper>

      <Header>
        <div>
          <h2>Perfil do Restaurante</h2>
        </div>
      </Header>
      <Card>
        <Form
          initialData={initialData}
          onSubmit={handleSubmitProfile}
          ref={formRef}
        >
          <Content>
            <Row>
              <Col sm="12">
                <AvatarInput name="avatar" title="Inserir/ Alterar Foto" />
                <p
                  style={{
                    textAlign: 'center',
                    color: '#33333390',
                    fontWeight: '500',
                    marginTop: '-20px',
                    marginBottom: '30px',
                  }}
                >
                  (FORMATO JPEG-PNG-JPG-GIF / DIMENSÃO MÁXIMA ARQUIVO: 3Mb /
                  RESOLUÇÃO SUGERIDA: 800px x 800px)
                </p>
              </Col>
            </Row>

            <h3>Dados</h3>

            <Row>
              <Col sm="12" md="3">
                <Input
                  disabled
                  label="Nome do restaurante"
                  name="name"
                  type="text"
                />
              </Col>
              <Col sm="12" md="3">
                <Input
                  label="Nome de exibição"
                  name="fantasy_name"
                  type="text"
                />
              </Col>
              <Col md="12" lg="3">
                <InputMask mask="(99) 99999-9999">
                  <Input
                    label="Telefone"
                    name="phone"
                    type="text"
                    maskChar=""
                    placeholder="(xx) xxxxx-xxxx"
                  />
                </InputMask>
              </Col>
              <Col sm="12" md="3">
                {user.user_role === 'Administrador' && (
                  <Input
                    label="Email financeiro"
                    name="financial_email"
                    type="email"
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col md="4" lg="4">
                {user.user_role === 'Administrador' && (
                  <Input
                    label="Senha de transferência de itens/ mesas:"
                    name="order_transfer_password"
                    type="password"
                  />
                )}
              </Col>
              <Col md="4" lg="4">
                {user.user_role === 'Administrador' && (
                  <>
                    <label style={{ marginTop: 15 }}>
                      <strong>
                        Senha administrativa:
                      </strong>
                      <FaQuestionCircle
                        color="orange"
                        title="Senha para cancelamento e reativação de comandas"
                        size={15}
                        style={{ marginLeft: 5, marginTop: 0 }}
                      />
                    </label>
                    <Input
                      name="order_cancel_password"
                      type="password"
                    />
                  </>
                )}
              </Col>

              <Col md="4" lg="4">
                <Input
                  label="Instagram:"
                  name="instagram"
                  type="text"
                  maskChar=""
                  placeholder="Sem o @"
                />
              </Col>
            </Row>
            <Row>
              <Col md="4" lg="4">
                <Row>
                  <Input
                    label="Valor da Taxa de Serviço (%)"
                    name="service_tax"
                    type="number"
                  />
                </Row>
              </Col>

              <Col md="4">
                <Row>

                  {user.user_protected && (
                    <>
                      <span style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                        <CheckboxInput
                          label="Vincular o caixa ao usuário"
                          name="use_cashier_by_user"
                          onChange={() => setCashierByUser(!cashierByUser)}

                        />
                        <FaQuestionCircle
                          color="orange"
                          title="Operação com mais de um caixa."
                          size={15}
                          style={{ marginLeft: 5, marginTop: 0 }}
                        />
                      </span>

                      {cashierByUser && (
                        <Select name="default_cashier_user_id" options={users} placeholder="Caixa a receber pagamentos online" defaultValue={{
                          value: user?.default_user?.id || -1,
                          label: user?.default_user?.name || "Caixa a receber pagamentos online",
                        }} />
                      )}
                    </>
                  )}
                </Row>
              </Col>

              <Col md="4">
                <TextareaContainer
                  name="greeting_message"
                  label="Informação para tela de boas vindas"
                  type="text"
                  maxLength="100"
                />
              </Col>
            </Row>
            <Row>
              <CheckboxInput
                label="Taxa de serviço"
                name="has_service_tax"
              />
              <CheckboxInput
                label="Senha do garçom ao abrir mesas no PDV"
                name="ask_waiter_key"
              />
              {user.user_role === 'Administrador' && (
                <CheckboxInput
                  label="Caixa às cegas"
                  name="audit_cashier"
                />
              )}

              {user.user_role === 'Administrador' && (
                <CheckboxInput
                  label="Impedir fechamento de caixa se houverem comandas fechadas sem pagamento integral informado."
                  name="can_cashier_verify_sessions"
                />
              )}
            </Row>
          </Content>
          <Content>
            <h3>Endereço</h3>

            <Row>
              <Col sm="12" lg="10">
                <Input label="Lougradouro" name="adress.street" type="text" />
              </Col>
              <Col sm="12" lg="2">
                <Input label="Nº" name="adress.number" type="number" />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="5">
                <Input label="Bairro" name="adress.neighborhood" type="text" />
              </Col>
              <Col sm="12" lg="4">
                <Input
                  label="Complemento"
                  name="adress.complement"
                  type="text"
                />
              </Col>
              <Col sm="12" lg="3">
                <InputMask mask="99.999-999">
                  <Input label="CEP" name="adress.zip_code" type="text" />
                </InputMask>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="7">
                <Input label="Cidade" name="adress.city" type="text" />
              </Col>
              <Col sm="12" lg="2">
                <InputMask mask="aa">
                  <Input label="Estado" name="adress.state" type="text" />
                </InputMask>
              </Col>
              <Col sm="12" lg="3">
                <Input label="Inscrição Estadual" name="adress.inscricao_estadual" type="text" />
              </Col>
            </Row>
            <SaveButton type="submit" />
          </Content>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggle}>
        <Form initialData={initialData} onSubmit={handleSubmitChangePassword}>
          <ModalHeader toggle={toggle}>Alterar Senha</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col lg="6">
                  <Input label="E-mail" name="email" type="text" />
                </Col>
                <Col lg="6">
                  <Input
                    label="Senha atual"
                    name="oldPassword"
                    type="Password"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Input label="Nova Senha" name="password" type="password" />
                </Col>
                <Col lg="6">
                  <Input
                    label="Confirmar Nova Senha"
                    name="confirmPassword"
                    type="Password"
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={toggle}>
              Salvar
            </Button>{' '}
            <Button color="danger" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Wrapper>
  );
}

export default Profile;
