import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Row, Col, Button, Modal, ModalBody, Collapse } from 'reactstrap';
import { Form } from '@unform/web';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import CheckboxInput from '~/components/Form/Checkbox';

import {
  Container,
  Header,
  Card,
  HelpDiv
} from './styles';

import image1_1 from '../../assets/img/help/1.1.png';
import image1_2 from '../../assets/img/help/1.2.png';
import image1_3 from '../../assets/img/help/1.3.png';
import image2_1 from '../../assets/img/help/2.1.png';
import image3_1 from '../../assets/img/help/3.1.jpg';
import image3_2 from '../../assets/img/help/3.2.jpg';
import image4_1 from '../../assets/img/help/4.1.jpg';
import image5_1 from '../../assets/img/help/5.1.jpg';
import image5_2 from '../../assets/img/help/5.2.jpg';
import image6_1 from '../../assets/img/help/6.1.jpg';
import image7_1 from '../../assets/img/help/7.1.jpg';
import image8_1 from '../../assets/img/help/8.1.jpg';
import image9_1 from '../../assets/img/help/9.1.png';
import image9_2 from '../../assets/img/help/9.2.png';
import image9_3 from '../../assets/img/help/9.3.png';

import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import FiscalGroupComponent from '~/components/Fiscal/FiscalGroupComponent';
import RegisterButton from '~/components/Buttons/RegisterButton';

function Help() {
  const [arrayHelp, setArrayHelp] = useState([]);
  // let arrayHelp = [];

  const handleCollapse = (id) => {
    if (arrayHelp.includes(id)) {
      const newCollapse = arrayHelp.filter((c) => c !== id);
      setArrayHelp(newCollapse);
    } else {
      setArrayHelp((state) => [...state, id]);
    }
  };

  return (
    <Container>
      <Header>
        <div>
          <h2>Ajuda</h2>
        </div>

      </Header>
      {/* {groups.map((group) => (
        <FiscalGroupComponent group={group} user={user} setGroups={setGroups} />
      ))} */}
      <Card>
        <Row style={{cursor: 'pointer'}} onClick={e => handleCollapse(1)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(1) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong> 1 - Minha impressão automática não está saindo, o que faço?</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(1)}>
          <HelpDiv>
            <p>Primeiramente você deve checar se o driver de impressão automática está em execução, clique na setinha próximo a hora e observe se o ícone rosa da Takeat está na bandeja de tarefas em segundo plano, conforme a imagem a seguir.</p>
            <div style={{textAlign: 'center'}}>
              <img src={image1_1} alt="Ícone rosa da Takeat na bandeja de tarefas" style={{align: 'center'}}/>
            </div>

            <p>Caso esteja, clique duas vezes e observe quando ele abrir se as suas impressoras estão listadas dentro dele, conforme a imagem a seguir.</p>
            <div style={{textAlign: 'center'}}>
              <img src={image1_2} alt="Impressoras listadas no driver de impressão automática"/>
            </div>

            <p>Caso as impressoras não estejam listadas, você deve clicar no botão SAIR e executar novamente o driver de impressão automática, que vai estar na sua área de trabalho do computador com o nome ORDER SHEET PRINTER (ou Impressão Takeat).</p>
            <p>Caso não haja um login dentro do driver ele vai aparecer na sua tela conforme a imagem a seguir. O email e senha de qualquer usuário do seu estabelecimento pode ser utilizado para fazer o login no driver.</p>
            <div style={{textAlign: 'center'}}>
              <img src={image1_3} alt="Tela de login do driver de impressão automática"/>
            </div>
            <p>Se o login já estiver feito, o driver vai entrar em execução em segundo plano e ficar na bandeja de tarefas conforme a primeira imagem.</p>
          </HelpDiv>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(2)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(2) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>2 - Não consigo lançar nem receber pedidos, o que aconteceu?</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(2)}>
          <div style={{padding: 15}}>
            <p>Primeiramente, você deve verificar o erro que está ocorrendo quando há a tentativa de lançamento de produto ou recebimento de pedido enquanto o restaurante está fechado. Nesse caso, será exibida uma notificação informando sobre isso, e o restaurante deve ser aberto para que seja possível realizar a operação.</p>
            <div style={{textAlign: 'center'}}>
              <img src={image2_1} alt="2.1" style={{align: 'center'}}/>
            </div>

            <p>Ao clicar no botão identificado pela seta 1 na imagem acima, será exibido um painel com os módulos de venda disponíveis para o seu restaurante. Você pode clicar no módulo desejado para ativá-lo e começar a receber pedidos na modalidade escolhida.</p>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(3)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(3) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>3 - Qual meu link de pedidos? Ou de visualização do cardápio?</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(3)}>
          <div style={{padding: 15}}>
            <p>O link para pedidos de delivery está localizado dentro do próprio painel de delivery, logo acima das colunas de etapas do pedido. Você pode usar o botão ao lado do link para copiá-lo instantaneamente e colá-lo em uma conversa com um cliente ou em qualquer outro lugar que desejar, conforme mostra a imagem a seguir.</p>
            
            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image3_1} alt="3.1" style={{align: 'center', width: '90%'}}/>
            </div>

            <p>O link de visualização exclusiva do cardápio está na página de operação e deve ser utilizado apenas nos casos em que você não deseja permitir que o cliente faça pedidos. Ele permite que o cliente visualize todos os produtos disponíveis no cardápio, promoções de dias específicos, entre outros. O botão indicado pela imagem abaixo contém dois links, cada um com a descrição do que fazem.</p>

            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image3_2} alt="3.2" style={{align: 'center', width: '90%'}}/>
            </div>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(4)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(4) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>4 - Não consigo emitir cupom/nota fiscal</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(4)}>
          <div style={{padding: 15}}>
            <p>Quando ocorre uma tentativa de emissão fiscal e a SEFAZ (Secretaria da Fazenda) rejeita, o sistema retorna com o erro informado. A partir dessa informação, é necessário fazer as adequações necessárias de acordo com o erro. Comumente, erros relacionados às informações fiscais dos produtos são recebidos. Portanto, as informações fiscais dos produtos mencionados no erro devem ser atualizadas de acordo com os padrões contábeis necessários.</p>

            <p>Dentro do produto (em CARDÁPIO &gt; PRODUTOS &gt; EDITAR PRODUTO), é possível para o lojista escolher entre os Grupos Fiscais, que fornecem as informações fiscais basicamente completas. É necessário apenas inserir manualmente o NCM (Nomenclatura Comum do Mercosul), que deve ser buscado com a ajuda de um contador ou nos resultados adequados encontrados no próprio Google.</p>
            <div style={{textAlign: 'center', width: '94%'}}>
              <img src={image4_1} alt="4.1" style={{align: 'center', width: '94%'}}/>
            </div>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(5)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(5) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>5 - Meu produto não aparece no cardápio pro cliente</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(5)}>
          <div style={{padding: 15}}>
            <p>Quando um produto está cadastrado no seu sistema, mas não aparece para o cliente, a única coisa que pode estar causando isso são as configurações da categoria ou a disponibilidade do produto.</p>

            <p>Você deve começar verificando as configurações e a disponibilidade da categoria. Verifique se a categoria está habilitada para o dia da semana em questão e se está dentro do horário definido nas configurações. Verifique também se a função de uso exclusivo (que faz a categoria aparecer apenas no painel do lojista e do garçom) está habilitada. Se o problema não for solucionado e compreendido com as configurações da categoria, verifique a disponibilidade do produto.</p>
            <div style={{textAlign: 'center', width: '95%'}}>
              <img src={image5_1} alt="5.1" style={{align: 'center', width: '95%'}}/>
            </div>
            <p>A verificação da disponibilidade do produto é feita conforme mostrado na imagem a seguir. Observe e clique na chave caso deseje alterar o status atual do produto para a modalidade de venda desejada.</p>
            <div style={{textAlign: 'center', width: '95%'}}>
              <img src={image5_2} alt="5.2" style={{align: 'center', width: '95%'}}/>
            </div>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(6)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(6) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>6 - Meu cliente não consegue pedir no Delivery, dá erro ao fazer pedido</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(6)}>
          <div style={{padding: 15}}>
            <ol>
                <li>O lojista deve confirmar se cadastrou os endereços/regiões em que faz entrega. Caso não haja nada cadastrado, o cliente não conseguirá fazer os pedidos. Esse cadastro deverá ser feito dentro de DELIVERY > ENDEREÇOS. <a href="https://www.youtube.com/watch?v=4kQVE0b8sd8&list=PLzE6n2kKmJw5OH3q5Sz29ygIRP9MAhj85" target="_blank">Clique aqui</a> para assistir ao vídeo que ensina a fazer o cadastro.</li>
                
                <div style={{textAlign: 'center', width: '90%'}}>
                  <img src={image6_1} alt="6.1" style={{align: 'center', width: '90%'}}/>
                </div>

                <li>Nem o cliente nem o lojista podem inserir letras no campo de NÚMERO do endereço de entrega. Caso ocorra, um erro será gerado ao fazer o pedido.</li>
                <li>Se o cliente abriu o cardápio antes da loja estar aberta, ele deve sair do cardápio e entrar novamente. Assim, a página será recarregada e o pedido poderá ser feito, conforme mencionado na resposta anterior.</li>
            </ol>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(7)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(7) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>7 - Meu estoque não está abatendo, há algum erro?</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(7)}>
          <div style={{padding: 15}}>
            <p>O estoque tem uma rotina de atualização automática às 3h da madrugada. No entanto, caso necessite que a atualização ocorra no momento em que estiver visualizando o estoque, você pode usar o botão "ATUALIZAR" em destaque na imagem a seguir.</p>

            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image7_1} alt="7.1" style={{align: 'center', width: '90%'}}/>
            </div>

            <p>Se, mesmo assim, as deduções não estiverem ocorrendo, você deve verificar se a ficha técnica dos produtos, dos quais você está querendo ver a dedução no estoque, estão devidamente preenchidas. Caso precise de auxílio no preenchimento das fichas técnicas, confira o vídeo a seguir: <a href="https://www.youtube.com/watch?v=wJ8OMgaITDg&list=PLzE6n2kKmJw7i09LQiq2CsU14vwUfpN0z" target="_blank">Estoque e Insumos</a>.</p>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(8)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(8) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>8 - Não consigo cancelar ou transferir itens</strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(8)}>
          <div style={{padding: 15}}>
            <p>Existe uma senha administrativa do sistema que todo usuário com acesso administrativo consegue visualizar no campo dentro do menu "MEU RESTAURANTE". Essa senha pode ser alterada e salva conforme a preferência de um usuário administrativo, e será usada para os processos de cancelamento e descontos.</p>
            <p>A senha de transferência também deve ser definida no seu respectivo campo, localizado dentro do mesmo menu. Você pode encontrar a imagem ilustrativa a seguir:</p>
            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image8_1} alt="8.1" style={{align: 'center', width: '90%'}}/>
            </div>
          </div>
        </Collapse>

        <div style={{borderBottom: '1px solid #E1E3DE', width: '97%', marginTop: 10}}/>

        <Row style={{cursor: 'pointer', marginTop: 15}} onClick={e => handleCollapse(9)}>
          <Col>
            <h4 style={{marginBottom: 0}}>
              {arrayHelp.includes(9) ? <MdExpandLess size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>  : <MdExpandMore size={20} title="Mostrar Resposta" style={{marginRight: 5}}/>}
              <strong>9 - Como utilizar mais de um caixa? </strong>
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={arrayHelp.includes(9)}>
          <div style={{padding: 15}}>
            <p>Agora é possível ativar a função de caixa por usuário. Essa função permite que todo usuário que possuir acesso ao caixa possa abrir um caixa. Para verificar/cadastrar os usuários, basta ir em <strong>Cadastros > Gerenciar usuários</strong>.</p>

            <p><strong><em>Obs:</em> Apenas usuário com nível administrativo pode ativar essa funcionalidade.</strong></p>

            <p>Para ativar essa função, acesse <em>menu > meu restaurante</em> e identifique “Vincular o caixa ao usuário”.</p>
            
            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image9_1} alt="9.1" style={{align: 'center', width: '90%'}}/>
            </div>

            <p>Ao marcar a caixa, você deve escolher quem será responsável por receber os pagamentos online:</p>
            
            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image9_2} alt="9.2" style={{align: 'center', width: '90%'}}/>
            </div>

            <p>Pronto! Agora os usuários operacionais conseguirão abrir caixas independentes, e o usuário administrador poderá acompanhar todos caixas abertos no restaurante e também individualmente.</p>
          
            <div style={{textAlign: 'center', width: '90%'}}>
              <img src={image9_3} alt="9.3" style={{align: 'center', width: '90%'}}/>
            </div>
          </div>
        </Collapse>

      </Card>
    </Container>
  );
}

export default Help;
