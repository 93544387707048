import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: 90%;
  min-width: 400px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));

  border-radius: 8px;
  padding: 0 30px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const CheckboxInfo = styled.div`
  text-align: left;
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const Content = styled.div`
  margin: 0px auto;
  width: 95%;
  padding: 10px;
`;

export const AddressesTable = styled(Table)`
  width: 90%;
  min-width: 400px;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  float: right;
  // position: absolute;
  // clear: both;
  right: 0;
  // margin-right: 5px;
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  align-self: center;
  margin-top: auto;
  flex-direction: column;
  // height: 30px;
  margin: auto;
`;

export const ActionButton = styled(Link)`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #dc3545;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 98%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const UserAddress = styled.div``;

export const DeliveryDistanceTax = styled.div`
  margin-top: 40px;
`;

export const PersonalTable = styled.table`
  margin-left: 10px;

  td {
    padding: 10px;
    text-align: left;

    input {
      width: 80px;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const AreaButton = styled.div`
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
`;
