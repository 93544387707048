import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap';
import { Wrapper, Header, Card, StyledForm, Container } from './styles';

import api from '~/services/api';
import getValidationErrorrs from '~/services/getValidationErrors';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { Button, Checkbox } from 'ui-kit-takeat';
import { FaCheck } from 'react-icons/fa';

function StorePayment({ toggle, getCustomPaymentMethods }) {
  const formRef = useRef(null);
  const [banks, setBanks] = useState();
  const { user } = useAuth();
  const [checkboxValues, setCheckboxValues] = useState({
    is_exclusive: false,
    delivery_accepts: true,
    available: true,
    include_weekends: false,
  });

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        interval: Yup.string().required('Prazo obrigatório'),
        service_tax: Yup.string().required('A taxa é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('restaurants/custom-payment-methods', {
        name: data.name,
        available: checkboxValues.available,
        delivery_accepts: checkboxValues.delivery_accepts,
        interval: data.interval,
        service_tax: data.service_tax,
        include_weekends: !checkboxValues.include_weekends,
        bank_account_id: data.bank_account_id,
        method: data.method || null,
        is_exclusive: checkboxValues.is_exclusive,
      });

      toast.success('Forma de pagamento cadastrada com sucesso!');

      toggle();
      getCustomPaymentMethods();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrorrs(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao cadastrar forma de pagamento! ${error.message}`);
      }
      // console.tron.log(error);
    }
  }

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksOptions = response.data.map((bank) => {
        return {
          label: bank.name,
          value: bank.id,
        };
      });

      setBanks(banksOptions);
    } catch (error) {
      toast.error('Erro ao baixar contas');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  const methodOptions = [
    { value: null, label: 'Nenhum' },
    { value: 'CREDIT', label: 'Crédito' },
    { value: 'DEBIT', label: 'Débito' },
  ];

  return (
    <Wrapper>
      <Header>
        <h3 align="center">Cadastrar forma de pagamento</h3>
      </Header>
      <Container>
        <Card>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col lg="7">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Forma de pagamento"
                />
              </Col>
              <Col lg="5">
                <Input
                  label="Taxa de transação %"
                  name="service_tax"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder="Taxa %"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="7">
                <Select
                  name="bank_account_id"
                  label="Conta de recebimento"
                  options={banks}
                  placeholder="Selecione a conta"
                />
              </Col>
              <Col lg="5" style={{ whiteSpace: 'nowrap' }}>
                <Input
                  label="Prazo de recebimento"
                  name="interval"
                  type="number"
                  step="1"
                  placeholder="Dias"
                  min="0"
                />
              </Col>
            </Row>

            {user.has_stone_pdv && (
              <Row>
                <Col lg="12">
                  <Select
                    name="method"
                    label="Método de Pagamento (POS)"
                    options={methodOptions}
                    placeholder="Selecione o método que vai cobrar na POS"
                    defaultValue={methodOptions[0]}
                  />
                </Col>
              </Row>
            )}

            <Checkbox
              label="Contar só dias úteis para recebimento"
              checked={checkboxValues.include_weekends}
              clickFunction={(e) =>
                setCheckboxValues({ ...checkboxValues, include_weekends: e })
              }
            />
            {!user.only_delivery && (
              <Checkbox
                label="Disponível no PDV"
                checked={checkboxValues.available}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, available: e })
                }
              />
            )}

            {user.delivery_info?.is_delivery_allowed && (
              <Checkbox
                label="Disponível no Delivery"
                checked={checkboxValues.delivery_accepts}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, delivery_accepts: e })
                }
              />
            )}

            <div style={{ position: 'relative' }}>
              <Checkbox
                label="Uso exclusivo"
                checked={checkboxValues.is_exclusive}
                clickFunction={(e) =>
                  setCheckboxValues({ ...checkboxValues, is_exclusive: e })
                }
              />
              <span
                style={{
                  color: '#6c6c6c',
                  fontSize: '12px',
                  position: 'absolute',
                  top: 20,
                  left: 29,
                }}
              >
                Esse método não será exibido para o cliente, apenas no PDV
              </span>
            </div>
            <Button
              marginTop={15}
              title="Cadastrar"
              icon={<FaCheck />}
              type="default"
              float="right"
              onClick={() => {}}
            />
            {/* <SaveButton type="submit" style={{ float: 'right' }}>
              Cadastrar
            </SaveButton> */}
          </StyledForm>
        </Card>
      </Container>
    </Wrapper>
  );
}

export default StorePayment;
