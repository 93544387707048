import styled, {css} from 'styled-components';
// import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const FiscalMessage = styled.div`
  background: #d3d3d3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  height: 119px;
  padding: 10px;
  p {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const NavDivTeste = styled.div`
  float: right;
`;

export const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForm = styled(Form)``;

export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding-right: 25px;
  padding-left: 10px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;

export const Button = styled.button`
  background-color: #2ec9b7;
  width: 70px;
  height: 48px;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const Times = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  input {
    width: 100px;
    border: 0px;
    border-bottom: 1px solid #33333330;
    border-radius: 0px;
    margin-left: 3px;
    margin-right: 3px;
    transition: all 1s;
    text-align: center;

    :focus {
      border: none;
      border-bottom: 1px solid red;
      border-radius: 0px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #d3d3d3;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #d3d3d3;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #d3d3d3;
    }
  }
`;
