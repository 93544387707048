import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { MdDelete } from 'react-icons/md';

import ReactSelect from 'react-select';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { FaQuestionCircle } from 'react-icons/fa';
import ReactInputMask from 'react-input-mask';
import {
  Container,
  Header,
  ComplementsTable,
  ErrorContainer,
  Delete,
  SearchDiv,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  InputsTable,
  DeleteFilterButton,
  InventaryButton,
  StyledTable,
} from './styles';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import RegisterButton from '~/components/Buttons/RegisterButton';
import api from '~/services/api';
import InputRow from '~/components/Rows/InputRow';
import IntermediaryRow from '~/components/Rows/IntermediaryRow';
import UpdateButton from '~/components/Buttons/UpdateButton';

import TextareaContainer from '~/components/Form/Textarea';
import getValidationErrors from '~/services/getValidationErrors';
import CheckboxInput from '~/components/Form/Checkbox';

import InputLossReport from '~/components/InputLossReport';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { generateInputsReportSheetXLSX } from '~/services/generateInputsReportSheet';
import FileSaver from 'file-saver';
import { DownloadButton } from 'ui-kit-takeat';
import { generateIntermediariesReportSheetXLSX } from '~/services/generateIntermediariesReportSheet';

function Inputs() {
  const { user, getProfileData } = useAuth();
  const [inputs, setInputs] = useState([]);
  const [intermediary, setIntermediary] = useState([]);
  const [inputsFiltered, setInputsFiltered] = useState([]);
  const [intermediaryFiltered, setIntermediaryFiltered] = useState([]);
  const [permission, setPermission] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [unidadeSelected, setUnidadeSelected] = useState('Kg');
  const [nameIntermediary, setNameIntermediary] = useState('');
  const [yeldIntermediary, setYeldIntermediary] = useState(1);
  const [filterString, setFilterString] = useState('');
  const [filterStringInter, setFilterStringInter] = useState('');
  const [isInventory, setIsInventory] = useState(false);
  const [inventory, setInventory] = useState({});
  const [isInterInventory, setIsInterInventory] = useState(false);
  const [interInventory, setInterInventory] = useState({});
  const [createCashFlowOnNewInput, setCreateCashFlowOnNewInput] = useState(
    true
  );
  const [filterInputMaster, setFilterInputMaster] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();

  function handleCreateCashFlowOnNewInput() {
    if (createCashFlowOnNewInput === true) {
      setCreateCashFlowOnNewInput(false);
    } else {
      setCreateCashFlowOnNewInput(true);
    }
  }

  const [categories, setCategories] = useState();
  const [cashFlowCategoryInputs, setCashFlowCategoryInputs] = useState({
    value: '',
    label: 'Categoria',
  });

  const formIntermediaryRef = useRef(null);
  const formRef = useRef(null);
  const formRefBank = useRef(null);
  const formRefSupplier = useRef(null);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'MenuInventory'}`
      );

      const { can_read } = response.data.MenuInventory;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  const getInputs = useCallback(async (filterString) => {
    try {
      const response = await api.get('/restaurants/inputs');
      setInputs(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  const getIntermediary = useCallback(async (filterStringInter) => {
    try {
      const response = await api.get('/restaurants/intermediary');
      setIntermediary(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados intermediarios');
    }
  }, []);

  const getCategoriesDetails = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });

      setCategories(categoriesSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  const [suppliers, setSuppliers] = useState();
  const [banks, setBanks] = useState();

  const getSuppliers = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/providers');

      const providersSelect = response?.data?.map((provider) => {
        return {
          value: provider.id,
          label: provider.name,
        };
      });

      setSuppliers(providersSelect);
    } catch (error) {
      toast.error('Erro ao baixar fornecedores');
    }
  }, []);


  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      const banksSelect = response?.data?.map((bank) => {
        return {
          value: bank.id,
          label: bank.name,
        };
      });

      setBanks(banksSelect);
    } catch (error) {
      toast.error('Erro ao baixar contas de banco');
    }
  }, []);


  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/payment-methods');

      const methodsSelect = response?.data?.map((method) => {
        return {
          value: method.id,
          label: method.name,
        };
      });

      setPaymentMethods(methodsSelect);
    } catch (error) {
      toast.error('Erro ao baixar métodos de pagamento');
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getSuppliers();
  }, [getSuppliers]);


  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    validateUser();
    getInputs(filterString);
    getIntermediary(filterStringInter);
    getCategoriesDetails();
  }, [getInputs, validateUser, getIntermediary, getCategoriesDetails]);

  const [modal, setModal] = useState(false);
  const [modalErroNotAllowed, setModalErroNotAllowed] = useState(false);

  const toggle = () => {
    if (user.allow_inputs) {
      setModal(!modal);
      setCreateCashFlowOnNewInput(true);
    } else {
      setModalErroNotAllowed(!modalErroNotAllowed);
    }
  };

  const [modalIntermediary, setModalIntermediary] = useState(false);

  const toggleIntermediary = () => {
    if (user.allow_inputs) {
      setModalIntermediary(!modalIntermediary);
    } else {
      setModalErroNotAllowed(!modalErroNotAllowed);
    }
  }

  const handleDelete = useCallback(async (id) => {
    try {
      if (window.confirm('Tem certeza que deseja deletar este insumo?')) {
        const response = await api.delete(`/restaurants/inputs/${id}`);
        setInputs(response.data);

        toast.success('Insumo deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar insumo.');
    }
  }, []);

  const handleDeleteIntermediary = useCallback(
    async (id, filterStringInter) => {
      try {
        if (
          window.confirm(
            'Tem certeza que deseja deletar este produto intermediario?'
          )
        ) {
          const response = await api.delete(`/restaurants/intermediary/${id}`);
          setIntermediary(response.data);

          toast.success('Produto Intermediario deletado com sucesso!');
        }
      } catch (error) {
        toast.error('Erro ao deletar produto intermediario.');
      }
    },
    []
  );

  const [paidCheck, setPaidCheck] = useState(false);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);
  }

  const handleInput = useCallback(
    async (data) => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        if (data.total_to_send && parseFloat(data.total_to_send) > 0) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
            category: Yup.string().required('Centro de custo obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            unidade: Yup.string().required('Unidade Obrigatória'),
            quantidade: Yup.string().required('Quantidade Obrigatória'),
            total_to_send: Yup.number().min(0, 'Não pode ser negativo'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const new_date = `${data.due_date}T03:00:00.000Z`;
        const new_paid = `${data.paid_at}T03:00:00.000Z`;

        const handleData = {
          name: data.name,
          quantidade: data.quantidade.replace(',', '.'),
          unidade: data.unidade,
          total: parseFloat(data.total_to_send.replace(',', '.')),
          cash_flow_category_subcategory_id: data.category || null,
          minimum_stock: data.minimum_stock,
          ideal_stock: data.ideal_stock,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id || null,
          due_date: data.due_date ? new_date : null,
          paid: data.paid,
          paid_at: data.paid ? new_paid : null,
          create_cash_flow: data.create_cashflow,
          cash_flow_payment_method_id: data.cash_flow_payment_method_id
        };

        const response = await api.post(`/restaurants/inputs`, handleData);
        setInputs(response.data);

        toast.success('Insumo adiconado com sucesso!');
        setModal(false);
        setCreateCashFlowOnNewInput(true);

        setPaidCheck(!paidCheck);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          toast.error('Erro ao adicionar insumo.');
        }
      }
    },
    [paidCheck]
  );

  const unidade_options = [
    {
      value: 'un',
      label: 'Unidade',
    },
    {
      value: 'kg',
      label: 'Kg',
    },
    {
      value: 'l',
      label: 'Litros',
    },
    {
      value: 'mL',
      label: 'mL',
    },
    {
      value: 'PCT',
      label: 'Pacote',
    },
  ];

  const inputs_select = inputs
    .filter((inp) => !inp.is_master)
    .map((input) => ({
      value: input.id,
      label: input.name,
      unidade: input.unidade,
      unitary_price: input.unitary_price,
      quantidade: input.quantidade,
    }));

  const inter_select = intermediary.map((inter) => ({
    value: inter.id,
    label: inter.name,
    unidade: inter.unidade,
    unitary_price: inter.unitary_price,
    quantidade: inter.quantidade,
  }));

  const [interInputs, setInterInputs] = useState([]);
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [selectInput, setSelectInput] = useState([]);
  const [quantInter, setQuantInter] = useState(0);

  const [interInter, setInterInter] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);

  function insertInputInter(selectInput, defaultInterValue) {
    const exists = interInputs.filter((int) => int.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      setInterInputs([
        ...interInputs,
        {
          name: selectInput.label,
          id: selectInput.value,
          quantidade_inter_value: defaultInterValue.replace(',', '.'),
          unidade: selectInput.unidade,
          unitary_price: selectInput.unitary_price,
          quantidade: selectInput.quantidade,
        },
      ]);
    }
  }

  function deleteInputInter(id) {
    const updated = interInputs.filter((int) => int.id !== id);
    setInterInputs(updated);
  }

  function insertInterInter(selectInter, defaultInterValue) {
    const exists = interInter.filter((int) => int.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      setInterInter([
        ...interInter,
        {
          name: selectInter.label,
          id: selectInter.value,
          quantidade_inter_value: defaultInterValue.replace(',', '.'),
          unidade: selectInter.unidade,
          unitary_price: selectInter.unitary_price,
          quantidade: selectInter.quantidade,
        },
      ]);
    }
  }

  function deleteInterInter(id) {
    const updated = interInter.filter((int) => int.id !== id);
    setInterInter(updated);
  }

  const [discountInputsOnHandleInter, setDiscountInputsOnHandleInter] = useState(true);

  async function handleIntermediary(data) {
    try {
      if (formIntermediaryRef.current) {
        formIntermediaryRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        unidade: Yup.string().required('Unidade Obrigatória'),
        quantidade_inter: Yup.string().required('Quantidade Obrigatória'),
        yield: Yup.string().required('Valor Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const inputs = interInputs.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));
      const intermediaries = interInter.map((inter) => ({
        id: inter.id,
        quantidade: inter.quantidade_inter_value,
      }));

      const handleData = {
        name: data.name,
        quantidade: quantInter.toString().replace(',', '.'),
        unidade: data.unidade,
        recipe: data.recipe,
        yield: data.yield,
        minimum_stock: data.minimum_stock,
        ideal_stock: data.ideal_stock,
        inputs,
        intermediaries,
        discount_inputs: discountInputsOnHandleInter
      };

      const response = await api.post(`/restaurants/intermediary`, handleData);
      setIntermediary(response.data);
      setInterInputs([]);
      setInterInter([]);
      getInputs(filterString);
      toggleIntermediary();
      toast.success('Produto intermediario adiconado com sucesso!');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formIntermediaryRef.current) {
          formIntermediaryRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao adicionar produto intermediário.');
      }
    }
  }

  useEffect(
    function filterInputs() {
      let filtered = inputsFiltered.length > 0 ? inputsFiltered : inputs; //alterar para inputs

      if (filterString !== '') {
        filtered = filtered.filter((inter) =>
          inter.name.toLowerCase().includes(filterString.toLowerCase())
        );
      }

      if (filterInputMaster) {
        filtered = filtered.filter(filter => filter.is_master);
      }

      if (cashFlowCategoryInputs.value) {
        const { value } = cashFlowCategoryInputs;

        filtered = filtered.filter(
          (input) => input.cash_flow_category_subcategory_id === value
        );
      }

      setInputsFiltered(filtered);
    },
    [inputs, filterString, cashFlowCategoryInputs, filterInputMaster]
  );

  function clearInputFilters() {
    setFilterString('');
    setCashFlowCategoryInputs({
      value: '',
      label: 'Categoria',
    });
  }

  const handleInventory = async () => {
    try {
      if (isInventory) {
        const response = await api.post(
          `/restaurants/inputs/inventory`,
          inventory
        );

        // setInputs(
        //   inputs.map((inp) => {
        //     const match = response.data.find((r) => r.id === inp.id);
        //     if (match) {
        //       return { ...inp, ...match };
        //     }
        //     return inp;
        //   })
        // );
      }

      setIsInventory(!isInventory);
      setInventory({});

      try {
        const response = await api.get('/restaurants/inputs');
        setInputs(response.data);
        setInputsFiltered([]);
        setInputsFiltered(response.data);
      } catch (err) {
        toast.error('Falha ao carregar dados');
      }
      // getInputs();
    } catch (err) {
      toast.error('Algo deu errado');
    }
  };

  const handleInterInventory = async () => {
    try {
      if (isInterInventory) {
        const response = await api.post(
          `/restaurants/intermediaries/inventory`,
          interInventory
        );

        setIntermediary(
          intermediary.map((inter) => {
            const match = response.data.find((r) => r.id === inter.id);
            if (match) {
              return { ...inter, ...match };
            }
            return inter;
          })
        );
      }
      setIsInterInventory(!isInterInventory);
      setInterInventory({});
    } catch (err) {
      toast.error('Algo deu errado');
    }
  };

  useEffect(
    function filterIntermediaries() {
      let filtered = intermediary;

      if (filterStringInter !== '') {
        filtered = filtered.filter((inter) =>
          inter.name.toLowerCase().startsWith(filterStringInter.toLowerCase())
        );
      }

      setIntermediaryFiltered(filtered);
    },
    [intermediary, filterStringInter]
  );

  useEffect(() => {
    if (filterInputMaster) {
      const newFiltered = inputs.filter(input => input.is_master);
      setInputsFiltered(newFiltered);
    } else {
      setInputsFiltered(inputs);
    }
  }, [filterInputMaster]);

  // create banks

  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const handleBankSubmit = useCallback(
    async (data) => {
      try {
        if (formRefBank?.current) {
          formRefBank.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefBank?.current) {
            formRefBank.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  // create suplliers

  const [isModalCreateSuppliersOpen, setIsModalCreatesuppliersOpen] = useState(
    false
  );

  const toggleModalCreateSuplliers = useCallback(() => {
    setIsModalCreatesuppliersOpen(!isModalCreateSuppliersOpen);
  }, [isModalCreateSuppliersOpen]);

  const handleSupplierSubmit = useCallback(
    async (data) => {
      try {
        if (formRefSupplier?.current) {
          formRefSupplier.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ/ CPF obrigatório')
            .trim()
            .matches('^[0-9]+$', 'Apenas números, sem espaço'),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/providers', {
          name: data.name,
          phone: data.phone,
          cnpj: data.cnpj,
        });

        toast.success('Fornecedor cadastrada com sucesso!');

        getSuppliers();
        toggleModalCreateSuplliers();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRefSupplier?.current) {
            formRefSupplier.current.setErrors(errors);
          }
        } else {
          toast.error(`Erro ao cadastrar fornecedor!`);
        }
      }
    },
    [getSuppliers, toggleModalCreateSuplliers]
  );

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const handleDownloadInputs = async () => {
    try {
      const sheet = await generateInputsReportSheetXLSX(inputs);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Insumos(${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const handleDownloadInters = async () => {
    try {
      const sheet = await generateIntermediariesReportSheetXLSX(
        intermediaryFiltered
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Intermediários(${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateInputs = useCallback(async () => {
    try {
      setLoadingUpdate(true);
      await api.post('/restaurants/inputs/update-restaurant');
      // getInputs(filterString);
      // getIntermediary(filterStringInter);
      // getProfileData();
      window.location.reload()
      toast.success('Estoque atualizado!');
    } catch (error) {
      toast.error('Erro ao atualizar estoque. Tente novamente.');
    }
    setLoadingUpdate(false);
  }, []);

  function searchStrings(array, search, isOne) {
    const splited = search.split(' ');
    let lastFiltered = null;
    let filtered = null;

    if (splited.length > 0) {
      filtered = array.filter(arr => arr.name.toLowerCase().includes(splited[0].toLowerCase()));
    }

    if (splited.length > 1) {
      filtered = array.filter(arr => arr.name.toLowerCase().includes(splited[0].toLowerCase()));
      lastFiltered = filtered;

      if (filtered.length === 0) {
        filtered = lastFiltered;
      }

      if (filtered.length > 1) {
        filtered = filtered.filter(arr => arr.name.toLowerCase().includes(splited[1].toLowerCase()));
        if (filtered.length === 0) {
          filtered = lastFiltered;
        }
      }
    }

    if (splited.length > 2) {
      filtered = array.filter(arr => arr?.name?.toLowerCase().includes(splited[0].toLowerCase()));
      lastFiltered = filtered;

      if (filtered.length > 1) {
        filtered = filtered.filter(arr => arr?.name?.toLowerCase().includes(splited[1].toLowerCase()));

        if (filtered.length === 0) {
          filtered = lastFiltered;
        }

        if (filtered.length > 1) {
          filtered = filtered.filter(arr => arr?.name?.toLowerCase().includes(splited[2].toLowerCase()));

          if (filtered.length === 0) {
            filtered = lastFiltered;
          }
        } else if (filtered.length === 0) {
          filtered = lastFiltered;
        }
      } else if (filtered.length === 0) {
        filtered = lastFiltered;
      }
    }

    if (filtered?.length > 0 && isOne) {
      filtered = filtered[0];
    }

    return filtered;
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex' }}>
            <h2>Estoque</h2>
            <VideoPlayer id={2} />
          </div>
          {user.use_stock && (
            <>
              <p>
                Gerencie os insumos e o estoque do seu restaurante. O estoque é{' '}
                <strong>
                  atualizado automaticamente uma vez ao dia, às 03:00.
                </strong>{' '}
                Caso precise atualizar manualmente, clique em ”Atualizar”
              </p>
              <p>
                <strong>Última atualização: </strong>
                {user.last_input_update &&
                  formatCompleteDate(new Date(user.last_input_update))}
              </p>
              <div
                style={{
                  width: 'fit-content',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                <UpdateButton
                  onClick={() => {
                    updateInputs();
                  }}
                  color="#3BD2C1"
                  id="update_restaurant_button"
                  text={loadingUpdate ? 'ATUALIZANDO...' : 'ATUALIZAR'}
                  disabled={loadingUpdate}
                />
              </div>
            </>
          )}
        </div>
        {user.use_stock && (
          <RegisterButton
            onClick={() => {
              activeTab === '1' ? toggle() : toggleIntermediary();
            }}
          >
            Adicionar
          </RegisterButton>
        )}
      </Header>

      {user.use_stock ? (
        <>
          <NavDiv>
            <NavDivTeste>
              <Nav tabs borderless="true">
                <NavItem>
                  <NavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => setActiveTab('1')}
                    style={{ cursor: 'pointer', color: '#333' }}
                  >
                    <NavLinkSpan>Insumos</NavLinkSpan>{' '}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={activeTab === '2' ? 'active' : ''}
                    onClick={() => {
                      setActiveTab('2');
                    }}
                    style={{ cursor: 'pointer', color: '#333' }}
                  >
                    <NavLinkSpan>Produtos Intermediários</NavLinkSpan>{' '}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={activeTab === '3' ? 'active' : ''}
                    onClick={() => {
                      setActiveTab('3');
                    }}
                    style={{ cursor: 'pointer', color: '#333' }}
                  >
                    <NavLinkSpan>Perdas</NavLinkSpan>{' '}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={activeTab === '4' ? 'active' : ''}
                    onClick={() => {
                      setActiveTab('4');
                    }}
                    style={{ cursor: 'pointer', color: '#333' }}
                  >
                    <NavLinkSpan>Sobras</NavLinkSpan>{' '}
                  </NavLink>
                </NavItem>
              </Nav>
            </NavDivTeste>

            {activeTab === '1' && (
              <SearchDiv>
                <Form onSubmit={clearInputFilters}>
                  <Row
                    style={{
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      marginTop: -16,
                    }}
                  >
                    <Col md="5">
                      <Input
                        style={{
                          'border-radius': '4px',
                          height: '38px',
                          marginLeft: '-2%',
                        }}
                        name="filter"
                        placeholder="Pesquise por aqui"
                        type="text"
                        id="filterString"
                        onChange={(e) => {
                          setFilterString(e.target.value);
                        }}
                        value={filterString}
                      />
                    </Col>
                    <Col md="5">
                      <ReactSelect
                        placeholder="Categoria"
                        options={categories}
                        onChange={(item) => {
                          setCashFlowCategoryInputs(item);
                        }}
                        value={cashFlowCategoryInputs}
                      />
                    </Col>
                    <Col md="2">
                      <DeleteFilterButton> Limpar </DeleteFilterButton>
                    </Col>
                  </Row>
                </Form>
              </SearchDiv>
            )}
            {activeTab === '2' && (
              <SearchDiv
                style={{
                  marginTop: -16,
                }}
              >
                <Form>
                  <Input
                    style={{
                      'border-radius': '4px',
                      height: '38px',
                    }}
                    name="filter"
                    placeholder="Pesquise por aqui"
                    type="text"
                    onChange={(e) => {
                      setFilterStringInter(e.target.value);
                    }}
                  />
                </Form>
              </SearchDiv>
            )}
          </NavDiv>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ComplementsTable borderless>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 10,
                  }}
                >
                  <InventaryButton onClick={() => handleInventory()}>
                    {isInventory
                      ? 'Salvar inventário'
                      : '+ Realizar inventário'}
                  </InventaryButton>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                    }}
                  >
                    <DownloadButton
                      clickFunction={() => handleDownloadInputs()}
                    />
                    <span>
                      <strong>Insumo total em estoque:</strong> R${' '}
                      {inputsFiltered &&
                        inputsFiltered
                          .reduce((accum, curr) => {
                            return (
                              accum +
                              Number(
                                isNaN(curr.total_value) || curr.total_value < 0
                                  ? 0
                                  : curr.total_value
                              )
                            );
                          }, 0)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: 10,
                  }}
                >
                  <label>
                    <Form>
                      <CheckboxInput
                        name="filter_input_master"
                        label="Insumos associados a produtos"
                        onChange={() => {
                          setFilterInputMaster(!filterInputMaster)
                        }}
                        value={filterInputMaster}
                        spanStyle={{ marginLeft: 10, fontWeight: 400, marginTop: 7 }}
                      />
                    </Form>
                  </label>
                  <FaQuestionCircle
                    color="orange"
                    title="Se houver controle de quantidade do produto, será criado um insumo vinculado a ele, e qualquer alteração no produto é refletida no insumo associado."
                    size={15}
                    style={{ marginLeft: 5, marginTop: 9 }}
                  />
                </div>

                <StyledTable borderless>
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Quantidade em Estoque</th>
                      <th>Custo Unitário</th>
                      <th>Valor em Estoque</th>
                      <th>Centro de custo</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputsFiltered.map((input) => (
                      <InputRow
                        key={input.id}
                        input={input}
                        setInputs={setInputs}
                        handleDelete={handleDelete}
                        categories={categories}
                        filterString={filterString}
                        banks={banks}
                        suppliers={suppliers}
                        getBanks={getBanks}
                        getSuppliers={getSuppliers}
                        isInventory={isInventory}
                        inventory={inventory}
                        setInventory={setInventory}
                        getInputs={getInputs}
                        inputsFiltered={inputsFiltered}
                        setInputsFiltered={setInputsFiltered}
                        paymentMethods={paymentMethods}
                      />
                    ))}
                  </tbody>
                </StyledTable>
              </ComplementsTable>
            </TabPane>

            <TabPane tabId="2">
              <ComplementsTable borderless>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 10,
                  }}
                >
                  <InventaryButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleInterInventory();
                    }}
                  >
                    {isInterInventory
                      ? 'Salvar inventário'
                      : '+ Realizar inventário'}
                  </InventaryButton>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                    }}
                  >
                    <DownloadButton
                      clickFunction={() =>
                        handleDownloadInters(intermediaryFiltered)
                      }
                    />
                    <span>
                      <strong>Prod. interm. total em estoque:</strong> R${' '}
                      {intermediaryFiltered &&
                        intermediaryFiltered
                          .reduce((accum, curr) => {
                            return (
                              accum +
                              Number(
                                isNaN(curr.total_value) || curr.total_value < 0
                                  ? 0
                                  : curr.total_value
                              )
                            );
                          }, 0)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}
                    </span>
                  </div>
                </div>

                <StyledTable borderless>
                  <thead>
                    <tr>
                      <th>Produto Intermediário</th>
                      <th>Quantidade em Estoque</th>
                      <th>Custo Unitário</th>
                      <th>Valor em Estoque</th>
                      <th>Quantidade Insumos</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {intermediaryFiltered.map((inter) => (
                      <IntermediaryRow
                        key={inter.id}
                        intermediary={inter}
                        setIntermediary={setIntermediary}
                        setIntermediaryFiltered={setIntermediaryFiltered}
                        handleDelete={handleDeleteIntermediary}
                        inputs={inputs}
                        intermediaries={intermediary}
                        getInputs={getInputs}
                        filterStringInter={filterStringInter}
                        filterString={filterString}
                        interInventory={interInventory}
                        isInterInventory={isInterInventory}
                        setInterInventory={setInterInventory}
                      />
                    ))}
                  </tbody>
                </StyledTable>
              </ComplementsTable>

              <Modal
                isOpen={isModalCreateSuppliersOpen}
                toggle={toggleModalCreateSuplliers}
              >
                <ModalHeader> Criar Fornecedor</ModalHeader>
                <Form onSubmit={handleSupplierSubmit} ref={formRefSupplier}>
                  <ModalBody>
                    <Input label="Nome do fornecedor" name="name" />
                    <Input label="CNPJ" name="cnpj" />
                    <ReactInputMask mask="(99) 99999-9999">
                      <Input
                        label="Telefone"
                        name="phone"
                        maskChar=""
                        placeholder="(xx) xxxxx-xxxx"
                      />
                    </ReactInputMask>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={toggleModalCreateSuplliers}>
                      Cancelar
                    </Button>
                    <Button color="success" type="submit">
                      Salvar
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                isOpen={isModalCreateBankOpen}
                toggle={toggleModalCreateBank}
              >
                <ModalHeader> Criar Conta</ModalHeader>
                <Form onSubmit={handleBankSubmit} ref={formRefBank}>
                  <ModalBody>
                    <Input label="Nome do banco" name="name" />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={toggleModalCreateBank}>
                      Cancelar
                    </Button>
                    <Button color="success" type="submit">
                      Salvar
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal isOpen={modalErroNotAllowed} toggle={toggle}>
                <Form onSubmit={handleInput} ref={formRef}>
                  <ModalHeader>Contate a franqueadora para criar {activeTab === '1' ? 'insumos' : 'produtos intermediários'}.</ModalHeader>
                  <ModalFooter style={{ float: 'right' }}>
                    <Button color="success" type="submit" onClick={e => toggle()}>
                      Ok
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal isOpen={modal} toggle={toggle}>
                <Form onSubmit={handleInput} ref={formRef}>
                  <ModalHeader>Cadastrar Insumos </ModalHeader>
                  <ModalBody>
                    <Container>
                      <Row>
                        <Col md="12">
                          <Input
                            label="Nome do Insumo:"
                            name="name"
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Input
                            label="Estoque atual:"
                            name="quantidade"
                            type="number"
                            step="0.001"
                            icon={
                              <FaQuestionCircle
                                title="Quantidade de insumo adiciondo ao estoque"
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col md="4">
                          <Select
                            label="Unidade:"
                            name="unidade"
                            options={unidade_options}
                            defaultValue={unidade_options[1]}
                          // onChange={(e) => setInputSelectedId(e.value)}
                          />
                        </Col>
                        <Col md="4">
                          <Input
                            label="Valor Gasto"
                            name="total_to_send"
                            type="number"
                            step="0.01"
                            defaultValue="1"
                            icon={
                              <FaQuestionCircle
                                title="Custo referente à quantidade de insumo adicionado."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Input
                            label="Estoque Mínimo"
                            name="minimum_stock"
                            type="number"
                            step="0.001"
                            icon={
                              <FaQuestionCircle
                                title="Caso o estoque do Insumo esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col md="6">
                          <Input
                            label="Estoque Ideal"
                            name="ideal_stock"
                            type="number"
                            step="0.001"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Select
                            label="Associar centro de custo"
                            name="category"
                            options={categories}
                            placeholder="Selecione a categoria"
                          // onChange={(e) => setInputSelectedId(e.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <label>
                            <CheckboxInput
                              name="create_cashflow"
                              label="Vincular esta movimentação ao financeiro"
                              defaultChecked
                              onChange={() => {
                                handleCreateCashFlowOnNewInput();
                              }}
                            />
                          </label>
                        </div>
                      </Row>

                      {createCashFlowOnNewInput && (
                        <>
                          <p style={{ marginTop: 10 }}>
                            Informações Adicionais{' '}
                            <FaQuestionCircle
                              color="orange"
                              title="Complemente as informações do seu lançamento para uma melhor gestão de estoque."
                            />
                          </p>
                          <Row>
                            <Col>
                              <Row>
                                <Col md="9" style={{ marginLeft: 5 }}>
                                  <Select
                                    label="Fornecedor"
                                    name="provider_id"
                                    options={suppliers}
                                    placeholder="Fornecedor"
                                  // onChange={(e) => setInputSelectedId(e.value)}
                                  />
                                </Col>
                                <Col md="1">
                                  <button
                                    type="button"
                                    style={{
                                      width: 32,
                                      height: 32,
                                      position: 'absolute',
                                      bottom: 0,
                                      left: 0,
                                      background: '#3BD2C1',
                                      borderRadius: '50%',
                                      border: 'none',
                                      marginBottom: 15,
                                    }}
                                    title="Cadastrar Fornecedor"
                                    onClick={toggleModalCreateSuplliers}
                                  >
                                    +
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Col md="9" style={{ marginLeft: 5 }}>
                                  <Select
                                    label="Conta"
                                    name="bank_account_id"
                                    options={banks}
                                    placeholder="Conta"
                                  // onChange={(e) => setInputSelectedId(e.value)}
                                  />
                                </Col>
                                <Col md="1">
                                  <button
                                    type="button"
                                    style={{
                                      width: 32,
                                      height: 32,
                                      position: 'absolute',
                                      bottom: 0,
                                      left: 0,
                                      background: '#3BD2C1',
                                      borderRadius: '50%',
                                      border: 'none',
                                      marginBottom: 15,
                                    }}
                                    onClick={toggleModalCreateBank}
                                    title="Cadastrar Conta Bancária"
                                  >
                                    +
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Select
                              placeholder="Método de pagamento"
                              options={paymentMethods}
                              name="cash_flow_payment_method_id"
                            />
                          </Row>
                          <Row>
                            <Input
                              id="date-local"
                              label="Vencimento"
                              type="date"
                              name="due_date"
                              defaultValue={format(new Date(), 'yyyy-MM-dd')}
                              className="data"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Row>
                          <Row>
                            <Col>
                              <CheckboxInput
                                name="paid"
                                label="Pago"
                                onChange={handleSetPaidCheck}
                              />
                            </Col>{' '}
                          </Row>

                          {paidCheck && (
                            <Row>
                              <Col>
                                <Input
                                  id="date-local"
                                  label="Pago em:"
                                  type="date"
                                  name="paid_at"
                                  defaultValue={format(
                                    new Date(),
                                    'yyyy-MM-dd'
                                  )}
                                  className="data"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={toggle}>
                      Cancelar
                    </Button>
                    <Button color="success" type="submit">
                      Salvar
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                isOpen={modalIntermediary}
                toggle={toggleIntermediary}
                size="xl"
              >
                <Form onSubmit={handleIntermediary} ref={formIntermediaryRef}>
                  <ModalHeader>Adicionar Produto Intermediário</ModalHeader>
                  <ModalBody>
                    <Container>
                      <Row>
                        <Col md="12">
                          <Input
                            label="Nome:"
                            name="name"
                            id="name_intermediary"
                            type="text"
                            onChange={(e) =>
                              setNameIntermediary(e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col md="12">
                          <p>
                            O rendimento da ficha técnica (receita) que iremos
                            inserir, produz:
                          </p>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col md="7">
                          <Input
                            name="yield"
                            label='Rendimento da ficha técnica (receita) produz:'
                            defaultValue={1}
                            type="number"
                            step="0.001"
                            onChange={(e) =>
                              setYeldIntermediary(e.target.value)
                            }
                          />
                        </Col>
                        {/* <Col md="3">
                          <p style={{ marginTop: 15 }}>
                            {unidadeSelected} de{' '}
                            {nameIntermediary === '' ? '-' : nameIntermediary}
                          </p>
                        </Col> */}
                        <Col md="5" >
                          <Select
                            label="Unidade:"
                            name="unidade"
                            options={unidade_options}
                            defaultValue={unidade_options[1]}
                            onChange={(e) => setUnidadeSelected(e.label)}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        <Col md="12" style={{ marginTop: 10 }}>
                          <p>Ficha Técnica</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Select
                            label="Insumos"
                            name="inputs_select"
                            options={inputs_select}
                            placeholder="Selecione"
                            onChange={(e) => setSelectInput(e)}
                            icon={
                              <FaQuestionCircle
                                title="Vincule os insumos usados na receita para produzir seu produto intermediário"
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col md="4">
                          <Input
                            label="Quantidade"
                            name="quantidade"
                            type="text"
                            defaultValue={0}
                            onChange={(e) => {
                              setDefaultInterValue(e.target.value);
                              document.getElementById('add').disabled = false;
                            }}
                            icon={
                              <FaQuestionCircle
                                title="Insira a quantidade necessária utilizada na receita para produzir seu produto intermediário."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col
                          md="2"
                          style={{ margin: 'auto', marginBottom: 15 }}
                        >
                          <button
                            type="button"
                            id="add"
                            style={{
                              background: '#3BD2C1',
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              border: 'none',
                              fontSize: 16,
                            }}
                            onClick={() =>
                              insertInputInter(selectInput, defaultInterValue)
                            }
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <InputsTable borderless>
                            <thead>
                              <tr>
                                <th>Insumo</th>
                                <th>
                                  Consumo unitário{' '}
                                  <FaQuestionCircle
                                    title="Quantidade necessária para
                            se produzir uma unidade do Produto Intermediário."
                                    color="orange"
                                    style={{ marginLeft: 2 }}
                                  />
                                </th>
                                <th>
                                  Custo do insumo{' '}
                                  <FaQuestionCircle
                                    title="Custo de uma unidade do insumo."
                                    color="orange"
                                    style={{ marginLeft: 2 }}
                                  />
                                </th>
                                <th>
                                  Consumo total{' '}
                                  <FaQuestionCircle
                                    title="Quantidade necessária para
                                se produzir uma receita do Produto Intermediário."
                                    color="orange"
                                    style={{ marginLeft: 2 }}
                                  />
                                </th>
                                <th>
                                  Custo da receita{' '}
                                  <FaQuestionCircle
                                    title="Custo para se produzir uma receita do Produto Intermediário."
                                    color="orange"
                                    style={{ marginLeft: 2 }}
                                  />
                                </th>
                                <th>Disponível</th>
                                <th>Ações</th>
                              </tr>
                            </thead>

                            <tbody>
                              {interInputs.length > 0 &&
                                interInputs.map((input) => (
                                  <tr>
                                    <td>{input.name}</td>
                                    <td>
                                      {parseFloat(
                                        input.quantidade_inter_value /
                                        yeldIntermediary
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {input.unidade}
                                    </td>

                                    <td>
                                      R$
                                      {parseFloat(
                                        input.unitary_price
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td>
                                      {parseFloat(input.quantidade_inter_value)
                                        .toFixed(4)
                                        .replace('.', ',')}{' '}
                                      {input.unidade}
                                    </td>

                                    <td>
                                      R$
                                      {parseFloat(
                                        input.quantidade_inter_value *
                                        // yeldIntermediary *
                                        input.unitary_price
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td>
                                      {' '}
                                      {parseFloat(input.quantidade).toFixed(4).replace('.', ',')}{' '}
                                      {input.unidade}
                                    </td>

                                    <td>
                                      <Delete
                                        type="button"
                                        onClick={() =>
                                          deleteInputInter(input.id)
                                        }
                                      >
                                        <MdDelete size={20} />
                                      </Delete>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </InputsTable>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{ margin: 5 }} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Select
                            label="Prod. Interm."
                            name="inter_select"
                            options={inter_select}
                            placeholder="Selecione"
                            onChange={(e) => setSelectInter(e)}
                            icon={
                              <FaQuestionCircle
                                title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col md="4">
                          <Input
                            label="Quantidade"
                            name="quantidade"
                            type="text"
                            defaultValue={0}
                            onChange={(e) => {
                              setDefaultInterInterValue(
                                e.target.value.replace(',', '.')
                              );
                              document.getElementById('add1').disabled = false;
                            }}
                          />
                        </Col>
                        <Col
                          md="2"
                          style={{ margin: 'auto', marginBottom: 15 }}
                        >
                          <button
                            type="button"
                            id="add1"
                            style={{
                              background: '#3BD2C1',
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              border: 'none',
                              fontSize: 16,
                            }}
                            onClick={() =>
                              insertInterInter(
                                selectInter,
                                defaultInterInterValue
                              )
                            }
                          >
                            +
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <InputsTable borderless>
                            <thead>
                              <tr>
                                <th>Prod. interm.</th>
                                <th>Consumo nitário</th>
                                <th>Custo do Prod. Int.</th>
                                <th>Consumo total</th>
                                <th>Custo da receita</th>
                                <th>Disponível</th>
                                <th>Ações</th>
                              </tr>
                            </thead>

                            <tbody>
                              {interInter.length > 0 &&
                                interInter.map((inter) => (
                                  <tr>
                                    <td>{inter.name}</td>
                                    <td>
                                      {parseFloat(
                                        inter.quantidade_inter_value /
                                        yeldIntermediary
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {inter.unidade}
                                    </td>

                                    <td>
                                      R$
                                      {parseFloat(
                                        inter.unitary_price
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td>
                                      {parseFloat(inter.quantidade_inter_value)
                                        .toFixed(3)
                                        .replace('.', ',')}
                                      {inter.unidade}
                                    </td>

                                    <td>
                                      R$
                                      {parseFloat(
                                        inter.quantidade_inter_value *
                                        // yeldIntermediary *
                                        inter.unitary_price
                                      ).toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td>
                                      <td>
                                        {' '}
                                        {parseFloat(inter.quantidade).toFixed(4).replace(
                                          '.',
                                          ','
                                        )}{' '}
                                        {inter.unidade}
                                      </td>
                                    </td>

                                    <td>
                                      <Delete
                                        type="button"
                                        onClick={() =>
                                          deleteInterInter(inter.id)
                                        }
                                      >
                                        <MdDelete size={20} />
                                      </Delete>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </InputsTable>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 10 }}>
                        <Col style={{ marginTop: 10 }}>
                          <p>Receita</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <TextareaContainer
                            name="recipe"
                            placeholder="Salve a receita do produto intermediário"
                            type="text"
                            maxLength="500"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Input
                            label="Quantidade em Estoque"
                            name="quantidade_inter"
                            onChange={(e) => setQuantInter(e.target.value)}
                            type="number"
                            step="0.001"
                            icon={
                              <FaQuestionCircle
                                title="Quantidade criada do Produto Intermediário nessa receita."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>

                      </Row>
                      <Row>
                        <Col md="6">
                          <Input
                            label="Estoque Mínimo"
                            name="minimum_stock"
                            icon={
                              <FaQuestionCircle
                                title="Caso o estoque do Produto Intermediário esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas.."
                                color="orange"
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        </Col>
                        <Col md="6">
                          <Input label="Estoque Ideal" name="ideal_stock" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="8">
                          <CheckboxInput
                            name="discount_inputs"
                            label="Ao criar a ficha técnica, descontar insumos e produtos intermediários do estoque"
                            onChange={() => {
                              setDiscountInputsOnHandleInter(!discountInputsOnHandleInter)
                            }}
                            value={discountInputsOnHandleInter}
                            checked={discountInputsOnHandleInter}
                            spanStyle={{ marginLeft: 10, fontWeight: 400, marginTop: 7 }}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="danger" onClick={toggleIntermediary}>
                      Cancelar
                    </Button>
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>{' '}
                  </ModalFooter>
                </Form>
              </Modal>
            </TabPane>

            <TabPane tabId="3">
              {activeTab === '3' ? <InputLossReport type='-' /> : <></>}
            </TabPane>

            <TabPane tabId="4">
              {activeTab === '4' ? <InputLossReport type='+' /> : <></>}
            </TabPane>
          </TabContent>
        </>
      ) : (
        <ErrorContainer>
          <p style={{ fontSize: 23 }}>
            Boas notícias! Seu plano atual já contempla a função de controle de
            estoque!
          </p>
          <p style={{ fontSize: 16 }}>
            Para ativar essa funcionalidade, entre em contato com o time de
            suporte que sua ativação será realizada sem custos extras.
          </p>
        </ErrorContainer>
      )}
    </Container>
  );
}

export default Inputs;