import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getTaxReportData(sessions) {
  const headers = [
    ['Relatório de Taxas de Serviço'],
    ['Dia', 'Pedidos', 'Vendas', 'Taxas'],
  ];

  const data = sessions?.index.map((idx) => {
    return [
        idx,
        sessions.days[idx]?.orders ? parseInt(sessions.days[idx]?.orders) : '',
        sessions.days[idx]?.total,
        sessions.days[idx]?.taxes
    ];
  });

  return [...headers, ...data];
}

// /restaurants/cash-flows/bank-accounts/events/:id
export function generateSheet(sessions) {
  const rows = getTaxReportData(sessions);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 4;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });
  worksheet['!cols'][2] = { wch: 24 };

  worksheet["!merges"] = [
    {
      s: { r: 0, c: 0 },
      e: { r: 0, c: num_cols - 1 },
    },
  ];

  const black = '000000';
  const white = 'ffffff';

  const orange = 'fb6340';
  const light_orange = 'fed8cf';
  const green = '2dce89';
  const light_green = 'cbf3e2';
  const dark1 = '525f7f';

  {
    worksheet['A1'].s = style()
      .Font(white, '', 'center')
      .Bg(black)
      .Get();
  }

  for (let j = 0; j < num_cols; j += 1) {
    const cell = worksheet[columns[j] + '2'];

    cell.s = style()
      .Font(black, '', 'center')
      .Borders('tblr', black)
      .Get();
  }

  for (let j = 0; j < num_cols; j += 1) {
    const cell = worksheet[columns[j] + '2'];

    cell.s = style()
      .Font(black, '', 'center')
      .Borders('tblr', black)
      .Get();
  }

  for (let i = 2; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      let s = style().Borders('lr', black);

      if (j === 4 || j === 5) {
        s = s.Font(black, '', 'right');
      } else if (typeof rows[i][j] === 'number') {
        s = s.Number();
      }


      if (i === rows.length - 1) {
        s = s.Borders('blr', black);
      }

      cell.s = s.Get();
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Taxas de Serviço');

  return XLSX.write(workbook, { type: 'buffer' });
}
