import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';
import { FaPen, FaTrash } from 'react-icons/fa';

import {
  getDay,
  startOfDay,
  endOfDay,
  addDays,
  format,
  addMinutes,
  subMinutes,
} from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import ReactDatePicker from 'react-datepicker';
import { MdCheck } from 'react-icons/md';
import api from '~/services/api';
import {
  Container,
  Footer,
  Content,
  ButtonGoToCart,
  CountBadge,
  ButtonAction,
  SelectField,
  SelectStyled,
  SelectGroup,
  InputStyled,
  ErrorPhrase,
  UserInfoCard,
  DeleteUserInfoButton,
  ButtonGoToCartSubmit,
  FooterSubmit,
  PersonalSelectStyled,
  AddressField,
  StreetInputField,
  InputField,
  HoursAvailable,
  HourCheckbox,
  DataPickerArea,
  HourTitle,
  HourDiv,
} from './styles';

import logo_icon from '../../../assets/img/logo_icon.png';

import { useCart } from '~/context/OrderingSystem/Cart';
import Input from '~/components/Form/Input';

import CheckboxInput from '~/components/Form/Checkbox';
import { useAuth } from '~/context/AuthContext';
import getValidationErrors from '~/services/getValidationErrors';
import { useMenu } from '~/context/OrderingSystem/Menu';
import PersonalButton from '~/components/Buttons/PersonalButton';

function InputUserInfoByDistance({
  toggleModalOperation,
  setDeliveryTax,
  discount,
  discountObs,
  adminPassword, 
  setAdminPassword 
}) {
  const { cart, addCartToOrder, closeCart } = useCart();
  const { getMenu } = useMenu();
  const [hasUsedDelivery, setHasUsedDelivery] = useState(true);
  const [renderPhrase, setRenderPhrase] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState('');
  const [method, setMethod] = useState('');
  const [methodId, setMethodId] = useState('');
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = useState(false);
  const [willReceiveSms, setWillReceiveSms] = useState('');
  const [orderType, setOrderType] = useState('delivery');
  const [userChangeValue, setUserChangeValue] = useState(0);
  const [buyerFormInitData, setBuyerFormInitData] = useState({});
  const [inputAddressIdx, setInputAddressIdx] = useState();
  const [inputAddressId, setInputAddressId] = useState();
  const [buyerId, setBuyerId] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [userInfoSelected, setUserInfoSelected] = useState(null);
  const [deliveryTaxes, setDeliveryTaxes] = useState([]);
  const [details, setDetails] = useState()
  const { user } = useAuth();

  const userRef = useRef(null);

  const formRef = useRef(null);

  const [isModalEditAddressOpened, setIsModalEditAddressOpened] = useState(
    false
  );

  const [isModalEditUserOpened, setIsModalEditUserOpened] = useState(false);
  const [userEditInfo, setUserEditInfo] = useState();

  function toggleModalEditUserAddress(data) {
    setIsModalEditAddressOpened(!isModalEditAddressOpened);
    setUserEditInfo(data);
  }

  function toggleModalEditUser(data) {
    setIsModalEditUserOpened(!isModalEditUserOpened);
    setUserEditInfo(data);
  }

  const [isModalCreateAddressOpened, setIsModalCreateAddressOpened] = useState(
    false
  );

  function toggleModalCreateUser() {
    setIsModalCreateAddressOpened(!isModalCreateAddressOpened);
  }

  async function getUserInfo() {
    try {
      const inputedPhone = userRef.current.value;

      const response = await api.get(
        `/restaurants/buyers?phone=${inputedPhone}`
      );

      const data = response.data || {};

      if (!response.data) {
        setDeliveryTax(null);
      }

      const {
        id,
        delivery_addresses,
        has_used_delivery,
        name = '',
        email = '',
        phone = inputedPhone,
      } = data;

      setBuyerId(id);
      // If the form has alreary been rendered sets the current data,
      // otherwise sets the initial data
      if (formRef.current) {
        formRef.current.setData({ name, email, phone });
      } else {
        setBuyerFormInitData({ name, email, phone });
      }

      if (delivery_addresses && has_used_delivery) {
        setHasUsedDelivery(true);

        for (const address of delivery_addresses) {
          try {
            const tax = await getDeliveryTax(address.id);
            setDeliveryTaxes((state) => [...state, tax]);
            if (delivery_addresses.indexOf(address) === 0) {
              setDeliveryTax(tax);
            }
          } catch (err) {
            if (err.response.data.errorType === 'delivery_max_distance') {
              setDeliveryTaxes((state) => [...state, false]);
            }
            if (delivery_addresses.indexOf(address) === 0) {
              setDeliveryTax(null);
            }
          }
        }

        setUserInfo({ name, email, phone, addresses: delivery_addresses });

        setUserInfoSelected({
          name,
          email,
          phone,
          address: delivery_addresses[0],
        });

        setRenderPhrase(false);
      } else {
        setRenderPhrase(true);
        setHasUsedDelivery(false);
        setUserInfo(null);
      }
    } catch (error) {
      setRenderPhrase(true);
      setHasUsedDelivery(false);
      setUserInfo(null);
      setDeliveryTax(null);
    }
  }

  function changeOrderType() {
    if (orderType === 'delivery') {
      setOrderType('withdrawal');
    }

    if (orderType === 'withdrawal') {
      setOrderType('delivery');
    }
  }

  function setUserPhoneDigit(e) {
    if (e.charAt(14) !== '_') {
      getUserInfo();
      // setIsWithdrawalAllowed(false);
    } else {
      setUserInfo(null);
      setRenderPhrase(false);
      setHasUsedDelivery(true);
      // setIsWithdrawalAllowed(false);
    }
  }
  async function handleGoToCartWithUser(data) {
    const infoUser = {
      ...userInfoSelected,
      with_withdrawal: isWithdrawalAllowed,
      payment_method_id: methodId,
      will_receive_sms: willReceiveSms,
      user_change: userChangeValue,
    };

    if(user.order_cancel_password && discount){
      if(adminPassword !== user.order_cancel_password){
        toast.error('Senha administrativa incorreta.');
        return;
      }
    }

    if (cart.length < 1) {
      toast.error('Carrinho vazio');
    } else if (discount && !discountObs) {
      toast.error('Escreva o motivo do desconto');
    } else if (isSchedluing) {
      if (!hourSelected) {
        toast.error('Selecione o horário de agendamento.');
      } else {
        addCartToOrder(
          null,
          null,
          orderType,
          hasUsedDelivery,
          infoUser,
          toggleModalOperation,
          null,
          null,
          null,
          getMenu,
          discount,
          discountObs,
          user.user_id,
          null,
          hourSelected,
          null,
          details
        );
      }
    } else {
      addCartToOrder(
        null,
        null,
        orderType,
        hasUsedDelivery,
        infoUser,
        toggleModalOperation,
        null,
        null,
        null,
        getMenu,
        discount,
        discountObs,
        user.user_id,
        null,
        null,
        null,
        details
      );
    }

    // closeCart();
  }

  async function handleGoToCart(data) {
    if (cart.length > 0) {
      if(user.order_cancel_password && discount){
        if(adminPassword !== user.order_cancel_password){
          toast.error('Senha administrativa incorreta.');
          return;
        }
      }
      if (isSchedluing) {
        if (!hourSelected) {
          toast.error('Selecione o horário de agendamento');
        } else {
          try {
            if (formRef.current) {
              formRef.current.setErrors({});
            }

            if (isWithdrawalAllowed) {
              const schema = Yup.object().shape({
                name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone Obrigatório'),
              });

              await schema.validate(data, {
                abortEarly: false,
              });
            } else {
              const schema = Yup.object().shape({
                name: Yup.string().required('Nome obrigatório'),
                phone: Yup.string().required('Telefone Obrigatório'),
                street: Yup.string().required('Rua obrigatória'),
                neighborhood: Yup.string().required('Bairro obrigatório'),
                city: Yup.string().required('Cidade obrigatória'),
              });

              await schema.validate(data, {
                abortEarly: false,
              });
            }

            addCartToOrder(
              null,
              null,
              orderType,
              hasUsedDelivery,
              data,
              toggleModalOperation,
              null,
              null,
              null,
              getMenu,
              discount,
              discountObs,
              user.user_id,
              null,
              hourSelected,
              null,
              details
            );
          } catch (error) {
            if (error instanceof Yup.ValidationError) {
              const errors = getValidationErrors(error);
              if (formRef.current) {
                formRef.current.setErrors(errors);
              }
            } else {
              console.log(error);
            }
          }
        }
      } else {
        try {
          if (formRef.current) {
            formRef.current.setErrors({});
          }

          if (isWithdrawalAllowed) {
            const schema = Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string().required('Telefone Obrigatório'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });
          } else {
            const schema = Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              phone: Yup.string().required('Telefone Obrigatório'),
              street: Yup.string().required('Rua obrigatória'),
              neighborhood: Yup.string().required('Bairro obrigatório'),
              city: Yup.string().required('Cidade obrigatória'),
            });

            await schema.validate(data, {
              abortEarly: false,
            });
          }

          addCartToOrder(
            null,
            null,
            orderType,
            hasUsedDelivery,
            data,
            toggleModalOperation,
            null,
            null,
            null,
            getMenu,
            discount,
            discountObs,
            user.user_id,
            null,
            null,
            null,
            details
          );
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (formRef.current) {
              formRef.current.setErrors(errors);
            }
          } else {
            console.log(error);
          }
        }
      }
    } else {
      toast.error('Carrinho vazio');
    }
  }

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/payment-methods`);

      const paymentOptions = response.data.payment_methods
        .filter((item) => item.delivery_accepts && item.keyword !== 'pix_auto')
        .map((method) => {
          return {
            label: method.name,
            value: method.id,
          };
        });

      setPaymentMethods(paymentOptions);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  const [selectedAddressId, setSelectedAddressId] = useState([]);

  function verifySelectedAddress(id) {
    if (selectedAddressId.includes(id)) {
      const newArray = selectedAddressId.filter((item) => item !== id);

      setSelectedAddressId(newArray);
    } else {
      setSelectedAddressId([id]);
    }
  }

  const [cepWithProblem, setCepWithProblem] = useState(false);

  const getDeliveryTaxByAddress = async (address) => {
    try {
      const response = await api.post('/restaurants/buyers/delivery-tax', {
        buyer_address: address,
      });

      setDeliveryTax(response.data);
    } catch (error) {
      if (error.response.data.errorType === 'delivery_max_distance') {
        toast.error('Endereço de entrega muito distante.');
      }
      console.log(error.message);
      setDeliveryTax(null);
      setCepWithProblem(false);
    }
  };

  const getDeliveryTaxByAddressWithNumber = async () => {
    try {
      const streetInput = formRef.current.getFieldRef('street').value;
      const neighboorhoodInput = formRef.current.getFieldRef('neighborhood')
        .value;
      const cityInput = formRef.current.getFieldRef('city').value;
      const stateInput = formRef.current.getFieldRef('state').value;
      const numberInput = formRef.current.getFieldRef('number').value;

      const response = await api.post('/restaurants/buyers/delivery-tax', {
        buyer_address: {
          street: streetInput,
          neighborhood: neighboorhoodInput,
          city: cityInput,
          state: stateInput,
          number: numberInput,
        },
      });

      setDeliveryTax(response.data);
    } catch (error) {
      if (error.response.data.errorType === 'delivery_max_distance') {
        toast.error('Endereço de entrega muito distante.');
      }
      console.log(error.message);
      setDeliveryTax(null);
    }
  };

  const [validCep, setValidCep] = useState(true);

  async function handleSearchCep(e) {
    if (e.target.value.charAt(8) !== '_') {
      const cep = e.target.value.replace('-', '').replace('.', '');

      const response = await api.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighborhood');
        const cityInput = formRef.current.getFieldRef('city');
        const stateInput = formRef.current.getFieldRef('state');
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        stateInput.value = response.data.uf;
        setValidCep(true);
      } else {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighborhood');
        const cityInput = formRef.current.getFieldRef('city');
        setValidCep(false);
        streetInput.value = '';
        neighboorhoodInput.value = '';
        cityInput.value = '';
        setCepWithProblem(false);
        setDeliveryTax(null);
      }

      if (response.data.logradouro) {
        getDeliveryTaxByAddress({
          zip_code: cep,
          street: response.data.logradouro,
          neighborhood: response.data.bairro,
          city: response.data.localidade,
          state: response.data.uf,
        });
        setCepWithProblem(false);
      } else {
        setCepWithProblem(true);
      }
    }
  }
  const editUseRef = useRef(null);

  async function handleSearchCepEdit(e) {
    if (e.target.value.charAt(8) !== '_') {
      const cep = e.target.value.replace('-', '').replace('.', '');

      const response = await api.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = editUseRef.current.getFieldRef('street');
        const neighboorhoodInput = editUseRef.current.getFieldRef(
          'neighborhood'
        );
        const cityInput = editUseRef.current.getFieldRef('city');
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        setValidCep(true);
      } else {
        const streetInput = editUseRef.current.getFieldRef('street');
        const neighboorhoodInput = editUseRef.current.getFieldRef(
          'neighborhood'
        );
        const cityInput = editUseRef.current.getFieldRef('city');
        setValidCep(false);
        streetInput.value = '';
        neighboorhoodInput.value = '';
        cityInput.value = '';
      }
    }
  }

  function handleChangeAddress(data) {
    setUserInfoSelected(data);
  }

  function handleEditUser(data) {
    setUserInfo((state) => {
      return {
        name: data.name,
        email: data.email,
        phone: data.phone,
        addresses: state.addresses,
      };
    });

    setUserInfoSelected((state) => {
      return {
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: userInfoSelected.address,
      };
    });

    toggleModalEditUser();
  }

  async function handleCreateUser(data) {
    try {
      const response = await api.post('/restaurants/buyer/delivery-addresses', {
        country: 'BR',
        state: data.state,
        buyer_id: buyerId,
        city: data.city,
        complement: data.complement,
        neighborhood: data.neighborhood,
        number: data.number,
        reference: data.reference,
        street: data.street,
        zip_code: data.zip_code,
      });

      setUserInfo((state) => {
        const addr = [...state.addresses, response.data];

        return { ...state, addresses: addr };
      });

      setUserInfoSelected((state) => {
        return { ...state, address: response.data };
      });
      toggleModalCreateUser();
    } catch (error) {
      toast.error('Erro ao adicionar novo endereço');
      console.log(error);
    }
  }

  const getDeliveryTax = async (address_id) => {
    const response = await api.post('/restaurants/buyers/delivery-tax', {
      buyer_address_id: address_id,
    });

    return response.data;
  };

  async function handleEditUserAddresses(data) {
    try {
      const response = await api.put(
        `/restaurants/buyer/delivery-addresses/${inputAddressId}`,
        {
          buyer_id: buyerId,
          city: data.city,
          complement: data.complement,
          neighborhood: data.neighborhood,
          number: data.number,
          reference: data.reference,
          street: data.street,
          zip_code: data.zip_code,
          state: data.state,
        }
      );

      setUserInfo((state) => {
        const addr = [...state.addresses];
        addr[inputAddressIdx] = response.data;

        return { ...state, addresses: addr };
      });

      setUserInfoSelected((state) => {
        return { ...state, address: response.data };
      });
      toggleModalEditUserAddress();
    } catch (error) {
      console.log(error);
    }
  }
  const [addressNumber, setAddressNumber] = useState();

  // scheduling
  const [isSchedluing, setIsSchedluing] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(false);
  const [activeDays, setRestaurantActiveDays] = useState([]);
  const [restaurantSettings, setRestaurantSettings] = useState(null);
  const [hourSelected, setHourSelected] = useState(null);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [hours, setHours] = useState([]);
  const [maxDate, setMaxDate] = useState(null);

  const getRestaurantConfigs = useCallback(async () => {
    try {
      const response = await api.get(`/restaurants/order-scheduling/`);

      setRestaurantSettings(response.data);
    } catch (err) {
      console.log('getRestaurantConfigs error: ', err);
    }
  }, []);

  const getTimes = async (day) => {
    setLoadingTimes(true);
    try {
      const response = await api.get(
        '/restaurants/order-scheduling/available-times',
        {
          params: {
            day,
            with_withdrawal: orderType.toString() === 'withdrawal',
          },
        }
      );

      setHours(response.data.times);
    } catch (err) {
      console.log('getTimes error: '.err);
    }
    setLoadingTimes(false);
  };

  const getFirstLetter = (word) => {
    return word.substring(0, 1).toUpperCase();
  };

  const handleDateUpdate = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    const day = startDate.getDay();
    if (activeDays.includes(day)) {
      getTimes(startDate.toISOString());
    }
  }, [startDate, activeDays]);

  const isWeekday = (date) => {
    const day = getDay(date);

    const ref_date = new Date(date).getTime();

    if (ref_date < startOfDay(new Date())) {
      return false;
    }

    if (maxDate) {
      if (ref_date > maxDate.getTime()) {
        return false;
      }
    }
    // activeDays.split('').map(item => {
    //   return {

    //   }
    // })

    return activeDays.includes(day);
  };

  useEffect(() => {
    if (!restaurantSettings) {
      setRestaurantActiveDays([]);
      return;
    }

    const daysFilter = [];

    const active_days =
      orderType === 'delivery'
        ? restaurantSettings.active_days
        : restaurantSettings.withdrawal_active_days;

    for (let i = 0; i < 7; i += 1) {
      if (active_days[i] === 't') {
        daysFilter.push(i);
      }
    }

    const max_time_in_advance =
      orderType === 'delivery'
        ? restaurantSettings.max_time_in_advance
        : restaurantSettings.withdrawal_max_time_in_advance;

    if (max_time_in_advance) {
      const start = startOfDay(new Date());
      const end = endOfDay(addMinutes(start, max_time_in_advance));

      setMaxDate(end);
    } else {
      setMaxDate(null);
    }

    setRestaurantActiveDays(daysFilter);
  }, [restaurantSettings, orderType]);

  useEffect(() => {
    getRestaurantConfigs();
  }, [getRestaurantConfigs]);

  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false)
  const [deliveryAddressIdToDelete, setDeliveryAddressIdToDelete] = useState()

  function toggleModalDelete(id){
    setIsModalDeleteOpened(!isModalDeleteOpened)
    setDeliveryAddressIdToDelete(id)
  }


async function handleDeleteAddress(){
  try{
    await api.put('/restaurants/buyer/delete/delivery-addresses',{
      buyer_address_id: deliveryAddressIdToDelete
    })
       const userInfoFiltered = userInfo.addresses.filter(address => address.id !== deliveryAddressIdToDelete)
    userInfo.addresses = userInfoFiltered
    toast.success('Endereço deletado com sucesso!')
    toggleModalDelete()
  }catch(error){
    toast.error('Erro ao deletar endereço')
    console.log(error.message)
  }
}

  return (
    <Container>

    <Modal isOpen={isModalDeleteOpened} toggle={toggleModalDelete}>   
      <ModalBody>
       <p>Deseja realmente deletar esse endereço?</p>
      </ModalBody>
      <ModalFooter>
        <PersonalButton message='Cancelar' color='#ff2c3a' onClick={toggleModalDelete}/>
        <PersonalButton message='Confirmar' color='#2ec9b7' onClick={()=>{handleDeleteAddress()}}/>
      </ModalFooter>
    </Modal>

      <Modal isOpen={isModalEditUserOpened} toggle={toggleModalEditUser}>
        <Form initialData={userEditInfo} onSubmit={handleEditUser}>
          <ModalHeader>Editar clientep</ModalHeader>
          <ModalBody>
            <AddressField>
              <Input type="text" placeholder="Nome" name="name" />

              <InputStyled mask="(99) 99999-9999">
                <Input name="phone" placeHolder="Telefone" />
              </InputStyled>

              <Input name="email" placeHolder="Email" />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalEditUser}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalEditAddressOpened}
        toggle={toggleModalEditUserAddress}
      >
        <Form
          initialData={userEditInfo}
          onSubmit={handleEditUserAddresses}
          ref={editUseRef}
        >
          <ModalHeader>Editar endereço</ModalHeader>
          <ModalBody>
            <AddressField>
              <ReactInputMask
                mask="99999-999"
                onChange={(e) => handleSearchCepEdit(e)}
              >
                <Input name="zip_code" placeHolder="CEP" />
              </ReactInputMask>
              {!validCep && (
                <Row>
                  <p style={{ color: 'red', textAlign: 'right' }}>
                    CEP inválido.
                  </p>
                </Row>
              )}
              <StreetInputField>
                {' '}
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                  />
                </InputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Número"
                    name="number"
                    id="number"
                  />
                </InputField>
              </StreetInputField>

              <Input type="text" placeholder="Bairro" name="neighborhood" />

              <Input name="city" placeHolder="Cidade" />
              <Input name="state" placeHolder="Estado" />

              <Input
                type="text"
                name="complement"
                placeholder="Ex: Casa 3, fundos"
              />

              <Input
                type="text"
                name="reference"
                placeholder="Ex: Prox. ao mercado x"
              />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalEditUserAddress}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalCreateAddressOpened} toggle={toggleModalCreateUser}>
        <Form onSubmit={handleCreateUser} ref={formRef}>
          <ModalHeader>Adicionar endereço</ModalHeader>
          <ModalBody>
            <AddressField>
              <ReactInputMask
                mask="99999-999"
                onChange={(e) => handleSearchCep(e)}
              >
                <Input name="zip_code" placeHolder="CEP" />
              </ReactInputMask>
              {!validCep && (
                <Row>
                  <p style={{ color: 'red', textAlign: 'right' }}>
                    CEP inválido.
                  </p>
                </Row>
              )}
              <StreetInputField>
                {' '}
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                  />
                </InputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Número"
                    name="number"
                    id="number"
                  />
                </InputField>
              </StreetInputField>

              <Input type="text" placeholder="Bairro" name="neighborhood" />

              <Input name="city" placeHolder="Cidade" />
              <Input name="state" placeHolder="Estado" />

              <Input
                type="text"
                name="complement"
                placeholder="Ex: Casa 3, fundos"
              />

              <Input
                type="text"
                name="reference"
                placeholder="Ex: Prox. ao mercado x"
              />
            </AddressField>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger"
              type="button"
              onClick={toggleModalCreateUser}
            >
              Cancelar
            </button>
            <button className="btn btn-success" type="submit">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Modal>
      <Content>
        <InputStyled
          mask="(99) 99999-9999"
          type="text"
          name="user_phone"
          placeholder="Buscar pelo celular"
          onChange={(e) => {
            setUserPhoneDigit(e.target.value);
          }}
          ref={userRef}
        />

        {renderPhrase && (
          <ErrorPhrase>
            Usuário ainda não pediu no delivery, favor preencher os dados
            abaixo.
          </ErrorPhrase>
        )}

        <Form>
          {user.delivery_info.is_withdrawal_allowed &&
            user.delivery_info.is_withdrawal_active && (
              <CheckboxInput
                label="Retirada"
                id="is_withdrawal_allowed"
                name="is_withdrawal_allowed"
                onChange={(e) => {
                  setIsWithdrawalAllowed(!isWithdrawalAllowed);
                  changeOrderType();
                  setDeliveryTax(null);
                }}
              />
            )}

          {user.delivery_info.has_sms_service && (
            <CheckboxInput
              label="Enviar SMS quando o pedido estiver pronto"
              id="will_receive_sms"
              name="will_receive_sms"
              onChange={(e) => setWillReceiveSms(!willReceiveSms)}
            />
          )}
          {user.is_order_scheduling_active && (
            <CheckboxInput
              label="Agendado"
              name="is_scheduling"
              value={isSchedluing}
              onChange={() => {
                setIsSchedluing(!isSchedluing);
              }}
            />
          )}
        </Form>
        {isSchedluing && (
          <DataPickerArea>
            <div style={{ position: 'relative' }}>
              <ReactDatePicker
                dateFormat="dd MMMM yyyy"
                formatWeekDay={(day) => getFirstLetter(day)}
                locale="pt"
                selected={startDate}
                onSelect={(e) => handleDateUpdate(e)}
                onChange={(e) => handleDateUpdate(e)}
                customInput={<span />}
                open
                filterDate={isWeekday}
              />
            </div>

            <HourDiv>
              {/* {!daySelected && <p>Selecione o dia desejado para que os horários sejam exibidos.</p>} */}
              <HourTitle>
                <span>Hora</span>
              </HourTitle>

              <HoursAvailable>
                <AnimatePresence>
                  {loadingTimes ? (
                    <Spinner style={{ margin: '15px auto', display: 'block' }}>
                      <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                  ) : (
                    hours?.map((h, i) => {
                      const hour = new Date(h);
                      const isDisabled =
                        subMinutes(hour, restaurantSettings.time_in_advance) <
                        new Date();

                      return (
                        <HourCheckbox
                          initial={{ x: -200 }}
                          animate={{ x: 0 }}
                          exit={{ x: -200 }}
                          transition={{
                            type: 'spring',
                            stiffness: 50,
                            mass: 1,
                          }}
                          key={i}
                          onClick={() => {
                            if (!isDisabled) {
                              setHourSelected(
                                hour.getHours() > 21 ? addDays(hour, 1) : h
                              );
                            }
                          }}
                          selected={h === hourSelected}
                          disabled={isDisabled}
                        >
                          <div>
                            {!isDisabled && (
                              <MdCheck size={15} color="#ffffff" />
                            )}
                          </div>
                          <p style={{ color: isDisabled && '#979797' }}>
                            {format(hour, "HH'h'mm")}
                          </p>
                        </HourCheckbox>
                      );
                    })
                  )}
                </AnimatePresence>
              </HoursAvailable>
            </HourDiv>
          </DataPickerArea>
        )}

        {!hasUsedDelivery && (
          <Form
            initialData={buyerFormInitData}
            onSubmit={handleGoToCart}
            ref={formRef}
          >
            {/* {user.delivery_info.is_withdrawal_allowed &&
              user.delivery_info.is_withdrawal_active && (
                <CheckboxInput
                  name="is_withdrawal_allowed"
                  label="Cliente irá retirar o produto"
                  onChange={(e) => {
                    setIsWithdrawalAllowed(!isWithdrawalAllowed);
                    setDeliveryTax(null);
                    changeOrderType();
                  }}
                />
              )}
            {user.delivery_info.has_sms_service && (
              <CheckboxInput
                name="will_receive_sms"
                label="Enviar sms pro usuário quando o pedido ficar pronto"
              />
            )} */}

            <Row>
              <Col lg="7">
                <Input name="name" placeHolder="Nome" />
              </Col>
              <Col lg="5">
                <ReactInputMask mask="(99) 99999-9999" disabled>
                  <Input name="phone" placeHolder="Telefone" />
                </ReactInputMask>
              </Col>
            </Row>
            <Input name="email" placeHolder="Email" />

            {orderType === 'delivery' && (
              <>
                <Row>
                  <Col lg="12">
                    <ReactInputMask
                      mask="99999-999"
                      onChange={(e) => handleSearchCep(e)}
                    >
                      <Input name="zip_code" placeHolder="CEP" />
                    </ReactInputMask>
                  </Col>
                </Row>
                <Row>
                  <Col lg="7">
                    <Input name="street" placeHolder="Rua" />
                  </Col>
                  <Col lg="5">
                    <Input
                      name="number"
                      placeHolder="Numero"
                      onChange={(e) => {
                        setAddressNumber(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <Input name="neighborhood" placeHolder="Bairro" />
                  </Col>
                  <Col lg="6">
                    <Input name="city" placeHolder="Cidade" />
                    <Input
                      name="state"
                      placeHolder="Estado"
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
                {addressNumber && cepWithProblem && validCep && (
                  <Row>
                    <button
                      type="button"
                      onClick={getDeliveryTaxByAddressWithNumber}
                      style={{
                        color: 'red',
                        textAlign: 'right',
                        border: 'none',
                        background: 'transparent',
                        textDecoration: 'underline',
                      }}
                    >
                      Calcular taxa de entrega no valor do carrinho
                    </button>
                  </Row>
                )}

                {!validCep && (
                  <Row>
                    <p style={{ color: 'red', textAlign: 'right' }}>
                      CEP inválido.
                    </p>
                  </Row>
                )}

                <Input name="complement" placeHolder="Complemento" />
                <Input name="reference" placeHolder="Referência" />
              </>
            )}

            <SelectGroup>
              <SelectField>
                <SelectStyled
                  name="payment_method"
                  options={paymentMethods}
                  isSearchable
                  placeholder="Método de pagamento"
                  onChange={(e) => {
                    setMethod(e.label);
                    setMethodId(e.value);
                  }}
                />
                {method === 'Dinheiro' && (
                  <Input
                    name="user_change"
                    placeholder="Troco pra quanto? (R$)"
                    style={{ marginTop: 10 }}
                    type="number"
                    step="0.01"
                  />
                )}
              </SelectField>

             
            </SelectGroup>

            <Form>
              <Input name='details' label='Informações adicionais' onChange={e => setDetails(e.target.value)} />
            </Form>
          

            <FooterSubmit>
              <div>
                <ButtonAction>
                  <ButtonGoToCartSubmit type="submit">
                    <img src={logo_icon} alt="logo da takeat" />
                    {!!cart.length > 0 && (
                      <CountBadge>{cart.length}</CountBadge>
                    )}
                  </ButtonGoToCartSubmit>
                  <span>Pedir</span>
                </ButtonAction>
              </div>
            </FooterSubmit>
          </Form>
        )}

        {userInfo && (
          <>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
                padding: 8,
              }}
            >
              <div>
                <p>{userInfo.name}</p>
                <p>{userInfo.phone}</p>
                <p>{userInfo.email}</p>
              </div>
              <div>
                <FaPen
                  onClick={() => toggleModalEditUser(userInfo)}
                  title="Editar cliente"
                  style={{ cursor: 'pointer' }}
                />
               
              </div>
              {console.log(deliveryTaxes)}
            </div>
            {isWithdrawalAllowed === false &&
              userInfo.addresses.map((address, idx) => (
                <UserInfoCard selected={selectedAddressId.includes(address.id)}>
                  <button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      textAlign: 'left',
                      width: '90%',
                    }}
                    disabled={deliveryTaxes[idx] === false}
                    onClick={() => {
                      handleChangeAddress({
                        name: userInfo.name,
                        email: userInfo.email,
                        phone: userInfo.phone,
                        address,
                      });
                      verifySelectedAddress(address.id);
                      setDeliveryTax(deliveryTaxes[idx]);
                    }}
                    type="button"
                    title="Selecionar endereço"
                  >
          
                    <p>
                      {address.street} - {address.number}
                    </p>
                    <p>
                      {address.neighborhood} - {address.zip_code}
                    </p>

                    <p>
                      {address.city} - {address.state}
                    </p>
                    <p>
                      {deliveryTaxes[idx] === false
                        ? 'Endereço de entrega muito distante'
                        : `Taxa de entrega: R$${deliveryTaxes[idx]}`}
                    </p>
                  </button>

                  <div>
                    <FaPen
                      onClick={() => {
                        toggleModalEditUserAddress(address);
                        setInputAddressIdx(idx);
                        setInputAddressId(address.id);
                      }}
                      title="Editar endereço"
                    />
                    <FaTrash style={{marginLeft: 4}} color='#ff2c3a' cursos='pointer' onClick={()=>toggleModalDelete(address.id)} title='Deletar endereço'/>
                  </div>
                </UserInfoCard>
              ))}
            {userInfo.addresses.length < 2 && (
              <DeleteUserInfoButton
                type="button"
                onClick={toggleModalCreateUser}
              >
                Adicionar novo endereço{' '}
              </DeleteUserInfoButton>
            )}
            <SelectGroup>
              <SelectField>
                <PersonalSelectStyled
                  name="payment_method"
                  options={paymentMethods}
                  isSearchable
                  placeholder="Método de pagamento"
                  onChange={(e) => {
                    setMethod(e.label);
                    setMethodId(e.value);
                  }}
                />
                {method === 'Dinheiro' && (
                  <InputStyled
                    name="user_change"
                    placeholder="Troco pra quanto? (R$)"
                    style={{ marginTop: 10 }}
                    type="number"
                    step="0.01"
                    onChange={(e) => {
                      setUserChangeValue(e.target.value);
                    }}
                  />
                )}
              </SelectField>
            </SelectGroup>


            <Form>
              <Input name='details' label='Informações adicionais' onChange={e => setDetails(e.target.value)} /> 
            </Form>
      

            {/* <DeleteUserInfoButton type="button" onClick={deleteUserInfo}>
              Alterar informações
            </DeleteUserInfoButton> */}
            <Footer>
              <div>
                <ButtonAction>
                  <ButtonGoToCart onClick={handleGoToCartWithUser}>
                    <img src={logo_icon} alt="logo da takeat" />
                    {!!cart.length > 0 && (
                      <CountBadge>{cart.length}</CountBadge>
                    )}
                  </ButtonGoToCart>
                  <span>Pedir</span>
                </ButtonAction>
              </div>
            </Footer>
          </>
        )}
      </Content>
    </Container>
  );
}

export default InputUserInfoByDistance;
