import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-hot-toast';

import api from '~/services/api';

import { ordersGetSuccess, ordersGetFailure } from './actions';
import Push from '~/services/Push';

export function* getOrders() {
  try {
    const pending = yield call(api.get, 'restaurants/orders', {
      params: {
        status: 'pending',
      },
    });
    const accepted = yield call(api.get, 'restaurants/orders', {
      params: {
        status: 'accepted',
      },
    });
    const finished = yield call(api.get, 'restaurants/orders', {
      params: {
        status: 'finished',
      },
    });
    const canceled = yield call(api.get, 'restaurants/orders', {
      params: {
        status: 'canceled',
      },
    });

    yield put(
      ordersGetSuccess({
        pending: pending.data,
        accepted: accepted.data,
        finished: finished.data,
        canceled: canceled.data,
      })
    );

    if (pending.data.length > 0) {
      Push.create('Pedidos pendentes', {
        body: 'Existem pedidos novos em seu restaurante aguardando sua ação',
        onClick() {
          window.focus();
          this.close();
        },
      });
    }
  } catch (err) {
    toast.error('Erro ao pegar dados');
    yield put(ordersGetFailure());
  }
}

export default all([takeLatest('@orders/GET_REQUEST', getOrders)]);
