import React, { useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';

export default function NfeDevolucao({
  nfe,
  modal,
  toggle,
  nfeReq,
  itens,
  setItens,
  data,
}) {
  const [allChecked, setAllChecked] = useState(false);

  function changeSend(item) {
    itens[item].send = !itens[item].send;

    const thisItens = itens.map((it) => {
      if (it.numero_item === item + 1) {
        it.send = !it.send;
      } else {
        it.send = it.send;
      }

      return it;
    });

    setItens(thisItens);
  }

  function checkAll() {
    if (allChecked) {
      itens.map((item) => {
        item.send = false;
      });
    } else {
      itens.map((item) => {
        item.send = true;
      });
    }

    setAllChecked(!allChecked);
  }

  const [loadingNfe, setLoadingNfe] = useState(false);
  const [mensagemSefaz, setMensagemSefaz] = useState('');

  const handleDevolucao = useCallback(async () => {
    const thisItens = itens.filter((it) => it.send === true);
    if (thisItens.length === 0) {
      toast.error('Selecione pelo menos um produto');
    } else {
      try {
        setLoadingNfe(true);
        const handle = data;

        handle.itens = thisItens;
        const response = await api.post(
          `/restaurants/new-devolucao-nfe/${nfe.id}`,
          handle
        );

        if (response.data.status === 'erro_autorizacao') {
          setMensagemSefaz(response.data.mensagem);
        } else if (response.data.status === 'autorizado') {
          window.open(
            response.data.url,
            'janela',
            'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
          );
          toggle();
        } else {
          setMensagemSefaz(response.data?.mensagem || '');
        }

        setLoadingNfe(false);
      } catch (err) {
        console.log(err);
        setLoadingNfe(false);
      }
    }
  });

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <Form initialData={data}>
            {!nfeReq && (
              <Row>
                <Col>
                  <h5 style={{ color: 'red' }}>
                    <strong>
                      Informações da nota ainda não processada pela Sefaz. Tente
                      novamente mais tarde.
                    </strong>
                  </h5>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{ borderBottom: '1px solid #EBEBEB', marginBottom: 5 }}
              >
                <h5>Dados do Emitente:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_emitente"
                  label="Razão Social"
                  type="text"
                  onChange={(e) => (data.nome_emitente = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cnpj_emitente"
                  label="CNPJ"
                  type="text"
                  onChange={(e) => (data.cnpj_emitente = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="inscricao_estadual_emitente"
                  label="Inscrição Estadual"
                  type="text"
                  onChange={(e) =>
                    (data.inscricao_estadual_emitente = e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <Input
                  name="logradouro_emitente"
                  label="Logradouro"
                  type="text"
                  onChange={(e) => (data.logradouro_emitente = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="numero_emitente"
                  label="Nº"
                  type="text"
                  onChange={(e) => (data.numero_emitente = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="complemento_emitente"
                  label="Complemento"
                  type="text"
                  onChange={(e) => (data.complemento_emitente = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Input
                  name="municipio_emitente"
                  label="Município"
                  type="text"
                  onChange={(e) => (data.municipio_emitente = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="uf_emitente"
                  label="UF"
                  type="text"
                  onChange={(e) => (data.uf_emitente = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="bairro_emitente"
                  label="Bairro"
                  type="text"
                  onChange={(e) => (data.bairro_emitente = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="cep_emitente"
                  label="CEP"
                  type="text"
                  onChange={(e) => (data.cep_emitente = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="regime_tributario_emitente"
                  label="Regime Tributário"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) =>
                    (data.regime_tributario_emitente = e.target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados do Destinatário:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Input
                  name="nome_destinatario"
                  label="Razão Social"
                  type="text"
                  onChange={(e) => (data.nome_destinatario = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="cnpj_destinatario"
                  label="CNPJ"
                  type="text"
                  onChange={(e) => (data.cnpj_destinatario = e.target.value)}
                />
              </Col>
              <Col md="3">
                <Input
                  name="inscricao_estadual_destinatario"
                  label="Inscrição Estadual"
                  type="text"
                  onChange={(e) =>
                    (data.inscricao_estadual_destinatario = e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  name="logradouro_destinatario"
                  label="Logradouro"
                  type="text"
                  onChange={(e) =>
                    (data.logradouro_destinatario = e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="numero_destinatario"
                  label="Nº"
                  type="text"
                  onChange={(e) => (data.cep_emitente = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="complemento_destinatario"
                  label="Complemento"
                  type="text"
                  onChange={(e) =>
                    (data.complemento_destinatario = e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="indicador_inscricao_estadual_destinatario"
                  label="Indic. Insc. Est."
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) =>
                    (data.indicador_inscricao_estadual_destinatario =
                      e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="local_destino"
                  label="Local Destino"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.local_destino = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Input
                  name="municipio_destinatario"
                  label="Município"
                  type="text"
                  onChange={(e) =>
                    (data.municipio_destinatario = e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="uf_destinatario"
                  label="UF"
                  type="text"
                  onChange={(e) => (data.uf_destinatario = e.target.value)}
                />
              </Col>
              <Col md="4">
                <Input
                  name="bairro_destinatario"
                  label="Bairro"
                  type="text"
                  onChange={(e) => (data.bairro_destinatario = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="cep_destinatario"
                  label="CEP"
                  type="text"
                  onChange={(e) => (data.cep_destinatario = e.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col
                style={{
                  borderBottom: '1px solid #EBEBEB',
                  marginBottom: 5,
                  paddingTop: 10,
                }}
              >
                <h5>Dados da Nota:</h5>
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="valor_total"
                  label="Valor Total"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_total = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_desconto"
                  label="Valor Desconto"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_desconto = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_frete"
                  label="Valor Frete"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_frete = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_pis"
                  label="Valor PIS"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_pis = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_ipi"
                  label="Valor IPI"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_ipi = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="valor_cofins"
                  label="Valor COFINS"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.valor_cofins = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="icms_aliquota"
                  label="ICMS Aliquota"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.icms_aliquota = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_base_calculo"
                  label="ICMS Base Cálculo"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.icms_base_calculo = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_valor_total"
                  label="ICMS Valor Total"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.icms_valor_total = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_aliquota_st"
                  label="ICMS Aliquota ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.icms_aliquota_st = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_base_calculo_st"
                  label="ICMS Base Cálc. ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.icms_base_calculo_st = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="icms_valor_total_st"
                  label="ICMS Valor Total ST"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.icms_valor_total_st = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="2">
                <Input
                  name="issqn_aliquota"
                  label="ISSQN Aliquota"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) => (data.issqn_aliquota = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="issqn_base_calculo"
                  label="ISSQN Base Cálculo"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.issqn_base_calculo = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="issqn_valor_total"
                  label="ISSQN Valor Total"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.issqn_valor_total = e.target.value)}
                />
              </Col>
              <Col md="2">
                <Input
                  name="pis_aliquota_porcentual"
                  label="PIS Aliq. Porcent."
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) =>
                    (data.pis_aliquota_porcentual = e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="cofins_aliquota_porcentual"
                  label="COFINS Aliq. Porc."
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) =>
                    (data.cofins_aliquota_porcentual = e.target.value)
                  }
                />
              </Col>
              <Col md="2">
                <Input
                  name="modalidade_frete"
                  label="Modalidade Frete"
                  type="text"
                  fontSize={{ fontSize: 10 }}
                  onChange={(e) => (data.modalidade_frete = e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Input
                  name="informacoes_adicionais_contribuinte"
                  label="Informações Adicionais"
                  type="text"
                  fontSize={{ fontSize: 12 }}
                  onChange={(e) =>
                    (data.informacoes_adicionais_contribuinte = e.target.value)
                  }
                />
              </Col>
            </Row>
          </Form>

          <Row>
            <Col
              style={{
                borderBottom: '1px solid #EBEBEB',
                marginBottom: 5,
                paddingTop: 10,
              }}
            >
              <Form>
                <h5>Produtos:</h5>
                <CheckboxInput
                  name="all_item_select"
                  label={`Selecionar todos`}
                  onChange={(e) => checkAll()}
                  value={allChecked}
                />
              </Form>
            </Col>
          </Row>
          {nfeReq && nfeReq?.itens && nfeReq?.itens.length > 0 ? (
            nfeReq?.itens.map((item) => (
              <Form
                initialData={itens[parseInt(item?.numero_item) - 1] || []}
                style={{ paddingTop: 15, borderBottom: '1px solid #EBEBEB' }}
              >
                <Row>
                  <Col>
                    <CheckboxInput
                      name="item_select"
                      id={`item_select_${item.numero_item}`}
                      label={`Produto #${item.numero_item}`}
                      checked={
                        (itens &&
                          itens[parseInt(item.numero_item) - 1]?.send) ||
                        false
                      }
                      onChange={(e) =>
                        changeSend(parseInt(item.numero_item) - 1)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input
                      name="codigo_produto"
                      label="Cód. Prod."
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).codigo_produto = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="4">
                    <Input
                      name="descricao"
                      label="Descrição"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).descricao = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="quantidade_comercial"
                      label="Qnt. Comercial"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).quantidade_comercial = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="quantidade_tributavel"
                      label="Qnt. Tributavel"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).quantidade_tributavel = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="codigo_ncm"
                      label="NCM"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).ncm = e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <Input
                      name="valor_unitario_comercial"
                      label="Valor Un. Comercial"
                      type="text"
                      fontSize={{ fontSize: 9 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).valor_unitario_comercial = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_unitario_tributavel"
                      label="Valor Un. Tributavel"
                      type="text"
                      fontSize={{ fontSize: 9 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).valor_unitario_tributavel = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="valor_bruto"
                      label="Valor Bruto"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).valor_bruto = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_comercial"
                      label="Un. Comercial"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).unidade_comercial = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="unidade_tributavel"
                      label="Un. Tributável"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).unidade_tributavel = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="cfop"
                      label="CFOP"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).cfop = e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Input
                      name="pis_situacao_tributaria"
                      label="PIS Situação Trib."
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).pis_situacao_tributaria = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      name="valor_frete"
                      label="Valor Frete"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).valor_frete = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      name="valor_desconto"
                      label="Valor Desconto"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).valor_desconto = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      name="icms_origem"
                      label="ICMS Origem"
                      type="text"
                      fontSize={{ fontSize: 12 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).icms_origem = e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Input
                      name="icms_situacao_tributaria"
                      label="ICMS Situação Trib."
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).icms_situacao_tributaria = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      name="icms_modalidade_base_calculo"
                      label="ICMS Mod. B. Calc."
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).icms_modalidade_base_calculo = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="icms_base_calculo"
                      label="ICMS Base Calc."
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).icms_base_calculo = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="icms_valor"
                      label="ICMS Valor"
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).icms_valor = e.target.value)
                      }
                    />
                  </Col>
                  <Col md="2">
                    <Input
                      name="cest"
                      label="CEST"
                      type="text"
                      fontSize={{ fontSize: 10 }}
                      disabled={
                        !itens?.find(
                          (it) => it.numero_item === item.numero_item
                        )?.send || false
                      }
                      onChange={(e) =>
                        (itens.find(
                          (it) => it.numero_item === item.numero_item
                        ).cest = e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Form>
            ))
          ) : (
            <p style={{ color: 'red' }}>Nota sem itens.</p>
          )}
          {mensagemSefaz !== '' && (
            <Row style={{ marginTop: 10 }}>
              <h5 style={{ color: 'red' }}>
                <strong>Mensagem SEFAZ:</strong> {mensagemSefaz}
              </h5>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle()}>
            Fechar
          </Button>
          <Button
            color="success"
            onClick={() => handleDevolucao()}
            disabled={loadingNfe}
          >
            {loadingNfe ? 'Aguarde' : 'Enviar'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
