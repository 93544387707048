import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35%;
  bottom: 90px;
  margin-top: auto;
  margin-bottom: auto;
  border-bottom: 1px solid #f8f8f8;
  border-top: 1px solid #33333330;
`;

export const HeaderAction = styled.div`
  padding-bottom: 14px;
  background: #fff;

  input {
    width: 340px;
    height: 48px;
    color: #fb4a20;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #fb4a20cc;
    margin-top: 40px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

    &::placeholder {
      text-align: center;
      color: #fb4a20cc;
      margin-top: 40px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #878282;
  background: #fff;
  padding: 10px;
  padding-top: 13px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #fb4a20;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  border-radius: 7px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 15px;
  width: 100%;
`;

export const ItemListHeader = styled.div`
  color: #333;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const Footer = styled.footer`
  height: 48px;

  width: 100%;
  padding-right: 20px;

  position: absolute;
  bottom: 0;

  button {
    height: 48px;
    width: 100%;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px 7px 0 0;
    border: none;
  }
`;

export const RouterDomLink = styled(Link)`
  text-decoration: none;
  color: ${shade(0.1, '#333333')};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const ClientButton = styled.button`
  text-decoration: none;
  // color: ${shade(0.1, '#333333')};
  display: flex;
  border: none;
  color: #61666B;
  background: transparent;
  margin-bottom: -20px;
  :focus {
    outline: none;
  }
  svg {
    margin-right: 4px;
  }
`;

export const ReturnButton = styled.button`
  text-decoration: underline;
  color: ${shade(0.1, 'blue')};
  text-align: center;
  font-size: 13px;
  border: none;
  background: transparent;
  :focus {
    outline: none;
  }
  svg {
    margin-right: 4px;
  }
`;

export const ButtonDeletePhone = styled.button`
  width: 90px;
  border: 1px solid #fb4a20;
  background: #fb4a20;
  border-radius: 7px;
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
`;

export const UserDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    display: flex;

    p {
      margin-left: 5px;
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-top: 20px;

  & + div {
    margin-top: 20px;
    border-top: 1px solid #33333320;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
    margin-right: 15px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 490px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: #fb4a20;
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
    border: 1px solid #fb4a20;
    border-radius: 14px;
  }
`;

export const MenuItemQuantity = styled.div`
  display: flex;
  width: 150px;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  p {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fb4a20;
  }
`;

export const MakingOrder = styled.div`
  width: 500px;
  height: 500px;
  background: #fb4a20;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;
