import React, {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import api from '~/services/api';
import { toast } from 'react-hot-toast';
import { useAuth } from '../AuthContext';
import Axios from 'axios';
import { uzapiNewApiList } from '~/utils/uzapiNewApiList';

const UzapiContext = createContext();

const UzapiProvider = ({ children }) => {
  const { user, websocketManager } = useAuth();
  const [wppConnected, setWppConnected] = useState(null);
  const [wppQrcode, setWppQrcode] = useState(null);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [showReconnect, setShowReconnect] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const checkWppStatus = async () => {
    const response = await api.post('/restaurants/uzapi/status', {
      sessionkey: user.name,
    });

    const connected =
      response.data.state === 'CONNECTED' &&
      response.data.status === 'inChat';

    setWppConnected(connected);

    localStorage.setItem(
      '@gddashboard:wppConnected',
      JSON.stringify(connected)
    );

    return response.data;
  };

  const logoutWpp = async () => {
    setLoadingQrCode(true);
    const response = await api.post('/restaurants/uzapi/logout', {
      sessionkey: user.name,
    });

    if (response.data.status === 200) {
      setWppConnected(false);
      localStorage.setItem('@gddashboard:wppConnected', JSON.stringify(false));
      setWppQrcode(null);
    }

    setLoadingQrCode(false);
  };

  const getQrCodeWpp = useCallback(async () => {
    setLoadingQrCode(true);
    try {
      const status = await checkWppStatus();
      if (
        status.state !== 'CONNECTED' ||
        status.status !== 'inChat' ||
        status.status === 'qrReadError'
      ) {
        const response = await api.post('/restaurants/uzapi/login', {
          sessionkey: user.name,
        });

        if (response.data.result !== 'success') {
          toast.error(
            'Não foi possível fazer o login no whatsapp. Tente novamente em alguns segundos.'
          );
          setLoadingQrCode(false);

          return;
        }

        if (
          response.data.state === 'CONNECTED' &&
          response.data.status === 'inChat'
        ) {
          setWppConnected(true);
          localStorage.setItem(
            '@gddashboard:wppConnected',
            JSON.stringify(true)
          );
          setLoadingQrCode(false);
          return;
        }else{
          setWppConnected(false);
          localStorage.setItem(
            '@gddashboard:wppConnected',
            JSON.stringify(false)
          );
        }

        if (response.data.state === 'STARTING') {
          return;
        }

        const urlToUse = user.uzapi_url || 'https://takeatcrm.uzapi.com.br:3333';

        const qrCode = await Axios.get(
          `${urlToUse}/getQrCode?session=${user.name}&sessionkey=${user.name}`,
          { responseType: 'blob' }
        );

        const url = URL.createObjectURL(qrCode.data);

        setWppQrcode(url);
      }
    } catch (err) {
      console.log('qrcode error > ', err);

      toast.error('Erro ao carregar QRCode, clique para gerar um novamente');
    }
    setLoadingQrCode(false);
  }, [user]);

  // useEffect(() => {
  //   if (user?.use_wpp && websocketManager?.socket) {
  //     websocketManager.addMessageCallback('uzapi_status', (data) => {
  //       console.log('uzapi status data > ', data);
  //       const connected = data.state === 'CONNECTED';

  //       setWppConnected(connected);
  //       localStorage.setItem(
  //         '@gddashboard:wppConnected',
  //         JSON.stringify(connected)
  //       );
  //     });
  //   }
  // }, [websocketManager]);

  useEffect(() => {
    if (user?.use_wpp) {
      checkWppStatus();
      window.addEventListener('click', () => setShowReconnect(false));
    }
  }, []);

  useEffect(() => {
    if (user?.use_wpp) {
      setWppConnected(null);
      checkWppStatus();
    }
  }, [user]);

  const value = useMemo(
    () => ({
      wppConnected,
      setWppConnected,
      wppQrcode,
      setWppQrcode,
      showReconnect,
      setShowReconnect,
      loadingQrCode,
      setLoadingQrCode,
      getQrCodeWpp,
      logoutWpp,
    }),
    [
      wppConnected,
      setWppConnected,
      wppQrcode,
      setWppQrcode,
      showReconnect,
      setShowReconnect,
      loadingQrCode,
      setLoadingQrCode,
      getQrCodeWpp,
      logoutWpp,
    ]
  );

  return (
    <UzapiContext.Provider value={value}>{children}</UzapiContext.Provider>
  );
};

function useUzapi() {
  const context = useContext(UzapiContext);

  if (!context) {
    throw new Error('useUzapi must be used within an UzapiProvider');
  }

  return context;
}

export { useUzapi, UzapiProvider };
