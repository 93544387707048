import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 100%;
  position: relative;
`;

export const ProductAreaHeader = styled.div`
  color: #ff2c3a;

  svg {
    color: #ff2c3a;
  }

  span {
    margin-left: 8px;
  }
`;

export const ProductArea = styled.div`
  width: 100%;

  height: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
`;

export const ButtonGoBack = styled.button`
  border: none;
  background: transparent;
  color: #ff2c3a;

  svg {
    color: #ff2c3a;
  }

  span {
    margin-left: 8px;
    color: #ff2c3a;
  }
`;

export const MakingOrder = styled.div`
  width: 500px;
  height: 500px;
  background: #ff2c3a;
  color: #fff;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
  }

  img {
    width: 92px;
  }
`;

export const HeaderAction = styled.div`
  padding-right: 10px;
  background: #fff;
  width: 100%;
  height: 90px;
  margin-top: 26px;

  z-index: 10;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    > div {
      font-size: 14px;
    }
  }
  nav {
    display: inline-flex;
    width: 100%;
    padding-left: 10px;

    .scroll-container {
      display: flex;
      align-content: center;
      padding-bottom: 15px;
    }
  }
`;

export const RecentlyProduct = styled.div``;

export const RecentlyProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 16px;
  font-weight: bold;
  height: 32px;

  p {
    padding-top: 14px;
  }
`;

export const Content = styled.div`
  width: 100%;

  height: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  padding-bottom: 76px;

  overflow-y: scroll;
`;

export const Footer = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 65px;
  background: #fff;

  border-top: 1px solid #33333310;
  > div {
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      border: none;
      background: transparent;
      svg {
        margin-bottom: 4px;
      }
    }
    span {
      font-size: 12px;
      color: #333;
      margin-top: -20px;
    }
    img {
      width: 26px;
    }
  }
  > div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const ButtonGoToAccount = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #333;
  svg {
    margin-bottom: 4px;
  }
`;

export const MenuContent = styled.div`
  padding: 8px;
  background: #fff;
  border-radius: 7px;
`;

export const MenuItem = styled.li`
  list-style: none;
  padding: 5px;
`;

export const Badge = styled.div`
  font-size: 10px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );

  max-width: 100px;
  padding: 8px;
  min-height: 25px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: bold;
`;

export const AddToCart = styled.div`
  width: 100%;
  background: transparent;
  text-decoration: none;
  list-style: none;
  border: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.4s;
  border-radius: 7px;

  &:hover {
    background-color: #33333310;
  }

  > div {
    display: flex;

    img {
      width: 52px;
      height: 52px;
      border-radius: 7px;
      margin-right: 5px;
      ${(props) =>
        props.disable === 'true' &&
        css`
          filter: opacity(40%);
        `}
    }
  }
`;

export const ButtonGoToCart = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border-radius: 50%;
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans- 'se' rif;
  background: #fff;
  width: 22px;
  height: 22px;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItemHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 5px;
  margin-top: 16px;

  div {
    font-size: 16px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 5px;
      }
      > p {
        font-size: 12px;
      }
    }
    svg {
      margin-left: 5px;
    }
  }
  strong {
    font-weight: 700;
    margin-left: 8px;
  }
`;

export const MenuItemBody = styled.div`
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & + div {
    margin-top: 8px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 190px;
  margin-left: 8px;
  color: #333;
  strong {
    font-weight: 900;
  }
  p {
    font-size: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.disable === 'true' &&
    css`
      filter: opacity(30%);
    `}
`;

export const MenuItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;

  p {
    font-size: 14px;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;

    & + p {
      margin-top: -16px;
    }
  }
`;

export const ButtonAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;

export const MenuItemPromotion = styled.p`
  color: red;
  text-decoration: line-through;

  filter: opacity(60%);
`;

export const SoldOff = styled.div`
  color: red;
  font-size: 14px;
  font-weight: bold;

  margin-top: 8px;
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const RestaurantClosed = styled.div`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 30px;
  top: 113px;
  background-color: rgba(251, 74, 32, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    color: #fff;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 100%;
  position: relative;

  input {
    width: 300px;
    font-size: 12px;
    background: #fff;
    color: rgba(251, 74, 32, 1);
    border: 2px solid rgba(251, 74, 32, 1);
    margin-top: -10px;
    border-radius: 7px;
    padding: 8px;
    padding-left: 28px;
    ::placeholder {
      color: rgba(251, 74, 32, 1);
    }
  }
`;

export const ButtonDeleteInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 280px;
  bottom: 12px;
`;

export const ButtonSearchInput = styled.button`
  background: transparent;
  border: none;

  border-radius: 8px;
  position: absolute;
  left: 10px;
  bottom: 14px;
`;

export const InputTableNumber = styled.input`
  position: absolute;
  left: 70px;
  border: solid 1px #33333340;
  border-radius: 7px;
  width: 150px;
  padding: 7px;
  text-align: center;
`;
