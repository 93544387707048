import {
  Badge,
  ButtonsDiv,
  Container,
  HeaderButton,
  Logo,
  NotificationBell,
  PendingNotification,
  RestaurantInfo,
  RightButtonsDiv,
  WppModalText,
  WppReconnect,
} from './styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import logo from '../../assets/img/logo_red.png';
import { Button, Hyperlink, ToggleButton, Tooltip } from 'ui-kit-takeat';
import {
  FaCalendarCheck,
  FaMotorcycle,
  FaPowerOff,
  FaQrcode,
  FaRegBell,
  FaUtensils,
  FaWhatsapp,
} from 'react-icons/fa';

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import api from '~/services/api';
import { NotificationPannel } from '../NotificationPannel';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useUzapi } from '~/context/UzapiContext';
import { FiCopy } from 'react-icons/fi';
import ImgToPrint from '../ImgToPrint';
import { useTables } from '~/context/TablesContext';
import { toJpeg } from 'html-to-image';

export const NewHeader = ({}) => {
  const {
    signOut,
    user,
    changeRestaurantStatus,
    updateDeliveryStatus,
    updateWithdrawalStatus,
    notificationsOpen,
    setNotificationsOpen,
    generalNotifications,
    setGeneralNotifications,
  } = useAuth();

  const { ifoodStatus, pendingBaskets } = useOrder();
  const basketCount =
    pendingBaskets.filter((basket) => basket.table.table_type === 'delivery')
      .length || 0;

  const {
    wppConnected,
    wppQrcode,
    showReconnect,
    setShowReconnect,
    loadingQrCode,
    getQrCodeWpp,
    logoutWpp,
  } = useUzapi();

  const { tableMenu } = useTables();

  const [opened, setOpened] = useState(user.opened);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [permission, setPermission] = useState(true);
  const [deliveryOpened, setDeliveryOpened] = useState(
    user?.delivery_info?.is_delivery_active
  );
  const [withdrawalOpened, setWithdrawalOpened] = useState(
    user?.delivery_info?.is_withdrawal_active
  );
  const [ifoodOpen, setIfoodOpen] = useState(user.ifood_open);
  const [notiIds, setNotiIds] = useState([]);
  const [newsRead, setNewsRead] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:newsRead'))
  );
  const history = useHistory();
  const [codeCopyed, setCodeCopyed] = useState(false);
  const [isModalQrcodeOpened, setIsModalQrcodeOpened] = useState(false);

  const inputRef = useRef(null);
  const inputDeliveryRef = useRef(null);
  const imgRef = useRef(null);

  const tableUrl = tableMenu
    ? `${tableMenu.table_code?.url}?q=${Date.now()}`
    : '';

  const toggleModalQrcode = () => {
    setIsModalQrcodeOpened(!isModalQrcodeOpened);
    setCodeCopyed(false);
  };

  function toggleDropDown() {
    setIsDropDownOpen(!isDropDownOpen);
  }

  function copyUrlCode() {
    const textoCopiado = inputRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  function copyDeliveryUrlCode() {
    const textoCopiado = inputDeliveryRef.current;
    textoCopiado.select();
    textoCopiado.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setCodeCopyed(true);
  }

  async function handleCloseRestaurant() {
    if (user?.delivery_info?.is_delivery_allowed) {
      setIsDropDownOpen(true);
    } else {
      const response = await changeRestaurantStatus({
        opened: !opened,
      });

      setOpened(response.opened);
    }
  }

  async function handleRestaurantStatusChange() {
    const response = await changeRestaurantStatus({
      opened: !opened,
      is_delivery_active: !!deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_delivery_active, is_withdrawal_active } = response.delivery_info;

    setOpened(response.opened);
    setDeliveryOpened(is_delivery_active);
    setWithdrawalOpened(is_withdrawal_active);
  }

  async function handleDeliveryStatusChange() {
    const response = await updateDeliveryStatus({
      opened: !!opened,
      is_delivery_active: !deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  async function handleWithdrawalStatusChange() {
    const response = await updateWithdrawalStatus({
      is_withdrawal_active: !withdrawalOpened,
      opened: !!opened,
      is_delivery_active: !!deliveryOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  const downloadPng = useCallback(() => {
    if (imgRef.current === null) {
      return;
    }

    toJpeg(imgRef.current, { cacheBust: true, fontEmbedCSS: false })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'cardapio_digital_takeat.jpg';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [imgRef]);

  const getNotifications = async () => {
    const res = await api.get('/restaurants/notifications');

    setGeneralNotifications(res.data);
    const fixedIds = res.data.fixed.map((f) => f.id);
    const allIds = res.data.all.map((a) => a.id);
    setNotiIds(fixedIds.concat(allIds));
  };

  const checkNotificationsRead = () => {
    return notiIds.some((nid) => !newsRead?.includes(nid));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (!notificationsOpen) {
      setNewsRead(JSON.parse(localStorage.getItem('@gddashboard:newsRead')));
    }
  }, [notificationsOpen]);

  return (
    <Container>
      <ButtonsDiv>
        {!user.only_delivery && (
          <HeaderButton onClick={() => history.push('/operation')}>
            <FaUtensils size={20} />
            <span>Operação</span>
          </HeaderButton>
        )}
        {(user.delivery_info?.is_delivery_allowed ||
          user.delivery_info?.is_withdrawal_allowed) && (
          <HeaderButton onClick={() => history.push('/delivery/orders')}>
            <FaMotorcycle size={22} />
            <span>Delivery</span>
            {basketCount > 0 && <Badge>{basketCount}</Badge>}
          </HeaderButton>
        )}
        <HeaderButton onClick={toggleModalQrcode}>
          <FaQrcode size={18} />
          <span>Divulgar cardápio</span>
        </HeaderButton>
        <HeaderButton onClick={() => history.push('/tasks')}>
          <FaCalendarCheck size={20} />
          <span>Tarefas</span>
        </HeaderButton>
      </ButtonsDiv>
      <Logo src={logo} alt="logo" />
      <RightButtonsDiv>
        {user.use_wpp && wppConnected !== null && (
          <div style={{ position: 'relative' }}>
            <Hyperlink
              fontSize={16}
              icon={<FaWhatsapp />}
              color={!wppConnected && '#ff2c3a'}
              onClick={(e) => {
                setShowReconnect(!showReconnect);
                if (!showReconnect && !wppConnected) {
                  getQrCodeWpp();
                }
                e.stopPropagation();
              }}
            >
              {wppConnected ? 'Conectado' : 'Desconectado'}
            </Hyperlink>
            {wppConnected ? (
              <WppReconnect
                show={showReconnect}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  isLoading={loadingQrCode}
                  disabled={loadingQrCode}
                  type="negative"
                  title="Desconectar"
                  onClick={() => logoutWpp()}
                />
              </WppReconnect>
            ) : (
              <WppReconnect
                show={showReconnect}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  onClick={() => getQrCodeWpp()}
                  isLoading={loadingQrCode}
                  type="default"
                  title={wppQrcode ? 'Gerar novo QR Code' : 'Iniciar conexão'}
                  disabled={loadingQrCode}
                />
                <WppModalText>
                  <h1>
                    Siga as instruções para ler o{' '}
                    <span style={{ color: '#2EC9B7' }}>QR Code</span> e efetivar
                    a conexão:
                  </h1>
                  <ol>
                    <li>
                      <p>
                        Abra seu WhatsApp e vá nos três pontinhos (no Android)
                        ou em “configurações” (no Iphone);
                      </p>
                    </li>
                    <li>
                      <p>
                        Clique no botão <b>“Dispositivos Vinculados”</b> e leia
                        o QR Code. Caso apareça uma mensagem de erro, clique em{' '}
                        <b>”Gerar novo QR Code”</b> e tente novamente;
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizada a conexão, constará no seu celular o
                        dispositivo com nome <b>“Uzapi”</b>.
                      </p>
                    </li>
                  </ol>
                </WppModalText>

                {wppQrcode &&
                  (loadingQrCode ? (
                    <div
                      style={{
                        width: '264px',
                        height: '264px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <img src={wppQrcode} />
                  ))}
              </WppReconnect>
            )}
          </div>
        )}

        <NotificationBell
          onClick={(e) => {
            setNotificationsOpen(true);
            e.stopPropagation();
          }}
        >
          <FaRegBell />
          {checkNotificationsRead() && <PendingNotification />}
        </NotificationBell>
        <AnimatePresence>
          {notificationsOpen && (
            <NotificationPannel
              setNotificationsOpen={setNotificationsOpen}
              notifications={generalNotifications}
            />
          )}
        </AnimatePresence>

        {permission &&
        (user?.delivery_info?.is_delivery_allowed ||
          user?.delivery_info?.is_withdrawal_allowed) &&
        user.is_active ? (
          <Dropdown toggle={toggleDropDown} isOpen={isDropDownOpen}>
            <DropdownToggle tag="span">
              <Button
                icon={<FaPowerOff />}
                title={user.opened ? 'Fechar' : 'Abrir'}
                type="negative"
                height={40}
                onClick={handleCloseRestaurant}
              />
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem header>
                <RestaurantInfo>
                  <ToggleButton
                    clickFunction={handleRestaurantStatusChange}
                    value={opened}
                  />
                  <span>Restaurante</span>
                </RestaurantInfo>
              </DropdownItem>

              {user?.delivery_info?.is_delivery_allowed && (
                <DropdownItem header>
                  <RestaurantInfo>
                    {' '}
                    <ToggleButton
                      clickFunction={handleDeliveryStatusChange}
                      value={deliveryOpened}
                    />
                    <span>Delivery</span>
                  </RestaurantInfo>
                </DropdownItem>
              )}

              {user?.delivery_info?.is_withdrawal_allowed && (
                <DropdownItem header>
                  <RestaurantInfo>
                    <ToggleButton
                      clickFunction={handleWithdrawalStatusChange}
                      value={withdrawalOpened}
                    />
                    <span>Retirada</span>
                  </RestaurantInfo>
                </DropdownItem>
              )}
              {user?.has_ifood &&
                Array.isArray(ifoodStatus) &&
                ifoodStatus?.map((status) => (
                  <DropdownItem>
                    <p style={{ textAlign: 'center' }}>
                      {status.ifood_restaurant}
                    </p>
                    <Tooltip
                      title={
                        status.ifood_open ? (
                          <p style={{ color: 'white', fontSize: 16 }}>
                            Restaurante aberto no iFood
                          </p>
                        ) : (
                          <div>
                            <p style={{ color: 'white', fontSize: 16 }}>
                              {status?.message?.title},{' '}
                              {status?.message?.subtitle}
                            </p>
                            <span style={{ color: 'white', fontSize: 16 }}>
                              {status?.message?.description}
                            </span>
                          </div>
                        )
                      }
                      placement="right"
                      style={{
                        margin: '0 auto',
                        width: 'fit-content',
                        color: status.ifood_open ? 'green' : 'red',
                      }}
                      arrow
                    >
                      <p>{status.ifood_open ? 'Aberto' : 'Fechado'}</p>
                    </Tooltip>
                    <RestaurantInfo />{' '}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        ) : (
          user.is_active && (
            <Button
              icon={<FaPowerOff />}
              title={user.opened ? 'Fechar' : 'Abrir'}
              type="negative"
              height={40}
              onClick={handleCloseRestaurant}
            />
          )
        )}
        <Button
          title="Sair"
          type="negative"
          height={40}
          inverted
          onClick={signOut}
        />
      </RightButtonsDiv>

      <Modal isOpen={isModalQrcodeOpened} toggle={toggleModalQrcode}>
        <ModalHeader>
          <strong style={{ fontSize: 16 }}>Cardápio Digital</strong>
        </ModalHeader>
        <ModalBody>
          <p>
            Compartilhe seu Cardápio em suas redes sociais e Whatsapp clicando
            no ícone "Copiar” <FiCopy color="FF2C3A" /> . Salve a imagem ou
            copie o link. Aproveite!
          </p>

          <div
            style={{
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',

                alignItems: 'center',

                width: '100%',
              }}
            >
              <strong>Cardápio Digital (SOMENTE VISUALIZAÇÃO)</strong>

              <FiCopy
                color="FF2C3A"
                onClick={copyUrlCode}
                size={18}
                style={{ cursor: 'pointer', marginLeft: 8 }}
                title="Copiar link"
              />
            </div>
            {tableMenu && (
              <input
                id="url-qrcode"
                ref={inputRef}
                value={`https://cardapio.takeat.app/${user.name}`}
                type="Text"
                style={{ border: 'none', width: '80%' }}
              />
            )}
          </div>

          {(user?.delivery_info?.is_delivery_allowed ||
            user?.delivery_info?.is_withdrawal_allowed) && (
            <div
              style={{
                marginBottom: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  width: '100%',
                }}
              >
                <strong>Cardápio Digital (PEDIDOS)</strong>
                <FiCopy
                  color="FF2C3A"
                  onClick={copyDeliveryUrlCode}
                  size={18}
                  style={{ cursor: 'pointer', marginLeft: 8 }}
                  title="Copiar link"
                />
              </div>

              <input
                id="url-delivery"
                ref={inputDeliveryRef}
                value={`https://pedido.takeat.app/${user.name}`}
                type="Text"
                style={{ border: 'none', width: '80%' }}
              />
            </div>
          )}

          {tableMenu && (
            <>
              <ImgToPrint
                ref={imgRef}
                imgSrc={tableUrl}
                tableMenu={tableMenu}
              />

              <Button
                width="100%"
                marginTop={10}
                type="negative"
                onClick={downloadPng}
                title="Baixar imagem"
              />
            </>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};
