import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { Table } from 'reactstrap';
import Input from '~/components/Form/Input';

export const BillExplanation = styled.div`
  width: 90%;
  margin-left: 2%;
  margin-top: 5px;
`;

export const PaymentMethodButton = styled.button`
  max-width: 100px;
  width: 100px;
  height: 40px;
  color: #333;
  border: none;
  border-radius: 7px;
  transition: all 0.5s;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-size: 12px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    border: 1px solid red;
  }
`;

export const InformationDiv = styled.div`
  width: 90%;

  margin-left: 2%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyled = styled.button`
  margin-right: 8px;
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.operationType === 'deposit' &&
    css`
      background-color: #28a745;
    `}

  ${(props) =>
    props.operationType === 'withdrawal' &&
    css`
      background-color: #ff403d;
    `}

    ${(props) =>
      props.operationType === 'report' &&
      css`
        background-color: #33333370;
      `}

      &:hover {
        filter: opacity(0.9);
      }
`;
export const FinalDiv = styled.div`
  width: 90%;
  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const Paid = styled.div`
  right: 0;

  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff6b3;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  margin-right: 10px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const PaymentDiv = styled.div`
  width: 90%;
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PaymentTable = styled.table`
  width: 100%;
  margin-top: 10px;

  tr {
    font-size: 18px;
  }
`;

export const AddPaymentButton = styled.span.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  width: 60%;
  &:hover {
    text-decoration: none;
  }
  span {
    color: ${(props) => props.color};
  }
`;

export const AddPaymentForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;

  height: 120px;
`;

export const AddPaymentInput = styled(Input)`
  width: 90px;
  padding: 5px;
  font-size: 15px;
  border-radius: 10px;
  border-color: green;
  border-effect: none;
`;

export const AddPaymentLink = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
  float: right;
  font-size: 24px;
  background-color: #28a745;
  text-align: center;
  color: white;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border: none;
  }
`;
export const DeletePaymentForm = styled(Link)`
  padding-bottom: 5px;
  color: red;
`;

export const InputButton = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 40px;
`;
