import React from 'react';
import { RiKnifeLine } from 'react-icons/ri';
import { Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';
import OrderSchedulingAndamento from '../../Items/OrderSchedulingAndamento ';

export default function CardOrdersSchedulingAndamento({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleItemReady,
  handleUpdate,
}) {
  const count_orders = baskets.filter((order) => order?.scheduled_to).length;
  return (
    <Bloco>
      <Header>
        <Icon>
          <RiKnifeLine color="#fff" size={25} />
        </Icon>
        EM ANDAMENTO
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            ?.filter((order) => order?.scheduled_to)
            .map((item) => (
              <OrderSchedulingAndamento
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
