import styled from 'styled-components';
import { lighten } from 'polished';

export const DeleteAndEditActions = styled.div`
  display: flex;
  align-self: center;
  margin-top: auto;
  flex-direction: column;
  height: 30px;
  margin: auto;
  float: right;
  right: 0;
  position: absolute;
  clear: both;
`;

export const Eye = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 15px;
  border: none;
  background: white;
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const DoneButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #28a745;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;

  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 35px;
  position: relative;

  &::before {
    content: ' ';
    display: inline-block;
    position: absolute;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;
