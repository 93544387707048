import styled, { css } from 'styled-components';
import Select from '~/components/Form/SelectInput';

export const Container = styled.div`
  padding: ${props => props.page !== "/operation" ? '20px' : '0px'};
  padding-bottom: 120px;


`;

export const SelectStyled = styled(Select)`
  margin-top: -20px;
`;

export const Card = styled.div`
  width: 100%;
  height: 740px;
  padding: ${props => props.page !== "/operation" ? '15px' : '15px 15px 130px 0'};
  border-radius: 7px;
  background-color: #fff;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;

  @media(max-width: 1370px){
    height: 390px;
  }

  @media(min-width: 1371px) and (max-width: 1441px){
    height: 490px;
  }
`;

export const ButtonArea = styled.div`
  width: 100%;
  margin-top: ${props => props.canAccessAllCashiers ? '16px' : '0px'};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: ${props => props.canAccessAllCashiers ? '48px' : '36px'};
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;

  height: 40%;
  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const TotalArea = styled.div`
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    height: 50px;
    width: 48%;
    margin-left: 10px;

 

  @media(min-width: 1000px) and (max-width: 1441px){
    strong{
      font-size:  ${props => props.page !== "/operation" ? '14px' : '12px'
  } }

    span{
      font-size:  ${props => props.page !== "/operation" ? '14px' : '12px'
  }

  }
  }
`
export const Button = styled.button`
  margin-right: 8px;
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }

  ${(props) =>
    props.operationType === 'deposit' &&
    css`
      background-color: #2EC9B7;
    `}

  ${(props) =>
    props.operationType === 'withdrawal' &&
    css`
      background-color: #FF2C3A;
    `}

    ${(props) =>
    props.operationType === 'report' &&
    css`
        background-color: #33333370;
      `}

      &:hover {
        filter: opacity(0.9);
      }
`;

export const DisplayNoneImprimir = styled.div`
display: none;
`

export const OpenBoxButton = styled.button`
  border: none;
  padding: 8px;
  border-radius: 7px;
  color: #fff;
  background-color: #2EC9B7;
  margin-right: 8px;
  font-size: 14px;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const StyledTable = styled.table`
  width: 32%;

  margin-bottom: 30px;
  border-collapse: separate;
  border-spacing: 0 20px;
  tr {
  
  }
  tbody {
    
  }

  ${props => props.operation && css`
       width: 100%;      `
  }


  @media (max-width: 1601px){
     width: 70%;

     ${props => props.operation && css`
       width: 100%;      `
  }
  }

  
`;

export const TotalValueArea = styled.div`
  border-top: 1px solid #33333320;
  padding-top: 20px;
`;

export const Deposits = styled.div`
  display: flex;
  margin: 8px;

  p {
    margin-right: 16px;
  }
`;
