import React, { useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import Switch from 'react-switch';

import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
  TabPane,
  TabContent
} from 'reactstrap';
import Select from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import InputMask from 'react-input-mask';
import NFeInfo from '~/services/NFeInfo';
import nfeInfo from '~/services/NFeInfo';

import { FaQuestionCircle, FaTrash } from 'react-icons/fa';
import {
  Header,
  Times,
  StyledForm,
  ButtonsArea,
  Button,
  Container,
  AreaSwitcher,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  InnerArea,
  FiscalMessage
} from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';

import { useAuth } from '~/context/AuthContext';
import { useMenu } from '~/context/OrderingSystem/Menu';
import PersonalButton from '~/components/Buttons/PersonalButton';
import CoverInput from '~/components/Form/CoverInput';

function Store({ toggle, getCategories, category, fiscalGroups }) {
  const [startTime, setStartTime] = useState(() => {
    if (category.start_time) {
      const d = new Date(category.start_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  const [endTime, setEndTime] = useState(() => {
    if (category.end_time) {
      const d = new Date(category.end_time);
      const hours = d.getHours().toString().padStart(2, '0');
      const mins = d.getMinutes().toString().padStart(2, '0');

      return `${hours}:${mins}`;
    }

    return null;
  });

  function readDaysSelected(value) {
    const selected = [];

    for (let i = 0; i < 7; i++) {
      if (value[i] === 't') {
        selected.push(i);
      }
    }

    return selected;
  }

  function daysSelectedToStr(selected) {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(selected.includes(i) ? 't' : 'f');
    }

    return days.join('');
  }

  const [weekDays] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);

  const [selectedDays, setSelectedDays] = useState(
    readDaysSelected(category.active_days)
  );

  const [initialData, setInitialData] = useState({
    name: category.name,
    time: category.preparation_time,
    available: category.available,
    is_exclusive: category.is_exclusive,
    available_in_delivery: category.available_in_delivery,
    startTime,
    endTime,
    image_id: {
      id: category.image ? category.image.id : null,
      url: category.image ? category.image.url : null,
    }
  })

  const { getMenu } = useMenu();

  const formRef = useRef(null);
  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState('1');

  async function handleUpdate(data) {
    try {
      const start_time = data.startTime
        ? `2021-01-09T${data.startTime}:00-03:00`
        : null;
      const end_time = data.endTime
        ? `2021-01-09T${data.endTime}:00-03:00`
        : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data inicial inválida').nullable(),
        end_time: Yup.date('Data inicial inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );


      const image_id_parsed = data.image_id ? Number(data.image_id) : null


      await api.put(`/restaurants/product-category/${category.id}`, {
        name: data.name,
        preparation_time: data.time,
        available: availableCategory,
        available_in_delivery: availableDeliveryCategory,
        is_exclusive: data.is_exclusive,
        start_time,
        end_time,
        active_days: daysSelectedToStr(selectedDays),
        image_id: image_id_parsed
      });

      if (category) {
        category.name = data.name;
        category.preparation_time = data.time;
        category.available = availableCategory;
        category.available_in_delivery = availableDeliveryCategory;
        category.is_exclusive = data.is_exclusive;
        category.start_time = start_time;
        category.end_time = end_time;
        category.image_id = data.image_id
      }

      toast.success('Categoria atualizada com sucesso!');
      getMenu();
      getCategories();
      toggle();
    } catch (error) {
      if (error.response.data.errorType === 'only_one_time') {
        toast.error(
          `Favor preencher os tempos dos horários de início e fim das categorias disponíveis.`
        );
      } else {
        toast.error(`Erro ao atualizar categoria!`);
      }
    }
  }

  const onDaySelected = useCallback(
    (selected) => {
      const index = selectedDays.indexOf(selected);

      if (index < 0) {
        selectedDays.push(selected);
      } else {
        selectedDays.splice(index, 1);
      }

      setSelectedDays([...selectedDays]);
    },
    [selectedDays]
  );

  // switchers

  const [availableCategory, setAvailableCategory] = useState(
    category?.available
  );

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] = useState(
    category?.available_in_delivery
  );

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }


  const [deleteCover, setDeleteCover] = useState(false)
  function handleDeleteCover() {
    setDeleteCover(!deleteCover)

    setInitialData(state => {
      return { ...state, image_id: null }
    })
  }

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(null);

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(null);
  const [csosnValue, setCsosnValue] = useState(null);
  const [cfopValue, setCfopValue] = useState(null);
  const [cofinsValue, setCofinsValue] = useState(null);
  const [pisValue, setPisValue] = useState(null);
  const [cestValue, setCestValue] = useState(null);
  const [icmsValorValue, setIcmsValorValue] = useState(null);
  const [baseCalculoValue, setBaseCalculoValue] = useState(null);
  const [aliquotaValue, setAliquotaValue] = useState(null);
  // regime normal
  const [cstValue, setCstValue] = useState(null);
  const [pisAliquotaValue, setPisAliquotaValue] = useState(null);
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(null);
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(null);
  const [cbenefValue, setCbenefValue] = useState(null);
  const [ncm, setNcm] = useState(null);

  const [csosnSelected, setCsosnSelected] = useState(null);
  const [cfopOptions, setCfopOptions] = useState([]);

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setIcmsValorValue(group.icms_valor);
    setAliquotaValue(group.aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };

      if (user.regime_tributario === '1') {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.ncm = ncm || data.ncm;

        if (csosnSelected === '00') {
          parsedData.aliquota = aliquotaValue || data.aliquota;
          parsedData.base_calculo =
            baseCalculoValue?.value || data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      } else {
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;
        parsedData.icms_valor = icmsValorValue || data.icms_valor;
        parsedData.ncm = ncm || data.ncm;

        parsedData.cst = cstValue?.value || data.cst;
        parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
        parsedData.cofins_aliquota =
          cofinsAliquotaValue || data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
        parsedData.cbenef = cbenefValue || data.cbenef;

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      }

      await api.put(`restaurants/product-category/fiscal/${category.id}`, parsedData);
      toast.success('Informações fiscais atualizadas');

    } catch (error) {
      toast.error('Falha ao salvar dados fiscais');
      console.log(error);
    }
  }

  return (
    <Container>
      <Header>
        <div>
          <h3>Editar categoria de produtos</h3>
        </div>
      </Header>

      <div>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                >
                  <NavLinkSpan>Categoria</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  <NavLinkSpan>Fiscal</NavLinkSpan>
                </NavLink>
              </NavItem>
            </Nav>
          </NavDivTeste>
        </NavDiv>
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <StyledForm
            initialData={initialData}
            ref={formRef}
            onSubmit={handleUpdate}
          >
            <Row>
              <Col size="md-6">

                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome da categoria"
                  disabled={category?.is_multistore_child}
                />


                <Row>
                  <Input
                    label="Tempo de preparo"
                    name="time"
                    type="number"
                    placeholder="Tempo (em minutos)"
                  />
                </Row>

              </Col>

              <Col md='6'>
                <p style={{ fontWeight: 'bold' }}>
                  Imagem para capa dessa categoria
                </p>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CoverInput name='image_id' deleteCover={deleteCover} />
                  <FaTrash cursor='pointer' onClick={() => handleDeleteCover()} color='#FF2B3A' style={{ marginLeft: 6 }} />
                </div>

                <p>
                  Caso não queira inserir uma imagem, usaremos a foto do primeiro produto dessa categoria como imagem de capa.
                </p>
              </Col>
            </Row>

            <Row>
              {!user?.only_delivery && (
                <Col md="2">
                  <AreaSwitcher title="Disponível no presencial">
                    <Switch
                      onChange={handleChangeAvailableCategory}
                      draggable
                      checked={availableCategory}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={27}
                      width={44}
                      handleDiameter={16}
                      activeBoxShadow="0 0 2px 3px #111"
                      boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                      onColor="#0CCAB4"
                      offColor="#666666"
                    />
                    <span>Disponível no presencial</span>
                  </AreaSwitcher>
                </Col>
              )}

              {user?.delivery_info?.is_delivery_allowed && (
                <Col md="2">
                  <AreaSwitcher title="Disponível no delivery">
                    <Switch
                      onChange={handleChangeAvailableDeliveryCategory}
                      draggable
                      checked={availableDeliveryCategory}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={27}
                      width={44}
                      handleDiameter={16}
                      activeBoxShadow="0 0 2px 3px #111"
                      boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                      onColor="#0CCAB4"
                      offColor="#666666"
                    />
                    <span>Disponível no delivery</span>
                  </AreaSwitcher>
                </Col>
              )}
              <Col md="2">
                <Checkbox
                  label="Uso exclusivo"
                  name="is_exclusive"
                  disabled={category?.is_multistore_child || false}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <div>
                <b> Dias: </b>
                <FaQuestionCircle
                  color="darkOrange"
                  title="Dias em que que essa categoria ficará disponível para o cliente"
                />

                <ButtonGroup style={{ margin: '10px 0', marginLeft: 10 }}>
                  {weekDays.map((item, i) => (
                    <Button
                      color="info"
                      size="md"
                      tag="label"
                      className="btn-simple"
                      style={{
                        margin: '0 1px',
                        padding: '5px',
                        'font-size': '12pt',
                        filter: selectedDays.includes(i)
                          ? 'opacity(100%)'
                          : 'opacity(40%)',
                      }}
                      onClick={() => onDaySelected(i)}
                      active={selectedDays.includes(i)}
                      type="button"
                    >
                      {item}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </Row>

            <div style={{ marginBottom: -10 }}>
              <b> Horário disponível: </b>

              <FaQuestionCircle
                color="darkOrange"
                title="Determina a faixa de horário em que essa categoria ficará disponível para o cliente realizar o pedido"
              />
            </div>

            {category && (
              <Times>
                <div> das </div>
                <Input
                  type="time"
                  name="startTime"
                  disabled={category?.is_multistore_child || false}
                />
                <div> às </div>
                <Input
                  type="time"
                  name="endTime"
                  disabled={category?.is_multistore_child || false}
                />{' '}
                horas.
              </Times>
            )}

            <ButtonsArea>
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggle();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </TabPane>

        <TabPane tabId="2">
          <StyledForm
            onSubmit={handleSubmitFiscal}
          >
            <InnerArea browserZoomLevel={browserZoomLevel} >
              {
                user.has_nfce &&
                <p>Área destinada a aplicar informações fiscais em massa em todos os produtos dessa categoria. Ao Salvar, as informações fiscais serão aplicadas a todos os produtos dessa categoria, e essa ação não poderá ser desfeita. Portanto, é importante tomar cuidado.</p>
              }
              {user.has_nfce &&
                <Row>
                  <Col sm="12">
                    <Select
                      name="fiscal_group_id"
                      options={fiscalGroups?.map((group) => {
                        return {
                          label: group.title,
                          value: group.id,
                        };
                      })}
                      label="Grupo Fiscal"
                      onChange={(e) => updateFiscalValues(e.value)}
                      value={fiscalGroupDefault}
                    />
                  </Col>
                </Row>}

              {!user.has_nfce && (
                <FiscalMessage>
                  <p>Seu plano atual não contempla a função de fiscal!</p>
                  <span>
                    Entre em contato com nosso time de suporte e faça a
                    ativação.
                  </span>
                </FiscalMessage>
              )}
              {user.has_nfce &&
                (user.regime_tributario === '1' ? (
                  <div>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask
                          mask="99999999"
                          onChange={(e) => {
                            setNcm(e.target.value);
                          }}
                          value={ncm}
                        >
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>
                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          label="ICMS de Origem"
                          value={icmsOrigemValue}
                          onChange={(e) => {
                            setIcmsOrigemValue(
                              icms_origem_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="csosn"
                          options={csosn_options}
                          label="CSOSN"
                          onChange={(e) => {
                            setCsosnSelected(e.value);
                            setCsosnValue(
                              csosn_options.find((x) => x.value === e.value)
                            );
                          }}
                          value={csosnValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                          onChange={(e) => {
                            setCfopValue(
                              NFeInfo.cfop_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cfopValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.cofins_options}
                          label="Cofins"
                          onChange={(e) => {
                            setCofinsValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cofinsValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.cofins_options}
                          label="Pis"
                          onChange={(e) => {
                            setPisValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={pisValue}
                        />
                      </Col>
                    </Row>

                    {(csosnValue?.value === '00' ||
                      aliquotaValue ||
                      baseCalculoValue) && (
                        <div>
                          <Row style={{ paddingTop: 20 }}>
                            <Col sm="12" lg="6">
                              <Input
                                label="Alíquota (Entre 0 e 100)"
                                name="aliquota"
                                type="text"
                                value={aliquotaValue}
                                onChange={(e) => {
                                  setAliquotaValue(e.target.value);
                                }}
                              />
                            </Col>

                            <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                              <Select
                                name="base_calculo"
                                options={base_calculo_options}
                                label="Base Cálculo"
                                value={baseCalculoValue}
                                onChange={(e) => {
                                  setBaseCalculoValue(
                                    base_calculo_options.find(
                                      (x) => x.value === e.value
                                    )
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                    {(csosnValue?.value === '500' || cestValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask
                            mask="9999999"
                            onChange={(e) => {
                              setCestValue(e.target.value);
                            }}
                            value={cestValue}
                          >
                            <Input label="CEST" name="cest" type="text" />
                          </InputMask>
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : (
                  <div>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="4">
                        <InputMask
                          mask="99999999"
                          onChange={(e) => {
                            setNcm(e.target.value);
                          }}
                          value={ncm}
                        >
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>

                      <Col sm="12" lg="4">
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          label="ICMS de Origem"
                          value={icmsOrigemValue}
                          onChange={(e) => {
                            setIcmsOrigemValue(
                              icms_origem_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                        />
                      </Col>

                      <Col sm="12" lg="4">
                        <Input
                          label="Alíquota ICMS (%)"
                          name="icms_aliquota"
                          type="text"
                          value={aliquotaValue}
                          onChange={(e) => {
                            setAliquotaValue(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                          onChange={(e) => {
                            setCfopValue(
                              NFeInfo.cfop_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cfopValue}
                        />
                      </Col>
                      <Col sm="12" lg="6">
                        <Select
                          name="cst"
                          options={NFeInfo.icms_situacao_tributaria}
                          label="CST"
                          onChange={(e) => {
                            setCstValue(
                              NFeInfo.icms_situacao_tributaria.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cstValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.pis_situacao_tributaria}
                          label="PIS"
                          onChange={(e) => {
                            setPisValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={pisValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota PIS (%)"
                          name="pis_aliquota"
                          type="text"
                          onChange={(e) => {
                            setPisAliquotaValue(e.target.value);
                          }}
                          value={pisAliquotaValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.pis_situacao_tributaria}
                          label="COFINS"
                          onChange={(e) => {
                            setCofinsValue(
                              NFeInfo.cofins_options.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={cofinsValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Alíquota COFINS (%)"
                          name="cofins_aliquota"
                          type="text"
                          onChange={(e) => {
                            setCofinsAliquotaValue(e.target.value);
                          }}
                          value={cofinsAliquotaValue}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="icms_modalidade_base_calculo"
                          options={nfeInfo.icms_modalidade_base_calculo}
                          label="Modalidade Base Calculo"
                          onChange={(e) => {
                            setIcmsModalidadeBCValue(
                              NFeInfo.icms_modalidade_base_calculo.find(
                                (x) => x.value === e.value
                              )
                            );
                          }}
                          value={icmsModalidadeBCValue}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Input
                          label="Código de Benefícios Fiscais (cBenef)"
                          name="cbenef"
                          type="text"
                          onChange={(e) => {
                            setCbenefValue(e.target.value);
                          }}
                          value={cbenefValue}
                        />
                      </Col>
                    </Row>

                    {(csosnValue?.value === '500' || cestValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="12">
                          <InputMask
                            mask="9999999"
                            onChange={(e) => {
                              setCestValue(e.target.value);
                            }}
                            value={cestValue}
                          >
                            <Input label="CEST" name="cest" type="text" />
                          </InputMask>
                        </Col>
                      </Row>
                    )}

                    {(cstValue?.value === '00' || icmsValorValue) && (
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="12">
                          <Input
                            label="ICMS Valor"
                            name="icms_valor"
                            type="text"
                            onChange={(e) => {
                              setIcmsValorValue(e.target.value);
                            }}
                            value={icmsValorValue}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}
            </InnerArea>
            <ButtonsArea>
              <PersonalButton
                color="#FF2C3A"
                message="Cancelar"
                style={{
                  marginLeft: 0,
                }}
                onClick={() => {
                  toggle();
                }}
              />

              <PersonalButton
                color="#0CCAB4"
                message="Salvar"
                type="submit"
                style={{
                  marginRight: 10,
                }}
              />
            </ButtonsArea>
          </StyledForm>
        </TabPane>
      </TabContent>
    </Container>
  );
}

export default Store;
