/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Form } from '@unform/web';

import ReactToPrint from 'react-to-print';

import { FaCashRegister, FaCentercode, FaEye, FaPrint } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';

import { toast } from 'react-hot-toast';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';

import { FiX } from 'react-icons/fi';
import ClientArea from './ClientArea';
import Cart from '~/components/OrderingSystem/Cart';
import DefaultButton from '~/components/Buttons/DefaultButton';
import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
  ButtonsArea,
} from './styles';
import { useAuth } from '~/context/AuthContext';
import { useCart } from '~/context/OrderingSystem/Cart';
import api from '~/services/api';
import Menu from '~/pages/OrderingSystem/Menu';
import EyeOrdersBillOperation from '~/components/Tables/EyeOrdersBillOperation';
import Input from '~/components/Form/Input';
import { ButtonAloneArea } from '../TableOperation/styles';
import PrePaidExtract from './PrePaidExtract';

function TableAvailable({
  session,
  getMinimalSessions,
  setMinimalSessions,
  inicialDate,
  finalDate,
  type,
  getTablesAvailable,
  sessionId,
  handleSearchInputTables,
  setCount,
  setInputValue,
  setTablesFilteredSearch,
  setAllTablesAvailable,
  // paymentMethods,
  handleUpdate,
}) {
  const { user, setWaiterId, setBuyerCreatedByWaiter, setBuyerSelectedByWaiter } = useAuth();
  const { closeCart, cart } = useCart();
  const [discountObs, setDiscountObs] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [modalAdvice, setModalAdvice] = useState(false);
  const [client, setClient] = useState(null);

  const [responseNfce, setResponseNfce] = useState(null);
  const [nfWarning, setNFWarning] = useState(false);

  function toggleModalAdvice() {
    setModalAdvice(!modalAdvice);
  }

  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  const [
    isModalVerifyPasswordOpened,
    setIsModalVerifyPasswordOpened,
  ] = useState(false);

  async function toggleModalOperation() {
    if (cart.length > 0) {
      setModalAdvice(true);
    } else if (client) {
      try {
        const data = {
          client_id: client.id,
          table_number: session.table_number,
        };

        await api.post('restaurants/clients/create-session', data);

        toast.success('Comanda aberta com cliente!');
        getMinimalSessions();
        getTablesAvailable();
        setIsModalOperationOpened(!isModalOperationOpened);
        closeCart();
      } catch (err) {
        toast.error('Erro ao abrir comanda');
        console.log(err);
      }
    } else {
      setIsModalOperationOpened(!isModalOperationOpened);
      closeCart();
    }
  }

  function confirmCloseCart() {
    setIsModalOperationOpened(!isModalOperationOpened);
    toggleModalAdvice();
    closeCart();
  }

  function toggleModalVerifyPassword() {
    if (isModalOperationOpened) {
      setIsModalVerifyPasswordOpened(false);
    } else {
      setIsModalVerifyPasswordOpened(!isModalVerifyPasswordOpened);
      setTimeout(() => {
        return document.getElementById('input_waiter_password')?.focus();
      }, 1);
    }
  }

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }
  const tableId =
    session?.status !== 'available' ? session?.table?.id : session?.id;
  const tableNumber =
    session?.status !== 'available'
      ? session?.table?.table_number
      : session?.table_number;

  function verifyWaiterPassword() {
    setBuyerCreatedByWaiter('')
    setBuyerSelectedByWaiter('')
    if (user.ask_waiter_key && user?.is_pdv) {
      toggleModalVerifyPassword();
    } else {
      setIsModalOperationOpened(true);
    }
  }

  async function verifyWaiterPasswordAccepted(data) {
    try {
      const response = await api.get(
        `/restaurants/verify-waiter-key/${data.pdv_key}`
      );

      if (response.data.id) {
        setIsModalOperationOpened(true);
        setWaiterId(response.data.id);
        toggleModalVerifyPassword();
      }
    } catch (error) {
      toast.error('Senha invalida');
    }
  }

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_type === 'delivery') {
      return 'Delivery';
    }

    if (table.table_type === 'command') {
      return `Comanda ${table.table_number}`;
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    return `Mesa ${table.table_number}`;
  }

  const [isModalExtractOpened, setIsModalExtractOpened] = useState(false);

  function toggleExtract() {
    setIsModalExtractOpened(!isModalExtractOpened);
  }

  return (
    <Content
      type={type}
      nullTable={session?.status}
      onClick={type === 'prepaid' ? toggleExtract : verifyWaiterPassword}
    >
      {session?.status !== 'available' ? (
        <>
          <p>{session?.table?.table_number}</p>
        </>
      ) : (
        <p>
          {session?.table_type === 'balcony'
            ? 'Balcão'
            : session?.table_number === -10
              ? 'Copa'
              : session?.table_number}
        </p>
      )}

      {type === 'prepaid' && (
        <p style={{ fontSize: 12, textAlign: 'center' }}>
          Pagamento Antecipado
        </p>
      )}

      <Modal isOpen={isModalExtractOpened} toggle={toggleExtract}>
        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
          <span style={{ float: 'right', padding: 10 }}>
            <FiX
              color="#FF2B3A"
              size={22}
              cursor="pointer"
              onClick={toggleExtract}
            />
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 30,
            }}
          >
            <p
              style={{ textAlign: 'center', color: '#FF2B3A', marginLeft: 40 }}
            >
              Pagamento Antecipado
            </p>
            <h4
              style={{
                textAlign: 'center',
                color: '#FF2B3A',
                fontWeight: 'bold',
                marginLeft: 40,
              }}
            >
              Mesa {session.table_number === -4 ? 'POS' : session.table_number}
            </h4>
          </div>

          <ModalBody>
            <PrePaidExtract tableId={session?.id} />
          </ModalBody>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 25,
            }}
          >
            <DefaultButton onClick={toggleExtract} message="Sair" />
          </div>
        </div>
      </Modal>

      <Modal isOpen={modalAdvice} toggle={toggleModalAdvice}>
        <ModalBody>
          <p>
            Você possui itens no carrinho. Tem certeza que deseja sair sem fazer
            o pedido?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModalAdvice} style={{ background: '#7fd1b9' }}>
            Irei fazer o pedido
          </Button>
          <Button onClick={confirmCloseCart} style={{ background: '#FF2B3A' }}>
            Sim, sair e excluir o carrinho.
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isModalVerifyPasswordOpened}
        toggle={toggleModalVerifyPassword}
      >
        <Form onSubmit={verifyWaiterPasswordAccepted}>
          <ModalBody>
            <Input
              label="Senha de acesso do garçom as mesas do PDV."
              name="pdv_key"
              type="password"
              id="input_waiter_password"
              maxlength={3}
              onChange={(e) => {
                if (e.target.value.length === 3) {
                  verifyWaiterPasswordAccepted({ pdv_key: e.target.value });
                }
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" style={{ background: '#7fd1b9' }}>
              Validar senha
            </Button>
            <Button
              onClick={toggleModalVerifyPassword}
              style={{ background: '#dc3545' }}
            >
              Cancelar.
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="takeat"
      >
        <ModalBody>
          <OperationArea>
            <OrderSystemArea>
              <Menu
                tableId={tableId}
                getTablesAvailable={getTablesAvailable}
                getSessions={getMinimalSessions}
                session={sessionId}
                handleSearchInputTables={handleSearchInputTables}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                setAllTablesAvailable={setAllTablesAvailable}
                client={client}
                tableNumber={tableNumber}
                isTableDelivery={false}
                tableType={session?.table_type}
                // paymentMethods={paymentMethods}
                responseNfce={responseNfce}
                setResponseNfce={setResponseNfce}
                nfWarning={nfWarning}
                setNFWarning={setNFWarning}
                setIsModalOperationOpened={setIsModalOperationOpened}
                handleUpdate={handleUpdate}
              />
            </OrderSystemArea>

            <BillTableArea>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>{searchTableName(session)} </h2>{' '}
                <span
                  style={{ fontWeight: 'bold', fontSize: 20, paddingRight: 10 }}
                >
                  Carrinho
                </span>
              </div>
              <Cart
                isTableDelivery={false}
                isBalcony={session?.table_type === 'balcony'}
                confirmCloseCart={confirmCloseCart}
                setDiscount={setDiscount}
                setDiscountObs={setDiscountObs}
                discount={discount}
                discountObs={discountObs}
                isAvailable
              />
              <EyeOrdersBillOperation
                sessionId={sessionId}
                inicialDate={inicialDate}
                finalDate={finalDate}
                setSessions={setMinimalSessions}
                toggleEyeOpen={toggleEyeOpen}
                getSessions={getMinimalSessions}
                getTablesAvailable={getTablesAvailable}
                session={session}
              />
              <ClientArea setClient={setClient} session={session} />
              <ButtonsArea>
                {user.is_printed_on_web || user.print_bills_on_web ? (
                  <ButtonAloneArea type="closing" disabled>
                    <FaPrint size={22} color="#e9243e" />
                    Fechar
                  </ButtonAloneArea>
                ) : (
                  <ButtonAloneArea type="closing" disabled>
                    <FaPrint size={22} color="#e9243e" />
                    Fechar
                  </ButtonAloneArea>
                )}
                {!user?.is_pdv && (
                  <ButtonAloneArea type="box" disabled>
                    <FaCashRegister size={22} color="#009f43" />

                    <span> Caixa</span>
                  </ButtonAloneArea>
                )}

                {user.is_printed_on_web || user.print_bills_on_web ? (
                  <ReactToPrint
                    onBeforeGetContent={async () => { }}
                    trigger={() => (
                      <ButtonAloneArea type="details" disabled>
                        <FaEye size={22} color="#0099ad" />

                        <span>Espelho</span>
                      </ButtonAloneArea>
                    )}
                  />
                ) : (
                  <ButtonAloneArea type="details" disabled>
                    <FaEye size={22} color="#0099ad" />

                    <span>Espelho</span>
                  </ButtonAloneArea>
                )}

                {/* <ButtonAloneArea type="discount" disabled>
                  <FaPercentage size={22} color="#7f0071" />
                  <span>Desconto</span>
                </ButtonAloneArea> */}

                <ButtonAloneArea type="transfer" disabled>
                  <BiTransfer size={22} color="#ffbb2f" />
                  <span style={{ textAlign: 'center', lineHeight: '14px' }}>
                    Transf. Mesa
                  </span>
                </ButtonAloneArea>
              </ButtonsArea>
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
      <Modal isOpen={nfWarning}>
        <ModalBody>
          <p>
            Ocorreu um erro ao tentar emitir a Nota Fiscal. Corrija-o e tente
            novamente!
          </p>
          <p>Erro(s):</p>
          {responseNfce?.status_sefaz === '100' ? (
            <p style={{ color: 'green' }}>
              Mensagem SEFAZ: {responseNfce?.mensagem_sefaz}
            </p>
          ) : (
            <div style={{ color: 'red' }}>
              <p style={{ color: 'red' }}>
                Mensagem SEFAZ:{' '}
                {responseNfce?.response
                  ? responseNfce?.response.mensagem
                  : responseNfce?.mensagem_sefaz}
              </p>
              {responseNfce?.response &&
                responseNfce?.response.codigo === 'erro_validacao_schema' &&
                responseNfce?.response.erros &&
                responseNfce?.response.erros.map((e) => <p> - {e.mensagem}</p>)}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => setNFWarning(false)}
            style={{ background: '#dc3545', margin: '0 auto' }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Content>
  );
}

export default TableAvailable;
