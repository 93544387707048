import React from 'react';

import { RiKnifeLine } from 'react-icons/ri';
import { Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';

import OrderSchedulingReady from '../../Items/OrderSchedulingReady';

export default function CardOrdersSchedulingReady({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  getAllOrders,
  // paymentMethods,
}) {
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const count_orders = baskets.filter((order) => order.scheduled_to).length;

  return (
    <Bloco>
      <Header>
        <Icon>
          <RiKnifeLine color="#fff" size={25} />
        </Icon>
        PRONTOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            ?.filter((basket) => basket.scheduled_to)
            .map((item) => (
              <OrderSchedulingReady
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                // paymentMethods={paymentMethods}
                getAllOrders={getAllOrders}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
