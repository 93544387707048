export function ordersGetRequest(email, password) {
  return {
    type: '@orders/GET_REQUEST',
    payload: { email, password },
  };
}

export function ordersGetSuccess({ pending, accepted, finished, canceled }) {
  return {
    type: '@orders/GET_SUCCESS',
    payload: { pending, accepted, finished, canceled },
  };
}

export function ordersGetFailure() {
  return {
    type: '@orders/GET_FAILURE',
  };
}
