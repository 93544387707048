import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import CheckboxTree from 'react-checkbox-tree';
import {
  FaAngleDown,
  FaAngleRight,
  FaFile,
  FaFolder,
  FaFolderOpen,
  FaRegCheckSquare,
  FaRegMinusSquare,
  FaRegSquare,
} from 'react-icons/fa';
import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';

import { Wrapper } from './styles';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function EditUser({ user, toggleEditUser, getUsers, hasStonePdv, devices }) {
  const formRef = useRef(null);

  const initialValue = {
    name: user?.name,
    email: user?.email,
  };

  const [changedCheck, setChangedCheck] = useState(false);
  const [userDefaultOption] = useState({
    label: user?.role?.name,
    value: user?.role?.id,
  });

  const [userRolesProfile, setUserRolesProfile] = useState([]);

  const getUserRolesProfiles = useCallback(async () => {
    const response = await api.get('/restaurants/user-roles');

    const rolesProfiles = response.data;

    const profileOption = rolesProfiles.map((item) => {
      return { label: item.name, value: item.id };
    });

    setUserRolesProfile(profileOption);
  }, []);
  useEffect(() => {
    try {
      getUserRolesProfiles();
    } catch (error) {
      console.log(error);
    }
  }, [getUserRolesProfiles]);

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const [userRoles, setUserRoles] = useState([]);
  const [oldArray, setOldArray] = useState([]);
  const [roleId, setRoleId] = useState(user?.role?.id);

  const getUserRoles = useCallback(async () => {
    const response = await api.get(`/restaurants/user-roles/${roleId}`);

    const categories = response.data;

    const roles = categories.map((cat) => {
      return {
        value: cat.name.toLowerCase(),
        label: cat.name,
        className: 'personal_check',
        children: cat.permissions.map((per) => {
          return {
            value: JSON.stringify({ name: per.name, permissions: per.status }),
            label: per.name,
          };
        }),
      };
    });

    setUserRoles(roles);

    const arrTeste = [];

    categories.map((cat) =>
      cat.permissions
        .filter((filt) => filt.status.can_read)
        .map((item) => {
          return arrTeste.push(
            JSON.stringify({ name: item.name, permissions: item.status })
          );
        })
    );

    setChecked(arrTeste);
    setOldArray(arrTeste);
  }, [roleId]);

  useEffect(() => {
    try {
      getUserRoles();
    } catch (error) {
      toast.error('Erro ao carregar permissões');
      console.log(error);
    }
  }, [getUserRoles]);

  function handleCompareCheck(a, b) {
    const oldArrayParsed = a.map((item) => JSON.parse(item).permissions);

    const checkedParsed = b.map((item) => JSON.parse(item).permissions);

    if (oldArrayParsed.length === checkedParsed.length) {
      setChangedCheck(false);
    } else {
      setChangedCheck(true);
    }
  }

  async function handleSubmit(data) {
    try {
      if (data.password) {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        if (changedCheck) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Inserir um email válido')
              .required('Email ogrigatório'),
            password: Yup.string()
              .required('A senha é obrigatória')
              .min(6, 'Mínimo de 6 dígitos'),
            custom_name: Yup.string().required('Nome do acesso obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const parsed = checked
            .map((item) => JSON.parse(item).permissions)
            .map((item) => {
              return { ...item, can_read: true };
            });

          await api.put(`restaurants/users/${user.id}`, {
            name: data.name,
            email: data.email,
            password: data.password,
            stone_device_id: data.stone_device_id,
            role: {
              name: data.custom_name,
              permissions: parsed,
            },
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Inserir um email válido')
              .required('Email ogrigatório'),
            password: Yup.string()
              .required('A senha é obrigatória')
              .min(6, 'Mínimo de 6 dígitos'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await api.put(`restaurants/users/${user.id}`, {
            name: data.name,
            email: data.email,
            password: data.password,
            role_id: roleId,
            stone_device_id: data.stone_device_id,
          });
        }
        toast.success('Usuário editado com sucesso!');

        toggleEditUser();
        getUsers();
      } else {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }

        if (changedCheck) {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Inserir um email válido')
              .required('Email ogrigatório'),
            custom_name: Yup.string().required('Nome do acesso obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const parsed = checked
            .map((item) => JSON.parse(item).permissions)
            .map((item) => {
              return { ...item, can_read: true };
            });
          await api.put(`restaurants/users/${user.id}`, {
            name: data.name,
            email: data.email,
            role_id: null,
            role: {
              name: data.custom_name,
              permissions: parsed,
            },
            stone_device_id: data.stone_device_id,
          });
        } else {
          const schema = Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .email('Inserir um email válido')
              .required('Email ogrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await api.put(`restaurants/users/${user.id}`, {
            name: data.name,
            email: data.email,
            role_id: roleId,
            stone_device_id: data.stone_device_id,
          });
        }

        toast.success('Usuário editado com sucesso!');

        toggleEditUser();
        getUsers();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (error.response.data.errorType === 'email_already_exists') {
        toast.error('Já existe um usuário cadastrado com esse email.');
      } else {
        toast.error(error?.response?.data?.message || 'Falha ao editar usuário');
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={initialValue}>
      <Wrapper>
        <Row>
          <Col>
            <Input name="name" label="Nome" />
          </Col>
          <Col>
            <Input name="email" label="E-mail" />
          </Col>
          <Col>
            <Input name="password" label="Senha" type="password" />
          </Col>
        </Row>

        {user && userDefaultOption && (
          <Row>
            <Col md="4">
              <Select
                name="access"
                label="Tipo do usuário"
                options={userRolesProfile}
                defaultValue={userDefaultOption}
                onChange={(e) => {
                  setRoleId(e.value);
                  setChangedCheck(false);
                }}
              />
            </Col>
            {
              hasStonePdv &&
                <Col md="4">
                  <Select
                    label="POS Stone (S/N):" 
                    name="stone_device_id" 
                    options={devices}
                    defaultValue={devices.find(dev => dev.id === user.stone_device_id) || {value: null, label: 'Nenhum'}}
                  />
                </Col>
            }
          </Row>
        )}

        <Row>
          <p style={{ marginLeft: '8px' }}>Acesso aos menus</p>
        </Row>
        {userRoles && (
          <CheckboxTree
            nodes={userRoles}
            checked={checked}
            expanded={expanded}
            onCheck={(checked) => {
              setChecked(checked);
              handleCompareCheck(oldArray, checked);
            }}
            onExpand={(expanded) => setExpanded(expanded)}
            icons={{
              check: <FaRegCheckSquare size={22} color="#58CEB1" />,
              uncheck: <FaRegSquare size={22} color="#58CEB1" />,
              halfCheck: <FaRegMinusSquare size={22} color="#58CEB1" />,
              expandClose: <FaAngleRight size={22} color="#58CEB1" />,
              expandOpen: <FaAngleDown size={22} color="#58CEB1" />,
              // expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
              // collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
              parentClose: <FaFolder size={22} color="#58CEB1" />,
              parentOpen: <FaFolderOpen size={22} color="#58CEB1" />,
              leaf: <FaFile size={22} color="#58CEB1" />,
            }}
          />
        )}

        {changedCheck && (
          <Row className="mt-3">
            <Col md="3">
              <Input
                name="custom_name"
                placeHolder="Nome do acesso"
                label="Defina um nome para este nível de acesso"
              />
              <span style={{ fontSize: '12px' }}>
                Este nível de acesso poderá ser replicado no futuro.{' '}
              </span>
            </Col>
          </Row>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            className="btn"
            type="button"
            style={{
              color: '#FF2C3A',
              border: '1px solid #FF2C3A',
              background: '#fff',
            }}
            onClick={() => {
              toggleEditUser();
            }}
          >
            Cancelar
          </button>
          <button
            className="btn"
            type="submit"
            style={{ background: '#58CEB1' }}
          >
            Salvar
          </button>
        </div>
      </Wrapper>
    </Form>
  );
}

export default EditUser;
