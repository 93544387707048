/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { Button, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  SeeButton,
  OrdersInfo,
  Actions,
  DeleteAndEditActions,
} from '../styles';
import { Print, DisplayNoneImprimir, StatusTag } from './styles';
import api from '~/services/api';

import logoTakeat from '~/assets/img/garfinho_red.png';
import logoIfood from '~/assets/img/ifood-logo.png';

import formatCompleteDate from '~/services/formatCompleteDate';
import PrintNewOrderComponentDelivery from '~/components/PrintNewOrderComponentDelivery';
import EyeModalOrdersDelivery from '~/components/EyeModalOrdersDelivery';
import { FaRedo } from 'react-icons/fa';
import { Form } from '@unform/web';
import Input from '~/components/Form/Input';
import { useOrder } from '~/context/orderContext';
import { PaymentPercentTag } from 'ui-kit-takeat';

export default function OrderFinishedDelivery({
  item,
  session,
  user,
  getSessions,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const { getAllOrders } = useOrder();
  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);

  const [paymentsBill, setPaymentsBill] = useState(session?.payments);
  const [productBills, setProductBills] = useState();
  const [loading, setLoading] = useState(false);

  const getPayments = useCallback(async () => {
    let total = 0;

    paymentsBill.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });
    setPaid(total.toFixed(2).toString());

    const porcentPaid = session?.is_delivery
      ? (total / session?.total_delivery_price) * 100
      : (total / session?.total_service_price) * 100;
    setPorcent(isNaN(porcentPaid) ? 0 : parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, session]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  const [bills, setBills] = useState();

  const getBills = useCallback(async () => {
    const res = await api.get(`restaurants/table-sessions-bills/${session.id}`);

    setBills(res.data.bills);
    setProductBills(res.data.products);
  }, [session.id]);

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/delivery/session-orders/${session.id}`
      );

      setOrders(response.data.orders);
    } catch (error) {
      console.log(error);
    }
  }, [session]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const componentRef = useRef(null);

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (session?.with_withdrawal && session?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (session?.with_withdrawal) {
        return 'Retirada';
      }

      if (!session?.with_withdrawal && session?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  const [modalRefinish, setModalRefinish] = useState(false);
  function toggleRefinish() {
    setModalRefinish(!modalRefinish);
  }

  async function handleRefinishSession(data) {
    setLoading(true);
    if (data.reopen_password === '' || !data.reopen_password) {
      toast.error('Digite a senha administrativa.');
      setLoading(false);
      return false;
    }

    try {
      await api.put('restaurants/finish-completed-session-delivery', {
        key: session.key,
        reopen_password: data.reopen_password,
      });
      getSessions();
      getAllOrders();
      toggleRefinish();
      toast.success('Comanda reativada!');
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Erro ao reativar mesa.');
      }
    }
    setLoading(false);
  }

  return (
    <Pedido>
      <ContentBody>
        <OrdersInfo>
          <div>
            {session?.table.table_type === 'delivery' && (
              <>
                <OrderNumber>#{session?.attendance_password}</OrderNumber>
                <Time>{formatCompleteDate(session?.start_time)}</Time>
              </>
            )}
            <img
              src={session?.ifood_restaurant === null ? logoTakeat : logoIfood}
              style={{ width: session?.ifood_restaurant === null ? 20 : 40 }}
            />
          </div>
          <div>
            <TableNumber>
              {item?.basket?.ifood_table
                ? item?.basket?.ifood_table
                : searchTableName(session?.table)}
            </TableNumber>
            {item?.basket?.schedule && (
              <PhoneNumber>
                <strong>{item?.basket?.schedule}</strong>
              </PhoneNumber>
            )}
            <PhoneNumber>{session?.bills[0]?.buyer?.name}</PhoneNumber>
            <PhoneNumber>{session?.bills[0]?.buyer?.phone}</PhoneNumber>
          </div>
          <div>
            <PaymentPercentTag percent={porcent} />
            {/* <StatusTag color={porcent >= 100 ? 'green' : '#dc3545'}>
              <span>Fechada {porcent > 100 ? '100' : porcent}%</span>
            </StatusTag> */}
          </div>

          <div>
            {session?.discount_total ? (
              <p>
                <strong>Valor Total:</strong> R${' '}
                {Number(session?.old_total_price) +
                  Number(session?.delivery_tax_price || 0)}
              </p>
            ) : (
              <p>
                <strong>Valor Total:</strong> R$ {session?.total_delivery_price}
              </p>
            )}

            {session?.discount_total && (
              <p>
                <strong>Desconto/ Cupom aplicado:</strong> - R${' '}
                {session?.discount_total}
              </p>
            )}
            <p>
              {' '}
              <strong>Valor Pago:</strong> R$ {paid}
            </p>
          </div>
        </OrdersInfo>
        <Actions>
          <DeleteAndEditActions>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Detalhes
              </div>
            </SeeButton>
            {user.order_cancel_password !== '' ? (
              <SeeButton
                onClick={toggleRefinish}
                style={{ background: '#5BD776' }}
              >
                <div>
                  <FaRedo size={13} style={{ marginRight: 6 }} />
                  Reativar
                </div>
              </SeeButton>
            ) : (
              <div />
            )}
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrdersDelivery
              item={session}
              paid={paid}
              bills={bills}
              orders={orders}
              user={user}
              session={session}
              productBills={productBills}
            />
          </Container>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalRefinish} toggle={toggleRefinish}>
        <Form onSubmit={handleRefinishSession}>
          <ModalBody>
            <p>
              <strong>Tem certeza que deseja reativar essa comanda?</strong>
            </p>
            <p>O pedido voltará para a aba de "Prontos".</p>
            {user.has_nfce && (
              <p>
                Se a nota fiscal da comanda foi emitida nos últimos 30 minutos,
                ela será cancelada na SEFAZ. Sendo necessário emitir novamente.
              </p>
            )}
            <Input
              name="reopen_password"
              label="Senha administrativa"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleRefinish} style={{ background: '#dc3545' }}>
              Cancelar
            </Button>
            <Button
              type="submit"
              style={{ background: 'green' }}
              disabled={loading}
            >
              Sim, reativar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Pedido>
  );
}
