import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineCheck } from 'react-icons/ai';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';

import {
  Pedido,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  OrdersInfo,
  Badge,
} from './styles';

import formatTime from '~/services/formatTime';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import { OrderStatusTag } from 'ui-kit-takeat';
import { InfoDiv } from '../Orders/Items/styles';

export default function WaiterTicket({ item }) {
  const [modal, setModal] = useState(false);
  const { user } = useAuth();
  const toggle = () => setModal(!modal);

  async function handleCheckTicket() {
    try {
      await api.put(`/restaurants/help-requests/check/${item.id}`);
      toast.success('Atendido com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar chamado.');
    }
  }

  return (
    <Pedido>
      <ContentBody>
        <OrderStatusTag text="Pedido de ajuda" color="#FF2C3A" width="147px" />

        <InfoDiv>
          <Time>
            {item?.created_at
              ? formatTime(item?.created_at)
              : formatTime(item?.createdAt)}
          </Time>
        </InfoDiv>
        <InfoDiv grow>
          {item?.command_table_number ? (
            <TableNumber>
              Comanda {item?.table_number}/ Mesa {item?.command_table_number}
            </TableNumber>
          ) : (
            <TableNumber>Mesa {item?.table_number}</TableNumber>
          )}

          {item?.buyer?.phone && (
            <PhoneNumber>{item?.buyer?.phone}</PhoneNumber>
          )}
          {item?.buyer?.name && <PhoneNumber>{item?.buyer?.name}</PhoneNumber>}
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            <AcceptButton onClick={handleCheckTicket}>
              <div>
                <AiOutlineCheck size={17} />
                Atendido
              </div>
            </AcceptButton>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} />
                Detalhes
              </div>
            </SeeButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h3>Mesa {item?.table_number}</h3>
          <p>
            {item?.buyer?.phone} {` - ${item?.buyer?.name}`}
          </p>
          <p>{item?.comment}</p>
        </ModalBody>
      </Modal>
      {console.log(item)}
    </Pedido>
  );
}
