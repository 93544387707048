import {
  addHours,
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { TiArrowSortedUp } from 'react-icons/ti';
import { Spinner } from 'reactstrap';
import { toast } from 'react-hot-toast';
import formatValue from '~/utils/formatValue';
import api from '~/services/api';
import {
  ButtonContainer,
  Container,
  DateContainer,
  DatePicker,
  MonthRow,
  RowContainer,
  ServiceTaxTable,
  SessionRow,
  TableHeader,
  TableRow,
} from './styles';
import { DatePickerSelect } from '~/components/DatePickerSelect';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { DownloadButton } from 'ui-kit-takeat';
import { generateSheet } from '~/services/SheetGenerate/generateTaxReportSheet';
import FileSaver from 'file-saver';

export const ServiceTax = () => {
  const now = new Date();
  const [sessions, setSessions] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth(now));
  const [endDate, setEndDate] = useState(endOfMonth(now));
  const [collapsed, setCollapsed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsServiceTax'}`
      );

      const { can_read } = response.data.ReportsServiceTax;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const aux = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
  const selectMonth = aux.map((i) => {
    return {
      value: addMonths(startDate, i),
      label: `${months[addMonths(startDate, i).getMonth()]}/${addMonths(
        startDate,
        i
      ).getFullYear()}`,
    };
  });

  window.addEventListener('click', (e) => {
    if (e.target.id !== 'datediv') {
      setShowSelect(false);
    }
  });

  const formatPrice = (value) => {
    return value?.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSubMonth = () => {
    setStartDate((state) => startOfMonth(subMonths(state, 1)));
    setEndDate((state) => endOfMonth(subMonths(state, 1)));
  };

  const handleAddMonth = () => {
    setStartDate((state) => startOfMonth(addMonths(state, 1)));
    setEndDate((state) => endOfMonth(addMonths(state, 1)));
  };

  const handleChangeMonth = (date) => {
    setStartDate(startOfMonth(date));
    setEndDate(endOfMonth(date));
    setShowSelect(false);
  };

  const getSessions = async () => {
    setLoading(true);
    const response = await api.get(
      `/restaurants/reports/taxes?start_date=${startDate}&end_date=${endDate}`
    );

    setSessions(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getSessions();
  }, [startDate, endDate]);

  const handleDownload = async () => {
    try {
      const sheet = await generateSheet(sessions);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Relatório de Taxas.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  return permission ? (
    <Container>
      <h1>Taxa de Serviço</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <h2>
          Relatório de taxas de serviço recebidas pelo restaurante. 
          Filtro referente a data de criação da comanda.
        </h2>
        <DatePicker loading={loading}>
          <DatePickerSelect
            options={selectMonth}
            show={showSelect}
            clickFunction={handleChangeMonth}
          />
          <ButtonContainer onClick={handleSubMonth}>
            <TiArrowSortedUp
              color="#FF2C3A"
              size={20}
              style={{ transform: 'rotate(-90deg)' }}
            />
          </ButtonContainer>
          <DateContainer
            onClick={() => setShowSelect(!showSelect)}
            id="datediv"
          >
            {months[startDate.getMonth()]}/{startDate.getFullYear()}
          </DateContainer>
          <ButtonContainer onClick={handleAddMonth}>
            <TiArrowSortedUp
              color="#FF2C3A"
              size={20}
              style={{ transform: 'rotate(90deg)' }}
            />
          </ButtonContainer>
        </DatePicker>
      </div>
      <ServiceTaxTable>
        <div style={{marginBottom: 10}}>
          <DownloadButton
            clickFunction={() => {
              handleDownload();
            }}
            fixed="open"
          />
        </div>
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <>
            <TableHeader>
              <span>Período</span>
              <span>Pedidos (Qtd)</span>
              <span>Vendas</span>
              <span>Taxas</span>
            </TableHeader>
            <MonthRow>
              <span>
                {months[startDate.getMonth()]}/{startDate.getFullYear()}
              </span>
              <span>{sessions.orders}</span>
              <span>
                R$
                {formatPrice(sessions.total)}
              </span>
              <span>R${formatPrice(sessions.taxes)}</span>
            </MonthRow>
            {sessions?.index?.map((day, idx) => {
              const { length } = sessions.days[day].sessions;
              const check = collapsed.includes(day);

              return (
                <RowContainer>
                  <TableRow
                    key={idx}
                    collapsable={length > 0}
                    isOpen={check}
                    onClick={() => {
                      if (!check) {
                        setCollapsed((state) => [...state, day]);
                      } else {
                        setCollapsed((state) => state.filter((s) => s !== day));
                      }
                    }}
                  >
                    <span>
                      {length > 0 && (
                        <IoIosArrowForward
                          size={16}
                          style={{
                            transform: check && 'rotate(90deg)',
                            marginRight: '5px',
                            transition: 'all 0.2s',
                          }}
                        />
                      )}
                      {day}
                    </span>
                    <span>{sessions.days[day].orders}</span>
                    <span>
                      R$
                      {formatPrice(sessions.days[day].total)}
                    </span>
                    <span>
                      R$
                      {formatPrice(sessions.days[day].taxes)}
                    </span>
                  </TableRow>
                  {sessions.days[day].sessions
                    .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                    .map((ss) => {
                      const maxTax =
                        parseFloat(ss.total_service_price) -
                        parseFloat(ss.total_price);
                      const extraPaid =
                        ss.payments.reduce(
                          (acc, cur) => acc + parseFloat(cur.payment_value),
                          0
                        ) - parseFloat(ss.total_price);

                      return (
                        <SessionRow check={check}>
                          {check && (
                            <>
                              <span>
                                <FaCircle
                                  size={12}
                                  color="#0CCAB4"
                                  style={{
                                    marginRight: '5px',
                                  }}
                                />
                                {format(new Date(ss.created_at), 'HH:mm')} - #
                                {ss.bills[0]?.order_baskets[0]?.basket_id}
                              </span>
                              <span>{ss.orders}</span>
                              <span>
                                R$
                                {formatPrice(
                                  ss.payed -
                                    (extraPaid < 0
                                      ? 0
                                      : extraPaid > maxTax
                                      ? maxTax
                                      : extraPaid)
                                )}
                              </span>
                              <span>
                                R$
                                {formatPrice(
                                  extraPaid < 0
                                    ? 0
                                    : extraPaid > maxTax
                                    ? maxTax
                                    : extraPaid
                                )}
                              </span>
                            </>
                          )}
                        </SessionRow>
                      );
                    })}
                </RowContainer>
              );
            })}
          </>
        )}
      </ServiceTaxTable>
    </Container>
  ) : (
    <PermissionErrorContainer />
  );
};
