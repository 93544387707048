import React, { useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { FiTrash } from 'react-icons/fi';

import { ActionButton, DownloadButton } from 'ui-kit-takeat';
import { TiPlus, TiTrash } from 'react-icons/ti';

export default function NfeAvulsaVolume({
  item,
  itens,
  setItens,
  key,
  number
}) {

  const [volumeOptions, setVolumeOptions]= useState([
   {label: 'Quantidade', value: 'quantidade'},
   {label: 'Espécie', value: 'especie'},
   {label: 'Marca', value: 'marca'},
   {label: 'Número', value: 'numero'},
   {label: 'Peso Líquido', value: 'peso_liquido'},
   {label: 'Peso Bruto', value: 'peso_bruto'},
   {label: 'Lacres', value: 'lacres'},
 ]);

 const [thisItens, setThisItens] = useState([]);

 const [selectValue, setSelectValue] = useState({label: 'Quantidade', value: 'quantidade'});
 const [inputValue, setInputValue] = useState('');


 useEffect(e => {
  let allItens = itens;

  let toJson = `{"numero_item":"${item.numero_item}"`;
  thisItens.map(it => {
    toJson = toJson + `,"${it.name}":"${it.value}"`
  });
  toJson = toJson + '}';
  const js = JSON.parse(toJson);

  allItens.map(it => {
    if(it.numero_item === item.numero_item){
      it.dataValues = js;
    }
  });

  setItens(allItens);
 }, [thisItens, item]);

  return (
    <div style={{padding: 15}}>
      <Form>
        <Row>
          <Col md="1" style={{marginTop: 15}}>
            <strong>Vol. {Number(number) + 1}) </strong>
          </Col>
          <Col md="4">
            <Select 
              options={volumeOptions}
              name="option"
              value={selectValue}
              onChange={e => {
                setSelectValue(e);
              }}
            />
          </Col>
          <Col md="5">
            <Input
              name={`volume_name_${item?.numero_item}`}
              id={`volume_name_${item?.numero_item}`}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Col>
          <Col md="2">
            <button name="add_campo_item" 
              onClick={e =>  {
                setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                setVolumeOptions(volumeOptions.filter(it => it.value !== selectValue.value));
                setSelectValue(volumeOptions.filter(it => it.value !== selectValue.value)[0] || []);
                setInputValue('');
                const hasDoc = document.getElementById(`volume_name_${item.numero_item}`);
                if(hasDoc){
                  hasDoc.focus();
                }
              }}
              type="button"
              style={{
                borderRadius: '25px',
                height: 40,
                width: 40,
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: '#2AB7A6',
                color: 'white',
                textAlign: 'center',
                marginTop: 10,
                fontSize: 20,
                fontWeight: 600
              }}
              disabled={volumeOptions.length === 0}
            > + </button>

            <button
              name="remove_campo_volume"
              onClick={e =>  {
                setItens(itens.filter(it => it.numero_item !== item.numero_item));
              }}
              type="button"
              style={{
                borderRadius: '25px',
                height: 40,
                width: 40,
                border: 'none',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: 'red',
                color: 'white',
                textAlign: 'center',
                marginTop: 10,
                marginLeft: 15,
              }}
            > <FiTrash size={17}/> </button>
          </Col>
        </Row>
        <div>
          {thisItens.map(it => (
            <Row style={{textAlign: 'left', paddingLeft: 20}}>
              <Col md="1" style={{textAlign: 'right'}}>
                <FiTrash size={16} color="red" style={{cursor: 'pointer'}} onClick={e => {
                  setThisItens(thisItens.filter(th => th.name !== it.name));
                  setVolumeOptions([...volumeOptions, {label: it.label, value: it.name}]);
                  setSelectValue([...volumeOptions, {label: it.label, value: it.name}][0] || []);
                }}/>
              </Col>
              <Col md="11">
                <strong>{it.label}:</strong> {it.value} 
              </Col>
              {/* <Col md="6">
                {it.value}  
              </Col> */}
            </Row>
          ))}
        </div>
      </Form>
    </div>
  );
}
