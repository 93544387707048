import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;

  width: 97%;
  position: absolute;
  bottom: 25px;
  padding-right: 25px;
`;
export const NumberFormatStyledValue = styled.input`
  height: 44px;
  width: 70%;
 
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;
export const InputStyled = styled.input`
  width: 70%;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const ButtonAdd = styled.button`
  color: #ff2c3a;
  text-decoration: underline;
  border: none;
  font-weight: bold;
  background-color: transparent;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const SelectStyled = styled(ReactSelect)`
  width: 90%;
  text-align: left;
`;

export const InputsTable = styled.table`
  width: 100%;
  border-collapse: initial;
  border-spacing: 0 30px;
  background: ${(props) => props.theme.colors.white};

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: calc(100% - 54px);
  max-width: 120px;
  min-width: 82px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const CmvButtons = styled.div`
  display: flex;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  cursor: pointer;

  input {
    margin-right: 5px;
  }
`;

export const SubmitStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: 8px;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;
