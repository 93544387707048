import React from 'react';
import { VideoPlayer } from '~/components/VideoPlayer';
import { Container, CategoryProductsMenu } from './styles';

const ProductCategoryHeaderFiscal = () => {
  return (
    <Container>
      <CategoryProductsMenu>
        <h2>Cardápio Fiscal</h2>
        <VideoPlayer id={100} />
      </CategoryProductsMenu>
    </Container>
  );
};

export default ProductCategoryHeaderFiscal;
