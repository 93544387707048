import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  subDays,
  differenceInDays,
} from 'date-fns';
import {
  Row,
  Col,
  Collapse,
  CardBody,
  Card as Cardstrap,
  CardFooter,
  CardTitle,
} from 'reactstrap';
import { Input } from '@material-ui/core';
import { FaQuestionCircle } from 'react-icons/fa';
import {
  Card,
  Container,
  Header,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  CategoriesTable,
  DataPickerArea,
  DatePickerStyled,
} from './styles';

import GoodCard from '~/components/Rate/GoodCard';
import BadCard from '~/components/Rate/BadCard';
import api from '~/services/api';
import ClientsRatingRow from '../ClientsRatingRow';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function ReportsRate() {
  const [buyers, setBuyers] = useState(0);
  const [rangeOn, setRangeOn] = useState(true);
  const [ratings, setRatings] = useState([]);
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [isSelectMonthOpen, setIsSelectMonthOpen] = useState(false);
  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setYesterday() {
    setRangeOn(true);
    const inicial = startOfDay(subDays(new Date(), 1));
    const final = endOfDay(subDays(new Date(), 1));

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    }
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const getRatings = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/ratings', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      response.data.ratings.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return -1;
      });
      setRatings(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getBuyers = useCallback(async () => {
    try {
      const response = await api.get('restaurants/reports/buyers', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setBuyers(response.data.length);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getRatings();
    getBuyers();
  }, [getRatings, getBuyers]);

  const rate = ratings.ratings_average ? ratings.ratings_average.toFixed(2) : 0;
  const percentRating = ((ratings.ratings_count * 100) / buyers).toFixed(0);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsRatings'}`
      );

      const { can_read } = response.data.ReportsRatings;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Relatório de Avaliações</h2>
          <p>Acompanhe as avaliações recebidas pelo seu estabelecimento!</p>
        </div>
      </Header>
      <Card>
        <Row>
          <Col md="4">
            <p>{hourPhrase}</p>
          </Col>
          <Col md="8">
            <div style={{ float: 'right', marginBottom: '20px' }}>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setYesterday}
                color="info"
              >
                Ontem
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>

              <DataPickerArea>
                <DatePickerStyled
                  selected={monthYearDate}
                  onChange={(date) => {
                    setMonthYearDate(date);
                    setIsSelectMonthOpen(false);
                    setMonth(date);
                    setRangeOn(true);
                  }}
                  locale="pt"
                  showMonthYearPicker
                  customInput={
                    <SelectDateButton
                      className="btn-round mr-auto"
                      onClick={() => setIsSelectMonthOpen(!isSelectMonthOpen)}
                      color="info"
                    >
                      Mês/Ano <MdArrowDropDown color="white" size={20} />
                    </SelectDateButton>
                  }
                />
              </DataPickerArea>

              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
              <FaQuestionCircle
                color="orange"
                title="Selecione o intervalo entre as datas com máximo de 31 dias"
                size={20}
                style={{ marginTop: -45, marginLeft: -5 }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div style={{ float: 'right', marginRight: '15px' }}>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        step="1"
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
          <p
            style={{
              textAlign: 'right',
              color: 'red',
              display: rangeOn ? 'none' : 'block',
            }}
          >
            Favor selecionar o intervalo máximo de 31 dias entre as datas.
          </p>
        </Row>
        <Row>
          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div
                      className="info-icon text-center"
                      style={{ background: '#ffc107' }}
                    >
                      <i className="tim-icons icon-shape-star" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Média de Avaliações</p>
                      <CardTitle tag="h3">{rate}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  Média de avaliações do estabelecimento
                </div>
              </CardFooter>
            </Cardstrap>
          </Col>

          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center">
                      <i className="tim-icons icon-chart-bar-32" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Avaliações</p>
                      <CardTitle tag="h3">{ratings.ratings_count}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">Total de avaliações</div>
              </CardFooter>
            </Cardstrap>
          </Col>
          <Col lg="4" md="4">
            <Cardstrap className="card-stats">
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-info">
                      <i className="tim-icons icon-single-02" />
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Clientes</p>
                      <CardTitle tag="h3">{buyers}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">Total de clientes</div>
              </CardFooter>
            </Cardstrap>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="6">
            <GoodCard ratings={ratings} />
          </Col>
          <Col lg="6" md="6">
            <BadCard ratings={ratings} />
          </Col>
        </Row>

        <Cardstrap>
          <CardBody>
            <CategoriesTable borderless>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Telefone</th>
                  <th style={{ textAlign: 'center' }}>Nome</th>
                  <th style={{ textAlign: 'center' }}>Avaliação</th>
                  <th style={{ textAlign: 'center' }}>Comentário</th>
                  <th style={{ textAlign: 'center' }}>Atributos</th>
                  <th style={{ textAlign: 'center' }}>Feita em:</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {ratings.ratings &&
                  ratings.ratings.length > 0 &&
                  ratings.ratings.map((item) => (
                    <ClientsRatingRow rating={item} />
                  ))}
              </tbody>
            </CategoriesTable>
          </CardBody>
        </Cardstrap>
      </Card>
    </Container>
  );
}

export default ReportsRate;
