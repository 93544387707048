import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import InputMask from 'react-input-mask';
import {
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from 'reactstrap';

import { FaExclamationCircle, FaQuestionCircle } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import ReactToPrint from 'react-to-print';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';
import { DisplayNoneImprimir } from './styles';
import PrintNFCe from '~/components/Prints/PrintNFCe';
import api from '~/services/api';
import { useTables } from '~/context/TablesContext';
import CashierFooterNF from '../CashierFooterNF';
import { useClube } from '~/context/ClubeContext';
import { useAuth } from '~/context/AuthContext';
import PersonalButton from '../Buttons/PersonalButton';
import PersonalOutlineButton from '../Buttons/PersonalOutlineButton';

import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';

export default function FooterNF({
  session,
  getSessions,
  toggleEye,
  has_nfce,
  payments,
  item,
  handleItemFinished,
  getBillsSession,
  handlePrintItem,
  printClick,
  componentRef,
  setAllTablesAvailable,
  setInputValue,
  setTablesFilteredSearch,
  handleDiscountUpdate,
  discount,
  discountObs,
  applyDiscount,
  adminPassword,
}) {
  const [html, setHtml] = useState(null);
  const { getTablesAvailable } = useTables();
  const { handlePontuarSession, sessionsAwarded, rescues, setRescues } =
    useClube();

  const { user } = useAuth();

  const [responseNfce, setResponseNfce] = useState([]);
  const [loadingNfce, setLoadingNfce] = useState(false);

  const [typeSelected, setTypeSelected] = useState('nfce');
  const [docSelected, setDocSelected] = useState(
    session?.ifood_document?.length === 11 || !session?.ifood_document
      ? 'cpf'
      : 'cnpj'
  );
  const [ieSelected, setIeSelected] = useState('9');
  const [localDestinoSelected, setLocalDestinoSelected] = useState(1);
  const [ufSelected, setUfSelected] = useState(null);

  const [errorMenewProducts, setErrorMenewProducts] = useState('');
  const [errorMenewComplements, setErrorMenewComplements] = useState('');

  const [dataTo, setDataTo] = useState([]);
  const [modalDiferenca, setModalDiferenca] = useState(false);
  const [modalDiferenceValue, setModalDiferenceValue] = useState(false);

  const [initialData, setInitialData] = useState({
    local_destino: 1,
    indicador_inscricao_estadual_destinatario: '9',
  });

  const [cnpj, setCnpj] = useState(null);

  const uf_options = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  function toggleDiferenca(data) {
    setDataTo(data);
    setModalDiferenca(!modalDiferenca);
  }
  function toggleDiferenceValue() {
    setModalDiferenceValue(!modalDiferenceValue);
  }

  const all_payments = payments;
  const soma_pagamentos = all_payments.reduce(
    (total, payment) =>
      total + parseFloat(payment.payment_value || payment.value || 0),
    0
  );

  let diferenca = 0;

  if (item?.table.table_type === 'delivery') {
    if (session?.old_total_price === null) {
      diferenca = parseFloat(session?.total_delivery_price) - soma_pagamentos;
    } else {
      // Este caso ocorre quando o lojista cria o pedido com desconto
      diferenca =
        parseFloat(session?.total_delivery_price) +
        parseFloat(session?.old_total_price) -
        parseFloat(session?.total_price) -
        soma_pagamentos;
    }
  } else {
    diferenca = parseFloat(session?.total_service_price) - soma_pagamentos;
  }

  if (applyDiscount) {
    diferenca -= discount || 0;
  }

  diferenca = parseFloat(diferenca.toFixed(2));

  const setSessionCompleted = useCallback(async () => {
    try {
      if (
        item?.table.table_type === 'delivery' ||
        item?.table.table_type === 'balcony'
      ) {
        if (
          discount &&
          user.order_cancel_password &&
          (!adminPassword || adminPassword === '') &&
          item.ifood_discount === 0
        ) {
          toast.error('Insira a senha administrativa.');
          return;
        }

        if (
          discount &&
          user.order_cancel_password &&
          adminPassword &&
          item.ifood_discount === 0
        ) {
          if (adminPassword !== user.order_cancel_password) {
            toast.error('Senha administrativa incorreta.');
            return;
          }
        }

        handleDiscountUpdate();
      }

      setLoadingNfce(true);
      const response = await api.put('restaurants/table-sessions-completed', {
        session_key: session?.key || session?.session_key,
      });

      if (item?.table.table_type === 'delivery') {
        handleItemFinished(item.basket.id);
        if (!item?.basket?.ifood_id) {
          handleNotifyWhatsapp(item, null, user, 'finished');
        }
      }
      if (response.data.errorMenew) {
        console.log(
          response.data.errorMenew.complements,
          response.data.errorMenew.products
        );
        setErrorMenewComplements(response.data.errorMenew.complements);
        setErrorMenewProducts(response.data.errorMenew.products);
      } else {
        toast.success('Comanda finalizada.');

        if (item?.table.table_type !== 'delivery') {
          getSessions();
          getTablesAvailable();
        }

        toggleEye();
        setLoadingNfce(false);
      }
      if (!sessionsAwarded.includes(session.id) && user.has_clube) {
        handlePontuarSession({
          session_key: session.key || session.session_key,
        });
      }
    } catch (err) {
      toast.error(err.error);
      console.log(err);
      setLoadingNfce(false);
    }
  }, [
    session,
    getSessions,
    toggleEye,
    getTablesAvailable,
    handleItemFinished,
    item,
    handleDiscountUpdate,
    discount,
    adminPassword,
    user,
  ]);

  const diferencaFunction = async (data) => {
    if (applyDiscount && !discountObs) {
      toast.error('Preencha o motivo do desconto');
    } else if (diferenca !== 0) {
      toggleDiferenca(data);
    } else {
      storeNfce(data);
    }
  };

  const diferenceValueFunction = async () => {
    if (diferenca !== 0) {
      toggleDiferenceValue();
    } else {
      setSessionCompleted();
    }
  };

  const storeNfce = useCallback(
    async (data) => {
      try {
        if (
          item?.table.table_type === 'delivery' ||
          item?.table.table_type === 'balcony'
        ) {
          if (
            discount &&
            user.order_cancel_password &&
            (!adminPassword || adminPassword === '') &&
            item.ifood_discount === 0
          ) {
            toast.error('Insira a senha administrativa.');
            return;
          }

          if (
            discount &&
            user.order_cancel_password &&
            adminPassword &&
            item.ifood_discount === 0
          ) {
            if (adminPassword !== user.order_cancel_password) {
              toast.error('Senha administrativa incorreta.');
              return;
            }
          }

          handleDiscountUpdate();
        }

        setLoadingNfce(true);
        document.getElementById('button_finalizar').disabled = true;

        let response;

        if (!data.type) {
          data.type = 'nfce';
        }

        if (data.type === 'nfce') {
          const dataSent = { key: session.key || session.session_key };

          if (data.email !== null && data.email !== '') {
            dataSent.email = data.email;
          }

          if (data.cpf !== '' && data.cpf !== null) {
            dataSent.cpf = data.cpf;
          }

          if (data.cnpj !== '' && data.cnpj !== null) {
            dataSent.cnpj = data.cnpj;
          }

          if (data.informacoes_adicionais_contribuinte) {
            dataSent.informacoes_adicionais_contribuinte =
              data.informacoes_adicionais_contribuinte;
          }

          dataSent.rescues = rescues;
          response = await api.post('restaurants/new-nfce', dataSent);

          if (
            response.data.status_sefaz === '100' &&
            !sessionsAwarded.includes(session.id) &&
            user.has_clube
          ) {
            handlePontuarSession({
              session_key: session.key || session.session_key,
            });
          } else if (response.data.status_sefaz !== '100') {
            toast.error('Erro Sefaz');
          }
        } else {
          const parsedData = {
            key: session.key || session.session_key,
            nome_destinatario: data.nome_destinatario,
            logradouro_destinatario: data.logradouro_destinatario,
            numero_destinatario: data.numero_destinatario,
            bairro_destinatario: data.bairro_destinatario,
            municipio_destinatario: data.municipio_destinatario,
            uf_destinatario: ufSelected,
            indicador_inscricao_estadual_destinatario: ieSelected,
            local_destino: localDestinoSelected,
            informacoes_adicionais_contribuinte:
              data.informacoes_adicionais_contribuinte,
          };

          if (data.cpf !== '' && data.cpf !== null) {
            parsedData.cpf = data.cpf;
          }

          if (data.cnpj !== '' && data.cnpj !== null) {
            parsedData.cnpj_destinatario = data.cnpj;
          }

          if (data.inscricao_estadual_destinatario !== '') {
            parsedData.inscricao_estadual_destinatario =
              data.inscricao_estadual_destinatario;
          }

          if (data.email !== null && data.email !== '') {
            parsedData.email = data.email;
          }

          parsedData.rescues = rescues;
          response = await api.post('restaurants/new-nfe', parsedData);
          setRescues([]);
        }

        setResponseNfce(response.data);
        if (response.data.status_sefaz === '100') {
          if (data.type === 'nfce') {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );

            if (item?.table.table_type === 'delivery') {
              handleItemFinished(item.basket.id);
            }

            if (item?.table.table_type !== 'delivery') {
              getSessions();
              getTablesAvailable();
            }
            toggleEye();
          } else {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );

            if (item?.table.table_type === 'delivery') {
              handleItemFinished(item.basket.id);
            }
            if (item?.table.table_type !== 'delivery') {
              getSessions();
              getTablesAvailable();
            }
            toggleEye();
          }
        }
        setLoadingNfce(false);
        const button_finalizar = document.getElementById('button_finalizar');
        if (button_finalizar) {
          button_finalizar.disabled = false;
        }
      } catch (error) {
        console.log('error de emissao', error);
        setLoadingNfce(false);
        const button_finalizar = document.getElementById('button_finalizar');
        if (button_finalizar) {
          button_finalizar.disabled = false;
        }
        toast.error(
          'Não foi possível finalizar e emitir a Nota Fiscal! Tente novamente'
        );
      }
    },
    [
      session,
      getSessions,
      getTablesAvailable,
      ufSelected,
      ieSelected,
      localDestinoSelected,
      rescues,
      handleDiscountUpdate,
      adminPassword,
      discount,
      user,
    ]
  );

  const menewDuplicate = useCallback(async () => {
    try {
      setLoadingNfce(true);
      document.getElementById('button_menew').disabled = true;

      const parsedData = {
        session_key: session.key || session.session_key,
      };

      await api.post('restaurants/menew/send/duplicate', parsedData);

      toast.success('Comanda finalizada com sucesso');

      getSessions();
      getTablesAvailable();
      toggleEye();
      setLoadingNfce(false);
      document.getElementById('button_menew').disabled = false;
    } catch (error) {
      console.log('error de duplicada menew', error);
      setLoadingNfce(false);
      document.getElementById('button_menew').disabled = false;
      toast.error(
        'Não foi possível finalizar a comanda duplicada Menew! Tente novamente'
      );
    }
  }, [session]);

  const componentRefPrint = useRef(null);
  const afterPrint = useCallback(async () => {
    setHtml(null);
    document.getElementById('close_modal').click();
  }, []);

  const type_options = [
    { value: 'nfce', label: 'NFCe' },
    { value: 'nfe', label: 'NFe' },
  ];

  const doc_options = [
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
  ];

  const local_options = [
    { value: 1, label: 'Operação Interna' },
    { value: 2, label: 'Operação Interestadual' },
  ];

  const ie_options = [
    {
      value: '1',
      label: '1 – Contribuinte ICMS (informar a IE do destinatário);',
    },
    {
      value: '2',
      label:
        '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS;',
    },
    {
      value: '9',
      label:
        '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
    },
  ];

  const getNfeClientInfo = useCallback(async (cnpj) => {
    try {
      const response = await api.get(`restaurants/nfe-client/${cnpj}`);

      if (response.data) {
        setInitialData({
          nome_destinatario: response.data.nome,
          logradouro_destinatario: response.data.logradouro,
          numero_destinatario: response.data.numero,
          bairro_destinatario: response.data.bairro,
          municipio_destinatario: response.data.municipio,
          uf_destinatario: response.data.uf,
          indicador_inscricao_estadual_destinatario:
            response.data.indicador_inscricao_estadual || '9',
          inscricao_estadual_destinatario: response.data.inscricao_estadual,
          local_destino: response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1,
          email: response.data.email,
        });

        setIeSelected(response.data.indicador_inscricao_estadual || '9');
        setLocalDestinoSelected(
          response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1
        );
        setUfSelected(response.data.uf);
      }

      // toast.success('Comanda finalizada com sucesso');
    } catch (error) {
      // toast.error(
      //   'Não foi possível finalizar a comanda duplicada Menew! Tente novamente'
      // );
    }
  }, []);

  useEffect(() => {
    const thisCnpj = cnpj
      ?.replaceAll('.', '')
      .replaceAll('/', '')
      .replaceAll('_', '')
      .replaceAll('-', '');
    if (thisCnpj?.length === 14 && docSelected === 'cnpj') {
      getNfeClientInfo(thisCnpj);
    } else if (thisCnpj?.length === 11) {
      getNfeClientInfo(thisCnpj);
    }
  }, [cnpj]);

  return (
    <>
      <div style={{ width: '100%' }}>
        {has_nfce ? (
          <Form onSubmit={diferencaFunction} initialData={initialData}>
            <Row>
              <Col lg="12">
                {!session.nfce_allowed && (
                  <>
                    {!session?.basket?.ifood_id && (
                      <Row>
                        <Col md="12">
                          <Select
                            label="Tipo:"
                            name="type"
                            options={type_options}
                            defaultValue={{ value: 'nfce', label: 'NFCe' }}
                            onChange={(e) => {
                              if (e.value === 'nfe') {
                                setDocSelected('cnpj');
                              } else {
                                setDocSelected('cpf');
                              }
                              setTypeSelected(e.value);
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                    {typeSelected === 'nfce' && (
                      <>
                        <Row>
                          <Col md="3">
                            <div style={{ marginTop: 13 }}>
                              <Select
                                label="Documento:"
                                name="doc"
                                options={doc_options}
                                defaultValue={
                                  session?.ifood_document?.length === 11
                                    ? { value: 'cpf', label: 'CPF' }
                                    : docSelected === 'cpf'
                                    ? { value: 'cpf', label: 'CPF' }
                                    : { value: 'cnpj', label: 'CNPJ' }
                                }
                                onChange={(e) => setDocSelected(e.value)}
                              />
                            </div>
                          </Col>
                          {docSelected === 'cpf' ? (
                            <Col md="9">
                              <InputMask mask="999.999.999-99">
                                <Input
                                  label="CPF: (opcional)"
                                  name="cpf"
                                  type="text"
                                  placeholder="xxx.xxx.xxx-xx"
                                  defaultValue={session.ifood_document}
                                />
                              </InputMask>
                            </Col>
                          ) : (
                            <Col>
                              <InputMask mask="99.999.999/9999-99">
                                <Input
                                  label="CNPJ: (opcional)"
                                  name="cnpj"
                                  type="text"
                                  placeholder="xx.xxx.xxx/xxxx-xx"
                                />
                              </InputMask>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col md="12">
                            <Input
                              label="Informações Adicionais"
                              name="informacoes_adicionais_contribuinte"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                      </>
                    )}

                    {typeSelected === 'nfe' && (
                      <>
                        <Row>
                          <Col md="3">
                            <div style={{ marginTop: 13 }}>
                              <Select
                                label="Documento:"
                                name="doc"
                                options={doc_options}
                                defaultValue={
                                  docSelected === 'cpf'
                                    ? { value: 'cpf', label: 'CPF' }
                                    : { value: 'cnpj', label: 'CNPJ' }
                                }
                                onChange={(e) => {
                                  setDocSelected(e.value);
                                  if (e.value === 'cpf') {
                                    setIeSelected('2');
                                  } else {
                                    setIeSelected('9');
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          {docSelected === 'cpf' ? (
                            <Col md="4">
                              <InputMask
                                mask="999.999.999-99"
                                onChange={(e) => setCnpj(e.target.value)}
                              >
                                <Input
                                  label="CPF:"
                                  name="cpf"
                                  type="text"
                                  placeholder="xxx.xxx.xxx-xx"
                                  defaultValue={session.ifood_document}
                                />
                              </InputMask>
                            </Col>
                          ) : (
                            <Col md="4">
                              <InputMask
                                mask="99.999.999/9999-99"
                                onChange={(e) => setCnpj(e.target.value)}
                              >
                                <Input
                                  label="CNPJ:"
                                  name="cnpj"
                                  type="text"
                                  placeholder="xx.xxx.xxx/xxxx-xx"
                                />
                              </InputMask>
                            </Col>
                          )}

                          <Col md="5">
                            <Input
                              label="Nome:"
                              name="nome_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <Input
                              label="Logradouro:"
                              name="logradouro_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                          <Col md="3">
                            <Input
                              label="Número:"
                              name="numero_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                          <Col md="3">
                            <Input
                              label="Bairro:"
                              name="bairro_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Input
                              label="Município:"
                              name="municipio_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                          <Col>
                            <Select
                              label="UF:"
                              name="uf_destinatario"
                              placeholder="Selecione a UF"
                              options={uf_options}
                              onChange={(e) => setUfSelected(e.value)}
                              value={
                                uf_options.filter(
                                  (local) => local.value === ufSelected
                                ) || null
                              }
                            />
                          </Col>
                          <Col>
                            <div style={{ marginTop: 13 }}>
                              <Select
                                label="Estado de origem:"
                                name="local_destino"
                                options={local_options}
                                // defaultValue={{
                                //   value: 1,
                                //   label: 'Operação Interna',
                                // }}
                                onChange={(e) =>
                                  setLocalDestinoSelected(e.value)
                                }
                                value={local_options.filter(
                                  (local) =>
                                    local.value === localDestinoSelected
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div style={{ marginTop: 15 }}>
                              <Select
                                label="Indicador da Inscrição Estadual:"
                                name="indicador_inscricao_estadual_destinatario"
                                options={ie_options}
                                defaultValue={{
                                  value: '9',
                                  label:
                                    '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
                                }}
                                onChange={(e) => setIeSelected(e.value)}
                                value={ie_options.filter(
                                  (local) => local.value === ieSelected
                                )}
                              />
                            </div>
                          </Col>
                          <Col>
                            <Input
                              label={
                                ieSelected === '1'
                                  ? 'Inscrição Estadual: (Obrigatório)'
                                  : 'Inscrição Estadual: (Opcional)'
                              }
                              name="inscricao_estadual_destinatario"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Input
                              label="Informações Adicionais"
                              name="informacoes_adicionais_contribuinte"
                              type="text"
                              placeholder=""
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col md="12">
                        <Input
                          label="E-mail para enviar cópia da Nota Fiscal: (opcional)"
                          name="email"
                          type="email"
                          placeholder="exemplo@gmail.com"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                {responseNfce.mensagem_sefaz && (
                  <div style={{ marginTop: 10 }}>
                    {responseNfce.status_sefaz === '100' ? (
                      <p style={{ color: 'green' }}>
                        <strong>
                          Mensagem SEFAZ: {responseNfce.mensagem_sefaz}
                        </strong>
                      </p>
                    ) : (
                      <div>
                        <p>
                          <strong style={{ color: 'red' }}>
                            Mensagem SEFAZ:{' '}
                            {responseNfce.response
                              ? responseNfce.response.mensagem
                              : responseNfce.mensagem_sefaz}
                          </strong>
                        </p>
                        {typeSelected === 'nfe' &&
                          responseNfce.response &&
                          responseNfce.response.codigo ===
                            'erro_validacao_schema' &&
                          responseNfce.response.erros &&
                          responseNfce.response.erros.map((erro) => (
                            <p> - {erro.mensagem}</p>
                          ))}
                        {responseNfce.response &&
                          responseNfce.response.codigo ===
                            'erro_validacao_schema' &&
                          responseNfce.erros_schema &&
                          responseNfce.erros_schema.map((e) => (
                            <p>
                              {' '}
                              - <strong>{e.name}:</strong>
                              {e.erro}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            {session?.table?.table_type === 'delivery' &&
              user?.adress?.inscricao_estadual &&
              user?.adress?.inscricao_estadual !== '' &&
              user?.adress?.inscricao_estadual !== ' ' && (
                <p>
                  Para a taxa de entrega na nota ser emitida como frete, insira
                  o CPF ou CNPJ. Se não, será emitida como um produto.
                </p>
              )}

            {session?.status === 'open' || session?.status === 'closing' ? (
              <CashierFooterNF
                session={session}
                getBillsSession={getBillsSession}
                handlePrintItem={handlePrintItem}
                printClick={printClick}
                componentRef={componentRef}
                setAllTablesAvailable={setAllTablesAvailable}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                loadingNfce={loadingNfce}
                diferenceValueFunction={diferenceValueFunction}
              />
            ) : (
              <Row lg="8">
                <Col md="12">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                      marginTop: 10,
                    }}
                  >
                    {!session.nfce_allowed && (
                      <>
                        <PersonalButton
                          type="submit"
                          color="#2EC9B7"
                          id="button_finalizar"
                          message={
                            loadingNfce
                              ? 'Finalizando'
                              : 'Finalizar e Emitir Nota Fiscal'
                          }
                          disabled={loadingNfce}
                        />

                        {session?.table?.table_type === 'delivery' && (
                          <PersonalOutlineButton
                            onClick={() => {
                              if (applyDiscount && !discountObs) {
                                toast.error('Preencha o motivo do desconto');
                              } else {
                                diferenceValueFunction();
                              }
                            }}
                            color="transparent"
                            borderColor="#2EC9B7"
                            fontColor="#2EC9B7"
                            message={
                              loadingNfce
                                ? 'Finalizando, aguarde ...'
                                : 'Fechar comanda'
                            }
                            disabled={loadingNfce}
                          />
                        )}
                      </>
                    )}

                    {/* <Button
                      onClick={toggleEye}
                      id="close_modal"
                      style={{ background: '#dc3545', marginTop: 20 }}
                    >
                      Cancelar
                    </Button> */}
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        ) : (
          <>
            <Row>
              <Col md="12">
                {errorMenewComplements !== '' && (
                  <strong>
                    <div style={{ color: 'red' }}>
                      <p>Error ao enviar para Menew!</p>
                      <p>{errorMenewProducts}</p>
                      <p>{errorMenewComplements}</p>
                    </div>
                  </strong>
                )}
              </Col>
            </Row>
            {session?.status === 'open' || session?.status === 'closing' ? (
              <CashierFooterNF
                session={session}
                getBillsSession={getBillsSession}
                handlePrintItem={handlePrintItem}
                printClick={printClick}
                componentRef={componentRef}
                setAllTablesAvailable={setAllTablesAvailable}
                setInputValue={setInputValue}
                setTablesFilteredSearch={setTablesFilteredSearch}
                loadingNfce={loadingNfce}
                diferenceValueFunction={diferenceValueFunction}
              />
            ) : (
              <Row lg="8">
                <Col md="12">
                  <div style={{ float: 'right' }}>
                    <PersonalButton
                      onClick={() => diferenceValueFunction()}
                      message={
                        loadingNfce
                          ? 'Finalizando, aguarde ...'
                          : has_nfce && !session?.basket?.ifood_id
                          ? 'Finalizar e Emitir Nota Fiscal'
                          : 'Fechar comanda'
                      }
                      disabled={loadingNfce}
                      id="button_finalizar"
                      color="#2EC9B7"
                    />

                    {session?.status === 'finished' && session?.menew_tried && (
                      <Button
                        onClick={() => menewDuplicate()}
                        style={{
                          background: '#2EC9B7',
                          marginRight: 12,
                          marginTop: 20,
                        }}
                        disabled={loadingNfce}
                        id="button_menew"
                      >
                        {loadingNfce
                          ? 'Finalizando, aguarde ...'
                          : 'Duplicidade Menew'}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
      <div>
        <ReactToPrint
          trigger={() => (
            <DisplayNoneImprimir>
              <Button id="only_print" />
            </DisplayNoneImprimir>
          )}
          content={() => componentRefPrint.current}
          onAfterPrint={() => afterPrint()}
        />
      </div>
      <DisplayNoneImprimir>
        <PrintNFCe html={html} ref={componentRefPrint} />
      </DisplayNoneImprimir>{' '}
      <Modal isOpen={modalDiferenca} toggle={toggleDiferenca}>
        <ModalBody>
          <h5 style={{ color: 'red' }}>
            O total dos pagamentos é {diferenca > 0 ? 'menor' : 'maior'} do que
            o valor da comanda.
          </h5>
          <p>
            Você deseja emitir a nota fiscal e finalizar a comanda com um
            {diferenca > 0 ? ' desconto' : ' acréscimo'} de R$
            {Math.abs(diferenca.toFixed(2))}?{' '}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => storeNfce(dataTo) && toggleDiferenca()}
            style={{ background: 'green' }}
          >
            {loadingNfce ? (
              <Spinner color="light" style={{ width: '1em', height: '1em' }} />
            ) : (
              'Sim'
            )}
          </Button>
          <Button onClick={toggleDiferenca} style={{ background: '#dc3545' }}>
            Não
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDiferenceValue} toggle={toggleDiferenceValue}>
        <ModalBody>
          <h5 style={{ color: 'red' }}>
            O total dos pagamentos é {diferenca > 0 ? 'menor' : 'maior'} do que
            o valor da comanda.
          </h5>
          <p>
            Você deseja finalizar a comanda com pagamento diferente do total?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              setSessionCompleted();
              toggleDiferenceValue();
            }}
            style={{ background: 'green' }}
          >
            Sim, finalizar
          </Button>
          <Button
            onClick={toggleDiferenceValue}
            style={{ background: '#dc3545' }}
          >
            Não
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
