/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from 'react';

import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import { Form } from '@unform/web';
import { Col, Row } from 'reactstrap';

import { FaCheck, FaTrash } from 'react-icons/fa';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import SearchInput from './SearchInput';

import {
  Card,
  Map,
  SearchBtn,
  CheckButton,
  TrashButton,
  NumberInput,
} from './styles';

import api from '~/services/api';

function MapEditor() {
  const [allAreas, setAllAreas] = useState([]);

  // Indica se algum dado foi alterado em caso positivo o botão é habilitado
  const [changed, setChanged] = useState([]);

  const fitZoom = useCallback(() => {
    const layers = L.mainMap.pm.getGeomanLayers();

    if (layers.length) {
      const group = new L.FeatureGroup(layers);

      L.mainMap.fitBounds(group.getBounds());
    }
  }, []);

  const updateMap = useCallback(async () => {
    const translation = {
      buttonTitles: {
        drawPolyButton: 'Desenhar uma região livre',
        drawRectButton: 'Desenhar uma região retangular',
        editButton: 'Alterar o formato de uma região',
        dragButton: 'Mover uma região de lugar',
        cutButton: 'Selecionar parte de uma região para remover',
        deleteButton: 'Apagar uma região',
      },
      zoom: {
        zoomInTitle: 'Aumentar o zoom do mapa',
        zoomOutTitle: 'Diminuir o zoom do mapa',
      },
    };

    let areas = [];
    let address = null;

    try {
      const { data } = await api.get('/restaurants/delivery-areas');

      areas = data.areas;
      address = data.address;
    } catch (err) {
      console.error('Failed to load distances');
    }

    // TODO: Adicionar tratamento para restaurantes sem endereço
    const vitoria_centro = [-20.3196699, -40.338479];

    const map_center = address?.latitude
      ? [address.latitude, address.longitude]
      : vitoria_centro;

    const map = L.map('leaflet-map', {
      zoomControl: false,
    }).setView(map_center, 13);

    L.control.zoom(translation.zoom).addTo(map);

    L.mainMap = map;

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    map.pm.addControls({
      editMode: true,
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawText: false,
      drawPolyline: false,
      rotateMode: false,
      drawPolygon: true,
      removalMode: true,
    });

    map.pm.setLang('br_custom', translation, 'pt_br');

    const pos = map_center;

    const icon = L.icon({
      iconUrl: '/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [-3, -76],
    });

    L.marker(pos, { icon, pmIgnore: true }).addTo(map);

    areas.forEach((area) => {
      area.key = v4();

      const feature = {
        type: 'Feature',
        geometry: area.area,
        properties: {
          key: area.key,
        },
      };

      const layer = L.geoJSON(feature).addTo(map);
      area.layer = layer;
    });

    fitZoom();
    setAllAreas(areas);
    setChanged(new Array(areas.length).fill(false));
  }, [fitZoom]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  // Adiciona eventos de layer
  const addEventsToLayer = useCallback(
    (layer, created) => {
      let layer_json = layer.toGeoJSON();

      if (layer_json.type === 'FeatureCollection') {
        [layer_json] = layer_json.features;
      }

      layer.off('mouseover');
      layer.on('mouseover', () => {
        layer.setStyle({ color: '#08FF08' });
        layer.bringToFront();

        const found = allAreas.find((a) => a.key === layer_json.properties.key);

        if (found) {
          const price = parseFloat(found.price).toFixed(2);
          const html = `<b>${found.name || 'Sem nome'}<b><br>R$${price}`;
          layer.bindTooltip(html);
          layer.openTooltip();
        }
      });

      layer.off('click');
      layer.on('click', () => {
        const found = allAreas.find((a) => a.key === layer_json.properties.key);

        if (found) {
          const anchor = document.getElementById(found.key);
          anchor.scrollIntoView({ behavior: 'smooth' });
          anchor.style.borderBottom = '2px solid #3bd2c1';
        }
      });

      layer.off('mouseout');
      layer.on('mouseout', () => {
        layer.setStyle({ color: '#3388ff' });
        layer.unbindTooltip();

        const found = allAreas.find((a) => a.key === layer_json.properties.key);

        if (found) {
          const anchor = document.getElementById(found.key);
          anchor.style.borderBottom = 'none';
        }
      });

      layer.off('pm:cut');
      layer.on('pm:cut', async (event) => {
        const found = allAreas.find((a) => a.key === layer_json.properties.key);

        if (!found) {
          return;
        }

        // Toda vez que uma layer é recortada uma nova é criada
        const { layer: new_layer, originalLayer } = event;

        try {
          const { id } = found;

          const new_layer_json = new_layer.toGeoJSON();

          const data = {
            area: new_layer_json.geometry,
          };

          await api.put(`/restaurants/delivery-areas/${id}`, data);

          new_layer.feature = new_layer_json;
          new_layer.feature.properties = {
            key: found.key,
          };

          found.area = new_layer_json.geometry;
          found.layer = new_layer;

          addEventsToLayer(new_layer);

          toast.success('Região atualizada.');
        } catch (err) {
          console.error('@@ Erro ao salvar região.', err);
          toast.error('Erro ao salvar região.');

          new_layer.remove();

          // Revertendo a edição feita no mapa
          const feature = {
            type: 'Feature',
            geometry: found.area,
            properties: {
              key: found.key,
            },
          };

          const revertedLayer = L.geoJSON(feature).addTo(L.mainMap);
          found.layer = revertedLayer;

          addEventsToLayer(revertedLayer);
        }
      });

      layer.off('pm:update');
      layer.on('pm:update', async (event) => {
        const found = allAreas.find((a) => a.key === layer_json.properties.key);

        if (!found) {
          return;
        }

        const { layer: new_layer } = event;
        const new_layer_json = new_layer.toGeoJSON();

        try {
          const { id } = found;

          const data = {
            area: new_layer_json.geometry,
          };

          await api.put(`/restaurants/delivery-areas/${id}`, data);

          found.area = new_layer_json.geometry;
          found.layer = new_layer;

          toast.success('Região atualizada.');
        } catch (err) {
          console.error('Erro ao salvar região.', err);
          toast.error('Erro ao salvar região.');

          layer.remove();

          // Revertendo a edição feita no mapa
          const feature = {
            type: 'Feature',
            geometry: found.area,
            properties: {
              key: found.key,
            },
          };

          const revertedLayer = L.geoJSON(feature).addTo(L.mainMap);
          found.layer = revertedLayer;

          addEventsToLayer(revertedLayer);
        }
      });

      layer.off('pm:remove');
      layer.on('pm:remove', async (e) => {
        const idx = allAreas.findIndex(
          (a) => a.key === layer_json.properties.key
        );

        if (idx < 0) {
          return;
        }

        try {
          const found = allAreas[idx];

          await api.delete(`/restaurants/delivery-areas/${found.id}`);

          changed.splice(idx, 1);
          allAreas.splice(idx, 1);

          setAllAreas([...allAreas]);
          setChanged([...changed]);

          toast.success('Deletado com sucesso!');
        } catch (err) {
          layer.addTo(L.mainMap);

          toast.error('Não foi possível deletar!');
        }
      });

      if (created) {
        setAllAreas([created, ...allAreas]);
        setChanged([true, ...changed]);
      }
    },
    [allAreas, changed]
  );

  const addEventsToMap = useCallback(() => {
    if (!L.mainMap) {
      return;
    }

    const map = L.mainMap;

    // Usando 'off' para evitar que um mesma função
    // seja disparada 2 vezes quando o evento ocorrer
    map.off('pm:create');
    map.on('pm:create', async (e) => {
      const { layer } = e;

      const newArea = {
        key: v4(),
        area: layer.toGeoJSON().geometry,
        name: null,
        price: '0.00',
      };

      try {
        const response = await api.post('/restaurants/delivery-areas', newArea);
        const created = response.data;

        newArea.id = created.id;
        newArea.layer = e.layer;

        e.layer.feature = e.layer.toGeoJSON();
        e.layer.feature.properties.key = newArea.key;

        addEventsToLayer(e.layer, newArea);

        toast.success('Região criada.');
      } catch (err) {
        console.error('Erro ao criar região.', err);
        toast.error('Erro ao criar região.');

        layer.remove();
      }
    });

    L.mainMap.pm.getGeomanLayers().map((l) => addEventsToLayer(l));
  }, [addEventsToLayer]);

  useEffect(() => {
    addEventsToMap();
  }, [addEventsToMap]);

  // Retorna se 2 polígonos são iguais usados os pontos, e
  // considerando a meas ordenação dos pontos
  function comparePolygonsPoints(poly1, poly2) {
    if (poly1.length !== poly2.length) {
      return false;
    }

    const size = poly1.length;

    for (let i = 0; i < size; i += 1) {
      const [lng1, lat1] = poly1[i];
      const [lng2, lat2] = poly2[i];

      if (
        lat1.toFixed(6) !== lat2.toFixed(6) ||
        lng1.toFixed(6) !== lng2.toFixed(6)
      ) {
        return false;
      }
    }

    return true;
  }

  const search = useCallback(
    async (data) => {
      if (!L.mainMap) {
        return;
      }

      try {
        const response = await api.get(
          `/restaurants/delivery-areas/search/${encodeURIComponent(
            data.address
          )}`
        );

        if (response.data.length) {
          const found = response.data.find((e) => {
            return e.class === 'boundary';
          });

          if (!found) {
            toast.error('Endereço não encontrado.');
            return;
          }

          const { geojson: geometry } = found;

          if (geometry.type === 'MultiPolygon') {
            geometry.type = 'Polygon';
            geometry.coordinates = geometry.coordinates[0];
          }

          const exists = L.mainMap.pm.getGeomanLayers().some((l) => {
            const points = l.getLatLngs()[0].map((p) => [p.lng, p.lat]);

            points.push([...points[0]]);

            return comparePolygonsPoints(points, geometry.coordinates[0]);
          });

          if (exists) {
            toast.error('A região pesquisada já existe.');
            return;
          }

          const newArea = {
            key: v4(),
            area: geometry,
            name: data.address,
            price: '0.00',
          };

          const response2 = await api.post(
            '/restaurants/delivery-areas',
            newArea
          );
          const created = response2.data;

          const feature = {
            type: 'Feature',
            geometry,
            properties: {
              key: newArea.key,
            },
          };

          const layer = L.geoJSON(feature).addTo(L.mainMap);

          newArea.id = created.id;
          newArea.layer = layer;

          addEventsToLayer(layer, newArea);

          L.mainMap.flyToBounds(layer.getBounds());
        }
        // toast.success('');
      } catch (e) {
        console.error('Failed to search', e);
        toast.error('Endereço não encontrado.');
      }
    },
    [addEventsToLayer]
  );

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleNameChange = useCallback(
    (e, i) => {
      allAreas[i].name = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [allAreas, changed]
  );

  const handleNumberChange = useCallback(
    (e, i) => {
      allAreas[i].price = e.floatValue / 100;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [allAreas, changed]
  );

  const handleTimeChange = useCallback(
    (e, i) => {
      allAreas[i].time = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [allAreas, changed]
  );

  const saveEditedArea = useCallback(
    async (i) => {
      const { id, name, price, area, time } = allAreas[i];

      const data = { name, price, area, time };

      try {
        await api.put(`/restaurants/delivery-areas/${id}`, data);

        changed[i] = false;
        setChanged([...changed]);

        toast.success('Alterações salvas com sucesso!');
      } catch (e) {
        toast.error('Não foi possível salvar a alteração!');
      }
    },
    [allAreas, changed]
  );

  const deleteArea = useCallback(
    async (i) => {
      const { id } = allAreas[i];

      try {
        await api.delete(`/restaurants/delivery-areas/${id}`);

        changed.splice(i, 1);
        const [area] = allAreas.splice(i, 1);

        if (area) {
          // Removendo layer do mapa
          area.layer.remove();
        }

        setAllAreas([...allAreas]);
        setChanged([...changed]);

        toast.success('Deletado com sucesso!');
      } catch (e) {
        toast.error('Não foi possível deletar!');
      }
    },
    [allAreas, changed]
  );

  return (
    <Card>
      <Row>
        <Col md="6">
          <Form onSubmit={(data) => search(data)}>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }}>
              <Col md="10">
                <SearchInput placeholder="Busque um endereço" name="address" />
              </Col>
              <Col md="2" style={{ paddingLeft: '0px' }}>
                <SearchBtn> Buscar </SearchBtn>
              </Col>
            </Row>
          </Form>

          <Map id="leaflet-map"> </Map>
        </Col>
        <Col md="6" style={{ paddingRight: '25px' }}>
          <Row style={{ height: '44px', marginBottom: '15px' }}>
            Para encontrar a região desejada recomendamos buscar por "Bairro - Cidade - Estado" ou "Cidade - Estado"
          </Row>

          <Row
            style={{
              borderTop: '1px solid #999',
              borderBottom: '1px solid #999',
              height: '44px',
              fontWeight: 'bold',
              color: '#555',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <Col md="6"> <span>Nome da região</span> </Col>

            <Col style={{ textAlign: "left" }}>
              <span style={{ marginLeft: -42 }}>Taxa</span>
            </Col>
            <Col>
              <span style={{ marginLeft: -8 }}>Tempo</span>
            </Col>
            <Col><span style={{ marginLeft: -32 }}>Ações</span></Col>
          </Row>

          <Row
            style={{
              height: '570px',
              background: '#fff',
              alignContent: 'flex-start',
              overflowY: 'scroll',
            }}
          >
            {allAreas.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  color: '#666',
                }}
              >
                Nenhuma região cadastrada
              </div>
            )}

            {allAreas.map((area, i) => {
              return (
                <Row
                  id={area.key}
                  key={area.key}
                  style={{

                    marginBottom: '5px',

                  }}
                >
                  <Col md="6" style={{ marginRight: -24 }} >
                    <input
                      name="name"
                      type="text"
                      placeholder="Escolha um nome"
                      style={{
                        height: '44px',
                        border: '1px solid #33333320',
                        borderRadius: '4px',
                        width: '100%',
                        padding: '0 10px',

                      }}
                      defaultValue={area.name}
                      onChange={(e) => handleNameChange(e, i)}
                    />
                  </Col>
                  <Col md="3" style={{ marginRight: -24 }}>
                    <NumberInput
                      prefix="R$"
                      format={currencyFormatter}
                      name="value"
                      defaultValue={0}
                      value={parseFloat(area.price) * 100}
                      onValueChange={(e) => handleNumberChange(e, i)}
                    />
                  </Col>
                  <Col style={{ marginRight: -24 }}>
                    <input
                      name="time"
                      type="text"
                      placeholder="Defina um tempo"
                      style={{
                        height: '44px',
                        border: '1px solid #33333320',
                        borderRadius: '4px',
                        width: '100%',
                        padding: '0 10px',
                      }}
                      defaultValue={area.time}
                      onChange={(e) => handleTimeChange(e, i)}
                    />
                  </Col>
                  <Col >
                    <div style={{ display: "flex" }}>
                      <CheckButton
                        disabled={!changed[i]}
                        onClick={() => saveEditedArea(i)}
                      >
                        <FaCheck />
                      </CheckButton>

                      <TrashButton disabled={false} onClick={() => deleteArea(i)}>
                        <FaTrash />
                      </TrashButton>
                    </div>

                  </Col>








                </Row>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default MapEditor;
