import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CheckboxTree from 'react-checkbox-tree';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { Modal, ModalBody, Row, Col, Card } from 'reactstrap';

import {
  FaAngleDown,
  FaAngleRight,
  FaEye,
  FaFolder,
  FaFolderOpen,
  FaRegCheckSquare,
  FaRegMinusSquare,
  FaRegSquare,
} from 'react-icons/fa';

import {
  Container,
  Header,
  WaitersTable,
  Delete,
  Actions,
  Wrapper,
  LinkButton,
} from './styles';

import { useAuth } from '~/context/AuthContext';

import Input from '~/components/Form/Input';
import SelectInput from '~/components/Form/SelectInput';

import EditUser from './Edit';
import Accesses from './Accesses';

import PersonalButton from '~/components/Buttons/PersonalButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';

function Users() {
  const [users, setUsers] = useState([]);
  const [devices, setDevices] = useState([]);

  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [permission, setPermission] = useState();
  const [editUser, setEditUser] = useState();
  const { user } = useAuth();

  const formRef = useRef(null);

  const [userRolesProfile, setUserRolesProfile] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  function toggleEditUser() {
    setIsEditUserOpen(!isEditUserOpen);
  }

  function openModalEditUser(user) {
    setEditUser(user);
    setListUsers(false);
    setIsEditUserOpen(true);
  }

  const getDevices = useCallback(async () => {
    try {
      const response = await api.get(`restaurants/stone/devices`);

      const devices = response.data
        .filter(dev => !dev.waiter)
        .map(dev => {
        return {
          label: dev.serial_number,
          value: dev.id,
          id: dev.id
        }
      });

      devices.push({
        label: 'Nenhum',
        value: null
      });
      
      setDevices(devices);
    } catch (error) {
      toast.error('Falha ao encontrar POS Stone.');
    }
  }, []);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'RegisterUsers'}`
      );

      const { can_read } = response.data.RegisterUsers;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  const getUsers = useCallback(async () => {
    const response = await api.get('/restaurants/users');

    const usersFiltered = response.data.filter(
      (us) => us.email !== user.email && !us.is_protected
    );

    setUsers(usersFiltered);
    getDevices();
  }, [user]);

  const getUserRolesProfiles = useCallback(async () => {
    const response = await api.get('/restaurants/user-roles');

    const rolesProfiles = response.data;

    const profileOption = rolesProfiles.map((item) => {
      return { label: item.name, value: item.id };
    });

    const roleFiltered = rolesProfiles.find(
      (item) => item.name === 'Administrador'
    );

    setUserRolesProfile(profileOption);
    setRoleId(roleFiltered.id);
  }, []);

  useEffect(() => {
    try {
      getUserRolesProfiles();
      // getDevices();
    } catch (error) {
      console.log(error);
    }
  }, [getUserRolesProfiles]);

  useEffect(() => {
    try {
      getUsers();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getUsers]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar o usuário?')) {
        await api.delete(`/restaurants/users/${id}`);

        getUsers();

        toast.success('Usuário deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar usuário');
    }
  }

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const [showAccessRegister, setShowAccessRegister] = useState(false);
  const [listUsers, setListUsers] = useState(true);

  function toggleListUsers() {
    setListUsers(!listUsers);
  }

  function toggleShowAccessRegister() {
    setShowAccessRegister(!showAccessRegister);
  }

  const [userRoles, setUserRoles] = useState([]);
  const [oldArray, setOldArray] = useState([]);
  const [roleId, setRoleId] = useState('475');

  const getUserRoles = useCallback(async () => {
    const response = await api.get(`/restaurants/user-roles/${roleId}`);

    const categories = response.data;

    const roles = categories.map((cat) => {
      return {
        value: cat.name.toLowerCase(),
        label: cat.name,
        className: 'personal_check',
        children: cat.permissions.map((per) => {
          return {
            value: JSON.stringify({ name: per.name, permissions: per.status }),
            label: per.name,
          };
        }),
      };
    });

    setUserRoles(roles);

    const arrTeste = [];

    categories.map((cat) =>
      cat.permissions
        .filter((filt) => filt.status.can_read)
        .map((item) => {
          return arrTeste.push(
            JSON.stringify({ name: item.name, permissions: item.status })
          );
        })
    );

    setChecked(arrTeste);
    setOldArray(arrTeste);
  }, [roleId]);

  useEffect(() => {
    try {
      getUserRoles();
    } catch (error) {
      toast.error('Erro ao carregar permissões');
      console.log(error);
    }
  }, [getUserRoles]);

  const [changedCheck, setChangedCheck] = useState(false);

  function handleCompareCheck(a, b) {
    const oldArrayParsed = a.map((item) => JSON.parse(item).permissions);

    const checkedParsed = b.map((item) => JSON.parse(item).permissions);

    if (oldArrayParsed.length === checkedParsed.length) {
      setChangedCheck(false);
    } else {
      setChangedCheck(true);
    }
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      if (changedCheck) {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Inserir um email válido')
            .required('Email ogrigatório'),
          password: Yup.string()
            .required('A senha é obrigatória')
            .min(6, 'Mínimo de 6 dígitos'),
          custom_name: Yup.string().required('Nome do acesso obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const parsed = checked
          .map((item) => JSON.parse(item).permissions)
          .map((item) => {
            return { ...item, can_read: true };
          });

        await api.post('restaurants/users', {
          name: data.name,
          email: data.email,
          password: data.password,
          stone_device_id: data.stone_device_id || null,
          role_id: null,
          role: {
            name: data.custom_name,
            permissions: parsed,
          },
        });
      } else {
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Inserir um email válido')
            .required('Email ogrigatório'),
          password: Yup.string()
            .required('A senha é obrigatória')
            .min(6, 'Mínimo de 6 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('restaurants/users', {
          name: data.name,
          email: data.email,
          password: data.password,
          stone_device_id: data.stone_device_id || null,
          role_id: roleId,
        });
      }

      toast.success('Usuário criado com sucesso!');

      setListUsers(true);
      setShowAccessRegister(false);

      getUsers();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (error.response.data.errorType === 'email_already_exists') {
        toast.error('Já existe um usuário cadastrado com esse email.');
      } else {
        toast.error('Falha ao criar usuário');
      }
    }
  }

  function handleChangeInputType() {
    const passwordInput = formRef.current.getFieldRef('password');

    passwordInput.type === 'password'
      ? (passwordInput.type = 'text')
      : (passwordInput.type = 'password');
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2 style={{ marginBottom: 30 }}>
            <strong> Gerenciar acessos </strong>
          </h2>
          <p>
            Gerencie os usuários de seu estabelecimento. Atribua o acesso delas
            e quais ferramentas elas podem usar.
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <PersonalButton
            message="Cadastrar usuário"
            onClick={() => {
              toggleShowAccessRegister();
              setListUsers(false);
              setIsEditUserOpen(false);
            }}
            color="#FF2C3A"
            Icon={MdAdd}
            disabled={showAccessRegister}
          >
            Adicionar
          </PersonalButton>

          {listUsers && (
            <LinkButton onClick={() => setOpenModal(true)}>
              Gerenciar tipos de usuário
            </LinkButton>
          )}
        </div>
      </Header>

      <Card style={{ width: '100%', paddingBottom: '20px' }}>
        {showAccessRegister && (
          <div>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col>
                    <Input name="name" label="Nome" type="text" />
                  </Col>
                  <Col>
                    <Input
                      name="email"
                      label="E-mail"
                      type="text"
                      autocomplete="off"
                    />
                  </Col>
                  <Col>
                    <Input
                      name="password"
                      label="Senha"
                      type="password"
                      autocomplete="off"
                      id="password"
                    />
                    <FaEye
                      style={{
                        position: 'absolute',
                        right: '40px',
                        top: '50px',
                        cursor: 'pointer',
                      }}
                      onClick={handleChangeInputType}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <SelectInput
                      name="access"
                      label="Tipo do usuário"
                      options={userRolesProfile}
                      defaultValue={userRolesProfile[0]}
                      onChange={(e) => {
                        setRoleId(e.value);
                        setChangedCheck(false);
                      }}
                    />
                  </Col>

                  {
                    user.has_stone_pdv &&
                      <Col md="4">
                        <SelectInput
                          label="POS Stone (S/N):" 
                          name="stone_device_id" 
                          options={devices.filter(dev => !users.map(user => user.stone_device_id).includes(dev.id))}
                          defaultValue={{value: null, label: 'Nenhum'}}
                        />
                      </Col>
                  }
                </Row>
              </ModalBody>

              <Wrapper>
                <Row>
                  <p style={{ marginLeft: '8px' }}>Acesso aos menus</p>
                </Row>
                {userRoles && (
                  <CheckboxTree
                    nodes={userRoles}
                    checked={checked}
                    expanded={expanded}
                    onCheck={(checked) => {
                      setChecked(checked);
                      handleCompareCheck(oldArray, checked);
                    }}
                    onExpand={(expanded) => setExpanded(expanded)}
                    icons={{
                      check: <FaRegCheckSquare size={22} color="#58CEB1" />,
                      uncheck: <FaRegSquare size={22} color="#58CEB1" />,
                      halfCheck: <FaRegMinusSquare size={22} color="#58CEB1" />,
                      expandClose: <FaAngleRight size={22} color="#58CEB1" />,
                      expandOpen: <FaAngleDown size={22} color="#58CEB1" />,
                      // expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                      // collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                      parentClose: <FaFolder size={22} color="#58CEB1" />,
                      parentOpen: <FaFolderOpen size={22} color="#58CEB1" />,
                      leaf: '',
                    }}
                  />
                )}

                {changedCheck && (
                  <Row className="mt-3">
                    <Col md="3">
                      <Input
                        name="custom_name"
                        placeHolder="Nome do acesso"
                        label="Defina um nome para este nível de acesso"
                      />
                      <span style={{ fontSize: '12px' }}>
                        Este nível de acesso poderá ser replicado no futuro.{' '}
                      </span>
                    </Col>
                  </Row>
                )}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    className="btn"
                    type="button"
                    style={{
                      color: '#FF2C3A',
                      border: '1px solid #FF2C3A',
                      background: '#fff',
                    }}
                    onClick={() => {
                      toggleShowAccessRegister();
                      setIsEditUserOpen(false);
                      setListUsers(true);
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    style={{ background: '#58CEB1' }}
                  >
                    Salvar
                  </button>
                </div>
              </Wrapper>
            </Form>
          </div>
        )}

        {isEditUserOpen && (
          <>
            <EditUser
              user={editUser}
              toggleEditUser={() => {
                toggleEditUser();
                toggleListUsers();
              }}
              getUsers={getUsers}
              devices={devices}
              hasStonePdv={user.has_stone_pdv}
            />
          </>
        )}

        {listUsers && (
          <>
            <WaitersTable borderless>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Nível de acesso</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user?.role?.name}</td>
                      <td>
                        <Actions>
                          <MdEdit
                            size={20}
                            onClick={() => {
                              openModalEditUser(user);
                            }}
                            color="blue"
                            title="Editar Usuário"
                            style={{ cursor: 'pointer' }}
                          />

                          <Delete onClick={() => handleDelete(user.id)}>
                            <MdDelete size={20} />
                          </Delete>
                        </Actions>
                      </td>
                    </tr>
                  ))}
                {users.length < 1 && <p>... Sem usuários cadastrados</p>}
              </tbody>
            </WaitersTable>
          </>
        )}
      </Card>

      <Modal toggle={() => setOpenModal(false)} isOpen={openModal} size="md">
        <ModalBody>
          <Accesses />
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default Users;
