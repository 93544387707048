import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { Form } from '@unform/web';
import { MdEdit } from 'react-icons/md';
import CheckboxInput from '~/components/Form/Checkbox';

import {
  Container,
  Card,
  Header,
  TablesTable,
  EditButton,
  DeleteAndEditActions,
} from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import SaveButton from '~/components/Buttons/SaveButton';
import DownloadButton from '~/components/Buttons/DownloadButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import PersonalButton from '~/components/Buttons/PersonalButton';
import Input from '~/components/Form/Input';
import { FaTrash } from 'react-icons/fa';

function Settings() {
  const { user, updateUser } = useAuth();
  const formRef = useRef(null);
  const [printers, setPrinters] = useState([]);
  const [printWeb, setPrintWeb] = useState(user.is_printed_on_web);
  const [activeTab, setActiveTab] = useState('1')
  function changePrintWeb() {
    setPrintWeb(!printWeb);
  }

  const getPrinters = useCallback(async () => {
    const response = await api.get('restaurants/printers');
    setPrinters(response.data);
  }, []);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'Configurations'}`
      );

      const { can_read } = response.data.Configurations;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  async function ClickSubmit(data) {
    try {
      let updateProfile;

      await api.put('/restaurants/settings', {
        print_complement_category_title: data.print_complement_category_title
      })

      if (data.is_printed_on_web) {
        updateProfile = {
          is_printed_on_web: data.is_printed_on_web,
          auto_print_orders: false,
          print_bills_on_web: false,
          print_separate_itens: false,
          print_transfer: false,
          print_canceled: false,
          auto_print_delivery: false,
          print_bills_delivery_on_web: false,
          // ifood_auto_accept: false,
          email: user.email,
          print_balcony_default: data.print_balcony_default,
          print_complement_category_title: data.print_complement_category_title
       
        };

    
      } else {
        updateProfile = {
          is_printed_on_web: data.is_printed_on_web,
          auto_print_orders: data.auto_print_orders,
          print_bills_on_web: data.print_bills_on_web,
          print_separate_itens: data.print_separate_itens,
          print_transfer: data.print_transfer,
          print_canceled: data.print_canceled,
          auto_print_delivery: data.auto_print_delivery,
          print_bills_delivery_on_web: data.print_bills_delivery_on_web,
          // ifood_auto_accept: data.ifood_auto_accept,
          email: user.email,
          print_balcony_default: data.print_balcony_default,
          print_complement_category_title: data.print_complement_category_title

        };
      }
      console.log('data');
      console.log(data);
      updateUser(updateProfile);
    } catch (err) {
      toast.error('Falha ao altualizar.');
    }
  }

  function downloadDriver() {
    window.open(
      'https://www.mediafire.com/file/xa94u24b4spc6am/Instalador.rar/file'
    );
  }

  useEffect(() => {
    try {
      getPrinters();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getPrinters]);

  const [isButtonDisable, setIsButtonDisable] = useState(true)
  const [pixelIdState, setPixelIdState] = useState(user.pixel_id)

  const handleSubmitPixelId = useCallback(async (data)=>{
   const  updateProfile = {pixel_id: data.pixel_id}
   try {
    updateUser(updateProfile);
    setPixelIdState(data.pixel_id)
   } catch(error){
    toast.error('Erro ao atualizar perfil!');
    console.log(error.message)
   }
  }, [])



  return !permission ? (
 
    <PermissionErrorContainer />
  ) : (
    <Container>
       { console.log(user.pixel_id)}
      <Header>
        <div>
          <h2 style={{fontWeight: 'bold', fontSize: '20px'}}>Configurações</h2>
          <p style={{fontSize: '18px'}}>Configure suas impressos e o pixel do facebook.</p>
        </div>
      </Header>

      <Nav tabs style={{marginLeft: 10}}>
          <NavItem>
            <NavLink
              onClick={() => { setActiveTab('1') }}
              className={activeTab === '1' ? 'active' : ''}
            >
              Impressora
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => { setActiveTab('2') }}
              className={activeTab === '2' ? 'active' : ''}
            >
              Pixel
            </NavLink>
          </NavItem>
        </Nav>
        <Card>
      <TabContent activeTab={activeTab}>
      
        <TabPane tabId='1'>
       

        <Form initialData={user} onSubmit={ClickSubmit} ref={formRef}>
          <Container>
            <h4>Download do Programa de Impressão da Takeat</h4>
            <p>
              O programa de Impressão da Takeat é uma forma de facilitar a
              impressão dos pedidos, fechamentos de contas e relatórios para
              você! Faça o download do programa clicando no botão abaixo,
              instale o programa, faça login com o mesmo e-mail e senha usados
              para acessar o Dashboard, cadastre sua(s) impressora(s), e
              configure-a(s) do jeito que quiser na parte de "Impressoras" aqui
              mesmo, na página de Configurações. Qualquer dúvida entre em
              contato com nossa equipe!
            </p>
            <Row>
              <Col sm="12" lg="6">
                <DownloadButton onClick={downloadDriver} />
              </Col>
            </Row>
            <h4 style={{ marginTop: 20 }}>Impressão Via Web</h4>
            <p>
              Ao deixar essa opção selecionada, todas as impressões serão feitas
              pelo navegador. Se desmarcada, é necessário ter o programa de
              impressão da Takeat instalado e com sua(s) impressora(s)
              configurada(s) no seu computador.
            </p>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Impressão via Web"
                  name="is_printed_on_web"
                  onClick={changePrintWeb}
                />
              </Col>
            </Row>
            <p style={{ marginTop: 20 }}>
              Ao ativar a Impressão de Fechamento de Comanda via Web, as
              comandas serão impressas via web mesmo com o programa da Takeat
              instalado no seu computador.
            </p>

            <Row>
              <Col sm="12" lg="6">
                {printWeb ? (
                  <CheckboxInput
                    label="Impressão de Fechamento de Comanda e Relatórios via Web"
                    name="print_bills_on_web"
                    disabled
                    checked={false}
                  />
                ) : (
                  <CheckboxInput
                    label="Impressão de Fechamento de Comanda e Relatórios via Web"
                    name="print_bills_on_web"
                  />
                )}
              </Col>
            </Row>

            <p style={{ marginTop: 20 }}>
              Ao ativar a Impressão de via de Entrega via Web, as
              comandas com informações de entrega do delivery serão impressas via web mesmo com o programa da Takeat
              instalado no seu computador.
            </p>

            <Row>
              <Col sm="12" lg="6">
                {printWeb ? (
                  <CheckboxInput
                    label="Impressão de via de Entrega via Web"
                    name="print_bills_delivery_on_web"
                    disabled
                    checked={false}
                  />
                ) : (
                  <CheckboxInput
                    label="Impressão de via de Entrega via Web"
                    name="print_bills_delivery_on_web"
                  />
                )}
              </Col>
            </Row>

            <Row>
            <p style={{ marginTop: 20 }}>
              Ao ativar a opção abaixo, permite que seja impresso o título da categoria de complemento.
            </p>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Imprimir título da categoria de complemento"
                  name="print_complement_category_title" 
                  defaultValue={user.print_complement_category_title}                
                />
              </Col>
            </Row>
           

            <h4 style={{ marginTop: 20 }}>Impressão Automática</h4>
            <p>
              Se a impressão for pelo programa de impressão da Takeat, instalado
              no seu computador, é possível que os pedidos sejam aceitos e
              impressos automaticamentes ao serem feitos. Com essa opção
              selecionada, todos os pedidos serão aceitos automaticamente e vão
              direto para a aba "Em Preparo" na área de acompanhar os pedidos.
            </p>

            <Row>
              <Col sm="12" lg="6">
                {printWeb ? (
                  <div>
                    <CheckboxInput
                      label="Impressão Automática de Pedidos"
                      name="auto_print_orders"
                      disabled
                      checked={false}
                    />
                    {user?.delivery_info?.is_delivery_allowed && (
                      <CheckboxInput
                        label="Impressão Automática de Pedidos de Delivery"
                        name="auto_print_delivery"
                        disabled
                        checked={false}
                      />
                    )}
                    <CheckboxInput
                      label="Imprimir Itens Separadamente"
                      name="print_separate_itens"
                      disabled
                      checked={false}
                    />
                    <CheckboxInput
                      label="Impressão Automática de Comanda de Balcão"
                      name="print_balcony_default"
                      disabled
                      checked={false}
                    />
                  </div>
                ) : (
                  <div>
                    <CheckboxInput
                      label="Impressão Automática de Pedidos"
                      name="auto_print_orders"
                    />
                    
                    <CheckboxInput
                      label="Imprimir Itens Separadamente"
                      name="print_separate_itens"
                    />
                    <CheckboxInput
                      label="Impressão Automática de Comanda de Balcão"
                      name="print_balcony_default"
                    />
                    <CheckboxInput
                      label="Impressão Automática de Pedidos Delivery"
                      name="auto_print_delivery"
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: 15 }}>
              <Col>
                <SaveButton type="submit">Salvar</SaveButton>
              </Col>
            </Row>
          </Container>
        </Form>
        <Container>
          <h4>Impressoras</h4>
        </Container>
        <TablesTable borderless>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Host</th>
              <th>Imprime</th>
              <th style={{ width: user.internal ? '120px' : 'auto' }}>Imprime todas categorias?</th>
              {user.internal && (
                <>
                  <th>Modelo</th>
                  <th style={{ width: '130px' }}>Método de Conexão</th>
                  <th>IP ou Porta</th>
                </>
              )}
              <th style={{ width: user.internal ? '110px' : 'auto' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {printers.map((printer) => (
              <tr>
                <td>{printer.name}</td>
                <td>{printer.host}</td>
                <td>
                  <p>{printer.print_bills_allowed && 'Comandas'}</p>
                  <p>{printer.print_orders_allowed && 'Pedidos'}</p>
                  <p>{printer.print_reports_allowed && 'Relatórios'}</p>
                </td>
                <td>{printer.is_category_limited ? 'Não' : 'Sim'}</td>
                {user.internal && (
                  <>
                    <td>{printer.model || '-'}</td>
                    <td>{printer.connection_method || '-' }</td>
                    <td>{printer.ip || '-'}</td>
                  </>
                )}
                <td>
                  <DeleteAndEditActions>
                    <EditButton
                      to={{
                        pathname: '/settings/printer',
                        state: {
                          printer,
                        },
                      }}
                    >
                      <div>
                        <MdEdit size={17} />
                        Editar
                      </div>
                    </EditButton>
                  </DeleteAndEditActions>
                </td>
              </tr>
            ))}
          </tbody>
        </TablesTable>
          
        </TabPane>
        <TabPane tabId='2'>
         <Card>
          <p style={{fontWeight: 'bold'}}>
          Facebook Pixel ID
          </p>
          <span>
          Você precisa ter uma conta anúncios do Meta para conseguir o Pixel ID.
          </span>
          <div>
            <Form onSubmit={handleSubmitPixelId}>
              <div style={{display: 'flex' , alignItems: 'center'}}>
                <Input name='pixel_id' onChange={()=>setIsButtonDisable(false)} />
                <PersonalButton type='submit' color='#58CEB1'message='Integrar' disabled={isButtonDisable}/>
              </div>

            </Form>
           
          </div>

          {pixelIdState && (
            <p>Pixel Id integrado: {pixelIdState} <FaTrash color='#FF2B3A' style={{marginLeft: 8}} cursor='pointer' onClick={()=>{handleSubmitPixelId({pixel_id: ''})}}/></p>
          )}
          
         </Card>
        </TabPane>
        
      

      </TabContent>
      
      </Card>
    </Container>
  );
}

export default Settings;
