import React, { useState } from 'react';
import axios from 'axios';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Badge,
} from 'reactstrap';

import {
  CategoriesTable,
  Container,
  NavDiv,
  NavDivTeste,
  ColorTag,
  LoadingDiv,
  EmptyDiv,
  NavLinkSpan,
} from './styles';

import Nfe from './Nfe';
import StockTransferManifestRow from '../Rows/StockTransferManifestRow';

export default function Nfes({ nfes, loading, html, inputs, cashFlows, handleUpdate, getInputs, getNfes }) {
  return (
    <Container>
      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Fornecedor</th>
            <th>Data de emissão</th>
            <th>Chave</th>
            <th>Número NFe</th>
            <th>Valor</th>
            <th>Ações</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td>
                <LoadingDiv>
                  <Spinner color="info" />
                </LoadingDiv>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        ) : (
          <tbody>
            {nfes.map((nfe) => (!nfe.is_stock ? 
              <Nfe
                key={nfe.id}
                nfe={nfe} html={html}
                getInputs={getInputs}
                inputs={inputs}
                handleUpdate={handleUpdate}
                cashFlows={cashFlows}
              />
              :
              <StockTransferManifestRow 
                key={nfe.id}
                stock={nfe}
                getNfes={getNfes}
              />
            ))}
          </tbody>
        )}
      </CategoriesTable>
    </Container>
  );
}
