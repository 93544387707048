import React, { useState } from 'react';

import { AiOutlineEye } from 'react-icons/ai';

import { Container, Modal, ModalBody } from 'reactstrap';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  OrdersInfo,
  Badge,
  SeeButton,
  Actions,
  DeleteAndEditActions,
  InfoDiv,
} from '../styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import formatCompleteDate from '~/services/formatDate';
import { useAuth } from '~/context/AuthContext';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function OrderCanceled({ item }) {
  const [modal, setModal] = useState(false);

  const { user } = useAuth();
  const toggle = () => setModal(!modal);

  const userName = item?.buyer
    ? formatPhone(
        item?.buyer?.phone ? item?.buyer.phone : item?.buyer?.ifood_phone
      ) || item?.buyer.name
    : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.phone
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    if (table?.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table?.table_number}`;
  }

  return (
    <Pedido>
      <ContentBody>
        {item?.table?.table_type !== 'delivery' && (
          <div>
            <OrderStatusTag text="Cancelado" color="#A1A1A1" width="147px" />
          </div>
        )}
        {item?.table?.table_type === 'delivery' ? (
          <InfoDiv>
            <OrderNumber>#{item?.attendance_password}</OrderNumber>
            {item && (
              <Time>{`${formatCompleteDate(
                item?.basket?.canceled_at
              )} - ${formatTime(item?.basket?.canceled_at)}`}</Time>
            )}
          </InfoDiv>
        ) : (
          <InfoDiv>
            <OrderNumber>#{item?.basket?.basket_id}</OrderNumber>
            {item && <Time>{formatTime(item?.basket?.start_time)}</Time>}
          </InfoDiv>
        )}

        <InfoDiv grow>
          <TableNumber>
            {item?.table?.table_type === 'command'
              ? `Comanda  ${item?.table.table_number} / Mesa ${
                  item?.basket?.command_table_number || 'não informada'
                } `
              : searchTableName(item?.table)}
          </TableNumber>
          {item?.basket?.schedule && (
            <PhoneNumber>
              <strong>{item?.basket?.schedule}</strong>
            </PhoneNumber>
          )}
          {item?.table.table_type === 'delivery' ? (
            <PhoneNumber>{userNameDelivery}</PhoneNumber>
          ) : (
            <PhoneNumber>{userName}</PhoneNumber>
          )}
        </InfoDiv>
        <Actions>
          <DeleteAndEditActions>
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Detalhes
              </div>
            </SeeButton>
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>
    </Pedido>
  );
}
