import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export const Container = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Card = styled.div`
  width: 90%;
  min-width: 400px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;
export const DayButton = styled.button`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${(props) => (props.isSelected ? '500' : '400')};
  font-size: 14px;
  color: ${(props) => (props.isSelected ? '#ffffff' : '#4D4D4C')};
  border: none;
  height: 58px;
  width: 58px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: ${(props) =>
    props.disabled ? '#D9D9D9' : props.isSelected ? '#FF1C32' : '#fff0f2'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  p {
    font-weight: normal;
  }

  :hover {
    border: 1px solid #ff1c32;
  }
`;
export const Content = styled.div`
  width: 95%;
  padding: 30px;
`;

export const Titles = styled.p`
  font-weight: bold;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
`;
export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;
export const MethodsTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0px 0px;
  margin-top: 20px;

  width: 50%;

  thead {
    border-bottom: 0px solid transparent;
    text-align: left;
    tr {
    }
  }
  tbody {
    tr {
    }
    td {
      /* border-bottom: 1px solid #eee; */

      input {
        width: 120px;
        border: 1px solid #979797;
        color: #979797;
        /* border-bottom: 1px solid #33333330; */
        transition: all 1s;
        text-align: center;
        border-radius: 5px;
        padding: 7px 14px;

        :focus {
          border-bottom: 1px solid #333;
        }

        :disabled {
          background: transparent;
          color: #d9d9d9;
          border: 1px solid #d9d9d9;
        }
      }
    }

    tr:last-child {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
  }
`;
