import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;

  width: 100%;

  height: 40px;

  margin-bottom: 10px;
  margin-left: -5px;
`;

export const FormArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  h6 {
    margin-top: 8px;
    margin-left: 8px;
  }

  button {
    border: none;
    padding: 7px 14px;
    color: #fff;
    background: #28a745;
    border-radius: 7px;
    margin-bottom: 8px;
  }
`;
