import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import { toast } from 'react-hot-toast';
import { AiOutlineEye, AiOutlinePrinter } from 'react-icons/ai';
import { FaHandHoldingUsd, FaRedo, FaExclamationCircle } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import FooterNF from '~/components/FooterNF';
import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';

import {
  Actions,
  StatusTag,
  ModalPaymentBody,
  DisplayNoneImprimir,
  ActionsInside,
  DeleteAndEditActions,
  CloseButton,
  SeeButton,
  CashierButton,
  ReopenButton,
  Print,
  ButtonStyled,
} from './styles';
import PaymentForm from '~/components/PaymentForm';
import formatCompleteDate from '~/services/formatCompleteDate';
import OrdersBill from '~/components/Tables/Bills/OrdersBill';
import PrintBillsTable from '~/components/Prints/PrintBillsTable';
import EyeBillsTable from '~/components/Tables/CloseOrder/Eye/EyeBillsTable';

import EyeOrdersBill from '~/components/Tables/EyeOrdersBill';

import api from '~/services/api';
import Input from '../Form/Input';
import getValidationErrors from '~/services/getValidationErrors';

export default function Bill({
  session,
  getSessions,
  // payments,
  // setAllPayments,
  // paymentMethods,
  setSessions,
  sessions,
  finalDate,
  inicialDate,
  handleUpdate,
}) {
  const { user } = useAuth();
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);
  const [rescues, setRescues] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:rescues')) || []
  );

  useEffect(() => {
    localStorage.setItem('@gddashboard:rescues', JSON.stringify(rescues));
  }, [rescues]);

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState('');
  const [bills, setBills] = useState();
  const [printBills, setPrintBills] = useState();
  const [productBills, setProductBills] = useState();
  const [sessionState, setSessionState] = useState();
  const [payments, setAllPayments] = useState(session?.payments);
  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const [modalOpeningBox, setModalOpeningBox] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const status = useMemo(() => {
    if (session.status === 'open') {
      return {
        statusText: 'Aberta',
        color: '#17a2b8',
      };
    }
    if (session.status === 'closing') {
      return {
        statusText: 'Fechando',
        color: '#17a2b8',
      };
    }
    if (session.status === 'transfer') {
      return {
        statusText: 'Transferida',
        color: '#17a2b8',
      };
    }
    if (session.status === 'finished') {
      return {
        statusText: 'Fechada',
        color: 'green',
      };
    }
    if (session.status === 'finished2') {
      return {
        statusText: 'Fechada',

        color: '#dc3545',
      };
    }
    if (session.status === 'completed') {
      return {
        statusText: 'Finalizada',

        color: '#dc3545',
      };
    }
    return {
      statusText: '',
      color: '#ddd',
    };
  }, [session.status]);

  const [modal, setModal] = useState(false);

  const [isModalTransferPasswordOpen, setIsModalTransferPasswordOpen] =
    useState(false);

  const [transferDataState, setTransferDataState] = useState();

  const [modalEye, setModalEye] = useState(false);

  const [modalEyeOpen, setModalEyeOpen] = useState(false);
  function toggleEyeOpen() {
    setModalEyeOpen(!modalEyeOpen);
  }

  const [modalReopen, setModalReopen] = useState(false);
  function toggleReopen() {
    setModalReopen(!modalReopen);
  }

  const [modalRefinish, setModalRefinish] = useState(false);
  function toggleRefinish() {
    setModalRefinish(!modalRefinish);
  }

  const [modalTransfer, setModalTransfer] = useState(false);
  async function toggleTransfer() {
    await getTables();
    setModalTransfer(!modalTransfer);
  }

  const [paid, setPaid] = useState(0);
  const [porcent, setPorcent] = useState(0);

  const [tables, setTables] = useState([]);

  const getTables = useCallback(async () => {
    const response = await api.get('/restaurants/tables');
    const tables = response.data.filter(
      (table) => table.status === 'available' && (table?.table_type === 'table'  || table?.table_type === 'command')
    );
    const tablesOptions = [];

    tables.forEach((table) => {
      tablesOptions.push({
        value: table.id,
        label: table.table_number.toString(),
      });
    });

    setTables(tablesOptions);
  }, []);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: session.id,
        close_after_print: true,
      });
      const newSessions = sessions.filter((s) => s.id !== session.id);
      setSessions(newSessions);
      toast.success('Comanda impressa e fechada.');
    } catch (error) {
      toast.error('Erro ao imprimir e fechar comanda.');
    }
  }

  // async function printClickWithoutClose() {
  //   try {
  //     await api.post('restaurants/printers/printer-queue', {
  //       table_session_id: session.id,
  //       close_after_print: false,
  //     });
  //     toast.success('Comanda impressa.');
  //   } catch (error) {
  //     toast.error('Erro ao imprimir comanda.');
  //   }
  // }

  const [paymentsBill, setPaymentsBill] = useState();

  async function handlePrintItem({ session_key }) {
    try {
      await api.put('restaurants/close-table-bill', {
        key: session_key,
      });
      getSessions();
      toast.success('Comanda fechada!');
    } catch (error) {
      toast.error('Não foi possível finalizar a comanda! tente novamente');
    }
  }

  const getPayments = useCallback(() => {
    let total = 0;

    paymentsBill &&
      paymentsBill.forEach((payment) => {
        total += parseFloat(payment.payment_value);
      });
    setPaid(total.toFixed(2).toString());

    const troco =
      total > session?.total_service_price
        ? total - session?.total_service_price
        : 0;

    const porcentPaid = session?.is_delivery
      ? (total / session?.total_delivery_price) * 100
      : ((total - troco) / session?.total_service_price) * 100;

    setPorcent(isNaN(porcentPaid) ? 0 : parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsBill, session]);

  const toggle = useCallback(() => {
    setModal(!modal);
    getPayments();
  }, [setModal, getPayments, modal]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  const toggleEye = useCallback(() => {
    setModalEye(!modalEye);
    getPayments();
  }, [getPayments, modalEye]);

  const componentRef = useRef(null);

  async function handleReopenTableBill() {
    try {
      await api.put('restaurants/reopen-table-bill', {
        key: session.key,
      });
      getSessions();
      toast.success('Comanda reaberta!');
    } catch (error) {
      if (session.table.status === 'ongoing') {
        toast.error('Essa mesa está aberta com outra sessão.');
      } else {
        toast.error('Erro ao reabrir mesa.');
      }
    }
  }

  async function handleRefinishSession(data) {
    setLoading(true);
    if (data.reopen_password === '' || !data.reopen_password) {
      toast.error('Digite a senha administrativa.');
      return false;
    }

    try {
      await api.put('restaurants/finish-completed-session', {
        key: session.key,
        reopen_password: data.reopen_password,
      });
      getSessions();
      toggleRefinish();
      toast.success('Comanda reativada!');
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error('Erro ao reativar mesa.');
      }
    }
    setLoading(false);
  }

  function toggleModalTransferPassword() {
    setIsModalTransferPasswordOpen(!isModalTransferPasswordOpen);
  }

  function handleTransferTable(data) {
    if (user.has_order_transfer_password) {
      toggleModalTransferPassword();
      setTransferDataState(data);
    } else {
      handleTransferTableBill(data);
    }
  }

  async function handleTransferTableBill(data) {
    setIsSubmiting(true);
    try {
      await api.put('restaurants/transfer-table-bill', {
        key: session.key,
        table_id: data.new_table_id,
      });
      getSessions();
      toggleTransfer();
      toast.success('Mesa transferida com sucesso!');
      setIsSubmiting(false);
    } catch (error) {
      toast.error('Erro ao transferir mesa.');
      setIsSubmiting(true);
    }
  }

  async function handleSentMenew() {
    try {
      await api.post('restaurants/menew/send-bills', {
        session_key: session.key,
      });
      toast.success('Enviado para Menew!');
    } catch (error) {
      toast.error('Não foi possível enviar para Menew. Tente novamente');
    }
  }

  async function handleVerifyTransferPassword(data) {
    setIsSubmiting(true);
    const response = await api.post('/restaurants/verify-transfer', {
      order_transfer_password: data.order_transfer_password,
    });

    if (response.data.success) {
      handleTransferTableBill(transferDataState);
      toggleModalTransferPassword();
    } else {
      toast.error('Senha incorreta');
      setIsSubmiting(false);
    }
  }

  const getBills = useCallback(async () => {
    const res = await api.get(`restaurants/table-sessions-bills/${session.id}`);

    setBills(res.data.bills);
    setPrintBills(res.data.print_bills);
    setSessionState(res.data.session);
    setProductBills(res.data.products);
  }, [session.id]);

  // useEffect(() => {
  //   getBills();
  // }, [getBills]);

  useEffect(() => {
    const payments_bill = payments;

    setPaymentsBill(payments_bill);
  }, [session, payments]);

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  function searchTableName(table) {
    if (table?.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table?.table_type === 'pos') {
      return 'POS';
    }

    if (table?.table_type === 'command') {
      return `Comanda ${table?.table_number}`;
    }

    if (table?.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table?.table_type === 'delivery') {
      if (session?.with_withdrawal && session?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (session?.with_withdrawal) {
        return 'Retirada';
      }

      if (!session?.with_withdrawal && session?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa ${table?.table_number}`;
  }

  const openingBoxRef = useRef();

  const toggleModalOpeningBox = useCallback(() => {
    setModalOpeningBox(!modalOpeningBox);
  }, [modalOpeningBox]);

  const [modalType, setModalType] = useState('');

  const verifyOpeningBoxSessionOpen = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggle();
    } catch (err) {
      if (err.response.data.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
        setModalType('modal');
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggle, toggleModalHasOpenedBoxMessage]);

  const verifyOpeningBox = useCallback(async () => {
    try {
      await api.get('/restaurants/cashier-opening-verify');

      toggleEye();
    } catch (err) {
      if (err.response.data.errorType === 'cashier_opening_not_found') {
        toggleModalHasOpenedBoxMessage();
        setModalType('modalEye');
      } else {
        toast.error('Erro ao carregar valores totais');
      }
    }
  }, [toggleEye, toggleModalHasOpenedBoxMessage]);

  const [isModalOnlineDepositsOpened, setIsModalOnlineDepositsOpened] =
    useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();

          if (modalType === 'modal') {
            toggle();
          }

          if (modalType === 'modalEye') {
            toggleEye();
          }

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      toggle,
      toggleEye,
      modalType,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );

  return (
    <>
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((item) => item.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <FaExclamationCircle color="darkOrange" size={32} />
            <h3 style={{ marginTop: '15px' }}>
              Você precisa abrir seu caixa para inserir pagamentos.
            </h3>

            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              operationType="withdrawal"
              type="button"
              onClick={toggleModalHasOpenedBoxMessage}
            >
              Cancelar
            </ButtonStyled>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalErrorUserPdvOpened}
        toggle={toggleModalErrorUserPdv}
      >
        <ModalBody>
          Seu perfil de usuário não possui permissão de abrir caixa. Solicite ao
          seu administrador
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%' }}>
            <ButtonStyled
              operationType="deposit"
              type="button"
              onClick={toggleModalErrorUserPdv}
              style={{ float: 'right' }}
            >
              Entendi, irei solicitar.
            </ButtonStyled>
          </div>
        </ModalFooter>
      </Modal>

      <tr>
        <td>
          {searchTableName(session?.table)}
        </td>
        <td>{formatCompleteDate(session.start_time)}</td>
        <td>#{session.id}</td>

        <td>R${session.is_delivery ? session.total_delivery_price : session.total_service_price}</td>
        <td>R${paid}</td>
        <td>
          <StatusTag color={porcent >= 100 ? 'green' : '#dc3545'}>
            <span>
              {status.statusText} {porcent}%
            </span>
          </StatusTag>
        </td>
        <td>
          {' '}
          {
            !session.is_delivery ?
              <Actions>
                {session.status === 'finished' || session.status === 'completed' ? (
                  <>
                    <ActionsInside>
                      <DeleteAndEditActions>
                        {session.status === 'completed' && user.has_menew && (
                          <CloseButton
                            onClick={() => handleSentMenew(session.key)}
                            style={{ background: 'green' }}
                          >
                            <div style={{ fontSize: 10 }}>Enviar Menew</div>
                          </CloseButton>
                        )}
                        {user.is_printed_on_web || user.print_bills_on_web ? (
                          <ReactToPrint
                            onBeforeGetContent={async () => {
                              await getBills();
                            }}
                            trigger={() => (
                              <CloseButton>
                                <div>
                                  <AiOutlinePrinter
                                    size={17}
                                    style={{ marginRight: 3 }}
                                  />
                                  Imprimir
                                </div>
                              </CloseButton>
                            )}
                            content={() => componentRef.current}
                          />
                        ) : (
                          <CloseButton onClick={printClick}>
                            <div>
                              <AiOutlinePrinter
                                size={17}
                                style={{ marginRight: 3 }}
                              />
                              Imprimir
                            </div>
                          </CloseButton>
                        )}

                        {session?.status !== 'completed' ? (
                          <>
                            <CashierButton onClick={verifyOpeningBox}>
                              <div>
                                <FaHandHoldingUsd
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Caixa
                              </div>
                            </CashierButton>

                            {!session?.is_prepaid &&
                              !(session?.table?.table_type === 'pos') && (
                                <ReopenButton onClick={toggleReopen}>
                                  <div>
                                    <FaRedo size={13} style={{ marginRight: 6 }} />
                                    Reabrir
                                  </div>
                                </ReopenButton>
                              )}
                          </>
                        ) : (
                          <>
                            {user.order_cancel_password !== '' ? (
                              <ReopenButton onClick={toggleRefinish}>
                                <div>
                                  <FaRedo size={13} style={{ marginRight: 6 }} />
                                  Reativar
                                </div>
                              </ReopenButton>
                            ) : (
                              <div />
                            )}
                          </>
                        )}
                      </DeleteAndEditActions>
                    </ActionsInside>
                  </>
                ) : (
                  <div>
                    <ActionsInside>
                      <DeleteAndEditActions>
                        {user.is_printed_on_web || user.print_bills_on_web ? (
                          <ReactToPrint
                            onBeforeGetContent={async () => {
                              await getBills();
                            }}
                            trigger={() => (
                              <CloseButton>
                                <div>
                                  <AiOutlinePrinter
                                    size={17}
                                    style={{ marginRight: 3 }}
                                  />
                                  Fechar
                                </div>
                              </CloseButton>
                            )}
                            content={() => componentRef.current}
                            onAfterPrint={() =>
                              handlePrintItem({
                                session_key: session.key,
                              })
                            }
                          />
                        ) : (
                          <CloseButton onClick={printClick}>
                            <div>
                              <AiOutlinePrinter
                                size={17}
                                style={{ marginRight: 3 }}
                              />
                              Imprimir
                            </div>
                          </CloseButton>
                        )}

                        {session.status !== 'completed' && (
                          <>
                            <CashierButton onClick={verifyOpeningBoxSessionOpen}>
                              <div>
                                <FaHandHoldingUsd
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Caixa
                              </div>
                            </CashierButton>

                            <SeeButton onClick={toggleEyeOpen}>
                              <div>
                                <AiOutlineEye
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Ver
                              </div>
                            </SeeButton>
                          </>
                        )}
                        {session.status !== 'completed' && (
                          <ReopenButton
                            onClick={toggleTransfer}
                            style={{ background: '#ffc107', color: 'black' }}
                          >
                            <div>
                              <BiTransfer size={13} style={{ marginRight: 6 }} />
                              Mudar Mesa
                            </div>
                          </ReopenButton>
                        )}
                      </DeleteAndEditActions>
                    </ActionsInside>
                  </div>
                )}
              </Actions>
            :
              <Actions>
                {session.status === 'finished' || session.status === 'completed' ? (
                  <>
                    <ActionsInside>
                      <DeleteAndEditActions>
                        {user.is_printed_on_web || user.print_bills_on_web ? (
                          <ReactToPrint
                            onBeforeGetContent={async () => {
                              await getBills();
                            }}
                            trigger={() => (
                              <CloseButton>
                                <div>
                                  <AiOutlinePrinter
                                    size={17}
                                    style={{ marginRight: 3 }}
                                  />
                                  Imprimir
                                </div>
                              </CloseButton>
                            )}
                            content={() => componentRef.current}
                          />
                        ) : (
                          <CloseButton onClick={printClick}>
                            <div>
                              <AiOutlinePrinter
                                size={17}
                                style={{ marginRight: 3 }}
                              />
                              Imprimir
                            </div>
                          </CloseButton>
                        )}

                        {session.status !== 'completed' && (
                          <>
                            <CashierButton onClick={verifyOpeningBox}>
                              <div>
                                <FaHandHoldingUsd
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Caixa
                              </div>
                            </CashierButton>

                            {!session?.is_prepaid &&
                              !(session?.table?.table_type === 'pos') && (
                                <ReopenButton onClick={toggleReopen}>
                                  <div>
                                    <FaRedo size={13} style={{ marginRight: 6 }} />
                                    Reabrir
                                  </div>
                                </ReopenButton>
                              )}
                          </>
                        )}
                      </DeleteAndEditActions>
                    </ActionsInside>
                  </>
                ) : (
                  <div>
                    <ActionsInside>
                      <DeleteAndEditActions>
                        {session.status !== 'completed' && (
                          <>
                            <CashierButton onClick={verifyOpeningBoxSessionOpen}>
                              <div>
                                <FaHandHoldingUsd
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Caixa
                              </div>
                            </CashierButton>

                            <SeeButton onClick={toggleEyeOpen}>
                              <div>
                                <AiOutlineEye
                                  size={17}
                                  style={{ marginRight: 3 }}
                                />
                                Ver
                              </div>
                            </SeeButton>
                          </>
                        )}
                      </DeleteAndEditActions>
                    </ActionsInside>
                  </div>
                )}
              </Actions>
            }
        </td>
        <td>
          <Modal
            isOpen={modal}
            toggle={toggle}
            size="lg"
            onClosed={handleUpdate}
          >
            <ModalBody>
              <ModalPaymentBody>
                <PaymentForm
                  session={session}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  // paymentMethods={paymentMethods}
                  setCount={setCount}
                  getSessions={getSessions}
                  setRescues={setRescues}
                />
                <OrdersBill session={session} bills={bills} />
              </ModalPaymentBody>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalEyeOpen} toggle={toggleEyeOpen}>
            <ModalBody>
              <Container>
                <EyeOrdersBill
                  session={session}
                  setSessions={setSessions}
                  inicialDate={inicialDate}
                  finalDate={finalDate}
                  toggleEyeOpen={toggleEyeOpen}
                  modalEyeOpen={modalEyeOpen}
                  getSessions={getSessions}
                />
              </Container>
            </ModalBody>
            <ModalFooter>
              {user.is_printed_on_web || user.print_bills_on_web ? (
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    await getBills();
                  }}
                  trigger={() => (
                    <Print>
                      <AiOutlinePrinter color="black" size={20} />
                    </Print>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                <Print onClick={printClick}>
                  <AiOutlinePrinter color="black" size={20} />
                </Print>
              )}
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={isModalTransferPasswordOpen}
            toggle={toggleModalTransferPassword}
          >
            <Form onSubmit={handleVerifyTransferPassword}>
              <ModalBody>
                <Input
                  name="order_transfer_password"
                  label="Senha de Transferência"
                  type="password"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  style={{ background: 'green' }}
                  disabled={isSubmiting}
                >
                  Transferir
                </Button>

                <Button
                  onClick={toggleModalTransferPassword}
                  style={{ background: '#dc3545' }}
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalReopen} toggle={toggleReopen}>
            <ModalBody>
              {session.table?.status === 'available' ||
              session.table?.table_type === 'balcony' ? (
                <p>Tem certeza que deseja reabrir essa comanda?</p>
              ) : (
                <p style={{ color: 'red' }}>
                  Essa mesa está com uma sessão ativa no momento.
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              {(session.table?.status === 'available' ||
                session.table?.table_type === 'balcony') && (
                <Button
                  onClick={handleReopenTableBill}
                  style={{ background: 'green' }}
                >
                  Sim, reabrir
                </Button>
              )}
              <Button onClick={toggleReopen} style={{ background: '#dc3545' }}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={modalRefinish} toggle={toggleRefinish}>
            <Form onSubmit={handleRefinishSession}>
              <ModalBody>
                <p>
                  <strong>Tem certeza que deseja reativar essa comanda?</strong>
                </p>
                <p>Ela voltará para as Comandas Fechadas.</p>
                {user.has_nfce && (
                  <p>
                    Se a nota fiscal da comanda foi emitida nos últimos 30
                    minutos, ela será cancelada na SEFAZ. Sendo necessário
                    emitir novamente.
                  </p>
                )}
                <Input
                  name="reopen_password"
                  label="Senha administrativa"
                  type="password"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={toggleRefinish}
                  style={{ background: '#dc3545' }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  style={{ background: 'green' }}
                  disabled={loading}
                >
                  Sim, reativar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={modalTransfer} toggle={toggleTransfer}>
            <Form onSubmit={handleTransferTable}>
              <ModalBody>
                <h3>Transferência de Mesa</h3>
                {tables.length !== 0 ? (
                  <Row>
                    <Col md="4">
                      <Select
                        name="new_table_id"
                        label="Nova mesa:"
                        options={tables}
                      />
                    </Col>
                    <Col md="6">
                      <h6>
                        Mesa atual:{' '}
                        <strong>{session.table?.table_number}</strong>
                      </h6>
                    </Col>
                  </Row>
                ) : (
                  <p style={{ color: 'red' }}>
                    Não há nenhuma mesa disponível no momento.
                  </p>
                )}
              </ModalBody>
              <ModalFooter>
                {tables.length !== 0 && (
                  <Button
                    type="submit"
                    style={{ background: 'green' }}
                    disabled={isSubmiting}
                  >
                    Transferir
                  </Button>
                )}
                <Button
                  onClick={toggleTransfer}
                  style={{ background: '#dc3545' }}
                >
                  Cancelar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <DisplayNoneImprimir>
            <PrintBillsTable
              item={session}
              restaurant={user}
              ref={componentRef}
              paymentData={payments}
              printBills={printBills}
              sessionState={sessionState}
              count={count}
              productBills={productBills}
            />
          </DisplayNoneImprimir>

          <Modal
            isOpen={modalEye}
            toggle={toggleEye}
            size="lg"
            onClosed={handleUpdate}
          >
            <ModalBody>
              <ModalPaymentBody>
                {/* <EyePaymentBills session={session} restaurant={user} /> */}
                <PaymentForm
                  session={session}
                  payments_bill={paymentsBill}
                  setPaymentsBill={setPaymentsBill}
                  all_payments={payments}
                  getPaymentsBill={getPayments}
                  setAllPayments={setAllPayments}
                  // paymentMethods={paymentMethods}
                  setCount={setCount}
                  getSessions={getSessions}
                  setRescues={setRescues}
                />
                <EyeBillsTable
                  item={session}
                  payments_bill={paymentsBill}
                  modalEye={modalEye}
                />
                {/* <OrdersBill session={session} /> */}
              </ModalPaymentBody>
              {session.status === 'finished' && (
                <ModalFooter>
                  <FooterNF
                    session={session}
                    toggleEye={toggleEye}
                    getSessions={getSessions}
                    payments={payments}
                    has_nfce={user.has_nfce}
                    setCount={setCount}
                    rescues={rescues}
                  />
                </ModalFooter>
              )}
            </ModalBody>
          </Modal>
        </td>
      </tr>
    </>
  );
}
