import React, { useState, useCallback, useEffect } from 'react';

import {
  TransfersTable,
  SearchDiv,
  SearchBtn,
  TableRow,
  TableHeader,
  TableBody,
  RowContainer,
  CollapsedRow,
  WarningContainer,
  Container,
  StyledDatePicker,
  NotFoundText,
} from './styles';

import { toast } from 'react-hot-toast';
import api from '~/services/api';
import { FaExclamationCircle, FaSearch, FaTimes } from 'react-icons/fa';
import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import { Spinner } from 'reactstrap';
import { FiArrowRight } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import formatValue from '~/services/formatValue';
import ReactDatePicker from 'react-datepicker';
import { TiArrowSortedDown } from 'react-icons/ti';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

function Transfers() {
  const [history, setHistory] = useState([]);
  const [historyToShow, setHistoryToShow] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const now = new Date();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(startOfDay(now));
  const [endDate, setEndDate] = useState(endOfDay(now));

  const getFirstLetter = (word) => {
    return word.substring(0, 1).toUpperCase();
  };

  const colors = {
    item: '#3BD2C1',
    'item-amount': '#3BD2C1',
    table: '#FF2C3A',
    client: '#01AFFF',
    waiter: '#FF7D24',
  };

  const types = {
    item: 'pedido',
    'item-amount': 'quantidade',
    table: 'mesa',
    waiter: 'garçom',
    client: 'cliente',
  };

  const updateHistory = useCallback(async () => {
    setLoading(true);

    const ids = search
      .trim()
      .split(/\s*,\s*/)
      .filter((id) => id !== '');

    const only_numbers = !ids.some((id) => !Number(id));

    if (!only_numbers) {
      toast.error('Use apenas números ao inserir as comandas.');
      return;
    }

    const response = await api.get(`/restaurants/history-transfer/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });

    const newHistory = response.data || [];

    for (const item of newHistory) {
      item.transfer_total = (
        parseFloat(item.src_session_total_price_before) -
        parseFloat(item.src_session_total_price_after)
      ).toFixed(2);
    }

    console.log('new > ', newHistory);
    setHistory(newHistory);
    setHistoryToShow(newHistory);
    setLoading(false);
  }, [setHistory, setHistoryToShow, startDate, endDate]);

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const handleDateUpdate = (date) => {
    setStartDate(date);
    setEndDate(addDays(date, 1));
  };

  useEffect(() => {
    updateHistory();
  }, [startDate, endDate]);

  useEffect(() => {
    if (search.length > 0) {
      setHistoryToShow(
        history.filter(
          (h) =>
            h.src_basket_key
              ?.replace('-', '')
              .startsWith(search.replace('-', '')) ||
            h.dest_basket_key
              ?.replace('-', '')
              .startsWith(search.replace('-', '')) ||
            h.table_baskets?.find((tb) =>
              tb.basket_id.replace('-', '').startsWith(search.replace('-', ''))
            )
        )
      );
    } else {
      setHistoryToShow(history);
    }
  }, [search]);


  const [permission, setPermission] = useState();


  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationHistoryTransfers'}`
      );

      const { can_read } = response.data.OperationHistoryTransfers;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <h1>Transferências</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '48px',
        }}
      >
        <h2>
          Acompanhe o histórico diário de transferência realizada na sua loja.
        </h2>
        <StyledDatePicker
          onClick={(e) => {
            const check =
              typeof e.target.className === 'string' &&
              e.target.className.includes('react-datepicker');
            if (check) {
              setOpenCalendar(true);
            } else {
              setOpenCalendar((state) => !state);
            }
          }}
        >
          <TiArrowSortedDown
            style={{
              transform: 'rotate(90deg)',
              position: 'absolute',
              top: '75px',
              left: '-34px',
              zIndex: 3,
              display: !openCalendar && 'none',
              pointerEvents: 'none',
            }}
            color="#ff2c3a"
            size={25}
          />
          <TiArrowSortedDown
            style={{
              transform: 'rotate(-90deg)',
              position: 'absolute',
              top: '75px',
              left: '208px',
              zIndex: 3,
              pointerEvents: 'none',
              display: !openCalendar && 'none',
            }}
            color="#ff2c3a"
            size={25}
          />
          <ReactDatePicker
            dateFormat="dd MMMM yyyy"
            formatWeekDay={(day) => getFirstLetter(day)}
            locale="pt"
            selected={startDate}
            onSelect={(e) => handleDateUpdate(e)}
            onChange={(e) => handleDateUpdate(e)}
            onClickOutside={(e) => {
              const check = e.path.find((p) => {
                if (typeof p.className === 'string') {
                  return p.className.includes('StyledDatePicker');
                }
                return false;
              });
              if (!check) {
                setOpenCalendar(false);
              }
            }}
            open={openCalendar}
          />
          <TiArrowSortedDown
            color="#ff2c3a"
            size={25}
            style={{ cursor: 'pointer' }}
          />
        </StyledDatePicker>
      </div>
      <TransfersTable borderless>
        <SearchDiv>
          <SearchBtn
            style={{ marginRight: '20px', color: '#FF1F2B' }}
            onClick={() => updateHistory(search)}
          >
            <FaSearch size={20} />
          </SearchBtn>
          <input
            placeholder="Buscar número do pedido"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchBtn onClick={() => setSearch('')}>
            <FaTimes size={20} />
          </SearchBtn>
        </SearchDiv>
        <TableHeader>
          <span>Tipo de transferência</span>
          <span
            style={{
              minWidth: '100px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Pedido{' '}
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FaExclamationCircle
                color="#FFA814"
                size={16}
                style={{ marginLeft: '5px' }}
                onMouseEnter={() => setShowWarning(true)}
                onMouseLeave={() => setShowWarning(false)}
              />
              <WarningContainer show={showWarning}>
                Caso você não utilize a configuração de impressão "Imprimir
                itens separadamente", o código do seu pedido transferido foi
                alterado. Expanda os cards para ver o novo número.
              </WarningContainer>
            </div>
          </span>
          <span style={{ minWidth: '200px' }}>Transferência</span>
          <span style={{ minWidth: '140px' }}>Data/Hora</span>
        </TableHeader>
        <TableBody>
          {loading ? (
            <div style={{ margin: '20px auto' }}>
              <Spinner />
            </div>
          ) : historyToShow.length ? (
            historyToShow.map((item, idx) => {
              const check = collapse.includes(item.id);

              return (
                <RowContainer key={idx}>
                  <TableRow onClick={() => handleCollapse(item.id)}>
                    <span style={{ color: colors[item.transfer_type] }}>
                      <MdKeyboardArrowRight
                        size={25}
                        color="#666666"
                        style={{
                          transform: check && 'rotate(90deg)',
                          transition: 'all 0.3s',
                        }}
                      />{' '}
                      Transferência de {types[item.transfer_type]}
                    </span>
                    <span style={{ minWidth: '100px' }}>
                      {item.dest_basket_key ? '#' + item.dest_basket_key : '-'}
                    </span>
                    <span style={{ minWidth: '200px' }}>
                      Mesa {item.src_table.table_number} <FiArrowRight /> Mesa{' '}
                      {item.dest_table.table_number}
                    </span>
                    <span style={{ minWidth: '140px' }}>
                      {format(new Date(item.createdAt), 'dd/MM/yy - HH:mm')}
                    </span>
                  </TableRow>
                  {item.transfer_type === 'item' ||
                  item.transfer_type === 'item-amount' ? (
                    <CollapsedRow check={check}>
                      <span>
                        {item.src_order_amount_before -
                          item.src_order_amount_after}
                        x {item.product?.name}
                      </span>
                      <span style={{ minWidth: '100px' }}>
                        {item.src_basket_key ? '#' + item.src_basket_key : '-'}
                      </span>
                      <span style={{ minWidth: '200px' }}>
                        {item.user?.name}
                        {item.user?.name && item.waiter?.name && ' - '}
                        {item.waiter?.name}
                      </span>
                      <span style={{ minWidth: '140px' }}>
                        {formatValue(item.transfer_total)}
                      </span>
                    </CollapsedRow>
                  ) : (
                    item.table_baskets?.map((tb) =>
                      tb.orders?.map((o) => {
                        return (
                          <CollapsedRow check={check}>
                            <span>
                              {o.amount}x {o.name}
                            </span>
                            <span style={{ minWidth: '100px' }}>
                              {tb.basket_id ? '#' + tb.basket_id : '-'}
                            </span>
                            <span style={{ minWidth: '200px' }}>
                              {item.user?.name}
                              {item.user?.name && item.waiter?.name && ' - '}
                              {item.waiter?.name}
                            </span>
                            <span style={{ minWidth: '140px' }}>
                              {formatValue(o.total_price)}
                            </span>
                          </CollapsedRow>
                        );
                      })
                    )
                  )}
                </RowContainer>
              );
            })
          ) : (
            <NotFoundText>
              Não encontramos transferências no período selecionado.
            </NotFoundText>
          )}
        </TableBody>
      </TransfersTable>
    </Container>
  );
}

export default Transfers;
