import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputContainer, Error } from './styles';

function Input({ name, label, maxlength, icon, fontSize, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputContainer>
      {label && (
        <label htmlFor={fieldName} style={fontSize ? fontSize : null}>
          {label}
          {icon}
        </label>
      )}
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        maxLength={maxlength}
        {...rest}
      />
      {error && (
        <Error>
          <p style={{ color: '#fff' }}>{error}</p>
        </Error>
      )}
    </InputContainer>
  );
}

export default Input;
