import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import { FiTrash } from 'react-icons/fi';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  DeletePaymentForm,
  ButtonStyled,
  PaymentMethodButton,
  RescueButton,
} from './styles';

import Input from '~/components/Form/Input';
import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';
import getValidationErrors from '~/services/getValidationErrors';
import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';
import CheckboxInput from '~/components/Form/Checkbox';
import InputMask from '~/components/Form/InputMask';

export default function PaymentFormDelivery({
  payments_session,
  item,
  getPayments,
  setPaymentsState,
  discount,
  discountObs,
  setDiscount,
  setDiscountObs,
  applyDiscount,
  setApplyDiscount,
  adminPassword,
  setAdminPassword
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
  const {
    user,
    setToastUpdateSystemMessage3,
    toastUpdateSystemMessage3,
    restaurantDiscountObs,
  } = useAuth();

  const [metodo, setMetodo] = useState('Outros');
  const [discountPercent, setDiscountPercent] = useState(
    (
      (discount /
        (item.old_total_price
          ? parseFloat(item.old_total_price) +
          parseFloat(item.delivery_tax_price)
          : item.total_delivery_price)) *
      100
    ).toFixed(2) || ''
  );

  const [discountText, setDiscountText] = useState(false);
  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const discountDisabled =
    item.ifood_discount > 0 || item.merchant_discount > 0;

  const [priceToShow, setPriceToShow] = useState(
    item.old_total_price
      ? (parseFloat(item.old_total_price) + parseFloat(item.delivery_tax_price) - discount).toFixed(2)
      : parseFloat(item.total_delivery_price).toFixed(2)
  );
  const [paymentOption, setPaymentOption] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState(0);
  const [isModalErrorUserPdvOpened, setIsModalUserPdvOpened] = useState(false);

  const toggleModalErrorUserPdv = useCallback(() => {
    setIsModalUserPdvOpened(!isModalErrorUserPdvOpened);
  }, [isModalErrorUserPdvOpened]);

  const getPaymentsSession = useCallback(async () => {
    let total = 0;
    payments_session.forEach((payment) => {
      total += parseFloat(payment.value);
    });
    setPaid(total.toFixed(2).toString());

    const localLeft =
      item.old_total_price
        ? parseFloat(item.old_total_price) + parseFloat(item.delivery_tax_price) - total - discount
        : item.total_delivery_price - total;

    setLeft(parseFloat(localLeft.toFixed(2)));
  }, [item, payments_session]);

  const deletePayments = useCallback(
    async (id) => {
      if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        await api.delete(`restaurants/payments/${id}`);

        const payments_bill_new = payments_session.filter(
          (payment) => payment.id !== id
        );

        const payments_bill_find = payments_session.filter(
          (payment) => payment.id === id
        );

        setPaymentsState(payments_bill_new);

        document.getElementById('value').value = (
          Number(left) + Number(payments_bill_find[0].value)
        ).toFixed(2);

        toast.success('Pagamento removido com sucesso!');
      }
    },
    [payments_session, setPaymentsState, left]
  );

  const [insertingPayment, setInsertingPayment] = useState(false);
  const newPayments = useCallback(async () => {
    try {
      const response = await api.post('restaurants/payments', {
        payment_value: value,
        table_session_id: item?.session_id,
        payment_method_id: paymentOption.id,
      });

      const newPayment = {
        value: Number(response.data.payment_value).toFixed(2),
        payment_method_id: response.data.payment_method_id,
        id: response.data.id,
        removable: true,
      };

      setPaymentsState([...payments_session, newPayment]);

      if (left === 0) {
        document.getElementById('value').value = (
          item?.total_delivery_price - value
        ).toFixed(2);
      } else if (left < 0) {
        document.getElementById('value').value = (left - value).toFixed(2);
      } else {
        document.getElementById('value').value = (left - value).toFixed(2);
      }
      setInsertingPayment(false);

      toast.success('Pagamento inserido com sucesso!');
    } catch (error) {
      setInsertingPayment(false);
      toast.error('Selecione o método de pagamento.');
      console.log(error);
    }
  }, [paymentOption, item, payments_session, setPaymentsState, left]);

  useEffect(() => {
    let total = 0;
    payments_session.forEach((payment) => {
      total += parseFloat(payment.value);
    });


    const totalValue =
      item.old_total_price ? parseFloat(item.old_total_price) + parseFloat(item.delivery_tax_price) -
        (applyDiscount ? discount : 0) - total
        : item.total_delivery_price - total

    setValue(totalValue.toFixed(2));
  }, [item, payments_session]);

  useEffect(() => {
    try {
      getPayments();
    } catch (error) {
      toast.error('Erro ao carregar informações');
    }
  }, [getPayments]);

  async function setMetodoAndPaymentOption(payment) {
    setInsertingPayment(true);
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });
  }

  useEffect(() => {
    getPaymentsSession();
  }, [getPaymentsSession]);

  const [hasOpenedBox, setHasOpenedBox] = useState(false);
  const openingBoxRef = useRef();

  const toggleModalHasOpenedBoxMessage = useCallback(() => {
    setHasOpenedBox(!hasOpenedBox);
  }, [hasOpenedBox]);

  const verifyOpeningBox = useCallback(
    async (data) => {
      try {
        await api.get('/restaurants/cashier-opening-verify');

        if (paymentOption.id) {
          newPayments(data);
        }
      } catch (err) {
        if (err.response.data.errorType === 'cashier_opening_not_found') {
          toggleModalHasOpenedBoxMessage();
        } else {
          toast.error('Erro ao carregar valores totais');
        }
      }
    },
    [toggleModalHasOpenedBoxMessage, newPayments]
  );

  const [
    isModalOnlineDepositsOpened,
    setIsModalOnlineDepositsOpened,
  ] = useState(false);
  const [onlineDeposits, setOnlineDeposits] = useState([]);

  const toggleOnlineDepositisModal = useCallback(() => {
    setIsModalOnlineDepositsOpened(!isModalOnlineDepositsOpened);
  }, [isModalOnlineDepositsOpened]);

  const openingBox = useCallback(
    async (data) => {
      if (!user?.is_pdv) {
        try {
          if (openingBoxRef?.current) {
            openingBoxRef.current.setErrors({});
          }

          const schema = Yup.object().shape({
            opening_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await api.post('/restaurants/cashier-opening', {
            initial_value: data.opening_value,
          });

          const { online_deposits } = response.data;
          setOnlineDeposits(online_deposits);

          if (online_deposits.length > 0) {
            toggleOnlineDepositisModal();
          }

          toggleModalHasOpenedBoxMessage();

          toast.success('Caixa aberto com sucesso.');
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            if (openingBoxRef?.current) {
              openingBoxRef.current.setErrors(errors);
            }
          } else if (error.response.data.errorType === 'cashier_already_open') {
            toast.error('Caixa já aberto');
          } else {
            toast.error('Erro ao abrir o caixa');
          }
        }
      } else {
        toggleModalErrorUserPdv();
      }
    },
    [
      toggleModalHasOpenedBoxMessage,
      user,
      toggleModalErrorUserPdv,
      toggleOnlineDepositisModal,
    ]
  );
  const [favoriteMethods, setFavoriteMethods] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('restaurants/payment-methods');
    if (item.basket.ifood_id === null) {
      const methods = response.data.payment_methods.filter(
        (method) => method.delivery_accepts
      );

      methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

      const parsedCompleteData = methods.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setAllPaymentMethods(parsedCompleteData.filter((p) => !p.is_ifood));

      const favoriteMethodsSplice = methods.splice(0, 8);

      const parsedDataFav = favoriteMethodsSplice.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setFavoriteMethods(parsedDataFav.filter((p) => !p.is_ifood));

      const parsedData = methods.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));
      setPaymentMethods(parsedData.filter((p) => !p.is_ifood));
    } else {
      const new_methods = response.data.payment_methods.filter(
        (method) => method.delivery_accepts
      );

      new_methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

      const parsedCompleteData = new_methods.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setAllPaymentMethods(parsedCompleteData.filter((p) => !p.is_ifood));

      const favoriteMethodsSplice = new_methods.splice(0, 8);

      const parsedDataFav = favoriteMethodsSplice.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));

      setFavoriteMethods(parsedDataFav);

      const parsedData = new_methods.map((data) => ({
        id: data.id,
        label: data.name,
        available: data.delivery_accepts,
        keyword: data.keyword,
        is_ifood: data.is_ifood,
      }));
      setPaymentMethods(parsedData);
    }
  }, []);

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '');

    const price = item?.old_total_price
      ? parseFloat(item.old_total_price) + parseFloat(item.delivery_tax_price)
      : item?.total_delivery_price;

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setPriceToShow(price);
      setLeft(price - paid);
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        ((parseFloat(targetValue.replace(',', '.')) / price) * 100).toFixed(
          2
        ) || '-'
      );
      setPriceToShow(
        (price - parseFloat(targetValue.replace(',', '.'))).toFixed(2)
      );
      setLeft(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
      setValue(
        (price - parseFloat(targetValue.replace(',', '.')) - paid).toFixed(2)
      );
    } else {
      setDiscount(
        ((parseFloat(targetValue.replace(',', '.')) * price) / 100).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setPriceToShow(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100
        ).toFixed(2)
      );
      setLeft(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100 -
          paid
        ).toFixed(2)
      );
      setValue(
        (
          price -
          (parseFloat(targetValue.replace(',', '.')) * price) / 100 -
          paid
        ).toFixed(2)
      );
    }
  };

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([
      ...(obs_options || []),
      { value: 'other', label: 'Outro' },
    ]);
    setDiscountObs(item.discount_obs);
  }, [restaurantDiscountObs]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    verifyOpeningBox();
  }, [paymentOption]);

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);

    setToastMessage(false);
  }

  useEffect(() => {
    if (
      !discountObsOptions.find((o) => o.label === discountObs) &&
      discountObs?.length > 0
    ) {
      setDiscountText(true);
    }
  }, []);

  return (
    <Container>
      <Modal
        isOpen={isModalOnlineDepositsOpened}
        toggle={toggleOnlineDepositisModal}
      >
        <ModalBody>
          <strong>
            Os valores a seguir, que foram pagamentos online feitos enquanto seu
            caixa estava fechado, entrarão no caixa que você está abrindo:
          </strong>
          {onlineDeposits &&
            onlineDeposits.length > 0 &&
            onlineDeposits
              .filter((dep) => dep.total > 0)
              .map((deposit) => (
                <p>
                  {deposit.description} - R$ {deposit.total.toFixed(2)}
                </p>
              ))}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              toggleOnlineDepositisModal();
              setOnlineDeposits([]);
            }}
          >
            Ok, entendi
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={hasOpenedBox} toggle={toggleModalHasOpenedBoxMessage}>
        <Form onSubmit={openingBox} ref={openingBoxRef}>
          <ModalBody>
            <FaExclamationTriangle color="darkOrange" size={32} />
            <h3>Você precisa abrir seu caixa para inserir pagamentos.</h3>

            <Input
              name="opening_value"
              label="Valor inicial no caixa (Dinheiro)"
              type="number"
              step="0.01"
              min="0"
            />
          </ModalBody>
          <ModalFooter>
            <ButtonStyled
              operationType="withdrawal"
              type="button"
              onClick={toggleModalHasOpenedBoxMessage}
            >
              Cancelar
            </ButtonStyled>
            <ButtonStyled operationType="deposit" type="submit">
              Abrir caixa
            </ButtonStyled>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalErrorUserPdvOpened}
        toggle={toggleModalErrorUserPdv}
      >
        <ModalBody>
          Seu perfil de usuário não possui permissão de abrir caixa. Solicite ao
          seu administrador
        </ModalBody>
        <ModalFooter>
          <div style={{ width: '100%' }}>
            <ButtonStyled
              operationType="deposit"
              type="button"
              onClick={toggleModalErrorUserPdv}
              style={{ float: 'right' }}
            >
              Entendi, irei solicitar.
            </ButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
      <BillExplanation>
        <h5>Adicionar Pagamento(s) </h5>
        <h7>
          Aqui você adiciona o(s) pagamento(s) já feitos da comanda e confere
          quanto falta a ser pago.
        </h7>
      </BillExplanation>

      <Form>
        <CheckboxInput
          label="Aplicar desconto"
          name="apply"
          disabled={item.ifood_discount > 0}
          checked={applyDiscount}
          onChange={(e) => {
            setApplyDiscount((state) => !state);
            if (!e.target.checked) {
              setLeft((state) => parseFloat(state) + parseFloat(discount));
              setValue((state) => parseFloat(state) + parseFloat(discount));
              setPriceToShow(
                (state) => parseFloat(state) + parseFloat(discount)
              );
            } else {
              setLeft((state) => parseFloat(state) - parseFloat(discount));
              setValue((state) => parseFloat(state) - parseFloat(discount));
              setPriceToShow(
                (state) => parseFloat(state) - parseFloat(discount)
              );
            }
          }}
        />
      </Form>

      {applyDiscount && (
        <>
          <span style={{ color: 'grey' }}>Desconto</span>
          <div
            style={{
              display: 'flex',
              margin: '10px 0 0',
              position: 'relative',
            }}
          >
            <input
              name="flat_discount"
              type="text"
              placeholder="R$"
              style={{ width: '50%', padding: '3px 10px' }}
              disabled={discountDisabled}
              value={discount && `R$${discount}`}
              onChange={(e) => handleChangeDiscount(e.target)}
            />
            <input
              name="discount"
              placeholder="%"
              type="text"
              style={{ width: '50%', padding: '3px 10px', marginLeft: '5px' }}
              disabled={discountDisabled}
              value={discountPercent && discountPercent}
              onChange={(e) => handleChangeDiscount(e.target)}
            />
            {discount && (
              <p style={{ position: 'absolute', right: '15px', top: '5px' }}>
                %
              </p>
            )}
          </div>
          <Form>
            <Row>
              <Select
                label={null}
                name="discount_obs"
                options={discountObsOptions}
                // disabled={discountDisabled}
                value={
                  discountObsOptions.find((o) => o.label === discountObs) ||
                  (discountObs?.length > 0 &&
                    discountObsOptions.find((o) => o.value === 'other'))
                }
                onChange={(e) => {
                  if (e.value === 'other') {
                    setDiscountText(true);
                  } else {
                    setDiscountText(false);
                    setDiscountObs(e.label);
                  }
                }}
              />
              {discountText && (
                <Input
                  type="text"
                  placeholder="Escreva o motivo"
                  id="discount_obs"
                  name="discount_obs"
                  // disabled={discountDisabled}
                  defaultValue={discountObs}
                  onChange={(e) => setDiscountObs(e.target.value)}
                />
              )}
              {user.order_cancel_password !== '' && item.ifood_discount === 0 &&
                <Input
                  placeholder="Senha administrativa"
                  id="cancel_password"
                  name="cancel_password"
                  type="password"
                  value={adminPassword}
                  autocomplete="off"
                  onChange={(e) => setAdminPassword(e.target.value)}
                />
              }
            </Row>
          </Form>
        </>
      )}
      <InformationDiv>
        <Total>
          <span>Total</span>

          <h4>R${priceToShow}</h4>
        </Total>
        <Paid>
          <span>{left > 0 ? 'Restante' : 'Troco'}</span>
          <h4>R${left > 0 ? left : left * -1}</h4>
        </Paid>
        <Form>
          <InputPaymentForm
            placeholder="R$"
            type="number"
            step="0.01"
            min="0"
            name="value"
            id="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form>
      </InformationDiv>

      <PaymentDiv>
        <div
          style={{
            position: 'fixed',
            top: 260,
            left: 290,
            width: '415px',
            height: 210,
            backgroundColor: '#fff',
            borderRadius: 7,
            padding: 20,
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            display: toastMessage ? 'flex' : 'none',
            zIndex: 2000000,
          }}
        >
          <div style={{}}>
            <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
              <FaExclamationTriangle color="darkOrange" />{' '}
              <strong>Atenção, nova Atualização!</strong>
            </p>
            <span>
              Agora é possível favoritar os métodos de pagamento para que eles
              apareçam nessa lista e agilize ainda mais a sua escolha. Se quiser
              alterar o método de pagamento favorito,{' '}
              <Link to="/box/payment"> clique aqui.</Link>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              style={{
                background: '#3BD2C1',
                color: '#fff',
                width: 54,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: 7,
                marginLeft: 10,
                fontWeight: 'bold',
                border: 'none',
              }}
              onClick={(e) => toggleToast(false)}
            >
              OK
            </button>
          </div>
        </div>
        <AddPaymentForm>
          {favoriteMethods.map((payment) =>
            payment.available &&
              payment.keyword !== 'clube' &&
              payment.keyword !== 'pix_auto' ? (
              <div key={payment.id}>
                <PaymentMethodButton
                  onClick={() => setMetodoAndPaymentOption(payment)}
                  disabled={insertingPayment}
                >
                  {payment.label}
                </PaymentMethodButton>
              </div>
            ) : (
              <></>
            )
          )}

          {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
              <DropdownToggle
                color="danger"
                caret
                style={{ width: '100px', height: '40px', margin: 0 }}
              >
                {metodo}
              </DropdownToggle>
              <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
                {paymentMethods.map((payment) =>
                  payment.available &&
                    payment.keyword !== 'clube' &&
                    payment.keyword !== 'pix_auto' ? (
                    <div key={payment.id}>
                      <DropdownItem
                        onClick={() => setMetodoAndPaymentOption(payment)}
                        disabled={insertingPayment}
                      >
                        {payment.label}
                      </DropdownItem>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </AddPaymentForm>

        <PaymentTable borderless>
          <tbody>
            {payments_session.map(
              (payment) =>
                payment.payment_method_id === 238 && (
                  <tr key={payment.id} style={{ color: '#017957' }}>
                    <td>Resgate Clube</td>
                    <td>R${payment.value}</td>
                  </tr>
                )
            )}

            {payments_session.map(
              (payment) =>
                payment.payment_method_id !== 238 && (
                  <tr
                    key={payment.id}
                    style={!payment.removable ? { color: '#017957' } : {}}
                  >
                    {allPaymentMethods
                      .filter(
                        (method) => method.id === payment.payment_method_id
                      )
                      .map((method) => (
                        <td key={method.id}>{method.label}</td>
                      ))}
                    <td>R${payment.value}</td>
                    <td />
                    <td>
                      {payment.removable ? (
                        <div>
                          <DeletePaymentForm
                            onClick={() => deletePayments(payment.id)}
                          >
                            <FiTrash size={15} />
                          </DeletePaymentForm>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'right', color: '#017957' }}>
                          Pago Online
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </PaymentTable>
      </PaymentDiv>
      <FinalDiv>
        <Total>
          <span>Pago</span>
          <h4>R${paid}</h4>
        </Total>
        <Paid>
          <span>Pago</span>
          <h4>
            {(paid / priceToShow) * 100 > 100
              ? '100'
              : ((paid / priceToShow) * 100).toFixed(2)}
            %
          </h4>
        </Paid>
      </FinalDiv>
    </Container>
  );
}
