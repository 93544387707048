import React from 'react';

import {
  Container,
  Title,
  Filter,
  Restaurant,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Footer,
  PriceItem,
  NamePrice,
  AlreadyClosed,
  TotalDiv,
} from './styles';

class PrintReportProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      complements: props.complements,
      products: props.products,
      total_restaurant_amount: props.total_restaurant_amount,
      total_restaurant_price: props.total_restaurant_price,
      inicialFormat: props.inicialFormat,
      finalFormat: props.finalFormat,
      payments: props.payments,
      totalBox: props.totalBox,
      buyers: props.buyers,
      restaurantName: props.restaurantName,
      categoriesCanceled: props.categoriesCanceled,
      total_restaurant_canceled_service_price:
        props.total_restaurant_canceled_service_price,
      total_restaurant_canceled_amount: props.total_restaurant_canceled_amount,
      has_service_tax: props.has_service_tax,
      service_tax: props.service_tax,
      total_restaurant_price_without_service:
        props.total_restaurant_price_without_service,
    };
  }

  render() {
    const {
      categories,
      products,
      inicialFormat,
      finalFormat,
      total_restaurant_price,
      total_restaurant_amount,
      payments,
      totalBox,
      buyers,
      restaurantName,
      categoriesCanceled,
      total_restaurant_canceled_service_price,
      total_restaurant_canceled_amount,
      has_service_tax,
      service_tax,
      total_restaurant_price_without_service,
    } = this.state;

    const complements = products.map((p) => p.complements).flat();

    let service =
      parseFloat(total_restaurant_price) -
      parseFloat(total_restaurant_price_without_service);
    service = service.toFixed(2).toString();

    return (
      <Container>
        <Title>
          <strong>RELATÓRIO DE PRODUTOS</strong>
        </Title>

        <Filter>
          <strong>
            {inicialFormat} - {finalFormat}
          </strong>
        </Filter>

        <Restaurant>
          <strong>{restaurantName}</strong>
        </Restaurant>
        {categories.map((category) => (
          <div>
            {category.category_earning.total > 0 && (
              <>
                <Body>
                  <Phone>
                    <strong>{category.name}</strong>
                  </Phone>
                  <Price>
                    <strong>
                      R${category.category_earning.total.toFixed(2)}
                    </strong>
                  </Price>
                </Body>
                <Items>
                  <Item>
                    <NamePrice>
                      <ItemName>Total Pedidos</ItemName>
                      <PriceItem>
                        <strong>{category.amount.total}</strong>
                      </PriceItem>
                    </NamePrice>
                  </Item>
                  {products
                    ?.filter((p) => p.category.id === category.id)
                    .map((product) => (
                      <Item>
                        <NamePrice>
                          <ItemName>
                            <strong>
                              {product.use_weight
                                ? `${product.total_sold_product_amount.toFixed(
                                  3
                                )} kg `
                                : `${product.amount}x `}
                              {product.name}
                            </strong>
                          </ItemName>
                          <PriceItem>
                            <strong>R${product.earnings.toFixed(2)}</strong>
                          </PriceItem>
                        </NamePrice>
                      </Item>
                    ))}
                  <Item>
                    <NamePrice>
                      <ItemName>
                        <strong>Porcentagem</strong>
                      </ItemName>
                      <PriceItem>
                        {isNaN(
                          (category.category_earning.total * 100) /
                          total_restaurant_price
                        ) ? (
                          <div>
                            <strong>0%</strong>
                          </div>
                        ) : (
                          <div>
                            <strong>
                              {(
                                (category.category_earning.total * 100) /
                                total_restaurant_price
                              ).toFixed(2)}
                              %
                            </strong>
                          </div>
                        )}
                      </PriceItem>
                    </NamePrice>
                  </Item>
                </Items>
              </>
            )}
          </div>
        ))}

        <Body>
          <Phone>
            <strong>Pedidos Cancelados</strong>
          </Phone>
          <Price>
            <strong>R${total_restaurant_canceled_service_price}</strong>
          </Price>
        </Body>

        <Items>
          <Item>
            <NamePrice>
              <ItemName>
                <strong>Total Excluídos</strong>
              </ItemName>
              <PriceItem>
                <strong>{total_restaurant_canceled_amount}</strong>
              </PriceItem>
            </NamePrice>
          </Item>

          {categoriesCanceled ? (
            categoriesCanceled.map((categorie) =>
              categorie.products ? (
                categorie.products.map((product) => (
                  <Item>
                    <NamePrice>
                      <ItemName>
                        <strong>
                          {product.total_canceled_product_amount}x{' '}
                          {product.name}
                        </strong>
                      </ItemName>
                      <PriceItem>
                        <strong>
                          R$
                          {product.price_promotion
                            ? parseFloat(product.price_promotion).toFixed(2)
                            : parseFloat(product.price).toFixed(2)}
                        </strong>
                      </PriceItem>
                    </NamePrice>
                  </Item>
                ))
              ) : (
                <></>
              )
            )
          ) : (
            <></>
          )}
        </Items>
        <Body style={{ borderTop: '1px solid black' }}>
          <Phone>
            <strong>Complementos</strong>
          </Phone>
          {/* <Price>
            <strong>R${total_restaurant_canceled_service_price}</strong>
          </Price> */}
        </Body>

        <Items>
          {/* <Item>
            <NamePrice>
              <ItemName>
                <strong>Total Excluídos</strong>
              </ItemName>
              <PriceItem>
                <strong>{total_restaurant_canceled_amount}</strong>
              </PriceItem>
            </NamePrice>
          </Item> */}
          {complements &&
            complements.length > 0 &&
            complements.map((complement) => (
              <Item>
                <NamePrice>
                  <ItemName>
                    <strong>
                      {complement?.amount}x {complement?.name}
                    </strong>
                  </ItemName>
                  <PriceItem>
                    <strong>
                      R$
                      {complement.earnings.toFixed(2)}
                    </strong>
                  </PriceItem>
                </NamePrice>
              </Item>
            ))}
        </Items>
        <TotalDiv>
          <Body>
            <Phone>
              <strong>TOTAL PRODUTOS</strong>
            </Phone>
            <Price>
              <strong>R${total_restaurant_price_without_service}</strong>
            </Price>
          </Body>
          {has_service_tax && (
            <div>
              <Body>
                <Phone>
                  <strong>SERVIÇOS ({service_tax}%)</strong>
                </Phone>
                <Price>
                  <strong>R${service}</strong>
                </Price>
              </Body>
              <Body>
                <Phone>
                  <strong>TOTAL COM SERVIÇOS</strong>
                </Phone>
                <Price>
                  <strong>R${total_restaurant_price}</strong>
                </Price>
              </Body>
            </div>
          )}

          <Body>
            <Phone>
              <strong>TOTAL EM CAIXA</strong>
            </Phone>
            <Price>
              <strong>R${totalBox}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>PRODUTOS PEDIDOS</strong>
            </Phone>
            <Price>
              <strong>{total_restaurant_amount}</strong>
            </Price>
          </Body>
          <Body>
            <Phone>
              <strong>CLIENTES</strong>
            </Phone>
            <Price>
              <strong>{buyers}</strong>
            </Price>
          </Body>
        </TotalDiv>
        <AlreadyClosed>
          <h4>
            <strong>Formas de Pagamento</strong>
          </h4>
          {payments?.map((payment) => (
            <Body>
              <Phone>
                <strong>{payment.name}</strong>
              </Phone>
              <Price>
                <strong>R${payment.total_payments_price}</strong>
              </Price>
            </Body>
          ))}
        </AlreadyClosed>
        <Footer>
          <strong>Desenvolvido por Takeat</strong>
        </Footer>
        <div />
      </Container>
    );
  }
}

export default PrintReportProducts;
