import styled from 'styled-components';
import { Form } from '@unform/web';
import { FiEye } from 'react-icons/fi';
import backgroundImg from '../../assets/img/wallpaper.jpeg';

export const Container = styled.div`
  display: flex;

  align-items: stretch;

  width: 100vw;
  height: 100vh;

  img {
    max-height: 200px;
  }
`;

export const ButtonWaiter = styled.a`
  background: #fff;
  color: #ff1814;
  padding: 10px 15px;
  border-radius: 7px;
  border: none;

  margin-top: 17px;

  cursor: pointer;
  margin-left: -235px;

  svg {
    margin-right: 5px;
  }
`;

export const LeftSide = styled.div`
  width: 40%;

  background-color: #ff1814;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 350px;
  }
`;

export const RightSide = styled.div`
  background: url(${backgroundImg}) no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 60%;
`;

export const Card = styled.div`
  max-width: 500px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: 20px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;

  h1 {
    font-size: 22px;

    text-align: center;
    font-weight: bold;
  }
`;

export const InputArea = styled.span`
  position: relative;
`;

export const FiEyeStyled = styled(FiEye)`
  position: absolute;
  right: 10px;
  bottom: 35px;
  cursor: pointer;
`;

export const StyledForm = styled(Form).attrs((props) => ({
  primary: props.theme.colors.primary,
  secondary: props.theme.colors.secondary,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;

  input {
    /* background: rgba(0, 0, 0, 0.03); */
    min-width: 300px;
    border-radius: 4px;
    border: 1px solid #ddd;
    height: 44px;
    padding: 20px;
    color: ${(props) => props.theme.colors.text};
    margin: 0 0 10px;
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 8px;
    background: #ff1814;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  a {
    cursor: pointer;
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
`;
