import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import createPersistedState from 'use-persisted-state';
import { exportComponentAsJPEG } from 'react-component-export-image';
import {
  FaBookOpen,
  FaBars,
  FaBullhorn,
  FaCoins,
  FaCalendarCheck,
  FaFileInvoiceDollar,
  FaExclamationTriangle,
  FaWhatsapp,
  FaPiggyBank,
} from 'react-icons/fa';

import {
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { toast } from 'react-hot-toast';

import {
  Wrapper,
  Content,
  ContentOperation,
  Footer,
  CarouselStyled,
  SaveButton,
  ButtonNews,
  FooterItem,
} from './styles';

import api from '~/services/api';

import SideBar from '~/components/Sidebar';
import Navbar from '~/components/Navbar';
import { useAuth } from '~/context/AuthContext';

import ImgToPrint from '~/components/ImgToPrint';
import { useOrder } from '~/context/orderContext';
import { FiHelpCircle } from 'react-icons/fi';
import { NewHeader } from '~/components/NewHeader';

function AdminLayout({ children }) {
  const usePersistedState = createPersistedState('collapsed');
  const [collapsed, setCollapsed] = usePersistedState(true);
  const [isModalQrcodeOpened, setIsModalQrcodeOpened] = useState(false);
  const [isModalNewsOpened, setIsModalNewsOpened] = useState(false);
  const [tablesAvailable, setTablesAvailable] = useState();
  const {
    user,
    userAccess,
    toastUpdateSystemMessage4,
    setToastUpdateSystemMessage4,
    websocketManager,
    updateUser,
  } = useAuth();
  const { pendingBaskets } = useOrder();

  function handleOpenWhatspp() {
    try {
      window.open(
        `https://api.whatsapp.com/send?phone=%22+552799892-7706%22&text=%22*Preciso%20de%20ajuda.%20O%20chat%20está%20fora%20do%20ar%20*%22`
      );
    } catch (error) {
      console.log(error);
      toast.error('Erro ao abrir suporte');
    }
  }

  const imgRef = useRef(null);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleModalQrcode = () => {
    setIsModalQrcodeOpened(!isModalQrcodeOpened);
  };

  function toggleNewsModal() {
    setIsModalNewsOpened(!isModalNewsOpened);
  }

  const getTablesAvailable = useCallback(async () => {
    const response = await api.get('/restaurants/tables', {
      params: {
        status: 'available',
        is_menu: true,
      },
    });

    setTablesAvailable(response.data);
  }, []);

  useEffect(() => {
    getTablesAvailable();
  }, [getTablesAvailable]);

  const page = window.location.pathname;
  const history = useHistory();

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage4.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage4(false);

    setToastMessage(false);
  }

  useEffect(() => {
    if (websocketManager) {
      websocketManager.addMessageCallback(4, (data) => {
        if (data.type === 'enable-menu') {
          const { value } = data;

          updateUser({
            enable_menu: value === 'true',
          });
        }
      });
    }
  }, [websocketManager, updateUser]);

  return page === '/operation' ? (
    <>
      <ContentOperation>{children}</ContentOperation>
      <Modal isOpen={isModalQrcodeOpened} toggle={toggleModalQrcode}>
        <ModalHeader>Cardápio Digital</ModalHeader>
        <ModalBody>
          <p>
            Agora você pode compartilhar seu cardápio em suas redes sociais!
            Basta salvar o QrCode ou copiar o link e compartilhar à vontade.
            Aproveite!
          </p>

          <p>
            Link:{' '}
            {tablesAvailable && tablesAvailable[0]?.table_code?.restaurant_url}
          </p>
          {tablesAvailable && (
            <>
              <ImgToPrint
                ref={imgRef}
                imgSrc={tablesAvailable[0]?.table_code?.url}
              />

              <SaveButton
                type="button"
                onClick={() => exportComponentAsJPEG(imgRef)}
              >
                Baixar Imagem
              </SaveButton>
            </>
          )}
        </ModalBody>
      </Modal>

      {!user?.is_pdv && (
        <Footer>
          <FooterItem onClick={() => history.push('/products')}>
            <FaBars />
            <span>Menu</span>
          </FooterItem>
          <FooterItem onClick={() => history.push('/products')}>
            <FaBookOpen />
            <span>Cardápio</span>
          </FooterItem>
          <FooterItem onClick={() => history.push('/bills')}>
            <FaFileInvoiceDollar />
            <span>Comandas</span>
          </FooterItem>
          {user.has_nfce && (
            <FooterItem onClick={() => history.push('/nfce')}>
              <FaPiggyBank />
              <span>Fiscal</span>
            </FooterItem>
          )}
          <FooterItem onClick={() => history.push('/help')}>
            <FiHelpCircle />
            <span>Ajuda</span>
          </FooterItem>
        </Footer>
      )}
    </>
  ) : (
    <>
      <Wrapper>
        {!user?.is_pdv &&
          user.enable_menu &&
          page !== '/kds' &&
          page !== '/kitchen' && (
            <>
              <SideBar
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
                style={{ position: 'relative' }}
              />
              <div
                style={{
                  position: 'fixed',
                  top: 300,
                  left: 150,
                  width: '415px',
                  height: 157,
                  backgroundColor: '#fff',
                  borderRadius: 7,
                  padding: 20,
                  filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
                  display: toastMessage ? 'flex' : 'none',
                  zIndex: 2000000,
                }}
              >
                <div style={{}}>
                  <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
                    <FaExclamationTriangle color="darkOrange" />{' '}
                    <strong>
                      Agora o estoque atualizará
                      <br />{' '}
                      <span style={{ color: 'red' }}> 1 vez por dia</span>{' '}
                      sempre às <span style={{ color: 'red' }}>03h00!</span>
                    </strong>
                  </p>
                  <span>
                    Caso precise atualizar manualmente, basta clicar no botâo{' '}
                    <strong>“Atualizar” </strong>na página “Estoque e Insumos”.
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      background: '#3BD2C1',
                      color: '#fff',
                      width: 54,
                      height: 48,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: 7,
                      marginLeft: 10,
                      fontWeight: 'bold',
                      border: 'none',
                    }}
                    onClick={(e) => toggleToast(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </>
          )}

        <Content
          style={{
            marginLeft: page === '/kds' || page === '/kitchen' ? 0 : '13rem',
            width: 'calc(100vw - 13rem)',
          }}
        >
          <NewHeader />
          {/* <Navbar collapsed={collapsed} toggleCollapse={toggleCollapse} /> */}
          {children}
        </Content>
      </Wrapper>
    </>
  );
}

export default AdminLayout;

AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
