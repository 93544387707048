import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
`;

export const NumberFormatStyledValue = styled.input`
  height: 44px;
  width: 70%;
 
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;


export const ButtonAdd = styled.button`
  color: #ff2c3a;
  text-decoration: underline;
  border: none;
  font-weight: bold;
  background-color: transparent;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const SelectStyled = styled(ReactSelect)`
  width: 90%;
  text-align: left;
`;
export const InputStyled = styled.input`
  width: 70%;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;



export const StockBanner = styled.div`
  width: 100%;
  background: #ecfffd;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 20px;

  margin-top: 10px;

  p {
    text-align: left;
    font-size: 20px;
    color: #414141;
    font-weight: bold;
  }

  span {
    font-size: 18px;
    color: #414141;
  }

  img {
    margin-right: 20px;
  }
`;

export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 
  padding: 20px 20px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding-right: 25px;
  padding-left: 10px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;

export const IfoodTable = styled.table`
  width: 100%;
  min-width: 200px;
  margin: 10px auto 10px 0;
  border-collapse: initial;
  border-spacing: 0 10px;
  background: ${(props) => props.theme.colors.white};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  padding: 0 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;



export const InputsTable = styled.table`
  width: 100%;
  border-collapse: initial;
  border-spacing: 0 30px;
  background: ${(props) => props.theme.colors.white};

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid transparent;
    }
    td:last-child {
      text-align: right;
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;


export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const Card = styled.div`
  margin: 0 auto;
  width: 80%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: calc(100% - 54px);
  max-width: 120px;
  min-width: 82px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  text-align: center;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const CmvButtons = styled.div`
  display: flex;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  cursor: pointer;

  input {
    margin-right: 5px;
  }
`;

export const SubmitStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-left: 8px;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;
