/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';

import { BsPersonPlusFill } from 'react-icons/bs';
import InputMask from 'react-input-mask';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import { Container, Content, ClientButton, ReturnButton } from './styles';
import api from '~/services/api';

const ClientArea = ({ setClient, session }) => {
  const [modal, setModal] = useState(false);
  const [clientInfo, setClientInfo] = useState();
  const [clientFinal, setClientFinal] = useState();
  const [phone, setPhone] = useState('');
  const formRef = useRef(null);

  const [showRegister, setShowRegister] = useState(false);

  function toggleModal() {
    setModal(!modal);
    setShowRegister(false);
  }

  async function getClient(phone) {
    try {
      const response = await api.post('restaurants/get-clients', {
        phone,
      });

      if (response.data.hasClient === true) {
        setClientInfo(response.data.client);
        setShowRegister(false);
      } else {
        setShowRegister(true);
      }
    } catch (error) {
      toast.error(
        'Não foi possível obter informações do cliente! tente novamente'
      );
    }
  }

  async function submitClient(data) {
    if (clientInfo) {
      // Relacionar Cliente Existente a Comanda
      try {
        setClient(clientInfo);
        setClientFinal(clientInfo);
        session.client = clientInfo;

        toggleModal();
        toast.success('Cliente adicionado a comanda com sucesso!');
      } catch (error) {
        toast.error(
          'Não foi possível adicionar o cliente a comanda! tente novamente'
        );
      }
    } else {
      // Cadastrar Novo Cliente
      if (phone[14] && phone[14] !== '_') {
        try {
          const response = await api.post('restaurants/clients', {
            phone,
            name: data?.name,
            cpf: data?.cpf,
            email: data?.email,
            birthday: data?.birthday,
            cep: data?.cep,
            bairro: data?.bairro,
            street: data?.street,
            number: data?.number,
            complement: data?.complement,
          });

          setClientInfo(response.data);
          setClientFinal(response.data);
          setClient(response.data);
          toggleModal();
          toast.success('Cliente cadastrado com sucesso!');
        } catch (error) {
          toast.error('Não foi possível finalizar o cadastro! tente novamente');
        }
      } else {
        toast.error('Digite um número de telefone válido');
      }
    }
  }

  async function editClient() {
    try {
      const data = formRef.current.getData();
      const response = await api.put(
        `restaurants/clients/${clientInfo.id}`,
        data
      );
      setClientInfo(response.data);
      setClient(response.data);
      if (clientInfo.id === clientFinal?.id) {
        setClientFinal(response.data);
      }
      toast.success('Cliente atualizado com sucesso!');
    } catch (err) {
      toast.error('Não foi possível atualizar o cadastro! tente novamente');
    }
  }

  useEffect(() => {
    if (phone[14] && phone[14] !== '_') {
      getClient(phone);
    }
  }, [phone]);

  return (
    <Container>
      <Content>
        <ClientButton onClick={() => toggleModal()}>
          <BsPersonPlusFill size={20} />
          {!clientInfo || !clientFinal ? (
            <h6>
              <strong>Cadastrar Cliente</strong>
            </h6>
          ) : (
            <div style={{ textAlign: 'left' }}>
              <h6>
                <strong>{clientFinal?.phone}</strong>
              </h6>
              <p style={{ fontSize: '13px' }}>{clientFinal?.name}</p>
            </div>
          )}
        </ClientButton>
      </Content>

      <Modal isOpen={modal} toggle={toggleModal}>
        <Form initialData={clientInfo} onSubmit={submitClient} ref={formRef}>
          <ModalBody>
            {!clientInfo ? (
              <>
                <h5>Cadastrar Cliente na Comanda</h5>
                <div style={{ textAlign: 'center' }}>
                  <p>Pra começar, digite o número de celular do cliente:</p>
                  <InputMask
                    mask="(99) 99999-9999"
                    onChange={(e) => setPhone(e.target.value)}
                  >
                    <Input
                      name="phone_to"
                      type="text"
                      placeholder="(xx) xxxxx-xxxx"
                      style={{
                        width: '40%',
                        margin: 'auto',
                        textAlign: 'center',
                      }}
                    />
                  </InputMask>
                  {showRegister && (
                    <div>
                      <p style={{ color: 'red' }}>
                        Cliente sem cadastro! Finalize o cadastro abaixo:
                      </p>
                      <Row>
                        <Col>
                          <Input
                            name="name"
                            label="Nome: (Opcional)"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Input
                            name="email"
                            label="E-mail: (Opcional)"
                            type="email"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputMask mask="999.999.999-99">
                            <Input
                              name="cpf"
                              label="CPF: (Opcional)"
                              type="text"
                            />
                          </InputMask>
                        </Col>
                        <Col>
                          <InputMask mask="99/99/9999">
                            <Input
                              name="birthday"
                              label="Data de Nascimento: (Opcional)"
                              type="text"
                            />
                          </InputMask>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputMask mask="99.999-999">
                            <Input
                              name="cep"
                              label="CEP: (Opcional)"
                              type="text"
                            />
                          </InputMask>
                        </Col>
                        <Col>
                          <Input
                            name="bairro"
                            label="Bairro: (Opcional)"
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            name="street"
                            label="Rua: (Opcional)"
                            type="text"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            name="number"
                            label="Nº: (Opcional)"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Input
                            name="complement"
                            label="Complemento: (Opcional)"
                            type="text"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col md="12" style={{ textAlign: 'center' }}>
                    <h4>{clientInfo?.phone}</h4>
                    <p style={{ color: 'green' }}>Cliente já cadastrado!</p>
                    <ReturnButton
                      onClick={() => {
                        setClientInfo(null);
                      }}
                    >
                      Mudar número
                    </ReturnButton>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input name="name" label="Nome: (Opcional)" type="text" />
                  </Col>
                  <Col>
                    <Input
                      name="email"
                      label="E-mail: (Opcional)"
                      type="email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputMask mask="999.999.999-99">
                      <Input name="cpf" label="CPF: (Opcional)" type="text" />
                    </InputMask>
                  </Col>
                  <Col>
                    <InputMask mask="99/99/9999">
                      <Input
                        name="birthday"
                        label="Data de Nascimento: (Opcional)"
                        type="text"
                      />
                    </InputMask>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputMask mask="99.999-999">
                      <Input name="cep" label="CEP: (Opcional)" type="text" />
                    </InputMask>
                  </Col>
                  <Col>
                    <Input
                      name="bairro"
                      label="Bairro: (Opcional)"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input name="street" label="Rua: (Opcional)" type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input name="number" label="Nº: (Opcional)" type="text" />
                  </Col>
                  <Col>
                    <Input
                      name="complement"
                      label="Complemento: (Opcional)"
                      type="text"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Button
                      onClick={() => editClient()}
                      style={{
                        background: '#17a2b8',
                        height: '30px',
                        paddingTop: '2px',
                        float: 'right',
                        fontSize: '14px',
                      }}
                    >
                      Atualizar Informações do Cliente
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {clientInfo && clientInfo.phone !== clientFinal?.phone && (
              <Button type="submit" style={{ background: 'green' }}>
                Adicionar a Comanda
              </Button>
            )}
            {showRegister && (
              <Button type="submit" style={{ background: 'green' }}>
                Cadastrar e Adicionar a Comanda
              </Button>
            )}
            <Button onClick={toggleModal} style={{ background: '#dc3545' }}>
              Fechar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
};

export default ClientArea;
