import { Form } from '@unform/web';
import React, { useState, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { MdDateRange } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import moment from 'moment';
import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import {
  DataPickerArea,
  DatePickerStyled,
  NumberFormatStyled,
} from '../styles';
import Select from '~/components/Form/SelectInput';
import SaveButton from '~/components/Buttons/SaveButton';

import DefaulButton from '~/components/Buttons/DefaultButton';

import formatdate from '../../../../services/formatDateYYYY';

// import { Container } from './styles';

function Edit({
  transaction,
  getTransactions,
  toggleModalEditTransaction,
  banks,
  categories,
  freqOptions,
  suppliers,
  paymentMethods,
  setIsPaid,
}) {
  const [paidCheck, setPaidCheck] = useState(transaction.paid);

  const [dueDate, setDuedate] = useState();
  const [competenceDate, setCompetenceDate] = useState();
  const [paidAtDate, setpaidAtDate] = useState();
  const [valueState, setValueState] = useState(transaction.value);
  const [isModalErrorOpened, setIsModalErrorOpened] = useState(false);

  const transactionFormRef = useRef(null);

  function handleSetPaidCheck() {
    setPaidCheck(!paidCheck);

    setpaidAtDate(new Date());
  }

  const toggleModalError = useCallback(() => {
    setIsModalErrorOpened(!isModalErrorOpened);
  }, [isModalErrorOpened]);
  const supplierFiltered = suppliers.filter(
    (supplier) => supplier.value === transaction?.provider?.id
  );

  const selectSuppler = supplierFiltered
    ? {
        value: supplierFiltered[0]?.value,
        label: supplierFiltered[0]?.label,
      }
    : null;

  const paymmentFiltered = paymentMethods.filter(
    (item) => item.value === transaction?.cash_flow_payment_method?.id
  );

  const selectPayment = paymmentFiltered
    ? {
        value: paymmentFiltered[0]?.value,
        label: paymmentFiltered[0]?.label,
      }
    : null;

  const banksFiltered = banks.filter(
    (item) => item.value === transaction?.bank_account?.id
  );

  const selectBank = banksFiltered[0]
    ? {
        value: banksFiltered[0].value,
        label: banksFiltered[0].label,
      }
    : null;

  const categoryFiltered = categories?.filter(
    (item) => item.value === transaction?.cash_flow_category_subcategory_id
  );

  const selectCategory = categoryFiltered
    ? {
        value: categoryFiltered.length > 0 ? categoryFiltered[0].value : '',
        label: transaction.text,
      }
    : '';

  const initialData = {
    description: transaction.description,
    information: transaction.information,

    competence_date: transaction.competence_date,
    paid: transaction.paid,
    paid_at: transaction.paid_At,
    provider_id: selectSuppler,
    bank_account_id: selectBank,
    cash_flow_payment_method_id: selectPayment,
    cash_flow_category_subcategory_id: selectCategory,
    repit: !!transaction.freq_type,
    freq_type: transaction.freq_type,
    freq_value: transaction.freq_value,
  };

  async function handleSubmit(data) {
    try {
      if (!valueState) {
        toggleModalError();
      } else {
        const readyValue = valueState.floatValue
          ? (valueState.floatValue / 100).toFixed(2)
          : valueState;

        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          // cash_flow_category_subcategory_id: Yup.string().required(
          //   'Essa categoria foi excluída, selecione outra por favor'
          // ),
          bank_account_id: Yup.string().required('Conta obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put(`/restaurants/cash-flows/${transaction.id}`, {
          value: readyValue,
          description: data.description,
          information: data.information,
          due_date: dueDate,
          competence_date: competenceDate,
          paid: data.paid,
          paid_at: paidAtDate,
          provider_id: data.provider_id || null,
          bank_account_id: data.bank_account_id,
          cash_flow_payment_method_id: data.cash_flow_payment_method_id || null,
          cash_flow_category_subcategory_id:
            data?.cash_flow_category_subcategory_id || null,
          freq_type: data.freq_type,
          freq_value: data.freq_value,
        });

        toast.success('Movimentação editada com sucesso!');
        setIsPaid(data.paid);
        getTransactions();
        toggleModalEditTransaction();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (transactionFormRef?.current) {
          transactionFormRef.current.setErrors(errors);
        }
      } else {
        toast.error(`Erro ao cadastrar Movimentação!`);
        console.log(error);
      }
    }
  }

  function convertData(data) {
    const dataFormt = moment(data, 'DD/MM/YYYY');

    return dataFormt.format('DD-MM-YYYY');
  }

  function currencyFormatter(value) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  function handleValueChange(e) {
    setValueState(e);
  }

  const dueDateText = transaction.due_date
    ? formatdate(transaction.due_date)
    : '__/__/____';
  const paidText = transaction.paid_at
    ? formatdate(transaction.paid_at)
    : '__/__/____';
  const competenceText = transaction.competence_date
    ? formatdate(transaction.competence_date)
    : '__/__/____';

  return (
    <>
      <ModalHeader> Editar Lançamento</ModalHeader>

      <Form
        onSubmit={handleSubmit}
        ref={transactionFormRef}
        initialData={initialData}
      >
        <ModalBody>
          <Row>
            <Col>
              <DataPickerArea>
                <span>Valor (R$)</span>
                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  defaultValue={valueState}
                  onValueChange={(e) => {
                    handleValueChange(e);
                  }}
                />
              </DataPickerArea>
            </Col>
            <Col>
              <DataPickerArea>
                <span>
                  Vencimento <MdDateRange />
                </span>

                <DatePickerStyled
                  selected={dueDate}
                  onChange={(date) => setDuedate(date)}
                  locale="pt"
                  dateFormat="dd/MM/yyyy"
                  placeholderText={dueDateText}
                />
              </DataPickerArea>
            </Col>
            <Col>
              <DataPickerArea>
                <>
                  <span>
                    Competência <MdDateRange />
                  </span>

                  <DatePickerStyled
                    selected={competenceDate}
                    onChange={(date) => setCompetenceDate(date)}
                    locale="pt"
                    dateFormat="dd/MM/yyyy"
                    placeholderText={competenceText}
                  />
                </>
              </DataPickerArea>
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                label="Conta"
                name="bank_account_id"
                placeholder="Selecione a conta"
                options={banks}
              />
            </Col>
            <Col>
              <Select
                label="Forma de pagamento"
                name="cash_flow_payment_method_id"
                placeholder="Selecione a forma de pagamento"
                options={paymentMethods}
              />
            </Col>
          </Row>

          <Input label="Descrição" name="description" />

          <Row>
            {transaction?.cash_flows.length === 0 && (
              <Col>
                <Select
                  label="Centro de custo"
                  name="cash_flow_category_subcategory_id"
                  placeholder="Centro de custo"
                  options={categories}
                />
              </Col>
            )}
            <Col>
              <Select
                label="Fornecedor"
                name="provider_id"
                placeholder="Informe o fornecedor"
                options={suppliers}
              />
            </Col>
          </Row>

          <Input label="Linha digitável" name="information" />

          <CheckboxInput
            name="paid"
            label="Pago"
            onChange={handleSetPaidCheck}
          />

          <Modal isOpen={isModalErrorOpened} toggle={toggleModalError}>
            <ModalBody>Os campos Valor e Vencimento são obrigatórios</ModalBody>
            <ModalFooter>
              <DefaulButton onClick={toggleModalError} message="Ok, entendi" />
            </ModalFooter>
          </Modal>

          {paidCheck && (
            <Row>
              <Col lg="3">
                <DataPickerArea>
                  <>
                    <span>
                      Pago em <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={paidAtDate}
                      onChange={(date) => setpaidAtDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={paidText}
                    />
                  </>
                </DataPickerArea>
              </Col>
            </Row>
          )}

          {/* <CheckboxInput
            name="repit"
            label="Repetir"
            onChange={handleSetRepitCheck}
          />

          {repitCheck && (
            <Row>
              <Col>
                <Input label="Qtd repetições" name="freq_value" type="number" />
              </Col>
              <Col>
                <Select
                  label="Frequência"
                  name="freq_type"
                  options={freqOptions}
                />
              </Col>
            </Row>
          )} */}
        </ModalBody>
        <ModalFooter>
          <SaveButton type="submit" message="Salvar" />
        </ModalFooter>
      </Form>
    </>
  );
}

export default Edit;
