import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import formatTime from '~/services/formatTime';
import SaveButton from '~/components/Buttons/SaveButton';
import { Container, Header, MethodsTable } from './styles';
import api from '~/services/api';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { ToggleButton } from 'ui-kit-takeat';

function OpeningHours() {
  const [openingHoursData, setOpeningHourData] = useState();
  const [twoShifts, setTwoShifts] = useState(false);
  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliverySchedules'}`
      );

      const { can_read } = response.data.DeliverySchedules;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const copyToAll = () => {
    setOpeningHourData(
      openingHoursData.map((o) => {
        return { ...openingHoursData[0], day: o.day, id: o.id };
      })
    );
  };

  async function getSchedules() {
    const response = await api.get('restaurants/delivery-schedules');

    setOpeningHourData(response.data);
    setTwoShifts(response.data.some((item) => item.is_two_shifts));
  }

  async function handleUpdateSchedules() {
    const x = new Date();
    const currentTime = x.getTimezoneOffset();

    try {
      await api.put('restaurants/delivery-schedules', {
        timezone_offset: currentTime,
        schedules: openingHoursData,
        is_two_shifts: twoShifts,
      });

      toast.success('Horário atualizado com sucesso');

      getSchedules();
    } catch (e) {
      toast.error('Erro ao atualizar horário');
      console.log(e.message);
    }
  }

  const formatInputValue = (value) => {
    if (value.substring(0, 1) > 2) {
      value = 2 + value.substring(1);
    }
    if (value.substring(0, 2) > 24) {
      value = '00' + value.substring(2);
    }
    if (value.substring(3, 4) > 5) {
      value = value.substring(0, 3) + '5' + value.substring(4);
    }
    return value;
  };

  useEffect(() => {
    try {
      getSchedules();
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ marginTop: 25 }}>
              <strong>Horário de Funcionamento</strong>
            </h2>
            <VideoPlayer id={73} />
          </div>
          <p>Configure o horário de abertura e fechamento do seu Delivery.</p>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <ToggleButton
              value={twoShifts}
              clickFunction={(e) => setTwoShifts(e)}
            />
            <span>Delivery em 2 turnos</span>
          </div>
        </div>
      </Header>

      <MethodsTable borderless twoShifts={twoShifts}>
        <thead>
          <tr>
            <th>Dia</th>
            <th>Ativar</th>
            <th>Início{twoShifts && ' 1° turno'}</th>
            <th>Fim{twoShifts && ' 1° turno'}</th>
            {twoShifts && <th>Início 2° turno</th>}
            {twoShifts && <th>Fim 2° turno</th>}
          </tr>
        </thead>
        <tbody>
          {openingHoursData &&
            openingHoursData.length > 0 &&
            openingHoursData.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>
                    <p style={{ margin: 0 }}>{item.day}</p>
                    {idx === 0 && (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#2EC9B7',
                          fontSize: '12px',
                        }}
                        onClick={() => copyToAll()}
                      >
                        Copiar para todos
                      </span>
                    )}
                  </td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ToggleButton
                        style={{ margin: '0 auto' }}
                        value={item.is_active}
                        clickFunction={(e) =>
                          setOpeningHourData(
                            openingHoursData.map((o) => {
                              if (o.id === item.id) {
                                return {
                                  ...o,
                                  is_active: e,
                                };
                              }
                              return o;
                            })
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <InputMask
                      mask="99:99"
                      id={item.id}
                      type="text"
                      name="initialHour"
                      value={formatTime(item.open_time)}
                      maskPlaceholder={formatTime(item.open_time)}
                      onChange={(e) => {
                        setOpeningHourData(
                          openingHoursData.map((o) => {
                            if (o.id === item.id) {
                              return {
                                ...o,
                                open_time: `2021-01-09T${formatInputValue(
                                  e.target.value
                                )}:00-03:00`,
                              };
                            }
                            return o;
                          })
                        );
                      }}
                    />{' '}
                    hs.
                  </td>
                  <td>
                    <InputMask
                      mask="99:99"
                      id={item.id}
                      type="text"
                      name="finalHour"
                      value={formatTime(item.close_time)}
                      maskPlaceholder={formatTime(item.close_time)}
                      onChange={(e) => {
                        setOpeningHourData(
                          openingHoursData.map((o) => {
                            if (o.id === item.id) {
                              return {
                                ...o,
                                close_time: `2021-01-09T${formatInputValue(
                                  e.target.value
                                )}:00-03:00`,
                              };
                            }
                            return o;
                          })
                        );
                      }}
                    />{' '}
                    hs.
                  </td>
                  {twoShifts && (
                    <td style={{ borderLeft: '1px solid lightgrey' }}>
                      <InputMask
                        mask="99:99"
                        id={item.id}
                        type="text"
                        name="initialHour2"
                        maskPlaceholder={
                          item.open_time_2
                            ? formatTime(item.open_time_2)
                            : '00:00'
                        }
                        value={
                          item.open_time_2
                            ? formatTime(item.open_time_2)
                            : '00:00'
                        }
                        onChange={(e) => {
                          setOpeningHourData(
                            openingHoursData.map((o) => {
                              if (o.id === item.id) {
                                return {
                                  ...o,
                                  open_time_2: `2021-01-09T${formatInputValue(
                                    e.target.value
                                  )}:00-03:00`,
                                };
                              }
                              return o;
                            })
                          );
                        }}
                      />{' '}
                      hs.
                    </td>
                  )}
                  {twoShifts && (
                    <td>
                      <InputMask
                        mask="99:99"
                        id={item.id}
                        type="text"
                        name="finalHour2"
                        maskPlaceholder={
                          item.close_time_2
                            ? formatTime(item.close_time_2)
                            : '00:00'
                        }
                        value={
                          item.close_time_2
                            ? formatTime(item.close_time_2)
                            : '00:00'
                        }
                        onChange={(e) => {
                          setOpeningHourData(
                            openingHoursData.map((o) => {
                              if (o.id === item.id) {
                                return {
                                  ...o,
                                  close_time_2: `2021-01-09T${formatInputValue(
                                    e.target.value
                                  )}:00-03:00`,
                                };
                              }
                              return o;
                            })
                          );
                        }}
                      />{' '}
                      hs.
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </MethodsTable>
      <SaveButton
        onClick={handleUpdateSchedules}
        title="Salvar"
        style={{ float: 'right', marginRight: '30px' }}
      />
    </Container>
  );
}

export default OpeningHours;
