import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { FiMail, FiLock, FiEye } from 'react-icons/fi';

import { FaUserMinus } from 'react-icons/fa';
import logo from '~/assets/img/logoLogin.png';
import {
  Container,
  StyledForm,
  Card,
  LeftSide,
  RightSide,
  FiEyeStyled,
  InputArea,
  ButtonWaiter,
} from './styles';
import LoginInput from '~/components/Form/LoginInput';
import { useAuth } from '~/context/AuthContext';
import { Button, DefaultInput, DefaultModal } from 'ui-kit-takeat';
import api from '~/services/api';
import { toast } from 'react-hot-toast';

function SignIn() {
  const formRef = useRef(null);

  const { signIn, loading } = useAuth();

  const [forgotPassOpen, setForgotPassOpen] = useState(false);
  const [emailToSent, setEmailToSent] = useState('');
  const toggleForgotPass = () => {
    setForgotPassOpen(!forgotPassOpen);
  };
  const [emailLoading, setEmailLoading] = useState(false);
  const sendForgotPassword = async () => {
    setEmailLoading(true);
    try {
      await api.post('/public/forgot-password', { email: emailToSent });

      setEmailLoading(false);
      setForgotPassOpen(false);
      toast.success('Sua nova senha foi enviada para o e-mail informado!');
    } catch (err) {
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Algo deu errado ao enviar o e-mail, consulte o suporte');
      }
      setEmailLoading(false);
    }
  };

  async function handleSubmit({ email, password }) {
    try {
      await signIn({ email, password });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current.setFieldError('email', 'Insira um e-mail válido');
      }
    }

    const user = localStorage.getItem('@gddashboard:user');
  }

  function togglePasswordType() {
    const password = document.querySelector('#password');

    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';

    password.setAttribute('type', type);
  }
  return (
    <Container>
      <LeftSide>
        <img src={logo} alt="Logo takeat Dashboard" />
      </LeftSide>
      <RightSide>
        <Card>
          <h1>Faça seu login</h1>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <LoginInput
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail"
            />

            <InputArea>
              <LoginInput
                icon={FiLock}
                name="password"
                type="password"
                placeholder="Senha"
                id="password"
              />

              <FiEyeStyled onClick={togglePasswordType} title="Exibir senha" />
            </InputArea>

            <button type="submit">
              {loading ? 'Carregando...' : 'Acessar'}
            </button>
            <a
              onClick={() => setForgotPassOpen(true)}
              style={{ textDecoration: 'underline', color: 'grey' }}
            >
              Esqueci minha senha
            </a>
            <DefaultModal
              width="350px"
              height="fit-content"
              toggle={toggleForgotPass}
              isOpen={forgotPassOpen}
            >
              <DefaultInput
                type="email"
                style={{ marginBottom: 10 }}
                placeholder="E-mail"
                value={emailToSent}
                onChange={(e) => setEmailToSent(e.target.value)}
                title="Informe o e-mail da sua conta:"
              />
              <Button
                title="Enviar"
                type="default"
                isLoading={emailLoading}
                disabled={emailLoading}
                onClick={() => sendForgotPassword()}
              />
            </DefaultModal>
          </StyledForm>
        </Card>
        <ButtonWaiter href="https://gm.takeat.app">
          <FaUserMinus /> Área do garçom
        </ButtonWaiter>
      </RightSide>
    </Container>
  );
}

export default SignIn;
