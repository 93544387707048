import React, { useCallback, useEffect, useState } from 'react';

import { RiKnifeLine } from 'react-icons/ri';
import { Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';

import OrderReady from '../../Items/OrderReady';
import api from '~/services/api';

export default function CardOrdersReady({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  getAllOrders,
  // paymentMethods,
}) {
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const count_orders = baskets.filter(
    (order) => order.table.table_type === 'delivery' && !order.scheduled_to
  ).length;

  // const getPaymentMethods = useCallback(async () => {
  //   const response = await api.get('restaurants/payment-methods');
  //   const parsedData = response.data.payment_methods.map((data) => ({
  //     id: data.id,
  //     label: data.name,
  //     available: data.available,
  //     keyword: data.keyword,
  //   }));
  //   setPaymentMethods(parsedData);
  // }, []);

  // useEffect(() => {
  //   getPaymentMethods();
  // }, [getPaymentMethods]);

  return (
    <Bloco>
      <Header>
        <Icon>
          <RiKnifeLine color="#fff" size={25} />
        </Icon>
        PRONTOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter((order) => !order.scheduled_to)
            .map((item) => (
              <OrderReady
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                // paymentMethods={paymentMethods}
                getAllOrders={getAllOrders}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
