import React from 'react';

import { TiCancel } from 'react-icons/ti';
import { Spinner } from 'reactstrap';
import { Header, Bloco } from './styles';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import OrderCanceled from '~/components/Orders/Items/OrderCanceled';

export default function CardOrdersCanceled({ baskets, loading }) {
  const count_orders = baskets.filter(
    (order) => order.table.table_type === 'delivery'
  ).length;

  return (
    <Bloco>
      <Header>
        <Icon>
          <TiCancel color="#fff" size={25} />
        </Icon>
        CANCELADOS
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="danger" />
          </LoadingDiv>
        ) : (
          baskets
            .filter((order) => order.table.table_type === 'delivery')
            .map((item) => <OrderCanceled key={item.basket.id} item={item} />)
        )}
      </Content>
    </Bloco>
  );
}
