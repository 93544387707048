import React, { useRef, useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { FaQuestionCircle, FaTrash } from 'react-icons/fa';
import { Row, Col, ButtonGroup, Form } from 'reactstrap';
import Switch from 'react-switch';
import {
  Header,
  Times,
  StyledForm,
  Container,
  ButtonsArea,
  Button,
  AreaSwitcher,

} from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import Checkbox from '~/components/Form/Checkbox';

import { useAuth } from '~/context/AuthContext';
import PersonalButton from '~/components/Buttons/PersonalButton';

import CoverInput from '~/components/Form/CoverInput';

function Store({ toggle, getCategories }) {
  const formRef = useRef(null);
  const { user } = useAuth();

  const [weekDays] = useState([
    'Dom',
    'Seg',
    'Ter',
    'Qua',
    'Qui',
    'Sex',
    'Sáb',
  ]);
  const [selectedDays, setSelectedDays] = useState([0, 1, 2, 3, 4, 5, 6]);

  const onDaySelected = useCallback(
    (selected) => {
      const index = selectedDays.indexOf(selected);

      if (index < 0) {
        selectedDays.push(selected);
      } else {
        selectedDays.splice(index, 1);
      }

      setSelectedDays([...selectedDays]);
    },
    [selectedDays]
  );

  function daysSelectedToStr(selected) {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(selected.includes(i) ? 't' : 'f');
    }

    return days.join('');
  }

  const [availableCategory, setAvailableCategory] = useState(true);

  function handleChangeAvailableCategory() {
    setAvailableCategory(!availableCategory);
  }

  const [availableDeliveryCategory, setAvailableDeliveryCategory] = useState(
    true
  );

  function handleChangeAvailableDeliveryCategory() {
    setAvailableDeliveryCategory(!availableDeliveryCategory);
  }

  const [exclusiveCategory, setExclusiveCategory] = useState(false);

  function handleChangeExclusiveCategory() {
    setExclusiveCategory(!exclusiveCategory);
  }
  async function handleSubmit(data) {
    try {
      const start_time = data.startTime
        ? `2021-01-09T${data.startTime}:00-03:00`
        : null;
      const end_time = data.endTime
        ? `2021-01-09T${data.endTime}:00-03:00`
        : null;

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        start_time: Yup.date('Data final inválida').nullable(),
        end_time: Yup.date('Data final inválida').nullable(),
      });

      await schema.validate(
        {
          name: data.name,
          start_time,
          end_time,
        },
        {
          abortEarly: false,
        }
      );

      const image_id_parsed = data.image_id ? Number(data.image_id) : null

      await api.post('/restaurants/product-category', {
        name: data.name,
        preparation_time: data.time || '0',
        available: availableCategory,
        available_in_delivery: availableDeliveryCategory,
        is_exclusive: exclusiveCategory,
        start_time,
        end_time,
        active_days: daysSelectedToStr(selectedDays),
        image_id: image_id_parsed
      });

      getCategories();

      toast.success('Categoria cadastrada com sucesso!');
      toggle();
    } catch (error) {
      // if (error.response.data.errorType === 'only_one_time') {
      //   toast.error(
      //     `Favor preencher o horário disponível de início e fim das categorias disponíveis.`
      //   );
      // } else {
      //   toast.error(`Erro ao cadastrar categoria!`);
      // }
      toast.error(
        `Erro ao cadastrar categoria! Favor preencher corretamente os campos.`
      );
      console.log(error);
    }
  }

  const [deleteCover, setDeleteCover] = useState(false)

  return (
    <Container>
      <Header>
        <div>
          <h3>Adicionar categoria</h3>
        </div>
      </Header>

      <StyledForm ref={formRef} onSubmit={handleSubmit}>
        <Row>
          <Col size="md-6">
        
              <Input
                label="Nome"
                name="name"
                type="text"
                placeholder="Nome da categoria"
              />
         

            <Row>
              <Input
                label="Tempo de preparo"
                name="time"
                type="number"
                placeholder="Tempo (em minutos)"
              />
            </Row>
         
          </Col>

          <Col md='6'>
            <p style={{fontWeight: 'bold'}}>
            Imagem para capa dessa categoria
            </p>
          
          <div style={{display: 'flex', alignItems: 'center'}}>
            <CoverInput name='image_id' deleteCover={deleteCover}/>
            <FaTrash cursor='pointer' onClick={()=>setDeleteCover(!deleteCover)} color='#FF2B3A' style={{marginLeft: 6}}/>
          </div>
              
            
            <p>
            Caso não queira inserir uma imagem, usaremos a foto do primeiro produto dessa categoria como imagem de capa.
            </p>
          </Col>
        </Row>

        <Row>
          {!user?.only_delivery && (
            <Col md="2">
              <AreaSwitcher title="Disponível no presencial">
                <Switch
                  onChange={handleChangeAvailableCategory}
                  draggable
                  checked={availableCategory}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={27}
                  width={44}
                  handleDiameter={16}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                />
                <span>Disponível no presencial</span>
              </AreaSwitcher>
            </Col>
          )}

          {user?.delivery_info?.is_delivery_allowed && (
            <Col md="2">
              <AreaSwitcher title="Disponível no delivery">
                <Switch
                  onChange={handleChangeAvailableDeliveryCategory}
                  draggable
                  checked={availableDeliveryCategory}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={27}
                  width={44}
                  handleDiameter={16}
                  activeBoxShadow="0 0 2px 3px #111"
                  boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  onColor="#0CCAB4"
                  offColor="#666666"
                />
                <span>Disponível no delivery</span>
              </AreaSwitcher>
            </Col>
          )}
          <Col md="2">
            <AreaSwitcher title="Uso exclusivo">
              <Switch
                onChange={handleChangeExclusiveCategory}
                draggable
                checked={exclusiveCategory}
                checkedIcon={false}
                uncheckedIcon={false}
                height={27}
                width={44}
                handleDiameter={16}
                activeBoxShadow="0 0 2px 3px #111"
                boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                onColor="#0CCAB4"
                offColor="#666666"
              />
              <span>Uso exclusivo</span>
            </AreaSwitcher>
          </Col>
    
        </Row>

        <Row className="mt-2">
          <div>
            <b> Dias: </b>
            <FaQuestionCircle
              color="darkOrange"
              title="Dias em que que essa categoria ficará disponível para o cliente"
            />

            <ButtonGroup style={{ margin: '10px 0', marginLeft: 10 }}>
              {weekDays.map((item, i) => (
                <Button
                  tag="label"
                  className="btn-simple"
                  style={{
                    padding: '5px',
                    'font-size': '12pt',
                    filter: selectedDays.includes(i)
                      ? 'opacity(100%)'
                      : 'opacity(40%)',
                  }}
                  onClick={() => onDaySelected(i)}
                  active={selectedDays.includes(i)}
                  type="button"
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </Row>

        <div style={{ marginBottom: -10, marginTop: 10 }}>
          <b> Horário disponível: </b>

          <FaQuestionCircle
            color="darkOrange"
            title="Determina a faixa de horário em que essa categoria ficará disponível para o cliente realizar o pedido"
          />
        </div>

        <Times>
          <div> das </div>
          <Input type="time" name="startTime" />
          <div> às </div>
          <Input type="time" name="endTime" /> horas.
        </Times>

        <ButtonsArea>
          <PersonalButton
            color="#FF2C3A"
            message="Cancelar"
            style={{
              marginLeft: 0,
            }}
            onClick={() => {
              toggle();
            }}
          />

          <PersonalButton
            color="#0CCAB4"
            message="Salvar"
            type="submit"
            style={{
              marginRight: 10,
            }}
          />
        </ButtonsArea>
      </StyledForm>
    </Container>
  );
}

export default Store;
