import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  subDays,
  differenceInDays,
} from 'date-fns';

import FileSaver from 'file-saver';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { Input } from '@material-ui/core';

import { FaQuestion, FaDownload } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';

import { Row, Col, Collapse } from 'reactstrap';

import {
  Card,
  Container,
  Header,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  TableReport,
  DataPickerArea,
  DatePickerStyled,
  ButtonsSelectDate,
  DownloadSheetButton,
} from './styles';

import PermissionErrorContainer from '~/components/PermissionErrorContainer';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import { generateSheet } from '~/services/SheetGenerate/generateOrderCanceledSheet';

function ReportsOrderCanceled() {
  const [rangeOn, setRangeOn] = useState(true);
  const [orders, setOrders] = useState([]);
  const formRef = useRef(null);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setYesterday() {
    setRangeOn(true);
    const inicial = startOfDay(subDays(new Date(), 1));
    const final = endOfDay(subDays(new Date(), 1));

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
      const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
      setRangeOn(true);
      const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
      const phrase = `De ${inicialFormat} até ${finalFormat}`;

      setHourPhrase(phrase);
    }
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsCanceledProducts'}`
      );

      const { can_read } = response.data.ReportsCanceledProducts;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const getOrders = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/reports/orders-canceled', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setOrders(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    if (permission) {
      getOrders();
    }
  }, [getOrders, permission]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function getTableName(table) {
    const { table_number, table_type } = table;

    switch (table_type) {
      case 'delivery':
        return 'Delivery';
      case 'balcony':
        return 'Balcão';
      case 'table':
        return table_number;
      default:
        return table_number;
    }
  }

  const handleDownloadSheet = () => {
    try {
      if (!orders || orders.length === 0) {
        return;
      }

      const sheet = generateSheet(inicialDate, finalDate, orders);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Pedidos Cancelados(${format(new Date(inicialDate), 'dd-MM')}_${format(
          new Date(finalDate),
          'dd-MM'
        )}).xlsx`
      );
    } catch (err) {
      console.error('Error download sheet', err);
    }
  };

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2 style={{ marginBottom: '15px' }}>
            Relatório de Pedidos Cancelados
          </h2>
          <p>Acompanhe os pedidos cancelados no seu estabelecimento!</p>
        </div>
      </Header>

      <ButtonsSelectDate>
        <p>{hourPhrase}</p>
        <div>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setToday}
            color="info"
          >
            Hoje
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setYesterday}
            color="info"
          >
            Ontem
          </SelectDateButton>
          <SelectDateButton
            className="btn-round mr-auto"
            onClick={setWeek}
            color="info"
          >
            Essa semana
          </SelectDateButton>

          <DataPickerArea>
            <DatePickerStyled
              selected={monthYearDate}
              onChange={(date) => {
                setMonthYearDate(date);
                setMonth(date);
                setRangeOn(true);
              }}
              locale="pt"
              showMonthYearPicker
              customInput={
                <SelectDateButton
                  className="btn-round mr-auto"
                  color="info"
                >
                  Mês/Ano <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              }
            />
          </DataPickerArea>

          <SelectDateButton
            className="btn-round mr-auto"
            onClick={toggle}
            color="info"
          >
            Selecionar Período
            <MdArrowDropDown color="white" size={20} />
          </SelectDateButton>
          <FaQuestion
            color="#ff2c3a"
            title="Selecione o intervalo entre as datas com máximo de 31 dias"
            size={15}
            style={{ marginTop: -45, marginLeft: -10, cursor: 'help' }}
          />
        </div>
      </ButtonsSelectDate>

      <div>
        <Collapse isOpen={isOpen}>
          <Form
            onSubmit={handleChangeDate}
            ref={formRef}
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <DateSelectorDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Inicial"
                  type="datetime-local"
                  name="initialDate"
                  onChange={(e) => setInitialPre(e.target.value)}
                  defaultValue={defaultInicial}
                  className="data"
                />
              </DateDiv>
              <DateDiv>
                <Input
                  id="datetime-local"
                  label="Data Final"
                  type="datetime-local"
                  name="finalDate"
                  onChange={(e) => setFinalPre(e.target.value)}
                  defaultValue={defaultFinal}
                  className="data"
                />
              </DateDiv>
              <div>
                <SubmitDate type="submit">Filtrar</SubmitDate>
              </div>
            </DateSelectorDiv>
          </Form>
        </Collapse>
        <p
          style={{
            textAlign: 'right',
            color: 'red',
            display: rangeOn ? 'none' : 'block',
            paddingRight: 20,
          }}
        >
          Favor selecionar o intervalo máximo de 31 dias entre as datas.
        </p>
      </div>

      <Card>
        <Row style={{ marginBottom: '5px' }}>
          <Col md="4">
            <DownloadSheetButton onClick={handleDownloadSheet}>
              <FaDownload style={{ marginRight: '3px' }} />
              Baixar planilha
            </DownloadSheetButton>
          </Col>
        </Row>

        <TableReport borderless>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Data/Hora</th>
              <th>Cliente/Garçom</th>
              <th>Qtd.</th>
              <th>Produto</th>
              <th>Valor</th>
              <th>Justificativa</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{getTableName(order.basket.bill.session.table)}</td>
                <td>{formatCompleteDate(order.canceled_at)}</td>
                {order.basket.bill.waiter && (
                  <td>{order.basket.bill.waiter.name}</td>
                )}
                {order.basket.bill.buyer && (
                  <td>{order.basket.bill.buyer.name || order.basket.bill.buyer.phone}</td>
                )}
                <td>{order.amount}</td>
                <td>{order.product.name}</td>
                <td>
                  R$
                  {parseFloat(order.total_price).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ color: 'red' }}>{order.cancel_reason}</td>
                <td>{order.users.name}</td>
              </tr>
            ))}
          </tbody>
        </TableReport>
      </Card>
    </Container>
  );
}

export default ReportsOrderCanceled;
