import styled from 'styled-components';

export const Container = styled.tr`
  display: ${(props) => props.isOpen && 'none'};
  border-top: none;

  td {
    border-top: none;
  }
`;
export const Content = styled.div`
  height: 100%;
  padding: 30px;
  position: relative;
  overflow: auto;
`;

export const LegendStyled = styled.p`
  margin-top: -10px;
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 97%;
  position: absolute;
  bottom: 80px; */
`;
export const TdStyled = styled.td`
  margin-left: 58px;
  @media (max-width: 1281px) {
    margin-left: 0px;
  }
  &::before {
    content: '';
    position: absolute;
    top: -36px;
    left: 0px;
    margin-left: 28px;
    margin-right: 45px;
    background-image: ${(props) => `url(${`${props.backgroundImage}`}) `};
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 80px;

    @media (max-width: 1281px) {
    margin-left: 0px;
  }
  }
`;

export const SetSliceSizeButton = styled.button`
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #ff2c3a;
  font-size: 14px;

  margin-bottom: 40px;
  margin-left: 40px;
  float: left;
  font-weight: bold;

    @media (max-width: 1281px) {
    margin-left: 0px;
  }
`;

export const ButtonLinnk = styled.button`
  border: none;
  text-decoration: underline;
  color: ${(props) => (props.group ? '#ff2c3a' : '#0CCAB4')};
  background-color: transparent;
  font-weight: bold;

  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: -20px;

      @media (max-width: 1281px) {
    margin-left: 0px;
      margin-top: 20px;
  }
`;

export const DropdownDiv = styled.div`

  display: flex;
  align-items: center;
  height: 30px;
  margin-right: 15px;
  justify-content: end;
  @media(min-width: 1600px){
    justify-content: end;
  }
           
`

export const ButtonArea = styled.div`

  margin-top: 12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

    @media (max-width: 1281px) {
    margin-top:28px;
  }
      
`