import React, { useState, useEffect, useRef, useCallback } from 'react';

import { MdMenu, MdRestaurantMenu } from 'react-icons/md';
import {
  FaArrowRight,
  FaArrowLeft,
  FaPowerOff,
  FaSignOutAlt,
  FaBell,
  FaWhatsapp,
} from 'react-icons/fa';

import Switch from 'react-switch';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import history from '~/services/history';
import {
  Container,
  Logo,
  DivButton,
  StyledLink,
  LogoTakeat,
  RestaurantInfo,
  CollapseButton,
  NotificationsButton,
  PendingNotification,
  WppButton,
  WppReconnect,
  WppModalText,

} from './styles';
import logo from '~/assets/img/logo_takeat_cinza.png';
import { useAuth } from '~/context/AuthContext';
import BackButton from '~/components/Buttons/BackButton';
import PersonalButton from '~/components/Buttons/PersonalButton';
import { useOrder } from '~/context/orderContext';
import { AnimatePresence } from 'framer-motion';
import { NotificationPannel } from '../NotificationPannel';
import api from '~/services/api';
import { Button } from 'ui-kit-takeat';

import { useUzapi } from '~/context/UzapiContext';


function Navbar({ toggleCollapse, collapsed }) {
  const {
    signOut,
    user,
    changeRestaurantStatus,
    updateDeliveryStatus,
    updateWithdrawalStatus,
  } = useAuth();
  const { ifoodStatus } = useOrder();
  const [deliveryOpened, setDeliveryOpened] = useState(false);
  const [withdrawalOpened, setWithdrawalOpened] = useState(false);
  const [ifoodOpen, setIfoodOpen] = useState(user.ifood_open);
  const [opened, setOpened] = useState(user.opened);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notiIds, setNotiIds] = useState([]);
  const notiButtonRef = useRef(null);
  const [newsRead, setNewsRead] = useState(
    JSON.parse(localStorage.getItem('@gddashboard:newsRead'))
  );

  const {
    wppConnected,
    wppQrcode,
    showReconnect,
    setShowReconnect,
    loadingQrCode,
    getQrCodeWpp,
    logoutWpp,
  } = useUzapi();

  const page = window.location.pathname;

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  function toggleDropDown() {
    setIsDropDownOpen(!isDropDownOpen);
  }

  async function handleCloseRestaurant() {
    if (user?.delivery_info?.is_delivery_allowed) {
      setIsDropDownOpen(true);
    } else {
      const response = await changeRestaurantStatus({
        opened: !opened,
      });

      setOpened(response.opened);
    }
  }

  async function handleRestaurantStatusChange() {
    const response = await changeRestaurantStatus({
      opened: !opened,
      is_delivery_active: !!deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_delivery_active, is_withdrawal_active } = response.delivery_info;

    setOpened(response.opened);
    setDeliveryOpened(is_delivery_active);
    setWithdrawalOpened(is_withdrawal_active);
  }

  async function handleDeliveryStatusChange() {
    const response = await updateDeliveryStatus({
      opened: !!opened,
      is_delivery_active: !deliveryOpened,
      is_withdrawal_active: !!withdrawalOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  async function handleWithdrawalStatusChange() {
    const response = await updateWithdrawalStatus({
      is_withdrawal_active: !withdrawalOpened,
      opened: !!opened,
      is_delivery_active: !!deliveryOpened,
      ifood_open: !!ifoodOpen,
    });

    const { is_withdrawal_active, is_delivery_active } = response.delivery_info;

    setWithdrawalOpened(is_withdrawal_active);
    setDeliveryOpened(is_delivery_active);
  }

  const getNotifications = async () => {
    const res = await api.get('/restaurants/notifications');

    setNotifications(res.data);
    const fixedIds = res.data.fixed.map((f) => f.id);
    const allIds = res.data.all.map((a) => a.id);
    setNotiIds(fixedIds.concat(allIds));
  };

  const checkNotificationsRead = () => {
    return notiIds.some((nid) => !newsRead?.includes(nid));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (!!opened !== user.opened) {
      setOpened(user.opened);
    }

    const { is_delivery_active, is_withdrawal_active } = user?.delivery_info;

    if (!!deliveryOpened !== is_delivery_active) {
      setDeliveryOpened(is_delivery_active);
    }

    if (!!withdrawalOpened !== is_withdrawal_active) {
      setWithdrawalOpened(is_withdrawal_active);
    }
  }, [opened, user.delivery_info, deliveryOpened, withdrawalOpened, user]);

  const [wppModal, setWppModal] = useState(false);
  const toggleWppModal = () => {
    setWppModal(!wppModal);
  };


  return (
    <Container collapsed={collapsed}>
      <AnimatePresence>
        {notificationsOpen && (
          <NotificationPannel
            setNotificationsOpen={setNotificationsOpen}
            notiButtonRef={notiButtonRef}
            notifications={notifications}
          />
        )}
      </AnimatePresence>
      <div style={{ width: '15%', display: 'flex', float: 'left' }}>
        <div style={{ paddingLeft: 20 }}>
          <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
          {page !== '/operation' && (
            <CollapseButton
              type="button"
              onClick={toggleCollapse}
              collapsed={collapsed}
            >
              {collapsed ? (
                <FaArrowRight color="#fff" />
              ) : (
                <FaArrowLeft color="#fff" />
              )}
            </CollapseButton>
          )}
        </div>
        {page === '/operation' && (
          <div style={{ float: 'left', paddingLeft: 20 }}>
            <StyledLink to="/products">
              <PersonalButton color="#ff403d" message="Menu" Icon={MdMenu} />{' '}
            </StyledLink>
          </div>
        )}

        {page !== '/operation' && !user.only_delivery && (
          <div style={{ float: 'left', paddingLeft: 20 }}>
            <StyledLink to="/operation">
              <PersonalButton
                color="#ff403d"
                message="Operação"
                Icon={MdRestaurantMenu}
              />{' '}
            </StyledLink>
          </div>
        )}
      </div>

      <div style={{ float: 'left', marginRight: 50 }}>
        <StyledLink to="/products">
          <LogoTakeat
            src={logo}
            alt="Logo Takeat"
            style={{
              opacity: 0.8,
            }}
          />
        </StyledLink>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 30,
          float: 'right',
          paddingRight: '10px',
        }}
      >
        {user.use_wpp && (
          <div style={{ position: 'relative' }}>

            <WppButton
              connected={wppConnected}
              onClick={(e) => {
                e.stopPropagation();
                setShowReconnect(!showReconnect);
                if (!showReconnect) {
                  getQrCodeWpp();
                }
              }}
              id="wpp_button"
            >
              <FaWhatsapp size={25} />
              <span>Robô {!wppConnected ? 'desconectado' : 'conectado'}</span>
            </WppButton>
            {wppConnected ? (
              <WppReconnect show={showReconnect}>
                <Button
                  type="negative"
                  title="Desconectar"
                  onClick={() => logoutWpp()}
                />
              </WppReconnect>
            ) : (
              <WppReconnect
                show={showReconnect}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  onClick={() => getQrCodeWpp()}
                  isLoading={loadingQrCode}
                  type="default"
                  title={wppQrcode ? 'Gerar novo QR Code' : 'Iniciar conexão'}
                  disabled={loadingQrCode}
                />
                <WppModalText>
                  <h1>
                    Siga as instruções para ler o{' '}
                    <span style={{ color: '#2EC9B7' }}>QR Code</span> e efetivar
                    a conexão:
                  </h1>
                  <ol>
                    <li>
                      <p>
                        Abra seu WhatsApp e vá nos três pontinhos (no Android)
                        ou em “configurações” (no Iphone);
                      </p>
                    </li>
                    <li>
                      <p>
                        Clique no botão <b>“Dispositivos Vinculados”</b> e leia
                        o QR Code. Caso apareça uma mensagem de erro, clique em{' '}
                        <b>”Gerar novo QR Code”</b> e tente novamente;
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizada a conexão, constará no seu celular o
                        dispositivo com nome <b>“Uzapi”</b>.
                      </p>
                    </li>
                  </ol>
                </WppModalText>

                {wppQrcode &&
                  (loadingQrCode ? (
                    <div
                      style={{
                        width: '264px',
                        height: '264px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <img src={wppQrcode} />
                  ))}
              </WppReconnect>
            )}
          </div>
        )}
        <NotificationsButton
          onClick={() => setNotificationsOpen(true)}
          ref={notiButtonRef}
        >
          <FaBell size={20} />
          {checkNotificationsRead() && <PendingNotification />}
        </NotificationsButton>
        {(user?.delivery_info?.is_delivery_allowed ||
          user?.delivery_info?.is_withdrawal_allowed) &&
          user.is_active ? (
          <Dropdown toggle={toggleDropDown} isOpen={isDropDownOpen}>
            <DropdownToggle tag="span">
              <PersonalButton
                Icon={FaPowerOff}
                message={user.opened ? 'Fechar' : 'Abrir'}
                color={user.opened ? '#ff403d' : 'grey'}
                onClick={handleCloseRestaurant}
              />
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem header>
                <RestaurantInfo>
                  <Switch
                    onChange={handleRestaurantStatusChange}
                    checked={opened}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={20}
                    width={40}
                    handleDiameter={20}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                  />
                  <span>Restaurante</span>
                </RestaurantInfo>
              </DropdownItem>



              {user?.delivery_info?.is_delivery_allowed && (
                <DropdownItem header>
                  <RestaurantInfo>
                    {' '}
                    <Switch
                      onChange={handleDeliveryStatusChange}
                      checked={deliveryOpened}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      disabled={!opened}
                      height={20}
                      width={40}
                      handleDiameter={20}
                      activeBoxShadow="0 0 2px 3px #111"
                      boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    />
                    <span>Delivery</span>
                  </RestaurantInfo>
                </DropdownItem>
              )}

              {user?.delivery_info?.is_withdrawal_allowed && (
                <DropdownItem header>
                  <RestaurantInfo>
                    {' '}
                    <Switch
                      onChange={handleWithdrawalStatusChange}
                      checked={withdrawalOpened}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      disabled={!opened}
                      height={20}
                      width={40}
                      handleDiameter={20}
                      activeBoxShadow="0 0 2px 3px #111"
                      boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    />
                    <span>Retirada</span>
                  </RestaurantInfo>
                </DropdownItem>
              )}
              {user?.has_ifood &&
                Array.isArray(ifoodStatus) &&
                ifoodStatus?.map((status) => (
                  <DropdownItem>
                    <p style={{ textAlign: 'center' }}>
                      {status.ifood_restaurant}
                    </p>
                    <Tooltip
                      title={
                        status.ifood_open ? (
                          <p style={{ color: 'white', fontSize: 16 }}>
                            Restaurante aberto no iFood
                          </p>
                        ) : (
                          <div>
                            <p style={{ color: 'white', fontSize: 16 }}>
                              {status?.message?.title},{' '}
                              {status?.message?.subtitle}
                            </p>
                            <span style={{ color: 'white', fontSize: 16 }}>
                              {status?.message?.description}
                            </span>
                          </div>
                        )
                      }
                      placement="right"
                      style={{
                        margin: '0 auto',
                        width: 'fit-content',
                        color: status.ifood_open ? 'green' : 'red',
                      }}
                      arrow
                    >
                      <p>{status.ifood_open ? 'Aberto' : 'Fechado'}</p>
                    </Tooltip>
                    <RestaurantInfo />{' '}
                    {/* só pra gerar a bordinha embaixo dessa seção, igual às outras */}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        ) : (
          user.is_active && (
            <PersonalButton
              Icon={FaPowerOff}
              message={user.opened ? 'Fechar' : 'Abrir'}
              color={user.opened ? '#ff403d' : 'grey'}
              onClick={handleCloseRestaurant}
            />
          )
        )}

        <PersonalButton
          Icon={FaSignOutAlt}
          message="Sair"
          color="#ff403d"
          onClick={signOut}
        />
      </div>
    </Container>
  );
}

export default Navbar;
