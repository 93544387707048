import { Carousel, CarouselControl, CarouselIndicators, Modal } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 97%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;
export const CarouselStyled = styled(Carousel)`
  width: 100%;

  img {
    width: 500px;
  }
`
export const CarouselControlStyled = styled(CarouselControl)`

  span{

  fill: #2ec9b7 !important;
  color:  #2ec9b7 !important;

  }
 
`

export const CarouselIndicatorsStyled = styled(CarouselIndicators)`
  margin-bottom: 80px;
button{
  background-color: #33333370 !important;

}

`
export const WppModal = styled(Modal)`
  .modal-content {
    width: 600px;
    border-radius: 10px;
  }

  img {
    border-radius: 10px;
  }

  h1 {
    margin: 20px 0;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  p {
    width: 505px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;

export const ReconnectButton = styled.button`
  width: 233px;
  height: 48px;
  line-height: 48px;
  background: #ff2c3a;
  border-radius: 5px;
  border: none;
  margin: 80px auto 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;
