import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { Table } from 'reactstrap';

export const Container = styled.div`
  width: 100%;
  margin: 10px;
  margin-top: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const CategoriesTable = styled(Table)`
  width: 95%;
  min-width: 400px;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #D7D5D5;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #dee2e6;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  margin-right: 10px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const NavDiv = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  clear: both;
`;

export const NavDivTeste = styled.div`
  float: right;
  margin: auto;
`;

export const ColorTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  content: ' ';
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

export const LoadingDiv = styled.div`
  padding-top: 20px;
  justify-content: right;
  height: 60px;
  width: 100%;
  text-align: right;
`;

export const EmptyDiv = styled.div`
  padding-top: 20px;
  justify-content: center;
  height: 60px;
  width: 95%;
  text-align: center;
`;

export const Badge = styled.div`
  width: 14px;
  height: 14px;
  font-size: 13px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
`;

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;
