import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';
import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/DefaultButton';
import RegisterButton from '~/components/Buttons/RegisterButton';

import { Container, Card, Header, ErrorContainer } from './styles';
import Bank from './Bank';
import api from '~/services/api';
import getValidationErrors from '~/services/getValidationErrors';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import PaymentMethod from './PaymentMethod';

function Banks() {
  const [isModalCreateBankOpen, setIsModalCreateBankOpen] = useState(false);

  const [banks, setBanks] = useState();

  const toggleModalCreateBank = useCallback(() => {
    setIsModalCreateBankOpen(!isModalCreateBankOpen);
  }, [isModalCreateBankOpen]);

  const getBanks = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/bank-accounts');

      setBanks(response.data);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao baixar contas');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/bank-accounts', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getBanks();
        toggleModalCreateBank();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getBanks, toggleModalCreateBank]
  );

  const handleDeleteBank = useCallback(async (id) => {
    if (window.confirm('Você realmente deseja deletar a conta?')) {
      try {
        await api.delete(`/restaurants/cash-flows/bank-accounts/${id}`);
        getBanks();
      } catch (error) {
        toast.error('Erro deletar conta');
      }
    }
  }, []);

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FinanceBankAccounts'}`
      );

      const { can_read } = response.data.FinanceBankAccounts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  //formas de pagamento
  const formRef = useRef(null);
  const [isModalCreateMethodOpen, setIsModalCreateMethodOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();

  const toggleModalCreateMethod = useCallback(() => {
    setIsModalCreateMethodOpen(!isModalCreateMethodOpen);
  }, [isModalCreateMethodOpen]);

  const getMethods = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/cash-flows/payment-methods');

      setPaymentMethods(response.data);
    } catch (error) {
      toast.error('Erro ao baixar métodos');
    }
  }, []);

  const handleSubmitPaymentMethod = useCallback(
    async (data) => {
      try {
        if (formRef?.current) {
          formRef.current.setErrors({});
        }
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/restaurants/cash-flows/payment-methods', {
          name: data.name,
        });

        toast.success('Conta cadastrada com sucesso!');

        getMethods();
        toggleModalCreateMethod();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          if (formRef?.current) {
            formRef.current.setErrors(errors);
          }
        } else {
          // console.tron.log(error);
          toast.error(`Erro ao cadastrar conta!`);
        }
      }
    },
    [getMethods, toggleModalCreateMethod]
  );

  async function handleDeleteMethod(id) {
    if (window.confirm('Você realmente deseja deletar este método?')) {
      try {
        await api.delete(`/restaurants/cash-flows/payment-methods/${id}`);
        getMethods();
      } catch (error) {
        toast.error('Erro ao deletar método');
      }
    }
  }

  // const [permission, setPermission] = useState();

  // const validateUser = useCallback(async () => {
  //   try {
  //     const response = await api.get(
  //       `/restaurants/users/role-permission/${'FinancePaymentMethods'}`
  //     );

  //     const { can_read } = response.data.FinancePaymentMethods;

  //     setPermission(can_read);
  //   } catch (error) {
  //     // setPermission(false);
  //     // if (error?.response?.data?.payload?.user_access === 'pdv') {
  //     //   window.location.href = '/operation';
  //     // }
  //     toast.error('Erro ao solicitar acesso');
  //   }
  // }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    getBanks();
    getMethods();
  }, [getBanks, getMethods]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Conciliação bancária</h2>
          <p>Crie aqui os bancos que serão usados para movimentações.</p>
        </div>

        <RegisterButton onClick={toggleModalCreateBank} />
      </Header>
      <Card borderless>
        <thead>
          <tr>
            <th>Conta</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {banks &&
            banks.length > 0 &&
            banks.map((bank) => (
              <Bank
                bank={bank}
                handleDeleteBank={handleDeleteBank}
                key={bank.id}
                setBanks={setBanks}
                getBanks={getBanks}
              />
            ))}
        </tbody>
      </Card>

      <Modal isOpen={isModalCreateBankOpen} toggle={toggleModalCreateBank}>
        <ModalHeader> Criar Conta</ModalHeader>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalBody>
            <Input label="Nome do banco" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>

      <Header>
        <div>
          <h2>Métodos de pagamento</h2>
          <p>
            Crie aqui os métodos de pagamento que serão usados para
            movimentações
          </p>
        </div>

        <RegisterButton onClick={toggleModalCreateMethod} />
      </Header>
      <Card borderless>
        <thead>
          <tr>
            <th>Método</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods &&
            paymentMethods.length > 0 &&
            paymentMethods.map((method) => (
              <PaymentMethod
                method={method}
                handleDeleteMethod={handleDeleteMethod}
              />
            ))}
        </tbody>
      </Card>

      <Modal isOpen={isModalCreateMethodOpen} toggle={toggleModalCreateMethod}>
        <ModalHeader> Criar métodos de pagamento</ModalHeader>
        <Form onSubmit={handleSubmitPaymentMethod} ref={formRef}>
          <ModalBody>
            <Input label="Nome do método" name="name" />
          </ModalBody>
          <ModalFooter>
            <SaveButton type="submit" message="Salvar" />
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default Banks;