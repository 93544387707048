import React, { useState, useEffect, useCallback, useRef } from 'react';

import { CategoryList, CategoryThumbnailLink } from './styles';

function CategoryThumbnail({ category, handleChangeCategory }) {
  const [imageCover, setImageCover] = useState();
  const [active, setActive] = useState(false);

  const changeCover = useCallback(() => {
    const photo = category?.products.filter((product) => product.image?.url);

    setImageCover(photo[0]);
  }, [category]);

  useEffect(() => {
    changeCover();
  }, [changeCover]);

  return (
    <>
      <CategoryList
        onClick={() => {
          handleChangeCategory(category?.name);
        }}
      >
        <CategoryThumbnailLink
          style={{
            backgroundImage: `url(${imageCover?.image?.url || ''})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          actives={active}
        />
        <p>{category?.name}</p>
      </CategoryList>
    </>
  );
}

export default CategoryThumbnail;
