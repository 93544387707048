import styled from 'styled-components';

import NumberFormat from 'react-number-format';
import { Table } from 'reactstrap';

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
`;

export const AddressesTable = styled(Table)`
  width: 100%;
  min-width: 400px;
  margin: 0 auto;
  padding: 0px;

  thead {
    border-bottom: 1px solid #ddd;
    text-align: center;

    tr > th {
      text-transform: none;
    }
  }

  tbody {
    td {
      padding: 5px !important;
    }

    td:first-child {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    tr:first-child {
      border-bottom: 1px solid #ddd;

      td {
        padding: 15px 5px !important;
      }
    }

    tr:nth-child(2) td {
      padding-top: 18px !important;
    }
  }
`;

export const NumberInput = styled(NumberFormat)`
  margin: 0 4px;
  height: 44px;
  width: 120px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 4px;
  }
`;

export const TextInput = styled.input`
  height: 44px;
  width: 100%;
  border: 1px solid #33333320;
  border-radius: 4px;
  padding: 0 10px;
`;

export const SelectContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  label {
    font-weight: bold;
  }
`;

export const CheckButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const PlusButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid #0ccab4;
    svg {
      color: #0ccab4;
    }
  }
`;

export const TrashButton = styled.button`
  height: 44px;
  width: 22px;
  padding: 0 6px;
  border: none;
  background-color: #fff;
  svg {
    color: #f00;
  }
`;
