import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  font-family: 'Poppins';
  font-style: normal;
  color: #000000;

  h1 {
    font-weight: 700;
    font-size: 25px;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }
`;

export const ServiceTaxTable = styled.div`
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #666666;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  text-align: center;
  gap: 5px;

  span {
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const RowContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  cursor: ${(props) => (props.collapsable ? 'pointer' : 'default')};
  text-align: center;
  gap: 5px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const MonthRow = styled.div`
  padding: 20px 10px;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #666666;
  text-align: center;

  span {
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }
`;

export const SessionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.check ? '14px 0' : '0')};
  text-align: center;
  gap: 5px;
  position: relative;
  transition: all 0.2s;
  transform: ${(props) => (props.check ? 'scaleY(1)' : 'scaleY(0)')};

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    flex-basis: 25%;
  }

  span:last-child {
    text-align: right;
  }

  span:first-child {
    text-align: left;
  }

  &::after {
    content: '';
    position: absolute;
    top: -16px;
    left: 5px;
    background-color: #0ccab4;
    height: ${(props) => (props.check ? '32px' : '0px')};
    width: 1px;
  }
`;

export const DatePicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  float: right;
  position: relative;
  ${(props) => props.loading && 'pointer-events: none'};
`;

export const ButtonContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ff2c3a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) => props.loading && 'pointer-events: none'};
`;

export const DateContainer = styled.span`
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 180px;
  text-align: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #666666;

  cursor: pointer;
`;
