import styled from 'styled-components';

import NumberFormat from 'react-number-format';

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
`;

export const Map = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 4px;
`;

export const SearchBtn = styled.button`
  height: 44px;
  width: 100%;
  min-width: 60px;
  border: none;
  background: #3bd2c1;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
`;

export const NumberInput = styled(NumberFormat)`

  height: 44px;
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 4px;
  }
`;

export const CheckButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 4px;
  border: none;
  background-color: #0ccab4;
  svg {
    color: #fff;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const TrashButton = styled.button`
  height: 44px;
  width: 22px;
  padding: 0 6px;
  border: none;
  background-color: #fff;
  svg {
    color: #f00;
  }
`;
