import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, Container } from 'reactstrap';

import {
  FaCircle,
  FaMinus,
  FaPen,
  FaPlus,
  FaPrint,
  FaTimes,
  FaTrash,
} from 'react-icons/fa';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import {
  Content,
  OperationArea,
  OrderSystemArea,
  BillTableArea,
  ButtonsArea,
  ProductsCard,
  CardHeader,
  CardItem,
  ButtonCancel,
  XButton,
  ConfirmDiv,
  ConfirmationButton,
  CanceledButton,
  AlterButton,
  AmountArea,
  SvgContainer,
  AddedItem,
  CardProduct,
  CardComplement,
  CardContent,
} from './styles';
import { useCart } from '~/context/OrderingSystem/Cart';
import Menu from '~/pages/OrderingSystem/Menu';
import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import { Button } from 'ui-kit-takeat';

function TableEditDelivery({ session, item, toggleDeleteDelivery, disabled }) {
  const { closeCart } = useCart();
  const { user } = useAuth();
  const [confirmCancel, setConfirmCancel] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [added, setAdded] = useState([]);
  const [orderBasket, setOrderBasket] = useState([]);
  const [isModalOperationOpened, setIsModalOperationOpened] = useState(false);
  const [confirmMinimumModal, setConfirmMinimumModal] = useState(false);

  const toggleMinimumModal = () => {
    setConfirmMinimumModal(!confirmMinimumModal);
  };

  function toggleModalOperation() {
    setIsModalOperationOpened(!isModalOperationOpened);
    setAdded([]);
    setCanceled([]);
    closeCart();
  }
  const getBills = useCallback(async () => {
    const res = await api.get(
      `restaurants/table-sessions-bills/${item.session_id}`
    );
    setOrderBasket(res.data.bills[0].order_baskets[0]);
  }, [item.id]);

  const calculateNewTotal = (item) => {
    const currentPrice = orderBasket.orders.reduce(
      (acc, cur) => acc + parseFloat(cur.total_price),
      0
    );
    const addedPrice = added.reduce(
      (acc, cur) => acc + cur.amount * parseFloat(cur.product.delivery_price),
      0
    );

    const canceledPrice = canceled.reduce(
      (acc, cur) =>
        acc +
        parseFloat(
          orderBasket.orders.find((o) => o.product.id === cur)?.product
            ?.total_price || 0
        ),

      0
    );

    return (
      currentPrice +
      addedPrice -
      canceledPrice -
      (parseFloat(item?.total_price) || 0)
    );
  };

  const [loadingAlter, setLoadingAlter] = useState(false);

  const alterOrder = async () => {
    setLoadingAlter(true);
    try {
      const response = await api.put(
        `/restaurants/delivery/orders/alter/${item.session_id}`,
        { added, canceled }
      );

      const newOrder = response.data;
      item.basket.total_price = newOrder.total_price;
      item.total_delivery_price = newOrder.total_delivery_price;
      setIsModalOperationOpened(false);
      closeCart();
      setAdded([]);
      setCanceled([]);
      toast.success('Pedido alterado com sucesso');
    } catch (err) {
      console.log('alterOrder error: ', err);
    }
    setLoadingAlter(false);
  };

  const handleIncreaseAmount = (item) => {
    setAdded((state) =>
      state.map((s) => {
        if (s.product.id === item.product.id) {
          return { ...s, amount: s.amount + 1 };
        }
        return s;
      })
    );
  };

  const handleDecreaseAmount = (item) => {
    setAdded((state) =>
      state
        .map((s) => {
          if (s.product.id === item.product.id) {
            return { ...s, amount: s.amount - 1 };
          }
          return s;
        })
        .filter((s) => s.amount > 0)
    );
  };

  useEffect(() => {
    if (isModalOperationOpened) {
      getBills();
    }
  }, [isModalOperationOpened]);

  return (
    <Content onClick={setIsModalOperationOpened} disabled={disabled}>
      <Modal isOpen={confirmMinimumModal} toggle={toggleMinimumModal}>
        <ModalBody style={{ fontSize: 18 }}>
          Essa alteração deixará o total do pedido <b>abaixo do mínimo</b> do
          delivery. Deseja alterar mesmo assim?
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              title="Não"
              type="negative"
              onClick={() => {
                toggleMinimumModal();
                confirmCancel.pop();
              }}
            />
            <Button
              title="Sim"
              type="default"
              onClick={() => {
                toggleMinimumModal();
                setCanceled((state) => [
                  ...state,
                  confirmCancel[confirmCancel.length - 1],
                ]);
              }}
            />
          </div>
        </ModalBody>
      </Modal>
      <FaPen size={14} /> Editar
      <Modal
        isOpen={isModalOperationOpened}
        toggle={toggleModalOperation}
        size="xl"
      >
        <ModalBody>
          <OperationArea>
            <OrderSystemArea>
              <Menu
                toggleModalOperation={toggleModalOperation}
                setAdded={setAdded}
                isTableDelivery
              />
            </OrderSystemArea>
            <BillTableArea>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h2>Pedido#{item?.basket?.basket_id}</h2>
                <h2>Delivery</h2>
              </div>

              <ProductsCard>
                <CardHeader>
                  <FaPrint /> <span>{item?.buyer?.phone}</span>
                </CardHeader>
                {orderBasket.orders?.map((o) => {
                  const check = confirmCancel.includes(o.id);
                  const checkCancel = canceled.includes(o.id);
                  return (
                    <CardContent>
                      <CardProduct canceled={checkCancel}>
                        <span>
                          {o.amount}x {o.product.name}
                        </span>
                        <span>
                          {format(new Date(orderBasket.start_time), 'HH:MM')}
                        </span>
                        <span>R${o.total_price}</span>
                        <ButtonCancel>
                          {checkCancel ? (
                            <CanceledButton>Cancelado</CanceledButton>
                          ) : check ? (
                            <ConfirmDiv>
                              <ConfirmationButton
                                confirm
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                  console.log('calc > ', calculateNewTotal(o));
                                  if (
                                    calculateNewTotal(o) >=
                                    parseFloat(
                                      user.delivery_info.delivery_minimum_price
                                    )
                                  ) {
                                    setCanceled((state) => [...state, o.id]);
                                  } else {
                                    toggleMinimumModal();
                                  }
                                }}
                              >
                                Sim
                              </ConfirmationButton>
                              <ConfirmationButton
                                onClick={() =>
                                  setConfirmCancel((state) =>
                                    state.filter((s) => s !== o.id)
                                  )
                                }
                              >
                                Não
                              </ConfirmationButton>
                              <span>Quer cancelar?</span>
                            </ConfirmDiv>
                          ) : (
                            <XButton
                              onClick={() =>
                                setConfirmCancel((state) => [...state, o.id])
                              }
                            >
                              <FaTimes size={20} color="#ffffff" />
                            </XButton>
                          )}
                        </ButtonCancel>
                      </CardProduct>
                      {o.complement_categories?.map((cc) => {
                        return cc.order_complements?.map((oc) => {
                          return (
                            <CardComplement>
                              <FaCircle size={7} />
                              <span style={{ flexGrow: 1 }}>
                                {oc.amount}x {oc.complement.name}
                              </span>
                              {oc.price > 0 && <span>R${oc.price}</span>}
                            </CardComplement>
                          );
                        });
                      })}
                    </CardContent>
                  );
                })}
              </ProductsCard>
              {added.length > 0 && (
                <div style={{ marginTop: '10px' }}>
                  <span
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '16px',
                      color: '#4D4D4C',
                    }}
                  >
                    Novos itens
                  </span>
                  {added.map((a) => {
                    return (
                      <div style={{ marginBottom: '20px' }}>
                        <AddedItem>
                          <span>{a?.product.name}</span>
                          <AmountArea>
                            <SvgContainer
                              onClick={() => handleDecreaseAmount(a)}
                            >
                              <FaMinus size={14} />
                            </SvgContainer>
                            <span
                              style={{ minWidth: '12px', textAlign: 'center' }}
                            >
                              {a?.amount}
                            </span>
                            <SvgContainer
                              onClick={() => handleIncreaseAmount(a)}
                            >
                              <FaPlus size={14} />
                            </SvgContainer>
                            <FaTrash
                              size={16}
                              color="#FF2C3A"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setAdded((state) =>
                                  state.filter(
                                    (s) => s.product.id != a?.product.id
                                  )
                                )
                              }
                            />
                          </AmountArea>
                        </AddedItem>
                        {a.complementsCategory.map((cc) =>
                          cc.complements.map((c) => {
                            return (
                              <div style={{ marginLeft: '20px' }}>
                                <FaCircle
                                  size={6}
                                  style={{ marginRight: '5px' }}
                                />
                                {c.amount}x {c.name}
                              </div>
                            );
                          })
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <ButtonsArea>
                <AlterButton onClick={toggleDeleteDelivery}>
                  Cancelar pedido inteiro
                </AlterButton>
                <Button
                  disabled={
                    added.length + canceled.length === 0 || loadingAlter
                  }
                  height={43}
                  isLoading={loadingAlter}
                  title="Alterar pedido"
                  type="default"
                  onClick={alterOrder}
                />
              </ButtonsArea>
            </BillTableArea>
          </OperationArea>
        </ModalBody>
      </Modal>
    </Content>
  );
}

export default TableEditDelivery;
