import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import ImageInput from '~/components/Form/ImageInput';

import { Form } from '@unform/web';

export const Wrapper = styled.div``;

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const  ImageInputStyled = styled(ImageInput)`
  width: 298px;
  height: 90px;
`

export const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    margin-left: 8px;
  }
`;
export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 95%;
  position: fixed;
  bottom: 0px;
  padding-right: 25px;
  padding-left: 10px;
  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;

export const Button = styled.button`
  background-color: #2ec9b7;
  width: 70px;
  height: 48px;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const Times = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  input {
    width: 100px;
    border: 0px;
    border-bottom: 1px solid #33333330;
    border-radius: 0px;
    margin-left: 3px;
    margin-right: 3px;
    transition: all 1s;
    text-align: center;

    :focus {
      border: none;
      border-bottom: 1px solid red;
      border-radius: 0px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #d3d3d3;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #d3d3d3;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #d3d3d3;
    }
  }
`;

export const StyledForm = styled(Form)``;
