import React, { useState, useCallback, useRef } from 'react';

import { Form } from '@unform/web';

import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,

  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Badge,
} from 'reactstrap';

import {
  CategoriesTable,
  Container,
  NavDiv,
  NavDivTeste,
  ColorTag,
  LoadingDiv,
  EmptyDiv,
  NavLinkSpan,
} from './styles';

import Input from '~/components/Form/Input';
import NfcesTable from './NfcesTable';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';

function getStatusName(status) {
  switch (status) {
    case 'autorizado':
      return 'Autorizada';
    case 'canceled':
      return 'Cancelada';
    default:
      return '';
  }
}

export default function Nfces({ nfces, getNfces }) {
  const [activeTab, setActiveTab] = useState('1');
  const autorizados = nfces.filter((nfce) => nfce.status === 'autorizado');
  const cancelados = nfces.filter((nfce) => nfce.status === 'canceled');

  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const [nfce, setNfce] = useState({});

  const htmlRef = useRef(null);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  function toggleDetailModal() {
    setShowDetailModal((state) => !state);
  }

  function toggleEmailModal() {
    setShowEmailModal((state) => !state);
  }

  function toggleCancelModal() {
    setShowCancelModal((state) => !state);
  }

  function onClickDetail(nfce) {
    setNfce(nfce);
    toggleDetailModal();
  }

  function onClickEmail(nfce) {
    setNfce(nfce);
    toggleEmailModal();
  }

  function onClickCancel(nfce) {
    setNfce(nfce);
    toggleCancelModal();
  }

  function onClickPrint(nfce) {
    const pageParams = {
      width: 795,
      height: 590,
      top: 100,
      left: 699,
      scrollbars: 'no',
      status: 'no',
      toolbar: 'no',
      location: 'no',
      menubar: 'no',
      resizable: 'no',
      fullscreen: 'no',
    };

    const paramsStr = Object
      .entries(pageParams)
      .map(([key, value]) => `${key}=${value}`)
      .join(', ');

    window.open(
      `${nfce.nfce_html}`,
      'janela',
      paramsStr
    );
  }

  const sendEmail = useCallback(
    async (data) => {
      setLoadingEmail(true);

      const url = nfce.type === 'nfce' 
        ? 'restaurants/email-nfce'
        : 'restaurants/email-nfe';

      try {
        await api.post(url, {
          ref: nfce.ref,
          email: data.email,
        });

        toast.success('E-mail enviado com sucesso!');
      } catch (err) {
        toast.error('Erro ao enviar E-mail.');
      }

      setLoadingEmail(false);
      setShowEmailModal(false);
    },
    [nfce]
  );

  const cancelNfce = useCallback(async () => {
    setLoading(true);

    const url = nfce.type === 'nfce' 
      ? 'restaurants/delete-nfce'
      : 'restaurants/delete-nfe';

    try {
      const response = await api.post(url, {
        ref: nfce.ref,
      });

      if (response.data.status_sefaz === '135') {
        toast.success('NFCe deletada com sucesso!');
      }

      getNfces();
    } catch (err) {
      toast.error('Erro ao deletar Nota Fiscal. Tente novamente.');
    }

    setLoading(false);
    setShowCancelModal(false);
  }, [nfce]);

  return (
    <Container>
      <NavDiv>
        <NavDivTeste>
          <Nav tabs red borderless>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => setActiveTab('1')}
              >
                <ColorTag color="green" />
                <NavLinkSpan>Autorizadas</NavLinkSpan>{' '}
                <Badge>{autorizados.length}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : ''}
                onClick={() => setActiveTab('2')}
              >
                <ColorTag color="#dc3545" />
                <NavLinkSpan>Canceladas</NavLinkSpan>{' '}
                <Badge>{cancelados.length}</Badge>
              </NavLink>
            </NavItem>
          </Nav>
        </NavDivTeste>
      </NavDiv>

      <TabContent activeTab={activeTab}>
        <TabPane
          tabId="1"
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 20px',
            background: '#baf0fb',
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            borderRadius: '8px',
            padding: '12px 10px',
            minWidth: '750px',
          }}>

          <NfcesTable
            nfces={autorizados}
            onClickDetail={onClickDetail}
            onClickPrint={onClickPrint}
            onClickEmail={onClickEmail}
            onClickCancel={onClickCancel}
          />
        </TabPane>

        <TabPane
          tabId="2"
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 20px',
            background: '#baf0fb',
            filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
            borderRadius: '8px',
            padding: '12px 10px',
            minWidth: '750px',
          }}>

          <NfcesTable
            nfces={cancelados}
            onClickDetail={onClickDetail}
            showCancelDate={true}
          />
        </TabPane>
      </TabContent>

      {/* Enviar por email */}
      <Modal isOpen={showEmailModal} toggle={toggleEmailModal}>
        <Form onSubmit={sendEmail}>
          <ModalBody>
            <h6>Enviar Nota Fiscal por E-mail</h6>
            <Input
              label="E-mail"
              name="email"
              type="email"
              placeholder="exemplo@gmail.com"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              style={{ background: 'green' }}
              disabled={loadingEmail}
            >
              {loadingEmail ? (
                <Spinner
                  color="light"
                  style={{ width: '1em', height: '1em' }}
                />
              ) : (
                'Enviar'
              )}
            </Button>

            <Button
              style={{ background: '#dc3545' }}
              onClick={toggleEmailModal}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      
      {/* Detalhes */}
      <Modal isOpen={showDetailModal} toggle={toggleDetailModal}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Referência:</strong> #{nfce.ref}
            </Col>
            <Col md="6">
              <strong>Status:</strong> {getStatusName(nfce.status)}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Criada em:</strong> {formatCompleteDate(nfce.created_at || new Date())}
            </Col>
            <Col md="6">
              <strong>Cancelada em:</strong>{' '}
              {nfce.deleted_at !== null
                ? formatCompleteDate(nfce.deleted_at || new Date())
                : '-'}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Série:</strong> {nfce.serie}
            </Col>
            <Col md="6">
              <strong>Número:</strong> {nfce.numero}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col md="6">
              <strong>Valor:</strong> R${nfce.total_price}
            </Col>
            <Col md="6">
              <strong>Tipo:</strong> {nfce.type === 'nfce' ? 'NFCe' : 'NFe'}
            </Col>
          </Row>
          <Row style={{ paddingTop: 15 }}>
            <Col>
              <strong>Chave da {nfce.type === 'nfce' ? 'NFCe' : 'NFe'}:</strong>{' '}
              {nfce.chave_nfce}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* Cancelar notas */}
      <Modal isOpen={showCancelModal} toggle={toggleCancelModal}>
        <ModalBody>
          <h4 style={{ color: 'red' }}>
            Tem certeza que deseja cancelar essa Nota Fiscal?
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={cancelNfce}
            style={{ background: '#dc3545' }}
            disabled={loading}>
            {loading ? (
              <Spinner
                color="light"
                style={{ width: '1em', height: '1em' }}
              />
            ) : (
              'Sim, cancelar'
            )}
          </Button>
          <Button
            onClick={toggleCancelModal}
            style={{ background: 'green' }}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
