/* eslint-disable react/prop-types */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { CellMeasurerCache } from 'react-virtualized';

import {
  Card,
  Modal,
  ModalBody,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from 'reactstrap';
import { FaAngleDown, FaRedo, FaSearch } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import Switch from 'react-switch';

import ReactModal from 'react-modal';
import api from '~/services/api';

import ProductCategoryHeader from '~/components/Products/ProductCategoryHeader';
import CategoryItem from '~/components/Products/CategoryItem';

import EditCategory from './Edit';

import {
  Container,
  LoadingContainer,
  InputSearchArea,
  SelectStyled,
  ButtonIfood,
  SetSliceSizeButton,
  CategoryName,
  ButtonAssociate,
  ContainerComplementsCategories,
  CategoryHeader,
} from './styles';
import RegisterButton from '~/components/Buttons/RegisterButton';
import Store from './Store';
import { useIfood } from '~/context/iFoodContext';
import CreateComplementCategory from '../ComplementsCategories/Create';
import ComplementCategoryDropdown from '../ComplementsCategories/ComplementCategoryDropdown';
import CreateComplement from '../Complements/Create';
import AssociateComplement from '../ComplementsCategories/AssociateComplement';

import Complements from '../Complements';

ReactModal.setAppElement('#root');

function ProductCategory({
  menewProducts,
  getMenewProducts,
  user,
  fiscalGroups,
}) {
  const { getIfoodMenus } = useIfood();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');

  const [modalEditCategory, setModalEditCategory] = useState(false);

  const [modalEditCategoryData, setModalEditCategoryData] = useState();

  const cache = new CellMeasurerCache({
    fixedHeight: false,
    defaultWidth: 800,
    fixedWidth: true,
  });

  const categorySelect = categories.map((cat) => {
    return {
      label: cat.name,
      value: cat.name,
    };
  });

  function toggleModalEditCategory() {
    setModalEditCategory(!modalEditCategory);
  }

  function handleChangeModalEditCategory(category) {
    setModalEditCategoryData(category);
    setModalEditCategory(!modalEditCategory);
  }

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get('/restaurants/products');

    setCategories(response.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  function handleSelectSearch(name) {
    setSelectedValue({ value: name, label: name });
    const categoriesFind = categories.filter((cat) => {
      if (
        cat.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .startsWith(
            name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      ) {
        return { ...cat, products: cat.products };
      }
      return '';
    });

    setCategoriesFiltered(() => {
      return categoriesFind;
    });

    setShowFiX(true);
  }

  async function handleSearchInputProduct(name) {
    if (name.length >= 3) {
      try {
        const productFind = categories.map((cat) =>
          cat.products.map((prod) => {
            if (
              prod.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  name
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            ) {
              return { ...cat, products: [prod] };
            }
            return '';
          })
        );

        const arrayReady = productFind
          .map((item) => item.filter((cat) => cat !== ''))
          .filter((arr) => arr.length > 0);

        const newArray = arrayReady.flatMap((item) => item);

        //
        const output = newArray.reduce(function (o, cur) {
          // Get the index of the key-value pair.
          const occurs = o.reduce(function (n, item, i) {
            return item.name === cur.name ? i : n;
          }, -1);

          // If the name is found,
          if (occurs >= 0) {
            // append the current value to its list of values.
            o[occurs].products = o[occurs].products.concat(cur.products);

            // Otherwise,
          } else {
            // add the current item to o (but make sure the value is an array).
            const obj = {
              name: cur.name,
              ...cur,
            };
            o = o.concat([obj]);
          }

          return o;
        }, []);

        setCategoriesFiltered(output);
      } catch (error) {
        toast.error('errro ao filtrar');
      }
    }

    if (name.length === 0) {
      setCategoriesFiltered({ products: [] });
    }
  }

  async function handleDeleteCategory(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar esta Categoria?')) {
        await api.delete(`/restaurants/product-category/${id}`);

        const categoriesFiltered = categories.filter(
          (category) => category.id !== id
        );

        setCategories(categoriesFiltered);
        localStorage.setItem('@gddashboard:isMenuUpdated', true);

        toast.success('Categoria deletada com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar categoria');
    }
  }

  const [modalCreateCategory, setModalCreateCatgory] = useState(false);
  function toggleModalCreateCategory() {
    setModalCreateCatgory(!modalCreateCategory);
  }
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const [activeTab, setActiveTab] = useState('1');

  const [
    modalNewComplementCategory,
    setModalNewComplementCategory,
  ] = useState();
  function toggleNewComplementCategory() {
    setModalNewComplementCategory(!modalNewComplementCategory);
  }

  const [sliceSize, setSliceSize] = useState(4);
  const [angleChanged, setAngleChange] = useState(false);

  function handleSetSliceSize() {
    setSliceSize(sliceSize + 5);
    setAngleChange(true);
  }

  const [showFiX, setShowFiX] = useState(false);

  // Complement category
  const [complementsCategories, setComplementsCategories] = useState([]);
  const [count, setCount] = useState('');
  const [
    complementsCategoriesFiltered,
    setComplementsCategoriesFiltered,
  ] = useState([]);
  const complementCategorySelect = complementsCategories.map((cat) => {
    return {
      label: cat.name,
      value: cat.name,
    };
  });

  const [
    selectedCategoryComplementValue,
    setSelectedCategroyComplementValue,
  ] = useState('');
  const [modal, setModal] = useState(false);
  const [modalAssociateCategory, setModalAssociateCategory] = useState();
  const [modalCreateComplements, setModalCreateComplements] = useState();

  const [modalNewComplement, setModalNewComplement] = useState();
  const selectComplementRef = useRef(null);
  function toggle() {
    setModal(!modal);
  }

  function toggleNewComplement() {
    setModalNewComplement(!modalNewComplement);
  }

  function AssociateComplements(category) {
    setModalAssociateCategory(category);
    toggle();
  }

  function createComplements(category) {
    setModalCreateComplements(category);
    toggleNewComplement();
  }

  const getComplementsCategories = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/product/complement-category'
      );

      setComplementsCategories(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  async function handleChangeComplementCategory(value, event, idx) {
    try {
      const id = Number(idx);
      const response = await api.put(
        `/restaurants/product/complement-category/${id}`,
        {
          available: value,
        }
      );

      const newCategories = [...complementsCategories];

      newCategories.map((cat, idix) => {
        if (cat.id === id) {
          newCategories[idix].available = response.data.available;
        }
      });

      setComplementsCategories(newCategories);

      toast.success('Categoria alterado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
    } catch (error) {
      toast.error('Erro ao ativar/ desativar categoria');
    }
  }

  function handleSelectSearchComplementCategory(name) {
    setSelectedCategroyComplementValue({ value: name, label: name });
    const categoriesFind = complementsCategories.filter((cat) => {
      if (
        cat.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .startsWith(
            name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      ) {
        return { ...cat, products: cat.products };
      }
      return '';
    });

    setComplementsCategoriesFiltered(() => {
      return categoriesFind;
    });

    setShowComplementFiX(true);
  }

  async function handleSearchInputComplement(name) {
    if (name.length >= 3) {
      try {
        const productFind = complementsCategories.map((cat) =>
          cat.complements.map((comp) => {
            if (
              comp.name
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                  name
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                )
            ) {
              return { ...cat, complements: [comp] };
            }
            return '';
          })
        );

        const arrayReady = productFind
          .map((item) => item.filter((cat) => cat !== ''))
          .filter((arr) => arr.length > 0);

        const newArray = arrayReady.flatMap((item) => item);

        //
        const output = newArray.reduce(function (o, cur) {
          // Get the index of the key-value pair.
          const occurs = o.reduce(function (n, item, i) {
            return item.name === cur.name ? i : n;
          }, -1);

          // If the name is found,
          if (occurs >= 0) {
            // append the current value to its list of values.
            o[occurs].complements = o[occurs].complements.concat(
              cur.complements
            );

            // Otherwise,
          } else {
            // add the current item to o (but make sure the value is an array).
            const obj = {
              name: cur.name,
              ...cur,
            };
            o = o.concat([obj]);
          }

          return o;
        }, []);

        setComplementsCategoriesFiltered(output);
      } catch (error) {
        console.log(error.message);
      }
    }

    if (name.length === 0) {
      setComplementsCategoriesFiltered({ complements: [] });
    }
  }

  const [showComplementFiX, setShowComplementFiX] = useState(false);
  const inputComplementRef = useRef(null);
  useEffect(() => {
    try {
      getComplementsCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getComplementsCategories]);

  const [collapse, setCollapse] = useState([]);

  const handleCollapse = (id) => {
    if (collapse.includes(id)) {
      const newCollapse = collapse.filter((c) => c !== id);
      setCollapse(newCollapse);
    } else {
      setCollapse((state) => [...state, id]);
    }
  };

  const [
    sliceSizeComplementsCategoires,
    setSliceSizeComplementsCategoires,
  ] = useState(5);
  const [
    angleChangedgetComplementsCategories,
    setAngleChangegetComplementsCategories,
  ] = useState(false);

  function handleSetSliceSizegetComplementsCategories() {
    setSliceSizeComplementsCategoires(sliceSizeComplementsCategoires + 5);
    setAngleChangegetComplementsCategories(true);
  }

  return isLoading ? (
    <LoadingContainer>
      <Spinner />
      <div>Carregando Cardápio...</div>
    </LoadingContainer>
  ) : (
    <Container>
      <ReactModal
        onRequestClose={toggleModalCreateCategory}
        isOpen={modalCreateCategory}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <Store
          toggle={toggleModalCreateCategory}
          getCategories={getCategories}
        />
      </ReactModal>
      <ReactModal
        isOpen={modalEditCategory}
        onRequestClose={toggleModalEditCategory}
        className="modal-content-takeat"
        closeTimeoutMS={500}
      >
        <EditCategory
          category={modalEditCategoryData}
          toggle={toggleModalEditCategory}
          getCategories={getCategories}
          fiscalGroups={fiscalGroups}
        />
      </ReactModal>

      <ReactModal
        isOpen={modalNewComplementCategory}
        onRequestClose={toggleNewComplementCategory}
        className="modal-content-takeat-without-overflow"
        closeTimeoutMS={500}
      >
        <CreateComplementCategory
          setComplementsCategories={setComplementsCategories}
          toggle={toggleNewComplementCategory}
          complementCategories={complementsCategories}
        />
      </ReactModal>
      <ProductCategoryHeader
        getCategories={getCategories}
        user={user}
        menewProducts={menewProducts}
        fiscalGroups={fiscalGroups}
        activeTab={activeTab}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '95%',
          marginLeft: '30px',
        }}
      >
        {activeTab === '1' ? (
          <InputSearchArea>
            <div style={{ position: 'relative' }}>
              <SelectStyled
                placeholder="Todas as categorias"
                onChange={(e) => handleSelectSearch(e.value)}
                options={categorySelect}
                ref={selectRef}
                value={selectedValue}
              />
              <span
                style={{
                  position: 'absolute',
                  right: 40,
                  display: showFiX ? 'block' : 'none',
                }}
              >
                {' '}
                <FiX
                  color="#333"
                  title="Limpar seleção"
                  cursor="pointer"
                  onClick={(e) => {
                    setCategoriesFiltered([]);
                    inputRef.current.value = '';
                    setSelectedValue('');
                    setShowFiX(false);
                  }}
                />
              </span>
            </div>

            <span>
              <FaSearch color="FF2C3A" />
            </span>

            <input
              placeholder="Buscar produto"
              onChange={(e) => handleSearchInputProduct(e.target.value)}
              style={{ marginLeft: 10, height: 38, paddingLeft: 38 }}
              ref={inputRef}
            />
            <button
              type="button"
              onClick={(e) => {
                setCategoriesFiltered([]);
                inputRef.current.value = '';
                setSelectedValue('');
              }}
              title="Mostrar todas"
            >
              <FiX color="#f7c3ca" />
            </button>
          </InputSearchArea>
        ) : (
          <InputSearchArea>
            <div style={{ position: 'relative' }}>
              <SelectStyled
                placeholder="Todas as cat. de complementos"
                onChange={(e) => handleSelectSearchComplementCategory(e.value)}
                options={complementCategorySelect}
                ref={selectComplementRef}
                value={selectedCategoryComplementValue}
              />

              <span
                style={{
                  position: 'absolute',
                  right: 40,
                  display: showComplementFiX ? 'block' : 'none',
                }}
              >
                {' '}
                <FiX
                  color="#333"
                  title="Limpar seleção"
                  cursor="pointer"
                  onClick={(e) => {
                    setComplementsCategoriesFiltered([]);
                    inputComplementRef.current.value = '';
                    setSelectedCategroyComplementValue('');
                    setShowComplementFiX(false);
                  }}
                />
              </span>
            </div>
            <span>
              <FaSearch color="FF2C3A" />
            </span>
            <input
              placeholder="Buscar complemento"
              onChange={(e) => handleSearchInputComplement(e.target.value)}
              style={{ marginLeft: 10, height: 38, paddingLeft: 38 }}
              ref={inputComplementRef}
            />
            <button
              type="button"
              onClick={(e) => {
                setComplementsCategoriesFiltered([]);
                inputComplementRef.current.value = '';
                setSelectedCategroyComplementValue('');
              }}
              title="Mostrar todas"
            >
              <FiX color="#f7c3ca" />
            </button>
          </InputSearchArea>
        )}

        <div>
          {activeTab === '1' ? (
            <RegisterButton
              text="Adicionar categoria"
              onClick={toggleModalCreateCategory}
              style={{ marginTop: 0 }}
            />
          ) : (
            <RegisterButton
              text="Adicionar grupo de Complementos"
              onClick={toggleNewComplementCategory}
              style={{ marginTop: 0 }}
            />
          )}

          {user?.has_ifood && (
            <ButtonIfood
              type="button"
              onClick={() => {
                getIfoodMenus();
              }}
            >
              <FaRedo color="#ff2c3a" />
              Produtos Ifood{' '}
            </ButtonIfood>
          )}

          {user?.has_menew && (
            <ButtonIfood
              message="Atualizar Produtos Menew"
              onClick={() => getMenewProducts()}
            >
              {' '}
              <FaRedo color="#ff2c3a" />
              Produtos Menew{' '}
            </ButtonIfood>
          )}
        </div>
      </div>

      <Nav tabs style={{ marginLeft: '18px' }}>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => {
              setActiveTab('1');
              setSliceSize(5);
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Produtos
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => {
              setActiveTab('2');
              setSliceSizeComplementsCategoires(5);
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Complementos
          </NavLink>
        </NavItem>
      </Nav>

      <Card
        style={{
          width: '95%',
          marginLeft: '30px',
          paddingBottom: '20px',
          marginTop: -10,
        }}
      >
        {activeTab === '1' && (
          <>
            {categoriesFiltered.length > 0
              ? categoriesFiltered.map((cat, idx) => (
                  <CategoryItem
                    category={cat}
                    getCategories={getCategories}
                    user={user}
                    handleChangeModalEditCategory={
                      handleChangeModalEditCategory
                    }
                    handleDeleteCategory={handleDeleteCategory}
                    menewProducts={menewProducts}
                    categories={categories}
                    cache={cache}
                    setCategoriesFiltered={setCategoriesFiltered}
                    setCategories={setCategories}
                    idx={idx}
                    setComplementsCategories={setComplementsCategories}
                    complementCategories={complementsCategories}
                    fiscalGroups={fiscalGroups}
                  />
                ))
              : categories
                  .slice(0, sliceSize)
                  .map((cat, idx) => (
                    <CategoryItem
                      category={cat}
                      getCategories={getCategories}
                      user={user}
                      handleChangeModalEditCategory={
                        handleChangeModalEditCategory
                      }
                      handleDeleteCategory={handleDeleteCategory}
                      menewProducts={menewProducts}
                      categories={categories}
                      cache={cache}
                      setCategoriesFiltered={setCategoriesFiltered}
                      setCategories={setCategories}
                      idx={idx}
                      setComplementsCategories={setComplementsCategories}
                      complementCategories={complementsCategories}
                      fiscalGroups={fiscalGroups}
                    />
                  ))}
          </>
        )}

        {activeTab === '2' && (
          // <ComplementsCategories
          //   setProductsCategories={setCategories}
          //   productsCategories={categories}
          // /
          <>
            {/* <Modal isOpen={modal} toggle={toggle} size="lg">
              <ModalBody>
                <AssociateComplement
                  category={modalAssociateCategory}
                  toggle={toggle}
                  getCategories={getCategories}
                  setCount={setCount}
                  productsCategories={categories}
                  setProductsCategories={setCategories}
                  categories={categories}
                  setComplementsCategories={setComplementsCategories}
                  complementsCategories={complementsCategories}
                />
              </ModalBody>
            </Modal> */}

            <ReactModal
              onRequestClose={toggle}
              isOpen={modal}
              className="modal-content-takeat"
              closeTimeoutMS={500}
            >
              <AssociateComplement
                category={modalAssociateCategory}
                toggle={toggle}
                getCategories={getCategories}
                setCount={setCount}
                productsCategories={categories}
                setProductsCategories={setCategories}
                categories={categories}
                setComplementsCategories={setComplementsCategories}
                complementsCategories={complementsCategories}
              />
     
            </ReactModal>
          
            <ReactModal
              onRequestClose={toggleNewComplement}
              isOpen={modalNewComplement}
              className="modal-content-takeat-without-overflow"
              closeTimeoutMS={500}
            >
                    
              <CreateComplement
                category={modalCreateComplements}
                getCategories={getCategories}
                toggle={toggleNewComplement}
                setCount={setCount}
                setComplementsCategories={setComplementsCategories}
                complementsCategories={complementsCategories}
                fiscalGroups={fiscalGroups}
              />
     
            </ReactModal>

            {complementsCategoriesFiltered &&
            complementsCategoriesFiltered.length > 0
              ? complementsCategoriesFiltered.map((category, index) => (
                  <ContainerComplementsCategories>
                    <CategoryHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch
                          id={`${category.id}`}
                          onChange={handleChangeComplementCategory}
                          draggable
                          checked={category.available}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          height={27}
                          width={44}
                          handleDiameter={16}
                          activeBoxShadow="0 0 2px 3px #111"
                          boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                          onColor="#0CCAB4"
                          offColor="#666666"
                          disabled={category.is_ifood}
                        />
                        <CategoryName>{category.name}</CategoryName>
                      </div>

                      <div>
                        <FaAngleDown
                          size={20}
                          title="Mostrar Produtos"
                          onClick={() => {
                            handleCollapse(category.id);
                          }}
                          style={{
                            transition: 'all 0.2s',
                            transform: collapse.includes(category.id)
                              ? 'rotate(-90deg)'
                              : 'rotate(0)',
                            marginLeft: 22,
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 30,
                          }}
                        >
                          <ComplementCategoryDropdown
                            category={category}
                            getCategories={getComplementsCategories}
                            productsCategories={categories}
                            setProductsCategories={setCategories}
                            categories={complementsCategories}
                            setCategories={setComplementsCategories}
                            index={index}
                            setComplementsCategoriesFiltered={setComplementsCategoriesFiltered}
                            fiscalGroups={fiscalGroups}
                          />
                        </div>
                      </div>
                    </CategoryHeader>

                    <div style={{ padding: 20 }}>
                      <RegisterButton
                        text="Adicionar complemento"
                        title="Cria complementos dentro desta categoria"
                        onClick={() => {
                          createComplements(category);
                        }}
                      />

                      {!category.is_multistore_child && (
                        <ButtonAssociate
                          title="Associar complementos já existentes"
                          onClick={() => {
                            AssociateComplements(category);
                          }}
                        >
                          Associar complementos já existentes
                        </ButtonAssociate>
                      )}
                    </div>
                    <Collapse isOpen={!collapse.includes(category.id)}>
                      <Complements
                        category={category}
                        count={count}
                        setCount={setCount}
                        getCategories={getComplementsCategories}
                        menewProducts={menewProducts}
                        allCategories={complementsCategories}
                        setProductsCategories={setCategories}
                        productsCategories={categories}
                        setCategories={setComplementsCategories}
                        fiscalGroups={fiscalGroups}
                      />
                    </Collapse>
                  </ContainerComplementsCategories>
                ))
              : complementsCategories
                  .slice(0, sliceSizeComplementsCategoires)
                  .map((category, index) => (
                    <ContainerComplementsCategories>
                      <CategoryHeader>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            id={`${category.id}`}
                            onChange={handleChangeComplementCategory}
                            draggable
                            checked={category.available}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={27}
                            width={44}
                            handleDiameter={16}
                            activeBoxShadow="0 0 2px 3px #111"
                            boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                            onColor="#0CCAB4"
                            offColor="#666666"
                            disabled={category.is_ifood}
                          />
                          <CategoryName>{category.name}</CategoryName>
                        </div>

                        <div>
                          <FaAngleDown
                            size={20}
                            title="Mostrar Produtos"
                            onClick={() => {
                              handleCollapse(category.id);
                            }}
                            style={{
                              transition: 'all 0.2s',
                              transform: collapse.includes(category.id)
                                ? 'rotate(-90deg)'
                                : 'rotate(0)',
                              marginLeft: 22,
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: 30,
                            }}
                          >
                            <ComplementCategoryDropdown
                              category={category}
                              getCategories={getComplementsCategories}
                              productsCategories={categories}
                              setProductsCategories={setCategories}
                              categories={complementsCategories}
                              setCategories={setComplementsCategories}
                              index={index}
                              fiscalGroups={fiscalGroups}
                            />
                          </div>
                        </div>
                      </CategoryHeader>

                      <div style={{ padding: 20 }}>
                        <RegisterButton
                          text="Adicionar complemento"
                          title="Cria complementos dentro desta categoria"
                          onClick={() => {
                            createComplements(category);
                          }}
                        />

                        {!category.is_multistore_child && (
                          <ButtonAssociate
                            title="Associar complementos já existentes"
                            onClick={() => {
                              AssociateComplements(category);
                            }}
                          >
                            Associar complementos já existentes
                          </ButtonAssociate>
                        )}
                      </div>
                      <Collapse isOpen={!collapse.includes(category.id)}>
                        <Complements
                          category={category}
                          count={count}
                          setCount={setCount}
                          getCategories={getComplementsCategories}
                          menewProducts={menewProducts}
                          allCategories={complementsCategories}
                          setProductsCategories={setCategories}
                          productsCategories={categories}
                          setCategories={setComplementsCategories}
                          fiscalGroups={fiscalGroups}
                        />
                      </Collapse>
                    </ContainerComplementsCategories>
                  ))}
            {sliceSizeComplementsCategoires < complementsCategories.length && (
              <div>
                {' '}
                <SetSliceSizeButton
                  type="button"
                  onClick={handleSetSliceSizegetComplementsCategories}
                >
                  <FaAngleDown
                    style={{
                      transition: 'all 0.2s',
                      transform: !angleChangedgetComplementsCategories
                        ? 'rotate(-90deg)'
                        : 'rotate(0)',
                    }}
                  />{' '}
                  Ver mais categorias
                </SetSliceSizeButton>
              </div>
            )}
          </>
        )}

        {categoriesFiltered &&
          categoriesFiltered.length < 1 &&
          sliceSize < categories.length &&
          activeTab === '1' && (
            <div>
              {' '}
              <SetSliceSizeButton type="button" onClick={handleSetSliceSize}>
                <FaAngleDown
                  style={{
                    transition: 'all 0.2s',
                    transform: !angleChanged ? 'rotate(-90deg)' : 'rotate(0)',
                  }}
                />{' '}
                Ver mais categorias
              </SetSliceSizeButton>
            </div>
          )}
      </Card>

      {/* {categories &&
        categories.length > 0 &&
        categories.map((category, index) => (
          <CategoryItem
            key={category.id}
            category={category}
            getCategories={getCategories}
            user={user}
            handleChangeModalEditCategory={handleChangeModalEditCategory}
            handleChangeDeliveryCategory={handleChangeDeliveryCategory}
            handleChangeExclusiveCategory={handleChangeExclusiveCategory}
            handleChangeCategory={handleChangeCategory}
            handleDeleteCategory={handleDeleteCategory}
            index={index}
            menewProducts={menewProducts}
            categories={categories}
          />
        ))} */}
    </Container>
  );
}

export default ProductCategory;
