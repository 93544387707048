import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

export const Container = styled.div`
  width: 90%;
  margin-top: 14px;
`;

export const Wrapper = styled.div`
  margin: 10px;
  width: 100%;
`;

export const Header = styled.div`
  width: 90%;
`;

export const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
