import React, { useEffect, useState } from 'react';
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { MdUpdate } from 'react-icons/md';

import { FiCopy } from 'react-icons/fi';
import { Wrapper, Header, LugarPedidos, StyledLink } from './styles';
import { useOrder } from '~/context/orderContext';

import CardOrdersAndamento from '~/components/Orders/Cards/CardOrdersAndamento';
import CardOrdersNew from '~/components/Orders/Cards/CardOrdersNew';
import CardOrdersCanceled from '~/components/Orders/Cards/CardOrdersCanceled';
import CardOrdersReady from '~/components/Orders/Cards/CardOrdersReady';
import CardOrdersFinishedDelivery from '~/components/Orders/Cards/CardOrderFinishedDelivery';
import TableDeliveryButton from '~/components/Operation/TablesOperation/TableDeliveryButton';
import { useMotoboys } from '~/context/MotoboysContext';
import { useAuth } from '~/context/AuthContext';
import CardOrdersSchedulingNew from '~/components/Orders/Cards/CardOrdersSchedulingNew';
import CardOrdersSchedulingReady from '~/components/Orders/Cards/CardOrdersSchedulingReady';
import CardOrdersSchedulingAndamento from '~/components/Orders/Cards/CardOrdersSchedulingAndamento';
import { VideoPlayer } from '~/components/VideoPlayer';

function OrdersPage({
  handleItemFinished,
  handleCancelItem,
  handleItemReady,
  handlePrintItem,
  setItemIfoodId,
}) {
  const [activeTab, setActiveTab] = useState('1');
  const {
    pendingBaskets,
    acceptedBaskets,
    finishedBaskets,
    canceledBaskets,
    readyBaskets,
    loading,
    updateTime,
    getAllOrders,
  } = useOrder();

  const { user } = useAuth();

  const { getMotoboys } = useMotoboys();

  async function handleUpdate() {
    getAllOrders();
  }

  useEffect(() => {
    if (user.delivery_info.is_delivery_allowed) {
      getMotoboys();
    }
  }, [getMotoboys]);

  return (
    <Wrapper>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ marginTop: 25 }}>
              <strong>Acompanhar Pedidos</strong>
            </h2>
            <VideoPlayer id={67} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              type="button"
              onClick={handleUpdate}
              style={{
                padding: '7px 14px',
                background: '#fe1933',
                color: '#fff',
                marginRight: 8,
                border: 'none',
                borderRadius: 8,
              }}
              title="Atualizar"
            >
              <MdUpdate size={21} />
            </button>
            <span>Última Atualização {updateTime}</span>
          </div>
          <p style={{ textAlign: 'left', marginTop: '10px' }}>
            {`Link de acesso ao aplicativo de delivery do cliente: `}
            <a
              href={`https://pedido.takeat.app/${
                user?.nickname ? user?.nickname : user?.name
              }`}
              style={{
                color: '#0a6edf',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
              target="blank"
            >
              {`https://pedido.takeat.app/${
                user?.nickname ? user?.nickname : user?.name
              }`}
            </a>
            <FiCopy
              color="FF2C3A"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://pedido.takeat.app/${
                    user?.nickname ? user?.nickname : user?.name
                  }`
                );
              }}
              size={18}
              style={{ cursor: 'pointer', marginLeft: 8 }}
              title="Copiar link"
            />
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'flex-end',
          }}
        >
          <TableDeliveryButton />
          {activeTab === '2' && (
            <StyledLink to="/delivery/scheduling">
              Configurar Agendamento
            </StyledLink>
          )}
        </div>
      </Header>
      <Nav tabs>
        <NavItem style={{ marginLeft: -11 }}>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => setActiveTab('1')}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Pedidos Novos e Em Andamento
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => setActiveTab('2')}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            <span style={{ marginRight: 8 }}>Pedidos Agendados</span>
            <Badge bg="secondary">
              {pendingBaskets.filter((order) => order.scheduled_to).length}
            </Badge>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => {
              setActiveTab('3');
              getAllOrders();
            }}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Pedidos Entregues
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={activeTab === '4' ? 'active' : ''}
            onClick={() => setActiveTab('4')}
            style={{ cursor: 'pointer', color: '#333' }}
          >
            Pedidos Cancelados
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LugarPedidos>
            <CardOrdersNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBaskets}
              loading={loading}
              setItemIfoodId={setItemIfoodId}
            />
            <CardOrdersAndamento
              baskets={acceptedBaskets}
              handleItemFinished={handleItemReady}
              handleCancelItem={handleCancelItem}
              loading={loading}
              handleItemReady={handleItemReady}
              handleUpdate={handleUpdate}
            />
            <CardOrdersReady
              baskets={readyBaskets}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
              getAllOrders={getAllOrders}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="2">
          <LugarPedidos>
            <CardOrdersSchedulingNew
              handlePrintItem={handlePrintItem}
              handleCancelItem={handleCancelItem}
              baskets={pendingBaskets}
              loading={loading}
              setItemIfoodId={setItemIfoodId}
            />
            <CardOrdersSchedulingAndamento
              baskets={acceptedBaskets}
              handleItemFinished={handleItemReady}
              handleCancelItem={handleCancelItem}
              loading={loading}
              handleItemReady={handleItemReady}
              handleUpdate={handleUpdate}
            />
            <CardOrdersSchedulingReady
              baskets={readyBaskets}
              handleItemFinished={handleItemFinished}
              handleCancelItem={handleCancelItem}
              loading={loading}
              getAllOrders={getAllOrders}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="3">
          <LugarPedidos>
            <CardOrdersFinishedDelivery
              baskets={finishedBaskets}
              loading={loading}
              handleCancelItem={handleCancelItem}
              getAllOrders={getAllOrders}
            />
          </LugarPedidos>
        </TabPane>

        <TabPane tabId="4">
          <LugarPedidos>
            <CardOrdersCanceled baskets={canceledBaskets} loading={loading} />
          </LugarPedidos>
        </TabPane>
      </TabContent>
    </Wrapper>
  );
}

export default OrdersPage;
