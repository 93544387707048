import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { MdDelete } from 'react-icons/md';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import {
  Container,
  Header,
  ErrorContainer,
  Delete,
  InputsTable,
  DatePickerStyled,
  DataPickerArea,
  CategoriesTable,
} from './styles';
import { MdDateRange } from 'react-icons/md';

import Input from '~/components/Form/Input';
import Select from '~/components/Form/SelectInput';
import RegisterButton from '~/components/Buttons/RegisterButton';
import api from '~/services/api';
import TextareaContainer from '~/components/Form/Textarea';
import { useAuth } from '~/context/AuthContext';
import StockTransferRow from '~/components/Rows/StockTransferRow';

function TransferInputs() {
  const { user } = useAuth();
  const [inputs, setInputs] = useState([]);
  const [intermediary, setIntermediary] = useState([]);
  const [permission, setPermission] = useState();

  const formRef = useRef(null);

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/users/role-permission/FiscalInputTransfer'
      );

      const { can_read } = response.data.FiscalInputTransfer;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/inputs');
      setInputs(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  const getIntermediary = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/intermediary');
      setIntermediary(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados intermediarios');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    if (permission) {
      getInputs();
      getIntermediary();
    }
  }, [getInputs, getIntermediary, permission]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const inputs_select = inputs.map((input) => ({
    value: input.id,
    label: input.name,
    unidade: input.unidade,
    unitary_price: input.unitary_price,
    quantidade: input.quantidade,
  }));

  const inter_select = intermediary.map((inter) => ({
    value: inter.id,
    label: inter.name,
    unidade: inter.unidade,
    unitary_price: inter.unitary_price,
    quantidade: inter.quantidade,
  }));

  const [interInputs, setInterInputs] = useState([]);
  const [defaultInterValue, setDefaultInterValue] = useState(0);
  const [defaultInputUnitary, setDefaultInputUnitary] = useState(0);
  const [defaultInterUnitary, setDefaultInterUnitary] = useState(0);

  const [selectInput, setSelectInput] = useState([]);
  const [interInter, setInterInter] = useState([]);
  const [defaultInterInterValue, setDefaultInterInterValue] = useState(0);
  const [selectInter, setSelectInter] = useState([]);

  function insertInputInter(
    selectInput,
    defaultInterValue,
    defaultInputUnitary
  ) {
    const exists = interInputs.filter((int) => int.id === selectInput.value);

    if (exists.length > 0) {
      toast.error('Insumo já cadastrado');
    } else {
      setInterInputs([
        ...interInputs,
        {
          name: selectInput.label,
          id: selectInput.value,
          unidade: selectInput.unidade,
          unitary_price: defaultInputUnitary.replace(',', '.'),
          quantidade: defaultInterValue.replace(',', '.'),
        },
      ]);
      setDefaultInputUnitary(0);
      setDefaultInterValue(0);
      console.log([
        ...interInputs,
        {
          name: selectInput.label,
          id: selectInput.value,
          unidade: selectInput.unidade,
          unitary_price: defaultInputUnitary.replace(',', '.'),
          quantidade: defaultInterValue.replace(',', '.'),
        },
      ]);
    }
  }

  function deleteInputInter(id) {
    const updated = interInputs.filter((int) => int.id !== id);
    setInterInputs(updated);
  }

  function insertInterInter(
    selectInter,
    defaultInterInterValue,
    defaultInterUnitary
  ) {
    const exists = interInter.filter((int) => int.id === selectInter.value);

    if (exists.length > 0) {
      toast.error('Produto Intermediário já cadastrado');
    } else {
      setInterInter([
        ...interInter,
        {
          name: selectInter.label,
          id: selectInter.value,
          unidade: selectInter.unidade,
          unitary_price: defaultInterUnitary.replace(',', '.'),
          quantidade: defaultInterInterValue.replace(',', '.'),
        },
      ]);
    }
  }

  function deleteInterInter(id) {
    const updated = interInter.filter((int) => int.id !== id);
    setInterInter(updated);
  }

  const [totalValue, setTotalValue] = useState('0.00');
  const [competenceDate, setCompetenceDate] = useState(null);
  const [stockTransfers, setStockTransfers] = useState(null);
  const [multistoreSisters, setMultistoreSisters] = useState([]);

  useEffect(() => {
    let total = 0;
    let total_inputs = interInputs.reduce(
      (Acc, inp) =>
        Acc + parseFloat(inp.unitary_price) * parseFloat(inp.quantidade),
      0
    );
    total_inputs = parseFloat(total_inputs.toFixed(2));

    let total_inter = interInter.reduce(
      (Acc, inp) =>
        Acc + parseFloat(inp.unitary_price) * parseFloat(inp.quantidade),
      0
    );
    total_inter = parseFloat(total_inter.toFixed(2));

    total = (total_inputs + total_inter).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    total = `R$${total}`;

    setTotalValue(total);
  }, [interInputs, interInter]);

  const getStockTransfers = useCallback(async (data) => {
    try {
      const response = await api.get('/restaurants/stock-transfer');
      setStockTransfers(response.data);
    } catch (err) {
      toast.error('Falha ao carregar dados de transferencia de estoque');
    }
  }, []);

  const getMultistoreSisters = useCallback(async (data) => {
    try {
      const response = await api.get('/restaurants/multistores-sisters');
      const multistores = response.data.map((multi) => {
        return {
          value: multi.id,
          label: `${multi.fantasy_name} (${multi.name})`,
        };
      });

      setMultistoreSisters(multistores);
    } catch (err) {
      toast.error('Falha ao carregar dados de multilojas irmãs');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const handleData = {
          restaurant_dest_id: data.restaurant,
          inputs: interInputs,
          inters: interInter,
          observation: data.observation,
          due_date: competenceDate,
        };
        console.log('data to create stock transfer', handleData);
        await api.post('/restaurants/stock-transfer/create', handleData);
        getStockTransfers();
        setModal(false);
        toast.success('Transferência de estoque criada com sucesso!');
      } catch (err) {
        toast.error('Falha ao criar transferencia de estoque');
      }
    },
    [interInter, interInputs, competenceDate]
  );

  useEffect(() => {
    if (permission) {
      getStockTransfers();
      getMultistoreSisters();
    }
  }, [permission]);

  return !permission ? (
    <ErrorContainer>
      <p>Apenas o Administrador tem acesso a este menu.</p>
    </ErrorContainer>
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Transferência de Estoque</h2>
          {user.use_stock && (
            <>
              <p>Transfira os insumos para seus filiados</p>
            </>
          )}
        </div>
        {user.is_distribution_center && (
          <RegisterButton
            onClick={() => {
              toggle();
            }}
          >
            Adicionar
          </RegisterButton>
        )}
      </Header>

      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Criada</th>
            <th>Para</th>
            <th>Valor</th>
            <th>Status</th>
            <th>Vencimento</th>
            <th>Observações</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {stockTransfers?.map((stock) => (
            <StockTransferRow
              key={stock.id}
              stock={stock}
              InputsTable={InputsTable}
            />
          ))}
        </tbody>
      </CategoriesTable>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalHeader>Nova transferência</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col md="12">
                  <Select
                    label="Restaurante"
                    name="restaurant"
                    options={multistoreSisters}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col md="12" style={{ marginTop: 10 }}>
                  <p>
                    Selecione os insumos e produtos intermediários que vão ser
                    transferidos:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Select
                    label="Insumos"
                    name="inputs_select"
                    options={inputs_select}
                    placeholder="Selecione"
                    onChange={(e) => setSelectInput(e)}
                    icon={
                      <FaQuestionCircle
                        title="Vincule os insumos que serão enviados para o restaurante que está recebendo a transferência."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                  />
                </Col>
                <Col md="3">
                  <Input
                    label="Quantidade"
                    name="quantidade"
                    type="text"
                    value={defaultInterValue}
                    onChange={(e) => setDefaultInterValue(e.target.value)}
                    icon={
                      <FaQuestionCircle
                        title="Insira a quantidade que será enviada para o restaurante que está recebendo a transferência."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                  />
                </Col>
                <Col md="3">
                  <Input
                    label="Custo Unitário"
                    name="unitary_price"
                    type="text"
                    value={defaultInputUnitary}
                    onChange={(e) => setDefaultInputUnitary(e.target.value)}
                    icon={
                      <FaQuestionCircle
                        title="Insira o valor unitário que será cobrado do restaurante que está recebendo a transferência."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                  />
                </Col>
                <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                  <button
                    type="button"
                    id="add"
                    style={{
                      background: '#3BD2C1',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      border: 'none',
                      fontSize: 16,
                    }}
                    onClick={() =>
                      insertInputInter(
                        selectInput,
                        defaultInterValue,
                        defaultInputUnitary
                      )
                    }
                  >
                    +
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <InputsTable borderless>
                    <thead>
                      <tr>
                        <th>Insumo</th>
                        <th>Quantidade</th>
                        <th>Custo unitário</th>
                        <th>
                          Custo total{' '}
                          <FaQuestionCircle
                            title="Custo total desse insumo que será transferido."
                            color="orange"
                            style={{ marginLeft: 2 }}
                          />
                        </th>
                        <th>Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      {interInputs.length > 0 &&
                        interInputs.map((input) => (
                          <tr>
                            <td>{input.name}</td>

                            <td>
                              {parseFloat(input.quantidade).toFixed(2)}{' '}
                              {input.unidade}
                            </td>

                            <td>
                              R${parseFloat(input.unitary_price).toFixed(2)}
                            </td>

                            <td>
                              R$
                              {(
                                parseFloat(input.unitary_price) *
                                parseFloat(input.quantidade)
                              ).toFixed(2)}
                            </td>

                            <td>
                              <Delete
                                type="button"
                                onClick={() => deleteInputInter(input.id)}
                              >
                                <MdDelete size={20} />
                              </Delete>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </InputsTable>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ margin: 5 }} />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Select
                    label="Prod. Interm."
                    name="inter_select"
                    options={inter_select}
                    placeholder="Selecione"
                    onChange={(e) => setSelectInter(e)}
                    icon={
                      <FaQuestionCircle
                        title="Vincule os produtos intermediários usados na receita para produzir seu novo produto intermediário."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                  />
                </Col>
                <Col md="3">
                  <Input
                    label="Quantidade"
                    name="quantidade"
                    type="text"
                    value={defaultInterInterValue}
                    onChange={(e) => setDefaultInterInterValue(e.target.value)}
                  />
                </Col>
                <Col md="3">
                  <Input
                    label="Custo Unitário"
                    name="unitary_price"
                    type="text"
                    defaultValue={0}
                    value={defaultInterUnitary}
                    onChange={(e) => setDefaultInterUnitary(e.target.value)}
                    icon={
                      <FaQuestionCircle
                        title="Insira o valor unitário que será cobrado do restaurante que está recebendo a transferência."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                  />
                </Col>
                <Col md="2" style={{ margin: 'auto', marginBottom: 15 }}>
                  <button
                    type="button"
                    id="add1"
                    style={{
                      background: '#3BD2C1',
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      border: 'none',
                      fontSize: 16,
                    }}
                    onClick={() =>
                      insertInterInter(
                        selectInter,
                        defaultInterInterValue,
                        defaultInterUnitary
                      )
                    }
                  >
                    +
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <InputsTable borderless>
                    <thead>
                      <tr>
                        <th>Prod. Interm.</th>
                        <th>Quantidade</th>
                        <th>Custo unitário</th>
                        <th>
                          Custo total{' '}
                          <FaQuestionCircle
                            title="Custo total desse insumo que será transferido."
                            color="orange"
                            style={{ marginLeft: 2 }}
                          />
                        </th>
                        <th>Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      {interInter.length > 0 &&
                        interInter.map((inter) => (
                          <tr>
                            <td>{inter.name}</td>

                            <td>
                              {parseFloat(inter.quantidade).toFixed(2)}{' '}
                              {inter.unidade}
                            </td>

                            <td>
                              R${parseFloat(inter.unitary_price).toFixed(2)}
                            </td>

                            <td>
                              R$
                              {(
                                parseFloat(inter.unitary_price) *
                                parseFloat(inter.quantidade)
                              ).toFixed(2)}
                            </td>

                            <td>
                              <Delete
                                type="button"
                                onClick={() => deleteInterInter(inter.id)}
                              >
                                <MdDelete size={20} />
                              </Delete>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </InputsTable>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Input
                    label="Número de Itens"
                    name="itens_length"
                    value={interInputs.length + interInter.length}
                    icon={
                      <FaQuestionCircle
                        title="Caso o estoque do Produto Intermediário esteja abaixo da quantidade mínima, você receberá uma notificação na central de tarefas.."
                        color="orange"
                        style={{ marginLeft: 5 }}
                      />
                    }
                    disabled
                  />
                </Col>
                <Col md="4">
                  <Input
                    label="Valor Total"
                    name="total_value"
                    value={totalValue}
                    disabled
                  />
                </Col>
                <Col md="4">
                  <DataPickerArea>
                    <span>
                      Vencimento <MdDateRange />
                    </span>

                    <DatePickerStyled
                      selected={competenceDate}
                      onChange={(date) => setCompetenceDate(date)}
                      locale="pt"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="__/__/____"
                    />
                  </DataPickerArea>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col style={{ marginTop: 10 }}>
                  <p>Observação</p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <TextareaContainer
                    name="observation"
                    placeholder="Digite alguma observação para o restaurante que irá receber sua transferência"
                    type="text"
                    maxLength="500"
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
}

export default TransferInputs;
