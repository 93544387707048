import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 80%;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const QrcodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 200px;
    max-height: auto;
  }

  h4 {
    margin-top: 20px;
  }
`;

export const DefaultAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px dashed #ddd;
  background: #fff;

  span {
    color: #ddd;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    max-width: 100px;
    margin: 0 auto;
  }
  button {
    margin: 20px auto 0 auto;
    padding: 10px 15px;
    background: ${(props) => props.theme.colors.secondary};
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
  }
`;
