import React from 'react';
import { Container, Option } from './styles';

export const DatePickerSelect = ({ options, clickFunction, show }) => {
  return (
    <Container show={show}>
      {options.map((o) => (
        <Option onClick={() => clickFunction(o.value)}>{o.label}</Option>
      ))}
    </Container>
  );
};
