import styled from 'styled-components';

export const Header = styled.div`
  padding: 20px 0px;
  justify-content: center;
  color: white;
  font-size: 20px;
  height: 70px;
  text-align: center;
  background-image: linear-gradient(to right, #fa0561, #fb4a20);
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  min-width: 200px;
  height: 500px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background: white;
  border-radius: 8px;
  border: solid 2px;
  border-color: #fb4a20;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));

  @media (max-width: 1024px) {
    margin: 0px auto;
    width: 90%;
    min-width: 200px;
    height: 500px;
    font-size: 12px;
  }
`;

export const IconTakeat = styled.span`
  float: left;
  margin-left: 15px;

  img {
    width: 25px;
    heigth: 50px;
  }
`;
