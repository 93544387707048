import React, { useState, useEffect, useCallback } from 'react';

import Switch from 'react-switch';
import ReactSelect from 'react-select';

import { FaCheck, FaTrash, FaPlus } from 'react-icons/fa';

import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import {
  Card,
  TextInput,
  AddressesTable,
  CheckButton,
  PlusButton,
  TrashButton,
  NumberInput,
} from './styles';

import api from '~/services/api';
import apiIbge from '~/services/apiIbge';

import { useAuth } from '~/context/AuthContext';

function Neighborhoods() {
  const { user } = useAuth();

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [currentState, setCurrentState] = useState(user.adress?.state || 'ES');

  const [itemIdLoading, setItemIdLoading] = useState(0);

  // Indica se algum dado foi alterado em caso positivo o botão é habilitado
  const [changed, setChanged] = useState([]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 44,
      minHeight: 44,
    }),
  };

  const [addresses, setAddresses] = useState([]);

  const getAddresses = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/delivery-addresses');

      const allAddresses = response.data.map((addr) => ({
        key: v4(),
        ...addr,
      }));

      const state = user.adress?.state || 'ES';

      allAddresses.unshift({
        key: v4(),
        id: -1,
        country: 'BR',
        state,
        city: null,
        neighborhood: null,
        is_active: true,
        delivery_tax_price: null,
      });

      setAddresses(allAddresses);
      setChanged(new Array(allAddresses.length).fill(false));
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [user.adress]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const getStatesData = useCallback(async () => {
    try {
      const response = await apiIbge.get('/localidades/estados');
      const parsedStates = response.data.map((item) => {
        return {
          label: item.sigla,
          value: item.sigla,
        };
      });

      parsedStates.sort((a, b) => a.value > b.value ? 1 : -1);
      setStates(parsedStates);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  const getCitiesData = useCallback(async () => {
    try {
      let data = [];

      // A api do IBGE não tem as cidades do DF, estas estão salvas no S3.
      if (currentState !== 'DF') {
        const response = await apiIbge.get(
          `/localidades/estados/${currentState}/municipios`
        );

        data = response.data;
      } else {
        const response = await api.get('/restaurants/delivery-addresses/cities');

        data = response.data['DF'].map((nome) => ({ nome }));
      }

      const parsedCities = data.map((item) => {
        return {
          label: item.nome,
          value: item.nome,
        };
      });

      parsedCities.sort((a, b) => a.value > b.value ? 1 : -1);
      setCities(parsedCities);
      setItemIdLoading(0);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [currentState]);

  useEffect(() => {
    getStatesData();
  }, [getStatesData]);

  useEffect(() => {
    getCitiesData();
  }, [getCitiesData]);

  // Mudança de estado do switch
  async function handleActiveChange(value, address, i) {
    address.is_active = value;

    if (!changed[i]) {
      changed[i] = true;
      setChanged([...changed]);
    }

    setAddresses([...addresses]);
  }

  // Consulta troca o estado e reconsulta as cidades
  async function handleStateChange(value, address, i) {
    if (address.state !== value) {
      address.state = value;
      address.city = null;
      address.neighborhood = null;

      setItemIdLoading(address.id);
      setCurrentState(value);

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }

      setAddresses([...addresses]);
    }
  }

  async function handleCityChange(value, address, i) {
    console.log('@@ handleCityChange', value, address.city);
    if (address.city !== value) {
      address.city = value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }

      setAddresses([...addresses]);
    }
  }

  // Usado para buscar os bairros novamente se o estado foi alterado em algum momento
  async function handleCityOpen(address) {
    if (address.state !== currentState) {
      setItemIdLoading(address.id);
      setCurrentState(address.state);
    }
  }

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  const handleNumberChange = useCallback(
    (e, i) => {
      addresses[i].delivery_tax_price = e.floatValue / 100;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }
    },
    [addresses, changed]
  );

  const handleNeighborhoodChange = useCallback(
    (e, i, address) => {
      address.neighborhood = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }

      setAddresses([...addresses]);
    },
    [addresses, changed]
  );

  const handleTimeChange = useCallback(
    (e, i, address) => {
      address.delivery_time = e.target.value;

      if (!changed[i]) {
        changed[i] = true;
        setChanged([...changed]);
      }

      setAddresses([...addresses]);
    },
    [addresses, changed]
  );

  const saveEdited = useCallback(
    async (i) => {
      const address = addresses[i];

      try {
        if (address.id === -1) {
          const data = {
            is_neighborhood_limited: !!address.neighborhood,
            ...address,
          };

          if (!data.is_neighborhood_limited) {
            delete data.neighborhood;
          }
          delete data.id;

          const response = await api.post(
            '/restaurants/delivery-addresses',
            data
          );

          address.id = response.data.id;

          const state = user.adress?.state || 'ES';

          const defaultAddress = {
            key: v4(),
            id: -1,
            country: 'BR',
            state,
            city: null,
            neighborhood: null,
            is_active: true,
            delivery_tax_price: null,
            delivery_time: null
          };

          changed[i] = false;

          setChanged([false, ...changed]);
          setAddresses([defaultAddress, ...addresses]);
        } else {
          const data = {
            is_neighborhood_limited: !!address.neighborhood,
            ...address,
          };

          if (!data.is_neighborhood_limited) {
            delete data.neighborhood;
          }

          delete data.id;

          await api.put(`/restaurants/delivery-addresses/${address.id}`, data);

          changed[i] = false;

          setChanged([...changed]);
        }

        toast.success('Endereço salvo com sucesso!');
      } catch (e) {
        toast.error('Erro ao salvar endereço');
      }
    },
    [addresses, changed, user.adress]
  );

  const deleteAddress = useCallback(
    async (i) => {
      const address = addresses[i];

      if (address.id === -1) {
        return;
      }

      try {
        if (window.confirm('Tem certeza que deseja deletar este endereço?')) {
          await api.delete(`/restaurants/delivery-addresses/${address.id}`);

          addresses.splice(i, 1);
          changed.splice(i, 1);

          setChanged([...changed]);
          setAddresses([...addresses]);

          toast.success('Endereço deletado com sucesso!');
        }
      } catch (err) {
        toast.error('Erro ao deletar endereço');
      }
    },
    [addresses, changed]
  );

  return (
    <Card>
      <AddressesTable borderless>
        <thead>
          <tr>
            <th style={{ width: '50px' }}>Ativo</th>
            <th style={{ width: '100px' }}>UF</th>
            <th style={{ width: '200px' }}>Cidade</th>
            <th>Bairro</th>
            <th style={{ width: '130px' }}>Tx. de Entrega</th>
            <th style={{ width: '130px' }}>Tempo de Entrega</th>
            <th style={{ width: '80px' }}> </th>
          </tr>
        </thead>
        <tbody>
          {addresses.map((address, i) => (
            <tr key={address.key}>
              <td>
                {i > 0 && (
                  <Switch
                    id={`${address.id}`}
                    onChange={(value) => handleActiveChange(value, address, i)}
                    draggable
                    checked={address.is_active}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={27}
                    width={44}
                    handleDiameter={16}
                    activeBoxShadow="0 0 2px 3px #111"
                    boxShadow="0 0 1px 3px rgba(0,0,0,0.1)"
                    onColor="#0CCAB4"
                    offColor="#666666"
                  />
                )}
              </td>
              <td>
                <ReactSelect
                  classNamePrefix="react-select"
                  styles={customStyles}
                  placeholder="Selecione"
                  name="state"
                  options={states}
                  value={
                    address.state
                      ? { value: address.state, label: address.state }
                      : null
                  }
                  onChange={(e) => {
                    handleStateChange(e.value, address, i);
                  }}
                />
              </td>
              <td>
                <ReactSelect
                  classNamePrefix="react-select"
                  styles={customStyles}
                  placeholder="Selecione"
                  name="city"
                  options={cities}
                  value={
                    address.city
                      ? { value: address.city, label: address.city }
                      : null
                  }
                  onChange={(e) => {
                    handleCityChange(e.value, address, i);
                  }}
                  onMenuOpen={() => {
                    handleCityOpen(address);
                  }}
                  isLoading={address.id === itemIdLoading}
                />
              </td>
              <td>
                <TextInput
                  name="neighborhood"
                  type="text"
                  placeholder="Esse campo vazio considera a cidade toda"
                  defaultValue={address.neighborhood}
                  onChange={(e) => handleNeighborhoodChange(e, i, address)}
                />
              </td>
              <td>
                <NumberInput
                  placeholder="Ex.: R$ 3.00"
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={0}
                  value={parseFloat(address.delivery_tax_price) * 100}
                  onValueChange={(e) => handleNumberChange(e, i)}
                />
              </td>
              <td>
                <TextInput
                  name="delivery_time"
                  type="text"
                  placeholder="Ex: 20"
                  defaultValue={address?.delivery_time}
                  onChange={(e) => handleTimeChange(e, i, address)}
                />
              </td>
              {i > 0 ? (
                <td>
                  <CheckButton
                    disabled={!changed[i]}
                    onClick={() => saveEdited(i)}
                  >
                    <FaCheck />
                  </CheckButton>

                  <TrashButton
                    disabled={false}
                    onClick={() => deleteAddress(i)}
                  >
                    <FaTrash />
                  </TrashButton>
                </td>
              ) : (
                <td>
                  <PlusButton
                    disabled={!changed[i]}
                    onClick={() => saveEdited(i)}
                  >
                    <FaPlus />
                  </PlusButton>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </AddressesTable>
    </Card>
  );
}

export default Neighborhoods;
