import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter } from 'react-icons/ai';

import { Print, DisplayImprimir, PrintDiv, ButtonPrint } from './styles';
import PrintClosingBoxAuditById from '~/components/Prints/PrintClosingBoxAuditById';

function PrintOpeningBoxAudit({
  totalManualDeposits,
  totalAutomaticsDeposit,
  boxTotalValue,
  boxInitialValue,
  totalWithdrawalDeposits,
  boxFinalValue,
  handleDeleteBoxStates,
  paymentsAutomatics,
  paymentsManual,
  manualDeposits,
  manualWithdrawals,
  boxClosingTime,
  boxOpeningTime,
  userClose,
  userOpen,
  arrayMethods,
  auditItems,
  modalPrintAudit,
  boxId,
}) {
  const componentRef = useRef(null);

  return (
    <>
      <PrintDiv>
        <ReactToPrint
          trigger={() => (
            <Print>
              <AiOutlinePrinter color="white" size={20} />
            </Print>
          )}
          content={() => componentRef.current}
          onAfterPrint={handleDeleteBoxStates}
        />
      </PrintDiv>
      <DisplayImprimir>
        <PrintClosingBoxAuditById
          ref={componentRef}
          totalAutomaticsDeposit={totalAutomaticsDeposit}
          totalManualDeposits={totalManualDeposits}
          boxTotalValue={boxTotalValue}
          boxInitialValue={boxInitialValue}
          totalWithdrawalDeposits={totalWithdrawalDeposits}
          boxFinalValue={boxFinalValue}
          paymentsAutomatics={paymentsAutomatics}
          paymentsManual={paymentsManual}
          manualDeposits={manualDeposits}
          manualWithdrawals={manualWithdrawals}
          boxClosingTime={boxClosingTime}
          boxOpeningTime={boxOpeningTime}
          userClose={userClose}
          userOpen={userOpen}
          arrayMethods={arrayMethods}
          auditItems={auditItems}
          modalPrintAudit={modalPrintAudit}
          boxId={boxId}
        />
      </DisplayImprimir>

      <PrintDiv>
        <ReactToPrint
          trigger={() => <ButtonPrint type="button">Imprimir</ButtonPrint>}
          content={() => componentRef.current}
          onAfterPrint={handleDeleteBoxStates}
        />
      </PrintDiv>
    </>
  );
}

export default PrintOpeningBoxAudit;
