import React, { useState, useMemo, useEffect } from 'react';

import { v4 } from 'uuid';

import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Complements,
  Footer,
  PriceItem,
  NamePrice,
  ItemsArea,
  TotalServices,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
  TotalRowPayments,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';

const PrintBillsTable = React.forwardRef(
  (
    {
      item,
      restaurant,
      paymentData,
      printBills,
      sessionState,
      count,
      productBills,
    },
    ref
  ) => {
    const [totalPaid, setTotalPaid] = useState(0);
    const [payments, setPayments] = useState([]);
    const { user } = useAuth();
    const [totalWaiterPrice, setTotalWaiterPrice] = useState();

    // const pendingBills = useMemo(() => {
    //   return item?.bills.filter((bill) => bill.status !== 'finished');
    // }, []);
    // const closedBills = useMemo(() => {
    //   return item?.bills.filter((bill) => bill.status === 'finished');
    // }, []);

    // const pendingBills = item?.bills.filter(
    //   (bill) => bill.status !== 'finished'
    // );
    // const closedBills = item?.bills.filter(
    //   (bill) => bill.status === 'finished'
    // );

    const date = useMemo(() => {
      if (!item.end_time) {
        return formatCompleteDate(new Date());
      }
      return formatCompleteDate(new Date(item.end_time));
    }, []);

    // console.log('printBills', printBills);

    useEffect(() => {
      try {
        const paymentsSession = paymentData;

        setPayments(paymentsSession);

        const total = paymentsSession.reduce(
          (acum, curr) => acum + Number(curr.payment_value),
          0
        );

        setTotalPaid(total.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    }, [item.id, paymentData, count]);

    function changeValue(value) {
      const newValue = value ? value.replace('.', ',') : '';
      return newValue;
    }

    //   let total_price_print = 0;
    //   let total_service_price_print = 0;

    // pendingBills.forEach((bill) => {
    //     bill.order_baskets.forEach((ob) => {
    //       ob.orders.forEach((order) => {
    //         total_price_print += parseFloat(order.total_price);
    //         total_service_price_print += parseFloat(order.total_service_price);
    //       });
    //     });
    //   });

    // useEffect(() => {
    //   const printBuyer = [];
    //   const printWaiters = [];

    //   const teste = printBills?.map((item) => {
    //     if (item.buyer !== null) {
    //       printBuyer.push(item);
    //     } else {
    //       printWaiters.push(item);
    //     }
    //   });

    //   setPritBuyerState(...printBills, ...printBuyer);
    // }, [printBills]);

    useEffect(() => {
      const waiters = printBills?.filter((item) => item?.waiter);

      const total_waiter_price = waiters?.reduce(
        (acum, curr) => acum + Number(curr?.total_service_price),
        0
      );

      setTotalWaiterPrice(total_waiter_price);
    }, [printBills]);

    return (
      <Container ref={ref}>
        {item ? (
          <React.Fragment key={item.id}>
            <Table>
              <strong>
                {item.table?.is_balcony
                  ? 'Balcão'
                  : ` MESA  ${item.table?.table_number}`}
              </strong>
            </Table>
            <Bill>
              <strong>{item.table.table_type !== 'command' && 'Comanda'} #{item.id}</strong>
            </Bill>
            {item?.is_prepaid && (
              <Bill>
                <strong>Pagamento antecipado</strong>
              </Bill>
            )}
            <Bill>
              <strong>Fechada {date}</strong>
            </Bill>
            <Restaurant>
              <strong>{restaurant.fantasy_name}</strong>
            </Restaurant>

            <ItemsArea>
              {totalWaiterPrice > 0 && (
                <Body>
                  <Phone>
                    <strong>Garçons</strong>
                  </Phone>
                  <Price>
                    <strong>R${totalWaiterPrice?.toFixed(2)}</strong>
                  </Price>
                </Body>
              )}
              <h4 style={{ marginTop: '14px' }}>Usuários</h4>
              {printBills
                ?.filter((item) => item?.buyer)
                ?.map((bill) => {
                  const userName = bill.buyer
                    ? bill.buyer.phone || bill.buyer.name
                    : bill.waiter?.name;
                  return (
                    parseFloat(bill.total_price) > 0 && (
                      <React.Fragment key={bill.id}>
                        <Body style={{ marginTop: '-16px' }}>
                          <Phone style={{ marginLeft: '16px' }}>
                            <strong>{userName}</strong>
                          </Phone>
                          <Price>
                            <strong>R${bill.total_service_price}</strong>
                          </Price>
                        </Body>
                      </React.Fragment>
                    )
                  );
                })}
            </ItemsArea>

            <ItemsArea>
              <h4 style={{ marginTop: '-8px' }}>Itens</h4>

              <Items>
                {productBills?.map((order) => (
                  <Item key={order.id}>
                    <NamePrice>
                      <ItemName>
                        {order.use_weight ? (
                          <strong>
                            {changeValue(order?.weight.toString())}kg{' '}
                            {order.name}
                          </strong>
                        ) : (
                          <strong>
                            {order?.amount}x {order?.name}
                          </strong>
                        )}
                      </ItemName>
                      <PriceItem>
                        <strong>R${order.total_price.toFixed(2)}</strong>
                      </PriceItem>
                    </NamePrice>
                    {order.complement_categories.map((category) => (
                      <React.Fragment key={category.id}>
                        {category.order_complements
                          .filter((it) => it.complement.price > 0)
                          .map((complement) => (
                            <div key={complement.complement.id}>
                              <NamePrice>
                                <Complements>
                                  <strong>
                                    - {complement.amount}x{' '}
                                    {complement.complement.name}{' '}
                                  </strong>
                                </Complements>
                              </NamePrice>
                            </div>
                          ))}
                      </React.Fragment>
                    ))}
                  </Item>
                ))}
                {/* <Item>
                    <NamePrice>
                      <ItemName>
                        <strong>Serviços (10%)</strong>
                      </ItemName>
                      <PriceItem>
                        <strong>
                          R$
                          {(
                            parseFloat(bill.total_service_price) -
                            parseFloat(bill.total_price)
                          ).toFixed(2)}
                        </strong>
                      </PriceItem>
                    </NamePrice>
                  </Item> */}
              </Items>
            </ItemsArea>

            <ItemsArea>
              {user.has_service_tax && !item?.table?.is_balcony && (
                <TotalServices>
                  <h4>Serviços ({user.service_tax}% - Opcional)</h4>
                  <strong>{`R$ ${parseFloat(
                    item.total_service_price - item.total_price
                  ).toFixed(2)} `}</strong>
                </TotalServices>
              )}
            </ItemsArea>

            {/* 
          <AlreadyClosed>
            <Body>
              <h4>
                <strong>Comandas Individuais Já Fechadas</strong>
              </h4>
              {closedBills.map((bill) => {
                const userName = bill.buyer
                  ? formatPhone(bill.buyer.phone)
                  : bill.waiter?.name;
                return (
                  <React.Fragment key={bill.id}>
                    <Body>
                      <Phone>
                        <strong>{userName}</strong>
                      </Phone>
                      <Price>
                        <strong>R${bill.total_service_price}</strong>
                      </Price>
                    </Body>
                  </React.Fragment>
                );
              })}
            </Body>
          </AlreadyClosed> */}
            <TotalDiv>
              {sessionState?.old_total_price && (
                <>
                  <TotalRow>
                    <TotalName>
                      <strong>SUB TOTAL</strong>
                    </TotalName>
                    <TotalPrice>
                      <strong>
                        R${parseFloat(sessionState?.old_total_price).toFixed(2)}
                      </strong>
                    </TotalPrice>
                  </TotalRow>
                  <TotalRow>
                    <TotalName>
                      <strong>DESCONTO</strong>
                    </TotalName>
                    <TotalPrice>
                      <strong>
                        {sessionState?.discount_percent}% / R$
                        {parseFloat(sessionState?.discount_total).toFixed(2)}
                      </strong>
                    </TotalPrice>
                  </TotalRow>
                  {user.has_service_tax && (
                    <TotalRow>
                      <TotalName>
                        <strong>TOTAL S/ SERVIÇO</strong>
                      </TotalName>
                      <TotalPrice>
                        <strong>{sessionState?.total_price}</strong>
                      </TotalPrice>
                    </TotalRow>
                  )}
                </>
              )}

              {/* <TotalRow>
                <TotalName>
                  <strong>SUB TOTAL</strong>
                </TotalName>
                <TotalPrice>
                  <strong>
                    R${parseFloat(sessionState?.total_price).toFixed(2)}
                  </strong>
                </TotalPrice>
              </TotalRow> */}

              <TotalRow>
                <TotalName>
                  <strong>TOTAL</strong>
                </TotalName>
                <TotalPrice>
                  <strong>
                    R${parseFloat(sessionState?.total_service_price).toFixed(2)}
                  </strong>
                </TotalPrice>
              </TotalRow>
              <TotalRow>
                <TotalName>
                  <strong>PAGO</strong>
                </TotalName>
                <TotalPrice>
                  <strong>R${totalPaid}</strong>
                </TotalPrice>
              </TotalRow>
              <TotalRowPayments>
                {payments &&
                  payments.length > 0 &&
                  payments.map((payment) => (
                    <div key={payment.id}>
                      <span style={{ fontSize: 22 }}>
                        - {payment?.payment_method?.name}
                      </span>
                      <span style={{ fontSize: 22 }}>
                        R$ {parseFloat(payment?.payment_value).toFixed(2)}
                      </span>
                    </div>
                  ))}
              </TotalRowPayments>
              <TotalRow>
                <TotalName>
                  <strong>RESTANTE</strong>
                </TotalName>
                <TotalPrice>
                  <strong>
                    R$
                    {(
                      parseFloat(sessionState?.total_service_price) - totalPaid
                    ).toFixed(2)}
                  </strong>
                </TotalPrice>
              </TotalRow>
            </TotalDiv>
            <Footer>
              <strong>Desenvolvido por Takeat</strong>
            </Footer>
          </React.Fragment>
        ) : (
          <div />
        )}
      </Container>
    );
  }
);

export default PrintBillsTable;
