import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const NoImage = styled.div`
  width: 80px;
  height: 80px;
  background: #33333330;
  border-radius: 8px;
`;
export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  width: 120px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }


    @media (max-width: 1281px) {
    width: 90px;
  }
`;

export const Description = styled.p`
  min-width: 390px;
  max-width: 390px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: 20px;

  @media (max-width: 1023px) {
    min-width: 100px;
    max-width: 100px;
  }

  @media (max-width: 1679px) {
    min-width: 200px;
    max-width: 200px;
  }
`;

export const SubmitStyledButton = styled.button`
  height: 44px;
  width: 44px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  margin-top: 8px;
  margin-left: 8px;
  background-color: #0ccab4;

  svg {
    color: #fff;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lightGray};

    svg {
      filter: opacity(0.3);
      color: #333;
    }
  }
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 10px 5px 10px 0;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.available &&
    css`
      img {
        opacity: 0.7;
      }
    `}
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div`
  svg {
    cursor: pointer;
  }
`;
