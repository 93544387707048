/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { FiTrash } from 'react-icons/fi';
import {
  Wrapper,
  Header,
  Card,
  NavDiv,
  NavDivTeste,
  NavLinkSpan,
  InputsTable,
  IfoodTable,
  ErrorContainer,
} from './styles';

import api from '~/services/api';

import ComplementInputRow from '~/components/Rows/ComplementInputRow';
import ComplementIntermediaryRow from '~/components/Rows/ComplementIntermediaryRow';
import RegisterButton from '~/components/Buttons/RegisterButton';

import CheckboxInput from '~/components/Form/Checkbox';
import Input from '~/components/Form/Input';

import SaveButton from '~/components/Buttons/SaveButton';
import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';
import ImageInput from '~/components/Form/ImageInput';
import { useIfood } from '~/context/iFoodContext';
import getValidationErrors from '~/services/getValidationErrors';
import NFeInfo from '~/services/NFeInfo';
import nfeInfo from '~/services/NFeInfo';

function EditComplement({
  complement,
  toggleModal,
  getCategories,
  getComplements = false,
  setCount,
  menewProducts,
  allCategories,
  setCategories,
  category,
  fiscalGroups,
}) {
  const { user } = useAuth();
  const formRef = useRef(null);
  const [activeTab, setActiveTab] = useState('1');
  const [inputs, setInputs] = useState([]);
  const [intermediaries, setIntermediaries] = useState([]);
  const [isMultistoreChild, setIsMultistoreChild] = useState(
    complement?.is_multistore_child || false
  );

  const [restaurantInputs, setRestaurantInputs] = useState([]);
  const [restaurantInputsSelect, setRestaurantInputsSelect] = useState([]);
  const [inputSelectedId, setInputSelectedId] = useState(null);
  const [inputSelected, setInputSelected] = useState('');
  const { ifoodMenus, ifoodRestaurants } = useIfood();

  const [selectIfoodComplements, setSelectIfoodComplements] = useState(() => {
    const toreturn = ifoodRestaurants.map((rest) => {
      return {
        ifood_restaurant_id: rest.ifood_restaurant_id,
        value: null,
      };
    });

    return toreturn;
  });

  const [ifoodComplements, setIfoodComplements] = useState(
    complement.ifood_complements
  );

  // const allComplements = allCategories.map((cat) => cat.complements).flat();

  const [restaurantIntermediaries, setRestaurantIntermediaries] = useState([]);
  const [
    restaurantIntermediarySelect,
    setRestaurantIntermediarySelect,
  ] = useState([]);
  const [intermediarySelectedId, setIntermediarySelectedId] = useState(null);
  const [intermediarySelected, setIntermediarySelected] = useState('');
  const arrayInputs = [];

  complement.intermediaries &&
    complement.intermediaries.forEach((inter) => {
      const total = parseFloat(
        (
          parseFloat(inter.unitary_price) *
          parseFloat(inter.ComplementIntermediary.quantidade)
        ).toFixed(2)
      );
      arrayInputs.push(total);
    });

  complement.inputs &&
    complement.inputs.forEach((input) => {
      const total = parseFloat(
        (
          parseFloat(input.unitary_price) *
          parseFloat(input.ComplementInput.quantidade)
        ).toFixed(2)
      );
      arrayInputs.push(total);
    });

  const inputsTotal = arrayInputs.reduce((Acc, input) => Acc + input, 0);
  const [totalInputs, setTotalInputs] = useState(inputsTotal.toFixed(2));

  const initialData = {
    name: complement.name,
    price: complement.price,
    limit: complement.limit,
    show_on_report: complement.show_on_report,
    cfop: complement.cfop,
    ncm: complement.ncm,
    cest: complement.cest,
    csosn: complement.csosn,
    icms_origem: complement.icms_origem,
    aliquota: complement.aliquota,
    base_calculo: complement.base_calculo,
    cofins: complement.cofins,
    pis: complement.pis,
    delivery_price: complement.delivery_price,
    available: complement.available,
    available_in_delivery: complement.available_in_delivery,
    image_id: {
      id: complement.image ? complement.image.id : null,
      url: complement.image ? complement.image.url : null,
    },
    description: complement.description,
    icms_aliquota: complement.icms_aliquota,
    cst: complement.cst, // cst
    pis_aliquota: complement.pis_aliquota,
    cofins_aliquota: complement.cofins_aliquota,
    icms_modalidade_base_calculo: complement.icms_modalidade_base_calculo,
    cbenef: complement.cbenef,
  };

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/inputs-minimal');
      const inputs_id = inputs?.map((input) => input.id);

      const new_inputs = response.data.filter(
        (input) => !inputs_id.includes(input.id)
      );
      const select_inputs = new_inputs.map((input) => ({
        value: input.id,
        label: input.name,
      }));
      setRestaurantInputs(response.data);
      setRestaurantInputsSelect(select_inputs);
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
      console.log(err.message);
    }
  }, [inputs]);

  const getIntermediaries = useCallback(async () => {
    try {
      const response = await api.get('restaurants/intermediary-minimal');
      const intermediaries_id = intermediaries?.map((inter) => inter.id);

      const new_inter = response.data.filter(
        (inter) => !intermediaries_id.includes(inter.id)
      );
      const select_inter = new_inter.map((inter) => ({
        value: inter.id,
        label: inter.name,
      }));
      setRestaurantIntermediaries(response.data);
      setRestaurantIntermediarySelect(select_inter);
    } catch (err) {
      toast.error('Erro ao carregar produtos intermediarios.');
    }
  }, [intermediaries]);

  const handleInput = useCallback(async (data) => {
    try {
      const handleData = {
        quantidade: data.quantidade.replace(',', '.'),
        complement_id: complement.id,
        input_id: data.input,
      };

      const response = await api.post(
        'restaurants/complement-inputs',
        handleData
      );

      setInputs(response.data.inputs);
      toast.success('Insumo adicionado a esse complemento com sucesso!');
      const aleatoryId = v4();
      setCount(aleatoryId);

      const arrayInputs = [];
      response.data.intermediaries &&
        response.data.intermediaries.forEach((inter) => {
          const total = parseFloat(
            (
              parseFloat(inter.unitary_price) *
              parseFloat(inter.ComplementIntermediary.quantidade)
            ).toFixed(2)
          );
          arrayInputs.push(total);
        });

      response.data.inputs &&
        response.data.inputs.forEach((input) => {
          const total = parseFloat(
            (
              parseFloat(input.unitary_price) *
              parseFloat(input.ComplementInput.quantidade)
            ).toFixed(2)
          );
          arrayInputs.push(total);
        });

      if (arrayInputs.length > 0) {
        const inputsTotal = arrayInputs.reduce((Acc, input) => Acc + input, 0);
        setTotalInputs(inputsTotal);
      }

      // getComplements();
      // getCategories();
    } catch (err) {
      toast.error('Erro ao carregar insumos.');
    }
  }, []);

  const handleIntermediary = useCallback(async (data) => {
    try {
      const handleData = {
        quantidade: data.quantidade.replace(',', '.'),
        complement_id: complement.id,
        intermediary_id: data.input,
      };

      const response = await api.post(
        'restaurants/complement-intermediary',
        handleData
      );

      setIntermediaries(response.data.intermediaries);

      const aleatoryId = v4();
      setCount(aleatoryId);

      const arrayInputs = [];
      response.data.intermediaries &&
        response.data.intermediaries.forEach((inter) => {
          const total = parseFloat(
            (
              parseFloat(inter.unitary_price) *
              parseFloat(inter.ComplementIntermediary.quantidade)
            ).toFixed(2)
          );
          arrayInputs.push(total);
        });

      response.data.inputs &&
        response.data.inputs.forEach((input) => {
          const total = parseFloat(
            (
              parseFloat(input.unitary_price) *
              parseFloat(input.ComplementInput.quantidade)
            ).toFixed(2)
          );
          arrayInputs.push(total);
        });

      if (arrayInputs.length > 0) {
        const inputsTotal = arrayInputs.reduce((Acc, input) => Acc + input, 0);
        setTotalInputs(inputsTotal);
      }

      // getComplements();
      // getCategories();
      toast.success(
        'Produto intermediário adicionado a esse complemento com sucesso!'
      );
    } catch (err) {
      console.log(err);
      toast.error('Erro ao salvar produto intermediário.');
    }
  }, []);

  useEffect(() => {
    const handle = restaurantInputs.filter(
      (input) => input.id === inputSelectedId
    );
    if (handle.length > 0) {
      setInputSelected(handle[0].unidade);
    }
  }, [inputSelectedId]);

  useEffect(() => {
    const inputs_id = inputs?.map((input) => input.id);

    const new_inputs = restaurantInputs.filter(
      (input) => !inputs_id.includes(input.id)
    );
    const select_inputs = new_inputs.map((input) => ({
      value: input.id,
      label: input.name,
    }));

    setRestaurantInputsSelect(select_inputs);
  }, [inputs]);

  useEffect(() => {
    const handle = restaurantIntermediaries.filter(
      (intermediary) => intermediary.id === intermediarySelectedId
    );
    if (handle.length > 0) {
      setIntermediarySelected(handle[0].unidade);
    }
  }, [intermediarySelectedId]);

  useEffect(() => {
    const inter_id = intermediaries?.map((inter) => inter.id);

    const new_inter = restaurantIntermediaries.filter(
      (inter) => !inter_id.includes(inter.id)
    );
    const select_inter = new_inter.map((inter) => ({
      value: inter.id,
      label: inter.name,
    }));

    setRestaurantIntermediarySelect(select_inter);
  }, [intermediaries]);

  useEffect(() => {
    getInputs();
  }, [getInputs]);

  useEffect(() => {
    getIntermediaries();
  }, [getIntermediaries]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setInputSelected('');
  };

  const [modalPI, setModalPI] = useState(false);
  const toggleModalPI = () => {
    setModalPI(!modalPI);
    setIntermediarySelected('');
  };

  async function handleSubmit(data) {
    const formatPrice = (price) => {
      return parseFloat(price.replace(',', '.')).toFixed(2);
    };

    const formattedPrice = formatPrice(data.price);

    const formattedDeliveryPrice = data.delivery_price
      ? formatPrice(data.delivery_price)
      : null;

    try {
      const parsedData = {
        name: data.name,
        price: formattedPrice,
        limit: data.limit,
        description: data.description,
        available: data.available,
        show_on_report: data.show_on_report,
        // cfop: data.cfop,
        // ncm: data.ncm,
        // csosn: data.csosn,
        // icms_origem: data.icms_origem,
        // aliquota: data.aliquota,
        // base_calculo: data.base_calculo,
        // cofins: data.cofins,
        // pis: data.pis,
        // cest: data?.cest || null,
        menew_cod: data.menew_cod === '' ? null : data.menew_cod,
        delivery_price: formattedDeliveryPrice,
        available_in_delivery: data.available_in_delivery,
        ifood_complement_id: data.ifood_complement,
      };

      if (data.image_id) {
        parsedData.image_id = data.image_id;
      }

      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        limit: Yup.string().required('Limite obrigatório'),
        price: Yup.string().required('Preço obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(
        `restaurants/product/complements/${complement.id}`,
        parsedData
      );

      const newCategories = [...allCategories];

      newCategories.map((cat, idix) =>
        cat.complements.map((item, idex) => {
          if (item.id === complement.id) {
            newCategories[idix].complements[idex] = response.data;
          }
        })
      );

      setCategories(newCategories);

      const complementFind = category.complements.findIndex(
        (item) => item.id === complement.id
      );

      if (complementFind !== -1) {
        category.complements[complementFind] = response.data;
      }

      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      toast.success('Complemento atualizado com sucesso!');

      // const aleatoryId = v4();

      // setCount(aleatoryId);

      // getComplements();
      // getCategories();
      toggleModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao editar complemento');
      }
    }
  }

  let menew_options;
  let menew_default;

  if (menewProducts && menewProducts.length > 0) {
    menew_default = menewProducts.filter(
      (menew) => menew.cod === complement.menew_cod
    );
    menew_default = {
      value: menew_default[0] ? menew_default[0].cod : null,
      label: `${
        menew_default[0] ? menew_default[0].cod : 'Carregando Menew'
      } - ${
        menew_default[0]
          ? menew_default[0].name
          : 'Saia dessa janela e clique de novo em editar'
      }`,
    };
    if (!complement.menew_cod) {
      menew_default = null;
    }
    menew_options = menewProducts.map((product) => ({
      value: product.cod,
      label: `${product.cod} - ${product.name}`,
    }));
  }

  const icms_origem_options = nfeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  let icms_default;
  if (complement.icms_origem) {
    icms_default = nfeInfo.icms_origem.filter(
      (c) => c.value === complement.icms_origem
    );
    if (icms_default[0]) {
      icms_default = {
        value: icms_default[0].value,
        label: icms_default[0].description,
      };
    }
  }

  const csosn_options = nfeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  let csosn_default;
  if (complement.csosn) {
    csosn_default = nfeInfo.csosn.filter((c) => c.csosn === complement.csosn);
    if (csosn_default[0]) {
      csosn_default = {
        value: csosn_default[0].csosn,
        label: `${csosn_default[0].csosn} - ${csosn_default[0].description}`,
      };
    }
  }

  const base_calculo_options = nfeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  let base_default;
  if (complement.base_calculo) {
    base_default = nfeInfo.base_calculo.filter(
      (c) => c.value === complement.base_calculo
    );
    base_default = {
      value: base_default.value,
      label: base_default.description,
    };
  }

  let cfop_default;
  if (complement.cfop) {
    cfop_default = nfeInfo.cfopDescription.filter(
      (c) => c.cfop === complement.cfop
    );
    if (cfop_default[0]) {
      cfop_default = {
        value: cfop_default[0].cfop,
        label: `${cfop_default[0].cfop} - ${cfop_default[0].description}`,
      };
    }
  }

  let cofins_default;
  if (complement.cofins) {
    cofins_default = nfeInfo.cofins_options.filter(
      (c) => c.value === complement.cofins
    );
  }

  let pis_default;
  if (complement.pis) {
    pis_default = nfeInfo.cofins_options.filter(
      (c) => c.value === complement.pis
    );
  }

  const [csosnSelected, setCsosnSelected] = useState(complement.csosn);
  const [cfopOptions, setCfopOptions] = useState([]);

  const insertComplementIfood = useCallback(
    async ({
      complement_id,
      ifood_restaurant_id,
      complement,
      ifoodComplements,
    }) => {
      let ifood_id = selectIfoodComplements.find(
        (prod) => prod.ifood_restaurant_id === ifood_restaurant_id
      );
      if (ifood_id) {
        ifood_id = ifood_id.value;
        const data = {
          ifood_id,
          complement_id,
          ifood_restaurant_id,
        };
        try {
          const response = await api.post(
            `/restaurants/ifood/complements`,
            data
          );
          ifoodComplements.push(response.data);
          toast.success('Complemento associado com sucesso');
          getCategories();
        } catch (err) {
          toast.error(
            err?.response?.data?.error
              ? `${err.response.data?.error}`
              : 'Erro ao associar complemento'
          );
          console.log('ifood error', err.response);
        }
      } else {
        toast.error('Erro ao associar complemento');
      }
    },
    []
  );

  const removeComplementIfood = useCallback(
    async ({ complement_id, ifood_id, complement }) => {
      if (window.confirm('Tem certeza que deseja deletar essa relação?')) {
        if (ifood_id) {
          const data = {
            ifood_id,
            complement_id,
          };
          try {
            await api.delete(`/restaurants/ifood/complements`, { data });
            toast.success('Complemento desassociado com sucesso');
            const newComplements = ifoodComplements.filter(
              (comp) => comp.ifood_id !== ifood_id
            );
            setIfoodComplements(newComplements);
          } catch (err) {
            toast.error(
              err?.response?.data?.error
                ? `${err.response.data?.error}`
                : 'Erro ao desassociar complemento'
            );
            console.log('ifood error', err.response);
          }
        } else {
          toast.error('Erro ao desassociar complemento');
        }
      }
    },
    []
  );

  // regime simples
  const [icmsOrigemValue, setIcmsOrigemValue] = useState(
    icms_origem_options.find((x) => x.value === complement.icms_origem)
  );
  const [csosnValue, setCsosnValue] = useState(
    csosn_options.find((x) => x.value === complement.csosn)
  );
  const [cfopValue, setCfopValue] = useState(
    NFeInfo.cfop_options.find((x) => x.value === complement.cfop)
  );
  const [cofinsValue, setCofinsValue] = useState(
    NFeInfo.cofins_options.find((x) => x.value === complement.cofins)
  );
  const [pisValue, setPisValue] = useState(
    NFeInfo.cofins_options.find((x) => x.value === complement.pis)
  );
  const [cestValue, setCestValue] = useState(complement.cest);
  const [baseCalculoValue, setBaseCalculoValue] = useState(
    complement.aliquota || complement.icms_aliquota
  );
  const [aliquotaValue, setAliquotaValue] = useState(
    base_calculo_options.find((x) => x.value === complement.base_calculo)
  );
  // regime normal
  const [cstValue, setCstValue] = useState(
    NFeInfo.icms_situacao_tributaria.find((x) => x.value === complement.cst)
  );
  const [pisAliquotaValue, setPisAliquotaValue] = useState(
    complement.pis_aliquota
  );
  const [cofinsAliquotaValue, setCofinsAliquotaValue] = useState(
    complement.cofins_aliquota
  );
  const [icmsModalidadeBCValue, setIcmsModalidadeBCValue] = useState(
    NFeInfo.icms_modalidade_base_calculo.find(
      (x) => x.value === complement.icms_modalidade_base_calculo
    )
  );
  const [cbenefValue, setCbenefValue] = useState(complement.cbenef);
  const group = fiscalGroups.find((x) => x.id === complement.fiscal_group_id);
  const [fiscalGroup, setFiscalGroup] = useState(null);
  const [fiscalGroupDefault, setFiscalGroupDefault] = useState(
    group
      ? {
          label: group.title,
          value: group.id,
        }
      : null
  );

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        fiscal_group_id: fiscalGroup,
      };

      if (user.regime_tributario === '1') {
        parsedData.ncm = data.ncm;
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;

        if (csosnSelected === '00') {
          parsedData.aliquota = aliquotaValue || data.aliquota;
          parsedData.base_calculo =
            baseCalculoValue?.value || data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      } else {
        parsedData.ncm = data.ncm;
        parsedData.cfop = cfopValue?.value || data.cfop;
        parsedData.csosn = csosnValue?.value || data.csosn;
        parsedData.cest = cestValue?.value || data?.cest || null;
        parsedData.icms_origem = icmsOrigemValue?.value || data.icms_origem;
        parsedData.icms_aliquota = aliquotaValue || data.icms_aliquota;

        parsedData.cst = cstValue?.value || data.cst;
        parsedData.pis_aliquota = pisAliquotaValue || data.pis_aliquota;
        parsedData.cofins_aliquota =
          cofinsAliquotaValue || data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          icmsModalidadeBCValue?.value || data.icms_modalidade_base_calculo;
        parsedData.cbenef = cbenefValue || data.cbenef;

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = cofinsValue?.value || data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = pisValue?.value || data.pis;
        }
      }

      const response = await api.put(
        `restaurants/product/complements/fiscal/${complement.id}`,
        parsedData
      );

      const newCategories = [...allCategories];

      newCategories.map((cat, idix) =>
        cat.complements.map((item, idex) => {
          if (item.id === complement.id) {
            newCategories[idix].complements[idex] = response.data;
          }
        })
      );

      setCategories(newCategories);
      toggleModal();

      toast.success('Produto atualizado com sucesso!');
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  useEffect(() => {
    const cfopSelect = nfeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = nfeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  const getComplementInfo = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/product/complements/${complement.id}`
      );
      setInputs(response.data.inputs);
      setIntermediaries(response.data.intermediaries);
      setIfoodComplements(response.data.ifood_complements);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar insumos');
    }
  }, [complement]);

  useEffect(() => {
    getComplementInfo();
  }, [getComplementInfo]);

  async function updateFiscalValues(id) {
    const group = fiscalGroups.find((group) => group.id === id);
    setFiscalGroup(id);
    setFiscalGroupDefault({
      value: group.id,
      label: group.title,
    });
    setIcmsOrigemValue(
      icms_origem_options.find((x) => x.value === group.icms_origem)
    );
    setCsosnValue(csosn_options.find((x) => x.value === group.csosn));
    setCfopValue(NFeInfo.cfop_options.find((x) => x.value === group.cfop));
    setCofinsValue(
      NFeInfo.cofins_options.find((x) => x.value === group.cofins)
    );
    setPisValue(NFeInfo.cofins_options.find((x) => x.value === group.pis));
    setCestValue(group.cest);
    setAliquotaValue(group.aliquota || group.icms_aliquota);
    setBaseCalculoValue(
      base_calculo_options.find((x) => x.value === group.base_calculo)
    );
    // regime normal
    setCstValue(
      NFeInfo.icms_situacao_tributaria.find((x) => x.value === group.cst)
    );
    setPisAliquotaValue(group.pis_aliquota);
    setCofinsAliquotaValue(group.cofins_aliquota);
    setIcmsModalidadeBCValue(
      NFeInfo.icms_modalidade_base_calculo.find(
        (x) => x.value === group.icms_modalidade_base_calculo
      )
    );
    setCbenefValue(group.cbenef);
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar complemento</h2>
        </div>
      </Header>
      <div style={{ margin: 'auto', width: '80%' }}>
        <NavDiv>
          <NavDivTeste>
            <Nav tabs red borderless>
              <NavItem>
                <NavLink
                  className={activeTab === '1' ? 'active' : ''}
                  onClick={() => setActiveTab('1')}
                >
                  <NavLinkSpan>Informações do Complemento</NavLinkSpan>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={activeTab === '2' ? 'active' : ''}
                  onClick={() => setActiveTab('2')}
                >
                  <NavLinkSpan>Ficha Técnica</NavLinkSpan>
                </NavLink>
              </NavItem>

              {user.has_ifood && (
                <NavItem>
                  <NavLink
                    className={activeTab === '3' ? 'active' : ''}
                    onClick={() => setActiveTab('3')}
                  >
                    <NavLinkSpan>iFood</NavLinkSpan>
                  </NavLink>
                </NavItem>
              )}

              {user.has_nfce && (
                <NavItem>
                  <NavLink
                    className={activeTab === '4' ? 'active' : ''}
                    onClick={() => setActiveTab('4')}
                  >
                    <NavLinkSpan>Fiscal</NavLinkSpan>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </NavDivTeste>
        </NavDiv>
      </div>
      <Card>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="4">
            <Form initialData={initialData} onSubmit={handleSubmitFiscal}>
              <Container>
                <Row>
                  <Col sm="12">
                    <Select
                      name="fiscal_group_id"
                      options={fiscalGroups?.map((group) => {
                        return {
                          label: group.title,
                          value: group.id,
                        };
                      })}
                      label="Grupo Fiscal"
                      onChange={(e) => updateFiscalValues(e.value)}
                      value={fiscalGroupDefault}
                    />
                  </Col>
                </Row>
                {user.has_nfce &&
                  (user.regime_tributario === '1' ? (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>

                        <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.cofins_options}
                            label="Cofins"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.cofins_options}
                            label="Pis"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '00' ||
                        aliquotaValue ||
                        baseCalculoValue) && (
                        <div>
                          <Row style={{ paddingTop: 20 }}>
                            <Col sm="12" lg="6">
                              <Input
                                label="Alíquota (Entre 0 e 100)"
                                name="aliquota"
                                type="text"
                                value={aliquotaValue}
                                onChange={(e) => {
                                  setAliquotaValue(e.target.value);
                                }}
                              />
                            </Col>

                            <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                              <Select
                                name="base_calculo"
                                options={base_calculo_options}
                                label="Base Cálculo"
                                value={baseCalculoValue}
                                onChange={(e) => {
                                  setBaseCalculoValue(
                                    base_calculo_options.find(
                                      (x) => x.value === e.value
                                    )
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_origem"
                            options={icms_origem_options}
                            label="ICMS de Origem"
                            value={icmsOrigemValue}
                            onChange={(e) => {
                              setIcmsOrigemValue(
                                icms_origem_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota ICMS (%)"
                            name="icms_aliquota"
                            type="text"
                            value={aliquotaValue}
                            onChange={(e) => {
                              setAliquotaValue(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cfop"
                            options={cfopOptions}
                            label="CFOP"
                            onChange={(e) => {
                              setCfopValue(
                                NFeInfo.cfop_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cfopValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <Select
                            name="cst"
                            options={NFeInfo.icms_situacao_tributaria}
                            label="CST"
                            onChange={(e) => {
                              setCstValue(
                                NFeInfo.icms_situacao_tributaria.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cstValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="csosn"
                            options={csosn_options}
                            label="CSOSN"
                            onChange={(e) => {
                              setCsosnSelected(e.value);
                              setCsosnValue(
                                csosn_options.find((x) => x.value === e.value)
                              );
                            }}
                            value={csosnValue}
                          />
                        </Col>
                        <Col sm="12" lg="6">
                          <InputMask mask="99999999">
                            <Input label="NCM" name="ncm" type="text" />
                          </InputMask>
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="pis"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="PIS"
                            onChange={(e) => {
                              setPisValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={pisValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota PIS (%)"
                            name="pis_aliquota"
                            type="text"
                            onChange={(e) => {
                              setPisAliquotaValue(e.target.value);
                            }}
                            value={pisAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="cofins"
                            options={nfeInfo.pis_situacao_tributaria}
                            label="COFINS"
                            onChange={(e) => {
                              setCofinsValue(
                                NFeInfo.cofins_options.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={cofinsValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota COFINS (%)"
                            name="cofins_aliquota"
                            type="text"
                            onChange={(e) => {
                              setCofinsAliquotaValue(e.target.value);
                            }}
                            value={cofinsAliquotaValue}
                          />
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: 10 }}>
                        <Col sm="12" lg="6">
                          <Select
                            name="icms_modalidade_base_calculo"
                            options={nfeInfo.icms_modalidade_base_calculo}
                            label="Modalidade Base Calculo"
                            onChange={(e) => {
                              setIcmsModalidadeBCValue(
                                NFeInfo.icms_modalidade_base_calculo.find(
                                  (x) => x.value === e.value
                                )
                              );
                            }}
                            value={icmsModalidadeBCValue}
                          />
                        </Col>

                        <Col sm="12" lg="6">
                          <Input
                            label="Código de Benefícios Fiscais (cBenef)"
                            name="cbenef"
                            type="text"
                            onChange={(e) => {
                              setCbenefValue(e.target.value);
                            }}
                            value={cbenefValue}
                          />
                        </Col>
                      </Row>

                      {(csosnValue?.value === '500' || cestValue) && (
                        <Row style={{ paddingTop: 10 }}>
                          <Col sm="12" lg="6">
                            <InputMask
                              mask="9999999"
                              onChange={(e) => {
                                setCestValue(e.target.value);
                              }}
                              value={cestValue}
                            >
                              <Input label="CEST" name="cest" type="text" />
                            </InputMask>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}

                <SaveButton type="submit" style={{ marginTop: 20 }}>
                  Salvar
                </SaveButton>
              </Container>
            </Form>
          </TabPane>

          <TabPane tabId="3">
            {ifoodRestaurants.map((ifoodRestaurant) => {
              const thisOptions =
                ifoodMenus.find(
                  (menu) =>
                    menu.ifood_restaurant_id ===
                    ifoodRestaurant.ifood_restaurant_id
                )?.complements || [];
              const complementsRegistered =
                ifoodComplements?.filter(
                  (ifood) =>
                    ifood.ifood_restaurant_id ===
                    ifoodRestaurant.ifood_restaurant_id
                ) || [];
              complementsRegistered.forEach((prod) => {
                const name =
                  thisOptions.find((option) => option.value === prod.ifood_id)
                    ?.label || null;
                prod.name = name;
              });

              return (
                <Form>
                  <Row>
                    <Col sm="10">
                      <Select
                        name="ifood_product"
                        options={thisOptions}
                        label={`${ifoodRestaurant.name}`}
                        onChange={(e) => {
                          const newData = selectIfoodComplements;
                          const select = newData.find(
                            (data) =>
                              data.ifood_restaurant_id ===
                              ifoodRestaurant.ifood_restaurant_id
                          );
                          if (select) {
                            select.value = e.value;
                          }
                          setSelectIfoodComplements(newData);
                        }}
                      />
                    </Col>
                    <Col sm="2">
                      <button
                        type="button"
                        id="add"
                        style={{
                          background: '#f4364c', // '#3BD2C1',
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          border: 'none',
                          fontSize: 16,
                          marginTop: 25,
                        }}
                        onClick={() =>
                          insertComplementIfood({
                            complement_id: complement.id,
                            ifood_restaurant_id:
                              ifoodRestaurant.ifood_restaurant_id,
                            complement,
                            ifoodComplements,
                          })
                        }
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      borderBottom: '1px solid #EBEBEB',
                      paddingBottom: 10,
                    }}
                  >
                    <IfoodTable borderless>
                      {/* <thead>
                        <tr>
                          <th>Produto</th>
                          <th>ID</th>
                          <th></th>
                        </tr>
                      </thead> */}

                      <tbody>
                        {complementsRegistered.map((prod) => (
                          <tr>
                            <td>
                              <strong>{prod.name}</strong>
                            </td>
                            <td>{prod.ifood_id}</td>
                            <td>
                              <Button
                                style={{
                                  background: 'transparent',
                                  color: 'red',
                                  padding: 5,
                                }}
                                onClick={() =>
                                  removeComplementIfood({
                                    complement_id: complement.id,
                                    ifood_id: prod.ifood_id,
                                    complement,
                                  })
                                }
                              >
                                <FiTrash size={15} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </IfoodTable>
                  </Row>
                </Form>
              );
            })}
          </TabPane>
          <TabPane tabId="2">
            {user.use_stock ? (
              <>
                <Row>
                  <InputsTable borderless>
                    <thead>
                      <tr>
                        <th>Custo do Item (CMV)</th>
                        <th>Valor de Venda</th>
                        <th>Lucro Bruto</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td style={{ color: '#A6803A' }}>
                          R$
                          {parseFloat(totalInputs).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td>
                          R$
                          {parseFloat(complement.price).toLocaleString(
                            'pt-BR',
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </td>
                        <td style={{ color: 'green' }}>
                          R$
                          {(
                            parseFloat(complement.price) -
                            parseFloat(totalInputs)
                          ).toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </InputsTable>
                </Row>
                <Row style={{ paddingTop: 30 }}>
                  <Col>
                    <h5>Produtos Intermediárioss</h5>
                    {!isMultistoreChild && (
                      <RegisterButton onClick={toggleModalPI} text="Associar" />
                    )}
                  </Col>
                </Row>
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Produto Intermediário</th>
                      <th>Esse Complemento Gasta</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    {intermediaries &&
                      intermediaries.length > 0 &&
                      intermediaries.map((intermediary) => (
                        <ComplementIntermediaryRow
                          intermediary={intermediary}
                          setIntermediaries={setIntermediaries}
                          key={intermediary.id}
                          getCategories={getCategories}
                          getComplements={getComplements}
                          setCount={setCount}
                          setTotalInputs={setTotalInputs}
                        />
                      ))}
                  </tbody>
                </InputsTable>
                <Row style={{ paddingTop: 30 }}>
                  <Col>
                    <h5>Insumos</h5>
                    {!isMultistoreChild && (
                      <RegisterButton onClick={toggle} text="Associar" />
                    )}
                  </Col>
                </Row>
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Esse Complemento Gasta</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputs &&
                      inputs.length > 0 &&
                      inputs.map((input) => (
                        <ComplementInputRow
                          input={input}
                          setInputs={setInputs}
                          key={input.id}
                          getCategories={getCategories}
                          getComplements={getComplements}
                          setCount={setCount}
                          setTotalInputs={setTotalInputs}
                        />
                      ))}
                  </tbody>
                </InputsTable>

                <Modal isOpen={modalPI} toggle={toggleModalPI}>
                  <Form onSubmit={handleIntermediary}>
                    <ModalHeader>Adicionar Produtos Intermediários</ModalHeader>
                    <ModalBody>
                      <Container>
                        <Row>
                          <Col md="12">
                            <Select
                              label="Produto Intermediário"
                              name="input"
                              options={restaurantIntermediarySelect}
                              onChange={(e) =>
                                setIntermediarySelectedId(e.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Input
                              label="A cada venda, esse complemento gasta:"
                              name="quantidade"
                              type="text"
                              placeholder={`${intermediarySelected}`}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={toggleModalPI}
                      >
                        Salvar
                      </Button>{' '}
                      <Button color="danger" onClick={toggleModalPI}>
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>

                <Modal isOpen={modal} toggle={toggle}>
                  <Form onSubmit={handleInput}>
                    <ModalHeader>Adicionar Insumos</ModalHeader>
                    <ModalBody>
                      <Container>
                        <Row>
                          <Col md="12">
                            <Select
                              label="Insumo"
                              name="input"
                              options={restaurantInputsSelect}
                              onChange={(e) => setInputSelectedId(e.value)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Input
                              label="A cada venda, esse produto gasta:"
                              name="quantidade"
                              type="text"
                              placeholder={`${inputSelected}`}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" type="submit" onClick={toggle}>
                        Salvar
                      </Button>{' '}
                      <Button color="danger" onClick={toggle}>
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>
              </>
            ) : (
              <ErrorContainer>
                <p style={{ fontSize: 23 }}>
                  Boas notícias! Seu plano atual já contempla a função de
                  controle de estoque!
                </p>
                <p style={{ fontSize: 16 }}>
                  Para ativar essa funcionalidade, entre em contato com o time
                  de suporte que sua ativação será realizada sem custos extras.
                </p>
              </ErrorContainer>
            )}
          </TabPane>

          <TabPane tabId="1">
            <Form
              initialData={initialData}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Container>
                <Row>
                  <Col sm="12">
                    <ImageInput
                      name="image_id"
                      complement
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="Nome"
                      name="name"
                      type="text"
                      placeholder="Nome do complemento"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Limite"
                      name="limit"
                      type="number"
                      placeholder="Limite individual"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="Preço"
                      name="price"
                      type="text"
                      placeholder="R$xx,xx"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input
                      label="Descrição"
                      name="description"
                      type="textarea"
                      placeholder="Descrição do complemento"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="Preço no delivery"
                      name="delivery_price"
                      type="text"
                      placeholder="R$xx,xx"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CheckboxInput
                      label="Disponível no presencial"
                      name="available"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                  <Col>
                    <CheckboxInput
                      label="Disponível no delivery"
                      name="available_in_delivery"
                      disabled={isMultistoreChild}
                    />
                  </Col>
                </Row>
                <Row />
                <Row />

                {user.has_menew && (
                  <Row>
                    <Col sm="12" lg="12" style={{ marginTop: 15 }}>
                      <Select
                        name="menew_cod"
                        options={menew_options}
                        defaultValue={menew_default}
                        label="Produto Menew"
                      />
                    </Col>
                  </Row>
                )}

                {/* {user.has_nfce && (
                  <div style={{ paddingTop: 30 }}>
                    <Row>
                      <Col>
                        <h5>Fiscal</h5>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask mask="99999999" disabled={isMultistoreChild}>
                          <Input label="NCM" name="ncm" type="text" />
                        </InputMask>
                      </Col>

                      <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                        <Select
                          name="icms_origem"
                          options={icms_origem_options}
                          defaultValue={icms_default}
                          label="ICMS de Origem"
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="csosn"
                          options={csosn_options}
                          defaultValue={csosn_default}
                          label="CSOSN"
                          onChange={(e) => setCsosnSelected(e.value)}
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="cfop"
                          options={cfopOptions}
                          label="CFOP"
                          defaultValue={cfop_default}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <Select
                          name="cofins"
                          options={nfeInfo.cofins_options}
                          defaultValue={cofins_default}
                          label="Cofins"
                        />
                      </Col>

                      <Col sm="12" lg="6">
                        <Select
                          name="pis"
                          options={nfeInfo.cofins_options}
                          defaultValue={pis_default}
                          label="Pis"
                        />
                      </Col>
                    </Row>

                    {csosnSelected === '500' && (
                      <Row style={{ paddingTop: 10 }}>
                        <InputMask mask="9999999">
                          <Input label="CEST" name="cest" type="text" />
                        </InputMask>
                      </Row>
                    )}

                    {csosnSelected === '00' && (
                      <div>
                        <Row style={{ paddingTop: 20 }}>
                          <Col sm="12" lg="6">
                            <Input
                              label="Alíquota (Entre 0 e 100)"
                              name="aliquota"
                              type="text"
                              maxlength="3"
                            />
                          </Col>

                          <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                            <Select
                              name="base_calculo"
                              options={base_calculo_options}
                              defaultValue={base_default}
                              label="Base Cálculo"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                )} */}

                <SaveButton type="submit">Cadastrar</SaveButton>
              </Container>
            </Form>
          </TabPane>
        </TabContent>
      </Card>
    </Wrapper>
  );
}

export default EditComplement;
