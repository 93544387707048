/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { toast } from 'react-hot-toast';
// import PDF, { Text, AddPage, Line, Image, Table, Html } from 'jspdf-react';

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  differenceInDays,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import { Row, Col, Collapse } from 'reactstrap';
import { Input } from '@material-ui/core';

import {
  Wrapper,
  Header,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  Card,
  Content,
  DataPickerArea,
  DatePickerStyled,
  SelectDateButton,
  ErrorContainer,
} from './styles';

import api from '~/services/api';
import UpdateButton from '~/components/Buttons/UpdateButton';

import Nfes from '~/components/Nfes';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';

export default function NFCeHistory() {
  const formRef = useRef(null);
  const zip = require('jszip')();

  const defaultInicial = useMemo(() => {
    return format(
      startOfMonth(subHours(new Date(), 3)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [rangeOn, setRangeOn] = useState(true);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 27)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    setRangeOn(true);

    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);

    setMonthYearDate(date);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  // function handleChangeDate() {
  //   setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
  //   setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
  //   const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
  //   const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
  //   const phrase = `De ${inicialFormat} até ${finalFormat}`;

  //   setHourPhrase(phrase);
  // }

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    }
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [nfes, setNfes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState([]);
  const [cashFlows, setCashFlows] = useState([]);

  const [updateTime, setUpdateTime] = useState(() => {
    return format(new Date(), 'HH:mm');
  });

  const updateNfe = useCallback(async () => {
    await api.get('/restaurants/nfe-received/pull');
  }, []);

  const getNfes = useCallback(async () => {
    try {
      const response = await api.get('restaurants/nfe-received', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          pendente: true,
        },
      });

      setNfes(response.data);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  const getInputs = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/inputs?units=true');

      const resp = response.data.map((inp) => ({
        value: inp.id,
        label: `${inp.name} (${inp.unidade})`,
        name: inp.name,
        name_sefaz: inp.name_sefaz,
        unidade: inp.unidade,
        quantidade: inp.quantidade,
        history_unit: inp.history_unit,
        unitary_price: inp.unitary_price,
        cash_flow_category_subcategory_id:
          inp.cash_flow_category_subcategory_id,
      }));

      setInputs(resp);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  const getCashFlows = useCallback(async () => {
    try {
      const response = await api.get(
        '/restaurants/cash-flows/categories-detail'
      );

      const categoriesSelect = response?.data?.map((category) => {
        return {
          value: category.id,
          label: category.text,
        };
      });
      setCashFlows(categoriesSelect);
    } catch (err) {
      toast.error('Falha ao carregar dados');
    }
  }, []);

  useEffect(() => {
    getNfes();
    getInputs();
    getCashFlows();
    setLoading(false);
  }, [getNfes]);

  async function handleUpdate() {
    setLoading(true);
    updateNfe();
    getNfes();
    setLoading(false);

    const nowTime = format(new Date(), 'HH:mm');
    setUpdateTime(nowTime);
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [modal, setModal] = useState(false);
  async function toggleEmail() {
    setModal(!modal);
  }

  // uservalidate

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'FiscalManifestNF'}`
      );

      const { can_read } = response.data.FiscalManifestNF;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);
  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Wrapper>
      <Header>
        <Row style={{ width: '100%' }}>
          <Col md="10">
            <div style={{ display: 'flex' }}>
              <h2>Manifesto de Notas Fiscais</h2>
              <VideoPlayer id={4} />
            </div>
            <p>Aceite ou recuse NFes emitidas para seu estabelecimento</p>
          </Col>
          <Col md="2">
            <span>Última Atualização {updateTime}</span>
            <UpdateButton onClick={handleUpdate} id="update_button" />
          </Col>
        </Row>
      </Header>
      <Card>
        <Content>
          <Row>
            <Col md="4">
              <p>{hourPhrase}</p>
            </Col>
            <Col md="8">
              <div style={{ float: 'right' }}>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setToday}
                  color="info"
                >
                  Hoje / Ontem
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={setWeek}
                  color="info"
                >
                  Essa semana
                </SelectDateButton>
                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={() => setMonth(new Date())}
                  color="info"
                >
                  Esse mês
                </SelectDateButton>

                <DataPickerArea>
                  <DatePickerStyled
                    selected={monthYearDate}
                    onChange={(date) => setMonth(date)}
                    locale="pt"
                    showMonthYearPicker
                    customInput={
                      <SelectDateButton
                        className="btn-round mr-auto"
                        color="info"
                      >
                        Mês/Ano <MdArrowDropDown color="white" size={20} />
                      </SelectDateButton>
                    }
                  />
                </DataPickerArea>

                <SelectDateButton
                  className="btn-round mr-auto"
                  onClick={toggle}
                  color="info"
                >
                  Selecionar Período
                  <MdArrowDropDown color="white" size={20} />
                </SelectDateButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div style={{ float: 'right', marginRight: '15px' }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
            <p
              style={{
                textAlign: 'right',
                color: 'red',
                display: rangeOn ? 'none' : 'block',
              }}
            >
              Favor selecionar o intervalo máximo de 31 dias entre as datas.
            </p>
          </Row>

          <Row />

          <Nfes
            nfes={nfes}
            loading={loading}
            inputs={inputs}
            getInputs={getInputs}
            handleUpdate={handleUpdate}
            cashFlows={cashFlows}
            getNfes={getNfes}
          />
        </Content>
      </Card>
    </Wrapper>
  );
}
