import React, { useState, useRef, useCallback, useEffect } from 'react';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from 'reactstrap';
import { TiDelete } from 'react-icons/ti';
import { toast } from 'react-hot-toast';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import EyeModalOrders from '~/components/EyeModalOrders';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import api from '~/services/api';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  CancelButton,
  OrdersInfo,
  Badge,
  InfoDiv,
} from '../styles';
import { ButtonCancel, DisplayNoneImprimir } from './styles';

import { useAuth } from '~/context/AuthContext';
import { useOrder } from '~/context/orderContext';
import formatCompleteDate from '~/services/formatCompleteDate';

import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import apiClube from '~/services/apiClube';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function OrderNew({
  item,
  handlePrintItem,
  handleCancelItem,
  setItemIfoodId,
}) {
  const { user } = useAuth();
  const { moveOrderToCanceled } = useOrder();
  const componentRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [btnDisable, serBtnDisable] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersNotGrouped, setOrdersNotGrouped] = useState([]);
  const [printLoading, setPrintLoading] = useState(false);
  const [loadingCancelItem, setLoadingCancelItem] = useState(false);
  const hasKey = user.has_order_cancel_password;

  const getOrders = useCallback(async () => {
    const group = false;
    try {
      setPrintLoading(true);
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
      setPrintLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getOrdersNotGrouped = useCallback(async () => {
    try {
      setPrintLoading(true);
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}?group=false`
      );

      setOrdersNotGrouped(response.data);
      setPrintLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const toggle = () => setModal(!modal);

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => {
    setModalDelete(!modalDelete);
    serBtnDisable(!btnDisable);
  };

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const userName = item?.buyer ? item?.buyer?.phone : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.ifood_phone
      ? item?.buyer?.ifood_phone
      : item?.buyer?.phone
    : item?.waiter?.name;

  async function closeClick() {
    try {
      if(user.print_bills_delivery_on_web){
        await api.post('restaurants/printers/printer-queue', {
          order_basket_id: item.basket.id,
          order_type: 'new_order',
        });
      }

      handlePrintItem(item.basket.id, item.basket.ifood_id, item?.table?.table_type === 'delivery');

      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'accepted');
      }
    } catch (error) {
      toast.error('Erro ao aceitar pedido.');
    }
  }

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }
    if (table.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }
    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    return `Mesa  ${table.table_number}`;
  }

  useEffect(() => {
    if (modalDelete) {
      getOrdersNotGrouped();
    }
  }, [getOrdersNotGrouped, modalDelete, modal]);

  return (
    <Pedido>
      <ContentBody>
        {item?.table.table_type !== 'delivery' && (
          <div>
            <OrderStatusTag
              text="Novo - Pendente"
              color="#2EC9B7"
              reversed
              width="147px"
            />
          </div>
        )}

        <InfoDiv>
          {item?.table.table_type === 'delivery' ? (
            <>
              <OrderNumber>#{item?.attendance_password}</OrderNumber>
              <Time>
                {item?.scheduled_to
                  ? formatCompleteDate(item?.scheduled_to)
                  : formatCompleteDate(item?.basket.start_time)}
              </Time>
            </>
          ) : (
            <>
              <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
              <Time>
                {item?.scheduled_to
                  ? formatCompleteDate(item?.scheduled_to)
                  : formatCompleteDate(item?.basket.start_time)}
              </Time>
            </>
          )}
          <img
            src={item.basket.ifood_id ? logoIfood : logoTakeat}
            style={{ width: item.basket.ifood_id === null ? 20 : 40 }}
          />
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            {item?.table.table_type === 'command' &&
            item?.table.table_type !== 'delivery' ? (
              `Comanda  ${item.table.table_number} / Mesa ${
                item?.basket?.command_table_number || 'não informada'
              } `
            ) : (
              <>
                {item?.basket?.ifood_table
                  ? item?.basket?.ifood_table
                  : searchTableName(item?.table)}
              </>
            )}
          </TableNumber>
          {item?.basket?.schedule && (
            <PhoneNumber>
              <strong>{item?.basket?.schedule}</strong>
            </PhoneNumber>
          )}{' '}
          {item?.table.table_type === 'delivery' ? (
            <PhoneNumber>{userNameDelivery}</PhoneNumber>
          ) : (
            <PhoneNumber>{userName}</PhoneNumber>
          )}
          {item?.buyer?.name && <PhoneNumber>{item?.buyer?.name}</PhoneNumber>}
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            {user.is_printed_on_web ? (
              <ReactToPrint
                onBeforeGetContent={async () => {
                  await getOrders();
                }}
                trigger={() => (
                  <AcceptButton>
                    {!printLoading ? (
                      <div>
                        <AiOutlinePrinter
                          size={17}
                          style={{ marginRight: 3 }}
                        />
                        Aceitar
                      </div>
                    ) : (
                      <Spinner size="sm" />
                    )}
                  </AcceptButton>
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  handlePrintItem(
                    item.basket.id,
                    item.basket.ifood_id,
                    item?.table?.table_type === 'delivery'
                  );
                  if (!item.basket.ifood_id) {
                    handleNotifyWhatsapp(item, orders, user, 'accepted');
                  }
                }}
              />
            ) : (
              <>
                {item?.table?.table_type === 'delivery' && user.print_bills_delivery_on_web ? (
                  <ReactToPrint
                    onBeforeGetContent={async () => {
                      await getOrders();
                    }}
                    trigger={() => (
                      <AcceptButton>
                        {!printLoading ? (
                          <div>
                            <AiOutlinePrinter
                              size={17}
                              style={{ marginRight: 3 }}
                            />
                            Aceitar
                          </div>
                        ) : (
                          <Spinner size="sm" />
                        )}
                      </AcceptButton>
                    )}
                    content={() => componentRef.current}
                    onAfterPrint={() => {
                      handlePrintItem(
                        item.basket.id,
                        item.basket.ifood_id,
                        item?.table?.table_type === 'delivery'
                      );
                      if (!item.basket.ifood_id) {
                        handleNotifyWhatsapp(item, orders, user, 'accepted');
                      }
                    }}
                  />
                ) : (
                  <AcceptButton onClick={closeClick}>
                    <div>
                      <AiOutlinePrinter size={17} />
                      Aceitar
                    </div>
                  </AcceptButton>
                )}
              </>
            )}
            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} />
                Detalhes
              </div>
            </SeeButton>

            {item?.table?.table_type === 'delivery' ? (
              <CancelButton
                onClick={() => {
                  setItemIfoodId(item.basket.ifood_id);
                  toggleDeleteDelivery();
                }}
                disabled={btnDisable}
              >
                <div>
                  <TiDelete color="#fff" size={17} />
                  Cancelar
                </div>
              </CancelButton>
            ) : (
              <CancelButton onClick={toggleDelete} disabled={btnDisable}>
                <div>
                  <TiDelete color="#fff" size={17} />
                  Cancelar
                </div>
              </CancelButton>
            )}
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeModalOrders item={item} />
          </Container>
        </ModalBody>
      </Modal>

      <DisplayNoneImprimir>
        {/* console.log("aqqqq")} {console.log(item) */}
        <PrintNewOrderComponent
          ref={componentRef}
          restaurant={user}
          item={item}
          handlePrintItem={handlePrintItem}
          orders={orders}
        />
      </DisplayNoneImprimir>

      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'pending',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="pending"
              orders={ordersNotGrouped}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={async () => {
              setLoadingCancelItem(true);
              await handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'pending',
                ifood_id: item.basket.ifood_id,
              });
              setLoadingCancelItem(false);
            }}
            disabled={loadingCancelItem}
            type="button"
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>
    </Pedido>
  );
}
