import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import { MdDelete, MdEdit } from 'react-icons/md';
import { Collapse, Input, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  endOfDay,
  format,
  startOfDay,
  subHours,
} from 'date-fns';
import { DownloadButton } from 'ui-kit-takeat';

import {
  Container,
  Header,
  StyledLink,
  MotoboysTable,
  Delete,
  Actions,
  MotoboyRow,
  DetailHeader,
  DisplayNoneImprimir,
  MotoboysDetailsTable,
} from './styles';

import RegisterButton from '~/components/Buttons/RegisterButton';
import { generateSheet } from '~/services/SheetGenerate/generateMotoboysSheet';
import FileSaver from 'file-saver';

import api from '~/services/api';
import CreateMotoboy from './Create';
import { useMotoboys } from '~/context/MotoboysContext';
import { useAuth } from '~/context/AuthContext';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { VideoPlayer } from '~/components/VideoPlayer';
import { FaAngleDown, FaCircle, FaPrint } from 'react-icons/fa';
import PrintMotoboysDetails from '~/components/Prints/PrintMotoboysDetails';
import { FullCalendar } from 'ui-kit-takeat';
function Motoboy() {
  const [isModalRegisterMotoboyOpen, setIsModalRegisterMotoboyOpen] = useState(
    false
  );
  const [loadingMotoboys, setLoadingMotoboys] = useState(false)

  const [initialDate, setInitialDate] = useState(startOfDay(subHours(new Date(), 3)));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  const {

    detailedMotoboy,
    getDetailedMotoboy,
  } = useMotoboys();
  const { user } = useAuth();

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'DeliveryMotoboys'}`
      );

      const { can_read } = response.data.DeliveryMotoboys;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);



  function toggleModalRegisterMotoboy() {
    setIsModalRegisterMotoboyOpen(!isModalRegisterMotoboyOpen);
    setTimeout(() => {
      return document.getElementById('input_motoboy_name')?.focus();
    }, 1);
  }



  async function handleDelete(id) {
    try {
      if (window.confirm('Tem certeza que deseja deletar este motoboy?')) {
        await api.delete(`/restaurants/motoboys/${id}`);

        getDetailedMotoboy(initialDate, endDate)

        toast.success('Motoboy deletado com sucesso!');
      }
    } catch (error) {
      toast.error('Erro ao deletar motoboy');
    }
  }

  const componentRef = useRef()

  const getDetailedMotoboys = useCallback(async () => {
    setLoadingMotoboys(true)

    await getDetailedMotoboy((initialDate).toISOString(), (endDate).toISOString());

    setLoadingMotoboys(false)
  }, [getDetailedMotoboy, initialDate, endDate,])

  useEffect(() => {
    getDetailedMotoboys()
  }, [initialDate, endDate, getDetailedMotoboys]);

  const [motoboySelected, setMotoboySelected] = useState({})
  const [motoboyPrint, setMotoboyPrint] = useState({})

  function toggleMotoboySelected(motoboy) {
    if (motoboy.id === motoboySelected.id) {
      setMotoboySelected({})
    } else {
      setMotoboySelected({ phone: motoboy.phone, id: motoboy.id })
    }
  }

  function toggleMotoboyPrint(motoboy) {

    setMotoboyPrint({ phone: motoboy.phone, id: motoboy.id })

  }

  const handleDownload = async () => {
    try {
      const sheet = await generateSheet(detailedMotoboy);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Motoboys.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };


  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ marginTop: 24 }}>
              <strong>Motoboys</strong>
            </h2>
            <VideoPlayer id={72} />
          </div>
          <p style={{ marginBottom: 30 }}>Gerencie os motoboys de seu estabelecimento!</p>
        </div>

        <RegisterButton onClick={toggleModalRegisterMotoboy} style={{ marginTop: 25 }}>
          Adicionar
        </RegisterButton>
      </Header>
      <Header style={{ marginTop: '-30px' }}>
        <div style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
          <DownloadButton
            clickFunction={() => handleDownload()}
          />
          <div style={{ marginLeft: '10px' }}>
            <FullCalendar
              onDateSelected={(e) => {
                setInitialDate(e.start);
                setEndDate(e.end);
              }}
              value={{ start: new Date(), end: null }}
            />
            {loadingMotoboys && <Spinner style={{ marginLeft: 9 }} />}
          </div>
        </div>
      </Header>


      <Modal
        isOpen={isModalRegisterMotoboyOpen}
        toggle={toggleModalRegisterMotoboy}
      >
        <ModalHeader>Cadastrar Motoboy</ModalHeader>
        <ModalBody>
          <CreateMotoboy
            toggleModalRegisterMotoboy={toggleModalRegisterMotoboy}

          />
        </ModalBody>
      </Modal>

      <MotoboysTable borderless>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Telefone</th>
            <th>Entregas</th>
            <th>Arrecadou</th>
            <th>Ações</th>
          </tr>

          <tr style={{ background: "#f4f4f4", borderRadius: "7px" }}>
            <td style={{ padding: 20, fontWeight: "bold" }}>Total</td>
            <td style={{ fontWeight: "bold" }}>-</td>
            <td style={{ fontWeight: "bold" }}>{detailedMotoboy && detailedMotoboy?.length > 0 && detailedMotoboy.filter(item => item.sessions && item.sessions.length > 0).map(motoboy => motoboy?.sessions?.length).reduce((accum, curr) => accum + curr, 0)}</td>
            <td style={{ fontWeight: "bold" }}>R$ {parseFloat(detailedMotoboy && detailedMotoboy?.length > 0 && detailedMotoboy.filter(motoboy => motoboy?.sessions).map(item => item.sessions.reduce(
              (tot, s) => tot + parseFloat(s.delivery_tax_price),
              0
            )).reduce((accum, curr) => accum + curr, 0)).toFixed(2)}</td>
            <td style={{ fontWeight: "bold", textAlign: "right", paddingRight: 16 }}>-</td>
          </tr>
        </thead>
        <tbody>

          {detailedMotoboy && detailedMotoboy.length > 0 ? detailedMotoboy?.map((motoboy) => (
            <>

              <MotoboyRow
                key={motoboy.id}
              >
                <td onClick={() => {
                  toggleMotoboySelected(motoboy)
                }
                }
                  style={{ cursor: "pointer", fontWeight: motoboySelected.id === motoboy.id ? "bold" : "normal" }}>
                  <FaAngleDown style={{
                    transform: motoboySelected.id === motoboy.id ? "rotate(-90deg)" : "rotate(0deg)"
                  }} /> {motoboy.name}</td>
                <td>{motoboy.phone || ' - '}</td>
                <td>{motoboy.sessions?.length || 0}</td>
                <td>
                  R${' '}
                  {motoboy.sessions
                    ?.reduce(
                      (tot, s) => tot + parseFloat(s.delivery_tax_price),
                      0
                    )
                    .toFixed(2) || "0.00"}
                </td>

                <Actions>
                  <ReactToPrint
                    onBeforeGetContent={async () => {
                      await toggleMotoboyPrint(motoboy)
                    }}
                    trigger={() => (
                      <FaPrint style={{ marginRight: 10 }} color='#FF2C3A' size={20} cursor="pointer" title='Imprimir relatório' />
                    )}
                    content={() => componentRef.current}
                  />
                  <StyledLink
                    to={{
                      pathname: '/delivery/motoboys/edit',
                      state: {
                        motoboy,
                      },
                    }}
                  >
                    <MdEdit size={20} />
                  </StyledLink>
                  <Delete onClick={() => handleDelete(motoboy.id)}>
                    <MdDelete size={20} />
                  </Delete>
                </Actions>

              </MotoboyRow>

              <Collapse isOpen={motoboySelected.id === motoboy.id} tag="tr">
                <td colSpan={5}>
                  <MotoboysDetailsTable style={{ width: "100%" }}>
                    {motoboy?.sessions === false ? (
                      <thead>
                        <tr>
                          <DetailHeader colSpan={4}>
                            Não houveram entregas desse motoboy nesse período
                          </DetailHeader>
                        </tr>
                      </thead>
                    ) : (
                      <>
                        <thead>
                          <tr>
                            <th>Cliente</th>
                            <th>Bairro</th>
                            <th>Data</th>
                            <th>Taxa</th>

                          </tr>
                        </thead>
                        <tbody>
                          {motoboy?.sessions
                            ?.sort((a, b) => b.created_at?.localeCompare(a.created_at))
                            .map((session) => {
                              const date = session.created_at
                                ? format(new Date(session.created_at), 'HH:mm - dd/MM/yy')
                                : session.createdAt
                                  ? format(new Date(session.createdAt), 'HH:mm - dd/MM/yy')
                                  : ' - ';
                              return (
                                <tr key={session.id}>
                                  <td>
                                    {session?.buyer_name ||
                                      session?.buyer_phone}
                                  </td>
                                  <td>{session?.neighborhood}</td>
                                  <td>{date}</td>
                                  <td>R${session.delivery_tax_price}</td>
                                  {/* <td style={{ textAlign: 'right' }}>
                                    
                                  </td> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      </>
                    )}
                  </MotoboysDetailsTable>
                </td>
              </Collapse>
            </>
          )) :
            <tr>
              <DetailHeader colSpan={4}>
                Não houveram entregas de motoboys nesse período
              </DetailHeader>
            </tr>
          }
        </tbody>
      </MotoboysTable>

      <DisplayNoneImprimir>
        <PrintMotoboysDetails
          item={detailedMotoboy}
          restaurant={user}
          ref={componentRef}
          motoboy={motoboyPrint}
          dates={{ initialDate, endDate }}
        />
      </DisplayNoneImprimir>
    </Container>
  );
}

export default Motoboy;
