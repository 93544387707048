import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Container, Row, Col } from 'reactstrap';
import ReactInputMask from 'react-input-mask';
import getValidationErrors from '../../../services/getValidationErrors';
import { useAuth } from '../../../context/AuthContext';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import SaveButton from '~/components/Buttons/SaveButton';
import { useMotoboys } from '~/context/MotoboysContext';

function CreateMotoboy({ toggleModalRegisterMotoboy }) {
  const formRef = useRef(null);
  const { getProfileData } = useAuth();
  const { setDetailedMotoboy } = useMotoboys();
  const input = document.getElementById('input_motoboy_name')?.focus();

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        phone: Yup.string()
          .min(13, 'O número deve ter DDD + 9 dígitos')
          .max(13, 'O número deve ter DDD + 9 dígitos'),
      });

      const req = {
        name: data.name,
      };

      if (data.phone.length > 0) {
        data.phone = data.phone.split(/^\(|\)|-|\s/).join('');
        data.phone = `(${data.phone.substring(0, 2)})${data.phone.substring(
          2
        )}`;

        req.phone = data.phone;
      } else {
        delete data.phone;
      }

      await schema.validate(data, {
        abortEarly: false,
      });

      const createdMotoboy = await api.post('restaurants/motoboys', req);

      toast.success('Motoboy criado com sucesso!');

      setDetailedMotoboy((state) => { return [...state, createdMotoboy.data] });

      toggleModalRegisterMotoboy();
      getProfileData();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response?.ok) {
        console.log('error motoboy: ', error);
        switch (error.response?.data?.errorType) {
          case 'phone_already_exists':
            toast.error('Telefone já usado por outro motoboy');
            break;
          case 'restaurant_not_found':
            toast.error('Restaurante não foi encontrado');
            break;
          default:
            console.log('motoboy err ', error);
            toast.error('Falha ao criar motoboy');
        }
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Container>
        <Row>
          <Col sm="12">
            <Input
              label="Nome do Motoboy:"
              name="name"
              type="text"
              id="input_motoboy_name"
            />
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <ReactInputMask mask="(99) 99999-9999">
              <Input label="Telefone do Motoboy:" name="phone" type="text" />
            </ReactInputMask>
          </Col>
        </Row>
        <SaveButton type="submit" style={{ float: 'right' }}>
          Cadastrar
        </SaveButton>
      </Container>
    </Form>
  );
}

export default CreateMotoboy;
