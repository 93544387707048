import toast from 'react-hot-toast';
import api from '../services/api';

export async function handleNotifyWhatsapp(item, orders, user, status) {
  if (
    user.delivery_info.notify_whatsapp &&
    item?.table?.table_type === 'delivery'
  ) {
    const userPhone = item?.buyer?.phone;

    if (user.use_wpp) {
      const textWpp = getDeliveryText(status, orders, item, user);

      const textSent = await api.post('/restaurants/uzapi/delivery-msg', {
        text: textWpp,
        phone: userPhone,
        token: user.name,
      });

      console.log('Notify whatsapp response > ', textSent.data);

      if (textSent.data.status === 400 || textSent.data.status === 'error') {
        if (textSent.data.status === 'error') {
          toast.error(textSent.data.message, { duration: 5000 });
        } else {
          toast.error(
            'Whatsapp está desconectado, essa mensagem deverá ser enviada manualmente',
            { duration: 5000 }
          );

          const text = getDeliveryText(status, orders, item, user).replace(
            /\n/g,
            '%0a'
          );

          window.open(
            `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`,
            'wppTakeat'
          );
        }
      } else {
        toast.success('Whatsapp informando PEDIDO ACEITO enviado para cliente');
      }
    } else {
      const text = getDeliveryText(status, orders, item, user).replace(
        /\n/g,
        '%0a'
      );

      window.open(
        `https://api.whatsapp.com/send?phone=55${userPhone}&text=${text}`,
        'wppTakeat'
      );
    }
  }
}

const getDeliveryText = (status, orders, item, user, is_wpp) => {
  const userName = item?.buyer?.name.split(' ')[0];

  const userAdress =
    `${item?.buyer.delivery_address?.street || ''} ${item?.buyer.delivery_address?.number || ''
    } - ${item?.buyer.delivery_address?.city || ''} - ${item?.buyer.delivery_address?.state || ''
    } - ${item?.buyer.delivery_address?.zip_code || ''}` || '';

  const userPaymentMethod = item?.intended_payment_method?.name || '';

  if (status === 'accepted') {
    const productsWpp = orders.map((prod) => {
      return `\n*${prod.amount}x - ${prod.product.name} - R$ ${parseFloat(
        prod.total_price
      )?.toFixed(2)}*\n${prod.complement_categories?.length > 0
        ? prod.complement_categories
          .map((complement_cat) => {
            return `    - ${complement_cat.complement_category.name}\n${complement_cat.order_complements.length > 0 &&
              complement_cat.order_complements
                .map((comp) => {
                  return `    ${comp.amount}x - ${comp.complement.name} - R$${comp.complement.price}\n`;
                })
                .join('')
              }`;
          })
          .join('')
        : ''
        }`;
    });

    const fantasyNameChanged = user?.fantasy_name.replace("&", "%26")

    return `Olá${userName && ', ' + userName}! Aqui é do *${fantasyNameChanged
      }*.\nO seu pedido já foi aceito e está em preparo!\n\n*Itens:*${productsWpp.join(
        ''
      )}\nTotal com Taxa (se houver): *R$${(
        Number(item?.basket.total_service_price) +
        Number(item?.delivery_tax_price) -
        Number(item?.coupon_discount ? item?.coupon_discount : 0)
      )?.toFixed(2)}*\n\n*Endereço:* ${userAdress}\n\n*Pagamento:* ${userPaymentMethod || 'Não informado'
      }`;
  } else if (status === 'ready') {
    const fantasyNameChanged = user?.fantasy_name.replace("&", "%26")
    return item?.with_withdrawal
      ? `O seu pedido está pronto${userName && ', ' + userName
      }! 😋\nVocê pode vir retirar no local, estaremos aguardando por você!\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`
      : `O seu pedido está pronto${userName && ', ' + userName
      }! 😋\nO nosso motoboy está a caminho e em breve ele chegará ao seu endereço.\n\nAgradecemos pela preferência e paciência. Em caso de qualquer dúvida, estamos à disposição. ❤️`;
  } else if (status === 'finished') {
    const fantasyNameChanged = user?.fantasy_name.replace("&", "%26")
    return `*Obrigado pela preferência, ${userName}!*\n\nSempre que precisar, nossa equipe estará a disposição.\n\nConte para nós, de 1 a 10, o que você achou da experiência de comprar no *${fantasyNameChanged}* ?\n\nAté a próxima! ☺️`;
  }
};
