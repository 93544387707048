import React, { useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import { FiTrash } from 'react-icons/fi';

import { ActionButton, DownloadButton } from 'ui-kit-takeat';
import { TiPlus, TiTrash } from 'react-icons/ti';

export default function NfeAvulsaItem({
  item,
  itens,
  setItens,
  key,
  number
}) {
  console.log('kkkk', number);
 const [itemOptions, setItemOptions]= useState([
   {label: 'Código Produto', value: 'codigo_produto'},
   {label: 'Descrição', value: 'descricao'},
   {label: 'CFOP', value: 'cfop'},
   {label: 'NCM', value: 'codigo_ncm'},
   {label: 'Unidade Comercial', value: 'unidade_comercial'},
   {label: 'Unidade Tributavel', value: 'unidade_tributavel'},
   {label: 'Quantidade Comercial', value: 'quantidade_comercial'},
   {label: 'Quantidade Tributavel', value: 'quantidade_tributavel'},
   {label: 'Valor Unitário Comercial', value: 'valor_unitario_comercial'},
   {label: 'Valor Unitario Tributavel', value: 'valor_unitario_tributavel'},
   {label: 'Valor Bruto', value: 'valor_bruto'},
   {label: 'PIS Situação Tributaria', value: 'pis_situacao_tributaria'},
   {label: 'Cofins Situação Tributaria', value: 'cofins_situacao_tributaria'},
   {label: 'ICMS Situação Tributaria', value: 'icms_situacao_tributaria'},
   {label: 'ICMS Origem', value: 'icms_origem'},
   {label: 'ICMS Valor', value: 'icms_valor'},
   {label: 'ICMS Aliquota', value: 'icms_aliquota'},
   {label: 'ICMS Base Calculo', value: 'icms_base_calculo'},
   {label: 'ICMS Modalidade Base Calculo', value: 'icms_modalidade_base_calculo'},
   {label: 'CEST', value: 'cest'},
 ]);

 const [thisItens, setThisItens] = useState([]);

 const [selectValue, setSelectValue] = useState({label: 'Código Produto', value: 'codigo_produto'});
 const [inputValue, setInputValue] = useState('');


 useEffect(e => {
  let allItens = itens;

  let toJson = `{"numero_item":"${item.numero_item}"`;
  thisItens.map(it => {
    toJson = toJson + `,"${it.name}":"${it.value}"`
  });
  toJson = toJson + '}';
  const js = JSON.parse(toJson);

  allItens.map(it => {
    if(it.numero_item === item.numero_item){
      it.dataValues = js;
    }
  });

  setItens(allItens);
 }, [thisItens, item]);

  return (
    <div style={{padding: 15}}>
      <Form>
        <Row>
          <Col md="1" style={{marginTop: 15}}>
            <strong>Item {Number(number) + 1}) </strong>
          </Col>
          <Col md="4">
            <Select 
              options={itemOptions}
              name="option"
              value={selectValue}
              onChange={e => {
                setSelectValue(e);
                // setThisItens([...thisItens, {value: e.label, value: 'teste'}])
              }}
            />
          </Col>
          <Col md="5">
            <Input 
              name={`input_name_${item?.numero_item}`}
              id={`input_name_${item?.numero_item}`}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </Col>
          <Col md="2">
            {/* <ActionButton
              name="add_campo_item"
              icon={<TiPlus />}
              onClick={e =>  {
                setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                setItemOptions(itemOptions.filter(it => it.value !== selectValue.value));
                setSelectValue(itemOptions.filter(it => it.value !== selectValue.value)[0] || []);
                setInputValue('');
              }}
              disabled={itemOptions.length === 0}
              style={{marginTop: 10}}
            >+</ActionButton> */}

                <button name="add_campo_item" 
                  onClick={e =>  {
                    setThisItens([...thisItens, {label: selectValue.label, name: selectValue.value, value: inputValue}]);
                    setItemOptions(itemOptions.filter(it => it.value !== selectValue.value));
                    setSelectValue(itemOptions.filter(it => it.value !== selectValue.value)[0] || []);
                    setInputValue('');
                    const hasDoc = document.getElementById(`input_name_${item.numero_item}`);
                    if(hasDoc){
                      hasDoc.focus();
                    }
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 40,
                    width: 40,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: '#2AB7A6',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 20,
                    fontWeight: 600
                  }}
                  disabled={itemOptions.length === 0}
                > + </button>

              {item.numero_item !== 1 &&
                <button
                  name="remove_campo_item"
                  onClick={e =>  {
                    setItens(itens.filter(it => it.numero_item !== item.numero_item));
                  }}
                  type="button"
                  style={{
                    borderRadius: '25px',
                    height: 40,
                    width: 40,
                    border: 'none',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    background: 'red',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: 10,
                    marginLeft: 15
                  }}
                > <FiTrash size={17}/> </button>
              }
          </Col>
        </Row>
        <div>
          {thisItens.map(it => (
            <Row style={{textAlign: 'left', paddingLeft: 20}}>
              <Col md="1" style={{textAlign: 'right'}}>
                <FiTrash size={16} color="red" style={{cursor: 'pointer'}} onClick={e => {
                  setThisItens(thisItens.filter(th => th.name !== it.name));
                  setItemOptions([...itemOptions, {label: it.label, value: it.name}]);
                  setSelectValue([...itemOptions, {label: it.label, value: it.name}][0] || []);
                }}/>
              </Col>
              <Col md="11">
                <strong>{it.label}:</strong> {it.value} 
              </Col>
              {/* <Col md="6">
                {it.value}  
              </Col> */}
            </Row>
          ))}
        </div>
      </Form>
    </div>
  );
}
