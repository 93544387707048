import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 30px;
`;

export const Header = styled.div`
  margin: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  margin-top: 30px;
  color: #fe1933;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    filter: contrast(1);
    text-decoration: underline;
    color: red;
  }
`;

export const RestaurantInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;

  margin-top: -5px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  span {
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const Card = styled.div`
  margin: 20px auto;
  width: 90%;
  display: flex;
  padding: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Bloco = styled.div`
  margin: 20px auto;
  width: 32%;
  height: 500px;
  padding: 30px;
  display: flex;
  float: left;
  margin-right: 10px;
  background: red;
  border-radius: 8px;
`;

export const LugarPedidos = styled.div`
  display: flex;
  background-color: white;
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)); */
  border-top: none;
  margin-top: -7px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
