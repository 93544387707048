import React, { useState, useCallback } from 'react';
import { FaTrash } from 'react-icons/fa';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';

import {
  Container,
  HistoryTable,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import api from '~/services/api';
import toast from 'react-hot-toast';
import { AiFillEdit } from 'react-icons/ai';
import { Form } from '@unform/web';
import Input from '~/components/Form/Input';
import { DownloadButton } from 'ui-kit-takeat';
import { generateSheet } from '~/services/SheetGenerate/generateBankEventSheet';
import FileSaver from 'file-saver';

function Bank({ bank, handleDeleteBank, setBanks, getBanks }) {
  const [modalEye, setModalEye] = useState(false);
  const [events, setEvents] = useState([]);

  const toggleEye = useCallback(async () => {
    if(!modalEye){
      try{
        const response = await api.get(`/restaurants/cash-flows/bank-accounts/events/${bank.id}`);
        setEvents(response.data);
        setModalEye(!modalEye);
      }catch(err){
        toast.error('Erro ao carregar eventos desse banco.')
      }
    }else{
      setModalEye(!modalEye);
    }
  }, [modalEye, bank.id]);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = useCallback(async () => {
    setModalEdit(!modalEdit);
  }, [modalEdit]);

  const [loading, setLoading] = useState(false);

  function getType(type){
    switch(type){
      case 'transfer_funds':
        return 'Transferência de fundos';
      case 'cash_flow':
        return 'Lançamento';
      case 'cash_flow_earning':
        return 'Conta a receber';
      case 'cash_flow_not_earning':
        return 'Conta a pagar';
      case 'manual_edit':
        return 'Conferência de saldo';
    }
  }

  const handleEdit = useCallback(async (data) => {
    setLoading(true);
    try{
      let body = {
        name: data.name
      };

      const value = data.value?.replace(',','.');
      if(value !== '' && value !== ' '){
        body.total = parseFloat(value);
      }
      
      const response = await api.put(`/restaurants/cash-flows/bank-accounts/${bank.id}`, body);
      // setBanks(response.data);
      getBanks();
      setModalEdit(false);
    }catch(err){
      toast.error('Erro ao editar esse banco.');
    }
    setLoading(false);
  }, [bank.id]);

  const handleDownload = async () => {
    try {
      const sheet = await generateSheet(bank?.name, events);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Conciliacao bancaria - ${bank?.name}.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  return (
    <>
      <tr>
        <td style={{ cursor: 'pointer' }} onClick={() => toggleEye()}>
          <strong>{bank?.name}</strong> - R${parseFloat(bank?.total).toLocaleString('pt-br', { minimumFractionDigits: 2})}
        </td>

        <td>
          <AiFillEdit
            title="Editar"
            size={17}
            style={{ cursor: 'pointer', marginRight: 15 }}
            onClick={() => {
              toggleEdit();
            }}
          />
          <FaTrash
            color="#ff403d"
            title="Deletar Banco"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleDeleteBank(bank?.id);
            }}
          />
        </td>
      </tr>

      <Modal isOpen={modalEye} toggle={toggleEye} size="xl">
        <ModalHeader>Histórico de Eventos</ModalHeader>
        <ModalBody>
          <Container>
            {/* <DataPickerArea>
              <DatePickerStyled
                selected={monthYearDate}
                onChange={(date) => {
                  setMonthYearDate(date);
                }}
                locale="pt"
                showMonthYearPicker
                customInput={
                  <SelectDateButton
                    className="btn-round mr-auto"
                    color="info"
                  >
                    Mês/Ano <MdArrowDropDown color="white" size={20} />
                  </SelectDateButton>
                }
              />
            </DataPickerArea>
            <p>
              Exibindo: {months[monthYearDate.getMonth()]}/
              {monthYearDate.getFullYear()}
            </p> */}
            <div style={{marginBottom: 10}}>
              <DownloadButton
                clickFunction={() => handleDownload()}
              />
            </div>
            <HistoryTable> 
              <thead>
                <tr>
                  <th>Valor</th>
                  <th>Saldo</th>
                  <th>Tipo</th>
                  <th>Método</th>
                  <th>Criado</th>
                  <th>Referente</th>
                  <th>Usuário</th>
                </tr>
              </thead>
              <tbody>
                {events?.map((event) =>
                  <tr style={event.operation === '-' ? { background: '#FBD1C5', color: '#590D12' } : { background: '#D7EED2', color: '#1E3422' }}>
                    <td>
                      <strong>{event.operation} R${parseFloat(event.value).toLocaleString('pt-br', { minimumFractionDigits: 2})}{' '}</strong>
                    </td>
                    <td>
                      {event.balance ? `R$${parseFloat(event.balance).toLocaleString('pt-br', { minimumFractionDigits: 2})}` : '-'}
                    </td>
                    <td>
                      {getType(event.type)}
                    </td>
                    <td>
                      {event.payment_method?.name || '-'}
                    </td>
                    <td>{formatCompleteDate(event?.createdAt)}</td>
                    <td>{formatCompleteDate(event?.due_date)}</td>
                    <td>
                      {event?.user?.name}
                    </td>
                  </tr>
                )}
              </tbody>
            </HistoryTable>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleEye} style={{marginLeft: 'auto'}}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEdit} toggle={toggleEdit} size="sm">
        <ModalHeader>Conferência de saldo</ModalHeader>
        <Form onSubmit={handleEdit}>
        <ModalBody>
          <Row>
            <Col>
              <Input label="Nome do banco" name="name" defaultValue={bank.name}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input 
                label="Valor"
                type="text"
                name="value"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleEdit}>
            Fechar
          </Button>
          <Button color="success" type="submit" style={{marginLeft: 'auto'}} disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Bank;