import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  subDays,
  differenceInDays,
} from 'date-fns';
import {
  Row,
  Col,
  Collapse,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { Input } from '@material-ui/core';
import { FaQuestionCircle, FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import {
  Card,
  Container,
  Header,
  DateDiv,
  DateSelectorDiv,
  SubmitDate,
  SelectDateButton,
  TableReport,
  DataPickerArea,
  DatePickerStyled,
  ButtonClose,
  RegisterButtonPersonal,
  ButtonSubmit,
} from './styles';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { useAuth } from '~/context/AuthContext';
import ButtonDefault from '~/components/Buttons/ButtonDefault';
import InputReact from '~/components/Form/Input';
import getValidationErrors from '~/services/getValidationErrors';

function ReportsDiscount() {
  const {
    removediscountObs,
    restaurantDiscountObs,
    getDiscountObs,
    updateDiscountObs,
  } = useAuth();
  const [rangeOn, setRangeOn] = useState(true);

  const [discounts, setDiscounts] = useState([]);
  const formRef = useRef(null);

  const oldTotal = parseFloat(
    discounts.reduce((acc, d) => acc + parseFloat(d.old_total_price), 0)
  );
  const total = parseFloat(
    discounts.reduce((acc, d) => acc + parseFloat(d.total_price), 0)
  );
  const discountTotal = parseFloat(
    discounts.reduce((acc, d) => acc + parseFloat(d.discount_total), 0)
  );

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm");
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(
      format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setYesterday() {
    setRangeOn(true);
    const inicial = startOfDay(subDays(new Date(), 1));
    const final = endOfDay(subDays(new Date(), 1));

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      format(
        startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
    );
    setFinalDate(
      format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss")
    );

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
      const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
      const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
      const phrase = `De ${inicialFormat} até ${finalFormat}`;
      setHourPhrase(phrase);
    }
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'ReportsDiscounts'}`
      );

      const { can_read } = response.data.ReportsDiscounts;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const getDiscounts = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/reports/discount', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });
      setDiscounts(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [inicialDate, finalDate]);

  useEffect(() => {
    getDiscounts();
    validateUser();
  }, [getDiscounts, validateUser]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [
    isRestaurantDiscountObsOpened,
    setIsRestaurantDiscountObsOpened,
  ] = useState(false);

  const toggleModalDiscountsObs = () =>
    setIsRestaurantDiscountObsOpened(!isRestaurantDiscountObsOpened);

  const [
    isCreateRestaurantDiscountObsOpened,
    setIsCreateRestaurantDiscountObsOpened,
  ] = useState(false);

  const toggleCreateModalDiscountsObs = () =>
    setIsCreateRestaurantDiscountObsOpened(
      !isCreateRestaurantDiscountObsOpened
    );

  useEffect(() => {
    if (isRestaurantDiscountObsOpened) {
      getDiscountObs();
    }
  }, [getDiscountObs, isRestaurantDiscountObsOpened]);

  const formRefsubmit = useRef(null);

  async function handleAddObsDiscount(data) {
    try {
      if (formRefsubmit.current) {
        formRefsubmit.current.setErrors({});
      }

      const schema = Yup.object().shape({
        obs: Yup.string().required('Desconto obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await updateDiscountObs(data.obs);
      toast.success('Desconto cadastrado!');
      toggleCreateModalDiscountsObs();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRefsubmit.current) {
          formRefsubmit.current.setErrors(errors);
        }
      } else {
        toast.error('Erro ao cadastrar desconto.');
      }
    }
  }

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Modal
        isOpen={isRestaurantDiscountObsOpened}
        toggle={toggleModalDiscountsObs}
      >
        <ModalHeader>
          <p style={{ fontWeight: 'bold', fontSize: 16 }}> Descontos</p>
        </ModalHeader>
        <ModalBody>
          {restaurantDiscountObs &&
            restaurantDiscountObs.length > 0 &&
            restaurantDiscountObs.map((justify) => (
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                {justify.obs}{' '}
                <FaTrash
                  color="#ff2c3a"
                  onClick={() => {
                    removediscountObs(justify.id);
                  }}
                  cursor="pointer"
                  title="Deletar o desconto"
                />
              </p>
            ))}
          <RegisterButtonPersonal onClick={toggleCreateModalDiscountsObs}>
            + Adicionar desconto
          </RegisterButtonPersonal>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              width: '100%',
              borderTop: '1px solid #33333330',
              paddingTop: 10,
            }}
          >
            <ButtonClose onClick={toggleModalDiscountsObs}>
              Cancelar
            </ButtonClose>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isCreateRestaurantDiscountObsOpened}
        toggle={toggleCreateModalDiscountsObs}
      >
        <Form onSubmit={handleAddObsDiscount} ref={formRefsubmit}>
          <ModalBody>
            <InputReact name="obs" label="Adicionar desconto" />
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                width: '100%',
                borderTop: '1px solid #33333330',
                paddingTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ButtonClose
                type="button"
                onClick={toggleCreateModalDiscountsObs}
              >
                Cancelar
              </ButtonClose>
              <ButtonSubmit type="submit">Salvar</ButtonSubmit>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Header>
        <div>
          <h2>Relatório de Desconto</h2>
          <p>Acompanhe os descontos do seu estabelecimento!</p>
        </div>
        {/* <BackButton onClick={() => history.goBack()}>Voltar</BackButton> */}

        <ButtonDefault
          label="Descontos cadastrados"
          color="#FF2C3A"
          onClick={toggleModalDiscountsObs}
        />
      </Header>
      <Card>
        <Row>
          <Col md="4">
            <p>{hourPhrase}</p>
          </Col>
          <Col md="8">
            <div style={{ float: 'right', marginBottom: '20px' }}>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setToday}
                color="info"
              >
                Hoje
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setYesterday}
                color="info"
              >
                Ontem
              </SelectDateButton>
              <SelectDateButton
                className="btn-round mr-auto"
                onClick={setWeek}
                color="info"
              >
                Essa semana
              </SelectDateButton>

              <DataPickerArea>
                <DatePickerStyled
                  selected={monthYearDate}
                  onChange={(date) => {
                    setMonthYearDate(date);
                    setMonth(date);
                    setRangeOn(true);
                  }}
                  locale="pt"
                  showMonthYearPicker
                  customInput={
                    <SelectDateButton
                      className="btn-round mr-auto"
                      color="info"
                    >
                      Mês/Ano <MdArrowDropDown color="white" size={20} />
                    </SelectDateButton>
                  }
                />
              </DataPickerArea>

              <SelectDateButton
                className="btn-round mr-auto"
                onClick={toggle}
                color="info"
              >
                Selecionar Período
                <MdArrowDropDown color="white" size={20} />
              </SelectDateButton>
              <FaQuestionCircle
                color="orange"
                title="Selecione o intervalo entre as datas com máximo de 31 dias"
                size={20}
                style={{ marginTop: -45, marginLeft: -5 }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div style={{ float: 'right', marginRight: '15px' }}>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        step="1"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
        </Row>
        <p
          style={{
            textAlign: 'right',
            paddingRight: 20,
            color: 'red',
            display: rangeOn ? 'none' : 'block',
          }}
        >
          Favor selecionar o intervalo máximo de 31 dias entre as datas.
        </p>
        <TableReport borderless>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Data/Hora</th>
              <th>Valor Original</th>
              <th>Valor c/ Desconto</th>
              <th>Desconto (R$)</th>
              <th>%</th>
              <th>Justificativa</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>-</td>
              <td>
                R$
                {oldTotal.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>
                R$
                {total.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td>
                <strong>
                  R$
                  {discountTotal.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </strong>
              </td>
              <td>{((discountTotal / oldTotal) * 100).toFixed(2)}%</td>
              <td>-</td>
              <td>-</td>
            </tr>
            {discounts.map((discount) => (
              <tr key={discount.id}>
                <td>{discount.table.table_number}</td>
                <td>
                  {discount.discount_at
                    ? formatCompleteDate(discount.discount_at)
                    : formatCompleteDate(discount.createdAt)}
                </td>
                <td>
                  R$
                  {parseFloat(discount.old_total_price).toLocaleString(
                    'pt-br',
                    { minimumFractionDigits: 2 }
                  )}
                </td>
                <td style={{ color: 'green' }}>
                  R$
                  {parseFloat(discount.total_price).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td style={{ color: 'red' }}>
                  <strong>
                    R$
                    {parseFloat(discount.discount_total).toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </strong>
                </td>
                <td>{discount.discount_percent}%</td>
                <td style={{ color: 'red' }}>{discount.discount_obs}</td>
                <td>{discount.users?.name}</td>
              </tr>
            ))}
          </tbody>
        </TableReport>
      </Card>
    </Container>
  );
}

export default ReportsDiscount;
