import styled, { css } from 'styled-components';

export const Content = styled.button`
  border-radius: 8px;
  padding: 5px;
  width: 100px;
  color: white;
  margin-right: 0px;
  border: none;
  background: ${(props) => (props.disabled ? 'lightgrey' : '#ff2c3a')};
  font-size: 14px;
  display: flex;
  text-align: center;
  cursor: pointer;

  ${(props) => props.disabled && 'pointer-events: none'};

  justify-content: center;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  p {
    color: #fff;
  }

  ${(props) =>
    props.disable &&
    css`
      background-color: #d9d9d9;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const OperationArea = styled.div`
  width: 100%;
  min-height: 600px;
  max-height: 800px;
  display: flex;
  justify-content: space-between;
  position: relative;

  flex-wrap: wrap;

  @media (max-width: 1500px) {
    height: 600px;
  }
`;
export const OrderSystemArea = styled.div`
  width: 58%;
  max-height: 800px;
  overflow-y: hidden;
  border-right: 1px solid #33333320;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 1500px) {
    height: 600px;
  }
`;
export const BillTableArea = styled.div`
  width: 40%;
  height: 100%;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 20px;
  padding-bottom: 80px;

  h2 {
    padding-left: 8px;
  }

  @media (max-width: 500px) {
    width: 100%;
    overflow-x: auto;
  }

  @media (max-width: 1500px) {
    height: 600px;
  }
`;

export const ButtonsArea = styled.div`
  padding-top: 16px;
  height: 60px;
  border-top: 1px solid #33333330;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  background-color: #fff;
`;

export const AlterButton = styled.button`
  border: none;
  padding: 10px 20px;
  background: ${(props) =>
    props.disabled ? '#D9D9D9' : props.confirm ? '#3BD2C1' : '#FF2C3A'};
  border-radius: 5px;

  ${(props) => props.disabled && 'pointer-events: none'};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0;
  color: #ffffff;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: relative;
  display: block;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const CloseButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #dc3545;

  display: flex;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const ActionsInside = styled.div`
  padding-top: 2px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const CashierButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  color: white;
  font-size: 12px;

  border: none;
  background: #28a745;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ReopenButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;
  color: #000;

  border: none;
  background: #ffc107;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 12px;

  color: white;

  border: none;
  background: #17a2b8;

  display: flex;
  flex-direction: row;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const TablePayment = styled.div`
  border-top: 1px solid #ffffff40;
  height: 40%;
  width: 90%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 24px;

  /* background-color: #fff;
  color: #dc3545; */

  p {
    font-size: 12px;

    & + p {
      margin-top: -10px;
    }
  }
`;

export const ProductsCard = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
`;

export const CardHeader = styled.div`
  background-color: #f4f4f4;
  border-radius: 10px 10px 0 0;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4d4d4c;

  svg {
    color: #ff1f2b;
    margin: 0 10px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

export const CardProduct = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  gap: 10px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4c;
    ${(props) => props.canceled && 'text-decoration: line-through'};
  }

  span:first-child {
    margin-right: auto;
  }
`;

export const CardComplement = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: flex-start;
  align-items: center;
  margin: 0 103px 0 30px;
  gap: 10px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #4d4d4c;
    ${(props) => props.canceled && 'text-decoration: line-through'};
  }

  span:first-child {
    margin-right: auto;
  }
`;

export const ButtonCancel = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-width: 93px;
`;

export const XButton = styled.button`
  width: 30px;
  height: 30px;
  background: #ff2c3a;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmDiv = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4d4d4c;
  min-width: 93px;
  display: flex;
  justify-content: flex-end;

  position: relative;

  span {
    position: absolute;
    top: -24px;
    left: -14px;
    width: 124px;
  }
`;

export const ConfirmationButton = styled.button`
  width: 44px;
  height: 30px;
  background: ${(props) => (props.confirm ? '#3BD2C1' : '#ff2c3a')};
  border-radius: 5px;
  border: none;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
`;

export const CanceledButton = styled.div`
  border: none;
  pointer-events: none;
  background: #d9d9d9;
  border-radius: 5px;
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
`;

export const AddedItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AmountArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;

export const SvgContainer = styled.div`
  color: #ff2c3a;
  border: 2px solid #ff2c3a;
  border-radius: 15px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
