import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px;
`;

export const Content = styled.div`
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #3d3d3d;

  p {
    text-align: center;
  }
`;

export const Header = styled.div`
  width: 95%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 30px;
    font-weight: bold;

    margin-left: 3%;
  }
`;

export const Card = styled.div`
  width: 95%;
  min-height: 730px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)); */
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
