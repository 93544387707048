import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';

import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
  OrderNumber,
  ContentBody,
  TableNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  OrdersInfo,
  Pedido,
  Badge,
} from '../styles';
import { Print, DisplayNoneImprimir } from './styles';

import { useAuth } from '~/context/AuthContext';
import formatTime from '~/services/formatTime';
import EyeBillsTableOperation from '../../Eye/EyeBillsTableOperation';
import PrintBillsTableOperation from '~/components/Prints/PrintBillsTableOperation';
import api from '~/services/api';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function CloseTable({ item, handlePrintItem, printQueueTable }) {
  const [modal, setModal] = useState(false);
  const [bills, setBills] = useState([]);
  const [printBills, setPrintBills] = useState([]);
  const [sessionBills, setSessionbills] = useState([]);
  const [productBills, setProductBills] = useState([]);
  const [commandTableNumber, setCommandTableNumber] = useState();
  const toggle = () => setModal(!modal);

  const { user } = useAuth();
  const componentRef = useRef(null);

  function closeClick() {
    printQueueTable(item);
  }

  const getBillsSession = useCallback(async () => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${item.id}`
    );

    setBills(response.data.bills);
    setPrintBills(response.data.print_bills);
    setSessionbills(response.data.session);
    setProductBills(response.data.products);

    const baskets = [];

    const sorted = baskets.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }

      if (a.id > b.id) {
        return -1;
      }

      return 0;
    });

    setCommandTableNumber(sorted[0]);
  }, [item.id]);

  useEffect(() => {
    if (modal) {
      getBillsSession();
    }
  }, [getBillsSession, modal]);

  return (
    <Pedido>
      <ContentBody>
        <div>
          <OrderStatusTag
            text="Fechamento de mesa"
            color="#FFA814"
            width="180px"
          />
        </div>
        <div>
          <OrderNumber>{formatTime(item.updatedAt)}</OrderNumber>
        </div>
        <div>
          <TableNumber>Mesa {item.table?.table_number}</TableNumber>
        </div>

        <Actions>
          {user.is_printed_on_web || user.print_bills_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getBillsSession();
              }}
              trigger={() => (
                <AcceptButton style={{ margin: 0 }}>
                  <div>
                    <AiOutlinePrinter size={17} style={{ marginRight: 3 }} />
                    Fechar
                  </div>
                </AcceptButton>
              )}
              content={() => componentRef.current}
              onAfterPrint={() => {
                handlePrintItem({
                  session_key: item.key,
                });
                // renderOperation();
              }}
            />
          ) : (
            <AcceptButton onClick={closeClick} style={{ margin: 0 }}>
              <div>
                <AiOutlinePrinter size={17} />
                Fechar
              </div>
            </AcceptButton>
          )}
          <SeeButton onClick={toggle} style={{ margin: 0 }}>
            <div>
              <AiOutlineEye size={17} />
              Detalhes
            </div>
          </SeeButton>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsTableOperation item={item} bills={bills} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            onBeforeGetContent={async () => {
              await getBillsSession();
            }}
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsTableOperation
          item={item}
          restaurant={user}
          ref={componentRef}
          paymentData={item?.payments}
          isOpen={modal}
          printBills={printBills}
          sessionBills={sessionBills}
          productBills={productBills}
          commandTableNumber={commandTableNumber}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
