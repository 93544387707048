/* eslint-disable no-shadow */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-hot-toast';
import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  addHours,
  differenceInDays,
} from 'date-fns';
import { Form } from '@unform/web';
import { MdArrowDropDown } from 'react-icons/md';

import { Row, Col, Collapse } from 'reactstrap';
import { Input } from '@material-ui/core';
import {
  Wrapper,
  Header,
  DateSelectorDiv,
  DateDiv,
  SubmitDate,
  Card,
  Content,
  SelectDateButton,
  DataPickerArea,
  DatePickerStyled,
} from './styles';

import Bills from '~/components/Bills';

import api from '~/services/api';
import UpdateButton from '~/components/Buttons/UpdateButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';

export default function ViewBills() {
  const formRef = useRef(null);

  const params = new URLSearchParams(document.location.search);

  const [activeTab, setActiveTab] = useState(params?.get('isCompleted') === 'true' ? '4' : '1');

  const defaultInicial = useMemo(() => {
    return startOfDay(subHours(new Date(), 27)).toISOString();
  }, []);
  const defaultFinal = useMemo(() => {
    return endOfDay(new Date()).toISOString();
  }, []);
  const inicialFormat = format(new Date(defaultInicial), 'dd/MM/yyyy, HH:mm');
  const finalFormat = format(new Date(defaultFinal), 'dd/MM/yyyy, HH:mm');
  const phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);
  const [rangeOn, setRangeOn] = useState(true);

  const [monthYearDate, setMonthYearDate] = useState(null);

  function setToday() {
    setRangeOn(true);
    setInicialDate(startOfDay(subHours(new Date(), 27)).toISOString());
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfDay(subHours(new Date(), 27)),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek() {
    setRangeOn(true);
    setInicialDate(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }).toISOString()
    );
    setFinalDate(endOfDay(new Date()).toISOString());

    const inicialFormat = format(
      startOfWeek(subHours(new Date(), 3), { weekStartsOn: 1 }),
      'dd/MM/yyyy, HH:mm'
    );
    const finalFormat = format(
      endOfDay(subHours(new Date(), 3)),
      'dd/MM/yyyy, HH:mm'
    );
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(date) {
    setRangeOn(true);

    const inicial = startOfMonth(date);
    const final = endOfMonth(date);

    setInicialDate(inicial.toISOString());
    setFinalDate(final.toISOString());

    const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);

    setMonthYearDate(date);
  }

  const [initialPre, setInitialPre] = useState(inicialDate);
  const [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    const days = differenceInDays(new Date(finalPre), new Date(initialPre));

    if (days > 31) {
      setRangeOn(false);
    } else {
      setRangeOn(true);
      setInicialDate(format(new Date(initialPre), "yyyy-MM-dd'T'HH:mm:ss"));
      setFinalDate(format(new Date(finalPre), "yyyy-MM-dd'T'HH:mm:ss"));
    }
    const inicialFormat = format(new Date(initialPre), 'dd/MM/yyyy, HH:mm');
    const finalFormat = format(new Date(finalPre), 'dd/MM/yyyy, HH:mm');
    const phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  const [sessions, setSessions] = useState([]);

  const [loading, setLoading] = useState(true);

  const [updateTime, setUpdateTime] = useState(() => {
    return format(new Date(), 'HH:mm');
  });

  // const getPayments = useCallback(async () => {
  //   try {
  //     const response = await api.get('restaurants/payments', {
  //       params: {
  //         start_date: `${format(
  //           addHours(new Date(inicialDate), 3),
  //           "yyyy-MM-dd'T'HH:mm:ss"
  //         )}`,
  //         end_date: `${format(
  //           addHours(new Date(finalDate), 3),
  //           "yyyy-MM-dd'T'HH:mm:ss"
  //         )}`,
  //       },
  //     });
  //     setPayments(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [finalDate, inicialDate]);

  // const [paymentMethods, setPaymentMethods] = useState([]);

  // const getPaymentMethods = useCallback(async () => {
  //   const response = await api.get('restaurants/payment-methods');
  //   const parsedData = response.data.payment_methods.map((data) => ({
  //     id: data.id,
  //     label: data.name,
  //     available: data.available,
  //   }));
  //   setPaymentMethods(parsedData);
  // }, []);

  const getSessions = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          filter_all: true,
          is_delivery: false,
        },
      });

      // const sessionsOrder = response.data.sort((a, b) => {
      //   return a.table.table_number - b.table.table_number;
      // });

      // setSessions(sessionsOrder);

      setSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, [finalDate, inicialDate]);

  useEffect(() => {
    setLoading(true);
    // getPayments();
    getSessions();

    setLoading(false);
  }, [getSessions]);

  async function handleUpdate() {
    setLoading(true);
    getSessions();
    // getPayments();
    // getPaymentMethods();
    setLoading(false);

    const nowTime = format(new Date(), 'HH:mm');
    setUpdateTime(nowTime);
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  async function orderByTableNumber() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${format(
            addHours(new Date(finalDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
        },
      });

      const sessionsOrder = response.data.sort((a, b) => {
        return a.table.table_number - b.table.table_number;
      });

      setSessions(sessionsOrder);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  async function orderByTableDate() {
    try {
      const response = await api.get('/restaurants/table-sessions-minimal', {
        params: {
          start_date: `${format(
            addHours(new Date(inicialDate), 3),
            "yyyy-MM-dd'T'HH:mm:ss"
          )}`,
          end_date: `${finalDate}`,
        },
      });

      setSessions(response.data);
    } catch (err) {
      // console.log('setsessions', response.data, inicialDate, finalDate);
      toast.error('Erro ao carregar informações');
    }
  }

  const [permission, setPermission] = useState(true);


  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/${'OperationAllSessions'}`
      );

      const { can_read } = response.data.OperationAllSessions;

      setPermission(can_read);
    } catch (error) {
      // setPermission(false);
      // if (error?.response?.data?.payload?.user_access === 'pdv') {
      //   window.location.href = '/operation';
      // }
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);




  return !permission ? 
    <PermissionErrorContainer />
  : (
    <Wrapper>
      <Header>
        <div>
          <h2>Acompanhar Comandas</h2>
          <p>
            Aqui você acompanha as comandas das mesas e consegue controlar as
            contas individuais fechadas e a(s) forma(s) de pagamento usada(s).
          </p>
          <span>Última Atualização {updateTime}</span>
          <UpdateButton onClick={handleUpdate} />
        </div>
      </Header>
      <Card>
        <Content>
          <Row>
            <Col md="4">
              <p>{hourPhrase}</p>
            </Col>
            {
              activeTab !== '5' &&
                <Col md="8">
                  <div style={{ float: 'right' }}>
                    <SelectDateButton
                      className="btn-round mr-auto"
                      onClick={setToday}
                      color="info"
                    >
                      Hoje / Ontem
                    </SelectDateButton>
                    <SelectDateButton
                      className="btn-round mr-auto"
                      onClick={setWeek}
                      color="info"
                    >
                      Essa semana
                    </SelectDateButton>
                    <SelectDateButton
                      className="btn-round mr-auto"
                      onClick={() => setMonth(new Date())}
                      color="info"
                    >
                      Esse mês
                    </SelectDateButton>

                    <DataPickerArea>
                      <DatePickerStyled
                        selected={monthYearDate}
                        onChange={(date) => setMonth(date)}
                        locale="pt"
                        showMonthYearPicker
                        customInput={
                          <SelectDateButton
                            className="btn-round mr-auto"
                            color="info"
                          >
                            Mês/Ano <MdArrowDropDown color="white" size={20} />
                          </SelectDateButton>
                        }
                      />
                    </DataPickerArea>

                    <SelectDateButton
                      className="btn-round mr-auto"
                      onClick={toggle}
                      color="info"
                    >
                      Selecionar Período
                      <MdArrowDropDown color="white" size={20} />
                    </SelectDateButton>
                  </div>
                </Col>
            }
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: 'right', marginRight: '15px' }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
            <p
              style={{
                textAlign: 'right',
                color: 'red',
                display: rangeOn ? 'none' : 'block',
              }}
            >
              Favor selecionar o intervalo máximo de 31 dias entre as datas.
            </p>
          </Row>

          {/* <Row>
            <ButtonChangeOrdenation type="button" onClick={orderByTableNumber}>
              Ordenar por mesa
            </ButtonChangeOrdenation>
            <ButtonChangeOrdenation type="button" onClick={orderByTableDate}>
              Ordenar por data
            </ButtonChangeOrdenation>
          </Row> */}

          <Bills
            sessions={sessions}
            getSessions={getSessions}
            loading={loading}
            // payments={payments}
            // paymentMethods={paymentMethods}
            setSessions={setSessions}
            finalDate={finalDate}
            inicialDate={inicialDate}
            orderByTableNumber={orderByTableNumber}
            orderByTableDate={orderByTableDate}
            handleUpdate={handleUpdate}
            params={params}
            activeTab={activeTab} 
            setActiveTab={setActiveTab}
          />
        </Content>
      </Card>
    </Wrapper>
  );
}
