import styled, {css} from 'styled-components';

export const DoneButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #28a745;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;
export const DisplayNoneImprimir = styled.button`
  display: none;
`;
export const Print = styled.button`
  height: 48px;
  width: 440px !important;
  border-radius: 5px;

  border: none;
  background: #ff2c3a;
  font-weight: bold;

  svg {
    margin-right: 6px;
  }

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
  }
`;
export const ModalPaymentBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  display: fixed;
  bottom: 20px;

`;

export const ContainerOrders = styled.div`
    max-height: 70vh;
    overflow-y: scroll;

    ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      max-height: 55vh;
    `}

    @media (max-width: 1441px){
      max-height: 55vh;
    }
`;