import { Table } from 'reactstrap';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

export const Card = styled(Table)`
  width: 100%;
  min-width: 400px;
  align-items: center;
  border-radius: 0 7px 7px 7px;
  border-collapse: separate;
  border-spacing: 0 20px;
  background: #fff;

  padding: 0 30px;
  margin: 20px auto;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 1px solid #33333310;
      }
    }
    td:last-child {
      text-align: right;
    }
  }
`;

export const Header = styled.header`
  margin: 30px;
  display: flex;
  justify-content: space-between;
`;

export const CategoryListItem = styled.div`
  width: 100%;

  align-items: center;
  border-bottom: 1px solid #33333310;
`;

export const CategoryListInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const DateSelectorDiv = styled.div`
  width: 90%;

  display: flex;
  align-items: center;
  margin-left: 60px;
  margin-top: 20px;

  padding-bottom: 20px;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 40px;
  border-radius: 8px;

  padding-left: 10px;
  padding-right: 10px;
  color: white;

  border: none;
  background: #17a2b8;
  margin-left: 10px;
  margin-top: 25px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  padding: 10px;

  color: white;
  align-self: center;
  margin-right: 15px;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const SelectDataArea = styled.div`
  margin-left: 65px;
`;

export const DatePickerStyledUnify = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  width: 40%;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const NumberFormatStyled = styled(NumberFormat)`
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 8px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 8px;
  }
`;

export const DataPickerArea = styled.div`
  margin-top: 10px;

  span {
    font-weight: bold;
  }
`;
export const FilterArea = styled.div`
  width: 100%;
  margin-left: 20px;
`;

export const DeleteFilterButton = styled.button`
  border: none;
  background: #17a2b8;
  color: #fff;
  padding: 8px 10px;
  border-radius: 8px;
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const StyledLink = styled(Link)`
  text-align: center;
  display: flex;
  align-items: center;
  background: green;
  margin: 10px 0;
  color: #fff;
  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
`;
