import React, { useState, useCallback, useRef } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Form } from '@unform/web';
import { AiOutlineEye } from 'react-icons/ai';

import { Modal, ModalBody, Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import api from '~/services/api';
import formatDate from '~/services/formatDate';
import formatValue from '~/utils/formatValue';
import Edit from '../Edit';
import EditEarning from '../EditEarning';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import { toast } from 'react-hot-toast';
import { ToggleButton } from 'ui-kit-takeat';

function Transaction({
  transaction,
  handleDeleteTransaction,
  getTransactions,
  banks,
  categories,
  freqOptions,
  suppliers,
  paymentMethods,
  handleAddTransactionToUnify,
  isEarningTransaction,
}) {
  const [isModalEditOpen, setIsModalEditopen] = useState();
  const [isModalEditEarningOpen, setIsModalEditEarningOpen] = useState();
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState();
  const [isPaid, setIsPaid] = useState(transaction.paid);

  async function handleSubmit(e) {
    try {
      const response = await api.put(
        `/restaurants/cash-flows/${transaction.id}`,
        {
          paid: e,
          paid_at: new Date(),
        }
      );

      transaction.paid = e;
      transaction.paid_at = new Date();

      if (response.data.paid) {
        toast.success(
          'Lançamento considerado pago no dia de HOJE! Caso queira mudar, vá em editar e informe a data',
          { duration: 5000 }
        );
      } else {
        toast.success('Lançamento marcado como NÃO pago!');
      }
      // getTransactions();
    } catch (error) {
      toast.error('Ocorreu um erro ao fazer essa alteração');
      console.log(error);
    }
  }

  const toggleModalEdit = useCallback(() => {
    setIsModalEditopen(!isModalEditOpen);
  }, [isModalEditOpen]);

  const toggleModalEditEarning = useCallback(() => {
    setIsModalEditEarningOpen(!isModalEditEarningOpen);
  }, [isModalEditEarningOpen]);

  const toggleModalDetails = useCallback(() => {
    setIsModalDetailsOpen(!isModalDetailsOpen);
  }, [isModalDetailsOpen]);

  function openModal(is_earning) {
    if (is_earning) {
      toggleModalEditEarning();
    } else {
      toggleModalEdit();
    }
  }

  const [isChecked, setIsChecked] = useState(false);

  return (
    <tr>
      {isEarningTransaction && (
        <td>
          <Form onSubmit={() => {}}>
            <CheckboxInput
              onChange={() => {
                handleAddTransactionToUnify(transaction);
                setIsChecked(!isChecked);
              }}
              name="unify"
              defaultChecked={isChecked}
            />
          </Form>
        </td>
      )}
      <td>
        <span>{formatDate(transaction?.due_date)}</span>
      </td>
      {isEarningTransaction ? (
        <td>{transaction?.cash_flow_payment_method?.name || '-'}</td>
      ) : (
        <td>{transaction?.description || '-'}</td>
      )}
      {isEarningTransaction ? (
        <td>{transaction?.description || '-'}</td>
      ) : (
        <td>{transaction?.provider?.name || '-'}</td>
      )}
      {isEarningTransaction ? (
        <td>{transaction?.bank_account?.name || '-'}</td>
      ) : (
        <td>{transaction?.text || '-'}</td>
      )}
      <td>
        <ToggleButton
          value={isPaid}
          clickFunction={(e) => {
            handleSubmit(e);
            setIsPaid(e);
          }}
        />
      </td>

      <td>
        <span
          style={{
            color: transaction.is_earning ? 'green' : '#f24f00',
            fontWeight: 'bold',
          }}
        >
          {formatValue(transaction.value)}
        </span>
      </td>
      <td style={{ width: 100 }}>
        {transaction?.cash_flows.length > 0 && (
          <AiOutlineEye
            color="#17a2b8"
            size={20}
            onClick={() => {
              toggleModalDetails();
            }}
            title="Detalhes"
            style={{ cursor: 'pointer' }}
          />
        )}

        <FaPen
          onClick={() => {
            openModal(transaction.is_earning);
          }}
          title="Editar movimentação"
          style={{ cursor: 'pointer', marginLeft: 8 }}
        />
        <FaTrash
          color="#ff403d"
          title="Deletar movimentação"
          style={{ cursor: 'pointer', marginLeft: 8 }}
          onClick={() => {
            handleDeleteTransaction(transaction.id);
          }}
        />
      </td>

      <Modal isOpen={isModalDetailsOpen} toggle={toggleModalDetails} size="xl">
        <ModalBody>
          <Row>
            <Col>
              <h4>Detalhes</h4>
            </Col>
          </Row>
          {transaction?.cash_flows.map((cash) => (
            <Form initialData={cash}>
              <Row>
                <Col md="5">
                  <Input label="Descrição" name="description" disabled />
                </Col>
                {/* <Col md="3">
                    <Input label="Data de Vencimento" name="due_date" value={format(new Date(cash.due_date), 'dd/MM/yyyy')} disabled/>
                  </Col> */}
                <Col md="2">
                  <Input
                    label="Valor"
                    name="value"
                    value={parseFloat(cash.value).toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                    })}
                    disabled
                  />
                </Col>
                <Col md="5">
                  <Input
                    label="Centro de custo"
                    name="cash_flow_text"
                    value={cash.cash_flow_text}
                    disabled
                  />
                </Col>
              </Row>
            </Form>
          ))}
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalEditOpen} toggle={toggleModalEdit} size="lg">
        <Edit
          transaction={transaction}
          getTransactions={getTransactions}
          toggleModalEditTransaction={toggleModalEdit}
          paymentMethods={paymentMethods}
          banks={banks}
          suppliers={suppliers}
          freqOptions={freqOptions}
          categories={categories}
          setIsPaid={setIsPaid}
        />
      </Modal>

      <Modal
        isOpen={isModalEditEarningOpen}
        toggle={toggleModalEditEarning}
        size="lg"
      >
        <EditEarning
          transaction={transaction}
          getTransactions={getTransactions}
          toggleModalEditTransaction={toggleModalEditEarning}
          paymentMethods={paymentMethods}
          banks={banks}
          suppliers={suppliers}
          freqOptions={freqOptions}
          categories={categories}
          setIsPaid={setIsPaid}
        />
      </Modal>
    </tr>
  );
}

export default Transaction;
