import React from 'react';
import { RiKnifeLine } from 'react-icons/ri';
import { Spinner } from 'reactstrap';
import { Content, Icon, ItemsCount, LoadingDiv } from '../styles';
import { Header, Bloco } from './styles';
import OrderAndamento from '~/components/Orders/Items/OrderAndamento';

export default function CardOrdersAndamento({
  baskets,
  handleItemFinished,
  handleCancelItem,
  loading,
  handleItemReady,
  handleUpdate,
}) {
  const count_orders = baskets.filter(
    (order) => order.table.table_type === 'delivery' && !order.scheduled_to
  ).length;

  return (
    <Bloco>
      <Header>
        <Icon>
          <RiKnifeLine color="#fff" size={25} />
        </Icon>
        EM ANDAMENTO
        <ItemsCount>{count_orders}</ItemsCount>
      </Header>
      <Content>
        {loading ? (
          <LoadingDiv>
            <Spinner color="warning" />
          </LoadingDiv>
        ) : (
          baskets
            .filter(
              (order) =>
                order.table.table_type === 'delivery' && !order.scheduled_to
            )
            .map((item) => (
              <OrderAndamento
                key={item.basket.id}
                handleCancelItem={handleCancelItem}
                handleItemFinished={handleItemFinished}
                item={item}
                handleItemReady={handleItemReady}
                handleUpdate={handleUpdate}
              />
            ))
        )}
      </Content>
    </Bloco>
  );
}
