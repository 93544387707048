
import React, { useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Container, CategoryList, CategoryThumbnailLink } from './styles';

import { useAuth } from '~/context/AuthContext';
import { useEffect } from 'react';

import { FaPhone, FaUser, FaUserPlus } from 'react-icons/fa';
import { toast } from 'react-hot-toast';

function BuyersScrollNav({ buyers, createBuyer, newBuyer }) {

  const { setBuyerSelectedByWaiter, buyerCreatedByWaiter, buyerSelectedByWaiter } = useAuth()
  const [selectedItem, setSelectedItem] = useState()

  function handleSelectBuyer(name, id) {

    if (name[0] === '+') {
      if (buyerCreatedByWaiter) {
        toast.error('Você já criou um usuário, faça o pedido para o mesmo, antes de criar outro.')
      } else {
        createBuyer(name)
      }

    } else {
      setBuyerSelectedByWaiter({
        name: name,
        id: id
      })
    }
  }


  function handleSelectItem(id) {
    if (id !== -33801) {
      setSelectedItem(id);
    }
  }

  function handleSelectUser() {
    setSelectedItem(newBuyer);
    setBuyerSelectedByWaiter('')
  }

  useEffect(() => { newBuyer ? handleSelectUser() : setSelectedItem(-33800) }, [newBuyer])

  useEffect(() => {
    if (!buyerSelectedByWaiter) {
      setSelectedItem(-33800)
    }
  }, [buyerSelectedByWaiter])

  return (
    <Container>

      <ScrollContainer className="scroll-container">
        {buyers && buyers.map(buyer => (
          <CategoryList onClick={() => { handleSelectBuyer(buyer.name ? buyer.name : buyer.phone, buyer.id); handleSelectItem(buyer.id) }} key={buyer.id}>
            <CategoryThumbnailLink
              alan={selectedItem === buyer.id ? "selected" : ""} buttonAdd={buyer?.name[0] === '+'}
            ><span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{buyer?.name[0] === '+' ? <FaUserPlus color='#3BD2C1' /> : buyer?.name ? buyer?.name[0].toUpperCase() : <FaUser />}</span>
            </CategoryThumbnailLink>
            <p>{buyer?.name || buyer?.phone.substring(11, 15)}</p>
          </CategoryList>
        ))}
      </ScrollContainer>
    </Container>
  );
}

export default BuyersScrollNav;
