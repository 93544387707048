import React from 'react';

import { Spinner } from 'reactstrap';

import { CategoriesTable, Container, LoadingDiv, EmptyDiv } from './styles';

import NfeDevRow from './NfeDevRow';

export default function NfesDevolucao({ nfes, loading, handleUpdate }) {
  return (
    <Container>
      <CategoriesTable borderless>
        <thead>
          <tr>
            <th>Fornecedor</th>
            <th>Data de emissão</th>
            {/* <th>Chave NFe</th> */}
            <th>Valor</th>
            <th>Documento Emitente</th>
            <th>Ações</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td />
              <td />
              {/* <td /> */}
              <td />
              <td>
                <LoadingDiv>
                  <Spinner color="info" />
                </LoadingDiv>
              </td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        ) : (
          <tbody>
            {nfes?.map((nfe) => (
              <NfeDevRow nfe={nfe} handleUpdate={handleUpdate}/>
            ))}
          </tbody>
        )}
      </CategoriesTable>
      {nfes?.length === 0 ? (
        <EmptyDiv>
          <span>Não há nenhuma Nota Fiscal com os filtros selecionados.</span>
        </EmptyDiv>
      ) : (
        <></>
      )}
    </Container>
  );
}
