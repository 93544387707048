import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import { Container, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import getValidationErrors from '../../../services/getValidationErrors';

import { Wrapper, Header, Card, Head } from './styles';
import history from '~/services/history';
import api from '~/services/api';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

import { useAuth } from '~/context/AuthContext';

function EditPrinter() {
  const { user } = useAuth();

  const formRef = useRef(null);
  const location = useLocation();

  const { printer } = location.state;

  const initialData = {
    name: printer.name,
    host: printer.host,
    model: printer.model,
    connection_method: printer.connection_method,
    ip: printer.ip,
    is_category_limited: printer.is_category_limited,
    print_bills_allowed: printer.print_bills_allowed,
    print_orders_allowed: printer.print_orders_allowed,
    print_reports_allowed: printer.print_reports_allowed,
    print_self_service_allowed: printer.print_self_service_allowed
  };

  const [categories, setCategories] = useState([]);
  const [categoryLimited, setCategoryLimited] = useState(
    printer.is_category_limited
  );

  function changeLimited() {
    setCategoryLimited(!categoryLimited);
  }

  const getCategories = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/product-category');

      const data = response.data.map((categorie) => ({
        value: categorie.id,
        label: categorie.name,
      }));
      setCategories(data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  let initialCategories = printer.print_product_categories.map((categorie) => ({
    value: categorie.product_category.id,
    label: categorie.product_category.name,
  }));

  useEffect(() => {
    try {
      getCategories();
    } catch (err) {
      // console.tron.log(err);
    }
  }, [getCategories]);

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório.'),
        host: Yup.string().required('Host obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let categoriesPut = [];
      if (data.is_category_limited) {
        categoriesPut = data.categories;
      }

      await api.put(`/restaurants/printers/${printer.id}`, {
        name: data.name,
        host: data.host,
        ip: data.ip,
        model: data.model,
        connection_method: data.connection_method,
        is_category_limited: data.is_category_limited,
        print_bills_allowed: data.print_bills_allowed,
        print_orders_allowed: data.print_orders_allowed,
        print_reports_allowed: data.print_reports_allowed,
        categories: categoriesPut,
        print_self_service_allowed: data.print_self_service_allowed,
        waiter_id: data.waiter_id
      });

      toast.success('Impressora atualizada com sucesso!');
      history.push('/settings');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao atualizar impressora.');
      }
    }
  }

  const [waiters, setWaiters] = useState([])
  const [defaultWaiter, setDefaultWaiter] = useState(null)


  const getWaiters = useCallback(async () => {
    const response = await api.get('/restaurants/waiters');

    const formattedWaiters = response.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })

    const formattedDefaultWaiter = response.data.find(item => item.id === printer.waiter_id)


    setWaiters(formattedWaiters);
    if (formattedDefaultWaiter) {
      setDefaultWaiter({
        value: formattedDefaultWaiter.id,
        label: formattedDefaultWaiter.name
      })
    } else {
      setDefaultWaiter(false)
    }

  }, []);

  useEffect(() => {
    try {
      if (user.has_stone_pos) {
        getWaiters();
      }

    } catch (error) {
      console.log(error.message)
    }
  }, [getWaiters, user]);

  const [printSelfService, setPrintSelfService] = useState(printer.print_self_service_allowed)
  function togglePrintSelfService() {
    setPrintSelfService(!printSelfService)
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar Impressora</h2>
          <p>{printer.name}</p>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form initialData={initialData} onSubmit={handleSubmit} ref={formRef}>
          <Container>
            <Row>
              <Col sm="12" lg="6">
                <Input
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome"
                />
              </Col>
              <Col sm="12" lg="6">
                <Input
                  label="Host"
                  name="host"
                  type="text"
                  placeholder="Host"
                  disabled
                />
              </Col>
            </Row>
            {user.internal && (
              <>
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="Modelo"
                      name="model"
                      type="text"
                      placeholder="Modelo"
                    />
                  </Col>
                  <Col sm="12" lg="6">
                    <Input
                      label="Método de Conexão"
                      name="connection_method"
                      type="text"
                      placeholder="Método de Conexão"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <Input
                      label="IP or Porta"
                      name="ip"
                      type="text"
                      placeholder="IP or Porta"
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row style={{ marginTop: 20 }}>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Imprime Comanda"
                  name="print_bills_allowed"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Imprime Pedidos"
                  name="print_orders_allowed"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Imprime Relatórios"
                  name="print_reports_allowed"
                />
              </Col>
            </Row>

            {user.has_stone_pos && (
              <Row>
                <Col sm="12" lg="6">
                  <CheckboxInput
                    label="Imprime Autoatendimento"
                    name="print_self_service_allowed"
                    onClick={togglePrintSelfService}
                    defaultChecked={printSelfService}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm="12" lg="6">
                <CheckboxInput
                  label="Limitar Categorias"
                  name="is_category_limited"
                  onClick={changeLimited}
                />
              </Col>
            </Row>

            {printSelfService && defaultWaiter !== null && (
              <div>
                <Head style={{ marginTop: 20 }}>
                  <h3>Associar garçom a impressora</h3>
                </Head>
                <Select
                  name="waiter_id"
                  defaultValue={defaultWaiter}
                  options={waiters}
                  placeholder="Selecione o garçom relacionado a esta impressora"
                />
              </div>
            )}

            {categoryLimited && (
              <div>
                <Head style={{ marginTop: 20 }}>
                  <h3>Associar categorias a impressora</h3>
                </Head>
                <Select
                  name="categories"
                  isMulti
                  defaultValue={initialCategories}
                  options={categories}
                  placeholder="Selecione as categorias que essa impressora imprime"
                />
              </div>
            )}
            <SaveButton type="submit">Salvar</SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default EditPrinter;
