import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import * as Yup from 'yup';

import { Container, Row, Col } from 'reactstrap';
import ReactInputMask from 'react-input-mask';
import getValidationErrors from '../../../services/getValidationErrors';
import { Wrapper, Header, Card } from './styles';

import history from '~/services/history';
import api from '~/services/api';

import Input from '~/components/Form/Input';
import BackButton from '~/components/Buttons/BackButton';
import SaveButton from '~/components/Buttons/SaveButton';

function EditMotoboy() {
  const location = useLocation();
  const formRef = useRef(null);

  const { motoboy } = location.state;

  const initialData = {
    name: motoboy?.name,
    phone: motoboy?.phone,
  };

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        phone: Yup.string()
          .min(13, 'O número deve ter DDD + 9 dígitos')
          .max(13, 'O número deve ter DDD + 9 dígitos'),
      });

      if (data.phone.length > 0) {
        data.phone = data.phone.split(/^\(|\)|-|\s/).join('');
        data.phone = `(${data.phone.substring(0, 2)})${data.phone.substring(
          2
        )}`;
      } else {
        delete data.phone;
      }
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`restaurants/motoboys/${motoboy.id}`, {
        name: data.name,
        phone: data.phone,
      });

      toast.success('Motoboy atualizado com sucesso!');
      history.goBack();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response.ok) {
        switch (error?.response?.data?.errorType) {
          case 'phone_already_exists':
            toast.error(error?.response?.data?.message);
            break;
          default:
            toast.error('Falha ao editar motoboy');
        }
      }
    }
  }

  return (
    <Wrapper>
      <Header>
        <div>
          <h2>Editar Motoboy</h2>
        </div>
        <BackButton onClick={() => history.goBack()}>Voltar</BackButton>
      </Header>
      <Card>
        <Form
          id="save-form"
          initialData={initialData}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <Container>
            <Row>
              <Col sm="12">
                <Input label="Nome do Motoboy:" name="name" type="text" />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <ReactInputMask mask="(99) 99999-9999">
                  <Input
                    label="Telefone do Motoboy:"
                    name="phone"
                    type="text"
                  />
                </ReactInputMask>
              </Col>
            </Row>
            <SaveButton type="submit" form="save-form">
              Cadastrar
            </SaveButton>
          </Container>
        </Form>
      </Card>
    </Wrapper>
  );
}

export default EditMotoboy;
