import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';

import { Container, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FaPrint } from 'react-icons/fa';
import api from '~/services/api';
import DeleteItemOrders from '~/components/Orders/DeleteItemOrders';
import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  Badge,
  SeeButton,
  CancelButton,
  OrdersInfo,
  InfoDiv,
} from '../styles';
import {
  Print,
  DisplayNoneImprimir,
  PrintOutline,
  Footer,
  ContainerOrders,
  ButtonCancel,
} from './styles';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';

import EyeModalOrders from '~/components/EyeModalOrders';
import PrintNewOrderComponent from '~/components/PrintNewOrderComponent';
import { useAuth } from '~/context/AuthContext';
import formatCompleteDate from '~/services/formatCompleteDate';
import { handleNotifyWhatsapp } from '~/utils/handleNotifyWhatsapp';
import logoTakeat from '../../../../assets/img/garfinho_red.png';
import logoIfood from '../../../../assets/img/ifood-logo.png';
import Select from '~/components/Form/SelectInput';
import { useMotoboys } from '~/context/MotoboysContext';
import TableEditDelivery from '~/components/Operation/TablesOperation/TableEditDelivery';
import apiClube from '~/services/apiClube';
import { OrderStatusTag } from 'ui-kit-takeat';

export default function OrderAndamento({
  item,
  handleItemFinished,
  handlePrintItem,
  handleCancelItem,
  handleItemReady,
  handleUpdate,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [btnDisable, serBtnDisable] = useState(false);
  const [session, setSession] = useState(null);
  const { motoboys } = useMotoboys();
  const { user } = useAuth();
  const initialData = {
    motoboy: {
      value: motoboys[0]?.id,
      label: motoboys[0]?.name,
    },
  };

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [modalDeleteDelivery, setModalDeleteDelivery] = useState(false);
  const toggleDeleteDelivery = () => {
    setModalDeleteDelivery(!modalDeleteDelivery);
    serBtnDisable(!btnDisable);
  };

  const [modalMotoboy, setModalMotoboy] = useState(false);
  const toggleModalMotoboy = () => {
    setModalMotoboy(!modalMotoboy);
  };

  const [orders, setOrders] = useState([]);
  const getOrders = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/basket-orders/${item.basket.id}`
      );

      setOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [item]);

  const getSession = async () => {
    const response = await api.get(
      `/restaurants/delivery/session-orders/${item.session_id}`
    );

    setSession(response.data?.orders[0]);
  };

  useEffect(() => {
    if (modalDelete) {
      getSession();
    }
  }, [modalDelete, getSession]);

  useEffect(() => {
    if (modalDelete) {
      getOrders();
    }
  }, [modalDelete, getOrders]);

  const componentRef = useRef(null);

  const [loadingPrinter, setLoadingPrinter] = useState(false);

  async function printClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        order_basket_id: item.basket.id,
        order_type: 'new_order',
      });
      toast.success('Pedido impresso.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
  }

  async function printDeliverySession() {
    setLoadingPrinter(true);
    try {
      await api.post('restaurants/printers/printer-queue', {
        table_session_id: item.session_id,
        is_delivery: true
      });

      toast.success('Pedido enviado para impressão.');
    } catch (error) {
      toast.error('Erro ao imprimir pedido.');
    }
    setLoadingPrinter(false);
  }

  const userName = item?.buyer
    ? formatPhone(
        item?.buyer?.phone
          ? item?.buyer.phone || item?.buyer.name
          : item?.buyer?.ifood_phone
      )
    : item?.waiter?.name;

  const userNameDelivery = item?.buyer
    ? item?.buyer?.ifood_phone
      ? item?.buyer?.ifood_phone
      : item?.buyer?.phone
    : item?.waiter?.name;

  function searchTableName(table) {
    if (table.table_type === 'balcony') {
      return 'Balcão';
    }

    if (table.table_type === 'pos') {
      return 'POS';
    }

    if (table.table_number === -10) {
      return 'Mesa Copa';
    }

    if (table.table_type === 'delivery') {
      if (item?.with_withdrawal && item?.scheduled_to) {
        return 'Retirada Agendada';
      }
      if (item?.with_withdrawal) {
        return 'Retirada';
      }

      if (!item?.with_withdrawal && item?.scheduled_to) {
        return 'Delivery Agendado';
      }

      return 'Delivery';
    }

    if (table.table_type === 'prepaid') {
      return `Mesa  ${table.table_number} - Pagamento Antecipado`;
    }

    return `Mesa  ${table.table_number}`;
  }

  const handleAddMotoboy = async (data) => {
    try {
      const response = await api.post('/restaurants/motoboys/relate', {
        motoboy_id: data.motoboy,
        session_id: item.session_id,
      });

      item.motoboy = response.data;

      toast.success('Motoboy adicionado à entrega');
      toggleModalMotoboy();
      handleItemReady(
        item.basket.id,
        item.basket.ifood_id,
        item.with_withdrawal
      );
      if (!item.basket.ifood_id) {
        handleNotifyWhatsapp(item, orders, user, 'ready');
      }
    } catch (err) {
      console.log('handleAddMotoboy Error: ', err);
      toast.error('Não foi possível adicionar motoboy à essa entrega');
    }
  };

  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <Pedido>
      <ContentBody>
        {item?.table.table_type !== 'delivery' && (
          <div>
            <OrderStatusTag text="Em andamento" color="#01AFFF" width="147px" />
          </div>
        )}

        <InfoDiv>
          {item?.table.table_type === 'delivery' ? (
            <>
              <OrderNumber>#{item?.attendance_password}</OrderNumber>
              <Time>
                {item?.scheduled_to
                  ? formatCompleteDate(item?.scheduled_to)
                  : formatCompleteDate(item?.basket.start_time)}
              </Time>
              <img
                src={item.basket.ifood_id ? logoIfood : logoTakeat}
                style={{ width: item.basket.ifood_id === null ? 20 : 40 }}
              />
            </>
          ) : (
            <>
              <OrderNumber>#{item?.basket.basket_id}</OrderNumber>
              <Time>{formatTime(item?.basket.start_time)}</Time>
            </>
          )}
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            {item?.table.table_type === 'command' &&
            item?.table.table_type !== 'delivery' ? (
              `Comanda  ${item?.table.table_number} / Mesa ${
                item?.basket?.command_table_number || 'não informada'
              } `
            ) : (
              <>
                {item?.basket?.ifood_table
                  ? item?.basket?.ifood_table
                  : searchTableName(item?.table)}
              </>
            )}
          </TableNumber>
          {item?.basket?.schedule && (
            <span>
              <strong>{item?.basket?.schedule}</strong>
            </span>
          )}
          {item?.table.table_type === 'delivery' ? (
            <PhoneNumber>{userNameDelivery}</PhoneNumber>
          ) : (
            <PhoneNumber>{userName}</PhoneNumber>
          )}
          {item?.buyer?.name &&
            (item?.buyer?.name?.length < 20 ? (
              <PhoneNumber>{item.buyer.name}</PhoneNumber>
            ) : (
              <PhoneNumber>{`${item.buyer.name.slice(0, 17)}...`}</PhoneNumber>
            ))}
        </InfoDiv>

        <Actions>
          <DeleteAndEditActions>
            {item?.table?.table_type === 'delivery' ? (
              <AcceptButton
                onClick={() => {
                  if (motoboys.length > 0) {
                    toggleModalMotoboy();
                  } else {
                    handleItemReady(
                      item.basket.id,
                      item.basket.ifood_id,
                      item.with_withdrawal
                    );
                    if (!item.basket.ifood_id) {
                      handleNotifyWhatsapp(item, orders, user, 'ready');
                    }
                  }
                }}
              >
                <div>
                  <MdDone size={17} style={{ marginRight: 3 }} />
                  Pronto
                </div>
              </AcceptButton>
            ) : (
              <AcceptButton
                onClick={() => {
                  handleItemFinished(item.basket.id);
                }}
              >
                <div>
                  <MdDone size={17} style={{ marginRight: 3 }} />
                  Pronto
                </div>
              </AcceptButton>
            )}

            <SeeButton onClick={toggle}>
              <div>
                <AiOutlineEye size={17} style={{ marginRight: 3 }} />
                Detalhes
              </div>
            </SeeButton>

            {item?.table?.table_type === 'delivery' && (
              <TableEditDelivery
                session={session}
                item={item}
                orders={orders}
                setOrders={setOrders}
                toggleDeleteDelivery={toggleDeleteDelivery}
                disabled={!!item.basket.ifood_id}
              />
            )}
          </DeleteAndEditActions>
        </Actions>
      </ContentBody>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ContainerOrders browserZoomLevel={browserZoomLevel}>
            <EyeModalOrders item={item} />
          </ContainerOrders>
        </ModalBody>
        <Footer
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
          }}
        >
          {user.is_printed_on_web ? (
            <ReactToPrint
              onBeforeGetContent={async () => {
                await getOrders();
              }}
              trigger={() => (
                <Print>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
              content={() => componentRef.current}
            />
          ) : (
            <>
              {item?.table.table_type === 'delivery' ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <PrintOutline onClick={printClick}>
                    <FaPrint color="#ff2c3a" size={20} />
                    Imprimir em ambiente de produção
                  </PrintOutline>
                  {
                    user.print_bills_delivery_on_web ? 
                    <ReactToPrint
                      onBeforeGetContent={async () => {
                        await getOrders();
                      }}
                      trigger={() => (
                        <Print>
                          <FaPrint color="#fff" size={20} />
                          Imprimir via de entrega
                        </Print>
                      )}
                      content={() => componentRef.current}
                    /> :
                    <Print onClick={printDeliverySession} disabled={loadingPrinter}>
                      <FaPrint color="#fff" size={20} />
                      Imprimir via de entrega
                    </Print>
                  }
                  
                </div>
              ) : (
                <Print onClick={printClick}>
                  <FaPrint color="#fff" size={20} />
                  Imprimir
                </Print>
              )}
            </>
          )}
        </Footer>
      </Modal>
      <Modal isOpen={modalDeleteDelivery} toggle={toggleDeleteDelivery}>
        <ModalBody>
          <Container>
            <h3>Tem certeza que deseja recusar o pedido?</h3>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              handleCancelItem({
                from: 'accepted',
                order_basket_id: item.basket.id,
                session_id: item.session_id,
                ifood_id: item.basket.ifood_id,
              })
            }
          >
            Recusar o pedido
          </Button>
          <Button color="secondary" onClick={() => toggleDeleteDelivery()}>
            Desistir
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalMotoboy} toggle={toggleModalMotoboy}>
        <Form onSubmit={handleAddMotoboy} initialData={initialData}>
          <ModalBody>
            <Container>
              <h3>Adicionar motoboy a essa entrega?</h3>
            </Container>
            <Select
              name="motoboy"
              label="Selecionar motoboy"
              options={motoboys.map((m) => {
                return { value: m.id, label: m.name };
              })}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                toggleModalMotoboy();
                handleItemReady(
                  item.basket.id,
                  item.basket.ifood_id,
                  item.with_withdrawal
                );
                if (!item.basket.ifood_id) {
                  handleNotifyWhatsapp(item, orders, user, 'ready');
                }
              }}
            >
              Ignorar
            </Button>
            <Button color="secondary" type="submit">
              Adicionar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalBody>
          <Container>
            <DeleteItemOrders
              item={item}
              modalDelete={modalDelete}
              from="accepted"
              orders={orders}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel
            color="danger"
            onClick={() =>
              handleCancelItem({
                order_basket_id: item.basket.id,
                from: 'accepted',
              })
            }
          >
            Cancelar todos de uma vez
          </ButtonCancel>
        </ModalFooter>
      </Modal>
      <DisplayNoneImprimir>
        <PrintNewOrderComponent
          restaurant={user}
          item={item}
          ref={componentRef}
          orders={orders}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
