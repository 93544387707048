import React, { useState, useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import { Form } from '@unform/web';
import { MdEdit } from 'react-icons/md';
import CheckboxInput from '~/components/Form/Checkbox';

import {
  Container,
  Card,
  Header,
  TablesTable,
  EditButton,
  DeleteAndEditActions,
  StatusTag,
} from './styles';

import api from '~/services/api';
import { useAuth } from '~/context/AuthContext';
import SaveButton from '~/components/Buttons/SaveButton';
import DownloadButton from '~/components/Buttons/DownloadButton';
import PermissionErrorContainer from '~/components/PermissionErrorContainer';
import { InputContainer } from '~/components/Form/styles';
import formatCompleteDate from '~/services/formatDate';
import FiscalGroupComponent from '~/components/Fiscal/FiscalGroupComponent';
import RegisterButton from '~/components/Buttons/RegisterButton';
import InputMask from 'react-input-mask';
import Input from '~/components/Form/Input';
import NFeInfo from '~/services/NFeInfo';
import Select from '~/components/Form/SelectInput';

function FiscalGroups() {
  const { user } = useAuth();
  const [groups, setGroups] = useState([]);

  const getGroups = useCallback(async () => {
    try {
      const response = await api.get('restaurants/fiscal-groups');
      setGroups(response.data);
    } catch (err) {
      toast.error('Erro ao carregar grupos.');
    }
  }, []);

  const icms_origem_options = NFeInfo.icms_origem.map((icms) => ({
    value: icms.value,
    label: icms.description,
  }));

  const csosn_options = NFeInfo.csosn.map((c) => ({
    value: c.csosn,
    label: `${c.csosn} - ${c.description}`,
  }));

  const base_calculo_options = NFeInfo.base_calculo.map((c) => ({
    value: c.value,
    label: c.description,
  }));

  const [csosnSelected, setCsosnSelected] = useState(null);
  const [cfopOptions, setCfopOptions] = useState([]);
  const [cstSelected, setCstSelected] = useState('');


  useEffect(() => {

    const cstSelect = NFeInfo.cst.filter((cs) => cs.cst === cstSelected);
    const cfop = [];

    cstSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = NFeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0]?.cfop,
          label: `${cfopDesc[0]?.cfop} - ${cfopDesc[0]?.description}`,
        });
      })
    );

    setCfopOptions(cfop);


  }, [cstSelected]);

  useEffect(() => {
    const cfopSelect = NFeInfo.csosn.filter((cs) => cs.csosn === csosnSelected);
    const cfop = [];

    cfopSelect.map((selected) =>
      selected.cfop?.forEach((cf) => {
        const cfopDesc = NFeInfo.cfopDescription.filter(
          (cfop) => cfop.cfop === cf
        );

        cfop.push({
          value: cfopDesc[0].cfop,
          label: `${cfopDesc[0].cfop} - ${cfopDesc[0].description}`,
        });
      })
    );

    setCfopOptions(cfop);
  }, [csosnSelected]);

  const [modal, setModal] = useState(false);
  const toggleRegister = () => setModal(!modal);

  async function handleSubmitFiscal(data) {
    try {
      const parsedData = {
        title: data.title,
      };

      if (user.regime_tributario === '1') {
        parsedData.cfop = data.cfop;
        parsedData.csosn = data.csosn;
        parsedData.cest = data?.cest || null;
        parsedData.icms_origem = data.icms_origem;

        if (csosnSelected === '00') {
          parsedData.aliquota = data.aliquota;
          parsedData.base_calculo = data.base_calculo;
        }

        if (user.has_nfce && data.cofins !== undefined) {
          parsedData.cofins = data.cofins;
        }

        if (user.has_nfce && data.pis !== undefined) {
          parsedData.pis = data.pis;
        }
      } else {
        parsedData.cfop = data.cfop;
        parsedData.csosn = data.csosn;
        parsedData.cest = data?.cest || null;
        parsedData.icms_origem = data.icms_origem;
        parsedData.icms_aliquota = data.icms_aliquota;
        parsedData.cst = data.cst; //cst
        parsedData.pis_aliquota = data.pis_aliquota;
        parsedData.cofins_aliquota = data.cofins_aliquota;
        parsedData.icms_modalidade_base_calculo =
          data.icms_modalidade_base_calculo;
        parsedData.cbenef = data.cbenef;

        if (data.cofins !== undefined) {
          parsedData.cofins = data.cofins;
        }

        if (data.pis !== undefined) {
          parsedData.pis = data.pis;
        }
      }

      const response = await api.post(`restaurants/fiscal-groups`, parsedData);
      const newGroups = [response.data, ...groups];
      setGroups(newGroups);

      toggleRegister();
      toast.success('Grupo fiscal criado com sucesso!');
    } catch (error) {
      toast.error('Falha ao criar grupo');
      console.log(error);
    }
  }

  const [permission, setPermission] = useState();

  const validateUser = useCallback(async () => {
    try {
      const response = await api.get(
        `/restaurants/users/role-permission/FiscalGroups`
      );

      const { can_read } = response.data.FiscalGroups;

      setPermission(can_read);
    } catch (error) {
      toast.error('Erro ao solicitar acesso');
    }
  }, []);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  useEffect(() => {
    if (permission) {
      getGroups();
    }
  }, [getGroups, permission]);

  return !permission ? (
    <PermissionErrorContainer />
  ) : (
    <Container>
      <Header>
        <div>
          <h2>Grupos Fiscais</h2>
        </div>
        <RegisterButton onClick={() => toggleRegister()}>
          Adicionar
        </RegisterButton>
      </Header>

      {groups.map((group) => (
        <FiscalGroupComponent group={group} user={user} setGroups={setGroups} />
      ))}

      <Modal isOpen={modal} toggle={toggleRegister} size="xl">
        <ModalBody>
          <Form onSubmit={handleSubmitFiscal}>
            <Container>
              {user.regime_tributario === '1' ? (
                <div>
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="12" style={{ marginTop: 15 }}>
                      <Input name="title" label="Nome do grupo" />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="12">
                      <Select
                        name="icms_origem"
                        options={icms_origem_options}
                        label="ICMS de Origem"
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="csosn"
                        options={csosn_options}
                        label="CSOSN"
                        onChange={(e) => setCsosnSelected(e.value)}
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Select name="cfop" options={cfopOptions} label="CFOP" />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="cofins"
                        options={NFeInfo.cofins_options}
                        label="Cofins"
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Select
                        name="pis"
                        options={NFeInfo.cofins_options}
                        label="Pis"
                      />
                    </Col>
                  </Row>

                  {csosnSelected === '500' && (
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask mask="9999999">
                          <Input label="CEST" name="cest" type="text" />
                        </InputMask>
                      </Col>
                    </Row>
                  )}

                  {csosnSelected === '00' && (
                    <div>
                      <Row style={{ paddingTop: 20 }}>
                        <Col sm="12" lg="6">
                          <Input
                            label="Alíquota (Entre 0 e 100)"
                            name="aliquota"
                            type="text"
                            maxlength="3"
                          />
                        </Col>

                        <Col sm="12" lg="6" style={{ marginTop: 15 }}>
                          <Select
                            name="base_calculo"
                            options={base_calculo_options}
                            label="Base Cálculo"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="12" style={{ marginTop: 15 }}>
                      <Input name="title" label="Nome do grupo" />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="icms_origem"
                        options={icms_origem_options}
                        label="ICMS de Origem"
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Input
                        label="Alíquota ICMS (%)"
                        name="icms_aliquota"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select name="cfop" options={cfopOptions} label="CFOP" />
                    </Col>
                    <Col sm="12" lg="6">
                      <Select
                        name="cst"
                        options={NFeInfo.icms_situacao_tributaria}
                        label="CST"
                        onChange={(e) => {
                          setCstSelected(e.value);

                        }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="pis"
                        options={NFeInfo.pis_situacao_tributaria}
                        label="PIS"
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Input
                        label="Alíquota PIS (%)"
                        name="pis_aliquota"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="cofins"
                        options={NFeInfo.pis_situacao_tributaria}
                        label="COFINS"
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Input
                        label="Alíquota COFINS (%)"
                        name="cofins_aliquota"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 10 }}>
                    <Col sm="12" lg="6">
                      <Select
                        name="icms_modalidade_base_calculo"
                        options={NFeInfo.icms_modalidade_base_calculo}
                        label="Modalidade Base Calculo"
                      />
                    </Col>

                    <Col sm="12" lg="6">
                      <Input
                        label="Código de Benefícios Fiscais (cBenef)"
                        name="cbenef"
                        type="text"
                      />
                    </Col>
                  </Row>

                  {csosnSelected === '500' && (
                    <Row style={{ paddingTop: 10 }}>
                      <Col sm="12" lg="6">
                        <InputMask mask="9999999">
                          <Input label="CEST" name="cest" type="text" />
                        </InputMask>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
              <Row>
                <Col md="12">
                  <SaveButton
                    type="submit"
                    style={{ marginTop: 20, float: 'right' }}
                  >
                    Salvar
                  </SaveButton>
                </Col>
              </Row>
            </Container>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default FiscalGroups;
