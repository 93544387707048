import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Form } from '@unform/web';
import { v4 } from 'uuid';

import { FiTrash } from 'react-icons/fi';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Spinner,
  // Button,
} from 'reactstrap';

import { Button } from 'ui-kit-takeat';

import InputMask from 'react-input-mask';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
  BillExplanation,
  InformationDiv,
  Total,
  Paid,
  PaymentDiv,
  PaymentTable,
  FinalDiv,
  AddPaymentForm,
  DeletePaymentForm,
  ButtonOrder,
  PaymentMethodButton,
  RescueButton,
  RescueDate,
  RescueContainer,
  LeftPrice,
} from './styles';

import api from '~/services/api';
import InputPaymentForm from '~/components/Form/InputPaymentForm';
import { useCart } from '~/context/OrderingSystem/Cart';
import { useAuth } from '~/context/AuthContext';
import { usePos } from '~/context/PosContext';

import Input from '../Form/Input';
import Select from '../Form/SelectInput';
import { useMenu } from '~/context/OrderingSystem/Menu';
import CheckboxInput from '../Form/Checkbox';
import apiClube from '~/services/apiClube';

import posIcon from '../../assets/img/pos-icon.svg';
import { BsCheckLg } from 'react-icons/bs';
import PersonalButton from '../Buttons/PersonalButton';
import PersonalOutlineButton from '../Buttons/PersonalOutlineButton';

export default function PaymentFormBalcony({
  tableId,
  client,
  clientId,
  setMakingOrder,
  toggleModalPayments,
  getSessions,
  setResponseNfce,
  setNFWarning,
  setIsModalOperationOpened,
  handleUpdate,
  commandTableNumber,
  session,
}) {
  const {
    user,
    toastUpdateSystemMessage3,
    setToastUpdateSystemMessage3,
    restaurantDiscountObs,
    setData,
  } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isTablePrintable, setIsTablePrintable] = useState(
    user?.print_balcony_default
  );
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);

  const [metodo, setMetodo] = useState('Outros');
  const { cart, addCartToOrder, closeCart } = useCart();
  const { getMenu } = useMenu();

  const { stoneTransactions, cancelStoneTransactionsBalcony, createStoneTransactionsBalcony } = usePos();

  const [modalCancelPos, setModalCancelPos] = useState(false);
  function toggleCancelPos(payment_id) {
    setPaymentIdToCancelStone(payment_id);
    setModalCancelPos(!modalCancelPos);
  }

  const [hasMoneyMethod, setHasMoneyMethod] = useState(false)

  const [paymentIdToCancelStone, setPaymentIdToCancelStone] = useState(null);

  const [discountObsOptions, setDiscountObsOptions] = useState([]);
  const [discountObs, setDiscountObs] = useState(null);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [paymentOption, setPaymentOption] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paid, setPaid] = useState(0);
  const [left, setLeft] = useState(0);
  const [leftInput, setLeftInput] = useState(() => {
    const totalProduct = cart.reduce((TotalAccumulator, order) => {
      const totalCategories = order.complement_categories.reduce(
        (categoryAccumulator, category) => {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach((complement) => {
              if (
                totalComplements <
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(complement.price) *
                  (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator + complement.price * complement.amount
              );
            },
            0
          );
          return categoryAccumulator + totalComplements;
        },
        0
      );

      if (order.weight) {
        return (
          TotalAccumulator +
          (parseFloat(totalCategories) +
            parseFloat(order.price * order.weight)) *
          order.amount
        );
      }
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
      );
    }, 0);

    return totalProduct.toFixed(2);
  }, []);

  const [loadingCashback, setLoadingCashback] = useState(false);
  const [value, setValue] = useState(leftInput);
  const [porcent, setPorcent] = useState(0);
  const [paymentsSession, setPaymentsSession] = useState([]);
  const [favoriteMethods, setFavoriteMethods] = useState([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [paymentsBill, setPaymentsBill] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountText, setDiscountText] = useState(false);
  const [clubInfo, setClubInfo] = useState({ phone: client?.phone, date: '' });
  const [clientCashback, setClientCashback] = useState({
    date: null,
    value: 0,
  });
  const [cnpj, setCnpj] = useState(null);
  const [adminPassword, setAdminPassword] = useState(null);

  const [loadingNfce, setLoadingNfce] = useState(false);
  const [formData, setFormData] = useState({
    type: 'nfce',
    doc: 'cpf',
  });
  const [typeSelected, setTypeSelected] = useState('nfce');
  const [docSelected, setDocSelected] = useState('cpf');
  const [ieSelected, setIeSelected] = useState('9');
  const [localDestinoSelected, setLocalDestinoSelected] = useState(1);
  const [ufSelected, setUfSelected] = useState(null);

  const [initialData, setInitialData] = useState({
    local_destino: 1,
    indicador_inscricao_estadual_destinatario: '9',
  });

  const type_options = [
    { value: 'nfce', label: 'NFCe' },
    { value: 'nfe', label: 'NFe' },
  ];

  const doc_options = [
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
  ];

  const local_options = [
    { value: 1, label: 'Operação Interna' },
    { value: 2, label: 'Operação Interestadual' },
  ];

  const ie_options = [
    {
      value: '1',
      label: '1 – Contribuinte ICMS (informar a IE do destinatário);',
    },
    {
      value: '2',
      label:
        '2 – Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS;',
    },
    {
      value: '9',
      label:
        '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
    },
  ];

  const getPaymentMethods = useCallback(async () => {
    const response = await api.get('restaurants/payment-methods');

    const methods = response.data.payment_methods.filter(
      (method) => method.available
    );

    methods.sort((a, b) => Number(b.is_favorite) - Number(a.is_favorite));

    const parsedCompleteData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
      method: data.method
    }));

    setAllPaymentMethods(parsedCompleteData);

    const favoriteMethodsSplice = methods.splice(0, 8);

    const parsedDataFav = favoriteMethodsSplice.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));

    setFavoriteMethods(parsedDataFav);

    const parsedData = methods.map((data) => ({
      id: data.id,
      label: data.name,
      available: data.available,
      keyword: data.keyword,
    }));
    setPaymentMethods(parsedData);
  }, []);

  const totalProduct = cart.reduce((TotalAccumulator, order) => {
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.more_expensive_only) {
          let totalComplements = 0;
          category.complements.forEach((complement) => {
            if (
              totalComplements <
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0)
            ) {
              totalComplements =
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0);
            }
          });

          return categoryAccumulator + totalComplements;
        }

        if (category.use_average) {
          const amountAverage = category.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0
          );

          if (amountAverage === 0) {
            return 0;
          }

          const totalPriceAverage =
            category.complements.reduce(
              (acum, curr) =>
                acum + (curr.delivery_price || curr.price) * curr.amount,
              0
            ) / amountAverage;

          return totalPriceAverage + categoryAccumulator;
        }

        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return complementAccumulator + complement.price * complement.amount;
          },
          0
        );
        return categoryAccumulator + totalComplements;
      },
      0
    );

    if (order.weight_price) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.weight_price)) *
        order.amount
      );
    }

    if (order.weight) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price * order.weight)) *
        order.amount
      );
    }
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  const getPaymentsSession = useCallback(async () => {
    let total = 0;

    paymentsSession.forEach((payment) => {
      total += parseFloat(payment.payment_value);
    });
    setPaid(total.toFixed(2).toString());

    const localLeft = totalProduct - total;

    // if (discount) {
    //   setLeft(parseFloat(localLeft.toFixed(2)) - Number(discount));

    //   console.log(parseFloat(localLeft.toFixed(2)) - Number(discount));
    //   console.log(parseFloat(localLeft.toFixed(2)));
    // } else {
    //   setLeft(parseFloat(localLeft.toFixed(2)));
    // }

    setLeft(parseFloat(localLeft.toFixed(2)));

    console.log(localLeft);

    const porcentPaid = (total / totalProduct) * 100;

    setPorcent(parseFloat(porcentPaid.toFixed(2)));
  }, [paymentsSession, totalProduct]);

  const deletePayments = useCallback(
    async (id) => {
      if (window.confirm('Tem certeza que deseja remover esse pagamento?')) {
        const payments_bill = paymentsSession.filter(
          (payment) => payment.id !== id
        );

        const payments_bill_find = paymentsSession.filter(
          (payment) => payment.id === id
        );
        document.getElementById('value').value =
          Number(left) + Number(payments_bill_find[0].payment_value);

        if (discount) {
          document.getElementById('left').value = Math.abs(
            Number(left) +
            Number(payments_bill_find[0].payment_value) -
            Number(discount)
          ).toFixed(2);
        } else {
          document.getElementById('left').value = Math.abs(
            Number(left) + Number(payments_bill_find[0].payment_value)
          ).toFixed(2);
        }

        setPaymentsSession(payments_bill);

        if (stoneTransactions
          .find(trans => trans.payment_balcony_id === id && trans.status !== 'canceled')) {
          cancelStoneTransactionsBalcony({ payment_balcony_id: id });
        }

        toast.success('Pagamento removido com sucesso!');
      }
    },
    [paymentsSession, setPaymentsSession, left, stoneTransactions]
  );

  const newPayments = useCallback(async () => {
    try {
      if (paymentOption.id) {
        const newPayment = {
          payment_value:
            paymentOption.id === 238 ? clientCashback.value : Number(value),
          payment_method_id: paymentOption.id,
          id: v4(),
        };

        if (left <= 0) {
          document.getElementById('value').value = (
            totalProduct - Number(value)
          ).toFixed(2);
        } else {
          document.getElementById('value').value = (
            left - Number(value)
          ).toFixed(2);

          // if (discount) {
          //   document.getElementById('left').value = Math.abs(
          //     Number(value) - Number(left) + Number(discount)
          //   ).toFixed(2);
          //   console.log('com desconto');
          //   console.log(left, value, discount);

          //   setLeft(
          //     Math.abs(Number(value) - Number(left) + Number(discount)).toFixed(
          //       2
          //     )
          //   );
          // } else {
          //   document.getElementById('left').value = Math.abs(
          //     left - Number(value)
          //   ).toFixed(2);
          //   console.log('sem desconto');
          //   console.log(left, value, discount);
          // }
        }

        setPaymentsSession([...paymentsSession, newPayment]);
        setClientCashback((state) => {
          return { ...state, value: 0 };
        });
        toast.success('Pagamento inserido com sucesso!');
      }
    } catch (error) {
      toast.error('Selecione o método de pagamento.');
    }
  }, [paymentsSession, paymentOption, left, totalProduct]);

  const storeNfce = useCallback(
    async (data) => {
      setLoadingNfce(true);
      try {
        let response;

        if (typeSelected === 'nfce') {
          const dataSent = { key: data.session.key || session?.session_key };

          if (data.email !== null && data.email !== '') {
            dataSent.email = data.email;
          }

          if (data.cpf !== '' && data.cpf !== null) {
            dataSent.cpf = data.cpf;
          }

          if (data.cnpj !== '' && data.cnpj !== null) {
            dataSent.cnpj = data.cnpj;
          }

          if (data.informacoes_adicionais_contribuinte) {
            dataSent.informacoes_adicionais_contribuinte =
              data.informacoes_adicionais_contribuinte;
          }

          response = await api.post('restaurants/new-nfce', dataSent);
        } else {
          const parsedData = {
            key: data.session.key || session.session_key,
            cnpj_destinatario: data.cnpj,
            cpf: data.cpf,
            nome_destinatario: data.nome_destinatario,
            logradouro_destinatario: data.logradouro_destinatario,
            numero_destinatario: data.numero_destinatario,
            bairro_destinatario: data.bairro_destinatario,
            municipio_destinatario: data.municipio_destinatario,
            uf_destinatario: data.uf_destinatario,
            indicador_inscricao_estadual_destinatario: ieSelected,
            local_destino: localDestinoSelected,
            informacoes_adicionais_contribuinte:
              data.informacoes_adicionais_contribuinte,
          };

          if (data.inscricao_estadual_destinatario !== '') {
            parsedData.inscricao_estadual_destinatario =
              data.inscricao_estadual_destinatario;
          }

          if (data.email !== null && data.email !== '') {
            parsedData.email = data.email;
          }

          response = await api.post('restaurants/new-nfe', parsedData);
          getSessions();
          // document.getElementById('update_button').click();
        }

        if (response.data.status_sefaz === '100') {
          if (data.type === 'nfce') {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );
          } else {
            window.open(
              `https://api.focusnfe.com.br${response.data.caminho_danfe}`,
              'janela',
              'width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no'
            );
          }
        }
        setResponseNfce(response.data);
        setClientCashback({ phone: '', date: '' });
        toggleModalPayments();
        closeCart();
        setIsModalOperationOpened(false);
        setLoadingNfce(false);
        if (response.data.status_sefaz !== '100') {
          setNFWarning(true);
        }
      } catch (error) {
        setLoadingNfce(false);
        console.log('error', error);
        toast.error(
          'Não foi possível finalizar e emitir a Nota Fiscal! Tente novamente'
        );
      }
    },
    [session, formData, typeSelected]
  );

  async function makeOrder(withNfce) {
    if (discount && !discountObs) {
      toast.error('Você deve informar um motivo para o desconto');
      return;
    }

    if (
      discount &&
      user.order_cancel_password &&
      (!adminPassword || adminPassword === '')
    ) {
      toast.error('Insira a senha administrativa.');
      return;
    }

    if (discount && user.order_cancel_password && adminPassword) {
      if (adminPassword !== user.order_cancel_password) {
        toast.error('Senha administrativa incorreta.');
        return;
      }
    }

    withNfce ? setLoadingNfce(true) : setMakingOrder(true);

    const res = await addCartToOrder(
      tableId,
      clientId || null,
      'in_person',
      null,
      null,
      null,
      paymentsSession,
      isTablePrintable,
      commandTableNumber,
      getMenu,
      parseFloat(discount?.replace(',', '.')),
      discountObs,
      user.user_id,
      clubInfo.phone,
      null,
      adminPassword
    );

    if (!res) {
      setMakingOrder(false);
      setLoadingNfce(false);
      return;
    }

    setMakingOrder(false);
    if (clubInfo.phone?.length > 0) {
      if (res?.data.cashbackAdded) {
        toast.success('Cliente pontuado!');
      } else {
        toast.error('Não foi possível pontuar o cliente');
      }
    }
    if (withNfce && res) {
      storeNfce({ ...formData, session: res.data.tableBill });
    } else {
      setClubInfo({ phone: client?.phone, date: '' });
      toggleModalPayments();
      closeCart();
      setIsModalOperationOpened(false);
    }

    handleUpdate();
  }

  const handleChangeDiscount = (target) => {
    const targetValue = target.value.replace(/(R|\$|%)/g, '');

    if (targetValue.length < 1) {
      setDiscount('');
      setDiscountPercent('');
      setValue(leftInput);
    } else if (target.name === 'flat_discount') {
      setDiscount(targetValue);
      setDiscountPercent(
        (
          (parseFloat(targetValue.replace(',', '.')) / totalProduct) *
          100
        ).toFixed(2)
      );
      setValue((left - parseFloat(targetValue.replace(',', '.'))).toFixed(2));
    } else {
      setDiscount(
        (
          (parseFloat(targetValue.replace(',', '.')) * totalProduct) /
          100
        ).toFixed(2)
      );
      setDiscountPercent(targetValue);
      setValue(
        (
          left -
          (parseFloat(targetValue.replace(',', '.')) * totalProduct) / 100
        ).toFixed(2)
      );
    }
  };

  const handleClearDiscount = (value) => {
    if (value === true) {
      setDiscount('');
      setDiscountPercent('');
      setValue(leftInput);
    }
  }

  const getCashback = useCallback(async () => {
    try {
      let login_club;
      setLoadingCashback(true);
      if (!user.club_login) {
        login_club = await apiClube.post('/public/sessions/takeat', {
          token: user.token_clube,
        });

        localStorage.setItem(
          '@gddashboard:user',
          JSON.stringify({
            ...user,
            club_login: login_club.data.token,
            minimo: login_club.data.user?.settings[0]?.minimo,
          })
        );

        setData((state) => {
          return {
            ...state,
            user: {
              ...user,
              club_login: login_club.data.token,
              minimo: login_club.data.user?.settings[0]?.minimo,
            },
          };
        });
      }
      const response = await apiClube.get(`/store/cashback/${clubInfo.phone}`, {
        headers: {
          Authorization: `Bearer: ${user.club_login || login_club.data.token}`,
        },
      });

      setClientCashback(response.data);
    } catch (err) {
      setClientCashback((state) => {
        return { ...state, value: 0 };
      });
      if (err.response?.data?.errorType === 'default_error') {
        toast.error(err.response.data.message);
      } else {
        toast.error(
          'Não foi possível buscar o cashback referente a este telefone'
        );
      }
      console.log('getCashback error: ', err);
    }
    setLoadingCashback(false);
  }, [user.club_login, clubInfo.phone]);

  async function setMetodoAndPaymentOption(payment) {
    if (
      payment.id === 238 &&
      parseFloat(clientCashback.value) < parseFloat(user.minimo)
    ) {
      toast.error('Valor do resgate abaixo do mínimo do clube');
      return;
    }
    setMetodo(payment.label);
    setPaymentOption({ id: payment.id, label: payment.label });
  }

  useEffect(() => {
    const paymentMethodFiltered = paymentsSession?.filter(payment => payment.payment_method_id === 161)
    if (paymentMethodFiltered?.length > 0) {
      setHasMoneyMethod(true)

    } else {
      setHasMoneyMethod(false)

    }
  }, [paymentsSession])

  useEffect(() => {
    getPaymentsSession();
  }, [getPaymentsSession]);

  useEffect(() => {
    const payments_bill = session?.payments;

    setPaymentsBill(payments_bill);
  }, [session]);

  useEffect(() => {
    newPayments();
  }, [paymentOption]);

  useEffect(() => {
    if (discount) {
      setValue((left - parseFloat(discount.replace(',', '.'))).toFixed(2));
    } else {
      setValue(left);
    }

    setMetodo('Outros');
  }, [left, discount]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);

  useEffect(() => {
    const obs_options = restaurantDiscountObs?.map((disc) => ({
      value: disc.id,
      label: disc.obs,
    }));
    setDiscountObsOptions([...obs_options, { value: 'other', label: 'Outro' }]);
  }, [restaurantDiscountObs]);

  const [toastMessage, setToastMessage] = useState(
    toastUpdateSystemMessage3.toString() === 'true'
  );

  function toggleToast() {
    setToastUpdateSystemMessage3(false);

    setToastMessage(false);
  }

  const getNfeClientInfo = useCallback(async (cnpj) => {
    try {
      const response = await api.get(`restaurants/nfe-client/${cnpj}`);

      if (response.data) {
        setInitialData({
          nome_destinatario: response.data.nome,
          logradouro_destinatario: response.data.logradouro,
          numero_destinatario: response.data.numero,
          bairro_destinatario: response.data.bairro,
          municipio_destinatario: response.data.municipio,
          uf_destinatario: response.data.uf,
          indicador_inscricao_estadual_destinatario:
            response.data.indicador_inscricao_estadual || '9',
          inscricao_estadual_destinatario: response.data.inscricao_estadual,
          local_destino: response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1,
          email: response.data.email,
        });

        setFormData({
          ...formData,
          nome_destinatario: response.data.nome,
          logradouro_destinatario: response.data.logradouro,
          numero_destinatario: response.data.numero,
          bairro_destinatario: response.data.bairro,
          municipio_destinatario: response.data.municipio,
          uf_destinatario: response.data.uf,
          indicador_inscricao_estadual_destinatario:
            response.data.indicador_inscricao_estadual || '9',
          inscricao_estadual_destinatario: response.data.inscricao_estadual,
          local_destino: response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1,
          email: response.data.email,
        });

        setIeSelected(response.data.indicador_inscricao_estadual || '9');
        setLocalDestinoSelected(
          response.data.local_destino
            ? parseInt(response.data.local_destino)
            : 1
        );
        setUfSelected(response.data.uf);
      }

      // toast.success('Comanda finalizada com sucesso');
    } catch (error) {
      // toast.error(
      //   'Não foi possível finalizar a comanda duplicada Menew! Tente novamente'
      // );
    }
  }, []);

  useEffect(() => {
    const thisCnpj = cnpj
      ?.replaceAll('.', '')
      .replaceAll('/', '')
      .replaceAll('_', '')
      .replaceAll('-', '');
    if (thisCnpj?.length === 14 && docSelected === 'cnpj') {
      getNfeClientInfo(thisCnpj);
    } else if (thisCnpj?.length === 11) {
      getNfeClientInfo(thisCnpj);
    }
  }, [cnpj]);

  useEffect(() => {
    if (user.has_clube && clubInfo.phone?.length === 15) {
      getCashback();
    }
  }, [clubInfo.phone]);

  useEffect(() => {
    if (
      clubInfo.date.length === 10 &&
      format(new Date(clientCashback.date), 'dd/MM/yyyy') !== clubInfo.date
    ) {
      toast.error('Data informada não coincide com a cadastrada');
    }
  }, [clubInfo.date]);

  function getLeftName(left) {
    if (discount) {
      if (left > 0 && left > discount) {
        return 'Restante';
      } else if (left > 0 && left < discount && hasMoneyMethod) {
        return 'Troco';
      } else if (left > 0 && left < discount && !hasMoneyMethod) {
        return 'Extra';
      } else if (left < 0 && hasMoneyMethod) {
        return 'Troco';
      } else if (left < 0 && !hasMoneyMethod) {
        return 'Extra';
      } else {
        return 'Restante';
      }
    } else if (left < 0 && !hasMoneyMethod) {
      return 'Extra';
    } else if (left < 0) {
      return 'Troco';
    } else {
      return 'Restante';
    }
  }

  // Diferença entre o valor da comanda e os pagamentos
  const [totalDifference, setTotalDifference] = useState(0);
  const [showModalDifference, setShowModalDifference] = useState(false);

  useEffect(() => {
    const paid = paymentsSession.reduce((total, payment) => {
      return total + parseFloat(payment.payment_value);
    }, 0);

    const diff = parseFloat((totalProduct - discount - paid).toFixed(2));
    setTotalDifference(diff);
  }, [paymentsSession, totalProduct, discount]);

  const [loadingPos, setLoadingPos] = useState(false);

  const handleStoneTransactions = useCallback(async ({ payment_value, method, payment_balcony_id }) => {
    setLoadingPos(true);
    try {
      await createStoneTransactionsBalcony({ payment_value, method, payment_balcony_id });
    } catch (err) {

    }
    setLoadingPos(false);
  }, []);

  const handleCancelStoneTransactions = useCallback(async () => {
    setLoadingPos(true);
    try {
      await cancelStoneTransactionsBalcony({ payment_balcony_id: paymentIdToCancelStone });
      toggleCancelPos();
    } catch (err) {

    }
    setLoadingPos(false);
  }, [paymentIdToCancelStone]);

  const uf_options = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  return (
    <>
      <ModalBody>
        <Row>
          <Col md={user.has_nfce ? "6" : "12"}>
            <BillExplanation>
              <h5>Finalizar pedido </h5>
              <h7>
                Adicione o(s) pagamento(s) já feitos na comanda de balcão e finalize
                seu pedido.
              </h7>
            </BillExplanation>
            <Form>
              <CheckboxInput
                label="Aplicar desconto"
                name="apply"
                value={applyDiscount}
                onChange={() => { setApplyDiscount(!applyDiscount); handleClearDiscount(applyDiscount) }}
              />
            </Form>
            {applyDiscount && (
              <>
                <span style={{ color: 'grey' }}>Desconto</span>
                <div
                  style={{
                    display: 'flex',
                    margin: '10px 0 0',
                    position: 'relative',
                  }}
                >
                  <input
                    name="flat_discount"
                    type="text"
                    placeholder="R$"
                    style={{ width: '50%', padding: '3px 10px' }}
                    value={discount && `R$${discount}`}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  <input
                    name="discount"
                    placeholder="%"
                    type="text"
                    style={{ width: '50%', padding: '3px 10px', marginLeft: '5px' }}
                    value={discountPercent && discountPercent}
                    onChange={(e) => handleChangeDiscount(e.target)}
                  />
                  {discount && (
                    <p style={{ position: 'absolute', right: '15px', top: '5px' }}>
                      %
                    </p>
                  )}
                </div>
                <Form>
                  <Select
                    label={null}
                    name="discount_obs"
                    options={discountObsOptions}
                    onChange={(e) => {
                      if (e.value === 'other') {
                        setDiscountText(true);
                      } else {
                        setDiscountText(false);
                        setDiscountObs(e.label);
                      }
                    }}
                  />
                  {discountText && (
                    <Input
                      type="text"
                      placeholder="Escreva o motivo"
                      id="discount_obs"
                      name="discount_obs"
                      onChange={(e) => setDiscountObs(e.target.value)}
                    />
                  )}
                  {user.order_cancel_password !== '' && (
                    <Input
                      placeholder="Senha administrativa"
                      id="cancel_password"
                      name="cancel_password"
                      type="password"
                      value={adminPassword}
                      onChange={(e) => setAdminPassword(e.target.value)}
                    />
                  )}
                </Form>
              </>
            )}

            {user.has_clube && (
              <Form
                style={{
                  borderBottom: '1px solid #dee2e6',
                  transition: 'all 0.4s',
                }}
              >
                <RescueContainer>
                  {loadingCashback && (
                    <Spinner
                      style={{
                        position: 'absolute',
                        top: 49,
                        left: 135,
                        zIndex: 10,
                        height: 15,
                        width: 15,
                      }}
                    />
                  )}
                  <InputMask
                    mask="(99) 99999-9999"
                    maskPlaceholder={null}
                    defaultValue={clubInfo.phone}
                    value={clubInfo.phone}
                    onChange={(e) => {
                      const newText = e.target.value;
                      setClubInfo((state) => {
                        return { ...state, phone: newText };
                      });
                    }}
                  >
                    <Input
                      label="Celular:"
                      name="club-phone"
                      type="text"
                      placeholder="Celular para pontuar no clube"
                    />
                  </InputMask>

                  <RescueDate>
                    <InputMask
                      id="date"
                      mask="99/99/9999"
                      maskPlaceholder={null}
                      defaultValue={clubInfo.date}
                      value={clubInfo.date}
                      onChange={(e) => {
                        const newText = e.target.value;
                        setClubInfo((state) => {
                          return { ...state, date: newText };
                        });
                      }}
                    >
                      <Input
                        label="Aniversário:"
                        name="club-birthday"
                        type="text"
                        placeholder="dd/mm/aaaa"
                      />
                    </InputMask>

                    <RescueButton
                      disabled={
                        format(
                          new Date(clientCashback?.date || null),
                          'dd/MM/yyyy'
                        ) !== clubInfo?.date ||
                        parseFloat(clientCashback?.value) <= 0
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          paymentsSession.find((p) => p.payment_method_id === 238)
                        ) {
                          toast.error('Este telefone já realizou o resgate');
                        } else if (parseFloat(clientCashback.value) > 0) {
                          setMetodoAndPaymentOption({
                            label: 'Resgate Clube',
                            id: 238,
                          });
                        }
                      }}
                    >
                      {`Resgatar R$${clientCashback?.value}`}
                    </RescueButton>
                  </RescueDate>
                </RescueContainer>
              </Form>
            )}

            <InformationDiv>
              <Total>
                <span>Total</span>

                <h4>
                  R$
                  {(
                    totalProduct - (parseFloat(discount?.replace(',', '.')) || 0)
                  ).toFixed(2)}
                </h4>
              </Total>

              <Paid>
                <span>{getLeftName(left)}</span>
                <LeftPrice
                  id="left"
                  value={
                    left > 0
                      ? Math.abs(
                        left - (parseFloat(discount?.replace(',', '.')) || 0)
                      ).toFixed(2)
                      : (
                        Math.abs(Number(left)) +
                        (parseFloat(discount?.replace(',', '.')) || 0)
                      ).toFixed(2)
                  }
                  readOnly
                />
              </Paid>
              <Form>
                <InputPaymentForm
                  placeholder="R$"
                  type="number"
                  step="0.01"
                  min="0"
                  name="value"
                  id="value"
                  value={value > 0 ? value : 0}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form>
            </InformationDiv>

            <PaymentDiv>
              <div
                style={{
                  position: 'fixed',
                  top: 300,
                  left: 290,
                  width: '415px',
                  height: 210,
                  backgroundColor: '#fff',
                  borderRadius: 7,
                  padding: 20,
                  filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
                  display: toastMessage ? 'flex' : 'none',
                  zIndex: 2000000,
                }}
              >
                <div style={{}}>
                  <p style={{ fonteWeight: 'bold', fontSize: 18 }}>
                    <FaExclamationTriangle color="darkOrange" />{' '}
                    <strong>Atenção, nova Atualização!</strong>
                  </p>
                  <span>
                    Agora é possível favoritar os métodos de pagamento para que eles
                    apareçam nessa lista e agilize ainda mais a sua escolha. Se
                    quiser alterar o método de pagamento favorito,{' '}
                    <Link to="/box/payment"> clique aqui.</Link>
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    type="button"
                    style={{
                      background: '#3BD2C1',
                      color: '#fff',
                      width: 54,
                      height: 48,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: 7,
                      marginLeft: 10,
                      fontWeight: 'bold',
                      border: 'none',
                    }}
                    onClick={(e) => toggleToast(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
              <AddPaymentForm>
                {favoriteMethods.map((payment) =>
                  payment.available &&
                    payment.keyword !== 'clube' &&
                    payment.keyword !== 'pix_auto' ? (
                    <div key={payment.id}>
                      <PaymentMethodButton
                        onClick={() => setMetodoAndPaymentOption(payment)}
                      >
                        {payment.label}
                      </PaymentMethodButton>
                    </div>
                  ) : (
                    <></>
                  )
                )}

                {paymentMethods.length > 0 && favoriteMethods.length >= 8 && (
                  <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggleDrop}>
                    <DropdownToggle
                      color="danger"
                      caret
                      style={{ width: '100px', height: '40px', margin: 0 }}
                    >
                      {metodo}
                    </DropdownToggle>
                    <DropdownMenu style={{ overflowY: 'scroll', height: 300 }}>
                      {paymentMethods.map((payment) =>
                        payment.available &&
                          payment.keyword !== 'clube' &&
                          payment.keyword !== 'pix_auto' ? (
                          <div key={payment.id}>
                            <DropdownItem
                              onClick={() => setMetodoAndPaymentOption(payment)}
                            >
                              {payment.label}
                            </DropdownItem>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </AddPaymentForm>

              <PaymentTable increaseMargin={favoriteMethods.length >= 8} borderless>
                <tbody>
                  {paymentsSession &&
                    paymentsSession.map(
                      (payment) =>
                        payment.payment_method_id === 238 && (
                          <tr key={payment.id} style={{ color: '#017957' }}>
                            <td>Resgate Clube</td>
                            <td>R${payment.payment_value}</td>
                          </tr>
                        )
                    )}

                  {paymentsSession &&
                    paymentsSession.map(
                      (payment) =>
                        payment.payment_method_id !== 238 && (
                          <tr key={payment.id}>
                            {allPaymentMethods
                              .filter(
                                (method) => method.id === payment.payment_method_id
                              )
                              .map((method) =>
                                method.available ? (
                                  <td key={method.id}>{method.label}</td>
                                ) : (
                                  <></>
                                )
                              )}
                            <td>R${payment.payment_value}</td>
                            <td />
                            <td>
                              <div>
                                <DeletePaymentForm
                                  onClick={() => deletePayments(payment.id)}
                                >
                                  <FiTrash size={15} />
                                </DeletePaymentForm>
                              </div>
                            </td>

                            {user.has_stone_pdv
                              && ['CREDIT', 'DEBIT', 'credit', 'debit'].includes(allPaymentMethods.find(pay => pay.id === payment.payment_method_id)?.method || false)
                              && parseFloat(payment.payment_value) > 0
                              &&
                              <td style={{ textAlign: 'center' }}>
                                {
                                  !stoneTransactions.find(trans => trans.payment_balcony_id === payment.id && trans.status !== 'canceled') &&
                                  <div>
                                    <DeletePaymentForm
                                      onClick={() => !loadingPos && handleStoneTransactions({ payment_value: payment.payment_value, payment_balcony_id: payment.id, method: allPaymentMethods.find(pay => pay.id === payment.payment_method_id)?.method })}
                                      style={loadingPos ? { pointerEvents: 'none' } : {}}
                                    >
                                      <img
                                        src={posIcon}
                                        style={{ height: 18 }}
                                      />
                                    </DeletePaymentForm>
                                  </div>
                                }

                                {
                                  stoneTransactions.find(trans => trans.payment_balcony_id === payment.id && trans.status === 'pending') &&
                                  <div>
                                    <DeletePaymentForm
                                      onClick={() => toggleCancelPos(payment.id)}
                                      disabled={loadingPos}
                                    >
                                      <Spinner size="sm" color="#3BD2C1" style={{ width: 15, height: 15, marginBottom: 4, color: "#3BD2C1" }} />
                                    </DeletePaymentForm>
                                  </div>
                                }

                                {
                                  stoneTransactions.find(trans => trans.payment_balcony_id === payment.id && trans.paid_at !== null) &&
                                  <div>
                                    <DeletePaymentForm
                                      // onClick={() => handleStoneTransactions(payment.id)}
                                      disabled={loadingPos}
                                    >
                                      <BsCheckLg style={{ width: 20, height: 20, color: "#3BD2C1" }} />
                                    </DeletePaymentForm>
                                  </div>
                                }
                              </td>
                            }
                          </tr>
                        )
                    )}
                </tbody>
              </PaymentTable>
            </PaymentDiv>

            <FinalDiv>
              <Total>
                <span>Pago</span>
                <h4>R${paid}</h4>
              </Total>
              <Total>
                <span>Pago</span>
                <h4>
                  {paid /
                    (totalProduct -
                      (parseFloat(discount?.replace(',', '.')) || 0)) >
                    1
                    ? '100'
                    : (
                      (paid /
                        (totalProduct -
                          (parseFloat(discount?.replace(',', '.')) || 0))) *
                      100
                    ).toFixed(2)}
                  %
                </h4>
              </Total>
            </FinalDiv>
          </Col>
          {
            user.has_nfce &&
            <Col md="6">
              {user.has_nfce && (
                <Form onSubmit="" initialData={initialData}>
                  <Row>
                    <Col lg="12">
                      {!session?.nfce_allowed && (
                        <>
                          <Row>
                            <Col md="12">
                              <Select
                                label="Tipo:"
                                name="type"
                                options={type_options}
                                defaultValue={{ value: 'nfce', label: 'NFCe' }}
                                onChange={(e) => {
                                  setFormData({ ...formData, type: e.value });
                                  if (e.value === 'nfe') {
                                    setDocSelected('cnpj');
                                  } else {
                                    setDocSelected('cpf');
                                  }
                                  setTypeSelected(e.value);
                                }}
                              />
                            </Col>
                          </Row>
                          {typeSelected === 'nfce' && (
                            <>
                              <Row>
                                <Col md="3">
                                  <div style={{ marginTop: 13 }}>
                                    <Select
                                      label="Documento:"
                                      name="doc"
                                      options={doc_options}
                                      defaultValue={{ value: 'cpf', label: 'CPF' }}
                                      onChange={(e) => {
                                        setFormData({ ...formData, doc: e.value });
                                        setDocSelected(e.value);
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="9">
                                  {docSelected === 'cpf' ? (
                                    <InputMask
                                      mask="999.999.999-99"
                                      onBlur={(e) =>
                                        setFormData({
                                          ...formData,
                                          cpf: e.target.value,
                                        })
                                      }
                                    >
                                      <Input
                                        label="CPF: (opcional)"
                                        name="cpf"
                                        type="text"
                                        placeholder="xxx.xxx.xxx-xx"
                                      />
                                    </InputMask>
                                  ) : (
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      onBlur={(e) =>
                                        setFormData({
                                          ...formData,
                                          cnpj: e.target.value,
                                        })
                                      }
                                    >
                                      <Input
                                        label="CNPJ: (opcional)"
                                        name="cnpj"
                                        type="text"
                                        placeholder="xx.xxx.xxx/xxxx-xx"
                                      />
                                    </InputMask>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <Input
                                    label="Informações Adicionais"
                                    name="informacoes_adicionais_contribuinte"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        informacoes_adicionais_contribuinte:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          )}

                          {typeSelected === 'nfe' && (
                            <>
                              <Row>
                                <Col md="3">
                                  <div style={{ marginTop: 13 }}>
                                    <Select
                                      label="Documento:"
                                      name="doc"
                                      options={doc_options}
                                      defaultValue={
                                        docSelected === 'cpf'
                                          ? { value: 'cpf', label: 'CPF' }
                                          : { value: 'cnpj', label: 'CNPJ' }
                                      }
                                      onChange={(e) => {
                                        setDocSelected(e.value);
                                        if (e.value === 'cpf') {
                                          setIeSelected('2');
                                        } else {
                                          setIeSelected('9');
                                        }
                                      }}
                                    />
                                  </div>
                                </Col>
                                {docSelected === 'cpf' ? (
                                  <Col md="9">
                                    <InputMask
                                      mask="999.999.999-99"
                                      onChange={(e) => setCnpj(e.target.value)}
                                      onBlur={(e) =>
                                        setFormData({
                                          ...formData,
                                          cpf: e.target.value,
                                        })
                                      }
                                    >
                                      <Input
                                        label="CPF:"
                                        name="cpf"
                                        type="text"
                                        placeholder="xxx.xxx.xxx-xx"
                                      />
                                    </InputMask>
                                  </Col>
                                ) : (
                                  <Col md="9">
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      onBlur={(e) =>
                                        setFormData({
                                          ...formData,
                                          cnpj: e.target.value,
                                        })
                                      }
                                      onChange={(e) => setCnpj(e.target.value)}
                                    >
                                      <Input
                                        label="CNPJ:"
                                        name="cnpj"
                                        type="text"
                                        placeholder="xx.xxx.xxx/xxxx-xx"
                                      />
                                    </InputMask>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    label="Nome:"
                                    name="nome_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        nome_destinatario: e.target.value,
                                      })
                                    }
                                  />
                                </Col>

                                <Col>
                                  <Input
                                    label="Logradouro:"
                                    name="logradouro_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        logradouro_destinatario: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="3">
                                  <Input
                                    label="Número:"
                                    name="numero_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        numero_destinatario: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md="6">
                                  <Input
                                    label="Bairro:"
                                    name="bairro_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        bairro_destinatario: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md="3">
                                  {/* <Input
                                  label="UF:"
                                  name="uf_destinatario"
                                  type="text"
                                  placeholder=""
                                  
                                  // value={initialData?.uf_destinatario && uf_options.filter(local => local.value === initialData?.uf_destinatario)}
                                  // onChange={(e) => setUfSelected(e.value)}
                                /> */}
                                  <Select
                                    label="UF:"
                                    name="uf_destinatario"
                                    placeholder="Estado"
                                    options={uf_options}
                                    onChange={(e) => setUfSelected(e.value)}
                                    value={
                                      uf_options.filter(
                                        (local) => local.value === ufSelected
                                      ) || null
                                    }
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        uf_destinatario: e.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col md="6">
                                  <Input
                                    label="Município:"
                                    name="municipio_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        municipio_destinatario: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                                <Col>
                                  <div style={{ marginTop: 13 }}>
                                    <Select
                                      label="Estado de origem:"
                                      name="local_destino"
                                      options={local_options}
                                      // defaultValue={{
                                      //   value: 1,
                                      //   label: 'Operação Interna',
                                      // }}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          local_destino: e.value,
                                        });
                                        setLocalDestinoSelected(e.value);
                                      }}
                                      value={local_options.filter(
                                        (local) =>
                                          local.value === localDestinoSelected
                                      )}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div style={{ marginTop: 15 }}>
                                    <Select
                                      label="Indicador da Inscrição Estadual:"
                                      name="indicador_inscricao_estadual_destinatario"
                                      options={ie_options}
                                      // defaultValue={{
                                      //   value: '9',
                                      //   label:
                                      //     '9 – Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes do ICMS.',
                                      // }}
                                      onChange={(e) => {
                                        setIeSelected(e.value);
                                        setFormData({
                                          ...formData,
                                          indicador_inscricao_estadual_destinatario:
                                            e.value,
                                        });
                                      }}
                                      value={ie_options.filter(
                                        (local) => local.value === ieSelected
                                      )}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <Input
                                    label={
                                      ieSelected === '1'
                                        ? 'Inscrição Estadual: (obrigatório)'
                                        : 'Inscrição Estadual: (opcional)'
                                    }
                                    name="inscricao_estadual_destinatario"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        inscricao_estadual_destinatario:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <Input
                                    label="Informações Adicionais"
                                    name="informacoes_adicionais_contribuinte"
                                    type="text"
                                    placeholder=""
                                    onBlur={(e) =>
                                      setFormData({
                                        ...formData,
                                        informacoes_adicionais_contribuinte:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </>
                          )}
                          <Row>
                            <Col md="12">
                              <Input
                                label="E-mail para enviar cópia da Nota Fiscal: (opcional)"
                                name="email"
                                type="email"
                                placeholder="exemplo@gmail.com"
                                onBlur={(e) =>
                                  setFormData({ ...formData, email: e.target.value })
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
          }
        </Row>
        <Modal
          isOpen={showModalDifference}
          toggle={() => setShowModalDifference(false)}
        >
          <ModalBody>
            <h5 style={{ color: 'red' }}>
              O total dos pagamentos é {totalDifference > 0 ? 'menor' : 'maior'}{' '}
              do que o valor da comanda.
            </h5>
            <p>
              Você deseja emitir a nota fiscal e finalizar a comanda com um
              {totalDifference > 0 ? ' desconto' : ' acréscimo'} de R$
              {Math.abs(totalDifference).toFixed(2)}?{' '}
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonOrder
              style={{ background: '#dc3545' }}
              onClick={() => setShowModalDifference(false)}
            >
              Não
            </ButtonOrder>
            <ButtonOrder
              style={{ background: 'green' }}
              disabled={!showModalDifference}
              onClick={() => {
                setShowModalDifference(false);
                makeOrder(true);
              }}
            >
              Sim
            </ButtonOrder>
          </ModalFooter>
        </Modal>
      </ModalBody>
      <ModalFooter style={{ margin: 0 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {user.auto_print_orders && (
            <div style={{ display: "flex" }}>
              <input
                type="checkbox"
                id="print_table"
                name="print_table"
                onChange={(e) => {
                  setIsTablePrintable(!isTablePrintable);
                }}
                defaultChecked={isTablePrintable}
              />
              <label
                htmlFor="print_table"
                style={{ fontWeight: 'bold', marginLeft: 10 }}
              >
                Imprimir a comanda.
              </label>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <PersonalOutlineButton
              type="button"
              disabled={loadingNfce}
              onClick={() => makeOrder(false)}
              borderColor="#2EC9B7"
              fontColor="#2EC9B7"
              message="Fechar comanda"
              color="transparent"
            />

            {user.has_nfce && (
              <PersonalButton
                type="submit"
                onClick={() => {
                  if (totalDifference === 0) {
                    makeOrder(true);
                  } else {
                    setShowModalDifference(true);
                  }
                }}
                disabled={loadingNfce}
                color="#2EC9B7"
                message={loadingNfce
                  ? 'Emitindo Nota Fiscal...'
                  : 'Finalizar e Emitir Nota Fiscal'}
              />


            )}
          </div>
        </div>
      </ModalFooter>

      <Modal isOpen={modalCancelPos} toggle={toggleCancelPos}>
        <ModalBody style={{ padding: 20 }}>
          <Row style={{ paddingTop: 15 }}>
            <Col md="12">
              Deseja cancelar esse pagamento na POS?
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter style={{ marginLeft: 'auto' }}>
          <Button title="Sim, cancelar" type="default" disabled={loadingPos} onClick={e => handleCancelStoneTransactions()} />
        </ModalFooter>
      </Modal>
    </>
  );
}
