import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { Progress } from 'reactstrap';

export const Wrapper = styled.div`
  padding: 20px 40px 0px 40px;
`;

export const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Card = styled.div`
  margin: 0px auto;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Content = styled.div`
  width: 100%;
  heigth: auto;
`;
export const TablesPlace = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
`;

export const BillExplanation = styled.div`
  width: 50%;
  margin-left: 60px;
  margin-top: 20px;
`;

export const Badge = styled.div`
  width: 16px;
  height: 16px;
  font-size: 13px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
`;

export const BadgeDiv = styled.div`
  display: flex;
`;

export const DateSelectorDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: center;
  margin-top: 10px;
`;

export const DateDiv = styled.div`
  float: left;
  left: 0;
  margin-left: 15px;
`;

export const SubmitDate = styled.button`
  height: 30px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  border: none;
  background: #17a2b8;
  margin-left: 10px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }
`;

export const ButtonAloneArea = styled.div`
  flex-direction: row;
  text-align: center;
  padding: 15px;
`;

export const DiscountButton = styled.button`
  border-radius: 50%;
  padding: 15px 15px;
  margin: auto;
  margin-bottom: 5px;

  color: white;
  font-size: 12px;

  border: none;
  background: purple;

  display: flex;
  flex-direction: row;
  text-align: center;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;


export const ButtonsArea = styled.div`
  bottom: 0px;
  display: flex;
  margin-left: auto;
  float: right;
  button {
    font-size: 8px;
  }
`;

export const SelectDateButton = styled.button`
  height: 40px;
  border-radius: 20px;
  border: none;
  padding: 10px;
  color: white;
  align-self: center;
  background: #17a2b8;
  margin-left: 10px;
  margin-right: 15px;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
  }

  :selected {
    border: none;
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const DataPickerArea = styled.div`
  display: inline-block;
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 40px;
  padding: 10px;

  margin-right: 15px;
  margin-left: 10px;

  border-radius: 20px;
  align-self: center;
  border: 1px solid ${(props) => props.theme.colors.lightGray};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 20px;
  }
`;

export const Progress2 = styled(Progress)`
  height: 15px;
  box-shadow: none;
  .bg-primary {
    background-color: #0d6efd !important;
  }
`;
