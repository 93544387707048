import React, { useState, useRef } from 'react';
import { AiOutlinePrinter, AiOutlineEye } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-hot-toast';
import PrintBillsIndividual from '~/components/Prints/PrintBillsIndividual';
import EyeBillsIndividual from '~/components/Tables/CloseOrder/Eye/EyeBillsIndividual';

import {
  Pedido,
  OrderNumber,
  Time,
  ContentBody,
  TableNumber,
  PhoneNumber,
  Actions,
  DeleteAndEditActions,
  AcceptButton,
  SeeButton,
  OrdersInfo,
  Badge,
  CancelButton,
} from '../styles';
import { Print, DisplayNoneImprimir } from './styles';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import formatPhone from '~/services/formatPhoneString';
import { OrderStatusTag } from 'ui-kit-takeat';
import { InfoDiv } from '~/components/Orders/Items/styles';

export default function CloseIndividual({
  item,
  handlePrintItem,
  individualBills,
}) {
  const { user } = useAuth();
  const componentRef = useRef(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const userName = item.bill.buyer
    ? formatPhone(item.bill.buyer.phone)
    : item.bill.waiter.name;

  async function closeClick() {
    try {
      await api.post('restaurants/printers/printer-queue', {
        individual_bill_id: item.bill.id,
      });
      handlePrintItem({
        session_key: item.session_key,
        bill_id: item.bill.id,
      });
    } catch (error) {
      toast.error('Erro ao fechar comanda.');
    }
  }

  return (
    <Pedido>
      <ContentBody>
        <div>
          <OrderStatusTag
            text="Fechamento individual"
            color="#FFA814"
            width="185px"
          />
        </div>

        {/* <div>
          <CancelButton />
        </div> */}
        <InfoDiv>
          <OrderNumber>#{item.bill.id}</OrderNumber>
          <Time>{formatTime(item.bill.updatedAt)}</Time>
        </InfoDiv>
        <InfoDiv grow>
          <TableNumber>
            Mesa{' '}
            {item.table?.table_number === -4 ? 'POS' : item.table?.table_number}
          </TableNumber>
          <PhoneNumber>{userName}</PhoneNumber>
        </InfoDiv>

        <Actions>
          {user.is_printed_on_web || user.print_bills_on_web ? (
            <ReactToPrint
              trigger={() => (
                <AcceptButton style={{ margin: 0 }}>
                  <div>
                    <AiOutlinePrinter size={17} style={{ marginRight: 3 }} />
                    Fechar
                  </div>
                </AcceptButton>
              )}
              content={() => componentRef.current}
              onAfterPrint={() =>
                handlePrintItem({
                  session_key: item.session_key,
                  bill_id: item.bill.id,
                })
              }
            />
          ) : (
            <AcceptButton onClick={closeClick} style={{ margin: 0 }}>
              <div>
                <AiOutlinePrinter size={17} style={{ marginRight: 3 }} />
                Fechar
              </div>
            </AcceptButton>
          )}
          <SeeButton onClick={toggle} style={{ margin: 0 }}>
            <div>
              <AiOutlineEye size={17} style={{ marginRight: 3 }} />
              Detalhes
            </div>
          </SeeButton>
        </Actions>
      </ContentBody>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            <EyeBillsIndividual item={item} />
          </Container>
        </ModalBody>
        <ModalFooter>
          <ReactToPrint
            trigger={() => (
              <Print>
                <AiOutlinePrinter color="black" size={20} />
              </Print>
            )}
            content={() => componentRef.current}
          />
        </ModalFooter>
      </Modal>

      <DisplayNoneImprimir>
        <PrintBillsIndividual
          item={item}
          restaurant={user}
          ref={componentRef}
        />
      </DisplayNoneImprimir>
    </Pedido>
  );
}
