import React, { useState, useCallback, useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { MdArrowDropDown } from 'react-icons/md';
import { AiOutlineCalendar } from 'react-icons/ai';

import {
  format,
  startOfDay,
  endOfDay,
  endOfMonth,
  startOfWeek,
  startOfMonth,
  subDays,
} from 'date-fns';

import {
  LossTable,
  DateRangeDiv,
  SearchDiv,
  SelectDateButton,
  ButtonsSelectDate,
  DataPickerArea,
  DatePickerStyled,
} from './styles';

import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';
import formatMonthYear from '~/services/formatMonthYear';
import { DownloadButton } from 'ui-kit-takeat';
import { generateLossReportSheet } from '~/services/generateLossReportSheet';
import FileSaver from 'file-saver';

export default function InputLossReport({ type }) {
  const [rangeStartDate, setRangeStartDate] = useState(
    startOfMonth(new Date())
  );
  const [rangeEndDate, setRangeEndDate] = useState(null);

  const [periodName, setPeriodName] = useState('Nesse mês');

  const [monthYearDate, setMonthYearDate] = useState(null);

  const [history, setHistory] = useState([]);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateLossReportSheet(history);

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        type === '+' ? `Sobras(${format(new Date(), 'dd-MM-yyyy')}).xlsx` : `Perdas(${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const updateHistory = useCallback(
    async (inicial, final) => {
      try {
        const response = await api.get('/restaurants/history-inputs-report', {
          params: {
            start_date: inicial.toISOString(),
            end_date: final.toISOString(),
            type
          },
        });

        setHistory(response.data);
      } catch (error) {
        toast.error('Falha ao carregar dados');
      }
    },
    [setHistory, type]
  );

  const setDate = useCallback(
    (dateType, date = null) => {
      let inicial = new Date();
      let final = new Date();
      const now = new Date();

      switch (dateType) {
        case 'today':
          inicial = startOfDay(now);
          final = endOfDay(now);

          setPeriodName('Hoje');
          break;
        case 'yesterday':
          inicial = startOfDay(subDays(now, 1));
          final = endOfDay(subDays(now, 1));

          setPeriodName('Ontem');
          break;
        case 'week':
          inicial = startOfWeek(new Date(), { weekStartsOn: 1 });
          final = endOfDay(new Date());

          setPeriodName('Essa semana');
          break;
        case 'month':
          inicial = startOfMonth(date);
          final = endOfMonth(date);

          setPeriodName(formatMonthYear(date));
          setMonthYearDate(date);
          break;
        case 'period': {
          const [start, end] = date;

          setRangeStartDate(start);
          setRangeEndDate(end);

          if (!end) {
            return;
          }

          inicial = startOfDay(start);
          final = endOfDay(end || now);

          const inicialFormat = format(inicial, 'dd/MM/yyyy, HH:mm');
          const finalFormat = format(final, 'dd/MM/yyyy, HH:mm');

          const period = `De ${inicialFormat} até ${finalFormat}`;
          setPeriodName(period);
          setMonthYearDate(start);
          break;
        }
        default:
          console.log('Error');
          break;
      }

      updateHistory(inicial, final);
    },
    [setPeriodName, setMonthYearDate, setRangeStartDate, setRangeEndDate]
  );

  useEffect(() => {
    setDate('today');
  }, [updateHistory]);

  return (
    <>
      <SearchDiv>
        <DateRangeDiv>
          <AiOutlineCalendar size={20} style={{ paddingRight: 5 }} />
          {periodName}
        </DateRangeDiv>

        <ButtonsSelectDate>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}
          >
            <DownloadButton
              clickFunction={() => {
                if (history.length > 0) {
                  handleDownloadSheet();
                }
              }}
            />
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={() => setDate('today')}
              color="info"
            >
              Hoje
            </SelectDateButton>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={() => setDate('yesterday')}
              color="info"
            >
              Ontem
            </SelectDateButton>
            <SelectDateButton
              className="btn-round mr-auto"
              onClick={() => setDate('week')}
              color="info"
            >
              Essa semana
            </SelectDateButton>

            <DataPickerArea>
              <DatePickerStyled
                selected={monthYearDate}
                onChange={(date) => setDate('month', date)}
                locale="pt"
                showMonthYearPicker
                customInput={
                  <SelectDateButton className="btn-round mr-auto" color="info">
                    Mês/Ano <MdArrowDropDown color="white" size={20} />
                  </SelectDateButton>
                }
              />
            </DataPickerArea>

            <DataPickerArea>
              <DatePickerStyled
                selected={rangeStartDate}
                onChange={(dates) => setDate('period', dates)}
                startDate={rangeStartDate}
                endDate={rangeEndDate}
                selectsRange
                selectsDisabledDaysInRange
                locale="pt"
                customInput={
                  <SelectDateButton className="btn-round mr-auto" color="info">
                    Período <MdArrowDropDown color="white" size={20} />
                  </SelectDateButton>
                }
              />
            </DataPickerArea>
          </div>
        </ButtonsSelectDate>
      </SearchDiv>

      <LossTable borderless>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Nome</th>
            <th>{type === '+' ? 'Sobras' : 'Perdas'}</th>
            {/* <th>Valor</th> */}
            <th>Custo</th>
            <th>Justificativa</th>
            <th>Data</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          {history?.length ? (
            history.map((item) => (
              <tr key={item.id}>
                <td> {item.is_intermediary ? 'Intermediário' : 'Insumo'} </td>
                <td> {item.name} </td>
                <td>
                  {type}{' '}
                  {`${parseFloat(item.quantidade).toFixed(3)} ${
                    item.unidade
                  }`}{' '}
                </td>
                {/* <td> {item.total !== null ? `R$ ${item.total}` : '-'} </td> */}
                <td> {item.cmv !== null ? `R$ ${item.cmv}` : '-'} </td>
                <td> {item.justificative || '-'} </td>
                <td> {formatCompleteDate(item.created_at)} </td>
                <td> {item.user?.name || '-'} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td />
              <td />
              <td />
              <td> Período sem dados! </td>
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </LossTable>
    </>
  );
}
