import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';

export const DropdownItemStyled = styled(DropdownItem)`
  &:hover {
    background-color: #fff;
  }
`;

export const Container = styled.div`
  margin: auto;
  width: 95%;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 20px;
  margin-top: 30px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid #f4f4f4;
`;

export const ButttonArea = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`;

export const AreaSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 18px;
  span {
    font-size: 14px;
    margin-right: 8px;
  }
`;

export const ReorderContainer = styled.div`
  width: 350px;
  margin-bottom: 16px;
  font-size: 25px;
`;

export const Delete = styled.button`
  border: none;
  background: none;
  &:active {
    border: none;
  }
  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const CategoryHeader = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #f4f4f4;
  div {
    display: flex;
    align-items: flex-end;
    svg {
      cursor: pointer;
    }
    p {
      margin-left: 16px;
    }
  }
  p {
    font-size: 20px;
    margin-top: 14px;
  }
`;

export const ReorderCategoryButton = styled.button`
  border: none;
  background-color: #ff403d;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-weight: bold;
  height: 36px;
  padding: 0 15px;
  font-weight: bold;
  border-radius: 4px;
  :hover {
    filter: brightness(0.8);
  }
`;

export const CategoryName = styled.div`
  font-weight: bold;
  font-size: 25px;
`;