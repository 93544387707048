import React, { useState, useMemo, useEffect } from 'react';

import { v4 } from 'uuid';

import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Table,
  Bill,
  Restaurant,
  Body,
  Phone,
  Price,
  Items,
  Item,
  ItemName,
  Complements,
  Footer,
  PriceItem,
  NamePrice,
  ItemsArea,
  TotalServices,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
  TotalRowPayments,
  DeliveryInfo,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';
import formatPhone from '~/services/formatPhoneString';

const PrintBillsOrderOperation = React.forwardRef(
  (
    {
      item,
      restaurant,
      count,
      paymentData,
      printBills,
      sessionBills,
      productBills,
      commandTableNumber,
    },
    ref
  ) => {
    const [totalPaid, setTotalPaid] = useState(0);
    const [payments, setPayments] = useState([]);
    const { user } = useAuth();
    const [totalWaiterPrice, setTotalWaiterPrice] = useState();
    const [hasTax, setHasTax] = useState(
      item?.total_service_price !== item?.total_price
    );
    // const pendingBills = useMemo(() => {
    //   return item?.bills.filter((bill) => bill.status !== 'finished');
    // }, []);
    // const closedBills = useMemo(() => {
    //   return item?.bills.filter((bill) => bill.status === 'finished');
    // }, []);

    // const pendingBills = item?.bills.filter(
    //   (bill) => bill.status !== 'finished'
    // );
    // const closedBills = item?.bills.filter(
    //   (bill) => bill.status === 'finished'
    // );

    const date = useMemo(() => {
      if (!item.end_time) {
        return formatCompleteDate(new Date());
      }
      return formatCompleteDate(new Date(item.end_time));
    }, []);

    function searchTableName(table) {
      if (table.table_type === 'balcony') {
        return 'Balcão';
      }

      if (table.table_type === 'pos') {
        return 'POS';
      }

      if (table.table_type === 'delivery') {
        return 'Delivery';
      }

      if (table.table_number === -10) {
        return 'Mesa Copa';
      }
      if (table.table_type === 'command') {
        return `Comanda ${table.table_number}`;
      }
      return `Mesa ${table.table_number}`;
    }

    // console.log('printBills', printBills);

    useEffect(() => {
      const waiters = printBills?.filter((item) => item?.waiter);

      const total_waiter_price = waiters?.reduce(
        (acum, curr) => acum + Number(curr?.total_service_price),
        0
      );

      setTotalWaiterPrice(total_waiter_price);
    }, [printBills]);

    useEffect(() => {
      try {
        const paymentsSession = paymentData;
        setPayments(paymentsSession);

        const total = paymentsSession.reduce(
          (acum, curr) => acum + Number(curr.payment_value),
          0
        );

        setTotalPaid(total.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    }, [item.id, count, paymentData]);

    function changeValue(value) {
      const newValue = value.replace('.', ',');
      return newValue;
    }

    //   let total_price_print = 0;
    //   let total_service_price_print = 0;

    // pendingBills.forEach((bill) => {
    //     bill.order_baskets.forEach((ob) => {
    //       ob.orders.forEach((order) => {
    //         total_price_print += parseFloat(order.total_price);
    //         total_service_price_print += parseFloat(order.total_service_price);
    //       });
    //     });
    //   });

    return (
      <Container ref={ref}>
        {item ? (
          <React.Fragment key={item.id}>
            <Table>
              <strong>{searchTableName(item.table)}</strong>
            </Table>
            {item?.table.table_type === 'prepaid' && (
              <Bill>
                <strong>Pagamento Antecipado</strong>{' '}
              </Bill>
            )}
            <Bill>
              {item.table.table_type === 'delivery' ? (
                <strong>Senha #{item.attendance_password}</strong>
              ) : (
                <strong>{item.table.table_type !== 'command' && 'Comanda'} #{item.id}</strong>
              )}
            </Bill>
            <Bill>
              <strong>Fechada {date}</strong>
            </Bill>
            <Restaurant>
              <strong>{restaurant?.fantasy_name}</strong>
            </Restaurant>

            <ItemsArea>
              {item.table.table_type === 'command' &&
                commandTableNumber?.command_table_number && (
                  <h4>
                    Último pedido: Mesa{' '}
                    {commandTableNumber?.command_table_number}
                  </h4>
                )}

              {totalWaiterPrice > 0 && (
                <Body>
                  <Phone>
                    <strong>Garçons</strong>
                  </Phone>
                  <Price>
                    <strong>R${totalWaiterPrice?.toFixed(2)}</strong>
                  </Price>
                </Body>
              )}

              <h4 style={{ marginTop: '14px' }}>Clientes</h4>

              {printBills
                ?.filter((item) => item?.buyer)
                ?.map((bill) => {
                  const userName = bill.buyer
                    ? bill.buyer.phone || bill.buyer.name
                    : bill.waiter?.name;
                  return (
                    parseFloat(bill.total_price) > 0 && (
                      <React.Fragment key={bill.id}>
                        <Body style={{ marginTop: '-16px' }}>
                          <Phone style={{ marginLeft: '16px' }}>
                            <strong>{userName}</strong>
                          </Phone>
                          <Price>
                            <strong>R${bill.total_service_price}</strong>
                          </Price>
                        </Body>
                        {item?.is_delivery && (
                          <DeliveryInfo>
                            <span>{bill.buyer?.name}</span>
                            <span>{bill.buyer?.email}</span>
                            {bill.buyer?.buyer_address && (
                              <>
                                <span>
                                  {`${bill.buyer?.buyer_address?.street || ''
                                    } - ${bill.buyer?.buyer_address?.number || ''
                                    }`}
                                </span>

                                <span>
                                  {bill.buyer?.buyer_address?.neighborhood ||
                                    ''}
                                </span>
                                <span>
                                  {`${bill.buyer?.buyer_address?.city || ''
                                    } - ${bill.buyer?.buyer_address?.state || ''
                                    }` || ''}
                                </span>
                                <span>
                                  {bill.buyer?.buyer_address?.zip_code || ''}
                                </span>
                              </>
                            )}
                          </DeliveryInfo>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
            </ItemsArea>

            <ItemsArea>
              <h4 style={{ marginTop: '-8px' }}>Itens</h4>

              <Items>
                {productBills?.map((order) => (
                  <Item key={order.id}>
                    <NamePrice>
                      <ItemName>
                        {order.use_weight ? (
                          <strong>
                            {changeValue(order.weight.toString())}kg{' '}
                            {order.name}
                          </strong>
                        ) : (
                          <strong>
                            {order.amount}x {order.name}
                          </strong>
                        )}
                      </ItemName>
                      <PriceItem>
                        <strong>R${order.total_price.toFixed(2)}</strong>
                      </PriceItem>
                    </NamePrice>
                    {order.complement_categories.map((category) => (
                      <React.Fragment key={category.id}>
                        {category.order_complements
                          .filter((it) => it.complement.price > 0)
                          .map((complement) => (
                            <div key={complement.complement.id}>
                              <NamePrice>
                                <Complements>
                                  <strong>
                                    - {complement.amount}x{' '}
                                    {complement.complement.name}{' '}
                                  </strong>
                                </Complements>
                              </NamePrice>
                            </div>
                          ))}
                      </React.Fragment>
                    ))}
                  </Item>
                ))}
              </Items>
            </ItemsArea>

            <ItemsArea>
              {user.has_service_tax &&
                !item?.table?.is_balcony &&
                !item?.is_delivery && (
                  <TotalServices>
                    <h4>Serviços ({user.service_tax}%) - Opcional</h4>
                    <strong>{`R$ ${parseFloat(
                      item?.total_service_price - item?.total_price
                    ).toFixed(2)} `}</strong>
                  </TotalServices>
                )}
            </ItemsArea>

            <TotalDiv>
              {sessionBills?.old_total_price && (
                <>
                  <TotalRow>
                    <TotalName>
                      <strong>SUB TOTAL</strong>
                    </TotalName>
                    <TotalPrice>
                      <strong>
                        R${parseFloat(sessionBills?.old_total_price).toFixed(2)}
                      </strong>
                    </TotalPrice>
                  </TotalRow>
                  <TotalRow>
                    <TotalName>
                      <strong>DESCONTO</strong>
                    </TotalName>
                    <TotalPrice>
                      <strong>
                        {sessionBills?.discount_percent}% / R$
                        {parseFloat(sessionBills?.discount_total).toFixed(2)}
                      </strong>
                    </TotalPrice>
                  </TotalRow>
                </>
              )}

              {item?.is_delivery && item?.delivery_tax_price > 0 && (
                <TotalRow>
                  <TotalName>
                    <strong>Taxa de entrega</strong>
                  </TotalName>
                  <TotalPrice>
                    <strong>
                      R${parseFloat(item?.delivery_tax_price).toFixed(2)}
                    </strong>
                  </TotalPrice>
                </TotalRow>
              )}

              <TotalRow>
                <TotalName>
                  <strong>TOTAL</strong>
                </TotalName>
                <TotalPrice>
                  {item?.is_delivery ? (
                    <strong>
                      R$
                      {parseFloat(sessionBills?.total_delivery_price).toFixed(
                        2
                      )}
                    </strong>
                  ) : (
                    <strong>
                      R$
                      {parseFloat(sessionBills?.total_service_price).toFixed(2)}
                    </strong>
                  )}
                </TotalPrice>
              </TotalRow>
              {hasTax > 0 && (
                <TotalRow>
                  <TotalName>
                    <strong>SUB TOTAL</strong>
                  </TotalName>
                  <TotalPrice>
                    <strong>
                      R$
                      {parseFloat(item?.total_price).toFixed(2)}
                    </strong>
                  </TotalPrice>
                </TotalRow>
              )}
              {item?.is_delivery &&
                item?.user_change > 0 &&
                (item?.intended_payment_method?.name === 'Dinheiro' ||
                  item?.intended_payment_method?.name ===
                  'Dinheiro (iFood)') && (
                  <TotalRow>
                    <TotalName>
                      <strong>Troco</strong>
                    </TotalName>
                    <TotalPrice>
                      <strong>
                        R$
                        {parseFloat(
                          Number(item?.user_change) -
                          Number(item?.total_delivery_price)
                        ).toFixed(2)}
                      </strong>
                    </TotalPrice>
                  </TotalRow>
                )}
              <TotalRow>
                <TotalName>
                  <strong>PAGO</strong>
                </TotalName>
                <TotalPrice>
                  <strong>R${totalPaid}</strong>
                </TotalPrice>
              </TotalRow>
              <TotalRowPayments>
                {payments &&
                  payments.length > 0 &&
                  payments.map((payment) => (
                    <div key={payment.id}>
                      <span style={{ fontSize: 22 }}>
                        - {payment?.payment_method?.name}
                      </span>
                      <span style={{ fontSize: 22 }}>
                        R$ {parseFloat(payment?.payment_value).toFixed(2)}
                      </span>
                    </div>
                  ))}
              </TotalRowPayments>
              <TotalRow>
                <TotalName>
                  <strong>RESTANTE</strong>
                </TotalName>
                <TotalPrice>
                  {item?.is_delivery ? (
                    <strong>
                      R$
                      {(
                        parseFloat(sessionBills?.total_delivery_price) -
                        totalPaid
                      ).toFixed(2)}
                    </strong>
                  ) : (
                    <strong>
                      R$
                      {(
                        parseFloat(sessionBills?.total_service_price) -
                        totalPaid
                      ).toFixed(2)}
                    </strong>
                  )}
                </TotalPrice>
              </TotalRow>
            </TotalDiv>
            <Footer>
              <strong>Desenvolvido por Takeat</strong>
            </Footer>
          </React.Fragment>
        ) : (
          <div />
        )}
      </Container>
    );
  }
);

export default PrintBillsOrderOperation;
