import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-hot-toast';
import jwtDecode from 'jwt-decode';
import api from '~/services/api';
import history from '~/services/history';

import FCMManager from '~/FCMManager';
import WebsocketManager from '~/WebsocketManager';

import { subHours } from 'date-fns';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@gddashboard:token');
    const user = localStorage.getItem('@gddashboard:user');
    if (token && user) {
      if (token !== null) {
        try {
          const { exp } = jwtDecode(token);
          const expirationTime = exp * 1000;
          if (Date.now() >= expirationTime) {
            localStorage.removeItem('@gddashboard:token');
            localStorage.removeItem('@gddashboard:user');

            return { token: null, user: null };
          }
        } catch (err) {
          console.log('invalid Token');
        }
      }
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return { token: null, user: null };
  });

  const [loading, setLoading] = useState(false);
  const [websocketManager, setWebsocketManager] = useState(null);
  const [fcmManager, setFcmManager] = useState(null);

  const signOut = useCallback(() => {
    localStorage.removeItem('@gddashboard:token');
    localStorage.removeItem('@gddashboard:user');
    localStorage.removeItem('@gddashboard:ifood_complements');
    localStorage.removeItem('@gddashboard:ifood_products');
    localStorage.removeItem('@gddashboard:ifood_menu');
    localStorage.removeItem('@gddashboard:ifood_restaurants');
    // localStorage.removeItem('@gddashboard:userAlreadyRate');

    // localStorage.removeItem('@gddashboard:modalNps');

    setModalNpsCloseTime(subHours(new Date(), 7))
    setUserAlreadyRate(false)

    setData({ user: null, token: null });

    history.push('/');
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    try {
      const response = await api.post('public/sessions/restaurants', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@gddashboard:token', token);
      localStorage.setItem('@gddashboard:user', JSON.stringify(user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({ token, user });
    } catch (err) {
      toast.error('Erro ao realizar login, verifique seus dados!');
    }

    setLoading(false);
  }, []);

  const getMenewProducts = useCallback(async () => {
    if (data?.user?.has_menew) {
      try {
        const response = await api.get('restaurants/menew/products');
        localStorage.setItem(
          '@gddashboard:menewProducts',
          JSON.stringify(response.data)
        );
        setMenewProducts(response.data);

        if (Array.isArray(response.data)) {
          toast.success('Produtos Menew atualizados com sucesso!');
        } else {
          toast.error(
            'Houve algum erro! Produtos Menew não foram atualizados.'
          );
        }
      } catch (err) {
        toast.error('Erro ao carregar produtos menew! Tente novamente');
      }
    }
  }, []);

  const [menewProducts, setMenewProducts] = useState(() => {
    const products = localStorage.getItem('@gddashboard:menewProducts');
    if (!products) {
      getMenewProducts();
    }
    return JSON.parse(products);
  });

  const [generalNotifications, setGeneralNotifications] = useState(() => {
    const notification = localStorage.getItem(
      '@gddashboard:generalNotifications'
    );

    if (notification) {
      return JSON.parse(notification);
    }

    return [];
  });

  const [notificationsOpen, setNotificationsOpen] = useState(() => {
    const notification = localStorage.getItem('@gddashboard:notificationsOpen');

    if (notification === 'true' || notification === true) {
      return true;
    } else {
      return false;
    }
  });

  const [restaurantDiscountObs, setDiscountObs] = useState(() => {
    const obs = localStorage.getItem('@gddashboard:discountObs');

    if (obs) {
      return JSON.parse(obs);
    }

    return [];
  });

  const getDiscountObs = useCallback(async () => {
    try {
      const response = await api.get('restaurants/discount-obs');
      localStorage.setItem(
        '@gddashboard:discountObs',
        JSON.stringify(response.data)
      );
      setDiscountObs(response.data);
    } catch (err) {
      console.log('erro ao pegar descontos obs', err);
    }
  }, []);

  useEffect(() => {
    getDiscountObs();
  }, [getDiscountObs]);

  const updateDiscountObs = useCallback(async (obs) => {
    try {
      const response = await api.post('restaurants/discount-obs', { obs });
      localStorage.setItem(
        '@gddashboard:discountObs',
        JSON.stringify(response.data)
      );
      setDiscountObs(response.data);
    } catch (err) {
      console.log('erro ao atualizar/inserir descontos obs', err);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:discountObs',
      JSON.stringify(restaurantDiscountObs)
    );
  }, [restaurantDiscountObs]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:generalNotifications',
      JSON.stringify(generalNotifications)
    );
  }, [generalNotifications]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:notificationsOpen', notificationsOpen);
  }, [notificationsOpen]);

  const removediscountObs = useCallback(async (discount_id) => {
    try {
      const response = await api.post(
        `restaurants/remove-discount-obs/${discount_id}`
      );

      setDiscountObs((state) => {
        const filtered = state.filter((item) => item.id !== discount_id);

        return filtered;
      });

      toast.success('Justicativa removida');
    } catch (e) {
      toast.error('Erro ao remover justificativa');
    }
  }, []);

  const [userAccess, setUserAccess] = useState(() => {
    const userAccessStorage = localStorage.getItem('@gddashboard:userAccess');

    if (userAccessStorage) {
      return userAccessStorage;
    }

    return '';
  });

  const [modalOperationMessageNew5, setModalOperationMessageNew5] = useState(
    () => {
      const modalOperationMessageStorageNew5 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew5'
      );

      if (modalOperationMessageStorageNew5) {
        return modalOperationMessageStorageNew5;
      }

      return true;
    }
  );

  const [modalOperationMessageNew6, setModalOperationMessageNew6] = useState(
    () => {
      const modalOperationMessageStorageNew6 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew6'
      );

      if (modalOperationMessageStorageNew6) {
        return modalOperationMessageStorageNew6;
      }

      return true;
    }
  );

  const [modalOperationMessageNew8, setModalOperationMessageNew8] = useState(
    () => {
      const modalOperationMessageStorageNew8 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew8'
      );

      if (modalOperationMessageStorageNew8) {
        return modalOperationMessageStorageNew8;
      }

      return true;
    }
  );

  const [modalOperationMessageNew14, setModalOperationMessageNew14] = useState(
    () => {
      const modalOperationMessageStorageNew14 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew14'
      );

      if (modalOperationMessageStorageNew14) {
        return modalOperationMessageStorageNew14;
      }

      return true;
    }
  );

  const [modalOperationMessageNew16, setModalOperationMessageNew16] = useState(
    () => {
      const modalOperationMessageStorageNew16 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew16'
      );

      if (modalOperationMessageStorageNew16) {
        return modalOperationMessageStorageNew16;
      }

      return true;
    }
  );

  const [modalOperationMessageNew12, setModalOperationMessageNew12] = useState(
    () => {
      const modalOperationMessageStorageNew12 = localStorage.getItem(
        '@gddashboard:modalOperationMessageNew12'
      );

      if (modalOperationMessageStorageNew12) {
        return modalOperationMessageStorageNew12;
      }

      return true;
    }
  );

  const [modalOperationMessageWhatsapp, setModalOperationMessageWhatsapp] = useState(
    () => {
      const modalOperationMessageWhatsappStorage = localStorage.getItem(
        '@gddashboard:modalOperationMessageWhatsapp'
      );

      if (modalOperationMessageWhatsappStorage) {
        return modalOperationMessageWhatsappStorage;
      }

      return true;
    }
  );

  const [modalOperationMessageWhatsappOnline, setModalOperationMessageWhatsappOnline] = useState(
    () => {
      const modalOperationMessageWhatsappOnlineStorage = localStorage.getItem(
        '@gddashboard:modalOperationMessageWhatsappOnline'
      );

      if (modalOperationMessageWhatsappOnlineStorage) {
        return modalOperationMessageWhatsappOnlineStorage;
      }

      return true;
    }
  );

  const [userAlreadyRate, setUserAlreadyRate] = useState(
    () => {
      const userAlreadyRateStorage = localStorage.getItem(
        '@gddashboard:userAlreadyRate'
      );

      if (userAlreadyRateStorage) {
        return userAlreadyRateStorage;
      }

      return false
    }
  )

  const [modalOperationClubSale, setModalOperationClubSale] = useState(
    () => {
      const modalOperationClubSaleStorage = localStorage.getItem(
        '@gddashboard:modalOperationClubSale'
      );

      if (modalOperationClubSaleStorage) {
        return modalOperationClubSaleStorage;
      }

      return true;
    }
  );

  // const [modalNps, setModalNps] = useState(
  //   () => {
  //     const modalNpsStorage = localStorage.getItem(
  //       '@gddashboard:modalNps'
  //     );

  //     if (modalNpsStorage) {
  //       return modalNpsStorage;
  //     }

  //    const hundredTime= response.data.hundred_sessions_date || new Date()


  //     if(differenceInMonths(new Date(), hundredTime) > 2){
  //       setModalNps(true)
  //     }else{
  //       setModalNps(false)
  //     }


  //   }
  // );

  const [modalNpsCloseTime, setModalNpsCloseTime] = useState(
    () => {
      const modalNpsCloseTimeStorage = localStorage.getItem(
        '@gddashboard:modalNpsCloseTime'
      );

      if (modalNpsCloseTimeStorage) {
        return modalNpsCloseTimeStorage;
      }


      return subHours(new Date(), 7)

    }
  );

  const [modalOperationMessageNew9, setModalOperationMessageNew9] = useState();

  const getAnswers = async () => {
    const response = await api.get('/restaurants/get-answers');
    setModalOperationMessageNew9(response.data);
  };

  const [toastUpdateSystemMessage4, setToastUpdateSystemMessage4] = useState(
    () => {
      const toastUpdateSystemMessageStorage4 = localStorage.getItem(
        '@gddashboard:toastUpdateSystemMessage4'
      );

      if (toastUpdateSystemMessageStorage4) {
        return toastUpdateSystemMessageStorage4;
      }

      return true;
    }
  );



  const [toastUpdateSystemMessage3, setToastUpdateSystemMessage3] = useState(
    () => {
      const toastUpdateSystemMessageStorage3 = localStorage.getItem(
        '@gddashboard:toastUpdateSystemMessage3'
      );

      if (toastUpdateSystemMessageStorage3) {
        return toastUpdateSystemMessageStorage3;
      }

      return true;
    }
  );

  const [updateUiModalContext, setUpdateUiModalContext] = useState(
    () => {
      const updateUiModalContextStorage = localStorage.getItem(
        '@gddashboard:updateUiModalContext'
      );

      if (updateUiModalContextStorage) {
        return updateUiModalContextStorage;
      }

      return true;
    }
  );

  const updateUser = useCallback(
    async (updateData) => {
      try {
        setLoading(true);
        const {
          name,
          fantasy_name,
          avatar_id,
          phone,
          adress,
          has_service_tax,
          service_tax,
          is_printed_on_web,
          auto_print_orders,
          auto_print_delivery,
          print_bills_on_web,
          print_separate_itens,
          print_canceled,
          print_transfer,
          has_nfce,
          nfce_token,
          cnpj,
          instagram,
          order_cancel_password,
          order_transfer_password,
          delivery_info,
          table_type,
          print_balcony_default,
          ask_waiter_key,
          greeting_message,
          audit_cashier,
          ifood_auto_accept,
          enable_menu,
          is_order_scheduling_active,
          financial_email,
          pixel_id,
          use_cashier_by_user,
          default_cashier_user_id,
          print_complement_category_title,
          can_cashier_verify_sessions,
          print_bills_delivery_on_web,
          ...rest
        } = updateData;

        const profile = {
          name,
          fantasy_name,
          avatar_id,
          phone,
          adress,
          is_printed_on_web,
          print_separate_itens,
          auto_print_orders,
          auto_print_delivery,
          print_bills_on_web,
          print_canceled,
          print_transfer,
          has_nfce,
          nfce_token,
          cnpj,
          instagram,
          has_service_tax,
          service_tax,
          order_cancel_password,
          order_transfer_password,
          delivery_info,
          table_type,
          print_balcony_default,
          ask_waiter_key,
          greeting_message,
          audit_cashier,
          ifood_auto_accept,
          enable_menu,
          is_order_scheduling_active,
          financial_email,
          pixel_id,
          use_cashier_by_user,
          default_cashier_user_id,
          print_complement_category_title,
          print_bills_delivery_on_web,
          can_cashier_verify_sessions,
          ...(rest.oldPassword ? rest : {}),
        };
        if (avatar_id) {
          profile.avatar_id = Number(avatar_id);
        }

        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        if (adress && adress.id) {
          const updatedAdress = await api.put(`/restaurants/address`, {
            street: adress?.street,
            number: Number(adress?.number),
            complement: adress?.complement,
            neighborhood: adress?.neighborhood,
            state: adress?.state,
            city: adress?.city,
            zip_code: adress.zip_code,
            inscricao_estadual: adress.inscricao_estadual,
          });
          profileUpdated.adress = updatedAdress.data;
        }
        if (adress && !adress.id) {
          const updatedAdress = await api.post(`/restaurants/address`, {
            street: adress?.street,
            number: Number(adress?.number),
            neighborhood: adress?.neighborhood,
            complement: adress?.complement,
            state: adress?.state,
            city: adress?.city,
            zip_code: adress.zip_code,
          });
          profileUpdated.adress = updatedAdress.data;
        }

        profileUpdated.print_complement_category_title = print_complement_category_title

        setData(oldState => {
          return {
            token: data.token,
            user: { ...oldState.user, ...profileUpdated }
          }

        });

        toast.success('Perfil atualizado com Sucesso!');
        setLoading(false);
      } catch (err) {
        if (err.response.data.errorType === "cannot_change_use_cashier_by_user") {
          toast.error('Ainda existe(m) caixa(s) aberto(s), favor fechar para habilitar/ desabilitar o vincular caixa por usuário');
        } else {
          toast.error('Erro ao atualizar perfil, confira seus dados!');
        }

        setLoading(false);
      }
    },
    [setData, data.token]
  );

  const changeRestaurantStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const user = {
          ...data.user,
          ...response.data,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.opened) {
          toast.success('Restaurante aberto com sucesso', { autoClose: 2000 });
        } else {
          toast.success('Restaurante fechado com sucesso', { autoClose: 2000 });
        }

        return user;
      } catch (e) {
        if (data.user.opened) {
          toast.error('Falha ao fechar o restaurante!');
        } else {
          toast.error('Falha ao abrir o restaurante!');
        }

        return data.user;
      }
    },
    [data.user, setData, data.token]
  );

  const getProfileData = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/show');

      if (response.data.is_user_active === false) {
        signOut();
      } else {
        setData({
          token: data.token,
          user: {
            ...response.data,
            club_key: data.user.club_key,
          },
        });
      }

      // const hundredTime= response.data.hundred_sessions_date || new Date()


      // if(differenceInMonths(new Date(), hundredTime) > 2){
      //   setModalNps(true)
      // }else{
      //   setModalNps(false)
      // }
    } catch (err) {
      toast.error('Erro ao atualizar informações deste restaurante');
    }
  }, [setData, data.token, signOut]);

  const updateToken = useCallback(
    async (updateData) => {
      try {
        const {
          x_picpay_token,
          x_seller_token,
          paytime_seller_id,
          withdrawal_allowed,
        } = updateData;

        const profile = {
          x_picpay_token,
          x_seller_token,
          paytime_seller_id,
          withdrawal_allowed,
        };

        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };
        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Tokens atualizados com Sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar Tokens, confira seus dados!');
      }
    },
    [data.token]
  );

  const updateDeliveryStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const user = {
          ...data.user,
          ...response.data,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.delivery_info.is_delivery_active) {
          toast.success('Delivery ativado com sucesso!');
        } else {
          toast.success('Delivery pausado com sucesso!');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const updateWithdrawalStatus = useCallback(
    async (status) => {
      try {
        const response = await api.put('restaurants/status', status);

        const user = {
          ...data.user,
          ...response.data,
        };

        setData({
          token: data.token,
          user,
        });

        if (user.delivery_info.is_withdrawal_active) {
          toast.success('Retirada ativada com sucesso!');
        } else {
          toast.success('Retirada pausada com sucesso!');
        }

        return response.data;
      } catch (err) {
        toast.error('Erro ao atualizar, tente novamente!');
      }

      return data.user;
    },
    [data.user, setData, data.token]
  );

  const updateSMSInfo = useCallback(
    async (updateData) => {
      try {
        const {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        } = updateData;

        const profile = {
          has_sms_service,
          is_sms_service_optional,
          client_pay_sms,
          whatsapp,
        };
        const response = await api.put('restaurants', profile);

        const profileUpdated = {
          ...response.data,
        };

        setData({
          token: data.token,
          user: profileUpdated,
        });

        toast.success('Configurações atualizadas com sucesso!');
      } catch (err) {
        toast.error('Erro ao atualizar configurações, confira seus dados!');
      }
    },
    [setData, data.token]
  );

  const [waiterId, setWaiterId] = useState(() => {
    const waiterIdStorage = localStorage.getItem('@garcomdigital:waiterId');

    if (waiterIdStorage) {
      return waiterIdStorage;
    }

    return '';
  });

  const [buyerCreatedByWaiter, setBuyerCreatedByWaiter] = useState(() => {
    const buyerCreatedByWaiterStorage = localStorage.getItem('@waitermanager:buyerCreatedByWaiter');

    if (buyerCreatedByWaiterStorage) {
      return JSON.parse(buyerCreatedByWaiterStorage);
    }

    return '';
  });

  const [buyerSelectedByWaiter, setBuyerSelectedByWaiter] = useState(() => {
    const buyerSelectedByWaiterStorage = localStorage.getItem('@waitermanager:buyerSelectedByWaiter');

    if (buyerSelectedByWaiterStorage) {
      return JSON.parse(buyerSelectedByWaiterStorage);
    }

    return '';
  });

  const [buyersCreatedByWaiter, setBuyersCreatedByWaiter] = useState(() => {
    const buyersCreatedByWaiterStorage = localStorage.getItem('@waitermanager:buyersCreatedByWaiter');

    if (buyersCreatedByWaiterStorage) {
      return JSON.parse(buyersCreatedByWaiterStorage);
    }

    return '';
  });


  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerSelectedByWaiter',
      JSON.stringify(buyerSelectedByWaiter),
    );
  }, [buyerSelectedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerCreatedByWaiter',
      JSON.stringify(buyerCreatedByWaiter),
    );
  }, [buyerCreatedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyersCreatedByWaiter',
      JSON.stringify(buyersCreatedByWaiter),
    );
  }, [buyersCreatedByWaiter]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cart', waiterId);
  }, [waiterId]);

  useEffect(() => {
    if (data.user) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('@gddashboard:token', data.token);

    if (data.token && data.token !== 'null') {
      const manager = new WebsocketManager(data.token);

      manager.addMessageCallback(-1, (data) => {
        console.log('AuthContext: socket new data');
      });

      setWebsocketManager(manager);

      setFcmManager(new FCMManager());
    } else if (websocketManager) {
      websocketManager.close();
      setWebsocketManager(null);
    }
  }, [data.token]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:userAccess', userAccess);
  }, [userAccess]);

  useEffect(() => {
    localStorage.setItem('@gddashboard:user', JSON.stringify(data.user));
  }, [data.user]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew5',
      modalOperationMessageNew5
    );
  }, [modalOperationMessageNew5]);


  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageWhatsapp',
      modalOperationMessageWhatsapp
    );
  }, [modalOperationMessageWhatsapp]);


  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageWhatsappOnline',
      modalOperationMessageWhatsappOnline
    );
  }, [modalOperationMessageWhatsappOnline]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationClubSale',
      modalOperationClubSale
    );
  }, [modalOperationClubSale]);


  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew6',
      modalOperationMessageNew6
    );
  }, [modalOperationMessageNew6]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew12',
      modalOperationMessageNew12
    );
  }, [modalOperationMessageNew12]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew14',
      modalOperationMessageNew14
    );
  }, [modalOperationMessageNew14]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew16',
      modalOperationMessageNew16
    );
  }, [modalOperationMessageNew16]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalOperationMessageNew9',
      JSON.stringify(modalOperationMessageNew9)
    );
    if (!modalOperationMessageNew9) {
      getAnswers();
    }
  }, [modalOperationMessageNew9]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:toastUpdateSystemMessage4',
      toastUpdateSystemMessage4
    );
  }, [toastUpdateSystemMessage4]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:toastUpdateSystemMessage3',
      toastUpdateSystemMessage3
    );
  }, [toastUpdateSystemMessage3]);

  // useEffect(() => {
  //   localStorage.setItem(
  //     '@gddashboard:modalNps',
  //     modalNps
  //   );
  // }, [modalNps]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:modalNpsCloseTime',
      modalNpsCloseTime
    );
  }, [modalNpsCloseTime]);

  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:userAlreadyRate',
      userAlreadyRate
    );
  }, [userAlreadyRate]);


  useEffect(() => {
    localStorage.setItem(
      '@gddashboard:updateUiModalContext',
      updateUiModalContext
    );
  }, [updateUiModalContext]);




  const value = useMemo(
    () => ({
      signIn,
      user: data.user,
      token: data.token,
      setData,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      menewProducts,
      getMenewProducts,
      updateDiscountObs,
      getDiscountObs,
      restaurantDiscountObs,
      updateDeliveryStatus,
      userAccess,
      setUserAccess,
      getProfileData,
      updateWithdrawalStatus,
      waiterId,
      setWaiterId,
      websocketManager,
      fcmManager,
      modalOperationMessageNew5,
      setModalOperationMessageNew5,
      modalOperationMessageNew6,
      setModalOperationMessageNew6,
      modalOperationMessageNew9,
      setModalOperationMessageNew9,
      modalOperationMessageNew12,
      setModalOperationMessageNew12,
      modalOperationMessageNew14,
      setModalOperationMessageNew14,
      modalOperationMessageNew16,
      setModalOperationMessageNew16,
      toastUpdateSystemMessage4,
      setToastUpdateSystemMessage4,
      toastUpdateSystemMessage3,
      setToastUpdateSystemMessage3,
      removediscountObs,
      generalNotifications,
      setGeneralNotifications,
      notificationsOpen,
      setNotificationsOpen,
      // modalNps,
      // setModalNps,
      modalNpsCloseTime,
      setModalNpsCloseTime,
      userAlreadyRate,
      setUserAlreadyRate,
      modalOperationMessageWhatsapp,
      setModalOperationMessageWhatsapp,
      modalOperationMessageWhatsappOnline,
      setModalOperationMessageWhatsappOnline,
      buyerCreatedByWaiter,
      setBuyerCreatedByWaiter,
      buyerSelectedByWaiter,
      setBuyerSelectedByWaiter,
      buyersCreatedByWaiter,
      setBuyersCreatedByWaiter,
      modalOperationClubSale,
      setModalOperationClubSale,
      updateUiModalContext,
      setUpdateUiModalContext
    }),
    [
      signIn,
      data.user,
      data.token,
      setData,
      signOut,
      updateUser,
      loading,
      changeRestaurantStatus,
      updateToken,
      updateSMSInfo,
      menewProducts,
      getMenewProducts,
      updateDiscountObs,
      getDiscountObs,
      restaurantDiscountObs,
      updateDeliveryStatus,
      userAccess,
      setUserAccess,
      updateWithdrawalStatus,
      getProfileData,
      waiterId,
      setWaiterId,
      websocketManager,
      fcmManager,
      modalOperationMessageNew5,
      setModalOperationMessageNew5,
      modalOperationMessageNew6,
      setModalOperationMessageNew6,
      toastUpdateSystemMessage4,
      setToastUpdateSystemMessage4,
      toastUpdateSystemMessage3,
      setToastUpdateSystemMessage3,
      modalOperationMessageNew9,
      setModalOperationMessageNew9,
      modalOperationMessageNew12,
      setModalOperationMessageNew12,
      modalOperationMessageNew14,
      setModalOperationMessageNew14,
      modalOperationMessageNew16,
      setModalOperationMessageNew16,
      removediscountObs,
      generalNotifications,
      setGeneralNotifications,
      notificationsOpen,
      setNotificationsOpen,
      // modalNps,
      // setModalNps,
      modalNpsCloseTime,
      setModalNpsCloseTime,
      userAlreadyRate,
      setUserAlreadyRate,
      modalOperationMessageWhatsapp,
      setModalOperationMessageWhatsapp,
      buyerCreatedByWaiter,
      setBuyerCreatedByWaiter,
      buyerSelectedByWaiter,
      setBuyerSelectedByWaiter,
      buyersCreatedByWaiter,
      setBuyersCreatedByWaiter,
      modalOperationMessageWhatsappOnline,
      setModalOperationMessageWhatsappOnline,
      modalOperationClubSale,
      setModalOperationClubSale,
      updateUiModalContext,
      setUpdateUiModalContext
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
