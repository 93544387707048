import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Container, Row, Col } from 'reactstrap';
import getValidationErrors from '../../../services/getValidationErrors';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import SaveButton from '~/components/Buttons/SaveButton';
import { useAuth } from '~/context/AuthContext';
import Select from '~/components/Form/SelectInput';

function CreatePos({ toggleModalRegisterWaiter, getWaiters }) {
  const formRef = useRef(null);
  const { user } = useAuth();
  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        serial_number: Yup.string().required('Serial Number (S/N) obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('restaurants/stone/devices', {
        serial_number: data.serial_number,
        type: data.type
      });

      toast.success('POS criado com sucesso!');

      toggleModalRegisterWaiter();
      getWaiters();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!error.response.ok) {
        switch (error.response.data.errorType) {
          default:
            toast.error(error.response.data.error || 'Falha ao criar POS');
        }
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Container>
        <Row>
          <Col sm="12">
            <Input label="Serial Number (S/N):" name="serial_number" type="text" />
            <span>O Serial Number (S/N) pode ser encontrado atrás da maquininha Stone</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Select 
              label="Tipo:" 
              name="type" 
              options={[
                {value: 'gm', label: 'Garçom'},
                {value: 'auto', label: 'Autoatendimento'},
                {value: 'pdv', label: 'PDV'},
              ]}
              defaultValue={{value: 'gm', label: 'Garçom'}}
            />
          </Col>
        </Row>

        <SaveButton type="submit" style={{ float: 'right' }}>
          Cadastrar
        </SaveButton>
      </Container>
    </Form>
  );
}

export default CreatePos;
