import React, { useCallback, useState } from 'react';
import { FiArrowDown, FiSearch } from 'react-icons/fi';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Badge,
  Row,
  Col,
  Button,
} from 'reactstrap';

import {
  CategoriesTable,
  Container,
  NavDiv,
  NavDivTeste,
  ColorTag,
  LoadingDiv,
  EmptyDiv,
  NavLinkSpan,
} from './styles';

import { useAuth } from '~/context/AuthContext';

import Bill from '~/components/Bill';
import Select from '../Form/SelectInput';
import { Form } from '@unform/web';
import Input from '../Form/Input';
import api from '~/services/api';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';

export default function Bills({
  sessions,
  getSessions,
  loading,
  setSessions,
  inicialDate,
  finalDate,
  orderByTableDate,
  orderByTableNumber,
  handleUpdate,
  params,
  setActiveTab,
  activeTab,
}) {
  const { user } = useAuth();
  // const [activeTab, setActiveTab] = useState(params?.get('isCompleted') === 'true' ? '4' : '5');
  const sessionsOpen = sessions.filter(
    (session) =>
      (session.status === 'open' || session.status === 'closing') &&
      !session.is_delivery
  );
  const sessionsClosing = sessions.filter(
    (session) => session.status === 'closing' && !session.is_delivery
  );
  const sessionsFinished = sessions.filter(
    (session) => session.status === 'finished' && !session.is_delivery
  );

  const sessionsCompleted = sessions.filter(
    (session) => session.status === 'completed' && !session.is_delivery
  );

  const sessionsOpenLength = sessionsOpen.length;
  const sessionsFinishedLength = sessionsFinished.length;
  const sessionsCompletedLength = sessionsCompleted.length;

  // const [allPayments, setAllPayments] = useState(payments);

  const selectOptions = [
    { label: 'Nº da Comanda', value: 'session' },
    { label: 'Nº do Pedido', value: 'basket' },
    { label: 'Nº do Pedido Delivery', value: 'delivery' },
    { label: 'Telefone', value: 'phone' },
  ];

  const [searchType, setSearchType] = useState({
    label: 'Nº da Comanda',
    value: 'session',
  });
  const [searchInput, setSearchInput] = useState('');
  const [searchedSessions, setSearchedSessions] = useState(null);

  const submitSearch = useCallback(async (data) => {
    try {
      let id =
        data.filter_type === 'phone'
          ? data.filter_input
          : data.filter_input?.replaceAll('#', '').replaceAll(' ', '');
      const response = await api.get('/restaurants/sessions/search', {
        params: {
          type: data.filter_type,
          id,
        },
      });

      setSearchedSessions(response.data);
    } catch (err) {
      toast.error('Erro ao carregar informações');
    }
  }, []);

  return (
    <Container>
      <NavDiv>
        <NavDivTeste>
          <Nav tabs red>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : ''}
                onClick={() => setActiveTab('1')}
              >
                <ColorTag color="#17a2b8" />
                <NavLinkSpan>Abertas</NavLinkSpan>{' '}
                <Badge>{sessionsOpenLength}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '3' ? 'active' : ''}
                onClick={() => setActiveTab('3')}
              >
                <ColorTag color="green" />
                <ColorTag color="#dc3545" />
                <NavLinkSpan>Fechadas</NavLinkSpan>{' '}
                <Badge>{sessionsFinishedLength}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '4' ? 'active' : ''}
                onClick={() => setActiveTab('4')}
              >
                <ColorTag color="#17a2b8" />
                <NavLinkSpan>Finalizadas</NavLinkSpan>{' '}
                <Badge>{sessionsCompletedLength}</Badge>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === '5' ? 'active' : ''}
                onClick={() => setActiveTab('5')}
              >
                <FiSearch style={{ marginBottom: 6, marginRight: 5 }} />
                <NavLinkSpan>Pesquisar</NavLinkSpan>{' '}
              </NavLink>
            </NavItem>
          </Nav>
        </NavDivTeste>
      </NavDiv>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    onClick={orderByTableNumber}
                    title="Ordenar pela mesa"
                  >
                    <FiArrowDown size={14} /> Mesa
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={orderByTableDate}
                    title="Ordenar pela data"
                  >
                    <FiArrowDown size={14} /> Data / Hora
                  </button>
                </th>
                <th>Nº</th>
                <th>Total</th>
                <th>Já Pago</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="info" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsOpen.map((session) => (
                  <Bill
                    session={session}
                    key={session.id}
                    getSessions={getSessions}
                    // payments={payments}
                    // setAllPayments={setAllPayments}
                    // paymentMethods={paymentMethods}
                    setSessions={setSessions}
                    sessions={sessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    handleUpdate={handleUpdate}
                    getMinimalSessions={getSessions}
                  />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsOpen === 0 ? (
            <EmptyDiv>
              <span>Não há comandas abertas no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>

        <TabPane tabId="3">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    onClick={orderByTableNumber}
                    title="Ordenar pela mesa"
                  >
                    <FiArrowDown size={14} /> Mesa
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    onClick={orderByTableDate}
                    title="Ordenar pela data"
                  >
                    <FiArrowDown size={14} /> Data / Hora
                  </button>
                </th>
                <th>Nº</th>
                <th>Total</th>
                <th>Já Pago</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="success" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsFinished.map((session) => (
                  <Bill
                    key={session.key}
                    session={session}
                    getSessions={getSessions}
                    // payments={payments}
                    // setAllPayments={setAllPayments}
                    // paymentMethods={paymentMethods}
                    setSessions={setSessions}
                    sessions={sessions}
                    inicialDate={inicialDate}
                    finalDate={finalDate}
                    handleUpdate={handleUpdate}
                  />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsFinished === 0 ? (
            <EmptyDiv>
              <span>Não há comandas fechadas no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>

        <TabPane tabId="4">
          <CategoriesTable borderless>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    onClick={orderByTableNumber}
                    title="Ordenar pela mesa"
                  >
                    <FiArrowDown size={14} /> Mesa
                  </button>
                </th>
                <th>
                  <button
                    onClick={orderByTableDate}
                    type="button"
                    title="Ordenar pela data"
                  >
                    <FiArrowDown size={14} /> Data/Hora
                  </button>
                </th>
                <th>Nº</th>
                <th>Valor</th>
                <th>Já pago</th>
                {user.has_nfce && <th>NFCe</th>}
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <LoadingDiv>
                      <Spinner color="success" />
                    </LoadingDiv>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            ) : (
              <tbody>
                {sessionsCompleted.map((session) => (
                  <Bill
                    key={session.key}
                    session={session}
                    getSessions={getSessions}
                    // payments={payments}
                    // setAllPayments={setAllPayments}
                    // paymentMethods={paymentMethods}
                    setSessions={setSessions}
                    sessions={sessions}
                    has_nfce={user.has_nfce}
                    handleUpdate={handleUpdate}
                  />
                ))}
              </tbody>
            )}
          </CategoriesTable>
          {sessionsFinished === 0 ? (
            <EmptyDiv>
              <span>Não há comandas finalizada no momento.</span>
            </EmptyDiv>
          ) : (
            <></>
          )}
        </TabPane>

        <TabPane tabId="5">
          <Form onSubmit={submitSearch}>
            <Row>
              <Col md="6" style={{ marginTop: 4 }}>
                <Select
                  name="filter_type"
                  options={selectOptions}
                  defaultValue={selectOptions[0]}
                  onChange={(e) => setSearchType(e)}
                />
              </Col>
              <Col md="4">
                {searchType?.value === 'phone' ? (
                  <InputMask
                    mask="(99) 99999-9999"
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    value={searchInput}
                  >
                    <Input
                      placeholder="Busque pelo telefone"
                      name="filter_input"
                    />
                  </InputMask>
                ) : (
                  <Input
                    name="filter_input"
                    type="text"
                    placeholder="Digite aqui..."
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                )}
              </Col>
              <Col md="2">
                <button
                  type="submit"
                  style={{
                    padding: '12px 24px',
                    background: '#fff',
                    color: '#fff',
                    marginRight: 8,
                    border: 'none',
                    borderRadius: 8,
                    marginTop: 6,
                  }}
                  title="Pesquisar"
                >
                  <FiSearch size={21} color="black" />
                </button>
              </Col>
            </Row>

            {Array.isArray(searchedSessions) &&
              (searchedSessions.length > 0 ? (
                <CategoriesTable borderless>
                  <thead>
                    <tr>
                      <th>Mesa</th>
                      <th>Data/Hora</th>
                      <th>Nº</th>
                      <th>Valor</th>
                      <th>Já pago</th>
                      {/* {user.has_nfce && <th>NFCe</th>} */}
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>
                          <LoadingDiv>
                            <Spinner color="success" />
                          </LoadingDiv>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {searchedSessions.map((session) => (
                        <Bill
                          key={session.key}
                          session={session}
                          getSessions={getSessions}
                          // payments={payments}
                          // setAllPayments={setAllPayments}
                          // paymentMethods={paymentMethods}
                          setSessions={setSessions}
                          sessions={sessions}
                          has_nfce={user.has_nfce}
                          handleUpdate={handleUpdate}
                        />
                      ))}
                    </tbody>
                  )}
                </CategoriesTable>
              ) : (
                <EmptyDiv>
                  <span>
                    Não foi encontrada nenhuma comanda com esse filtro.
                  </span>
                </EmptyDiv>
              ))}
          </Form>
        </TabPane>
      </TabContent>
    </Container>
  );
}
