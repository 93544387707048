import React, { useState, useCallback } from 'react';
import Input from '~/components/Form/Input';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';
import {
  Actions,
  ActionsInside,
  DeleteAndEditActions,
  SeeButton,
} from './styles';
import formatCompleteDate from '~/services/formatCompleteDate';
import formatDate from '~/services/formatDate';
import api from '~/services/api';
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

export default function StockTransferRow({ stock, InputsTable }) {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  const openModal = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const response = await api.get(
          `/restaurants/stock-transfer/${stock.id}`
        );
        setInfo(response.data);
        setModal(true);
      } catch (err) {
        toast.error('Falha ao carregar dados de transferencia de estoque');
      }
      setLoading(false);
    },
    [stock]
  );

  function getStatus(status){
    switch(status){
      case 'created':
        return 'Pentende';
      case 'accepted':
        return 'Aceita';
      case 'refused':
        return 'Recusada';
      default:
        return '';
    }
  }

  return (
    <>
      <tr>
        <td>{formatCompleteDate(stock?.createdAt)}</td>
        <td>{stock?.restaurant_dest?.fantasy_name}</td>
        <td>
          R$
          {stock?.value?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td>{getStatus(stock?.status)}</td>
        <td>{formatDate(stock?.due_date)}</td>
        <td>{stock?.observation}</td>
        <td>
          {' '}
          <Actions>
            <ActionsInside>
              <DeleteAndEditActions>
                <SeeButton onClick={() => openModal()}>
                  {loading ? 'Aguarde...' : 'Detalhes'}
                </SeeButton>
              </DeleteAndEditActions>
            </ActionsInside>
          </Actions>
        </td>
      </tr>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalBody>
          <Form initialData={info}>
            <h5>Detalhes da Transferência</h5>
            <Row>
              <Col md="6">
                <Input
                  label="Para"
                  name="restaurant_dest"
                  value={stock?.restaurant_dest?.fantasy_name}
                  disabled
                />
              </Col>
              <Col md="6">
                <Input
                  label="Status"
                  name="value"
                  value={getStatus(stock?.status)}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Input
                  label="Criada"
                  name="createdAt"
                  value={formatCompleteDate(stock?.createdAt)}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Valor"
                  name="value"
                  value={`R$${stock?.value?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                  disabled
                />
              </Col>
              <Col md="4">
                <Input
                  label="Vencimento"
                  name="due_date"
                  value={formatDate(stock?.due_date)}
                  disabled
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 7, marginBottom: 7 }}>
              <Col md="12">
                <strong>Observações: </strong>
                {`  ${stock?.observation}`}
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Quantidade</th>
                      <th>Custo unitário</th>
                      <th>Custo total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {info?.length > 0 &&
                      info.map(
                        (input) =>
                          input.input_origin && (
                            <tr>
                              <td>{input.input_origin.name}</td>

                              <td>
                                {parseFloat(input.quantidade).toFixed(2)}{' '}
                                {input.input_origin.unidade}
                              </td>

                              <td>
                                R${parseFloat(input.unitary_price).toFixed(2)}
                              </td>

                              <td>
                                R${parseFloat(input.total_price).toFixed(2)}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </InputsTable>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputsTable borderless>
                  <thead>
                    <tr>
                      <th>Produto Intermediário</th>
                      <th>Quantidade</th>
                      <th>Custo unitário</th>
                      <th>Custo total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {info?.length > 0 &&
                      info.map(
                        (input) =>
                          input.inter_origin && (
                            <tr>
                              <td>{input.inter_origin.name}</td>

                              <td>
                                {parseFloat(input.quantidade).toFixed(2)}{' '}
                                {input.inter_origin.unidade}
                              </td>

                              <td>
                                R${parseFloat(input.unitary_price).toFixed(2)}
                              </td>

                              <td>
                                R${parseFloat(input.total_price).toFixed(2)}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </InputsTable>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => toggle()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
