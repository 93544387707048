import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 5px;
  width: 180px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  top: 50px;
  transform-origin: top;
  transform: ${(props) => (props.show ? 'scaleY(1)' : 'scaleY(0)')};
  transition: all 0.3s;
  z-index: 2;
`;

export const Option = styled.span`
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  color: #666666;
  cursor: pointer;

  &:hover {
    background-color: #ffa8ae;
  }
`;
