/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import { useAuth } from '~/context/AuthContext';
import api from '~/services/api';

import { OrdersInfo, ProductName } from './styles';

function OrderItem({ product, handleChangeStatus, basket, isLate, index }) {
  // const { websocketManager } = useAuth();
  // useEffect(() => {
  //   if (websocketManager) {
  //     websocketManager.addMessageCallback(6, (data) => {
  //       console.log('KDS: socket new data', data);

  //       if (data.type === 'kds:order-check') {
  //         const kdsData = data.item;

  //         if (Number(basket.id) === Number(kdsData.kds_basket_id)) {
  //           if (Number(productChecked.id) === Number(kdsData.kds_order_id)) {
  //             setProductChecked((item) => {
  //               return { ...item, checked: kdsData.checked };
  //             });
  //           }
  //         }

  //         console.log(product.name, kdsData.kds_order_id);
  //       }
  //     });
  //   }
  // }, [websocketManager, product, productChecked, basket]);

  const handleCheckProduct = useCallback(
    async (id, status) => {
      await api.put(`/restaurants/kds/basket/order-check/${id}`, {
        checked: status,
      });

      const productFindIndex = basket.kds_orders.findIndex(
        (item) => item.id === id
      );

      if (productFindIndex !== -1) {
        basket.kds_orders[productFindIndex].checked = status;
      }

      if (
        basket.kds_orders
          .filter((item) => !item.canceled_at)
          .every((item) => item.checked)
      ) {
        handleChangeStatus('doing', basket.id);

        console.log('todos');
      }
    },
    [basket, handleChangeStatus]
  );

  return (
    <OrdersInfo isLate={isLate}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span>
          {basket?.status === 'doing' && !product.canceled_at && (
            <input
              type="checkbox"
              width={25}
              checked={basket.kds_orders[index].checked}
              onChange={() => {
                handleCheckProduct(product?.id, !product.checked);
              }}
            />
          )}
        </span>

        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 10,
          }}
        >
          <ProductName canceled={product?.canceled_at}>
            {product?.amount}x {product?.name}
          </ProductName>
          <span style={{ marginTop: 3 }}>
            {product?.complements &&
              product?.complements.length > 0 &&
              product?.complements?.map((comp) => (
                <Badge
                  pill
                  className="bg-complement"
                  style={{ marginLeft: 0, marginRight: 4 }}
                >
                  <span style={{ fontSize: 12 }}>{comp.name}</span>
                </Badge>
              ))}
          </span>
        </span>
      </span>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: 18 }}>{product?.average_time}</span>
        <span style={{ fontSize: 10, marginTop: -6 }}>min</span>
      </span>
    </OrdersInfo>
  );
}

export default OrderItem;
