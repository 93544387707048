/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useContext, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiMenu } from 'react-icons/fi';
import Switch from 'react-switch';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import {
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaAngleDown, FaCheck, FaEllipsisV } from 'react-icons/fa';
import OrderProductContext from '../../../pages/Products/ProductsTables/context';
import ImageInput from '~/components/Form/ImageInput';
import SaveButton from '~/components/Buttons/SaveButton';

import {
  ProductTableRow,
  ProductImage,
  MenuItemPromotion,
  NoImage,
  Description,
  ProductName,
  ProductPrice,
  ProductDescription,
  ProductAvailable,
  ButtonsTd,
  ItemTd,
  ProductAvatar,
  NumberFormatStyled,
  SubmitStyledButton,
} from './styles';
import EditProduct from '~/pages/Products/Edit';

import api from '~/services/api';
import formatValue from '../../../utils/formatValue';

import { useAuth } from '~/context/AuthContext';
import ProductsComplementsCategory from '~/pages/Products/ProductsComplementsCategory';
import Select from '~/components/Form/SelectInput';
import Input from '~/components/Form/Input';

export default function ItemProductIfood({
  product,
  category,
  handleDelete,
  getCategories,
  index,
  menewProducts,
  setProducts,
  products,
  categories,
  setCategoriesFiltered,
  setCategories,
  sliceSize,
  setComplementsCategories,
  complementCategories,
  fiscalGroups,
  ifoodMenus,
  ifoodRestaurants,
  ifoodProducts,
}) {
  const [modalImage, setModalImage] = useState(false);
  const [modalEditProduct, setModalEditProduct] = useState(false);
  const [
    complementCategoriesInsideProduct,
    setComplementCategoriesInsideProduct,
  ] = useState([]);
  const { user } = useAuth();
  // drag and drop
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownHidden, setDropDownHidden] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const { move, onDrop } = useContext(OrderProductContext);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'PRODUCTCARD', index, product_id: product.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        const draggedIndex = item.index;
        const targetIndex = index;

        onDrop(item.product_id, product.id, draggedIndex, targetIndex);
      }
    },
  });

  const [, dropRef] = useDrop({
    accept: 'PRODUCTCARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = targetSize.height / 2;
      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;
      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }
      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }
      move(item.product_id, product.id, draggedIndex, targetIndex);

      item.index = targetIndex;
    },
    drop(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      onDrop(item.product_id, product.id, draggedIndex, targetIndex);

      setDropDownHidden(false);
    },
  });

  dragRef(dropRef(ref));

  function toggleImage() {
    setModalImage(!modalImage);
  }

  function toggleModalEditProduct() {
    setModalEditProduct(!modalEditProduct);
  }

  const initialData = {
    image_id: {
      id: product.image ? product.image.id : null,
      url: product.image ? product.image.url : null,
    },
  };

  async function handleSubmit(data) {
    try {
      const parsedData = {};

      if (data.image_id) {
        parsedData.image_id = Number(data.image_id);
      }

      await api.put(`restaurants/products/${product.id}`, parsedData);

      toast.success('Produto atualizado com sucesso!');
      localStorage.setItem('@gddashboard:isMenuUpdated', true);
      toggleImage();
      // getMenu(userScreenTop);

      // getCategories();
    } catch (error) {
      toast.error('Falha ao atualizar produto');
      console.log(error);
    }
  }

  async function handleChangeProduct(value, event, idx) {
    const [type, prodId] = idx.split('-');
    const id = Number(prodId);

    const data = {};

    if (type === 'pdv') {
      data.available = value;
    } else {
      data.available_in_delivery = value;
    }

    try {
      await api.put(`restaurants/products/${id}`, data);

      const productFind = products?.findIndex((prod) => prod.id === id);

      const newProductsArray = [...products];

      newProductsArray[productFind] = {
        ...product,
        ...data,
      };

      const newProducts = [...newProductsArray];

      setProducts(newProducts);

      const catFind = categories?.findIndex((cat) => cat.id === category.id);

      categories[catFind].products = newProducts;
      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toast.success('Produto alterado com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar/ desativar produto!');
      console.log(err);
    }
  }

  const getComplementsCategoryInsideProduct = useCallback(async () => {
    const response = await api.get(
      `/restaurants/product-complement-categories/${product.id}`
    );

    setComplementCategoriesInsideProduct(response.data);
  }, [product]);

  const [collapsed, setCollapsed] = useState([]);

  const handleCollapse = (id) => {
    getComplementsCategoryInsideProduct();
    if (collapsed.includes(id)) {
      const newCollapse = collapsed.filter((c) => c !== id);
      setCollapsed(newCollapse);
    } else {
      setCollapsed((state) => [...state, id]);
    }
  };

  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  // edit price at input
  const [buttonsSubmitDesbloqued, setButtonsSubmitDesbloqued] = useState([]);
  const [
    buttonsSubmitDeliveryPriceDesbloqued,
    setButtonsSubmitDeliveryPriceDesbloqued,
  ] = useState([]);

  function handleDesbloquedSubmitButton(id) {
    if (product.id === id) {
      setButtonsSubmitDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitDesbloqued.filter((item) => item !== id);
      setButtonsSubmitDesbloqued(idsFiltered);
    }
  }

  function handleDesbloquedSubmitDeliveryPriceButton(id) {
    if (product.id === id) {
      setButtonsSubmitDeliveryPriceDesbloqued([id]);
    } else {
      const idsFiltered = buttonsSubmitDeliveryPriceDesbloqued.filter(
        (item) => item !== id
      );
      setButtonsSubmitDeliveryPriceDesbloqued(idsFiltered);
    }
  }
  const [price, setPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const handleSubmitPrice = useCallback(
    async (is_promotion) => {
      const readyValue = price.floatValue
        ? (price.floatValue / 100).toFixed(2)
        : price;

      const formatPrice = (price) => {
        return readyValue > 0 ? price.replace(',', '.') : '0.00';
      };

      const formattedPrice = formatPrice(readyValue);

      is_promotion
        ? await api.put(`restaurants/products/${product.id}`, {
            price_promotion: formattedPrice,
          })
        : await api.put(`restaurants/products/${product.id}`, {
            price: formattedPrice,
          });

      const newCategories = [...categories];

      newCategories.map((cat, idix) =>
        cat.products.map((prod, indx) => {
          if (prod.id === product.id) {
            is_promotion
              ? (newCategories[idix].products[
                  indx
                ].price_promotion = formattedPrice)
              : (newCategories[idix].products[indx].price = formattedPrice);
          }
        })
      );

      setCategories(newCategories);
      localStorage.setItem('@gddashboard:isMenuUpdated', true);

      toast.success('Preço alterado com sucesso.');

      setButtonsSubmitDesbloqued([]);
      setButtonsSubmitDeliveryPriceDesbloqued([]);
    },
    [price, categories, setCategories, product]
  );

  const handleSubmitDeliveryPrice = useCallback(async () => {
    const readyValue = deliveryPrice.floatValue
      ? (deliveryPrice.floatValue / 100).toFixed(2)
      : deliveryPrice;

    const formatPrice = (price) => {
      return readyValue > 0 ? price.replace(',', '.') : '0.00';
    };

    const formattedPrice = formatPrice(readyValue);

    await api.put(`restaurants/products/${product.id}`, {
      delivery_price: formattedPrice,
    });

    const newCategories = [...categories];

    newCategories.map((cat, idix) =>
      cat.products.map((prod, indx) => {
        if (prod.id === product.id) {
          newCategories[idix].products[indx].delivery_price = formattedPrice;
        }
      })
    );

    setCategories(newCategories);

    toast.success('Preço alterado com sucesso.');
    localStorage.setItem('@gddashboard:isMenuUpdated', true);

    setButtonsSubmitDesbloqued([]);
    setButtonsSubmitDeliveryPriceDesbloqued([]);
  }, [categories, setCategories, product, deliveryPrice]);

  function handleValueChange(e) {
    setPrice(e);
  }

  function handleValueDeliveryChange(e) {
    setDeliveryPrice(e);
  }

  const handleDuplicateProduct = useCallback(
    async (product_id) => {
      try {
        const response = await api.put(
          `/restaurants/products/duplicate/${product_id}`
        );

        const duplicate_item = response.data;

        const newCategories = [...categories];

        const categoryFind = newCategories.findIndex(
          (item) => item.id === category.id
        );

        if (categoryFind !== -1) {
          newCategories[categoryFind].products.splice(
            [index + 1],
            0,
            duplicate_item
          );
          setCategories(newCategories);
          localStorage.setItem('@gddashboard:isMenuUpdated', true);
        }

        toast.success('Produto duplicado com sucesso');
      } catch (error) {
        toast.error('Erro ao duplicar produto');
      }
    },
    [categories, category, index, setCategories]
  );

  const handleIfood = useCallback(
    async (data) => {
      const ifood_restaurant_id = parseInt(data.ifood_restaurant_id);

      try {
        const thisData = {
          ifood_array_id: data.ifood_products,
          ifood_restaurant_id,
          product_id: product.id,
        };

        await api.post(`/restaurants/ifood/products/array`, thisData);

        toast.success('Produto associado com sucesso!');
      } catch (error) {
        if (error.response.data.type === 'already') {
          let message = '';
          ifoodMenus
            .find((menu) => menu.ifood_restaurant_id === ifood_restaurant_id)
            ?.products?.filter((prod) =>
              error.response.data.array.includes(prod.value)
            )
            .map((prod) => {
              message = `${message + prod.label}, `;
            });
          toast.error(`${message} já vinculado(s)`);
        } else {
          toast.error('Erro ao vincular');
        }
      }
    },
    [product]
  );

  return (
    <>
      <ProductTableRow available sold_off={product?.sold_off} ref={ref}>
        <ItemTd>
          <ProductAvatar>
            {product?.image ? (
              <ProductImage src={product?.image.url} alt="Foto do produto" />
            ) : (
              <NoImage />
            )}
          </ProductAvatar>
          <ProductDescription>
            <ProductName>{product?.name}</ProductName>
          </ProductDescription>
        </ItemTd>
        {!user.only_delivery && (
          <ProductPrice>
            {product?.price_promotion ? (
              <>
                <MenuItemPromotion>
                  {formatValue(product?.price)}
                </MenuItemPromotion>

                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price_promotion)}
                  disabled
                />
              </>
            ) : (
              <>
                <NumberFormatStyled
                  prefix="R$"
                  format={currencyFormatter}
                  name="value"
                  defaultValue={formatValue(product?.price)}
                  disabled
                />
              </>
            )}
          </ProductPrice>
        )}

        <ProductPrice style={{ width: '450px' }}>
          {ifoodRestaurants.map((restaurant) => (
            <Form onSubmit={handleIfood}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', marginRight: 10 }}>
                  <Select
                    name="ifood_products"
                    isMulti
                    defaultValue={ifoodMenus
                      .find(
                        (menu) =>
                          menu.ifood_restaurant_id ===
                          restaurant.ifood_restaurant_id
                      )
                      ?.products?.filter((prod) =>
                        product.ifood_products
                          .map((ifo) => ifo.ifood_id)
                          .includes(prod.value)
                      )}
                    options={
                      ifoodMenus.find(
                        (menu) =>
                          menu.ifood_restaurant_id ===
                          restaurant.ifood_restaurant_id
                      )?.products || []
                    }
                    label={ifoodRestaurants.length > 1 ? restaurant.name : ''}
                  />
                </div>
                <Input
                  style={{ display: 'none' }}
                  value={restaurant.ifood_restaurant_id}
                  name="ifood_restaurant_id"
                />
                <SubmitStyledButton
                  id="limit"
                  type="submit"
                  style={{
                    marginLeft: 'auto',
                    marginTop: ifoodRestaurants.length > 1 ? 30 : 10,
                  }}
                >
                  <FaCheck />
                </SubmitStyledButton>
              </div>
            </Form>
          ))}
        </ProductPrice>

        <ButtonsTd />
        {/* 
        <ButtonsTd>
          <div
            style={{
              display: 'flex',
              height: 'auto',
            }}
          >
            <FaAngleDown
              size={20}
              title="Mostrar complementos"
              cursor="pointer"
              onClick={() => {
                handleCollapse(product?.id);
              }}
              style={{
                transition: 'all 0.2s',
                transform: collapsed.includes(product?.id)
                  ? 'rotate(-180deg)'
                  : 'rotate(0deg)',
                marginLeft: 22,
              }}
            />
          </div>
        </ButtonsTd> */}
      </ProductTableRow>

      {/* {complementCategoriesInsideProduct &&
        complementCategoriesInsideProduct.length > 0 &&
        complementCategoriesInsideProduct.map((comp_category, idx) => (
          <ProductsComplementsCategory
            complementCategory={comp_category}
            complementCategoriesProduct={complementCategoriesInsideProduct}
            setComplementCategoriesInsideProduct={
              setComplementCategoriesInsideProduct
            }
            index={idx}
            isOpen={!collapsed.includes(product?.id)}
            getCategories={getCategories}
            menewProducts={menewProducts}
            product={product}
            categories={categories}
            setCategories={setCategories}
            category={category}
            setComplementsCategories={setComplementsCategories}
            complementCategories={complementCategories}
            fiscalGroups={fiscalGroups}
          />
        ))} */}
    </>
  );
}
