import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  margin: 20px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  svg {
    color: ${(props) => props.theme.colors.tertiary};
    margin-right: 10px;
  }
`;

export const WaitersTable = styled.table`
  width: 100%;
  min-width: 600px;
  /* margin: 10px auto 30px auto; */
  border-collapse: initial;
  border-spacing: 0 20px;
  background: ${(props) => props.theme.colors.white};
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2)); */
  border-radius: 8px;
  padding: 0 30px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const CategoryHeader = styled.div`
  margin-left: 50px;
  margin-bottom: 20px;
`;

export const ProductImage = styled.img`
  width: 80px;
`;

export const Delete = styled.button`
  border: none;
  background: none;

  &:active {
    border: none;
  }

  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Actions = styled.div``;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;
  border: none;
  background: #d60300;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const StatusTag = styled.div.attrs((props) => ({
  color: props.color,
  backgroundcolor: lighten(0.33, props.color),
}))`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundcolor};
  font-weight: bold;
  padding: 5px 10px;
  max-width: 150px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  &::before {
    content: ' ';
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
  }
`;

export const ErrorContainer = styled.div`
  width: 60%;
  min-width: 600px;
  margin: 10px auto 30px auto;

  background: orange;
  color: #fff;

  border-radius: 8px;
  padding: 30px;

  p {
    text-align: center;
    font-size: 22px;
  }
`;

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;

  margin: 10px 0px 0px 10px;
  background-color: transparent;
  color: #0ccab4;
  height: 36px;
  cursor: pointer;

  text-decoration: underline;
  font-weight: bold;
  font-size: 12pt;

  border: 0;
  transition: background 0.2s;

  &:hover {
    color: #333;
  }

  svg {
    margin-right: 6px;
  }

  span {
    color: #33333390;
  }
`;
