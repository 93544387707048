import React, { useState } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import { MdExpandMore } from 'react-icons/md';
import { Collapse } from 'reactstrap';

import { Container, Content } from './styles';
import Subcategorie from './Subcategorie';

function Subcategories({
  category,
  getCategories,
  setParentSubcategory,
  toggle,
  toggleEdit,
  setSubcategory,
}) {
  const [isCollapseOpen, setIsColĺapseOpen] = useState(true);

  function toggleCollapse() {
    setIsColĺapseOpen(!isCollapseOpen);
  }

  return (
    <Container>
      <button type="button" onClick={toggleCollapse}>
        Mostrar/ Esconder Subcategorias <MdExpandMore />
      </button>

      <Collapse isOpen={isCollapseOpen}>
        <Content>
          {category && category?.cash_flow_subcategories.length > 0 ? (
            category?.cash_flow_subcategories.map((cat) => (
              <Subcategorie
                key={cat.id}
                subcategory={cat}
                getCategories={getCategories}
                setParentSubcategory={setParentSubcategory}
                toggle={toggle}
                toggleEdit={toggleEdit}
                setSubcategory={setSubcategory}
                category={category}
              />
            ))
          ) : (
            <p> ... sem subcategorias</p>
          )}
        </Content>
      </Collapse>
    </Container>
  );
}

export default Subcategories;
